import { InjectionKey } from 'vue';
import { ApiUsersDeletePayload } from '@/modules/administration/users/types/apiUsersDeletePayload';
import { ApiUsersLockPayload } from '@/modules/administration/users/types/apiUsersLockPayload';
import { ApiUserPayload } from '@/modules/administration/users/types/apiUserPayload';
import { LoadOptions } from 'devextreme/data';
import { ApiUserData } from '@/modules/administration/users/types/apiUserData';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { GridOutput } from '@/modules/common/types/api/gridOutput';
import { AxiosResponse } from 'axios';

export interface IUsersService {
  getUsers(payload?: LoadOptions): Promise<AxiosResponse<ApiResponse<GridOutput<ApiUserData>>>>;
  blockAndUnblockUsers(payload: ApiUsersLockPayload): Promise<void>;
  deleteUsers(payload: ApiUsersDeletePayload): Promise<void>;
  createUser(payload: ApiUserPayload): Promise<void>;
  updateUser(id: string, payload: ApiUserPayload): Promise<void>;
}

export const IUsersServiceKey: InjectionKey<IUsersService> = Symbol('IUsersService');
