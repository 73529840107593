import { GraphEndpointKey } from '@/modules/common/endpoints/graphEndpoint.interfaces';
import { ApiGraphBuildResponse } from '@/modules/common/types/api/apiGraphBuildResponse';
import {
  useGraphBuildStartedEvent
} from '@/modules/graph/unity/composables/events/useGraphBuildStartedEvent';
import {
  IUnityGraphBuildService
} from '@/modules/graph/unity/services/unityGraphBuildService.interfaces';
import { AxiosInstance } from 'axios';
import { inject, injectable } from 'inversify';
import ResultGraph from '../types/ResultGraph';
import GraphBuildRequest from '../types/GraphBuildRequest';

const { trigger: triggerGraphBuildStarted } = useGraphBuildStartedEvent();

@injectable()
export class UnityGraphBuildService implements IUnityGraphBuildService {
  constructor(
    @inject(GraphEndpointKey as symbol) private graphEndpoint: AxiosInstance
  ) {
  }

  async build(request: GraphBuildRequest): Promise<string> {
    const { data } = await this.graphEndpoint.post<ApiGraphBuildResponse>('/Graphs/Build', request);

    triggerGraphBuildStarted(data.id, request.parameters.type);

    return data.id;
  }

  async getGraph(id: string): Promise<ResultGraph> {
    const { data } = await this.graphEndpoint.get<ResultGraph>(`/Graphs/${id}/Result`);
    return data;
  }

  async getRequest(id: string): Promise<GraphBuildRequest> {
    const { data } = await this.graphEndpoint.get<GraphBuildRequest>(`/Graphs/${id}/Request`);
    return data;
  }
}
