import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { IGraphService } from '@/modules/graph/table/services/graphService.interfaces';
import { ApiGraphCounterpartyResult } from '@/modules/graph/table/types/api/apiGraphCounterpartyResult';
import {
  ApiGraphCreationQueryPersons
} from '@/modules/graph/table/types/api/apiGraphCreationQueryPersons';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class GraphService implements IGraphService {
  constructor(
    @inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance
  ) { }

  searchSelectionToAnalysis(payload: ApiGraphCreationQueryPersons): Promise<AxiosResponse<ApiResponse<ApiGraphCounterpartyResult>>> {
    return this.apiEndpoint.post('graph/grouped-graph-query', payload);
  }
}
