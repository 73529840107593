import { inject, injectable } from 'inversify';
import { ICompanyOgrnipValidationService } from '@/modules/validation/services/companyOgrnipValidationService.interfaces';
import {
  IMasksService,
  IMasksServiceKey,
} from '@/modules/masks/services/masksService/masksService.interfaces';
import {
  companyOgrnipLength,
  companyOgrnipMask,
} from '@/modules/validation/configuration/masks/companyOgrnipMask';

@injectable()
export default class CompanyOgrnipValidationService implements ICompanyOgrnipValidationService {
  constructor(@inject(IMasksServiceKey as symbol) private masksService: IMasksService) {}

  validate(value: string): boolean {
    if (!this.masksService.isCompleted(value, companyOgrnipMask)) {
      return false;
    }

    return value.length == companyOgrnipLength;
  }
}
