import {
  ITaskStatusToApiStatusMapperService
} from '@/modules/tasks/services/taskStatusToApiStatusMapperService/taskStatusToApiStatusMapperService.interfaces';
import { ApiProcessStatus } from '@/modules/tasks/types/api/apiProcessStatus';
import { ProcessStatus } from '@/modules/tasks/types/api/processStatus';
import { injectable } from 'inversify';

@injectable()
export default class TaskStatusToApiStatusMapperService implements ITaskStatusToApiStatusMapperService {
  map(value: ProcessStatus): ApiProcessStatus {
    switch (value) {
      case ProcessStatus.Complete: return ApiProcessStatus.Complete;
      case ProcessStatus.Progress: return ApiProcessStatus.Progress;
      case ProcessStatus.Pending: return ApiProcessStatus.Pending;
      case ProcessStatus.Error: return ApiProcessStatus.Error;
      case ProcessStatus.PartialError: return ApiProcessStatus.PartialError;
    }
  }

  reverseMap(value: ApiProcessStatus): ProcessStatus {
    switch (value) {
      case ApiProcessStatus.Complete: return ProcessStatus.Complete;
      case ApiProcessStatus.Progress: return ProcessStatus.Progress;
      case ApiProcessStatus.Pending: return ProcessStatus.Pending;
      case ApiProcessStatus.Error: return ProcessStatus.Error;
      case ApiProcessStatus.PartialError: return ProcessStatus.PartialError;
    }
  }
}
