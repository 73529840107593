import { injectable } from 'inversify';
import { IFullNameRequisiteMatchingService } from '@/modules/requisites/services/matchers/fullNameRequisiteMatchingService.interfaces';

@injectable()
export class FullNameRequisiteMatchingService implements IFullNameRequisiteMatchingService {
  match(value: string): boolean {
    const regExpFullName = /^[\p{L}а-яА-ЯёЁ'-]+(\s[\p{L}а-яА-ЯёЁ'-]+){0,2}$/u;
    const result = value.match(regExpFullName);
    return Array.isArray(result);
  }

  matchPartial(value: string): boolean {
    const regExpFullName = /^[\p{L}а-яА-ЯёЁ'-]+(\s[\p{L}а-яА-ЯёЁ'-]+){2}$/u;
    const result = value.match(regExpFullName);
    return Array.isArray(result);
  }
}
