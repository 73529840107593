import { injectable } from 'inversify';
import { IPersonContactsEmailVerificationCodeService } from '@/modules/dossier/person/contacts/services/personContactsEmailVerificationCodeService.interface';
import { UseDxTagConfig } from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';

@injectable()
export default class PersonContactsEmailVerificationCodeService
  implements IPersonContactsEmailVerificationCodeService
{
  getEmailVerificationCodeTagData(emailVerificationCode: string | undefined): UseDxTagConfig {
    switch (emailVerificationCode) {
      case 'Корректное значение':
        return {
          theme: 'success',
          content: emailVerificationCode,
          tooltip: 'always',
          tooltipText:
            'Соответствует общепринятым правилам, реальное существование адреса не проверяется',
        };
      case 'Временная электронная почта':
        return { theme: 'warning', content: emailVerificationCode };
      case 'Некорректное значение':
        return {
          theme: 'danger',
          content: emailVerificationCode,
          tooltip: 'always',
          tooltipText: 'Не соответствует общепринятым правилам',
        };
      case 'Исправлены опечатки':
        return { theme: 'common-alt', content: emailVerificationCode };
      default:
        return { theme: 'common-alt', content: 'Неизвестно' };
    }
  }
}
