import { DxTableColumnInfo } from '@/modules/devExtreme/types/dxTableColumnInfo';
import { InjectionKey } from 'vue';
import { ApiDataTableFieldProfile } from '@/modules/profile/types/api/apiDataTableFieldProfile';

export interface IPersonProfileToColumnInfoMapper {
  map(item: ApiDataTableFieldProfile): DxTableColumnInfo
  reverseMap(item: DxTableColumnInfo): ApiDataTableFieldProfile
}

export const IPersonProfileToColumnInfoMapperKey: InjectionKey<IPersonProfileToColumnInfoMapper> =
  Symbol('IPersonProfileToColumnInfoMapper');
