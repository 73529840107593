import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import ExternalDataParserService from '@/modules/dataCollection/services/externalDataParserService';
import {
  IExternalDataParserServiceKey
} from '@/modules/dataCollection/services/externalDataParserService.interfaces';
import ExternalParserOptionsGroupIconsService
  from '@/modules/dataCollection/services/externalParserOptionsGroupIconsService';
import {
  IExternalParserOptionsGroupIconsServiceKey
} from '@/modules/dataCollection/services/externalParserOptionsGroupIconsService.interfaces';
import ExternalParserOptionsGroupTypeFormatter
  from '@/modules/dataCollection/services/formatters/externalParserOptionsGroupTypeFormatter';
import {
  IExternalParserOptionsGroupTypeFormatterKey
} from '@/modules/dataCollection/services/formatters/externalParserOptionsGroupTypeFormatter.interfaces';

export function bindDataCollectionServices(bind: IContainerBinder) {
  bind(IExternalParserOptionsGroupTypeFormatterKey).to(ExternalParserOptionsGroupTypeFormatter);
  bind(IExternalParserOptionsGroupIconsServiceKey).to(ExternalParserOptionsGroupIconsService);
  bind(IExternalDataParserServiceKey).to(ExternalDataParserService);
}
