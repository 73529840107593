import { injectable, inject } from 'inversify';
import { ApiPersonDetailVkRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecord';
import { PersonSocialNetworkAdditionalInfoVKMainInfo } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKMainInfo';
import { PersonSocialNetworkAdditionalInfoAccordeonSections } from '@/modules/dossier/person/socialNetworkAdditionalInfo/common/types/personSocialNetworkAdditionalInfoAccordeonSections';
import { PersonSocialNetworkAdditionalInfoVKRecordKeys } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKRecordKeys';
import {
  IPersonSocialNetworkAdditionalInfoVKGetLastValueService,
  IPersonSocialNetworkAdditionalInfoVKGetLastValueServiceKey,
} from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/parsers/personSocialNetworkAdditionalInfoVKGetLastValueService.interface';
import { IPersonSocialNetworkAdditionalInfoVKMainInfoFormatter } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoFormatter.interface';
import {
  IPersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatter,
  IPersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatterKey,
} from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoByDateFormatter.interface';
import {
  IPersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter,
  IPersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatterKey,
} from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoTitledFormatter.interface';

@injectable()
export default class PersonSocialNetworkAdditionalInfoVKMainInfoFormatter
  implements IPersonSocialNetworkAdditionalInfoVKMainInfoFormatter
{
  constructor(
    @inject(IPersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatterKey as symbol)
    private PersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatter: IPersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatter,
    @inject(IPersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatterKey as symbol)
    private PersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter: IPersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter,
    @inject(IPersonSocialNetworkAdditionalInfoVKGetLastValueServiceKey as symbol)
    private PersonSocialNetworkAdditionalInfoVKGetLastValueService: IPersonSocialNetworkAdditionalInfoVKGetLastValueService,
  ) {}

  formatAdditionalInfoVKDetailRecords(
    detailVkRecord: ApiPersonDetailVkRecord,
  ): PersonSocialNetworkAdditionalInfoVKMainInfo {
    return {
      basicInfo:
        this.PersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter.getPersonSocialNetworkVKMainInfoTitledFormattedData(
          detailVkRecord,
          PersonSocialNetworkAdditionalInfoAccordeonSections.basicInfo,
          [
            PersonSocialNetworkAdditionalInfoVKRecordKeys.lastName,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.firstName,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.nickName,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.maidenName,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.birthdate,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.gender,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.homeCity,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.languages,
          ],
        ),
      relatives: detailVkRecord.relatives_users,
      contacts:
        this.PersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter.getPersonSocialNetworkVKMainInfoTitledFormattedData(
          detailVkRecord,
          PersonSocialNetworkAdditionalInfoAccordeonSections.contacts,
          [
            PersonSocialNetworkAdditionalInfoVKRecordKeys.country,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.city,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.phone,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.skype,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.site,
          ],
        ),
      schools: detailVkRecord.schools,
      university: detailVkRecord.universities,
      career: detailVkRecord.career,
      military: detailVkRecord.military,
      interestSection:
        this.PersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter.getPersonSocialNetworkVKMainInfoTitledFormattedData(
          detailVkRecord,
          PersonSocialNetworkAdditionalInfoAccordeonSections.interests,
          [
            PersonSocialNetworkAdditionalInfoVKRecordKeys.activities,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.interests,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.about,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.music,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.movies,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.tv,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.books,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.games,
          ],
        ),
      lifePosition:
        this.PersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter.getPersonSocialNetworkVKMainInfoTitledFormattedData(
          detailVkRecord,
          PersonSocialNetworkAdditionalInfoAccordeonSections.lifePosition,
          [
            PersonSocialNetworkAdditionalInfoVKRecordKeys.political,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.religion,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.lifeMain,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.peopleMain,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.smoking,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.alcohol,
            PersonSocialNetworkAdditionalInfoVKRecordKeys.inspiredBy,
          ],
        ),
      aboutMe:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord.about,
        ),
      activity:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord.activities,
        ),
      movies:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord.movies,
        ),
      books:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord.books,
        ),
      interests:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord.interests,
        ),
      games:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord.games,
        ),
      music:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord.music,
        ),
      quote:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord.quotes,
        ),
      tvs: this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
        detailVkRecord.tv,
      ),
      statuses:
        this.PersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatter.getPersonSocialNetworkVKMainInfoFormattedDataByDate(
          detailVkRecord.status,
          PersonSocialNetworkAdditionalInfoAccordeonSections.statuses,
        ),
      familyStatus:
        this.PersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatter.getPersonSocialNetworkVKMainInfoFormattedDataByDate(
          detailVkRecord.marital_status,
          PersonSocialNetworkAdditionalInfoAccordeonSections.familyStatus,
        ),
      domains: detailVkRecord.user_id,
    };
  }
}
