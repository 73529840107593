import { IRegulationsRootTypesService } from '@/modules/regulationsCheck/common/services/regulationRootTypesService.interface';
import {
  RegulationsCheckRootType
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckRootType';
import { injectable } from 'inversify';

@injectable()
export default class RegulationsRootTypesService implements IRegulationsRootTypesService {
  getRegulationRootTypes(status: RegulationsCheckRootType): string {
    switch (status) {
      case RegulationsCheckRootType.Auto:
        return 'Автоматическая';
      case RegulationsCheckRootType.Manual:
        return 'Ручная';
      default:
        return 'Неизвестно';
    }
  }
}
