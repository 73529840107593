import { ApiGridUlRecord } from '@/modules/dataGrid/company/types/api/apiGridUlRecord';
import { ApiElasticHit } from '@/modules/elastic/types/api/apiElasticHit';
import {
  IElasticHitToGridCompanyMapper
} from '@/modules/searchConcept/company/services/elasticHitToGridCompanyMapper.interfaces';
import { injectable } from 'inversify';

@injectable()
export class ElasticHitToGridCompanyMapper implements IElasticHitToGridCompanyMapper {
  map(hit: ApiElasticHit): ApiGridUlRecord {
    const source = hit._source;
    const company = source.companyRoot;

    const id = source.id;
    const company_original_id = source.id;

    const fullName = company.fullName.values[0];
    const shortName = company.shortName.values[0];
    const inn = company.inn.values[0];
    const ogrn = company.ogrn.values[0];
    const legalAddress = company.address.values[0];
    const principalActivity = company.mainActivity.values[0];
    const companyListsArray = new Array<string>()
    const ulDetailUrl = `/company/${id}`;

    return {
      id,
      company_original_id,
      fullName,
      shortName,
      inn,
      ogrn,
      legalAddress,
      principalActivity,
      companyListsArray,
      ulDetailUrl
    }
  }
}
