import { ImportListSettings } from '@/modules/import/types/api/importListSettings';
import { ImportListTypes } from '@/modules/import/types/api/importListTypes';

export module importFileListSettings {
  export const defaultListSettings: ImportListSettings = {
    listType: ImportListTypes.none,
    name: '',
    description: '',
    listIds: [],
    isNeedToCreate: false,
  };
}
