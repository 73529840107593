import { inject, injectable } from 'inversify';
import { IMassImportPersonIdentifiersParsingService } from '@/modules/import/modules/massImport/modules/person/services/massImportPersonIdentifiersParsingService/massImportPersonIdentifiersParsingService.interfaces';
import { MassImportPersonIdentifier } from '@/modules/import/modules/massImport/modules/person/types/massImportPersonIdentifier';
import { MassImportPersonIdentifierTypes } from '@/modules/import/modules/massImport/modules/person/types/massImportPersonIdentifierTypes';
import {
  IMassImportIdentifiersParsingService,
  IMassImportIdentifiersParsingServiceKey,
} from '@/modules/import/modules/massImport/services/massImportIdentifiersParsingService/massImportIdentifiersParsingService.interfaces';
import {
  IPersonInnValidationService,
  IPersonInnValidationServiceKey,
} from '@/modules/validation/services/personInnValidationService.interface';
import {
  IPersonSnilsValidationService,
  IPersonSnilsValidationServiceKey,
} from '@/modules/validation/services/personSnilsValidationService.interface';

@injectable()
export default class MassImportPersonIdentifiersParsingService
  implements IMassImportPersonIdentifiersParsingService
{
  constructor(
    @inject(IMassImportIdentifiersParsingServiceKey as symbol)
    private massImportIdentifiersParsingService: IMassImportIdentifiersParsingService,
    @inject(IPersonInnValidationServiceKey as symbol)
    private personInnValidationService: IPersonInnValidationService,
    @inject(IPersonSnilsValidationServiceKey as symbol)
    private personSnilsValidationService: IPersonSnilsValidationService,
  ) {}

  parse(identifier: string): MassImportPersonIdentifier {
    return (
      this.parseInn(identifier) ??
      this.parseSnils(identifier) ?? {
        type: MassImportPersonIdentifierTypes.unknown,
        value: identifier,
      }
    );
  }

  private parseInn(identifier: string): MassImportPersonIdentifier | undefined {
    return this.massImportIdentifiersParsingService.parse(
      identifier,
      this.personInnValidationService,
      MassImportPersonIdentifierTypes.inn,
    );
  }

  private parseSnils(identifier: string): MassImportPersonIdentifier | undefined {
    return this.massImportIdentifiersParsingService.parse(
      identifier,
      this.personSnilsValidationService,
      MassImportPersonIdentifierTypes.snils,
    );
  }
}
