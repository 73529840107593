import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import ApiSettings from '@/modules/common/settings/apiSettings';
import { IApiSettingsKey } from '@/modules/common/settings/apiSettings.interfaces';
import DaDataSettings from '@/modules/common/settings/daDataSettings';
import { IDaDataSettingsKey } from '@/modules/common/settings/daDataSettings.interfaces';
import { IEnvironmentVariablesKey } from '@/modules/common/settings/environmentVariables.interfaces';
import UiSettings from '@/modules/common/settings/uiSettings';
import { IUiSettingsKey } from '@/modules/common/settings/uiSettings.interfaces';
import { ICacheSettingsKey } from '@/modules/common/settings/cacheSettings.interfaces';
import CacheSettings from '@/modules/common/settings/cacheSettings';
import { IImportSettingsKey } from '@/modules/common/settings/importSettings.interfaces';
import ImportSettings from '@/modules/common/settings/importSettings';
import { IEnvironmentSettingsKey } from '@/modules/common/settings/environmentSettings.interfaces';
import EnvironmentSettings from '@/modules/common/settings/environmentSettings';
import IYandexMapsApiSettings from '@/modules/common/settings/yandexMapsApiSettings';
import { IYandexMapsApiSettingsKey } from '@/modules/common/settings/yandexMapsApiSettings.interfaces';

export function bindSettings(bind: IContainerBinder) {
  bind(IEnvironmentVariablesKey)
    .toDynamicValue(() => process.env)
    .inSingletonScope();
  bind(IApiSettingsKey).to(ApiSettings).inSingletonScope();
  bind(IDaDataSettingsKey).to(DaDataSettings).inSingletonScope();
  bind(IUiSettingsKey).to(UiSettings).inSingletonScope();
  bind(ICacheSettingsKey).to(CacheSettings).inSingletonScope();
  bind(IImportSettingsKey).to(ImportSettings).inSingletonScope();
  bind(IEnvironmentSettingsKey).to(EnvironmentSettings).inSingletonScope();
  bind(IYandexMapsApiSettingsKey).to(IYandexMapsApiSettings).inSingletonScope();
}
