import { InjectionKey } from 'vue';
import { ApiArbitrationCase } from '@/modules/dossier/person/common/types/api/apiArbitrationCase';

export interface IPersonArbitrationStatisticService {
  calculateArbitrationCountByType(arbitrationList: Array<ApiArbitrationCase>, type: string): string;
  calculateArbitrationSum(arbitrationList: Array<ApiArbitrationCase>): string;
}

export const IPersonArbitrationStatisticsServiceKey: InjectionKey<IPersonArbitrationStatisticService> =
  Symbol('IPersonArbitrationStatisticsService');
