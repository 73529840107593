import { IDesktopReportsStatusFormatter } from '@/modules/reports/desktop/services/desktopReportsStatusFormatter.interfaces';
import { ReportStatusType } from '@/modules/reports/common/types/ReportStatusType';
import { injectable } from 'inversify';

@injectable()
export default class DesktopReportsStatusFormatter implements IDesktopReportsStatusFormatter {
  format(status: ReportStatusType): string {
    switch (status) {
      case ReportStatusType.complete:
        return 'Сгенерирован';
      case ReportStatusType.error:
        return 'Ошибка';
      case ReportStatusType.pending:
        return 'Ожидается';
      case ReportStatusType.progress:
        return 'В процессе';
      default:
        return 'Неизвестно';
    }
  }
}
