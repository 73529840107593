import uniq from 'lodash/uniq';
import { injectable } from 'inversify';
import { ICompanyCertificatesMappingService } from '@/modules/dossier/company/common/services/mappings/companyCertificatesMappingService.interface';
import { CompanyCertificateTypes } from '@/types/companyData/companyCertificatesTypes';
import { CompanyCertificates } from '@/types/companyData/CompanyCertificates';
import { CertificationsAndDeclarations } from '@/modules/dossier/company/common/types/companyCertificatesAndDeclarations';

@injectable()
export default class companyCertificatesMappingService
  implements ICompanyCertificatesMappingService
{
  protected getUniqCertificatesByRoleAndType(
    role: string,
    type: CompanyCertificateTypes,
    certificates: CompanyCertificates[],
  ): number {
    const condition = (item: CompanyCertificates) =>
      (item.applicantRole == role || (!item.applicantRole && role == 'Неизвестно')) &&
      item.type == type;

    const uniqCertificates = uniq(certificates.filter(condition).map((item) => item.number));
    return uniqCertificates.length;
  }

  protected divideCertificatesByRole(
    role: string,
    arr: CompanyCertificates[],
  ): CertificationsAndDeclarations[] {
    const certificates = this.getUniqCertificatesByRoleAndType(role, 'Сертификат', arr);
    const declarations = this.getUniqCertificatesByRoleAndType(role, 'Декларация', arr);
    const result: CertificationsAndDeclarations[] = [
      { applicantRole: role, type: 'Сертификат', count: `${certificates}` },
      { applicantRole: role, type: 'Декларация', count: `${declarations}` },
    ];

    return result;
  }

  getCertificatesAnalyticsTableData(
    allRoles: Array<string>,
    certificates: Array<CompanyCertificates>,
  ) {
    const data: Array<CertificationsAndDeclarations> = [];
    allRoles.forEach((item) => {
      const dividedCertificates = this.divideCertificatesByRole(item, certificates);
      data.push(...dividedCertificates);
    });
    return data;
  }
}
