import { InjectionKey } from 'vue';
import { ApiPersonDetailVkRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecord';
import { PersonSocialNetworkAdditionalInfoVKMainInfo } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKMainInfo';

export interface IPersonSocialNetworkAdditionalInfoVKMainInfoFormatter {
  formatAdditionalInfoVKDetailRecords(
    detailVkRecord: ApiPersonDetailVkRecord,
  ): PersonSocialNetworkAdditionalInfoVKMainInfo;
}

export const IPersonSocialNetworkAdditionalInfoVKMainInfoFormatterKey: InjectionKey<IPersonSocialNetworkAdditionalInfoVKMainInfoFormatter> =
  Symbol('IPersonSocialNetworkAdditionalInfoVKMainInfoFormatterKey');
