import {
  IStringNormalizationService
} from '@/modules/normalization/services/stringNormalizationService.interfaces';
import { injectable } from 'inversify';
import { isNumber, isString, isNaN } from 'lodash';

@injectable()
export default class StringNormalizationService implements IStringNormalizationService {
  normalize(value: any): string {
    if(isString(value))
      return value;
    else if(isNumber(value) && !isNaN(value))
      return value.toString();
    else
      return '';
  }
}
