import { useLogoutEvent } from '@/modules/auth/composables/events/useLogoutEvent/useLogoutEvent';
import { UseTasksTrackingItem } from '@/modules/tasks/composables/useTasksTracking/useTasksTracking.interfaces';
import { useTaskStore } from '@/modules/tasks/stores/useTaskStore';
import { ApiProcessStatus } from '@/modules/tasks/types/api/apiProcessStatus';
import { ProcessTaskStepType } from '@/modules/tasks/types/api/processTaskStepType';
import { TrackedTask } from '@/modules/tasks/types/trackedTask';
import { TaskUtils } from '@/modules/tasks/utils/taskUtils';
import { storeToRefs } from 'pinia';
import { computed, effectScope, onScopeDispose, reactive, watch } from 'vue';

export function useTasksTracking() {
  const { onLogout } = useLogoutEvent();

  const taskList = reactive<Array<UseTasksTrackingItem>>([]);

  const outerTasksList = computed(() =>
    taskList.map<TrackedTask>(x => ({
      taskId: x.taskId,
      currentStep: x.currentStep,
      status: x.status,
      progress: x.progress,
    })),
  );

  function registerTask(taskId: string, status = ApiProcessStatus.Pending) {
    let task = taskList.find(t => t.taskId == taskId);

    if (task == undefined) {
      task = {
        taskId,
        status,
        progress: 0,
        currentStep: ProcessTaskStepType.Unknown,
      };
      taskList.push(task);
    }

    const isValidRequest =
      (TaskUtils.isTaskInProgress(task.status) && TaskUtils.isTaskInProgress(status)) ||
      (TaskUtils.isTaskInProgress(task.status) && !TaskUtils.isTaskInProgress(status));

    if (!isValidRequest) {
      return;
    }

    task.status = status;
    handleTaskStatusChange(task.taskId);
  }

  function registerTasks(taskIds: string[]) {
    taskIds.forEach(taskId => registerTask(taskId));
  }

  function handleTaskStatusChange(taskId: string) {
    const task = taskList.find(t => t.taskId == taskId);
    if (task == undefined) {
      return;
    }

    if (TaskUtils.isTaskInProgress(task.status)) {
      startTaskTracking(task.taskId);
    } else {
      stopTaskTracking(task.taskId);
    }
  }

  function startTaskTracking(taskId: string) {
    const task = taskList.find(t => t.taskId == taskId);
    if (task == undefined) {
      return;
    }
    if (task.scope != undefined) {
      return;
    }

    const scope = effectScope(true);
    task.scope = scope;

    scope.run(() => {
      const taskStore = useTaskStore(task.taskId);
      const { status, progress, currentStep } = storeToRefs(taskStore);

      watch(
        status,
        status => {
          task.status = status;
          handleTaskStatusChange(task.taskId);
        },
        { immediate: true },
      );

      watch(
        progress,
        progress => {
          task.progress = progress;
        },
        { immediate: true },
      );

      watch(
        currentStep,
        currentStep => {
          task.currentStep = currentStep;
        },
        { immediate: true },
      );
    });
  }

  function stopTaskTracking(taskId: string) {
    const task = taskList.find(t => t.taskId == taskId);
    if (task == undefined) {
      return;
    }

    if (task.scope != undefined) {
      task.scope.stop();
      task.scope = undefined;
    }
  }

  function unregisterTask(taskId: string) {
    const task = taskList.find(t => t.taskId == taskId);
    if (task == undefined) {
      return;
    }

    stopTaskTracking(task.taskId);
    const index = taskList.findIndex(t => t.taskId == task.taskId);
    if (index > -1) {
      taskList.splice(index, 1);
    }
  }

  function clear() {
    taskList.forEach(task => {
      stopTaskTracking(task.taskId);
      unregisterTask(task.taskId);
    });
  }

  onLogout(() => clear());
  onScopeDispose(() => clear());

  return {
    taskList: outerTasksList,
    registerTask,
    registerTasks,
    unregisterTask,
    clear,
  };
}
