import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { GridOutput } from '@/modules/common/types/api/gridOutput';
import { ApiGridInput } from '@/modules/dataGrid/common/types/api/apiGridInput';
import { IReportsService } from '@/modules/reports/common/services/reportsService/reportsService.interfaces';
import { ReportInterruptResponseApi } from '@/modules/reports/common/types/reportInterruptResponseApi';
import { TGenerateReportBodyApi } from '@/modules/reports/desktop/types/TGenerateReportBodyApi';
import { TGenerateReportResponseApi } from '@/modules/reports/desktop/types/TGenerateReportResponseApi';
import { THistoryReportApi } from '@/modules/reports/desktop/types/THistoryReportApi';
import { TStatusTaskResponseApi } from '@/modules/reports/desktop/types/TStatusTaskResponseApi';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class ReportsService implements IReportsService {
  constructor(@inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance) {}

  cancelReportGeneration(taskId: string): Promise<AxiosResponse<ReportInterruptResponseApi>> {
    return this.apiEndpoint.post('/easy-reports/interrupt', { taskId });
  }

  generateReport(
    payload: TGenerateReportBodyApi,
  ): Promise<AxiosResponse<TGenerateReportResponseApi>> {
    const formData = new FormData();

    formData.append('request', JSON.stringify(payload));
    formData.append('file', 'undefined');

    return this.apiEndpoint.post('/easy-reports/generate', formData);
  }

  getReportStatus(taskId: string): Promise<AxiosResponse<TStatusTaskResponseApi>> {
    return this.apiEndpoint.get(`/easy-reports/status`, {
      params: {
        taskId,
      },
    });
  }

  getAllHistoryReports(): Promise<AxiosResponse<ApiResponse<THistoryReportApi[]>>> {
    return this.apiEndpoint.get('/easy-reports/history');
  }

  getHistoryReports(
    payload?: ApiGridInput,
  ): Promise<AxiosResponse<ApiResponse<GridOutput<THistoryReportApi>>>> {
    return this.apiEndpoint.post('/easy-reports/history-paging', payload ?? {});
  }

  postStopGenerateReport(): Promise<AxiosResponse<void>> {
    return this.apiEndpoint.post('/easy-reports/interrupt', {});
  }
}
