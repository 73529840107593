import { InjectionKey } from 'vue';
import { MassImportCompanyIdentifier } from '@/modules/import/modules/massImport/modules/company/types/massImportCompanyIdentifier';
import { ApiMassImportCompanyIdentifier } from '@/modules/import/types/api/apiMassImportCompanyIdentifier';

export interface IMassImportCompanyIdentifierMapperService {
  map(identifier: MassImportCompanyIdentifier): ApiMassImportCompanyIdentifier;
}

export const IMassImportCompanyIdentifierMapperServiceKey: InjectionKey<IMassImportCompanyIdentifierMapperService> =
  Symbol('IMassImportCompanyIdentifierMapperService');
