import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import AnalysisGraphBuildRequestFactory
  from '@/modules/graph/analysisUnity/factories/analysisGraphBuildRequestFactory';
import {
  IAnalysisGraphBuildRequestFactoryKey
} from '@/modules/graph/analysisUnity/factories/analysisGraphBuildRequestFactory.interfaces';

export function bindAnalysisUnityGraphServices(bind: IContainerBinder) {
  bind(IAnalysisGraphBuildRequestFactoryKey).to(AnalysisGraphBuildRequestFactory);
}
