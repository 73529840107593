import { injectable } from 'inversify';
import { ICompanyPledgesMappingService } from './companyPledgesMappingService.interface';
import { Pledge } from '@/modules/dossier/company/pledges/types/pledge';
import { PledgeTableRow } from '@/modules/dossier/company/pledges/types/pledgeTableRow';
import { PledgeStatisticsTableRow } from '@/modules/dossier/company/pledges/types/pledgeStatisticsTableRow';
import { PledgeholdersByPledges } from '@/modules/dossier/company/pledges/types/pledgeholdersByPledges';

@injectable()
export default class СompanyPledgesMappingService implements ICompanyPledgesMappingService {
  mapPledgesListTableData(pledges: Array<Pledge>): Array<PledgeTableRow> {
    return pledges.map((pledge) => {
      const compiledPledgeeData = pledge.pledgeholders.reduce(
        (acc, { inn, ogrn, name }) => ({
          inn: [...acc.inn, inn],
          ogrn: [...acc.ogrn, ogrn],
          names: [...acc.names, name],
        }),
        { inn: [], ogrn: [], names: [] } as Record<string, Array<string>>,
      );

      const compiledPledgerData = pledge.pledgers.reduce(
        (acc, { inn, ogrn, name }) => ({
          inn: [...acc.inn, inn],
          ogrn: [...acc.ogrn, ogrn],
          names: [...acc.names, name],
        }),
        { inn: [], ogrn: [], names: [] } as Record<string, Array<string>>,
      );

      return {
        registrationNumber: pledge.notificationNumber,
        vin: pledge.vin ? pledge.vin : pledge.pledgedThingNumber,
        pledgeType: pledge.pledgeTypeStr,
        pledgeDescription: pledge.description,
        contractName: pledge.contractName,
        contractNumber: pledge.contactNumber,
        notaryName: pledge.fullname,
        notaryInn: pledge.inn,
        contractDate: pledge.contractDate,
        obligationsDeadline: pledge.executionDate,
        bondIssueRegistrationDate: pledge.bondIssueRegistrationDate,
        pledgeCreationDate: pledge.registrationDateStr,
        lastNotificationDate: pledge.updateDate,
        pledgerInn: compiledPledgerData.inn.join(', '),
        pledgerOgrn: compiledPledgerData.ogrn.join(', '),
        pledgerName: compiledPledgerData.names.join(', '),
        pledgeeInn: compiledPledgeeData.inn.join(', '),
        pledgeeOgrn: compiledPledgeeData.ogrn.join(', '),
        pledgeeName: compiledPledgeeData.names.join(', '),
      };
    });
  }

  getPledgeholdersByPledges(pledges: Array<Pledge>): Array<PledgeStatisticsTableRow> {
    const pledgeholdersByPledges: PledgeholdersByPledges = pledges.reduce((acc, pledge) => {
      const date = new Date(pledge.contractDate).getFullYear().toString();
      const pledgeholderName =
        pledge.pledgeholders[0].name == null ? 'Неизвестно' : pledge.pledgeholders[0].name;
      if (pledgeholderName in acc) {
        if (date in acc[pledgeholderName]) {
          acc[pledgeholderName][date] += 1;
        } else {
          acc[pledgeholderName] = { ...acc[pledgeholderName], [date]: 1 };
        }
      } else {
        acc[pledgeholderName] = { [date]: 1 };
      }
      return acc;
    }, {} as PledgeholdersByPledges);

    return Object.entries(pledgeholdersByPledges)
      .map((entry) =>
        Object.entries(entry[1]).map((year) => ({
          companyName: entry[0],
          year: year[0],
          contractsAmount: year[1],
        })),
      )
      .flat();
  }
}
