import { AnalysisEntity } from '@/modules/analysis/types/analysisEntity';

import { InjectionKey } from 'vue';
import { ApiUlListDataItem } from '@/modules/lists/types/api/ul/apiUlListDataItem';
import { ApiPersonListDataItem } from '@/modules/lists/types/api/fl/apiPersonListDataItem';

export interface IListsToAnalysisEntityMapper {
  mapCompaniesLists(item: ApiUlListDataItem): AnalysisEntity;
  mapPersonsLists(item: ApiPersonListDataItem): AnalysisEntity;
}

export const IListsToAnalysisEntityMapperKey: InjectionKey<IListsToAnalysisEntityMapper> = Symbol(
  'IListsToAnalysisEntityMapperKey',
);
