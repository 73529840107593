import { injectable } from 'inversify';
import { AnalyticsScore } from '@/modules/dossier/person/financialScorings/types/financialAnalyticsScore';
import { IAnalyticsScoreOKBTypeParserService } from '@/modules/dossier/person/financialScorings/services/parsers/analyticsScoreOKBTypeParserService.interface';

@injectable()
export default class AnalyticsScoreOKBTypeParserService
  implements IAnalyticsScoreOKBTypeParserService
{
  parseOKBAnalyticScore(score: number): AnalyticsScore {
    switch (true) {
      case score >= 855:
        return AnalyticsScore.HighScore;
      case score >= 722:
        return AnalyticsScore.MediumScore;
      case score >= 600:
        return AnalyticsScore.PoorScore;
      case score < 600 && score > 0:
        return AnalyticsScore.WorstScore;
      case score == 0:
        return AnalyticsScore.NotFound;
      case score < 0:
        return AnalyticsScore.OverdueScore;
      default:
        return AnalyticsScore.NotFound;
    }
  }
}
