import { injectable, inject } from 'inversify';
import {
  IDaDataService,
  IDaDataServiceKey,
} from '@/modules/dadata/services/DaDataApiService.interfaces';
import { IDaDataMapCoordinatesService } from '@/modules/dossier/person/socialNetworkAdditionalInfo/common/services/DaDataMapCoordinatesService.interface';
import { MapCoordinates } from '@/modules/maps/types/MapCoordinates';

@injectable()
export default class DaDataMapService implements IDaDataMapCoordinatesService {
  constructor(
    @inject(IDaDataServiceKey as symbol)
    private DaDataService: IDaDataService,
  ) {}

  async getCoordinatesByCityName(city: string): Promise<MapCoordinates | undefined> {
    if (!city) return undefined;

    const res = await this.DaDataService.getAddressSuggestions(city);

    if (res.data.suggestions.length == 0) return undefined;

    const matchedCity = res.data.suggestions.find((item) => item.data.city === city);

    const firstInSuggestion = {
      geo_lat: res.data.suggestions[0].data.geo_lat,
      geo_lon: res.data.suggestions[0].data.geo_lon,
    };

    return {
      geo_lat: matchedCity?.data.geo_lat || firstInSuggestion.geo_lat,
      geo_lon: matchedCity?.data.geo_lon || firstInSuggestion.geo_lon,
    };
  }

  async getCountryByCityName(city: string): Promise<string | undefined> {
    if (!city) return undefined;

    const res = await this.DaDataService.getAddressSuggestions(city);

    if (res.data.suggestions.length == 0) return undefined;

    const { country } = res.data.suggestions[0].data;
    return country;
  }
}
