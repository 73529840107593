<template>
  <div v-show="show" class="ui-snack-bar" :class="uiSnackBarModifier" @click="handleClickComponent">
    <div class="ui-snack-bar__view">
      <slot />
    </div>
    <div class="ui-snack-bar__description">
      <span class="ui-snack-bar__description-title">{{ props.title }}</span>
      <span class="ui-snack-bar__description-quick-sell">{{ props.quickSell }}</span>
    </div>
    <UIIcon
      class="ui-snack-bar__close"
      :name="SystemIconsNames.closeSmall"
      clickable
      @click.stop="handleCloseIconClick"
    />
  </div>
</template>

<script lang="ts" setup>
import { SnackBarClickHandler } from '@/modules/snacks/UISnackBar/utils/snackBarClickHandler';
import { computed } from 'vue';
import UIIcon from '@/ui-components/UIIcon/UIIcon.vue';

import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { UISnackBarStatus } from '@/modules/snacks/UISnackBar/types/UISnackBarStatus';

type Emits = {
  (eventName: 'close'): void;
};

interface Props {
  title: string;
  quickSell?: string;
  status?: UISnackBarStatus;
  onClick?: SnackBarClickHandler;
  show?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  status: 'info',
  show: true,
});

const emits = defineEmits<Emits>();

const uiSnackBarModifier = computed(() => [
  `ui-snack-bar--${props.status}`,
  {
    'ui-snack-bar--pointer': props.onClick != undefined,
  },
]);

function closeNotification() {
  emits('close');
}
function handleCloseIconClick() {
  closeNotification();
}

function handleClickComponent() {
  if (props.onClick?.() === true) {
    closeNotification();
  }
}
</script>

<style lang="scss">
.ui-snack-bar {
  @include sectionMount(
    $background: var(--color-white-7),
    $shadow: var(--shadow-2),
    $filter: var(--filter-blur-10),
    $radius: var(--border-radius-12)
  );
  display: flex;
  z-index: 100;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  &.ui-snack-bar--info {
    background: var(--color-white-9);
  }
  &.ui-snack-bar--error {
    background: var(--color-red-7);
  }

  &.ui-snack-bar--warning {
    background: var(--color-orange-6);
  }

  &.ui-snack-bar--success {
    background: var(--color-green-9);
  }

  &.ui-snack-bar--pointer {
    cursor: pointer;
  }
}

.ui-snack-bar__view {
  align-self: center;
  flex: 0 1 48px;
}

.ui-snack-bar__description {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-right: 16px;
  overflow: hidden;
}

.ui-snack-bar__description-title {
  font-weight: var(--font-weight-450);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-20);
  color: var(--color-gray-1);
}

.ui-snack-bar__description-quick-sell {
  white-space: pre;
  font-weight: var(--font-weight-450);
  font-size: var(--font-size-10);
  line-height: var(--font-line-height-14);
  color: var(--color-gray-5);
}

.ui-snack-bar__close {
  align-self: start;
  min-width: 16px;
  color: var(--color-gray-2);
  transition: var(--transition-2);

  &:hover {
    transition: var(--transition-2);
    transform: rotate(90deg);
  }
}
</style>
