export interface ManualCompanyState {
  [k: string]: string;
  companyName: string;
  inn: string;
  ogrn: string;
  phoneType: string;
  phoneNumber: string;
  email: string;
  socialNetworkType: string;
  socialNetworkId: string;
  employeeSocialNetworkType: string;
  employeeSocialNetworkId: string;
  transportNumber: string;
  transportVin: string;
  note: string;
}

export enum MutationTypes {
  SET_NEW_VALUE_COMPANIES = 'SET_NEW_VALUE_COMPANIES',
  RESET_VALUES_COMPANIES = 'RESET_VALUES_COMPANIES',
}

export type Mutations<S = ManualCompanyState> = {
  [MutationTypes.SET_NEW_VALUE_COMPANIES](state: S, payload: { name: string; value: string }): void;
  [MutationTypes.RESET_VALUES_COMPANIES](state: S): void;
};
