import { injectable } from 'inversify';
import { ApiPersonDetailOkRecordStringItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordStringItem';
import { orderBy } from 'lodash';
import { IPersonSocialNetworkAdditionalInfoOKGetLastValueService } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/parsers/personSocialNetworkAdditionalInfoOKGetLastValueService.interface';

@injectable()
export default class PersonSocialNetworkAdditionalInfoOKGetLastValueService
  implements IPersonSocialNetworkAdditionalInfoOKGetLastValueService
{
  getPersonSocialNetworkOKMainInfoLatestValue(
    detailOkItemValue: ApiPersonDetailOkRecordStringItem[],
  ): string {
    return (
      orderBy(detailOkItemValue, (value) => new Date(value.date), 'desc').shift()?.value ||
      'Информация отсутствует'
    );
  }
}
