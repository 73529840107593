import { injectable } from 'inversify';
import { ICurrencyTagFormatterService } from '@/modules/currency/services/currencyTagFormatterService/currencyTagFormatterService.interfaces';
import { CurrencyTag } from '@/modules/currency/types/currencyTag';

@injectable()
export default class CurrencyTagFormatterService implements ICurrencyTagFormatterService {
  format(value: CurrencyTag): string {
    switch (value) {
      case CurrencyTag.rub:
        return '₽';
      case CurrencyTag.usd:
        return '$';
      case CurrencyTag.eur:
        return '€';
      case CurrencyTag.gbr:
        return '£';
      case CurrencyTag.chf:
        return '¥';
      case CurrencyTag.chf:
        return '₺';
      case CurrencyTag.inr:
        return '₹';
      case CurrencyTag.ils:
        return '₪';
      case CurrencyTag.kzt:
        return '₸';
      case CurrencyTag.vnd:
        return '₫';
      case CurrencyTag.krw:
        return '₩';
      case CurrencyTag.thb:
        return '฿';
      case CurrencyTag.afn:
        return '؋';
      default:
        return '';
    }
  }
}
