import {
  IDxPdfExportServiceFactory, IDxPdfExportServiceFactoryRequest
} from '@/modules/devExtreme/export/pdf/factories/dxPdfExportServiceFactory/dxPdfExportServiceFactory.interfaces';
import {
  IDxPdfCellFormatter, IDxPdfCellFormatterKey
} from '@/modules/devExtreme/export/pdf/services/dxPdfCellFormatter/dxPdfCellFormatter.interfaces';
import DxPdfExportService
  from '@/modules/devExtreme/export/pdf/services/dxPdfExportService/dxPdfExportService';
import {
  IDxPdfExportService
} from '@/modules/devExtreme/export/pdf/services/dxPdfExportService/dxPdfExportService.interfaces';
import {
  IDxPdfExternalFontsService, IDxPdfExternalFontsServiceKey
} from '@/modules/devExtreme/export/pdf/services/dxPdfExternalFontsService/dxPdfExternalFontsService.interfaces';
import {
  IDxPdfOrientationToJsPdfOrientationMappingService,
  IDxPdfOrientationToJsPdfOrientationMappingServiceKey
} from '@/modules/devExtreme/export/pdf/services/dxPdfOrientationToJsPdfOrientationMappingService/dxPdfOrientationToJsPdfOrientationMappingService.interfaces';
import { inject, injectable, multiInject } from 'inversify';

@injectable()
export default class DxPdfExportServiceFactory implements IDxPdfExportServiceFactory {
  constructor(
    @multiInject(IDxPdfCellFormatterKey as symbol) private cellFormatters: IDxPdfCellFormatter[],
    @inject(IDxPdfExternalFontsServiceKey as symbol) private externalFontsService: IDxPdfExternalFontsService,
    @inject(IDxPdfOrientationToJsPdfOrientationMappingServiceKey as symbol) private orientationMapper: IDxPdfOrientationToJsPdfOrientationMappingService
  ) { }

  getPdfExportService(request: IDxPdfExportServiceFactoryRequest): IDxPdfExportService {
    return new DxPdfExportService(
      this.orientationMapper.map(request.orientation),
      this.cellFormatters,
      this.externalFontsService
    );
  }
}
