import { IDesktopReportsHistoryItemTagsFactory } from '@/modules/reports/desktop/services/desktopReportsHistoryItemTagsFactory.interfaces';
import { UseDxTagConfig } from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';
import { ReportHistoryItem } from '@/modules/reports/common/types/reportHistoryItem';
import { ReportStatusType } from '@/modules/reports/common/types/ReportStatusType';
import { injectable } from 'inversify';

@injectable()
export default class DesktopReportsHistoryItemTagsFactory
  implements IDesktopReportsHistoryItemTagsFactory
{
  getTag(report: ReportHistoryItem): UseDxTagConfig {
    switch (report.status) {
      case ReportStatusType.complete:
        return {
          theme: 'success',
          content: 'Сгенерирован',
        };
      case ReportStatusType.error:
        return {
          theme: 'danger',
          content: 'Ошибка',
        };
      case ReportStatusType.pending:
        return {
          theme: 'common',
          content: 'Ожидается',
        };
      case ReportStatusType.progress:
        return {
          theme: 'common',
          content: 'В процессе',
        };
      default:
        return {
          theme: 'common',
          content: 'Неизвестно',
        };
    }
  }
}
