import {
  IDxDateComparatorService
} from '@/modules/devExtreme/services/dxDateComparatorService/dxDateComparatorService.interfaces';
import { injectable } from 'inversify';

@injectable()
export default class DxDateComparatorService implements IDxDateComparatorService {
  compare(left: string | Date, right: string | Date): boolean {
    return this.getKey(left) == this.getKey(right)
  }

  private getKey(value: string | Date) {
    const date = new Date(value);
    return `${date.getFullYear()} ${date.getMonth()}`;
  }
}
