import { IExternalParserOptionsGroupIconsService } from '@/modules/dataCollection/services/externalParserOptionsGroupIconsService.interfaces';
import { ExternalParserOptionsGroupType } from '@/modules/dataCollection/types/externalParserOptionsGroupType';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { injectable } from 'inversify';

@injectable()
export default class ExternalParserOptionsGroupIconsService
  implements IExternalParserOptionsGroupIconsService
{
  getIconByType(type: ExternalParserOptionsGroupType): SystemIconsNames | undefined {
    switch (type) {
      case ExternalParserOptionsGroupType.Odyssey:
        return SystemIconsNames.odyssey;
      case ExternalParserOptionsGroupType.Cronos:
        return SystemIconsNames.cronos;
      case ExternalParserOptionsGroupType.Av100:
        return SystemIconsNames.av100;
      case ExternalParserOptionsGroupType.Mvd:
        return SystemIconsNames.mvd;
      case ExternalParserOptionsGroupType.FNS:
        return SystemIconsNames.fns;
      case ExternalParserOptionsGroupType.Xneo:
        return SystemIconsNames.xneo;
      case ExternalParserOptionsGroupType.Damia:
        return SystemIconsNames.damia;
      case ExternalParserOptionsGroupType.Idx:
        return SystemIconsNames.idx;
      case ExternalParserOptionsGroupType.KonturFocus:
        return SystemIconsNames.konturFocus;
      case ExternalParserOptionsGroupType.Bfo:
        return SystemIconsNames.bfo;
      case ExternalParserOptionsGroupType.OK:
        return SystemIconsNames.okIcon;
      case ExternalParserOptionsGroupType.VK:
        return SystemIconsNames.vkIcon;
    }
  }
}
