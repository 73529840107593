import {
  RegulationsCheckRootType
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckRootType';
import { InjectionKey } from 'vue';

export interface IRegulationsRootTypesService {
  getRegulationRootTypes(status: RegulationsCheckRootType | undefined): string;
}

export const IRegulationsRootTypesServiceKey: InjectionKey<IRegulationsRootTypesService> = Symbol('IRegulationsRootTypesServiceKey',);
