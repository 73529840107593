import { injectable } from 'inversify';
import { ICompanyRelationsService } from '@/modules/dossier/company/relations/services/companyRelationsService.interface';
import { CompanyRelationsItem } from '@/modules/dossier/company/relations/types/companyRelations';

@injectable()
export default class companyRelationsService implements ICompanyRelationsService {
  calculateCountRelationsByType(items: Array<CompanyRelationsItem>, type: string): number {
    return items.filter((item) => item.relationTypeStr == type).length;
  }
}
