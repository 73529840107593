import { InjectionKey } from 'vue';
import { ApiDossierLink } from '@/modules/dossier/common/types/apiDossierLink';

export interface ICompanyDossierIdService {
  getIdFromDossierLink(dossierId?: ApiDossierLink): string | undefined;
  getIdFromUrl(url?: string): string | undefined;
}

export const ICompanyDossierIdServiceKey: InjectionKey<ICompanyDossierIdService> = Symbol(
  'ICompanyDossierIdService',
);
