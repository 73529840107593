import { InjectionKey } from 'vue';
import { PersonContactsPhonesFioPhoneBondVerificationTag } from '@/modules/dossier/person/contacts/types/personContactsPhonesFioPhoneBondVerificationTag';

export interface IPersonContactsPhonesFioPhoneBondVerificationService {
  formatFioPhoneBondVerificationCode(
    value: number,
  ): PersonContactsPhonesFioPhoneBondVerificationTag | undefined;
}

export const IPersonContactsPhonesFioPhoneBondVerificationServiceKey: InjectionKey<IPersonContactsPhonesFioPhoneBondVerificationService> =
  Symbol('IPersonContactsPhonesFioPhoneBondVerificationServiceKey');
