import { ISetupNotificationCleanupService } from '@/modules/notifications/services/setupNotificationCleanup.interfaces';
import { injectable } from 'inversify';
import { NotificationDuration } from '@/modules/notifications/types/notificationDuration';
import { NotificationConfig } from '@/modules/notifications/types/notificationConfig';

@injectable()
export class SetupNotificationCleanupService implements ISetupNotificationCleanupService {
  setupNotificationCleanup(
    notification: NotificationConfig,
    cleanup: (value: NotificationConfig) => void,
  ) {
    const { options } = notification;
    if (options.duration != NotificationDuration.infinite) {
      setTimeout(() => cleanup(notification), options.duration);
    }
  }
}
