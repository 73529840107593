import { InjectionKey } from 'vue';
import { ImageGroupItem } from '@/modules/images/types/imageGroupItem';
import { ApiPersonDetailOkRecordAvatarItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordAvatarItem';

export interface IPersonSocialNetworkAdditionalInfoOkAvatarsMappingService {
  mapSocialNetworkAdditionalInfoOkAvatars(
    avatars: Array<ApiPersonDetailOkRecordAvatarItem>,
  ): Array<ImageGroupItem>;
}

export const IPersonSocialNetworkAdditionalInfoOkAvatarsMappingServiceKey: InjectionKey<IPersonSocialNetworkAdditionalInfoOkAvatarsMappingService> =
  Symbol('IPersonSocialNetworkAdditionalInfoOkAvatarsMappingServiceKey');
