export module GuidUtils {
  export const emptyGuid = '00000000-0000-0000-0000-000000000000';

  export function isEmptyGuid(value: string): boolean {
    return value == emptyGuid;
  }

  export function generateGUID(): string {
    function generateId(count: number): string {
      let out = '';
      for (let i = 0; i < count; i++) {
        out += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      return out;
    }

    return [generateId(2), generateId(1), generateId(1), generateId(1), generateId(3)].join('-');
  }

  export function isEmptyOrNull(value?: string | null): boolean {
    return value == emptyGuid || value == undefined || value == '';
  }
}
