import { ICompanyProfileToVisibleColumnMapper } from '@/modules/dataGrid/company/services/mappers/companyProfileToColumnInfoMapper/companyProfileToColumnInfoMapper.interfaces';
import { DxTableColumnInfo } from '@/modules/devExtreme/types/dxTableColumnInfo';
import { injectable } from 'inversify';
import { ApiDataTableFieldProfile } from '@/modules/profile/types/api/apiDataTableFieldProfile';
import { DxTableVisibleColumnConfig } from '@/modules/devExtreme/types/DxTableVisibleColumn';

@injectable()
export default class CompanyProfileToColumnInfoMapper implements ICompanyProfileToVisibleColumnMapper
{
  map(item: ApiDataTableFieldProfile): DxTableVisibleColumnConfig {
    return {
      dataField: this.getDataField(item.fieldCode),
      visible: true,
      visibleIndex: item.index,
    };
  }

  mapReverse(item: DxTableColumnInfo): ApiDataTableFieldProfile {
    return {
      fieldCode: this.getFieldCode(item.dataField),
      index: item.visibleIndex,
      sortOrder: 0,
      sortIndex: 0,
    };
  }

  private getDataField(field: string) {
    switch (field) {
      case 'commentary':
        return 'comment';
      case 'legal_address':
        return 'legalAddress';
      case 'principal_activity':
        return 'principalActivity';
      case 'company_lists':
        return 'companyLists';
      default:
        return field;
    }
  }

  private getFieldCode(field: string) {
    switch (field) {
      case 'comment':
        return 'commentary';
      case 'legalAddress':
        return 'legal_address';
      case 'principalActivity':
        return 'principal_activity';
      case 'companyLists':
        return 'company_lists';
      default:
        return field;
    }
  }
}
