import {
  RegulationsChecksHistoryItemDTO
} from '@/modules/regulationsCheck/common/types/dto/regulationChecksHistoryItemDTO';
import {
  RegulationsChecksHistoryItem
} from '@/modules/regulationsCheck/common/types/regulationsChecksHistoryItem';
import { InjectionKey } from 'vue';

export interface IRegulationChecksHistoryItemDTOMappingService {
  map(item: RegulationsChecksHistoryItem): RegulationsChecksHistoryItemDTO
  mapReverse(item: RegulationsChecksHistoryItemDTO): RegulationsChecksHistoryItem
}

export const IRegulationChecksHistoryItemDTOMappingServiceKey: InjectionKey<IRegulationChecksHistoryItemDTOMappingService> = Symbol('IRegulationChecksHistoryItemDTOMappingService');
