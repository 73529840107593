import { IProcessingSettingsService } from '@/modules/processingSettings/services/processingSettingsService.interface';
import { useProcessingSettingsStore } from '@/modules/processingSettings/store/processingSettingsStore';
import { Ref } from 'vue';
import { ProcessingSettings } from '@/modules/processingSettings/types/processingSettings';
import { storeToRefs } from 'pinia';
import { injectable } from 'inversify';

@injectable()
export default class ProcessingSettingsService implements IProcessingSettingsService {
  private processingSettingsStore;

  constructor() {
    this.processingSettingsStore = useProcessingSettingsStore();
  }

  get settings(): Ref<ProcessingSettings> {
    const { settings } = storeToRefs(this.processingSettingsStore);
    return settings;
  }
}
