import { MaskOptions } from 'maska';

export const displayPhoneMask: MaskOptions = {
  mask: '+7 (BBB) BBB-BB-BB',
  tokens: {
    B: { pattern: /[0-9]/ },
  },
};

export const editPhoneMask: MaskOptions = {
  mask: '+7 (BBB) BBB BB BB',
  tokens: {
    B: { pattern: /[0-9]/ },
  },
};

export const apiPhoneMask: MaskOptions = {
  mask: 'B(BBB)BBB-BB-BB',
  tokens: {
    B: { pattern: /[0-9]/ },
  },
};

export const phoneMaskPlaceholder = '+7 (---) --- -- --';
