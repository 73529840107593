import StatisticsAPI from '@/api/statistics';
import {
  ActionContext,
  ActionTree,
  MutationTree,
  Module,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
} from 'vuex';
import type { RootState } from '../../type';
import type { StatisticsState, Mutations } from './type';
import { MutationTypesEnum, ActionTypesEnum } from './type';

const moduleState: StatisticsState = {
  isLoad: false,
  personStatistics: {
    personsCountForCounterParties: [],
    risks: [
      { name: 'Нет информации', count: 1 },
      { name: 'Низкий', count: 1 },
      { name: 'Высокий', count: 1 },
      { name: 'Средний', count: 0 },
    ],
    employmentStatuses: [
      { name: 'Уволен', count: 1 },
      { name: 'Трудоустроен', count: 1 },
      { name: 'Нет информации', count: 1 },
    ],
    individualTypes: [
      { name: 'Сотрудник', count: 1 },
      { name: 'Иная персона', count: 1 },
    ],
  },
  companyStatistics: {
    risks: [
      { name: 'Нет информации', count: 1 },
      { name: 'Средний', count: 1 },
      { name: 'Высокий', count: 1 },
      { name: 'Низкий', count: 1 },
    ],
    counterPartyTypes: [
      { name: 'Не является контрагентом', count: 10 },
      { name: 'Является контрагентом', count: 5 },
      { name: 'Неизвестно', count: 1 },
    ],
    proceedTypes: [
      { name: 'Крупная', count: 11 },
      { name: 'Микро', count: 6 },
      { name: 'Малая', count: 6 },
      { name: 'Нет данных', count: 1 },
      { name: 'Средняя', count: 2 },
    ],
    personsCountForEmployers: [],
  },
};

const mutations: MutationTree<StatisticsState> & Mutations = {
  [MutationTypesEnum.SET_PERSON_STATISTICS](state, payload) {
    state.personStatistics = payload.personStatistics;
  },
  [MutationTypesEnum.SET_COMPANY_STATISTICS](state, payload) {
    state.companyStatistics = payload.companyStatistics;
  },
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<StatisticsState, RootState>, 'commit'>;

interface Actions {
  [ActionTypesEnum.GET_STATISTICS]({ commit }: AugmentedActionContext): void;
}

const actions: ActionTree<StatisticsState, RootState> & Actions = {
  async [ActionTypesEnum.GET_STATISTICS]({ commit, state }) {
    /*const {
      data: { data },
    } = await StatisticsAPI.getGlobalEntities();
    const result = data;
    state.isLoad = true;
    commit(MutationTypesEnum.SET_PERSON_STATISTICS, { personStatistics: result.persons });
    commit(MutationTypesEnum.SET_COMPANY_STATISTICS, { companyStatistics: result.ul });*/
  },
};

export type StatisticsStore<S = StatisticsState> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions,
  ): ReturnType<Actions[K]>;
};

export const StatisticsModule: Module<StatisticsState, RootState> = {
  state: moduleState,
  mutations,
  actions,
};
