import {
  IEnvironmentVariables,
  IEnvironmentVariablesKey,
} from '@/modules/common/settings/environmentVariables.interfaces';
import { IReportsSettings } from '@/modules/reports/common/settings/reportsSettings.interfaces';
import { ReportsVersions } from '@/modules/reports/desktop/types/reportsVersions';
import { inject, injectable } from 'inversify';

@injectable()
export default class ReportsSettings implements IReportsSettings {
  constructor(
    @inject(IEnvironmentVariablesKey as symbol) private environmentVariables: IEnvironmentVariables,
  ) {}

  get version(): ReportsVersions {
    switch (this.environmentVariables.VUE_APP_REPORTS_VERSION) {
      case 'v2':
        return ReportsVersions.v2;
      default:
        return ReportsVersions.v1;
    }
  }
}
