import { injectable } from 'inversify';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { IPersonContactsPhonesFioPhoneBondVerificationService } from '@/modules/dossier/person/contacts/services/personContactsPhonesFioPhoneBondVerificationService.interface';
import { PersonContactsPhonesFioPhoneBondVerificationTag } from '@/modules/dossier/person/contacts/types/personContactsPhonesFioPhoneBondVerificationTag';
import { FioPhoneBondVerificationResultCodeEnum } from '@/modules/dossier/person/contacts/types/fioPhoneBondVerificationResultCodeEnum';

@injectable()
export default class PersonContactsPhonesFioPhoneBondVerificationService
  implements IPersonContactsPhonesFioPhoneBondVerificationService
{
  formatFioPhoneBondVerificationCode(
    status?: number,
  ): PersonContactsPhonesFioPhoneBondVerificationTag | undefined {
    switch (status) {
      case FioPhoneBondVerificationResultCodeEnum.fail:
        return {
          iconName: SystemIconsNames.uiV3Error,
          type: 'error',
          title: 'Не подтверждена',
        };
      case FioPhoneBondVerificationResultCodeEnum.success:
        return {
          iconName: SystemIconsNames.uiV3CheckRoundedStroke,
          type: 'success',
          title: 'Подтверждена',
        };
      default:
        return {
          iconName: SystemIconsNames.uiV3Warning,
          type: 'warning',
          title: 'Неизвестно',
        };
    }
  }
}
