import { injectable } from 'inversify';
import { ITaxesService } from '@/modules/taxes/services/taxesService.interfaces';
import { CompanyPaidTaxesList } from '@/modules/dossier/company/common/types/CompanyPaidTaxesList';

@injectable()
export default class TaxesService implements ITaxesService {
  private year = new Date().getFullYear();

  getTaxesPaidSum(taxes: CompanyPaidTaxesList[]): number {
    return taxes
      .filter(({ year }) => [this.year, this.year - 1, this.year - 2].includes(year))
      .reduce((acc, { taxPaidSum }) => (acc += taxPaidSum), 0);
  }
}
