import { InjectionKey } from 'vue';
import { ManualImportCreatePersonModel } from '@/modules/import/types/person/manualImportCreatePersonModel';
import { ApiManualImportCreatePerson } from '@/modules/import/types/api/person/apiManualImportCreatePerson';

export interface IManualImportCreatePersonModelMapper {
  map(item: ManualImportCreatePersonModel): ApiManualImportCreatePerson;
}

export const IManualImportCreatePersonModelMapperKey: InjectionKey<IManualImportCreatePersonModelMapper> =
  Symbol('IManualImportCreatePersonModelMapper');
