import {
  IMappedTag,
  IPassportTypeMapper,
} from '@/modules/dossier/person/common/services/mappers/passportTypeMapper.interfaces';
import { PassportTypes } from '@/modules/dossier/person/common/types/api/passportTypes';
import { injectable } from 'inversify';

@injectable()
export default class PassportTypeMapper implements IPassportTypeMapper {
  format(status: PassportTypes): IMappedTag {
    switch (status) {
      case PassportTypes.Actual:
        return {
          type: 'success',
          label: 'Актуальный',
        };
      case PassportTypes.Old:
        return {
          type: 'danger',
          label: 'Недействительный',
        };
      default:
        return {
          type: 'warning',
          label: 'Неизвестно',
        };
    }
  }
}
