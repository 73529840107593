import { MaybeRefFields } from '@/modules/common/types/maybeRefFields';
import { UseProgressNotificationConfig } from '@/modules/notifications/composables/useProgressNotification.interfaces';
import { useNotificationStore } from '@/modules/notifications/stores/notificationStore';
import { Notification } from '@/modules/notifications/types/notification';
import { NotificationDuration } from '@/modules/notifications/types/notificationDuration';
import UIProgressSnackBar from '@/modules/snacks/UISnackBar/UIProgressSnackBar.vue';

export function useProgressNotification() {
  const notificationStore = useNotificationStore();

  function fireNotification(config: MaybeRefFields<UseProgressNotificationConfig>): Notification {
    return notificationStore.fireNotification({
      component: UIProgressSnackBar,
      config,
      options: {
        duration: NotificationDuration.infinite,
        onClose: config.onClose,
      },
    });
  }

  return {
    fireNotification,
  };
}
