import {
  DxPdfExportConfiguration
} from '@/modules/devExtreme/export/pdf/configuration/dxPdfExportConfiguration';
import {
  IDxPdfCellFormatter
} from '@/modules/devExtreme/export/pdf/services/dxPdfCellFormatter/dxPdfCellFormatter.interfaces';
import { DxTableExtendedColumn } from '@/modules/devExtreme/types/dxTableExtendedColumn';
import { Cell, PdfDataGridCell } from 'devextreme/pdf_exporter';
import { injectable } from 'inversify';

@injectable()
export default class DxPdfHeaderCellFormatter implements IDxPdfCellFormatter {
  cellType = 'header'

  format(pdfCell: Cell, gridCell: PdfDataGridCell, dxColumn: DxTableExtendedColumn) {
    pdfCell.font = {
      size: DxPdfExportConfiguration.defaultFontSize,
      style: 'bold'
    }

    pdfCell.horizontalAlign = DxPdfExportConfiguration.defaultHorizontalAlignment;
    pdfCell.verticalAlign = DxPdfExportConfiguration.defaultVerticalAlignment;
  }
}
