import { InjectionKey } from 'vue';
import { DxDataSourceFilter } from '@/modules/devExtreme/filters/types/DxDataSourceFilter';
import type DxRichFilter from '@/modules/devExtreme/filters/models/dxRichFilter';

export interface IDxDataSourceFilterFactory {
  buildFilter(group: DxRichFilter): Array<DxDataSourceFilter> | undefined;
}

export const IDxDataSourceFilterFactoryKey: InjectionKey<IDxDataSourceFilterFactory> = Symbol(
  'IDxDataSourceFilterFactoryKey',
);
