import { injectable } from 'inversify';
import { IDxDataSourceFilterFactory } from '@/modules/devExtreme/components/UITableDx/factories/dxDataSourceFilterFactory.interfaces';
import { DxDataSourceFilter } from '@/modules/devExtreme/filters/types/DxDataSourceFilter';
import { DxFilterObject } from '@/modules/devExtreme/filters/types/DxFilterObject';
import DxRichFilter from '@/modules/devExtreme/filters/models/dxRichFilter';
import { DxUtils } from '@/modules/devExtreme/utils/dxUtils';
import { DxDataSourceFilterUtils } from '@/modules/devExtreme/components/UITableDx/utils/dxDataSourceFilterUtils';
import { DxRichFilterItem } from '@/modules/devExtreme/filters/types/DxRichFilterItem';

@injectable()
export default class DxDataSourceFilterFactory implements IDxDataSourceFilterFactory {
  buildFilter(group: DxRichFilter): Array<DxDataSourceFilter> | undefined {
    const filter = group.items.reduce((result, item) => {
      const currentFilter = this.buildFilterItem(item);

      if (currentFilter != undefined) {
        if (result.length != 0) {
          result.push(group.operation);
        }
        result.push(currentFilter);
      }

      return result;
    }, new Array<DxDataSourceFilter>());

    return filter.length > 0 ? filter : undefined;
  }

  private buildFilterItem(item: DxRichFilterItem): DxDataSourceFilter | undefined {
    if (item instanceof DxRichFilter && item.items.length > 0) {
      return this.buildFilter(item);
    }

    if (DxDataSourceFilterUtils.isFilterObject(item)) {
      return this.buildFilterObject(item);
    }

    if (DxDataSourceFilterUtils.isFilterFunction(item)) {
      return [item];
    }

    return undefined;
  }

  private buildFilterObject(item: DxFilterObject): DxDataSourceFilter {
    return [item.dataField, DxUtils.convertDxCompareOperationsToString(item.compare), item.value];
  }
}
