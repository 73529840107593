import {
  IDxCellFormatter
} from '@/modules/devExtreme/export/common/services/dxCellFormatter/dxCellFormatter.interfaces';
import { ExcelDataGridCell } from 'devextreme/excel_exporter';
import { InjectionKey } from 'vue';

export interface IDxXlsxCellFormatter extends IDxCellFormatter<any, ExcelDataGridCell> {
}

export const IDxXlsxCellFormatterKey: InjectionKey<IDxXlsxCellFormatter> = Symbol('IDxXlsxCellFormatter');
