export enum ExternalParserOptionsGroupType {
  Unknown = 0,
  Cronos = 1,
  Av100 = 2,
  Mvd = 3,
  FNS = 4,
  Xneo = 5,
  Damia = 6,
  Odyssey = 7,
  Idx = 8,
  KonturFocus = 9,
  Bfo = 10,
  VK = 11,
  OK = 12
}
