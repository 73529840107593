import {
  IAnalysisRelationClusterTypeFormatterService
} from '@/modules/analysis/services/analysisRelationClusterTypeFormatterService.interfaces';
import { AnalysisRelationClusterType } from '@/modules/analysis/types/analysisRelationClusterType';
import { injectable } from 'inversify';

@injectable()
export default class AnalysisRelationClusterTypeFormatterService implements IAnalysisRelationClusterTypeFormatterService {
  format(clusterType: AnalysisRelationClusterType): string {
    switch (clusterType) {
      case AnalysisRelationClusterType.jobs: return 'Трудовая деятельность';
      case AnalysisRelationClusterType.affiliation: return 'Аффилированность';
      case AnalysisRelationClusterType.relatedPersons: return 'Связанные лица';
      case AnalysisRelationClusterType.movableProperty: return 'Движимое имущество';
      case AnalysisRelationClusterType.socialNetworks: return 'Социальные сети';
      case AnalysisRelationClusterType.birthplace: return 'Место рождения';
      case AnalysisRelationClusterType.contacts: return 'Контактная информация';
      case AnalysisRelationClusterType.movements: return 'Перемещения';
      case AnalysisRelationClusterType.education: return 'Образование';
      default: return 'Неизвестно';
    }
  }
}
