import { injectable } from 'inversify';
import { IPersonRoadAccidentsMappingService } from '@/modules/dossier/person/roadAccidents/services/personRoadAccidentsMappingService.interface';
import { DateUtils } from '@/utils/dateUtils';
import { RoadAccidentAnalyticTableDataResult } from '@/modules/dossier/person/roadAccidents/types/roadAccidentAnalyticTableDataResult';
import uniq from 'lodash/uniq';
import { ApiPersonDetailRoadAccidentRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailRoadAccidentRecord';
import { RoadAccidentsVinTableDataResult } from '@/modules/dossier/person/roadAccidents/types/roadAccidentsVinTableData';
import { DateFormatConfiguration } from '@/configuration/dateFormatConfiguration';

@injectable()
export default class PersonRoadAccidentsMappingService
  implements IPersonRoadAccidentsMappingService
{
  getUniqRoadAccidentsDatesByVin(
    accidentList: Array<ApiPersonDetailRoadAccidentRecord>,
    vin: string,
  ) {
    const accidentsDates = accidentList
      .filter((accident) => accident.vin == vin)
      .map((accident) => DateUtils.formatDate(accident.date, DateFormatConfiguration.year))
      .sort((a, b) => {
        if (a > b) {
          return 1;
        }
        return -1;
      });
    return uniq(accidentsDates);
  }

  getRoadAccidentsVinTableData(
    accidentList: Array<ApiPersonDetailRoadAccidentRecord>,
    vin: string,
    date: string,
  ): Array<RoadAccidentsVinTableDataResult> {
    function getIsOwner(relationToVehicleType: number) {
      if (relationToVehicleType == 1) {
        return 'Да';
      }
      return 'Нет';
    }

    return accidentList
      .filter(
        (accident) => accident.vin == vin && DateUtils.formatDate(accident.date, DateFormatConfiguration.year) == date,
      )
      .map((accident) => ({
        type: accident.type,
        gosNumber: accident.gosNumber,
        date: accident.date,
        region: accident.region,
        schemaUrl: accident.schemaUrl,
        relationToVehicleType: getIsOwner(accident.relationToVehicleType),
      }));
  }

  getRoadAccidentAnalyticTableData(
    accidentList: Array<ApiPersonDetailRoadAccidentRecord>,
  ): Array<RoadAccidentAnalyticTableDataResult> {
    const accidentGroups = accidentList.reduce((acc, curr) => {
      const date = DateUtils.formatDate(curr.date, DateFormatConfiguration.isoDate);

      if (acc[date] != undefined) {
        acc[date].count = `${+acc[date].count + 1}`;
      } else {
        acc[date] = { count: '1', date };
      }

      return acc;
    }, {} as Record<string, RoadAccidentAnalyticTableDataResult>);

    return Object.values(accidentGroups);
  }
}
