import { InjectionKey } from 'vue';
import { TDetailsCompanyContactsList } from '@/types/companyData/companyData';

export interface ICompanyContactsMappingService {
  getMailsTableData(
    contacts: Array<TDetailsCompanyContactsList>,
  ): Array<TDetailsCompanyContactsList>;
  getPhonesTableData(
    contacts: Array<TDetailsCompanyContactsList>,
  ): Array<TDetailsCompanyContactsList>;
}

export const ICompanyContactsMappingServiceKey: InjectionKey<ICompanyContactsMappingService> =
  Symbol('ICompanyContactsMappingServiceKey');
