import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import {
  bindAnalysisUnityGraphServices
} from '@/modules/graph/analysisUnity/configuration/inversify/container.analysisUnity';
import ChainsService from '@/modules/graph/common/services/chainsService';
import { IChainsServiceKey } from '@/modules/graph/common/services/chainsService.interfaces';
import GraphSettings from '@/modules/graph/common/settings/graphSettings';
import { IGraphSettingsKey } from '@/modules/graph/common/settings/graphSettings.interfaces';
import {
  bindStandaloneUnityServices
} from '@/modules/graph/standaloneUnity/configuration/inversify/container.standaloneUnity';
import {
  bindTableGraphServices
} from '@/modules/graph/table/configuration/inversify/configuration.tableGraph';
import {
  bindUnityGraphServices
} from '@/modules/graph/unity/configuration/inversify/container.unityGraph';

export function bindGraphServices(bind: IContainerBinder) {
  bindTableGraphServices(bind);
  bindUnityGraphServices(bind);
  bindAnalysisUnityGraphServices(bind);
  bindStandaloneUnityServices(bind);

  bind(IGraphSettingsKey).to(GraphSettings)
  bind(IChainsServiceKey).to(ChainsService);
}
