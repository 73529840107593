import { DxJoinOperation } from '@/modules/devExtreme/types/DxJoinOperation';
import { DxRichFilterItem } from '@/modules/devExtreme/filters/types/DxRichFilterItem';

export default class DxRichFilter {
  static create(operation: DxJoinOperation, items: Array<DxRichFilterItem | undefined>) {
    const filledItems = <Array<DxRichFilterItem>>items.filter((item) => item != undefined);
    return new DxRichFilter(operation, filledItems);
  }

  static and(...items: Array<DxRichFilterItem | undefined>) {
    return this.create('and', items);
  }

  static or(...items: Array<DxRichFilterItem | undefined>) {
    return this.create('or', items);
  }

  constructor(public operation: DxJoinOperation, public items: Array<DxRichFilterItem>) {}
}
