import {
  IDxPdfExportService
} from '@/modules/devExtreme/export/pdf/services/dxPdfExportService/dxPdfExportService.interfaces';
import { DxPdfOrientation } from '@/modules/devExtreme/export/pdf/types/dxPdfOrientation';
import { InjectionKey } from 'vue';

export interface IDxPdfExportServiceFactory {
  getPdfExportService(request: IDxPdfExportServiceFactoryRequest): IDxPdfExportService

}

export interface IDxPdfExportServiceFactoryRequest {
  orientation: DxPdfOrientation
}

export const IDxPdfExportServiceFactoryKey: InjectionKey<IDxPdfExportServiceFactory> = Symbol('IDxPdfExportServiceFactory');
