export enum PersonSocialNetworkAdditionalInfoVKRecordKeys {
  infoExist = 'infoExist',
  userId = 'user_id',
  firstName = 'firstname',
  lastName = 'lastname',
  nickName = 'nickname',
  maidenName = 'maiden_name',
  birthdate = 'birthdate',
  gender = 'gender',
  status = 'status',
  country = 'country',
  city = 'city',
  homeCity = 'home_city',
  languages = 'languages',
  phone = 'phone',
  site = 'site',
  skype = 'skype',
  maritalStatus = 'marital_status',
  partnerUrl = 'partner_url',
  partnerFirstname = 'partner_firstname',
  partnerLastname = 'partner_lastname',
  activities = 'activities',
  interests = 'interests',
  music = 'music',
  movies = 'movies',
  tv = 'tv',
  books = 'books',
  games = 'games',
  quotes = 'quotes',
  about = 'about',
  alcohol = 'alcohol',
  smoking = 'smoking',
  lifeMain = 'life_main',
  peopleMain = 'people_main',
  political = 'political',
  religion = 'religion',
  inspiredBy = 'inspired_by',
  avatars = 'avatars',
  photos = 'photos',
  schools = 'schools',
  universities = 'universities',
  career = 'career',
  military = 'military',
  isOnlyIds = 'is_only_ids',
  isRelativesExist = 'is_relatives_exist',
  isFriendsExist = 'is_friends_exist',
  isFollowersExist = 'is_followers_exist',
  isLikersExist = 'is_likers_exist',
  isCommentatorsExist = 'is_commentators_exist',
  isMentionsExist = 'is_mentions_exist',
  isTaggedExist = 'is_tagged_exist',
  isWhoTaggedExist = 'is_who_tagged_exist',
  profileUrl = 'profile_url',
  relatives = 'relatives',
  friendsIds = 'friends_ids',
  followersIds = 'followers_ids',
  likersIds = 'likers_ids',
  commentatorsIds = 'commentators_ids',
  mentionsIds = 'mentions_ids',
  taggedIds = 'tagged_ids',
  whoTaggedIds = 'who_tagged_ids',
  relativesUsers = 'relatives_users',
  friends = 'friends',
  followers = 'followers',
  likers = 'likers',
  commentators = 'commentators',
  mentions = 'mentions',
  tagged = 'tagged',
  whoTagged = 'who_tagged',
}
