import { InjectionKey } from 'vue';
import { PersonNegativeOverviewStatisticsTableRow } from '@/modules/dossier/person/negativeOverview/types/personNegativeOverviewStatisticsTableRow';
import { ApiPersonDetailNegativeInfoRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailNegativeInfoRecord';
import { UseDxTagConfig } from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';

export interface IPersonNegativeOverviewMappingService {
  mapNegativeOverviewStatisticsTableData(
    negativeMentions: Array<ApiPersonDetailNegativeInfoRecord>,
  ): Array<PersonNegativeOverviewStatisticsTableRow>;
  mapNegativeClarificationValue(value?: boolean): UseDxTagConfig;
}

export const IPersonNegativeOverviewMappingServiceKey: InjectionKey<IPersonNegativeOverviewMappingService> =
  Symbol('IPersonNegativeOverviewMappingServiceKey');
