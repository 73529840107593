import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { INumberFormatterServiceKey } from '@/modules/common/modules/numbers/services/numberFormatterService/numberFormatterService.interfaces';
import NumberFormatterService from '@/modules/common/modules/numbers/services/numberFormatterService/numberFormatterService';
import { INumberTruncationServiceKey } from '@/modules/common/modules/numbers/services/numberTruncationService/numberTruncationService.interfaces';
import NumberTruncationService from '@/modules/common/modules/numbers/services/numberTruncationService/numberTruncationService';
import { INumberParserServiceKey } from '@/modules/common/modules/numbers/services/numberParserService/numberParserService.interfaces';
import NumberParserService from '@/modules/common/modules/numbers/services/numberParserService/numberParserService';

export function bindNumberServices(bind: IContainerBinder) {
  bind(INumberFormatterServiceKey).to(NumberFormatterService);
  bind(INumberTruncationServiceKey).to(NumberTruncationService);
  bind(INumberParserServiceKey).to(NumberParserService);
}
