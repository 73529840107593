<template>
  <inline-svg
    v-if="hasSrc"
    :src="iconSrc"
    class="ui-icon"
    :class="rootModifiers"
    :transformSource="transformSvg"
  />
</template>

<script lang="ts" setup>
import { UIIconSize } from '@/ui-components/UIIcon/types/UIIconSize';
import { ref, computed, watchEffect } from 'vue';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { IconsUtils } from '@/utils/iconsUtils';
import { useUIIconSvgTransform } from '@/ui-components/UIIcon/composable/useUIIconSvgTransform';

interface Props {
  name: SystemIconsNames | undefined;
  size?: UIIconSize;
  clickable?: boolean;
  rotate?: number;
  translateX: string;
  translateY: string;
}

const props = withDefaults(defineProps<Props>(), {
  clickable: false,
  translateX: '0',
  translateY: '0',
});

const { transformSvg } = useUIIconSvgTransform();

const iconName = computed(() => props.name);
const iconSrc = ref<string>();
const hasSrc = computed(() => iconSrc.value != undefined);

const rootBaseModifiers = computed(() => ({
  'ui-icon--clickable': props.clickable,
}));

const rootSizeModifiers = computed(() =>
  props.size != undefined ? `ui-icon--size-${props.size}` : '',
);

const rootModifiers = computed(() => [rootBaseModifiers.value, rootSizeModifiers.value]);

const transform = computed(() => {
  const transformValueArr = [];
  if (props.rotate != undefined) {
    transformValueArr.push(`rotate(${props.rotate}deg)`);
  }
  if (props.translateX != '0' || props.translateY != '0') {
    transformValueArr.push(`translate(${props.translateX}, ${props.translateY})`);
  }
  return transformValueArr.join(' ');
});

function getIconSrc(name?: SystemIconsNames): string | undefined {
  if (name == undefined) {
    return undefined;
  }

  const iconPath = IconsUtils.getIconPath(name);

  if (iconPath == undefined) {
    return;
  }

  try {
    return require(`@/assets/icons/${iconPath}.svg`);
  } catch (error) {
    console.error(error);
  }
}

watchEffect(() => {
  iconSrc.value = getIconSrc(iconName.value);
});
</script>

<style lang="scss">
.ui-icon {
  display: inline-flex;

  &.ui-icon--size-small {
    width: 16px;
    height: auto;
  }

  &.ui-icon--size-middle {
    width: 20px;
    height: auto;
  }

  &.ui-icon--size-large {
    width: 24px;
    height: auto;
  }

  &.ui-icon--size-extra-large {
    width: 30px;
    height: auto;
  }

  &.ui-icon--clickable {
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
.ui-icon {
  transform: v-bind(transform);
}
</style>
