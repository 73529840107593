import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IPersonIntegrityScoringFormatterServiceKey } from '@/modules/dossier/person/personIntegrityScoring/services/personIntegrityScoringFormatterService.interface';
import PersonIntegrityScoringFormatterService from '@/modules/dossier/person/personIntegrityScoring/services/personIntegrityScoringFormatterService';
import { IPersonIntegrityScoringsIndicatorServiceKey } from '@/modules/dossier/person/personIntegrityScoring/services/personIntegrityScoringIndicatorService.interface';
import PersonIntegrityScoringIndicatorService from '@/modules/dossier/person/personIntegrityScoring/services/personIntegrityScoringIndicatorService';

export function bindPersonIntegrityScoringsServices(bind: IContainerBinder) {
  bind(IPersonIntegrityScoringFormatterServiceKey).to(PersonIntegrityScoringFormatterService);
  bind(IPersonIntegrityScoringsIndicatorServiceKey).to(PersonIntegrityScoringIndicatorService);
}
