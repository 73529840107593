import { IDaDataSettings } from '@/modules/common/settings/daDataSettings.interfaces';
import {
  IEnvironmentVariables, IEnvironmentVariablesKey
} from '@/modules/common/settings/environmentVariables.interfaces';
import { inject, injectable } from 'inversify';

@injectable()
export default class DaDataSettings implements IDaDataSettings {
  constructor(
    @inject(IEnvironmentVariablesKey as symbol) private environmentVariables: IEnvironmentVariables
  ) {
  }

  get baseUrl() {
    return this.environmentVariables.VUE_APP_DADATA_BASE_URL;
  }

  get secret() {
    return this.environmentVariables.VUE_APP_DADATA_SECRET;
  }

  get token() {
    return this.environmentVariables.VUE_APP_DADATA_TOKEN;
  }
}
