import { InjectionKey } from 'vue';
import { ApiPersonDetailPledgeMemberInfoRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailPledgeMemberInfoRecord';

export interface IPersonPledgesRegistriesScoringsPledgersFormatter {
  getPledgersFormattedNames(
    pledgers: Array<ApiPersonDetailPledgeMemberInfoRecord>,
    personFullName: string,
  ): string;
}

export const IPersonPledgesRegistriesScoringsPledgersFormatterKey: InjectionKey<IPersonPledgesRegistriesScoringsPledgersFormatter> =
  Symbol('IPersonPledgesRegistriesScoringsPledgersFormatterKey');
