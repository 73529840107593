export enum AnalysisRelationType {
  ZupBirthplace = 0,
  ZupRegistryAddress = 1,
  ZupActualAddress = 2,
  ZupOvd = 3,
  ZupEducation = 4,
  ZupSpecialty = 5,
  ZupCompany = 6,
  ZupDepartment = 7,
  ZupPosition = 8,
  ZupRelative = 9,

  BusinessTrips = 10,
  VehicleParkings = 11,
  Vehicles = 12,

  SocialFriends = 13,
  ReciprocalSocialFriends = 14,

  EducationWithPeriod = 50,
  JobsWithPeriod = 51,

  ParksNotOwner = 100,
  ParksDifferentsDriver = 101,

  Avia = 200,

  RelativeInn = 300,
  RelativeBirthday = 301,
  PhoneNumber = 94,
  Email = 95,

  /// <summary>
  /// связанный абонентский номер; (в связанных персонах по номерам)
  /// </summary>
  RelatedPhoneNumber = 500,

  /// <summary>
  /// перемещения по пропускам в COVID
  /// </summary>
  CovidMovements = 501,

  /// <summary>
  /// сотрудники (например, для двух юридических лиц); [из одиссеи]
  /// </summary>
  RelatedEmployees = 502,

  // просто адрес, может быть что угодно в пределах данных пользователя
  Address = 1000,
  AddressToHouse = 1001,

  /// <summary>
  /// Учредители
  /// </summary>
  CompanyFounder = 5000,
  /// <summary>
  /// Руководители
  /// </summary>
  CompanyHead = 5001,

  /// <summary>
  /// исторические учредители
  /// </summary>
  CompanyFounderHistory = 5002,
  /// <summary>
  /// исторические руководители
  /// </summary>
  CompanyHeadHistory = 5003,

  /// <summary>
  /// Места работы, которые связывают вершины фл и юл
  /// </summary>
  Jobs = 5100,

  /// <summary>
  /// Индивидуальный предприниматель
  /// </summary>
  IndividualEntrepreneur = 5200,
}
