<template>
  <UIDialog :title="title" :quicksell="quicksell" :busy="isBusy">
    <template #header-controls>
      <slot name="header-controls" :cancel="cancel" />
    </template>

    <template #footer>
      <slot name="footer" :confirm="confirm" :cancel="cancel" />
    </template>
  </UIDialog>
</template>

<script setup lang="ts">
import UIDialog from '@/modules/dialogs/components/UIDialog/UIDialog.vue';
import { MaybePromise } from '@/modules/common/types/maybePromise';
import { shallowRef } from 'vue';

interface Props {
  title: string;
  quicksell?: string;
  confirm?: () => MaybePromise<void>;
  cancel?: () => MaybePromise<void>;
}
interface Emits {
  (e: 'close'): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isBusy = shallowRef(false);

async function executeAction(action: () => MaybePromise<void>) {
  if (isBusy.value) {
    return;
  }

  isBusy.value = true;

  try {
    await action();
  } finally {
    isBusy.value = false;
  }
}

function close() {
  emits('close');
}

async function cancel() {
  await executeAction(() => props.cancel?.());
  close();
}

async function confirm() {
  await executeAction(() => props.confirm?.());
  close();
}
</script>

<style lang="scss"></style>
