import { useInjectable } from '@/hooks/useInjectable';
import { ApiGridColumnInput } from '@/modules/dataGrid/common/types/api/apiGridColumnInput';
import { ApiGridInput } from '@/modules/dataGrid/common/types/api/apiGridInput';
import {
  ICompanyDataGridServiceKey
} from '@/modules/dataGrid/company/services/companyDataGridService.interfaces';
import {
  IPersonDataGridServiceKey
} from '@/modules/dataGrid/person/services/personDataGridService.interfaces';
import {
  IDataTableSettingsServiceKey
} from '@/modules/profile/services/dataTableSettingsService.interfaces';
import { DataTableConfigProfiles } from '@/modules/profile/types/api/dataTableConfigProfile';
import {
  ActionContext,
  ActionTree,
  MutationTree,
  Module,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
  GetterTree,
} from 'vuex';
import {
  MutationTypesEnum, ActionTypesEnum, TTypeGrid,
} from '@/store/modules/GridMain/type';
import type { RootState } from '../../type';
import type { GridMainState, Mutations, Getters } from './type';

const moduleState: GridMainState = {
  companysData: [],
  personsData: [],
  companySettings: [],
  personSettings: [],
  allCompanysData: [],
  allPersonsData: [],
  uniqCompanysData: [],
  uniqPersonData: [],
  skip: 0,
  take: 12,
  companyDataCount: 0,
  personDataCount: 0,
  columnValues: [],
  applyedFilter: {
    filter: [],
    sort: [],
  },
};

const mutations: MutationTree<GridMainState> & Mutations = {
  [MutationTypesEnum.SET_COMPANY_DATA](state, { companysData }) {
    state.companysData = companysData;
  },
  [MutationTypesEnum.SET_COMPANY_SETTINGS](state, { companySettings }) {
    state.companySettings = companySettings;
  },
  [MutationTypesEnum.SET_PERSON_DATA](state, { personsData }) {
    state.personsData = personsData;
  },
  [MutationTypesEnum.SET_PERSON_SETTINGS](state, { personSettings }) {
    state.personSettings = personSettings;
  },
  [MutationTypesEnum.SET_DATA_COMPANY_COUNT](state, { companyDataCount }) {
    state.companyDataCount = companyDataCount;
  },
  [MutationTypesEnum.SET_DATA_PERSON_COUNT](state, { personDataCount }) {
    state.personDataCount = personDataCount;
  },
  [MutationTypesEnum.SET_SKIP](state, payload) {
    state.skip = payload;
  },
  [MutationTypesEnum.SET_TAKE](state, payload) {
    state.take = payload;
  },
  [MutationTypesEnum.SET_ALL_DATA_PERSONS](state, payload) {
    state.allPersonsData = payload;
  },
  [MutationTypesEnum.SET_ALL_DATA_COMPANY](state, payload) {
    state.allCompanysData = payload;
  },
  [MutationTypesEnum.SET_UNIQ_DATA_COMPANY](state, payload) {
    state.uniqCompanysData = payload;
  },
  [MutationTypesEnum.SET_UNIQ_DATA_PERSONS](state, payload) {
    state.uniqPersonData = payload;
  },
  [MutationTypesEnum.SET_COLUMN_VALUES](state, payload) {
    state.columnValues = payload;
  },
  [MutationTypesEnum.SET_APPLYED_FILTER](state, payload) {
    state.applyedFilter = payload;
  },
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<GridMainState, RootState>, 'commit'>;

interface Actions {
  [ActionTypesEnum.GET_COMPANY_DATA](
    { commit }: AugmentedActionContext,
    payload: ApiGridInput,
  ): void;
  [ActionTypesEnum.GET_COMPANY_SETTINGS]({ commit }: AugmentedActionContext): void;
  [ActionTypesEnum.GET_PERSON_DATA](
    { commit }: AugmentedActionContext,
    payload: ApiGridInput,
  ): void;
  [ActionTypesEnum.GET_PERSON_SETTINGS]({ commit }: AugmentedActionContext): void;
  [ActionTypesEnum.GET_COLUMN_VALUES](
    { commit }: AugmentedActionContext,
    payload: { name: TTypeGrid; payload: ApiGridColumnInput },
  ): void;
}

const actions: ActionTree<GridMainState, RootState> & Actions = {
  async [ActionTypesEnum.GET_COMPANY_DATA]({ commit, state }, payload) {
    const {
      data: { data },
    } = await useInjectable(ICompanyDataGridServiceKey).getContent(payload);
    const items = data.items;
    const prevIds = state.companysData.map(({ id }) => id);
    const result = [...state.uniqCompanysData, ...items.filter(({ id }) => !prevIds.includes(id))];
    const { totalCount } = data;

    commit(MutationTypesEnum.SET_COMPANY_DATA, { companysData: items });
    commit(MutationTypesEnum.SET_UNIQ_DATA_COMPANY, result);
    commit(MutationTypesEnum.SET_DATA_COMPANY_COUNT, { companyDataCount: totalCount });
  },
  async [ActionTypesEnum.GET_PERSON_DATA]({ commit, state }, payload) {
    const {
      data: { data },
    } = await useInjectable(IPersonDataGridServiceKey).getContent(payload);
    const items = data.items;
    const prevIds = state.personsData.map(({ id }) => id);

    const result = [...state.uniqPersonData, ...items.filter(({ id }) => !prevIds.includes(id))];
    const { totalCount } = data;

    commit(MutationTypesEnum.SET_PERSON_DATA, { personsData: items });
    commit(MutationTypesEnum.SET_UNIQ_DATA_PERSONS, result);
    commit(MutationTypesEnum.SET_DATA_PERSON_COUNT, { personDataCount: totalCount });
  },
  async [ActionTypesEnum.GET_COMPANY_SETTINGS]({ commit }) {
    const dataTableSettingsService = useInjectable(IDataTableSettingsServiceKey);

    const {
      data: { data },
    } = await dataTableSettingsService.getSettings(DataTableConfigProfiles.Company);

    const result = data;

    commit(MutationTypesEnum.SET_COMPANY_SETTINGS, { companySettings: result });
  },
  async [ActionTypesEnum.GET_PERSON_SETTINGS]({ commit }) {
    const dataTableSettingsService = useInjectable(IDataTableSettingsServiceKey);

    const {
      data: { data },
    } = await dataTableSettingsService.getSettings(DataTableConfigProfiles.Person);

    const result = data;

    commit(MutationTypesEnum.SET_PERSON_SETTINGS, { personSettings: result });
  },
  async [ActionTypesEnum.GET_COLUMN_VALUES]({ commit, state }, { name, payload }) {
    const service = name == 'person' ?
      useInjectable(IPersonDataGridServiceKey) :
      useInjectable(ICompanyDataGridServiceKey);

    const {
      data: { data },
    } = await service.getColumnValues(payload);

    const result = data;

    commit(MutationTypesEnum.SET_COLUMN_VALUES, [...state.columnValues, ...result]);
  },
};

const getters: GetterTree<GridMainState, RootState> & Getters = {
  gridMainPersonPages: (state: GridMainState) => {
    const currentPagesCount = Math.ceil(state.personDataCount / state.take);

    return currentPagesCount <= 1 ? 1 : currentPagesCount;
  },
  gridMainCompanyPages: (state: GridMainState) => {
    const currentPagesCount = Math.ceil(state.companyDataCount / state.take);

    return currentPagesCount <= 1 ? 1 : currentPagesCount;
  },
};

export type GridMainStore<S = GridMainState> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions,
  ): ReturnType<Actions[K]>;
};

export const GridMainModule: Module<GridMainState, RootState> = {
  state: moduleState,
  mutations,
  actions,
  getters,
};
