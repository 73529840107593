import { useInjectable } from '@/hooks/useInjectable';
import { useTaskNotifications } from '@/modules/common/composables/useTaskNotifications/useTaskNotifications';
import { useUIConfirmation } from '@/modules/dialogs/components/UIConfirmation/composables/useUIConfirmation/useUIConfirmation';
import { IPluralMessagesServiceKey } from '@/modules/messages/plural/pluralMessagesService.interfaces';
import { ReportsApiServiceKey } from '@/modules/reports/common/services/reportsApiService.interface';

export function useReportsGenerationTasksInterruptionConfirmation() {
  const reportsApiService = useInjectable(ReportsApiServiceKey);
  const pluralMessagesService = useInjectable(IPluralMessagesServiceKey);

  const { showUIConfirmation } = useUIConfirmation();
  const { runTask } = useTaskNotifications();

  function getReportsCountLabel(count: number) {
    return pluralMessagesService.getMessage(count, {
      one: x => (x == 1 ? 'отчета' : `${x} отчета`),
      two: x => `${x} отчетов`,
      some: x => `${x} отчетов`,
    });
  }

  function getReportsCanceledSuccessLabel(isSingleTask: boolean) {
    return isSingleTask ? 'Отчет успешно отменен' : 'Отчеты успешно отменены';
  }

  function cancelReportGenerationTasks(taskIds: string[]) {
    const reportsCanceledSuccessLabel = getReportsCanceledSuccessLabel(taskIds.length == 1);

    runTask({
      action: () => reportsApiService.cancelReportGenerationTasks(taskIds),
      success: () => ({ title: reportsCanceledSuccessLabel }),
    });
  }

  function showReportsGenerationTasksInterruptionConfirmation(taskIds: string[]) {
    const reportsCountLabel = getReportsCountLabel(taskIds.length);

    showUIConfirmation({
      title: `Отмена генерации ${reportsCountLabel}`,
      quicksell: `Вы уверены, что хотите отменить генерацию ${reportsCountLabel}?`,
      confirmLabel: 'Продолжить',
      cancelLabel: 'Отмена',
      confirm: () => cancelReportGenerationTasks(taskIds),
    });
    return false;
  }

  return {
    showReportsGenerationTasksInterruptionConfirmation,
  };
}
