import { injectable } from 'inversify';
import { IAdministrationMarkersMappingService } from '@/modules/administration/services/mappers/administrationMarkersMappingService.interface';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { markerWeightsPersonGroups } from '@/modules/administration/configuration/markerWeightsPersonGroups';
import { markerWeightsCompanyGroups } from '@/modules/administration/configuration/markerWeightsCompanyGroups';
import { GroupNameResult } from '@/modules/administration/types/groupNameResult';
import { AdministrationMarkerWeightsGroup } from '@/modules/administration/types/administrationMarkerWeightsGroup';
import { WeightMarkerItem } from '@/modules/administration/types/weightMarkerItem';
import { StringUtils } from '@/modules/common/utils/stringUtils';

@injectable()
export default class AdministrationMarkersMappingService
  implements IAdministrationMarkersMappingService
{
  // eslint-disable-next-line class-methods-use-this
  protected getMarkerGroupData(
    marker: WeightMarkerItem,
    markerWeightsGroups: Array<AdministrationMarkerWeightsGroup>,
  ): GroupNameResult | undefined {
    const foundGroup = markerWeightsGroups.find((group) => {
      const result = group.list.find((i) =>
        StringUtils.checkEqualStrings(i, marker.presentName ?? ''),
      );
      return result != undefined;
    });

    if (foundGroup != undefined) {
      const { groupName, statusIcon, statusIconColor } = foundGroup;
      return { groupName, statusIcon, statusIconColor };
    }
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getGroupNameAndStatusIcon(marker: WeightMarkerItem): GroupNameResult {
    if (marker.presentName != undefined) {
      if (marker.ParentTypeStr == 'Компания') {
        const foundGroupData = this.getMarkerGroupData(marker, markerWeightsCompanyGroups);
        if (foundGroupData != undefined) {
          return foundGroupData;
        }
      } else if (marker.ParentTypeStr == 'Персона') {
        const foundGroupData = this.getMarkerGroupData(marker, markerWeightsPersonGroups);
        if (foundGroupData != undefined) {
          return foundGroupData;
        }
      }
    }

    return {
      groupName: 'Неизвестно',
      statusIcon: SystemIconsNames.unknown,
      statusIconColor: 'unknown',
    };
  }
}
