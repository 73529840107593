import {
  DxXlsxExportConfiguration
} from '@/modules/devExtreme/export/xlsx/configuration/dxXlsxExportConfiguration';
import {
  IDxXlsxWorksheetColumnsAligner
} from '@/modules/devExtreme/export/xlsx/services/dxXlsxWorksheetColumnsAligner/dxXlsxWorksheetColumnsAligner.interfaces';
import { Worksheet } from 'exceljs';
import { injectable } from 'inversify';

/** Determined this value based upon experimentation */
export const PIXELS_PER_EXCEL_WIDTH_UNIT = 7.5;

@injectable()
export default class DxXlsxWorksheetColumnsAligner implements IDxXlsxWorksheetColumnsAligner {
  alignColumns(worksheet: Worksheet) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (ctx == undefined)
      return;

    worksheet.columns.forEach((column: any) => {
      let columnWidth = 10;

      column["eachCell"]({ includeEmpty: true }, (cell: any) => {
        const fontName = cell?.font?.name ?? DxXlsxExportConfiguration.defaultFontName;
        const fontSize = cell.font?.size ?? DxXlsxExportConfiguration.defaultFontSize;
        const boldFont = (cell.font?.bold ?? false) ? 'bold' : '';

        ctx.font = `${boldFont} ${fontSize}pt ${fontName}`;

        const rows = (cell.value as string | undefined)?.split('\n') ?? [];
        const longestRow = rows.reduce((result, row) => row.length > result.length ? row : result, '');

        const metrics = ctx.measureText(longestRow);
        const contentWidth = metrics.width;

        if (contentWidth > columnWidth )
          columnWidth = contentWidth;
      });

      column.width = Math.ceil(columnWidth / PIXELS_PER_EXCEL_WIDTH_UNIT) + 1;
    });
  }
}
