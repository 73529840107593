import { useTasksTracking } from '@/modules/tasks/composables/useTasksTracking/useTasksTracking';
import { MaybeRef } from '@vueuse/core';
import { unref, watch } from 'vue';

export function useTrackedTasks(taskIds: MaybeRef<string[]>) {
  const { registerTask, unregisterTask, taskList } = useTasksTracking();

  watch(
    () => unref(taskIds),

    taskIds => {
      const tasksToRegister = taskIds.filter(
        taskId => !taskList.value.some(x => x.taskId == taskId),
      );

      const tasksToUnregister = taskList.value.filter(
        x => !taskIds.some(taskId => x.taskId == taskId),
      );

      tasksToRegister.forEach(x => registerTask(x));
      tasksToUnregister.forEach(x => unregisterTask(x.taskId));
    },

    { immediate: true },
  );

  return taskList;
}
