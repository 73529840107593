import { injectable, inject } from 'inversify';
import { ApiPersonDetailTrustScoringRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailTrustScoringRecord';
import { PersonIntegrityScoringRow } from '@/modules/dossier/person/personIntegrityScoring/types/personIntegrityScoringRow';
import { IPersonIntegrityScoringFormatterService } from './personIntegrityScoringFormatterService.interface';
import {
  IPersonIntegrityScoringsIndicatorService,
  IPersonIntegrityScoringsIndicatorServiceKey,
} from './personIntegrityScoringIndicatorService.interface';

@injectable()
export default class PersonIntegrityScoringsMappingService
  implements IPersonIntegrityScoringFormatterService
{
  constructor(
    @inject(IPersonIntegrityScoringsIndicatorServiceKey as symbol)
    private PersonIntegrityScoringsIndicatorService: IPersonIntegrityScoringsIndicatorService,
  ) {}

  prepareIntegrityScoringsTable(
    trust: ApiPersonDetailTrustScoringRecord,
  ): Array<PersonIntegrityScoringRow> {
    const incidents = Object.keys(trust)
      .filter((key) => /^c\d$/.test(key))
      .map((key) => ({
        incident: trust[`${key}Name` as keyof ApiPersonDetailTrustScoringRecord],
        indicator: trust[key as keyof ApiPersonDetailTrustScoringRecord],
      }));

    return incidents.map((incident) => ({
      incident: String(incident.incident),
      indicator: this.PersonIntegrityScoringsIndicatorService.getIntegrityScoringIndicator(
        String(incident.indicator),
      ),
    }));
  }
}
