import { DeepMaybeRef } from '@vueuse/shared';
import {
  _ExtractActionsFromSetupStore,
  _ExtractGettersFromSetupStore,
  _ExtractStateFromSetupStore,
  Store, StoreDefinition,
} from 'pinia';
import { shallowRef, unref, watch } from 'vue';

type DynamicStoreDefinition<Id extends string, SS> = StoreDefinition<Id, _ExtractStateFromSetupStore<SS>, _ExtractGettersFromSetupStore<SS>, _ExtractActionsFromSetupStore<SS>>
export type DynamicStore<Id extends string, SS> = Store<Id, _ExtractStateFromSetupStore<SS>, _ExtractGettersFromSetupStore<SS>, _ExtractActionsFromSetupStore<SS>>

export function defineDynamicStore<T extends any[], Id extends string, SS>(factory: (...args: T) => DynamicStoreDefinition<Id, SS>) {
  return (...args: DeepMaybeRef<T>): DynamicStore<Id, SS> => {
    const storeRef = shallowRef<DynamicStore<Id, SS>>();

    watch(() => args.map(x => unref(x)), args => {
      storeRef.value = factory(...args.map(x => unref(x)) as T)() as DynamicStore<Id, SS>;
    }, { immediate: true });

    const storeProxy: any = new Proxy(storeRef, {
      get(target, prop: string) {
        return (<any>target.value)[prop];
      },

      set(target, prop, value) {
        (<any>target.value)[prop] = value;
        return true;
      }
    });

    return storeProxy
  };
}
