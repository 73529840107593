<template>
  <button type="button" class="ui-button-v3" :class="buttonModifiers" @click="clickHandler">
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ButtonV3Shape } from '@/ui-components/UIButtonV3/types/buttonV3Shape';
import { ButtonV3Size } from '@/ui-components/UIButtonV3/types/buttonV3Size';
import { ButtonV3Theme } from '@/ui-components/UIButtonV3/types/buttonV3Theme';
import { ButtonV3Variant } from '@/ui-components/UIButtonV3/types/buttonV3Variant';

interface Props {
  shape?: ButtonV3Shape;
  size?: ButtonV3Size;
  theme?: ButtonV3Theme;
  variant?: ButtonV3Variant;
  disabled?: boolean;
  autogrow?: boolean;
}
interface Emits {
  (eventName: 'click'): void;
  (eventName: 'disabledClick'): void;
}

const props = withDefaults(defineProps<Props>(), {
  shape: 'capsule',
  theme: 'primary-light',
  size: 'large',
  variant: 'normal',
  disabled: false,
  autogrow: false,
});

const emits = defineEmits<Emits>();

function clickHandler() {
  if (props.disabled) {
    emits('disabledClick');
  } else {
    emits('click');
  }
}

const shapeClass = computed(() => {
  switch (props.shape) {
    case 'circle':
      return 'ui-button-v3--shape-circle';
    case 'rounded':
      return 'ui-button-v3--shape-rounded';
    case 'square':
      return 'ui-button-v3--shape-square';
    case 'capsule':
    default:
      return 'ui-button-v3--shape-capsule';
  }
});

const sizeClass = computed(() => {
  switch (props.size) {
    case 'small':
      return 'ui-button-v3--size-small';
    case 'large':
    default:
      return 'ui-button-v3--size-large';
  }
});

const themeClass = computed(() => {
  if (props.disabled) {
    return `ui-button-v3--disabled-${props.variant}`;
  }

  switch (props.theme) {
    case 'ghost-light':
    case 'ghost-dark':
    case 'primary-gradient':
    case 'gray':
    case 'secondary':
      return `ui-button-v3--theme-${props.theme}`;
    case 'primary-light':
    case 'primary-dark':
    case 'danger':
      return `ui-button-v3--theme-${props.theme}-${props.variant}`;
    default:
      return 'ui-button-v3--theme-primary-light-normal';
  }
});

const autoGrowModifier = computed(() => (props.autogrow ? 'ui-button-v3--autogrow' : ''));

const buttonModifiers = computed(() => [
  shapeClass.value,
  sizeClass.value,
  themeClass.value,
  autoGrowModifier.value,
]);
</script>

<style lang="scss">
.ui-button-v3 {
  width: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--color-transparent-1);
  border: 1px solid var(--color-transparent-1);
  white-space: nowrap;
  color: var(--color-white-1);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent; // Убирает подсветку кнопок при клике на мобилке

  &.ui-button-v3--shape-circle {
    border-radius: var(--border-radius-100);
  }

  &.ui-button-v3--shape-rounded,
  &.ui-button-v3--shape-square {
    border-radius: var(--border-radius-8);
  }

  &.ui-button-v3--shape-capsule {
    border-radius: var(--border-radius-100);
  }

  &.ui-button-v3--size-small {
    font-weight: var(--font-weight-450);
    font-size: var(--font-size-12);
    line-height: var(--font-line-height-16);

    &.ui-button-v3--shape-circle {
      padding: 8px;
    }

    &.ui-button-v3--shape-rounded {
      padding: 6px 8px;
    }

    &.ui-button-v3--shape-square {
      padding: 6px;
    }

    &.ui-button-v3--shape-capsule {
      padding: 8px 16px;
    }
  }

  &.ui-button-v3--size-large {
    font-weight: var(--font-weight-450);
    font-size: var(--font-size-16);
    line-height: var(--font-line-height-24);

    &.ui-button-v3--shape-circle {
      padding: 12px;
    }

    &.ui-button-v3--shape-rounded {
      padding: 8px 12px;
    }

    &.ui-button-v3--shape-square {
      padding: 8px;
    }

    &.ui-button-v3--shape-capsule {
      padding: 12px 24px;
    }
  }

  &.ui-button-v3--disabled-normal {
    background-color: var(--color-gray-5);
    opacity: 0.4;
  }

  &.ui-button-v3--disabled-outlined {
    color: var(--color-gray-5);
    border-color: var(--color-gray-5);
    filter: var(--shadow-1);
  }

  &.ui-button-v3--theme-primary-light-normal {
    background-color: var(--color-blue-1);

    &:hover {
      background-color: var(--color-blue-3);
    }
  }

  &.ui-button-v3--theme-primary-light-outlined {
    color: var(--color-blue-1);
    border-color: var(--color-blue-1);
    filter: var(--shadow-1);

    &:hover {
      color: var(--color-blue-3);
      border-color: var(--color-blue-3);
    }
  }

  &.ui-button-v3--theme-primary-dark-normal {
    background-color: var(--color-blue-4);

    &:hover {
      background-color: var(--color-blue-5);
    }
  }

  &.ui-button-v3--theme-primary-dark-outlined {
    color: var(--color-blue-5);
    border-color: var(--color-blue-5);
    filter: var(--shadow-1);

    &:hover {
      border-color: var(--color-blue-2);
    }
  }

  &.ui-button-v3--theme-secondary {
    color: var(--primary_1);
    background-color: var(--color-white-1);
    box-shadow: var(--shadow-2);
    backdrop-filter: var(--filter-blur-10);

    &:hover {
      color: var(--color-white-1);
      background-color: var(--color-blue-1);
      box-shadow: none;
    }
  }

  &.ui-button-v3--theme-danger-normal {
    background-color: var(--color-red-1);

    &:hover {
      background-color: var(--color-red-3);
    }
  }

  &.ui-button-v3--theme-danger-outlined {
    color: var(--color-red-1);
    border-color: var(--color-red-1);
    filter: var(--shadow-1);

    &:hover {
      color: var(--color-red-3);
      border-color: var(--color-red-3);
    }
  }

  &.ui-button-v3--theme-ghost-light {
    color: var(--color-blue-1);

    &:hover {
      color: var(--color-blue-3);
    }
  }

  &.ui-button-v3--theme-ghost-dark {
    color: var(--color-blue-5);

    &:hover {
      color: var(--color-blue-2);
    }
  }

  &.ui-button-v3--theme-gray {
    color: var(--color-gray-2);
    background-color: var(--color-blue-19);
  }

  &.ui-button-v3--theme-primary-gradient {
    background: var(--gradient-7);
  }

  &.ui-button-v3--theme-transparent {
    background-color: transparent;

    &:hover {
      color: var(--color-blue-2);
    }
  }

  &.ui-button-v3--autogrow {
    width: 100%;
  }
}
</style>
