import { InjectionKey } from 'vue';
import { PurchasesList } from '@/modules/dossier/company/common/types/detailsCompanyPurchasesList';
import { PurchasesMappedList } from '@/modules/dossier/company/common/types/detailsCompanyPurchasesMappedList';
import {
  CommonProductsGroupedData,
  ExecutionStatusesGroupedData,
  MajorSuppliersAndCustomersGroupedData,
} from '@/modules/dossier/company/common/types/detailsCompanyPurchases';

export interface ICompanyPurchasesService {
  getPurchasesData(purchases: PurchasesList[]): PurchasesMappedList[];
  getMajorSuppliersAndCustomersGroupedData(
    purchases: PurchasesMappedList[],
  ): MajorSuppliersAndCustomersGroupedData[];
  getCommonProductsGroupedData(purchases: PurchasesMappedList[]): CommonProductsGroupedData;
  getExecutionStatusesGroupedData(purchases: PurchasesMappedList[]): ExecutionStatusesGroupedData;
}

export const ICompanyPurchasesServiceKey: InjectionKey<ICompanyPurchasesService> = Symbol(
  'ICompanyPurchasesServiceKey',
);
