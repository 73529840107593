import { injectable, inject } from 'inversify';
import { ImageGroupItem } from '@/modules/images/types/imageGroupItem';
import { IFilesService, IFilesServiceKey } from '@/modules/files/services/filesService.interface';
import { ApiPersonDetailVkPhotoRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkPhotoRecord';
import { DateUtils } from '@/utils/dateUtils';
import { IPersonSocialNetworkAdditionalInfoVkGalleryMappingService } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/mappings/personSocialNetworkAdditionalInfoVkGalleryMappingService.interface';

@injectable()
export default class PersonSocialNetworkAdditionalInfoVkGalleryMappingService
  implements IPersonSocialNetworkAdditionalInfoVkGalleryMappingService
{
  constructor(
    @inject(IFilesServiceKey as symbol)
    private FilesService: IFilesService,
  ) {}

  mapSocialNetworkAdditionalInfoVkGallery(
    photos: Array<ApiPersonDetailVkPhotoRecord>,
  ): Array<ImageGroupItem> {
    return photos.map((photo) => {
      const dateWithoutDay = photo.date.split(', ').slice(1).join();
      const formattedDate = DateUtils.parseRussianDate(dateWithoutDay)?.toLocaleDateString();
      const publicationDate = DateUtils.getPublicationDateText(formattedDate);
      return {
        title: publicationDate,
        src: this.FilesService.getDownloadUrl(photo.original_url),
      };
    });
  }
}
