import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import RouteMatcherNested from '@/modules/tabs/routeTabs/routeMatchers/routeMatcherNested';
import RouteMatcherExactly from '@/modules/tabs/routeTabs/routeMatchers/routeMatcherExactly';
import { IRouteMatcherNestedKey } from '@/modules/tabs/routeTabs/routeMatchers/routeMatcherNested.interfaces';
import { IRouteMatcherExactlyKey } from '@/modules/tabs/routeTabs/routeMatchers/routeMatcherExactly.interfaces';

export function bindRouteTabsServices(bind: IContainerBinder) {
  bind(IRouteMatcherExactlyKey).to(RouteMatcherExactly);
  bind(IRouteMatcherNestedKey).to(RouteMatcherNested);
}
