import { injectable } from 'inversify';
import { ICompanyArbitrationRisksStatisticService } from '@/modules/dossier/company/arbitrations/services/companyArbitrationRisksStatisticService.interface';
import { ArbitrationMarkers } from '@/modules/dossier/company/arbitrations/types/arbitrationMarkers';
import { ArbitrationRisksStatistic } from '@/modules/dossier/company/arbitrations/types/arbitrationRisksStatistic';

@injectable()
export default class CompanyArbitrationRisksStatisticPlaintiffService
  implements ICompanyArbitrationRisksStatisticService
{
  // eslint-disable-next-line class-methods-use-this
  getArbitrationRisksStatisticData(
    arbitrationMarkers: ArbitrationMarkers,
  ): ArbitrationRisksStatistic {
    return {
      outcomeCasesOverPastThreeYearsClaimSatisfiedInFullPercent:
        arbitrationMarkers.plaintiffOutcomeCasesOverPastThreeYearsClaimSatisfiedInFullPercent,
      outcomeCasesOverPastThreeYearsClaimPartiallySatisfiedPercent:
        arbitrationMarkers.plaintiffOutcomeCasesOverPastThreeYearsClaimPartiallySatisfiedPercent,
      outcomeCasesOverPastThreeYearsClaimNotSatisfiedPercent:
        arbitrationMarkers.plaintiffOutcomeCasesOverPastThreeYearsClaimNotSatisfiedPercent,
      outcomeCasesOverPastThreeYearsDiscontinuedPercent:
        arbitrationMarkers.plaintiffOutcomeCasesOverPastThreeYearsDiscontinuedPercent,
      outcomeCasesOverPastThreeYearsUndefinedPercent:
        arbitrationMarkers.plaintiffOutcomeCasesOverPastThreeYearsUndefinedPercent,
      outcomeCasesOverPastThreeYearsUnderReviewPercent:
        arbitrationMarkers.plaintiffOutcomeCasesOverPastThreeYearsUnderReviewPercent,
    };
  }
}
