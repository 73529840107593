import { IImportFileCompanyService } from '@/modules/import/services/importFileCompanyService.interfaces';
import { ImportListSettings } from '@/modules/import/types/api/importListSettings';
import AImportFileService from '@/modules/import/services/importFileService.abstract';
import { ImportFileProcessId } from '@/modules/import/types/api/importFileProcessId';
import { inject, injectable } from 'inversify';
import personApi from '@/api/person';
import {
  IProcessingSettingsService,
  IProcessingSettingsServiceKey,
} from '@/modules/processingSettings/services/processingSettingsService.interface';

@injectable()
export default class ImportFilePersonService
  extends AImportFileService
  implements IImportFileCompanyService
{
  constructor(
    @inject(IProcessingSettingsServiceKey as symbol)
    private processingSettingsService: IProcessingSettingsService,
  ) {
    super();
  }
  public async importFile(
    file: Blob,
    listSettings?: ImportListSettings,
  ): Promise<ImportFileProcessId> {
    const data = this.getFormDataForImportFile(
      file,
      listSettings,
      this.processingSettingsService.settings,
    );

    const response = await personApi.makeProcessingPerson(data);
    return response.data.data.importProgressId;
  }
}
