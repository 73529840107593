import { IEndpointsFactory } from '@/modules/common/factories/endpoints/endpointsFactory.interfaces';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify';

@injectable()
export default abstract class EndpointsFactory implements IEndpointsFactory {
  get(): AxiosInstance {
    return axios.create(this.getConfig());
  }

  protected abstract getConfig(): AxiosRequestConfig
}
