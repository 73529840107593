import { injectable } from 'inversify';
import { ApiPersonDetailPledgeMemberInfoRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailPledgeMemberInfoRecord';
import { IPersonPledgesRegistriesScoringsPledgeholdersFormatter } from './personPledgesRegistriesScoringsPledgeholdersFormatter.interface';

@injectable()
export default class PersonPledgesRegistriesScoringsPledgeholdersFormatter
  implements IPersonPledgesRegistriesScoringsPledgeholdersFormatter
{
  getPledgeholdersFormattedNames(pledgeholders: ApiPersonDetailPledgeMemberInfoRecord[]): string {
    return pledgeholders.map((pledgeholder) => pledgeholder.name).join(', ');
  }
}
