import { InjectionKey } from 'vue';
import { DetailsCompanyLicensesList } from '@/modules/dossier/company/common/types/detailsCompanyLicensesList';

export interface ICompanyLicensesService {
  getLicensesData(licenses: DetailsCompanyLicensesList[]): DetailsCompanyLicensesList[];
}

export const ICompanyLicensesServiceKey: InjectionKey<ICompanyLicensesService> = Symbol(
  'ICompanyLicensesServiceKey',
);
