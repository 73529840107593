import { inject, injectable } from 'inversify';
import { IPersonSocialNetworkAdditionalInfoVkUserInfoFormatter } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVkUserInfoFormatter.interface';
import { ApiPersonDetailVkSchoolRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkSchoolRecord';
import { ApiPersonDetailVkUniversityRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkUniversityRecord';
import {
  IStringNormalizationService,
  IStringNormalizationServiceKey,
} from '@/modules/normalization/services/stringNormalizationService.interfaces';
import { StringUtils } from '@/modules/common/utils/stringUtils';

@injectable()
export default class PersonSocialNetworkAdditionalInfoVkUserInfoFormatter
  implements IPersonSocialNetworkAdditionalInfoVkUserInfoFormatter
{
  constructor(
    @inject(IStringNormalizationServiceKey as symbol)
    private stringNormalizationService: IStringNormalizationService,
  ) {}

  getPersonVkUserSchools(vkUserSchools: Array<ApiPersonDetailVkSchoolRecord>): string {
    return (vkUserSchools || [])
      .map(({ name, city, education_start: educationStart, education_end: educationEnd }) => {
        const schoolNameNormalized = this.stringNormalizationService.normalize(name);
        const schoolName =
          schoolNameNormalized != '' ? StringUtils.flatten(schoolNameNormalized) : '';

        const schoolCityNormalized = this.stringNormalizationService.normalize(city);
        const schoolCity =
          schoolCityNormalized != '' ? `г. ${StringUtils.flatten(schoolCityNormalized)}` : '';

        const educationPeriod = [
          this.stringNormalizationService.normalize(educationStart),
          this.stringNormalizationService.normalize(educationEnd),
        ]
          .filter(Boolean)
          .join(' - ');

        const educationPeriodFormatted = educationPeriod != '' ? `(${educationPeriod})` : '';

        const schoolDetails = [
          schoolName,
          [schoolCity, educationPeriodFormatted].filter(Boolean).join(' '),
        ]
          .filter(Boolean)
          .join(', ');

        return `${schoolDetails}\n`;
      })
      .join('\n');
  }

  getPersonVkUserUniversities(
    vkUserUniversities: Array<ApiPersonDetailVkUniversityRecord>,
  ): string {
    return (vkUserUniversities || [])
      .map(({ name, city, education_end: educationEnd, faculty }) => {
        const universityNameNormalized = this.stringNormalizationService.normalize(name);
        const universityName =
          universityNameNormalized != '' ? StringUtils.flatten(universityNameNormalized) : '';

        const universityCityNormalized = this.stringNormalizationService.normalize(city);
        const universityCity =
          universityCityNormalized != ''
            ? `г. ${StringUtils.flatten(universityCityNormalized)}`
            : '';

        const universityEducationEndNormalized =
          this.stringNormalizationService.normalize(educationEnd);
        const universityEducationEndFormatted =
          universityEducationEndNormalized != '' ? `(${universityEducationEndNormalized})` : '';

        const universityFacultyNormalized = this.stringNormalizationService.normalize(faculty);
        const universityFaculty =
          universityFacultyNormalized != '' ? StringUtils.flatten(universityFacultyNormalized) : '';

        const universityDetails = [
          universityName,
          [universityCity, universityEducationEndFormatted].filter(Boolean).join(' '),
          universityFaculty,
        ]
          .filter(Boolean)
          .join(', ');

        return `${universityDetails}\n`;
      })
      .join('\n');
  }
}
