import { injectable } from 'inversify';
import { IChartColorsFactory } from '@/modules/charts/factories/colors/chartColorsFactory.interfaces';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

const colorBlue1 = '#405AA9';
const colorBlue5 = '#8FA8FD';
const colorBlue21 = '#C1C5DD';
const baseDesignColors = [colorBlue1, colorBlue5, colorBlue21];

const d3Colors = scaleOrdinal(schemeCategory10);
const getD3Colors = (index: string) => d3Colors(index);
@injectable()
export default class ChartColorsFactory implements IChartColorsFactory {
  getD3ColorByIndex(index: number): string {
    if (index < baseDesignColors.length) {
      return baseDesignColors[index];
    }
    return getD3Colors(index.toString());
  }
}
