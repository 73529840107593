import { InjectionKey } from 'vue';
import { riskType } from '@/modules/risks/types/riskType';

export interface IRiskTypeCalculatorService {
  calculateRiskType(value: number): riskType;
}

export const IRiskTypeCalculatorServiceKey: InjectionKey<IRiskTypeCalculatorService> = Symbol(
  'IRiskTypeCalculatorService',
);
