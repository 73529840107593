<template>
  <component
    :is="component"
    v-bind="config"
    v-on="events"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData"><slot :name="name" v-bind="slotData" /></template>
  </component>
</template>

<script setup lang="ts">
import { ComponentDefinition } from '@/modules/common/types/componentDefinition';
import { RefUtils } from '@/modules/common/utils/refUtils';
import { computed, toRefs } from 'vue';

interface Props {
  definition: ComponentDefinition
}

const props = defineProps<Props>();
const { definition } = toRefs(props);

const component = computed(() => definition.value.component);
const config = computed(() => RefUtils.unwrapConfig(definition.value.config));
const events = computed(() => definition.value.events ?? {});
</script>
