import { injectable, inject } from 'inversify';
import { ApiPersonDetailFinancialScoringDataRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailFinancialScoringDataRecord';
import { FinancialScoringAnalyticsBlockByPerson } from '@/modules/dossier/person/financialScorings/types/financialScoringAnalyticsBlockByPerson';
import { AnalyticsTypes } from '@/modules/dossier/person/financialScorings/types/financialAnalyticsTypes';
import {
  IAnalyticsScoreThemeFactory,
  IAnalyticsScoreThemeFactoryKey,
} from '@/modules/dossier/person/financialScorings/services/formatters/analyticsScoreThemeFactory.interface';
import {
  IAnalyticsScoreTypeFormatterService,
  IAnalyticsScoreTypeFormatterServiceKey,
} from '@/modules/dossier/person/financialScorings/services/formatters/analyticsScoreTypeFormatterService.interface';
import {
  IAnalyticsTypeFormatterService,
  IAnalyticsTypeFormatterServiceKey,
} from '@/modules/dossier/person/financialScorings/services/formatters/analyticsTypeFormatterService.interface';
import {
  IAnalyticsTypeDescriptionFactory,
  IAnalyticsTypeDescriptionFactoryKey,
} from '@/modules/dossier/person/financialScorings/services/formatters/analyticsTypeDescriptionFactory.interface';
import {
  IAnalyticsScoreTypeParserService,
  IAnalyticsScoreTypeParserServiceKey,
} from '@/modules/dossier/person/financialScorings/services/parsers/analyticsScoreTypeParserService.interface';
import {
  IAnalyticsTypeParserService,
  IAnalyticsTypeParserServiceKey,
} from '@/modules/dossier/person/financialScorings/services/parsers/analyticsTypeParserService.interface';
import {
  IAnalyticsScoreOKBTypeParserService,
  IAnalyticsScoreOKBTypeParserServiceKey,
} from '@/modules/dossier/person/financialScorings/services/parsers/analyticsScoreOKBTypeParserService.interface';

import { IPersonFinancialScoringsMappingService } from './personFinancialScoringsMappingService.interface';

@injectable()
export default class PersonFinancialScoringsMappingService
  implements IPersonFinancialScoringsMappingService
{
  constructor(
    @inject(IAnalyticsScoreTypeParserServiceKey as symbol)
    private AnalyticsScoreTypeParserService: IAnalyticsScoreTypeParserService,
    @inject(IAnalyticsTypeParserServiceKey as symbol)
    private AnalyticsTypeParserService: IAnalyticsTypeParserService,
    @inject(IAnalyticsScoreThemeFactoryKey as symbol)
    private AnalyticsScoreThemeFactory: IAnalyticsScoreThemeFactory,
    @inject(IAnalyticsScoreTypeFormatterServiceKey as symbol)
    private AnalyticsScoreTypeFormatterService: IAnalyticsScoreTypeFormatterService,
    @inject(IAnalyticsScoreOKBTypeParserServiceKey as symbol)
    private AnalyticsScoreOKBTypeParserService: IAnalyticsScoreOKBTypeParserService,
    @inject(IAnalyticsTypeFormatterServiceKey as symbol)
    private AnalyticsTypeFormatterService: IAnalyticsTypeFormatterService,
    @inject(IAnalyticsTypeDescriptionFactoryKey as symbol)
    private AnalyticsTypeDescriptionFactory: IAnalyticsTypeDescriptionFactory,
  ) {}

  mapFinancialScoringsAnalytics(
    scorings: Array<ApiPersonDetailFinancialScoringDataRecord>,
  ): Array<FinancialScoringAnalyticsBlockByPerson> {
    return scorings.map((scoring) => {
      const scoringReasons = scoring.scoringReasons?.split('\n\r') || [];
      const analyticType = this.AnalyticsTypeParserService.parseAnalyticType(scoring.type);
      const isOKBAnalyticType = analyticType == AnalyticsTypes.OKB;
      const analyticScore = isOKBAnalyticType
        ? this.AnalyticsScoreOKBTypeParserService.parseOKBAnalyticScore(scoring.score)
        : this.AnalyticsScoreTypeParserService.parseAnalyticScore(scoring.score);

      return {
        person: {
          fio: scoring.checkedFio,
          birthdate: scoring.checkedBirthday,
          passportNumber: scoring.checkedPassportNumber,
          passportIssueDate: scoring.checkedPassportIssueDate,
          phoneNumber: scoring.checkedPhone,
        },
        analyticsData: {
          id: scoring.id,
          typeName: this.AnalyticsTypeFormatterService.getAnalyticsScoreTypeName(analyticType),
          typeDescription:
            this.AnalyticsTypeDescriptionFactory.getAnalyticScoreTypeDescription(analyticType),
          score: scoring.score,
          scoreDecoding:
            this.AnalyticsScoreTypeFormatterService.getAnalyticsScoreTypeFormattedName(
              analyticScore,
            ),
          scoringReasons,
          theme: this.AnalyticsScoreThemeFactory.getAnalyticScoreTheme(analyticScore),
        },
      };
    });
  }
}
