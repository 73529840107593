import { ReportParentType } from '@/modules/reports/common/types/ReportParentType';
import { InjectionKey } from 'vue';

export interface IReportParentTypeFormatter {
  format(parentType: ReportParentType): string;
}

export const IReportParentTypeFormatterKey: InjectionKey<IReportParentTypeFormatter> = Symbol(
  'IReportParentTypeFormatter',
);
