import { injectable } from 'inversify';
import { INumberTruncationService } from '@/modules/common/modules/numbers/services/numberTruncationService/numberTruncationService.interfaces';
import { NumberTruncationMode } from '@/modules/common/modules/numbers/types/numberTruncationMode';

@injectable()
export default class NumberTruncationService implements INumberTruncationService {
  truncateMode(value: number | undefined, truncateMode: NumberTruncationMode = NumberTruncationMode.none): number | undefined {
    if (value == undefined) {
      return undefined
    }
    switch (truncateMode) {
      case NumberTruncationMode.none:
        return value;
      case NumberTruncationMode.floor:
        return Math.floor(value);
      case NumberTruncationMode.ceil:
        return Math.ceil(value);
      case NumberTruncationMode.round:
        return Math.round(value);
      case NumberTruncationMode.trunc:
        return Math.trunc(value);
      default:
        return value;
    }
  }
}
