import uniq from 'lodash/uniq';
import { injectable } from 'inversify';
import { InspectionsInfo } from '@/types/companyInspectionsInfo/companyInspectionsInfo';
import { InspectionInfoStatus } from '@/types/companyInspectionsInfo/companyInspectionInfoStatus';
import { ICompanyInspectionsService } from '@/modules/dossier/company/common/services/statistics/companyInspectionsService.interface';

@injectable()
export class companyInspectionsService implements ICompanyInspectionsService {
  calculateCountInspectionsByStatus(
    inspections: Array<InspectionsInfo>,
    status: InspectionInfoStatus,
  ): number {
    return uniq(
      inspections
        .filter((inspection) => inspection.status == status)
        .map((inspection) => inspection.registrationNumber),
    ).length;
  }
}
