import {
  ISocialNetworkTypeFormatter
} from '@/modules/dossier/person/common/services/formatters/socialNetworkTypeFormatter.interfaces';
import { SocialNetworkTypes } from '@/modules/dossier/person/common/types/api/socialNetworkTypes';
import { injectable } from 'inversify';

@injectable()
export default class SocialNetworkTypeFormatter implements ISocialNetworkTypeFormatter {
  format(value: SocialNetworkTypes): string {
    switch (value) {
      case SocialNetworkTypes.Unknown: return 'Неизвестно';
      case SocialNetworkTypes.Vk: return 'Вконтакте';
      case SocialNetworkTypes.Ok: return 'Одноклассники';
      case SocialNetworkTypes.Fb: return 'Фейсбук';
      case SocialNetworkTypes.Instagram: return 'Инстаграм';
      case SocialNetworkTypes.Twitter: return 'Твиттер';
      case SocialNetworkTypes.TikTok: return 'Тик-ток';
      case SocialNetworkTypes.Telegram: return 'Телеграм';
      case SocialNetworkTypes.WhatsApp: return 'ВатсАпп';
      case SocialNetworkTypes.Viber: return 'Вайбер';
      case SocialNetworkTypes.Skype: return 'Скайп';
      default: return '';
    }
  }
}
