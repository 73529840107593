import { ApiElasticSourceType } from '@/modules/elastic/types/api/apiElasticSourceType';
import { SearchConceptOption } from '@/modules/searchConcept/common/types/searchConceptOption';
import {
  SearchConceptOptionsGroupType
} from '@/modules/searchConcept/common/types/searchConceptOptionsGroupType';
import {
  IPersonSearchConceptOptionsFactory
} from '@/modules/searchConcept/person/factories/personSearchConceptOptionsFactory.interfaces';
import { injectable } from 'inversify';

const personSearchConceptOptionsData: Omit<SearchConceptOption, 'source'>[] = [
  // { type: 'firstName', groupType: SearchConceptOptionsGroupType.General },
  // { type: 'lastName', groupType: SearchConceptOptionsGroupType.General },
  // { type: 'patronymic', groupType: SearchConceptOptionsGroupType.General },
  { field: 'fullNames', groupType: SearchConceptOptionsGroupType.General },
  { field: 'inns', groupType: SearchConceptOptionsGroupType.PersonalInformation },
  { field: 'snilses', groupType: SearchConceptOptionsGroupType.PersonalInformation },
  { field: 'birthPlaces', groupType: SearchConceptOptionsGroupType.PersonalInformation },
  { field: 'citizenships', groupType: SearchConceptOptionsGroupType.PersonalInformation },
  // { type: 'nationalities', groupType: SearchConceptOptionsGroupType.PersonalInformation },
  { field: 'passports', groupType: SearchConceptOptionsGroupType.PersonalInformation },
  { field: 'ratings', groupType: SearchConceptOptionsGroupType.PersonScoring },
  { field: 'phones', groupType: SearchConceptOptionsGroupType.PersonContacts },
  { field: 'emails', groupType: SearchConceptOptionsGroupType.PersonContacts },
  { field: 'tags', groupType: SearchConceptOptionsGroupType.PersonContacts },
  // { type: 'sites', groupType: SearchConceptOptionsGroupType.PersonContacts },
  { field: 'messengers', groupType: SearchConceptOptionsGroupType.PersonContacts },
  { field: 'socials', groupType: SearchConceptOptionsGroupType.PersonContacts },
  { field: 'addresses', groupType: SearchConceptOptionsGroupType.PersonAddresses },
  // { type: 'driverIds', groupType: SearchConceptOptionsGroupType.PersonTransports },
  { field: 'carNumbers', groupType: SearchConceptOptionsGroupType.PersonTransports },
  { field: 'carVins', groupType: SearchConceptOptionsGroupType.PersonTransports },
  { field: 'carMarks', groupType: SearchConceptOptionsGroupType.PersonTransports },
  { field: 'carModels', groupType: SearchConceptOptionsGroupType.PersonTransports },
  { field: 'carOwnerFullNames', groupType: SearchConceptOptionsGroupType.PersonTransports },
  { field: 'carOwnerPhones', groupType: SearchConceptOptionsGroupType.PersonTransports },
  // { type: 'carOwnerInns', groupType: SearchConceptOptionsGroupType.PersonTransports },
  { field: 'carOwnerAddresses', groupType: SearchConceptOptionsGroupType.PersonTransports },
  { field: 'adds', groupType: SearchConceptOptionsGroupType.PersonAds },
  // { type: 'phonesRelatedCarNumber', groupType: SearchConceptOptionsGroupType.PersonTransports },
  // { type: 'phonesRelatedDossierObject', groupType: SearchConceptOptionsGroupType.PersonParkings },
  { field: 'parkingZones', groupType: SearchConceptOptionsGroupType.PersonParkings },
  { field: 'parkingCarNumbers', groupType: SearchConceptOptionsGroupType.PersonParkings },
  { field: 'educationNames', groupType: SearchConceptOptionsGroupType.PersonEducations },
  { field: 'educationSpecialties', groupType: SearchConceptOptionsGroupType.PersonEducations },
  // { type: 'educationQualifications', groupType: SearchConceptOptionsGroupType.PersonEducations },
  // { type: 'educationProffesions', groupType: SearchConceptOptionsGroupType.PersonEducations },
  // { type: 'legalEnitityParticipationCompanyShortNames', groupType: SearchConceptOptionsGroupType.PersonLegalEntityParticipations },
  { field: 'legalEnitityParticipationCompanyFullNames', groupType: SearchConceptOptionsGroupType.PersonLegalEntityParticipations },
  { field: 'legalEnitityParticipationCompanyInns', groupType: SearchConceptOptionsGroupType.PersonLegalEntityParticipations },
  { field: 'legalEnitityParticipationCompanyOgrns', groupType: SearchConceptOptionsGroupType.PersonLegalEntityParticipations },
  { field: 'jobCompanyNames', groupType: SearchConceptOptionsGroupType.PersonJobs },
  { field: 'jobCompanyInns', groupType: SearchConceptOptionsGroupType.PersonJobs },
  // { type: 'jobCompanyOgrns', groupType: SearchConceptOptionsGroupType.PersonJobs },
  { field: 'jobDepartments', groupType: SearchConceptOptionsGroupType.PersonJobs },
  { field: 'jobPositions', groupType: SearchConceptOptionsGroupType.PersonJobs },
  { field: 'jobPhones', groupType: SearchConceptOptionsGroupType.PersonJobs },
  // { type: 'jobFriendFios', groupType: SearchConceptOptionsGroupType.PersonJobs },
  { field: 'businessTripCompanySenders', groupType: SearchConceptOptionsGroupType.BusinessTrips },
  // { type: 'businessTripCompanyReceiver', groupType: SearchConceptOptionsGroupType.BusinessTrips },
  { field: 'militaryRecordIds', groupType: SearchConceptOptionsGroupType.PersonMilitary },
  { field: 'negativeInfos', groupType: SearchConceptOptionsGroupType.PersonNegativeInfos },
  { field: 'negativeInfoSource', groupType: SearchConceptOptionsGroupType.PersonNegativeInfos },
  { field: 'familyStatus', groupType: SearchConceptOptionsGroupType.PersonFamily },
  { field: 'familyFullNames', groupType: SearchConceptOptionsGroupType.PersonFamily },
  { field: 'familyInns', groupType: SearchConceptOptionsGroupType.PersonFamily },
  { field: 'affiliatedCompanyNames', groupType: SearchConceptOptionsGroupType.PersonAffiliationInfos },
  { field: 'affiliatedCompanyInns', groupType: SearchConceptOptionsGroupType.PersonAffiliationInfos },
  // { type: 'affiliatedCompanyOgrns', groupType: SearchConceptOptionsGroupType.PersonAffiliationInfos },
  { field: 'affiliatedFlFullNames', groupType: SearchConceptOptionsGroupType.PersonAffiliationInfos },
  { field: 'affiliatedFlInns', groupType: SearchConceptOptionsGroupType.PersonAffiliationInfos },
  // { type: 'affiliatedFlPhones', groupType: SearchConceptOptionsGroupType.PersonAffiliationInfos },
  // { type: 'affiliatedFlAddresses', groupType: SearchConceptOptionsGroupType.PersonAffiliationInfos },
  { field: 'movementAirportFrom', groupType: SearchConceptOptionsGroupType.PersonMovements },
  { field: 'movementAirportTo', groupType: SearchConceptOptionsGroupType.PersonMovements },
  // { type: 'movementCovidAndPassFrom', groupType: SearchConceptOptionsGroupType.PersonMovements },
  // { type: 'movementCovidAndPassTo', groupType: SearchConceptOptionsGroupType.PersonMovements },
  // { type: 'movementCovidAndPassCarNumbers', groupType: SearchConceptOptionsGroupType.PersonMovements },
  // { type: 'movementTaxiFrom', groupType: SearchConceptOptionsGroupType.PersonMovements },
  // { type: 'movementTaxiTo', groupType: SearchConceptOptionsGroupType.PersonMovements },
  { field: 'notes', groupType: SearchConceptOptionsGroupType.General },
]

const personSearchConceptOptions: SearchConceptOption[] = personSearchConceptOptionsData.map(x => ({
  ...x,
  source: ApiElasticSourceType.Person
}));

@injectable()
export default class PersonSearchConceptOptionsFactory implements IPersonSearchConceptOptionsFactory {
  getOptions(): SearchConceptOption[] {
    return personSearchConceptOptions;
  }
}
