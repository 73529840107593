import { RootState } from '@/store/type';
import { MutationTree, Module } from 'vuex';
import { ManualPersonState, Mutations, MutationTypes } from './types';

const moduleState: ManualPersonState = {
  snils: '',
  inn: '',
  firstName: '',
  lastName: '',
  patronymic: '',
  birthDate: '',
  personType: '',
  passportType: '',
  passportNumber: '',
  passportSeries: '',
  departmentCode: '',
  passportDepartment: '',
  passportDate: '',
  phoneType: '',
  phoneNumber: '',
  socialNetworkType: '',
  socialNetworkId: '',
  addressType: '',
  addressLocation: '',
  educationInstitution: '',
  specialityName: '',
  educationSeries: '',
  educationNumber: '',
  educationStartDate: '',
  educationEndDate: '',
  companyName: '',
  companyInn: '',
  companyDivisionName: '',
  position: '',
  workStartDate: '',
  workEndDate: '',
  transportNumber: '',
  transportVin: '',
  relativesFirstName: '',
  relativesPatronymic: '',
  relativesLastName: '',
  relativeBirthDate: '',
  relativesType: '',
  innRelatives: '',
  note: '',
};
const mutations: MutationTree<ManualPersonState> & Mutations = {
  [MutationTypes.SET_NEW_VALUE_PERSONS](state, payload: { name: string; value: string }) {
    state[payload.name] = payload.value;
  },
  [MutationTypes.RESET_VALUES_PERSONS](state) {
    Array.of(...Object.keys(state)).forEach((key) => (state[key] = ''));
  },
};

const ImportManualPersonModule: Module<ManualPersonState, RootState> = {
  state: moduleState,
  mutations,
};

export default ImportManualPersonModule;
