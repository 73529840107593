import {
  ExternalParserOptionsGroupType
} from '@/modules/dataCollection/types/externalParserOptionsGroupType';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { InjectionKey } from 'vue';

export interface IExternalParserOptionsGroupIconsService {
  getIconByType(type: ExternalParserOptionsGroupType): SystemIconsNames | undefined
}

export const IExternalParserOptionsGroupIconsServiceKey: InjectionKey<IExternalParserOptionsGroupIconsService> = Symbol('IExternalParserOptionsGroupIconsService');
