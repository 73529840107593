import { ICacheSettings } from '@/modules/common/settings/cacheSettings.interfaces';
import { injectable } from 'inversify';
import { CacheStatuses } from '@/types/cacheStatuses';

@injectable()
export default class CacheSettings implements ICacheSettings {
  constructor() {
    const searchParams = new URLSearchParams(document.location.search);

    if (searchParams.get('cache') == CacheStatuses.enabled) {
      localStorage.setItem('is-cache-enabled', CacheStatuses.enabled);
    }

    if (searchParams.get('cache') == CacheStatuses.disabled) {
      localStorage.removeItem('is-cache-enabled');
    }
  }

  get isCacheEnabled() {
    return localStorage.getItem('is-cache-enabled') == CacheStatuses.enabled;
  }
}
