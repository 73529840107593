import { IReportHistoryRequisitesFactory } from '@/modules/reports/common/services/reportHistoryRequisitesFactory.interfaces';
import { inject, injectable } from 'inversify';
import { ReportHistoryItemDTO } from '@/modules/reports/common/types/reportHistoryItemDTO';
import { ReportTypeInput } from '@/modules/reports/common/types/ReportTypeInput';
import { displayPhoneMask } from '@/modules/validation/configuration/masks/phoneMask';
import { DateUtils } from '@/utils/dateUtils';
import { DateFormatConfiguration } from '@/configuration/dateFormatConfiguration';
import {
  IMasksService,
  IMasksServiceKey,
} from '@/modules/masks/services/masksService/masksService.interfaces';

@injectable()
export default class ReportHistoryRequisitesFactory implements IReportHistoryRequisitesFactory {
  constructor(@inject(IMasksServiceKey as symbol) private masksService: IMasksService) {}

  getRequisite(report: ReportHistoryItemDTO): string {
    switch (report.inputType) {
      case ReportTypeInput.phone:
        return this.masksService.getMaskedValue(report.phoneNumber, displayPhoneMask) ?? '';
      // TODO return report.phoneNumber ? PhoneNumberUtils.formatPhoneNumber(report.phoneNumber) : '';
      case ReportTypeInput.fullName:
        return report.fio
          ? `${report.fio}, ${DateUtils.formatDate(
              report.birthday,
              DateFormatConfiguration.fullDate,
              '',
            )}`
          : '';
      case ReportTypeInput.inn:
        return report.inn || '';
      case ReportTypeInput.carNumber:
        return report.carNumber || '';
      default:
        return '';
    }
  }
}
