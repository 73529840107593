import {
  RegulationCheckStatus
} from '@/modules/regulationsCheck/common/types/regulationCheckStatus';
import { InjectionKey } from 'vue';

export interface IRegulationCheckStatusFormatter {
  format(status: RegulationCheckStatus): string
}

export const IRegulationCheckStatusFormatterKey: InjectionKey<IRegulationCheckStatusFormatter> = Symbol('IRegulationCheckStatusFormatter');
