import ListsEntityIdsExtractionService
  from '@/modules/lists/services/listsEntityIdsExtractionService';
import {
  IPersonListsEntityIdsExtractionService
} from '@/modules/lists/services/personListsEntityIdsExtractionService.interfaces';
import {
  IPersonListsService, IPersonListsServiceKey
} from '@/modules/lists/services/personListsService.interfaces';
import { inject, injectable } from 'inversify';

@injectable()
export default class PersonListsEntityIdsExtractionService extends ListsEntityIdsExtractionService implements IPersonListsEntityIdsExtractionService {
  constructor(
    @inject(IPersonListsServiceKey as symbol) private personListsService: IPersonListsService,
  ) {
    super();
  }
  protected async getListEntityIdsAsync(listId: string): Promise<string[]> {
    const {
      data: {
        data: {
          personIds
        }
      }
    } = await this.personListsService.getListDetails(listId);

    return personIds;
  }
}
