import { InjectionKey } from 'vue';
import { FinanceAnalysisShortTableData } from '@/modules/dossier/company/financeAnalysis/types/financeAnalysisShortTableData';
import { IndicatorsOfCashFlowTableData } from '@/modules/dossier/company/financeAnalysis/types/IndicatorsOfCashFlowTableData';
import { CashFlow } from '@/modules/dossier/company/financeAnalysis/types/api/cashFlow';
import { FinanceCompanyDetail } from '@/modules/dossier/company/financeAnalysis/types/api/financeAnalysDetail';
import { LiquidityAndSolvency } from '@/modules/dossier/company/financeAnalysis/types/api/liquidityAndSolvency';
import { FinancialStability } from '@/modules/dossier/company/financeAnalysis/types/api/financialStability';
import { IndicatorsOfProfitability } from '@/modules/dossier/company/financeAnalysis/types/api/indicatorsOfProfitability';
import { FinanceAnalysisTableData } from '@/modules/dossier/company/financeAnalysis/types/financeAnalysisTableData';
import { DebtEbit } from '@/modules/dossier/company/financeAnalysis/types/api/debtEbit';
import { EbitPercent } from '@/modules/dossier/company/financeAnalysis/types/api/ebitPercent';
import { FinanceShortDebtShare } from '@/modules/dossier/company/financeAnalysis/types/financeShortDebtShare';
import { DossierTagData } from '@/modules/common/types/dossierTag';

export interface IFinanceAnalysisMappingService {
  getFinanceAnalysisShortTableData(
    financeDetailsList: Array<
      FinanceCompanyDetail | DebtEbit | EbitPercent | FinanceShortDebtShare
    >,
  ): Array<FinanceAnalysisShortTableData>;
  getIndicatorsOfCashFlowTableData(
    indicatorsOfCashFlowList: Array<CashFlow>,
  ): Array<IndicatorsOfCashFlowTableData>;
  getLiquidityAndSolvencyTableData(
    liquidityAndSolvency: LiquidityAndSolvency,
  ): Array<FinanceAnalysisTableData>;
  getStabilityTableData(financeStability: FinancialStability): Array<FinanceAnalysisTableData>;
  getIndicatorOfProfitabilityTableData(
    indicatorsOfProfitability: IndicatorsOfProfitability,
  ): Array<FinanceAnalysisTableData>;
  getActualRatioCell(actualRatioText: string): DossierTagData;
}

export const FinanceAnalysisMappingServiceKey: InjectionKey<IFinanceAnalysisMappingService> =
  Symbol('FinanceAnalysisMappingServiceKey');
