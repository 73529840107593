import { InjectionKey } from 'vue';
import { TPassportChecksTableRow } from '@/modules/dossier/person/passports/types/passportChecksTableRow';
import { ApiPersonDetailPassportComplexChecks } from '@/modules/dossier/person/common/types/api/apiPersonDetailPassportComplexChecks';
import { ApiPersonDetailPassports } from '@/modules/dossier/person/common/types/api/apiPersonDetailPassports';
import { ApiPersonDetailInnPassportAccordanceChecks } from '@/modules/dossier/person/common/types/api/apiPersonDetailInnPassportAccordanceChecks';

export interface IPersonPassportCheckingMappingService {
  mapPassportCheckingDataTable(
    passportsList: ApiPersonDetailPassports,
    complexChecksList: ApiPersonDetailPassportComplexChecks,
    innChecksList: ApiPersonDetailInnPassportAccordanceChecks,
  ): Array<TPassportChecksTableRow>;
}

export const IPersonPassportCheckingMappingServiceKey: InjectionKey<IPersonPassportCheckingMappingService> =
  Symbol('IPersonPassportCheckingMappingServiceKey');
