import { injectable } from 'inversify';
import { ApiPersonDetailPledgeMemberInfoRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailPledgeMemberInfoRecord';
import { IPersonPledgesRegistriesScoringsPledgersFormatter } from './personPledgesRegistriesScoringsPledgersFormatter.interface';

@injectable()
export default class PersonPledgesRegistriesScoringsPledgersFormatter
  implements IPersonPledgesRegistriesScoringsPledgersFormatter
{
  getPledgersFormattedNames(
    pledgers: ApiPersonDetailPledgeMemberInfoRecord[],
    personFullName: string,
  ): string {
    if (pledgers.length != 0) {
      return pledgers.map((pledger) => pledger.name).join(', ');
    }
    return personFullName;
  }
}
