import { injectable } from 'inversify';
import { IRiskTypeCalculatorService } from '@/modules/risks/services/riskTypeCalculatorService.interfaces';
import { riskType } from '@/modules/risks/types/riskType';

@injectable()
export class RiskTypeCalculatorService implements IRiskTypeCalculatorService {
  calculateRiskType(value: number): riskType {
    if (value <= 50) {
      return riskType.Low;
    }
    if (value > 50 && value <= 100) {
      return riskType.Medium;
    }
    return riskType.High;
  }
}
