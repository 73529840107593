import { injectable } from 'inversify';
import { IPersonJobsNormalizationService } from '@/modules/dossier/person/common/services/normalization/personJobsNormalizationService.interfaces';
import { ApiPersonDetailJobRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailJobRecord';

@injectable()
export default class PersonJobsNormalizationService implements IPersonJobsNormalizationService {
  normalize(item: ApiPersonDetailJobRecord): Required<ApiPersonDetailJobRecord> {
    return {
      id: item.id,
      companyName: item.companyName ?? '',
      companyNameSource: item.companyNameSource ?? '',
      companyInn: item.companyInn ?? '',
      companyAddress: item.companyAddress ?? '',
      companyAddressSource: item.companyAddressSource ?? '',
      companyPhoneSource: item.companyPhoneSource ?? '',
      managersPhoneSource: item.managersPhoneSource ?? '',
      accountantsPhoneSource: item.accountantsPhoneSource ?? '',
      companyPhone: item.companyPhone ?? '',
      departmentName: item.departmentName ?? '',
      departmentCode: item.departmentCode ?? '',
      departmentCodeParent: item.departmentCodeParent ?? '',
      position: item.position ?? '',
      militaryDutyCategory: item.militaryDutyCategory ?? '',
      startDate: item.startDate ?? '',
      startDateStr: item.startDateStr ?? '',
      endDate: item.endDate ?? '',
      endDateStr: item.endDateStr ?? '',
      articleDissmissal: item.articleDissmissal ?? '',
      isWorkingNow: item.isWorkingNow ?? false,
      dossierId: item.dossierId,
      managersPhone: item.managersPhone ?? '',
      accountantsPhone: item.accountantsPhone ?? '',
    };
  }
}
