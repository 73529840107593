import { InjectionKey } from 'vue';
import { ReportHistoryItem } from '@/modules/reports/common/types/reportHistoryItem';

export interface IReportHistoryItemsService {
  hasDownloadPdfLink(rawData: ReportHistoryItem): boolean;
  hasDownloadJsonLink(rawData: ReportHistoryItem): boolean;
  hasWebLink(rawData: ReportHistoryItem): boolean;
  canBeInterrupted(rawData: ReportHistoryItem): boolean;
  canBeMigratedToSystem(rawData: ReportHistoryItem): boolean;
  canBeAddedToGraph(rawData: ReportHistoryItem): boolean;
  canBeExtendedReportGenerated(rawData: ReportHistoryItem): boolean;
}

export const IReportHistoryItemsServiceKey: InjectionKey<IReportHistoryItemsService> = Symbol(
  'IReportHistoryItemsServiceKey',
);
