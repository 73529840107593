import { useEvent } from '@/modules/events/composables/useEvent';

export function useRegulationCheckCompanyMarkersChangedEvent() {
  const { trigger, on } =  useEvent<[companyId: string]>('RegulationCheckCompanyMarkersChanged');

  return {
    triggerRegulationCheckCompanyMarkersChangedEvent: trigger,
    onRegulationCheckCompanyMarkersChanged: on
  }
}
