<template>
  <RouterView />
  <OverlayRoot />
  <NotificationsRoot />
</template>

<script setup lang="ts">
import { useInjectable } from '@/hooks/useInjectable';
import { useAppNotifications } from '@/modules/app/composables/useAppNotifications/useAppNotifications';
import { IEnvironmentSettingsKey } from '@/modules/common/settings/environmentSettings.interfaces';
import NotificationsRoot from '@/modules/notifications/components/NotificationsRoot.vue';
import OverlayRoot from '@/modules/overlay/components/OverlayRoot/OverlayRoot.vue';
import { useAppPlatformClasses } from '@/modules/app/composables/useAppPlatformClasses/useAppPlatformClasses';

const envSettings = useInjectable(IEnvironmentSettingsKey);
console.log('commit hash', envSettings.commitHash);

useAppPlatformClasses();
useAppNotifications();
</script>
