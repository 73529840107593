import { InjectionKey } from 'vue';
import { CompanyParticipation } from '@/modules/dossier/company/common/types/companyParticipation/companyParticipation';
import { CompanyParticipationTableData } from '@/modules/dossier/company/participations/types/companyParticipationTableData';

export interface ICompanyParticipationMappingService {
  getCompanyParticipationTableData(
    participationList: Array<CompanyParticipation>,
  ): Array<CompanyParticipationTableData>;
}

export const ICompanyParticipationMappingServiceKey: InjectionKey<ICompanyParticipationMappingService> =
  Symbol('ICompanyParticipationMappingServiceKey');
