import {
  UIContentTagConfig
} from '@/modules/tags/components/UIContentTag/types/UIContentTagConfig';
import { InjectionKey } from 'vue';

export interface IRiskTagsFactory {
  getTag(rate: number): UIContentTagConfig
}

export const IRiskTagsFactoryKey: InjectionKey<IRiskTagsFactory> = Symbol('IRiskTagsFactory');
