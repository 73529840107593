import {
  IDataGridPersonNameFormatterService
} from '@/modules/dataGrid/person/services/formatters/dataGridPersonNameFormatterService/dataGridPersonNameFormatterService.interfaces';
import { ApiGridPersonRecord } from '@/modules/dataGrid/person/types/api/apiGridPersonRecord';
import { injectable } from 'inversify';

@injectable()
export default class DataGridPersonNameFormatterService implements IDataGridPersonNameFormatterService {
  format(person: ApiGridPersonRecord): string {
    return person.fullname || person.inn || person.snils || ''
  }
}
