import { ListUtils } from '@/modules/common/utils/listUtils';
import { EventHandler, UseEventResult } from '@/modules/events/composables/useEvent.interfaces';
import { tryOnScopeDispose } from '@vueuse/core';

const handlerGroups = new Map<string, EventHandler[]>();

function getHandlersGroup<T extends any[]>(name: string): EventHandler<T>[] {
  let handlers = handlerGroups.get(name);

  if(handlers == undefined) {
    handlers = new Array<EventHandler>();
    handlerGroups.set(name, handlers);
  }

  return handlers;
}

export function useEvent<T extends any[] = []>(name: string): UseEventResult<T> {
  const handlersGroup = getHandlersGroup<T>(name);
  const addedHandlers = new Array<EventHandler<T>>();

  function trigger(...args: T) {
    handlersGroup.forEach(handler => handler(...args));
  }

  function on(handler: EventHandler<T>) {
    handlersGroup.push(handler);
    addedHandlers.push(handler);

    return () => off(handler);
  }

  function off(handler: EventHandler<T>) {
    ListUtils.removeFromList(handlersGroup, handler);
    ListUtils.removeFromList(addedHandlers, handler);
  }

  tryOnScopeDispose(() => {
    addedHandlers.forEach(handler => off(handler));
  });

  return { trigger, on, off }
}
