import { useLogoutEvent } from '@/modules/auth/composables/events/useLogoutEvent/useLogoutEvent';
import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { IAuthService } from '@/modules/auth/common/services/authService.interfaces';
import { ApiUserLoginResult } from '@/modules/auth/common/types/api/apiUserLoginResult';
import { ApiUserLogoutResult } from '@/modules/auth/common/types/api/apiUserLogoutResult';
import { ApiUserData } from '@/modules/administration/users/types/apiUserData';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';
import { useLoginEvent } from '@/modules/auth/common/composables/useLoginEvent';

const { trigger: triggerLoginEvent } = useLoginEvent();

const { triggerLogoutEvent } = useLogoutEvent();

@injectable()
export default class AuthService implements IAuthService {
  constructor(@inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance) {}

  async login(
    email: string,
    password: string,
  ): Promise<AxiosResponse<ApiResponse<ApiUserData | ApiUserLoginResult>>> {
    const data = { email, password };
    const response = await this.apiEndpoint.post('/auth/login', data);
    triggerLoginEvent(response);
    return response;
  }

  async logout(): Promise<AxiosResponse<ApiResponse<ApiUserLogoutResult>>> {
    const result = await this.apiEndpoint.post('/auth/logout');

    triggerLogoutEvent();

    return result;
  }
}
