import { InjectionKey } from 'vue';
import { ApiPersonDetailNegativeWantedsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailNegativeWantedsRecord';
import { ApiPersonDetailNegativeDebtsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailNegativeDebtsRecord';
import { ApiPersonDetailNegativeCriminalsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailNegativeCriminalsRecord';
import { ApiPersonDetailNegativeInfoRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailNegativeInfoRecord';

export interface IPersonNegativeOverviewStatisticsService {
  calculateWantedCasesAmount(wantedsList: Array<ApiPersonDetailNegativeWantedsRecord>): number;
  calculateNegativeMentions(negativeMentionsList: Array<ApiPersonDetailNegativeInfoRecord>): number;
  calculateDebtsAmout(debtsList: Array<ApiPersonDetailNegativeDebtsRecord>): number;
  calculateBlackListCasesAmount(negativeMentionsList: Array<ApiPersonDetailNegativeInfoRecord>): number;
  calculateSpecialTreatmentCases(negativeMentionsList: Array<ApiPersonDetailNegativeInfoRecord>): number;
  calculateCriminalCharges(criminalsList: Array<ApiPersonDetailNegativeCriminalsRecord>): number;
  calculateAdministrativeCharges(criminalsList: Array<ApiPersonDetailNegativeCriminalsRecord>, negativeMentionsList: Array<ApiPersonDetailNegativeInfoRecord>): number;
}

export const IPersonNegativeOverviewStatisticsServiceKey: InjectionKey<IPersonNegativeOverviewStatisticsService> =
  Symbol('IPersonNegativeOverviewStatisticsServiceKey');
