import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { ApiNewUlListData } from '@/modules/lists/types/api/ul/apiNewUlListData';
import { ApiUlListAppendRequest } from '@/modules/lists/types/api/ul/apiUlListAppendRequest';
import { ApiUlListCreationRequest } from '@/modules/lists/types/api/ul/apiUlListCreationRequest';
import { ApiUlListUpdateRequest } from '@/modules/lists/types/api/ul/apiUlListUpdateRequest';
import { ApiUlListData } from '@/modules/lists/types/api/ul/apiUlListData';
import { ApiUlListDetails } from '@/modules/lists/types/api/ul/apiUlListDetails';
import { ApiUlListRemoveItemsRequest } from '@/modules/lists/types/api/ul/apiUlListRemoveItemsRequest';
import { ApiUlListRemoveRequest } from '@/modules/lists/types/api/ul/apiUlListRemoveRequest';
import { ApiUlListsAddTags } from '@/modules/lists/types/api/ul/apiUlListsAddTags';
import { AxiosResponse } from 'axios';
import { InjectionKey } from 'vue';

export interface ICompanyListsService {
  getLists(): Promise<AxiosResponse<ApiResponse<ApiUlListData>>>
  createList(payload: ApiUlListCreationRequest): Promise<AxiosResponse<ApiResponse<ApiNewUlListData>>>
  updateList(payload: ApiUlListUpdateRequest): Promise<AxiosResponse<ApiResponse<string>>>
  addToList(payload: ApiUlListAppendRequest): Promise<AxiosResponse<ApiResponse<string>>>
  deleteList(payload: ApiUlListRemoveRequest): Promise<AxiosResponse<ApiResponse<string>>>
  getListDetails(id: string): Promise<AxiosResponse<ApiResponse<ApiUlListDetails>>>
  deleteEntities(payload: ApiUlListRemoveItemsRequest): Promise<AxiosResponse<ApiResponse<string>>>
  addToCounterParty(payload: ApiUlListsAddTags): Promise<AxiosResponse<ApiResponse<string>>>
  addToCompetitor(payload: ApiUlListsAddTags): Promise<AxiosResponse<ApiResponse<string>>>
  addToGroupCompany(payload: ApiUlListsAddTags): Promise<AxiosResponse<ApiResponse<string>>>
  addToBlackList(payload: ApiUlListsAddTags): Promise<AxiosResponse<ApiResponse<string>>>
}

export const ICompanyListsServiceKey: InjectionKey<ICompanyListsService> = Symbol('ICompanyListsService');
