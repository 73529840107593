import { importFileListSettings } from '@/modules/import/configuration/importFileSettings/importFileListSettings';
import { ProcessingSettings } from '@/modules/processingSettings/types/processingSettings';
import { IImportFileService } from '@/modules/import/services/importFileService.interfaces';
import { ImportListSettings } from '@/modules/import/types/api/importListSettings';
import { ImportFileProcessId } from '@/modules/import/types/api/importFileProcessId';
import { injectable } from 'inversify';
import { Ref } from 'vue';
import { ApiImportProgress } from '@/modules/import/types/api/apiImportProgress';

@injectable()
export default abstract class AImportFileService implements IImportFileService {
  protected getFormDataForImportFile(
    file: Blob,
    listSettings?: ImportListSettings,
    normalizationSettings?: Ref<ProcessingSettings>,
  ): FormData {
    const { defaultListSettings } = importFileListSettings;

    const data = new FormData();
    data.append('file', file);
    data.append('settings', JSON.stringify(normalizationSettings?.value));
    data.append('listData', JSON.stringify(listSettings ?? defaultListSettings));

    return data;
  }

  protected getFormDataForImportFiles(
    file: Blob,
    listSettings?: ImportListSettings,
    normalizationSettings?: Ref<ProcessingSettings>,
  ): FormData {
    const { defaultListSettings } = importFileListSettings;

    const data = new FormData();
    data.append('files', file);
    data.append('settings', JSON.stringify(normalizationSettings?.value));
    data.append('listData', JSON.stringify(listSettings ?? defaultListSettings));

    return data;
  }

  abstract importFile(
    file: Blob,
    listSettings: ImportListSettings | undefined,
  ): Promise<ImportFileProcessId>;
}
