import { inject, injectable } from 'inversify';
import { IYandexMapsApiFactory } from '@/modules/maps/yandexMaps/factories/yandexMapsApiFactory.interfaces';
// @ts-ignore
import { loadYmap } from 'vue-yandex-maps';
import {
  IYandexMapsApiSettings,
  IYandexMapsApiSettingsKey,
} from '@/modules/common/settings/yandexMapsApiSettings.interfaces';

@injectable()
export default class YandexMapsApiFactory implements IYandexMapsApiFactory {
  constructor(
    @inject(IYandexMapsApiSettingsKey as symbol)
    private yandexMapsApiSettings: IYandexMapsApiSettings,
  ) {}

  private get settings() {
    return {
      apiKey: this.yandexMapsApiSettings.apiKey,
      coordorder: this.yandexMapsApiSettings.coordorder,
      debug: this.yandexMapsApiSettings.debug,
      lang: this.yandexMapsApiSettings.lang,
      version: this.yandexMapsApiSettings.version,
    };
  }

  async getYandexMapsAPI(): Promise<any> {
    await loadYmap(this.settings);

    return new Promise((resolve, reject): void => {
      const { ymaps } = <any>window;

      if (ymaps == undefined) {
        reject();
      } else {
        ymaps.ready(resolve(ymaps), reject);
      }
    });
  }
}
