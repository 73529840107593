import { ApiElasticSourceType } from '@/modules/elastic/types/api/apiElasticSourceType';
import { SearchConceptOption } from '@/modules/searchConcept/common/types/searchConceptOption';
import {
  SearchConceptOptionsGroupType
} from '@/modules/searchConcept/common/types/searchConceptOptionsGroupType';
import {
  ICompanySearchConceptOptionsFactory
} from '@/modules/searchConcept/company/factories/companySearchConceptOptionsFactory.interfaces';
import { injectable } from 'inversify';

const companySearchConceptOptionsData: Omit<SearchConceptOption, 'source'>[] = [
  { field: 'shortName', groupType: SearchConceptOptionsGroupType.General },
  { field: 'fullName', groupType: SearchConceptOptionsGroupType.General },
  { field: 'inn', groupType: SearchConceptOptionsGroupType.General },
  { field: 'ogrn', groupType: SearchConceptOptionsGroupType.General },
  { field: 'mainActivity', groupType: SearchConceptOptionsGroupType.General },
  { field: 'address', groupType: SearchConceptOptionsGroupType.CompanyAddress },
  { field: 'phone', groupType: SearchConceptOptionsGroupType.Phones },
  { field: 'tags', groupType: SearchConceptOptionsGroupType.Tags },
  { field: 'socials', groupType: SearchConceptOptionsGroupType.Socials },
  // { type: 'socialsEmployee', groupType: SearchConceptOptionsGroupType.SocialsEmployee },
  { field: 'headFio', groupType: SearchConceptOptionsGroupType.Head },
  { field: 'headInn', groupType: SearchConceptOptionsGroupType.Head },
  { field: 'founderFio', groupType: SearchConceptOptionsGroupType.Founder },
  // { type: 'founderInn', groupType: SearchConceptOptionsGroupType.Founder },
  { field: 'shareholderFio', groupType: SearchConceptOptionsGroupType.ShareHolder },
  // { type: 'trusteeFio', groupType: SearchConceptOptionsGroupType.Trustee },
  // { type: 'documentNumber', groupType: SearchConceptOptionsGroupType.LegalDocuments },
  { field: 'email', groupType: SearchConceptOptionsGroupType.Emails },
  // { type: 'site', groupType: SearchConceptOptionsGroupType.Sites },
  // { type: 'userLists', groupType: SearchConceptOptionsGroupType.Lists },
  // { type: 'driverIds', groupType: SearchConceptOptionsGroupType.CompanyVehicles },
  { field: 'carNumbers', groupType: SearchConceptOptionsGroupType.CompanyVehicles },
  { field: 'carVins', groupType: SearchConceptOptionsGroupType.CompanyVehicles },
  { field: 'carMarks', groupType: SearchConceptOptionsGroupType.CompanyVehicles },
  { field: 'carModels', groupType: SearchConceptOptionsGroupType.CompanyVehicles },
  { field: 'carOwnerFullNames', groupType: SearchConceptOptionsGroupType.CompanyVehicles },
  { field: 'carOwnerPhones', groupType: SearchConceptOptionsGroupType.CompanyVehicles },
  // { type: 'carOwnerInns', groupType: SearchConceptOptionsGroupType.CompanyVehicles },
  { field: 'carOwnerAddresses', groupType: SearchConceptOptionsGroupType.CompanyVehicles },
  // { type: 'isIp', groupType: SearchConceptOptionsGroupType.IpInfo },
  // { type: 'ipType', groupType: SearchConceptOptionsGroupType.IpInfo },
  // { type: 'ipFio', groupType: SearchConceptOptionsGroupType.IpInfo },
  { field: 'ipInn', groupType: SearchConceptOptionsGroupType.IpInfo },
  { field: 'ipOgrn', groupType: SearchConceptOptionsGroupType.IpInfo },
  // { type: 'ipStatus', groupType: SearchConceptOptionsGroupType.IpInfo },
  // { type: 'ipRegion', groupType: SearchConceptOptionsGroupType.IpInfo },
  // { type: 'ipCitizenship', groupType: SearchConceptOptionsGroupType.IpInfo },
  // { type: 'ipEmail', groupType: SearchConceptOptionsGroupType.IpInfo },
  // { type: 'ipPhone', groupType: SearchConceptOptionsGroupType.IpInfo },
  // { type: 'ipMainActivity', groupType: SearchConceptOptionsGroupType.IpInfo },
  // { type: 'ipNote', groupType: SearchConceptOptionsGroupType.IpInfo },
  { field: 'relatedPersonsFio', groupType: SearchConceptOptionsGroupType.CompanyRelatedPersons },
  { field: 'relatedPersonsInn', groupType: SearchConceptOptionsGroupType.CompanyRelatedPersons },
  { field: 'relatedPersonsPassport', groupType: SearchConceptOptionsGroupType.CompanyRelatedPersons },
  { field: 'relatedPersonsAddress', groupType: SearchConceptOptionsGroupType.CompanyRelatedPersons },
  { field: 'relatedPersonsPhone', groupType: SearchConceptOptionsGroupType.CompanyRelatedPersons },
  { field: 'relatedPersonsJob', groupType: SearchConceptOptionsGroupType.CompanyRelatedPersons },
  { field: 'relatedPersonsJobInn', groupType: SearchConceptOptionsGroupType.CompanyRelatedPersons },
]

const companySearchConceptOptions: SearchConceptOption[] = companySearchConceptOptionsData.map(x => ({
  ...x,
  source: ApiElasticSourceType.Company
}));

@injectable()
export default class CompanySearchConceptOptionsFactory implements ICompanySearchConceptOptionsFactory {
  getOptions(): SearchConceptOption[] {
    return companySearchConceptOptions;
  }
}
