import { UseUIConfirmationConfig } from '@/modules/dialogs/components/UIConfirmation/composables/useUIConfirmation/useUIConfirmation.interfaces';
import UIDesktopConfirmation from '@/modules/dialogs/components/UIConfirmation/UIDesktopConfirmation.vue';
import UIMobileConfirmation from '@/modules/dialogs/components/UIConfirmation/UIMobileConfirmation.vue';
import { useOverlay } from '@/modules/overlay/composables/useOverlay';
import useDeviceStore from '@/stores/device';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

export function useUIConfirmation() {
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const component = computed(() => {
    if (isMobile.value) {
      return UIMobileConfirmation;
    }
    return UIDesktopConfirmation;
  });

  const { show } = useOverlay<UseUIConfirmationConfig>({
    component: component.value,
    verticalAlignment: 'center',
    horizontalAlignment: 'center',
    closeOnBackgroundClick: false,
  });

  return {
    showUIConfirmation: show,
  };
}
