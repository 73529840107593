import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IPersonPledgesRegistriesScoringsPledgeholdersFormatterKey } from '@/modules/dossier/person/pledgesRegistries/services/formatters/personPledgesRegistriesScoringsPledgeholdersFormatter.interface';
import PersonPledgesRegistriesScoringsPledgeholdersFormatter from '@/modules/dossier/person/pledgesRegistries/services/formatters/personPledgesRegistriesScoringsPledgeholdersFormatter';
import { IPersonPledgesRegistriesScoringsPledgersFormatterKey } from '@/modules/dossier/person/pledgesRegistries/services/formatters/personPledgesRegistriesScoringsPledgersFormatter.interface';
import PersonPledgesRegistriesScoringsPledgersFormatter from '@/modules/dossier/person/pledgesRegistries/services/formatters/personPledgesRegistriesScoringsPledgersFormatter';

export function bindPersonDossierPledgesRegistriesServices(bind: IContainerBinder) {
  bind(IPersonPledgesRegistriesScoringsPledgeholdersFormatterKey).to(
    PersonPledgesRegistriesScoringsPledgeholdersFormatter,
  );
  bind(IPersonPledgesRegistriesScoringsPledgersFormatterKey).to(
    PersonPledgesRegistriesScoringsPledgersFormatter,
  );
}
