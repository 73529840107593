import { inject, injectable } from 'inversify';
import { ICompanyOgrnValidationService } from '@/modules/validation/services/companyOgrnValidationService.interfaces';
import {
  IMasksService,
  IMasksServiceKey,
} from '@/modules/masks/services/masksService/masksService.interfaces';
import {
  companyOgrnLength,
  companyOgrnMask,
} from '@/modules/validation/configuration/masks/companyOgrnMask';

@injectable()
export default class CompanyOgrnValidationService implements ICompanyOgrnValidationService {
  constructor(@inject(IMasksServiceKey as symbol) private masksService: IMasksService) {}

  validate(value: string): boolean {
    if (!this.masksService.isCompleted(value, companyOgrnMask)) {
      return false;
    }

    return value.length == companyOgrnLength;
  }
}
