import { InjectionKey } from 'vue';
import { ApiArbitrationCase } from '@/modules/dossier/person/common/types/api/apiArbitrationCase';
import { DossierTagData } from '@/modules/common/types/dossierTag';

export interface IPersonArbitrationMappingService {
  getArbitrationByRole(
    arbitrationList: Array<ApiArbitrationCase>,
    role: string,
  ): Array<ApiArbitrationCase>;
  getArbitrationByStatus(
    arbitrationList: Array<ApiArbitrationCase>,
    status: string,
  ): Array<ApiArbitrationCase>;
  getArbitrationStatusTagData(status: string): DossierTagData;
}

export const IPersonArbitrationMappingServiceKey: InjectionKey<IPersonArbitrationMappingService> =
  Symbol('PersonArbitrationMappingServiceKey');
