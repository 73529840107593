import { injectable } from 'inversify';
import { DriverLicenseFormattedTag } from '@/modules/dossier/person/vehicles/driverLicenses/types/driverLicenseFormattedTag';
import { IDriverLicenseSignDeprivationMapper } from '@/modules/dossier/person/vehicles/driverLicenses/services/mappers/personDriverLicenseSignDeprivationMapper.interfaces';

@injectable()
export default class PersonDriverLicenseSignDeprivationMapper
  implements IDriverLicenseSignDeprivationMapper
{
  format(value: boolean): DriverLicenseFormattedTag {
    switch (value) {
      case true:
        return {
          type: 'danger',
          label: 'Есть лишения',
        };
      case false:
        return {
          type: 'primary',
          label: 'Лишений нет',
        };
      default:
        return {
          type: 'secondary',
          label: 'Неизвестно',
        };
    }
  }
}
