import { Notification } from '@/modules/notifications/types/notification';
import { shallowRef } from 'vue';

export function useExclusiveNotifications() {
  const lastNotification = shallowRef<Notification>();

  function processNotification(notification: Notification): Notification {
    lastNotification.value?.close();
    lastNotification.value = notification;

    return notification;
  }

  return {
    processNotification
  }
}
