import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IMessagePointerServiceKey } from '@/modules/messages/plural/messagePointerService.interfaces';
import { MessagePointerService } from '@/modules/messages/plural/messagePointerService';
import { IPluralMessagesServiceKey } from '@/modules/messages/plural/pluralMessagesService.interfaces';
import { PluralMessagesServices } from '@/modules/messages/plural/pluralMessagesService';

export function bindPluralMessagesServices(bind: IContainerBinder) {
  bind(IMessagePointerServiceKey).to(MessagePointerService);
  bind(IPluralMessagesServiceKey).to(PluralMessagesServices);
}
