import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { bindDatesServices } from '@/modules/common/configuration/inversify/container.dates';
import { bindEndpoints } from '@/modules/common/configuration/inversify/container.endpoints';
import { bindNumberServices } from '@/modules/common/modules/numbers/configuration/inversify/container.numbers';
import { bindSettings } from '@/modules/common/configuration/inversify/container.settings';
import {
  bindEventJournalServices
} from '@/modules/eventJournal/configuration/inversify/container.eventJournal';
import CollectionLookupFactory from '@/modules/common/factories/collectionLookupFactory/collectionLookupFactory';
import { ICollectionLookupFactoryKey } from '@/modules/common/factories/collectionLookupFactory/collectionLookupFactory.interfaces';

export function bindCommonServices(bind: IContainerBinder) {
  bindSettings(bind);
  bindEndpoints(bind);
  bindEventJournalServices(bind);
  bindNumberServices(bind);
  bindDatesServices(bind);
  bind(ICollectionLookupFactoryKey).to(CollectionLookupFactory);
}
