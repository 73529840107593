import {
  IPersonListToAnalysisEntityMapper
} from '@/modules/analysis/services/mappers/personListToAnalysisEntityMapper.interfaces';
import { AnalysisEntity } from '@/modules/analysis/types/analysisEntity';
import { AnalysisEntityType } from '@/modules/analysis/types/analysisEntityType';
import { ApiPersonListDataItem } from '@/modules/lists/types/api/fl/apiPersonListDataItem';
import { injectable } from 'inversify';

@injectable()
export default class PersonListToAnalysisEntityMapper implements IPersonListToAnalysisEntityMapper {
  map(item: ApiPersonListDataItem): AnalysisEntity {
    return {
      id: item.id,
      type: AnalysisEntityType.personsList,
      title: item.displayName
    }
  }

}
