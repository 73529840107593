import {
  IExternalParserOptionsGroupTypeFormatter
} from '@/modules/dataCollection/services/formatters/externalParserOptionsGroupTypeFormatter.interfaces';
import {
  ExternalParserOptionsGroupType
} from '@/modules/dataCollection/types/externalParserOptionsGroupType';
import { injectable } from 'inversify';

@injectable()
export default class ExternalParserOptionsGroupTypeFormatter implements IExternalParserOptionsGroupTypeFormatter {
  format(value: ExternalParserOptionsGroupType): string {
    switch (value) {
      case ExternalParserOptionsGroupType.Unknown: return 'Неизвестно';
      case ExternalParserOptionsGroupType.Cronos: return 'Кронос';
      case ExternalParserOptionsGroupType.Av100: return 'Av100';
      case ExternalParserOptionsGroupType.Mvd: return 'МВД';
      case ExternalParserOptionsGroupType.FNS: return 'ФНС';
      case ExternalParserOptionsGroupType.Xneo: return 'Xneo';
      case ExternalParserOptionsGroupType.Damia: return 'Дамиа';
      case ExternalParserOptionsGroupType.Odyssey: return 'Odyssey';
      case ExternalParserOptionsGroupType.Idx: return 'IDX';
      case ExternalParserOptionsGroupType.OK: return 'Одноклассники';
      case ExternalParserOptionsGroupType.VK: return 'Вконтакте';
      case ExternalParserOptionsGroupType.KonturFocus: return 'Контур.Фокус';
      case ExternalParserOptionsGroupType.Bfo: return 'Государственный информационный ресурс Бухгалтерской (Финансовой) отчетности';
    }
  }
}
