import {
  IAddressTypeFormatter
} from '@/modules/dossier/person/common/services/formatters/addressTypeFormatter.interfaces';
import { AddressTypes } from '@/modules/dossier/person/common/types/api/addressTypes';
import { injectable } from 'inversify';

@injectable()
export default class AddressTypeFormatter implements IAddressTypeFormatter {
  format(value: AddressTypes): string {
    switch(value) {
      case AddressTypes.Unknown: return 'Неизвестно';
      case AddressTypes.PermanentRegistration: return 'Адрес регистрации';
      case AddressTypes.ActualInhabitation: return 'Адрес проживания';
      default: return '';
    }
  }
}
