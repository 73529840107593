import { IApiSettings } from '@/modules/common/settings/apiSettings.interfaces';
import {
  IEnvironmentVariables,
  IEnvironmentVariablesKey,
} from '@/modules/common/settings/environmentVariables.interfaces';
import { inject, injectable } from 'inversify';

@injectable()
export default class ApiSettings implements IApiSettings {
  constructor(
    @inject(IEnvironmentVariablesKey as symbol) private environmentVariables: IEnvironmentVariables,
  ) {}

  get v1ApiUrl() {
    return this.environmentVariables.VUE_APP_BASE_URL;
  }

  get rootApiUrl() {
    return this.v1ApiUrl.replace('/api/v1/', '/api/');
  }

  get rootUrl() {
    return this.v1ApiUrl.replace('/api/v1/', '');
  }

  get staticFilesUrl() {
    return this.v1ApiUrl.replace('/api/v1/', '/static/');
  }
  get reportsBaseUrl() {
    return this.environmentVariables.VUE_APP_REPORTS_URL;
  }
}
