import { injectable } from 'inversify';
import { InspectionInfoTypes } from '@/types/companyInspectionsInfo/companyInspectionInfoTypes';
import { InspectionsInfo } from '@/types/companyInspectionsInfo/companyInspectionsInfo';
import { InspectionInfoField } from '@/types/companyInspectionsInfo/companyInspectionInfoField';
import { getInspectionsByTypeResult } from '@/modules/dossier/company/common/types/companyInspections/companyInspections';
import { DateUtils } from '@/utils/dateUtils';
import { ICompanyInspectionsMappingService } from '@/modules/dossier/company/common/services/mappings/companyInspectionsMappingService.interface';
import { DateFormatConfiguration } from '@/configuration/dateFormatConfiguration';

@injectable()
export default class companyInspectionsMappingService implements ICompanyInspectionsMappingService {
  getInspectionsTableRowByTypeAndField(
    target: InspectionInfoTypes | string,
    inspections: Array<InspectionsInfo>,
    field: InspectionInfoField,
  ): getInspectionsByTypeResult {
    const filteredInspections = inspections.filter((inspection) => {
      if (field == 'startDate') {
        const date = DateUtils.formatDate(inspection.startDate, DateFormatConfiguration.year);
        return target == date;
      }
      if (field == 'type') {
        return inspection[field] == target;
      }
      return false;
    });

    const finished = filteredInspections.filter((inspection) => inspection.status == 'Завершено');
    const warningAnnounced = filteredInspections.filter(
      (inspection) => inspection.status == 'Предостережение объявлено',
    );
    const awaitingCompletion = filteredInspections.filter(
      (inspection) => inspection.status == 'Ожидает завершения',
    );

    return {
      type: target,
      count: `${filteredInspections.length}`,
      finished: `${finished.length}`,
      warningAnnounced: `${warningAnnounced.length}`,
      awaitingCompletion: `${awaitingCompletion.length}`,
    };
  }
}
