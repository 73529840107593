enum RouteNames {
  HOME = 'home',
  DATA = 'data-and-analysis',
  DATA_ALL_V2 = 'allV2',
  DATA_ALL_PERSON_LIST = 'person-list',
  DATA_ALL_COMPANY_LIST = 'company-list',
  DATA_ALL_PERSONS = 'persons',
  DATA_GRID_PERSONS_V2 = 'personsV2',
  DATA_GRID_COMPANIES_V2 = 'companiesV2',
  DATA_LISTS_ROOT = 'listsRoot',
  DATA_ALL_LISTS = 'lists',
  DATA_PERSONS_LIST = 'personsList',
  DATA_PERSONS_DETAIL_LIST = 'personsDetailList',
  DATA_PERSONS_DETAIL_LIST_ANALYTICS = 'personsDetailListAnalytics',
  DATA_PERSONS_DETAIL_LIST_TABLE = 'personsDetailListTable',
  DATA_COMPANIES_LIST = 'companiesList',
  DATA_COMPANIES_DETAIL_LIST = 'companiesDetailList',
  DATA_COMPANIES_DETAIL_LIST_ANALYTICS = 'companiesDetailListAnalytics',
  DATA_COMPANIES_DETAIL_LIST_TABLE = 'companiesDetailListTable',
  DATA_GRID = 'dataGrid',
  DATA_ANALYSIS_ANALYS = 'analys',
  ANALYSIS_V2 = 'analysis-v2',
  DATA_ANALYSIS_RELATIONS = 'relations',
  COLLECTION = 'data-collection',
  COMMON_REGULATIONS_CHECK = 'commonRegulationsCheck',
  COMMON_REGULATIONS_CHECK_COMPANY = 'commonCompanyRegulationsCheck',
  COMMON_REGULATIONS_CHECK_PERSON = 'commonPersonRegulationsCheck',
  HISTORY = 'history',
  HISTORY_COMPANY = 'company-common-history',
  HISTORY_PERSON = 'person-common-history',
  PERSON_HISTORY = 'person-history',
  INDIVIDUAL_ENTITY_DATA = 'individual-entity',
  LEGAL_ENTITY_DATA = 'legal-entity',
  LISTS_DATA = 'data-lists',
  IMPORT_DATA = 'data-import',

  ADMINISTRATION_ROOT = 'administrationRoot',
  ADMINISTRATION_USERS = 'administrationUsers',
  ADMINISTRATION_SETTINGS = 'settings',
  ADMINISTRATION_COLLECTING_DATA = 'collectingData',
  ADMINISTRATION_COMPANIES_TYPES = 'companiesTypes',
  ADMINISTRATION_MARKER_WEIGHT = 'markerWeight',
  ADMINISTRATION_STATISTICS = 'statistics',
  ADMINISTRATION_REPORTS = 'reports',
  PERSON = 'person',
  PERSON_COMMON = 'common',
  PERSON_OVERVIEW = 'overview',
  PERSON_CONTACTS_ROOT = 'contactsRoot',
  PERSON_CONTACTS_PHONES = 'contactsPhones',
  PERSON_CONTACTS_MAILS = 'contactsMails',
  PERSON_TAX_DEBT_INFORMATION = 'personTaxDebtInformation',
  PERSON_ADDRESSES = 'addresses',
  PERSON_EDUCATION = 'education',
  PERSON_CONNECTIONS = 'connections',
  PERSON_COUNTERPARTIES_RELATIONS = 'counterpartiesRelationPerson',
  PERSON_BLACK_LIST_RELATIONS = 'blacklistsRelationPerson',
  PERSON_COMPETITORS_RELATIONS = 'competitorsRelationPerson',
  PERSON_INNER_LOOP_TEAM_RELATIONS = 'innerLoopTeamsRelationPerson',
  PERSON_RELATED_PERSONS_RELATIONS = 'relatedPersonsRelationPerson',
  PERSON_RELATIVES = 'relatives',
  PERSON_NEGATIVE_OVERVIEW_ROOT = 'negativeOverview',
  PERSON_NEGATIVE_OVERVIEW_STATISTICS = 'negativeOverviewStatistics',
  PERSON_NEGATIVE_OVERVIEW_CLARIFICATION_DATA = 'negativeOverviewClarification',
  PERSON_EXECUTION_PROCEEDINGS = 'executionProceedings',
  PERSON_ARBITRATION_ROOT = 'arbitrationRootPerson',
  PERSON_ARBITRATION_ANALYTICS = 'arbitrationAnalyticsPerson',
  PERSON_ARBITRATION_CASES = 'arbitrationCasesPerson',
  PERSON_CRIMINAL_CASES = 'criminalCase',
  PERSON_DEBTS_ROOT = 'debts',
  PERSON_DEBTS_ANALYTICS = 'analytics',
  PERSON_DEBTS_DEBTS_LIST = 'debts-list',
  PERSON_WANTED = 'wanted',
  PERSON_CAR_WANTED = 'carWanted',
  PERSON_DRIVING_LICENSES = 'drivingLicenses',
  PERSON_CHECK_REGISTRY = 'checkRegistry',
  PERSON_PARTICIPATION_UL = 'legalParticipation',
  PERSON_PARTICIPATION_IP = 'individualEntrepreneurs',
  PERSON_BANKRUPTCY_OVERVIEW = 'bankruptcyOverview',
  PERSON_BANKRUPTCY_STAGES = 'bankruptcyStages',
  PERSON_PARTICIPATION_ROOT = 'participationRoot',
  PERSON_PARTICIPATION_UL_ROOT = 'legalParticipationRoot',
  PERSON_PARTICIPATION_UL_ANALYTIC = 'legalParticipationAnalytic',
  PERSON_PARTICIPATION_UL_DATA = 'legalParticipationData',
  PERSON_PARTICIPATION_UL_CHECKS = 'legalParticipationChecks',
  PERSON_PARTICIPATION_IP_ROOT = 'individualEntrepreneursRoot',
  PERSON_PARTICIPATION_IP_ANALYTIC = 'individualEntrepreneursAnalytic',
  PERSON_PARTICIPATION_IP_DATA = 'individualEntrepreneursData',
  PERSON_BANKRUPTCY_MESSAGES = 'bankruptcyMessages',
  PERSON_AV100 = 'av100',
  PERSON_MVD = 'mvd',
  PERSON_FNS = 'fns',
  PERSON_XNEO = 'xneo',

  PERSON_ENFORCEMENT_PROCEEDINGS_ROOT = 'personEnforcementProceedings',
  PERSON_ENFORCEMENT_PROCEEDINGS_STATISTICS = 'personEnforcementProceedingsStatistics',
  PERSON_ENFORCEMENT_PROCEEDINGS_DATA = 'personEnforcementProceedingsData',

  PERSON_EVENT_JOURNAL = 'eventJournal',
  PERSON_FINANCIAL_SCORING_ROOT = 'financialScoring',
  PERSON_FINANCIAL_SCORING_ANALYTICS = 'financialScoringAnalytics',
  PERSON_FINANCIAL_SCORING_LIST = 'financialScoringList',
  PERSON_INTEGRITY_SCORING = 'integrityScoring',
  PERSON_DISQUALIFICATION = 'disqualification',
  PERSON_PERSONAL_DATA_CONSENT = 'personalDataConsent',
  PERSON_NUMBER_INITIALS_CONNECTION_CHECK = 'numberInitialsConnectionCheck',
  PERSON_PHONE_NUMBER_BY_INN = 'phoneNumberByInn',
  PERSON_DRIVER_LICENSES_ROOT = 'driverLicensesRoot',
  PERSON_CHECK_PLEDGES_REGISTRY = 'checkPledgesRegistry',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_ROOT = 'additionalSocialNetworkInfoRoot',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_ROOT = 'additionalSocialNetworkInfoVkRoot',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_ACCOUNTS_LIST = 'additionalSocialNetworkInfoVkAccountsList',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_ACCOUNT_ROOT = 'additionalSocialNetworkInfoVkAccountRoot',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_MAIN_INFO = 'additionalSocialNetworkInfoVkMainInfo',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_GEOGRAPHY_OF_CONNECTION = 'additionalSocialNetworkInfoVkGeographyOfConnection',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_GALLERY = 'additionalSocialNetworkInfoVkGallery',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_RELATIVES = 'additionalSocialNetworkInfoVkRelatives',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_FRIENDS = 'additionalSocialNetworkInfoVkFriends',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_LIKERS = 'additionalSocialNetworkInfoVkLikers',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_COMMENTATORS = 'additionalSocialNetworkInfoVkCommentators',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_MARKS_PHOTO = 'additionalSocialNetworkInfoVkMarksPhoto',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_ROOT = 'additionalSocialNetworkInfoOkRoot',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_ACCOUNTS_LIST = 'additionalSocialNetworkInfoOkAccountsList',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_ACCOUNT_ROOT = 'additionalSocialNetworkInfoOkAccountRoot',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_MAIN_INFO = 'additionalSocialNetworkInfoOkMainInfo',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_GALLERY = 'additionalSocialNetworkInfoOkGallery',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_RELATIVES = 'additionalSocialNetworkInfoOkRelatives',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_FRIENDS = 'additionalSocialNetworkInfoOkFriends',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_LIKERS = 'additionalSocialNetworkInfoOkLikers',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_COMMENTATORS = 'additionalSocialNetworkInfoOkCommentators',
  PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_MARKS_PHOTO = 'additionalSocialNetworkInfoOkMarksPhoto',
  PERSON_REGULATIONS_CHECK_ROOT = 'regulationsCheckPerson',
  PERSON_REGULATIONS_CHECK_INIT = 'personRegulationsCheckInit',
  PERSON_REGULATIONS_CHECK_HISTORY_GROUPED = 'personRegulationsCheckHistoryGrouped',
  PERSON_REGULATIONS_CHECK_DETAIL_HISTORY = 'personRegulationsCheckDetailHistory',
  PERSON_REGULATIONS_CHECK_RESULT = 'personRegulationsCheckResult',
  PERSON_REGULATIONS_CHECK_RESULT_AUTO_INDICATORS = 'personRegulationsCheckResultAutoIndicators',
  PERSON_REGULATIONS_CHECK_RESULT_CHECK_REQUIRED_INDICATORS = 'personRegulationsCheckResultCheckRequiredIndicators',
  PERSON_REGULATIONS_CHECK_ACTUAL_INFO = 'personRegulationsCheckActualInfo',
  PERSON_REGULATIONS_CHECK_AUTO_INDICATORS = 'personRegulationsCheckAutoIndicators',
  PERSON_REGULATIONS_CHECK_CHECK_REQUIRED_INDICATORS = 'personRegulationsCheckCheckRequiredIndicators',
  PERSON_SOCIAL_NETWORK_SEARCH = 'socialNetworkSearch',
  PERSON_PARKING_ROOT = 'personParkingRoot',
  PERSON_PARKING_ANALYTIC = 'personParkingAnalytic',
  PERSON_PARKING_ALL = 'personParkingAll',
  PERSON_CAR_ACCIDENT_ROOT = 'carAccidentRoot',
  PERSON_CAR_ACCIDENT_MAIN = 'carAccidentMain',
  PERSON_CAR_ACCIDENT_VIN = 'carAccidentVin',
  PERSON_CAR_ACCIDENT_ANALYTIC = 'carAccidentAnalytic',
  PERSON_CAR_ACCIDENT_LIST = 'carAccidentList',
  PERSON_RESTRICTIONS = 'restrictions',
  PERSON_PLEDGES_REGISTRIES_ROOT = 'pledgeRegistries',
  PERSON_PLEDGES_REGISTRIES_ANALYTICS = 'pledgeRegistriesAnalytics',
  PERSON_PLEDGES_REGISTRIES_LIST = 'pledgeRegistriesList',
  PERSON_FINES_ROOT = 'personFines',
  PERSON_FINES_ANALYTICS = 'personFinesAnalytics',
  PERSON_FINES_DATA = 'personFinesData',
  PERSON_PLEDGES_REGISTRIES_SCORING_LIST = 'pledgeRegistriesScoringList',
  PERSON_FINES = 'fines',
  PERSON_ATTACHMENTS_AND_LINKS = 'attachmentsAndLinks',
  PERSON_AFFILIATED = 'affiliated',
  PERSON_WORK = 'work',
  PERSON_LABOR_WORK = 'laborWork',
  PERSON_PASSPORTS_ROOT = 'passportsRoot',
  PERSON_PASSPORTS_DATA = 'passportsData',
  PERSON_PASSPORTS_CHECKS = 'passportsChecks',
  PERSON_SOCIAL_NETWORKS = 'socialNetworks',
  PERSON_FRIENDS = 'friends',
  PERSON_AIR_TRAVEL = 'airTravel',
  PERSON_MENTIONS = 'mentions',
  PERSON_ANNOUNCEMENTS = 'personAnnouncements',
  PERSON_CHECK = 'check',
  PERSON_FILES_ROOT = 'filesRoot',
  PERSON_FILES_LIST = 'filesList',
  PERSON_FILES_CONSENT_PROCESSING = 'filesConsentProcessing',
  PERSON_BUSINESS_TRIP = 'businessTrip',
  PERSON_CARS_ROOT = 'personCarsRoot',
  PERSON_CARS_ALL = 'personCarsAll',
  PERSON_CARS_ANALYTIC = 'personCarsAnalytic',
  PERSON_VEHICLES = 'vehiclesPerson',
  PERSON_CONTOUR = 'contour',
  PERSON_CRONOS = 'kronos',
  SEARCH_CONCEPT = 'search',
  SEARCH_CONCEPT_PERSONS = 'persons-search',
  SEARCH_CONCEPT_COMPANIES = 'companies-search',
  COMPANY = 'company',
  COMPANY_COMMON = 'commonCompany',
  COMPANY_ADDRESSES = 'addressesCompany',
  COMPANY_CONTACTS_ROOT = 'contactsCompanyRoot',
  COMPANY_CONTACTS_PHONES = 'contactsCompanyPhones',
  COMPANY_CONTACTS_MAILS = 'contactsCompanyMails',
  COMPANY_SOCIALS = 'companySocials',
  COMPANY_MENTIONS = 'companyMentions',
  COMPANY_SITES = 'sitesCompany',
  COMPANY_PRODUCTION = 'productionCompany',
  COMPANY_CONDITION = 'condition',
  COMPANY_JOURNAL = 'eventJournalCompany',
  COMPANY_MARKERS = 'markers',
  COMPANY_TAX_INFORMATION = 'taxInformationCompany',
  COMPANY_SOCIAL_INSURANCE_FUNDS = 'socialInsuranceFundCompany',
  COMPANY_PENSION_INSURANCE_FUNDS = 'pensionInsuranceFundCompany',
  COMPANY_MEDICINE_INSURANCE_FUNDS = 'medicineInsuranceFundCompany',
  COMPANY_EGR_RECORDS = 'EGRRecordsCompany',
  COMPANY_LEADERS = 'leadersCompany',
  COMPANY_CONNECTIONS = 'connectionsCompany',
  COMPANY_CAPITAL = 'capitalCompany',
  COMPANY_BENEFICIARIES = 'beneficiariesCompany',
  COMPANY_BENEFICIARIES_FOUNDERS = 'beneficiariesFoundersCompany',
  COMPANY_BENEFICIARIES_SHAREHOLDERS = 'beneficiariesShareholdersCompany',
  COMPANY_BRANCHES = 'branchesCompany',
  COMPANY_BRANCHES_STATISTIC = 'branchesStatisticCompany',
  COMPANY_BRANCHES_DATA = 'branchesDataCompany',
  COMPANY_NAME_LIST = 'nameListCompany',
  COMPANY_PARTICIPATION_MANAGEMENT = 'participationManagementCompany',
  COMPANY_RELATIONS = 'relationsCompany',
  COMPANY_COUNTERPARTY_RELATIONS = 'counterpartyRelationsCompany',
  COMPANY_BLACK_LIST_RELATIONS = 'blackListRelationsCompany',
  COMPANY_COMPETITOR_RELATIONS = 'competitorRelationsCompany',
  COMPANY_EMPLOYEE_RELATIONS = 'employeeRelationsCompany',
  COMPANY_ACTIVITIES = 'activitiesCompany',
  COMPANY_LICENSES = 'licensesCompany',
  COMPANY_LEASING = 'leasing',
  COMPANY_LEASING_CONTRACTS = 'leasingContracts',
  COMPANY_LEASING_STATISTICS_ROOT = 'leasingStatistics',
  COMPANY_LEASING_STATISTICS_SUBJECTS = 'leasingSubjects',
  COMPANY_LEASING_STATISTICS_LESSORS = 'leasingLessors',

  COMPANY_PLEDGES = 'pledges',
  COMPANY_CERTIFICATES_ROOT = 'certificatesAndDeclarations',
  COMPANY_CERTIFICATES_ANALYTIC = 'certificatesAndDeclarationsAnalytic',
  COMPANY_CERTIFICATES_LIST = 'certificatesAndDeclarationsAllCertificates',
  COMPANY_PLEDGES_STATISTICS = 'pledgesStatistics',
  COMPANY_PLEDGES_LIST = 'pledgesList',
  COMPANY_CERTIFICATES_AND_DECLARATIONS = 'certificatesAndDeclarations',
  COMPANY_TRADEMARKS = 'trademarks',
  COMPANY_PURCHASES = 'purchasesRootCompany',
  COMPANY_PURCHASES_STATISTICS = 'purchasesStatisticsCompany',
  COMPANY_PURCHASES_STATISTICS_MAJOR_SUPPLIERS_AND_CUSTOMERS = 'purchasesStatisticsMajorSuppliersAndCustomersCompany',
  COMPANY_PURCHASES_STATISTICS_MOST_COMMON_PRODUCT = 'purchasesStatisticsMostCommonProductCompany',
  COMPANY_PURCHASES_STATISTICS_EXECUTION_STATUSES = 'purchasesStatisticsExecutionStatusesCompany',
  COMPANY_PURCHASES_PURCHASE_DATA = 'purchasesPurchaseDataCompany',
  COMPANY_SRO_PARTICIPATION = 'SROParticipation',
  COMPANY_SRO_PARTICIPATION_ANALYTICS = 'SROParticipationAnalytics',
  COMPANY_SRO_PARTICIPATION_DATA = 'SROParticipationData',
  COMPANY_ACTIVITIES_ROOT = 'activitiesCompany',
  COMPANY_ACTIVITIES_ANALYTICS = 'activitiesCompanyAnalytics',
  COMPANY_ACTIVITIES_DATA = 'activitiesCompanyAllActivities',
  COMPANY_INSPECTIONS_ROOT = 'inspections',
  COMPANY_INSPECTIONS_DATA = 'inspectionData',
  COMPANY_INSPECTIONS_ANALYTIC_ROOT = 'inspectionsAnalytic',
  COMPANY_INSPECTIONS_ANALYTIC_TYPE = 'inspectionsAnalyticType',
  COMPANY_INSPECTIONS_ANALYTIC_BY_DATE = 'inspectionsAnalyticByDate',
  COMPANY_ARBITRATION_ROOT = 'arbitrationRoot',
  COMPANY_ARBITRATION_STATISTIC = 'arbitrationStatistic',
  COMPANY_ARBITRATION_STATISTIC_RISKS_PLAINTIFF = 'arbitrationStatisticRisksPlaintiff',
  COMPANY_ARBITRATION_STATISTIC_RISKS_DEFENDANT = 'arbitrationStatisticRisksDefendant',
  COMPANY_ARBITRATION_COMPANIES = 'arbitrationCompanies',
  COMPANY_ARBITRATION_LIST = 'arbitrationList',
  COMPANY_GOVERNMENT_CHECKS = 'governmentChecks',
  COMPANY_ENFORCEMENT_PROCEEDINGS_ROOT = 'enforcementProceedings',
  COMPANY_ENFORCEMENT_PROCEEDINGS_STATISTICS = 'enforcementProceedingsStatistics',
  COMPANY_ENFORCEMENT_PROCEEDINGS_DATA = 'enforcementProceedingsData',
  COMPANY_VEHICLES = 'vehiclesCompany',
  COMPANY_VEHICLES_ANALYTICS = 'vehiclesAnalyticsCompany',
  COMPANY_VEHICLES_DATA = 'vehiclesDataCompany',
  COMPANY_VEHICLES_PENALTY = 'vehiclesPenaltyCompany',
  COMPANY_VEHICLES_PARKING = 'vehiclesParkingCompany',
  COMPANY_ACCOUNTING_OLD = 'companyAccountingOld',
  COMPANY_ACCOUNTING_ROOT = 'companyAccountingRoot',
  COMPANY_ACCOUNTING_BALANCE = 'companyAccountingBalance',
  COMPANY_ACCOUNTING_REPORTS_ROOT = 'companyAccountingReportsRoot',
  COMPANY_ACCOUNTING_REPORTS_RESULTS = 'companyAccountingReportsResults',
  COMPANY_ACCOUNTING_REPORTS_USAGE = 'companyAccountingReportsUsage',
  COMPANY_ACCOUNTING_REPORTS_CHANGES = 'companyAccountingReportsChanges',
  COMPANY_ACCOUNTING_REPORTS_MOVEMENTS = 'companyAccountingReportsMovements',
  COMPANY_FINANCE_ANALYSIS = 'financeAnalysisCompany',
  COMPANY_FINANCE_ANALYSIS_ANALYTIC = 'financeAnalysisAnalyticCompany',
  COMPANY_FINANCE_ANALYSIS_ANALYTIC_REVENUE = 'financeAnalysisAnalyticRevenueCompany',
  COMPANY_FINANCE_ANALYSIS_ANALYTIC_LIABILITY = 'financeAnalysisAnalyticLiabilityCompany',
  COMPANY_FINANCE_ANALYSIS_SHORT = 'financeAnalysisShortCompany',
  COMPANY_FINANCE_ANALYSIS_ADVANCED = 'financeAnalysisAdvancedCompany',
  COMPANY_BANK_GUARANTEES = 'bankGuaranteesCompany',
  COMPANY_BANK_GUARANTEES_ANALYTICS = 'bankGuaranteesAnalyticsCompany',
  COMPANY_BANK_GUARANTEES_ALL = 'bankGuaranteesAllCompany',
  COMPANY_BANK_ACCOUNTS = 'bankAccountsCompany',
  COMPANY_BANK_ACCOUNTS_ALL = 'bankAccountsAllCompany',
  COMPANY_BANK_ACCOUNTS_BLOCKED = 'bankAccountsBlockedCompany',
  COMPANY_TAXES = 'taxesCompany',
  COMPANY_TAXES_INDUSTRY_INDICATORS = 'taxesIndustryIndicatorsCompany',
  COMPANY_TAXES_PAID_TAXES = 'taxesPaidTaxesCompany',
  COMPANY_REGULATIONS_CHECK_ROOT = 'regulationsCheckCompany',
  COMPANY_REGULATIONS_CHECK_HISTORY_GROUPED = 'companyRegulationsCheckHistoryGrouped',
  COMPANY_REGULATIONS_CHECK_INIT = 'companyRegulationsCheckInit',
  COMPANY_REGULATIONS_CHECK_DETAIL_HISTORY = 'companyRegulationsCheckDetailHistory',
  COMPANY_REGULATIONS_CHECK_RESULT = 'companyRegulationsCheckResult',
  COMPANY_REGULATIONS_CHECK_RESULT_AUTO_INDICATORS = 'companyRegulationsCheckResultAutoIndicators',
  COMPANY_REGULATIONS_CHECK_RESULT_CHECK_REQUIRED_INDICATORS = 'companyRegulationsCheckResultCheckRequiredIndicators',
  COMPANY_REGULATIONS_CHECK_ACTUAL_INFO = 'companyRegulationsCheckActualInfo',
  COMPANY_REGULATIONS_CHECK_AUTO_INDICATORS = 'companyRegulationsCheckAutoIndicators',
  COMPANY_REGULATIONS_CHECK_CHECK_REQUIRED_INDICATORS = 'companyRegulationsCheckCheckRequiredIndicators',
  COMPANY_CHECK = 'checkCompany',
  COMPANY_FILES_ROOT = 'filesRootCompany',
  COMPANY_FILES_LIST = 'filesListCompany',
  COMPANY_FILES_CONSENT_PROCESSING = 'filesConsentProcessingCompany',
  COMPANY_ATTACHMENTS_AND_LINKS = 'attachmentsAndLinksCompany',
  IMPORT = 'importRootView',
  IMPORT_PERSON_V2 = 'importPersonV2',
  IMPORT_PERSON_MANUAL_V2 = 'importPersonManualV2',
  IMPORT_PERSON_MASS_V2 = 'importPersonMassV2',
  IMPORT_PERSON_FILE_V2 = 'importPersonFileV2',
  IMPORT_COMPANY_V2 = 'importCompanyV2',
  IMPORT_COMPANY_MANUAL_V2 = 'importCompanyManualV2',
  IMPORT_COMPANY_MASS_V2 = 'importCompanyMassV2',
  IMPORT_COMPANY_FILE_V2 = 'importCompanyFileV2',
  IMPORT_ZUP_V2 = 'importZupV2',
  IMPORT_PHOTOS_V2 = 'importPhotosV2',
  COLLECTION_EXTERNAL_DATA = 'collection',
  HISTORY_EXTERNAL_DATA = 'historyExternalData',
  REPORTS_OLD = 'reportsOld',
  AUTH_PAGE = 'authPage',
  API_ACCESS = 'apiAccess',
  GRAPH_VISUALIZATION = 'graphVisualization',
  REPORTS_ROOT_V1 = 'reportsV1',
  REPORTS_ROOT_V2 = 'reportsV2',
  REPORTS_CONFIGURATION_V1 = 'reportsConfigurationV1',
  REPORTS_CONFIGURATION_V2 = 'reportsConfigurationV2',
  REPORTS_CONFIGURATION_PERSON = 'reportsConfigurationPerson',
  REPORTS_CONFIGURATION_COMPANY = 'reportsConfigurationCompany',
  REPORTS_HISTORY_V1 = 'reportsHistoryV1',
  REPORTS_HISTORY_V2 = 'reportsHistoryV2',
  REPORTS_REQUISITE_INPUT_PERSON = 'reportsRequisiteInputPerson',
  REPORTS_REQUISITE_INPUT_COMPANY = 'reportsRequisiteInputCompany',
  PERSON_REPORTS = 'person-reports',
  MOBILE_REPORTS_ROOT = 'mobileReportsRoot',
  MOBILE_REPORTS_CONFIGURATION = 'mobileReportsConfiguration',
  MOBILE_REPORTS_HISTORY = 'mobileReportsHistory',
  MOBILE_REPORTS_REQUISITE_INPUT = 'mobileReportsRequisiteInput',
  MOBILE_REPORTS_PROGRESS = 'mobileReportsProgress',
  IMPORT_ONEC = 'importOnec',
  DASHBOARD = 'dashboard',
}

export default RouteNames;
