import { DxTableColumnInfo } from '@/modules/devExtreme/types/dxTableColumnInfo';
import { InjectionKey } from 'vue';
import { ApiDataTableFieldProfile } from '@/modules/profile/types/api/apiDataTableFieldProfile';

export interface ICompanyProfileToVisibleColumnMapper {
  map(item: ApiDataTableFieldProfile): DxTableColumnInfo;
  mapReverse(item: DxTableColumnInfo): ApiDataTableFieldProfile;
}

export const ICompanyProfileToVisibleColumnMapperKey: InjectionKey<ICompanyProfileToVisibleColumnMapper> =
  Symbol('ICompanyProfileToVisibleColumnMapper');
