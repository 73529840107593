import EndpointsFactory from '@/modules/common/factories/endpoints/endpointsFactory';
import {
  IDaDataSettings,
  IDaDataSettingsKey
} from '@/modules/common/settings/daDataSettings.interfaces';
import { AxiosRequestConfig } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class DaDataEndpointsFactory extends EndpointsFactory {
  constructor(
    @inject(IDaDataSettingsKey as symbol) private daDataSettings: IDaDataSettings
  ) {
    super();
  }

  protected override getConfig(): AxiosRequestConfig {
    return {
      headers: {
        authorization: `Token ${this.daDataSettings.token}`,
        'x-secret': this.daDataSettings.secret,
        'content-type': 'application/json',
      },

      baseURL: this.daDataSettings.baseUrl
    }
  }
}
