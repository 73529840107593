import {
  ICompanyDataGridBatchLoaderService, ICompanyDataGridBatchLoaderServiceKey
} from '@/modules/dataGrid/company/services/companyDataGridBatchLoaderService.interfaces';
import {
  ICompanyDataGridService, ICompanyDataGridServiceKey
} from '@/modules/dataGrid/company/services/companyDataGridService.interfaces';
import {
  IPersonDataGridBatchLoaderService, IPersonDataGridBatchLoaderServiceKey
} from '@/modules/dataGrid/person/services/personDataGridBatchLoaderService.interfaces';
import {
  IPersonDataGridService, IPersonDataGridServiceKey
} from '@/modules/dataGrid/person/services/personDataGridService.interfaces';
import {
  IAnalysisGraphBuildRequestConfig, IAnalysisGraphBuildRequestFactory
} from '@/modules/graph/analysisUnity/factories/analysisGraphBuildRequestFactory.interfaces';
import {
  IChainsService, IChainsServiceKey
} from '@/modules/graph/common/services/chainsService.interfaces';
import {
  ApiGraphCreationQueryPersons
} from '@/modules/graph/table/types/api/apiGraphCreationQueryPersons';
import GraphBuildRequest from '@/modules/graph/unity/types/GraphBuildRequest';
import {
  ICompanyListsEntityIdsExtractionService, ICompanyListsEntityIdsExtractionServiceKey
} from '@/modules/lists/services/companyListsEntityIdsExtractionService.interfaces';
import {
  ICompanyListsService, ICompanyListsServiceKey
} from '@/modules/lists/services/companyListsService.interfaces';
import {
  IPersonListsEntityIdsExtractionService, IPersonListsEntityIdsExtractionServiceKey
} from '@/modules/lists/services/personListsEntityIdsExtractionService.interfaces';
import {
  IPersonListsService, IPersonListsServiceKey
} from '@/modules/lists/services/personListsService.interfaces';
import { inject, injectable } from 'inversify';
import _ from 'lodash';

@injectable()
export default class AnalysisGraphBuildRequestFactory implements IAnalysisGraphBuildRequestFactory {
  constructor(
    @inject(IChainsServiceKey as symbol) private chainsService: IChainsService,
    @inject(IPersonDataGridServiceKey as symbol) private personDataGridService: IPersonDataGridService,
    @inject(ICompanyDataGridServiceKey as symbol) private companyDataGridService: ICompanyDataGridService,
    @inject(IPersonListsServiceKey as symbol) private personListsService: IPersonListsService,
    @inject(ICompanyListsServiceKey as symbol) private companyListsService: ICompanyListsService,
    @inject(IPersonDataGridBatchLoaderServiceKey as symbol) private personsBatchLoader: IPersonDataGridBatchLoaderService,
    @inject(ICompanyDataGridBatchLoaderServiceKey as symbol) private companiesBatchLoader: ICompanyDataGridBatchLoaderService,
    @inject(IPersonListsEntityIdsExtractionServiceKey as symbol) private personListsIdsExtractor: IPersonListsEntityIdsExtractionService,
    @inject(ICompanyListsEntityIdsExtractionServiceKey as symbol) private companyListsIdsExtractor: ICompanyListsEntityIdsExtractionService
  ) { }

  async buildRequestAsync(config: IAnalysisGraphBuildRequestConfig): Promise<GraphBuildRequest> {
    const {
      chains,
      request,
      hiddenPersons = [],
      type = 0
    } = config;

    const personIds = this.chainsService.getPersonIds(chains);
    const persons = await this.personsBatchLoader.getItemsAsync(personIds);

    const ulIds = this.chainsService.getCompanyIds(chains);
    const uls = await this.companiesBatchLoader.getItemsAsync(ulIds);

    const requestPersonIds = await this.getPersonIdsFromRequestAsync(request);
    const requestCompanyIds = await this.getCompanyIdsFromRequest(request);

    return {
      persons,
      uls,
      chains: chains,
      parameters: {
        persons: requestPersonIds,
        uls: requestCompanyIds,
        hiddenPersons,
        relationsTypes: request.relationsTypes,
        specificities: request.lookingForIndividualTypes,
        type
      },
    };
  }

  private async getPersonIdsFromRequestAsync(request: ApiGraphCreationQueryPersons) {
    const personIds = request.selectedIdListPerson;
    const listPersonIds = await this.personListsIdsExtractor.getIdsAsync(request.selectedIdListPersonList);

    return _.uniq([...personIds, ...listPersonIds]);
  }

  private async getCompanyIdsFromRequest(request: ApiGraphCreationQueryPersons) {
    const companyIds = request.selectedIdListCompany;
    const listCompanyIds = await this.companyListsIdsExtractor.getIdsAsync(request.selectedIdListCompanyList);

    return _.uniq([...companyIds, ...listCompanyIds]);
  }
}
