import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { ApiPersonDetail } from '@/modules/dossier/person/common/types/api/apiPersonDetail';
import { AxiosResponse } from 'axios';
import { InjectionKey } from 'vue';

export interface IPeopleService {
  getPerson(id: string): Promise<AxiosResponse<ApiResponse<ApiPersonDetail>>>
  getJsonReport(personId: string): Promise<AxiosResponse>
}

export const IPeopleServiceKey: InjectionKey<IPeopleService> = Symbol('IPeopleService');
