import { injectable } from 'inversify';
import { ICollectionLookupFactory } from '@/modules/common/factories/collectionLookupFactory/collectionLookupFactory.interfaces';

@injectable()
export default class CollectionLookupFactory implements ICollectionLookupFactory {
  buildLookup<T>(values: Array<T>, format: (value: T) => string): (input: string) => T[] {
    const items = values.map((value) => ({ value, label: format(value).toLowerCase() }));

    return (input: string): Array<T> => {
      const normalizedInput = input.toLowerCase();
      return items.filter((item) => item.label.includes(normalizedInput)).map((item) => item.value);
    };
  }
}
