import { injectable } from 'inversify';
import { AnalyticsTypes } from '@/modules/dossier/person/financialScorings/types/financialAnalyticsTypes';
import { IAnalyticsTypeParserService } from './analyticsTypeParserService.interface';

@injectable()
export default class AnalyticsTypeParserService implements IAnalyticsTypeParserService {
  parseAnalyticType(type: string): AnalyticsTypes {
    switch (true) {
      case type.includes('FICO'):
        return AnalyticsTypes.FICO;
      case type.includes('ОКБ'):
        return AnalyticsTypes.OKB;
      default:
        return AnalyticsTypes.CLASSIC;
    }
  }
}
