import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { ITasksService } from '@/modules/tasks/services/tasksService/tasksService.interfaces';
import { ApiTaskStatusInfo } from '@/modules/tasks/types/api/apiTaskStatusInfo';
import { AxiosInstance } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class TasksService implements ITasksService {
  constructor(@inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance) {}

  async getStatus(taskId: string): Promise<ApiTaskStatusInfo> {
    const response = await this.apiEndpoint.get<ApiResponse<ApiTaskStatusInfo>>(
      '/process-task/status',
      {
        params: { taskId },
      },
    );

    return response.data.data;
  }
}
