import { Ref, toRaw } from 'vue';

export module ListUtils {
  export function removeFromList<T>(list: T[], item: T) {
    const idx = list.indexOf(item);

    if(idx != -1)
      list.splice(idx, 1);
  }

  export function removeFromListBy<T>(list: T[], finder: (item: T) => boolean) {
    let idx = list.findIndex(finder);

    while (idx != -1) {
      list.splice(idx, 1);
      idx = list.findIndex(finder);
    }
  }

  export function removeFromRefList<T>(list: Ref<T[]>, item: T) {
    const rawItem = toRaw(item);

    list.value = list.value.filter(x => toRaw(x) != rawItem);
  }

  export function someRef<T>(list: Ref<T[]>, item: T) {
    const rawItem = toRaw(item);

    return list.value.some(x => toRaw(x) == rawItem);
  }

  export function compareLists<T>(list1: Array<T>, list2: Array<T>, comparator?: (left: T, right: T) => boolean): boolean {
    if(list1.length != list2.length)
      return false;

    const comparatorOrDefault = comparator ?? ((left:T, right:T) => left === right);

    for(const idx of list1.keys()) {
      if(!comparatorOrDefault(list1[idx], list2[idx]))
        return false;
    }

    return true;
  }
}
