import { useTasksTracking } from '@/modules/tasks/composables/useTasksTracking/useTasksTracking';
import { defineStore } from 'pinia';

// GlobalTasksStore служит для глобальной регистрации задачи. т.е. он для тасок которые должны отслеживаться не зависимо от наличия UI для отображения их состояния
export const useGlobalTasksStore = defineStore('GlobalTasksStore', () => {
  const { registerTask, registerTasks } = useTasksTracking();

  return {
    registerTask,
    registerTasks,
  };
});
