import { InjectionKey } from 'vue';
import { ImageGroupItem } from '@/modules/images/types/imageGroupItem';
import { ApiPersonDetailOkRecordPhotoItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordPhotoItem';

export interface IPersonSocialNetworkAdditionalInfoOkPhotosMappingService {
  mapSocialNetworkAdditionalInfoOkPhotos(
    photos: Array<ApiPersonDetailOkRecordPhotoItem>,
  ): Array<ImageGroupItem>;
}

export const IPersonSocialNetworkAdditionalInfoOkPhotosMappingServiceKey: InjectionKey<IPersonSocialNetworkAdditionalInfoOkPhotosMappingService> =
  Symbol('IPersonSocialNetworkAdditionalInfoOkPhotosMappingServiceKey');
