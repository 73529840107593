export enum AnalysisRelationClusterType {
  unknown = 0,
  jobs = 1,
  affiliation = 2,
  relatedPersons = 3,
  movableProperty = 4,
  socialNetworks = 5,
  birthplace = 6,
  contacts = 7,
  movements = 8,
  education = 9
}
