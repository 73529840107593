import {
  IRegulationChecksHistoryItemDTOMappingService
} from '@/modules/regulationsCheck/common/services/regulationChecksHistoryItemDTOMappingService/regulationChecksHistoryItemDTOMappingService.interfaces';
import {
  RegulationsChecksHistoryItemDTO
} from '@/modules/regulationsCheck/common/types/dto/regulationChecksHistoryItemDTO';
import {
  RegulationsChecksHistoryItem
} from '@/modules/regulationsCheck/common/types/regulationsChecksHistoryItem';
import { DateUtils } from '@/utils/dateUtils';
import { injectable } from 'inversify';

@injectable()
export default class RegulationChecksHistoryItemDTOMappingService implements IRegulationChecksHistoryItemDTOMappingService {
  map(item: RegulationsChecksHistoryItem): RegulationsChecksHistoryItemDTO {
    return {
      ...item,

      comment: item.comment || '',
      created: new Date(item.created),
      modified: DateUtils.parseOptionalDate(item.modified),
      generationEnd: DateUtils.parseOptionalDate(item.generationEnd)
    }
  }

  mapReverse(item: RegulationsChecksHistoryItemDTO): RegulationsChecksHistoryItem {
    return {
      ...item,

      created: item.created.toISOString(),
      modified: item.modified?.toISOString(),
      generationEnd: item.generationEnd?.toISOString()
    }
  }

}
