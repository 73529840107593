import { IPersonPassportNormalizationService } from '@/modules/dossier/person/passports/services/normalization/personPassportNormalizationService.interfaces';
import { injectable } from 'inversify';
import { ApiPersonDetailPassportsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailPassportsRecord';

@injectable()
export default class PersonPassportNormalizationService
  implements IPersonPassportNormalizationService
{
  normalize(item: ApiPersonDetailPassportsRecord): Required<ApiPersonDetailPassportsRecord> {
    return {
      id: item.id,
      passportType: item.passportType,
      passportTypeStr: item.passportTypeStr ?? '',
      source: item.source ?? '',
      verificationCode: item.verificationCode ?? '',
      divisionСodeIncoming: item.divisionСodeIncoming ?? '',
      departmentDadataType: item.departmentDadataType ?? '',
      regionCode: item.regionCode ?? '',
      passportSeries: item.passportSeries ?? '',
      passportNumber: item.passportNumber ?? '',
      passportIssuedDate: item.passportIssuedDate ?? '',
      passportIssuedDateStr: item.passportIssuedDateStr ?? '',
      passportIssuedBy: item.passportIssuedBy ?? '',
      passportDepartmentCode: item.passportDepartmentCode ?? '',
    };
  }
}
