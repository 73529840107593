import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { GridOutput } from '@/modules/common/types/api/gridOutput';
import { ApiGridColumnInput } from '@/modules/dataGrid/common/types/api/apiGridColumnInput';
import {
  ApiGridFilterColumnValueDistinct
} from '@/modules/dataGrid/common/types/api/apiGridFilterColumnValueDistinct';
import { ApiGridInput } from '@/modules/dataGrid/common/types/api/apiGridInput';
import {
  ICompanyDataGridService
} from '@/modules/dataGrid/company/services/companyDataGridService.interfaces';
import { ApiGridUlRecord } from '@/modules/dataGrid/company/types/api/apiGridUlRecord';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class CompanyDataGridService implements ICompanyDataGridService {
  constructor(@inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance) {
  }

  getContent(payload: ApiGridInput): Promise<AxiosResponse<ApiResponse<GridOutput<ApiGridUlRecord>>>> {
    return this.apiEndpoint.post(`/grid/main/ul`, payload);
  }

  getColumnValues(payload: ApiGridColumnInput): Promise<AxiosResponse<ApiResponse<ApiGridFilterColumnValueDistinct[]>>> {
    return this.apiEndpoint.post('/grid/main/ul/column/values', payload);
  }
}
