import { injectable } from 'inversify';
import { IReportsTypeService } from '@/modules/reports/common/services/reportsTypeService.interfaces';
import { ReportTypeInput } from '@/modules/reports/common/types/ReportTypeInput';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';

@injectable()
export default class ReportsTypeService implements IReportsTypeService {
  getIconByType(reportType: ReportTypeInput): SystemIconsNames {
    switch (reportType) {
      case ReportTypeInput.carNumber:
        return SystemIconsNames.reportsRequisitesCar;
      case ReportTypeInput.inn:
        return SystemIconsNames.reportsRequisitesInn;
      case ReportTypeInput.phone:
        return SystemIconsNames.reportsRequisitesPhone;
      case ReportTypeInput.photo:
      case ReportTypeInput.fullName:
      default:
        return SystemIconsNames.reportsRequisitesFullName;
    }
  }
}
