import { DossierTagData } from '@/modules/common/types/dossierTag';
import { InjectionKey } from 'vue';
import { DossierTagWithTooltip } from '@/modules/common/types/dossierTagWithTooltip';

export interface IPersonContactsPhoneVerificationCodeService {
  getPhoneVerificationCodeTagData(
    phoneVerificationCode: string | undefined,
  ): DossierTagData | DossierTagWithTooltip;
}

export const IPersonContactsPhoneVerificationCodeServiceKey: InjectionKey<IPersonContactsPhoneVerificationCodeService> =
  Symbol('IPersonContactsPhoneVerificationCodeServiceKey');
