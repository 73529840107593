import { injectable } from 'inversify';
import { IPersonEducationsNormalizationService } from '@/modules/dossier/person/common/services/normalization/personEducationsNormalizationService.interfaces';
import { ApiPersonDetailEducationRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailEducationRecord';

@injectable()
export default class PersonEducationsNormalizationService
  implements IPersonEducationsNormalizationService
{
  normalize(item: ApiPersonDetailEducationRecord): Required<ApiPersonDetailEducationRecord> {
    return {
      id: item.id,
      educationName: item.educationName ?? '',
      diplomaSeries: item.diplomaSeries ?? '',
      diplomaNumber: item.diplomaNumber ?? '',
      educationStart: item.educationStart ?? '',
      educationStartStr: item.educationStartStr ?? '',
      educationEnd: item.educationEnd ?? '',
      educationEndStr: item.educationEndStr ?? '',
      specialtyName: item.specialtyName ?? '',
      educationProgramName: item.educationProgramName ?? '',
      educationFaculty: item.educationFaculty ?? '',
      educationForm: item.educationForm ?? '',
    };
  }
}
