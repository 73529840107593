import { uniqueId } from 'lodash';

const uiIconUrlPatternRegex = /url\(#pattern*/;

export function useUIIconSvgTransform() {
  function changeSvgPatternId(element: Element, patternId: string) {
    if (element.childElementCount > 0) {
      [...element.children].forEach((child) => {
        changeSvgPatternId(child, patternId);
      });
    }

    const elementFillAttr = element.getAttributeNS(null, 'fill') ?? '';

    if (uiIconUrlPatternRegex.test(elementFillAttr)) {
      element.setAttributeNS(null, 'fill', `url(#${patternId})`);
    }

    if (element.nodeName == 'pattern') {
      element.setAttributeNS(null, 'id', patternId);
    }
  }

  function transformSvg(svg: SVGElement) {
    const uniquePatternId = uniqueId('ui-icon-pattern-');

    changeSvgPatternId(svg, uniquePatternId);

    return svg;
  }

  return {
    transformSvg,
  };
}
