import { injectable } from 'inversify';
import { ICompanyContactsMappingService } from '@/modules/dossier/company/contacts/services/companyContactsMappingService.interface';
import { TDetailsCompanyContactsList } from '@/types/companyData/companyData';
import { ContactTypes } from '@/modules/dossier/company/contacts/types/contactTypes';

@injectable()
export default class CompanyContactsMappingService implements ICompanyContactsMappingService {
  getMailsTableData(
    contacts: Array<TDetailsCompanyContactsList>,
  ): Array<TDetailsCompanyContactsList> {
    const mails = contacts.filter((contact) => contact.contactType == ContactTypes.email);
    return mails
      .sort((a, b) => {
        if (a.isHistoric == b.isHistoric) {
          if (a.emailVerificationCode == b.emailVerificationCode) {
            return Number(a.companiesWithThisContact) - Number(b.companiesWithThisContact);
          }
          if (
            a.emailVerificationCode == 'Некорректное значение' ||
            a.emailVerificationCode == 'Пустое или заведомо «мусорное» значение'
          ) {
            return -1;
          }
          if (
            b.emailVerificationCode == 'Некорректное значение' ||
            b.emailVerificationCode == 'Пустое или заведомо «мусорное» значение'
          ) {
            return 1;
          }
          if (
            a.emailVerificationCode == 'Временная электронная почта' ||
            a.emailVerificationCode == '«Одноразовый» адрес'
          ) {
            return -1;
          }
          if (
            b.emailVerificationCode == 'Временная электронная почта' ||
            b.emailVerificationCode == '«Одноразовый» адрес'
          ) {
            return 1;
          }
          if (
            a.emailVerificationCode == 'Адрес распознан уверенно' ||
            a.emailVerificationCode == 'Корректное значение'
          ) {
            return -1;
          }
          if (
            b.emailVerificationCode == 'Адрес распознан уверенно' ||
            b.emailVerificationCode == 'Корректное значение'
          ) {
            return 1;
          }
          if (a.emailVerificationCode == 'Неизвестный') {
            return -1;
          }
          if (b.emailVerificationCode == 'Неизвестный') {
            return 1;
          }
        }
        return a.isHistoric ? 1 : -1;
      })
      .map((item) => ({
        companiesWithThisContact:
          item.companiesWithThisContact == null ? '0' : item.companiesWithThisContact,
        contactType: item.contactType,
        from: item.from,
        value: item.value,
        contactSubType: item.contactSubType,
        phoneVerificationCode: item.phoneVerificationCode,
        country: item.country,
        created: item.created,
        domainEmail: item.domainEmail,
        emailType: item.emailType,
        emailVerificationCode: item.emailVerificationCode,
        fromStr: item.fromStr,
        isHistoric: item.isHistoric,
        id: item.id,
        isHistoricStr: item.isHistoricStr,
        localEmail: item.localEmail,
        modified: item.modified,
        phoneType: item.phoneType,
        provider: item.provider,
        source: item.source,
        tags: item.tags,
        timeZone: item.timeZone,
        toStr: item.toStr,
        to: item.to,
      }));
  }

  getPhonesTableData(
    contacts: Array<TDetailsCompanyContactsList>,
  ): Array<TDetailsCompanyContactsList> {
    const phones = contacts.filter((contact) => contact.contactType == ContactTypes.phone);
    return phones
      .sort((a, b) => {
        if (a.isHistoric == b.isHistoric) {
          if (a.phoneVerificationCode == b.phoneVerificationCode) {
            return Number(a.companiesWithThisContact) - Number(b.companiesWithThisContact);
          }
          if (a.phoneVerificationCode == 'Телефон пустой или заведомо «мусорный»') {
            return -1;
          }
          if (b.phoneVerificationCode == 'Телефон пустой или заведомо «мусорный»') {
            return 1;
          }
          if (
            a.phoneVerificationCode ==
            'Исходное значение распознано с допущениями или не распознано'
          ) {
            return -1;
          }
          if (
            b.phoneVerificationCode ==
            'Исходное значение распознано с допущениями или не распознано'
          ) {
            return 1;
          }
          if (
            a.phoneVerificationCode == 'Российский телефон, распознан уверенно' ||
            a.phoneVerificationCode == 'Телефон распознан уверенно'
          ) {
            return -1;
          }
          if (
            b.phoneVerificationCode == 'Российский телефон, распознан уверенно' ||
            b.phoneVerificationCode == 'Телефон распознан уверенно'
          ) {
            return 1;
          }
          if (a.phoneVerificationCode == 'Неизвестный') {
            return -1;
          }
          if (b.phoneVerificationCode == 'Неизвестный') {
            return 1;
          }
        }
        return a.isHistoric ? 1 : -1;
      })
      .map((item) => ({
        companiesWithThisContact:
          item.companiesWithThisContact == null ? '0' : item.companiesWithThisContact,
        contactType: item.contactType,
        from: item.from,
        value: item.value,
        contactSubType: item.contactSubType,
        phoneVerificationCode: item.phoneVerificationCode,
        country: item.country,
        created: item.created,
        domainEmail: item.domainEmail,
        emailType: item.emailType,
        emailVerificationCode: item.emailVerificationCode,
        fromStr: item.fromStr,
        isHistoric: item.isHistoric,
        id: item.id,
        isHistoricStr: item.isHistoricStr,
        localEmail: item.localEmail,
        modified: item.modified,
        phoneType: item.phoneType,
        provider: item.provider,
        source: item.source,
        tags: item.tags,
        timeZone: item.timeZone,
        toStr: item.toStr,
        to: item.to,
      }));
  }
}
