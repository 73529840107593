import { createStore } from 'vuex';
import ImportManualPersonModule from '@/store/modules/ImportManuals/ImportManualPerson';
import ImportManualCompaniesModule from '@/store/modules/ImportManuals/ImportManualCompanies';
import type { RootState } from './type';
import { StatisticsModule } from './modules/Statistics';
import { GridMainModule } from './modules/GridMain';
import { GridPinsModule } from './modules/GridPins';
import { ToastModule } from './modules/Toast';
import { DataCollectionPageModule } from './modules/DataCollectionPage';
import { EasyDataParserModule } from './modules/EasyDataParser';
import { CompaniesTypesModule } from './modules/CompaniesTypes';

import type { StatisticsStore } from './modules/Statistics';
import type { GridMainStore } from './modules/GridMain';
import type { GridPinsStore } from './modules/GridPins';
import type { ToastStore } from './modules/Toast';
import type { DataCollectionPageStore } from './modules/DataCollectionPage';
import type { EasyDataParserStore } from './modules/EasyDataParser';
import type { CompaniesTypesStore } from './modules/CompaniesTypes';
import type { AdminMarkersStore } from './modules/Administration';

import { AdminMarkersModule } from './modules/Administration';

export type Store = StatisticsStore<Pick<RootState, 'StatisticsModule'>> &
  GridMainStore<Pick<RootState, 'GridMainModule'>> &
  GridPinsStore<Pick<RootState, 'GridPinsModule'>> &
  ToastStore<Pick<RootState, 'ToastModule'>> &
  DataCollectionPageStore<Pick<RootState, 'DataCollectionPageModule'>> &
  EasyDataParserStore<Pick<RootState, 'EasyDataParserModule'>> &
  CompaniesTypesStore<Pick<RootState, 'CompaniesTypesModule'>> &
  AdminMarkersStore<Pick<RootState, 'AdminMarkersModule'>>;

const store = createStore({
  modules: {
    StatisticsModule,
    GridMainModule,
    GridPinsModule,
    ToastModule,
    ImportManualPersonModule,
    ImportManualCompaniesModule,
    DataCollectionPageModule,
    EasyDataParserModule,
    CompaniesTypesModule,
    AdminMarkersModule,
  },
});

export function useStore(): Store {
  return store as Store;
}

export default store;
