import { InjectionKey } from 'vue';
import { ApiPersonDetailIndividualEntrepreneursRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailIndividualEntrepreneursRecord';

export interface IPersonParticipationIpAnalyticService {
  getActivitiesList(activities: string): Array<string>;
  calculateActiveIp(
    legalEntitiesParticipationList: Array<ApiPersonDetailIndividualEntrepreneursRecord>,
  ): number;
  calculateInactiveIp(
    legalEntitiesParticipationList: Array<ApiPersonDetailIndividualEntrepreneursRecord>,
  ): number;
}

export const IPersonParticipationIpAnalyticServiceKey: InjectionKey<IPersonParticipationIpAnalyticService> =
  Symbol('IPersonParticipationIpAnalyticService');
