import { injectable } from 'inversify';
import { NegativeCriminal } from '@/modules/dossier/person/negativeCriminals/types/negativeCriminal';
import { NegativeCriminalTableRow } from '@/modules/dossier/person/negativeCriminals/types/negativeCriminalTableRow';
import { INegativeCriminalService } from '@/modules/dossier/person/negativeCriminals/services/personNegativeCriminalService.interface';

@injectable()
export default class personNegativeCriminalService implements INegativeCriminalService {
  private strToList(str: string | undefined): Array<string> {
    return str == undefined ? [] : str.split(';').filter((activity) => activity !== '');
  }

  prepareNegativeCriminalTable(negativeCriminal: NegativeCriminal[]): NegativeCriminalTableRow[] {
    return negativeCriminal.map((item) => ({
      criminalArticle: item.criminalArticle || '',
      criminalPart: item.criminalPart || '',
      criminalPoint: this.strToList(item.criminalPoint),
      articleContent: item.articleContent || '',
      criminalDate: item.criminalDate || '',
      criminalYear: item.criminalYear || '',
      criminalDateInitiation: item.criminalDateInitiation || '',
      criminalNumber: item.criminalNumber || '',
      criminalNote: this.strToList(item.criminalNote),
      criminalNickname: item.criminalNickname || '',
      criminalSigns: item.criminalSigns || '',
    }));
  }
}
