import { MaybeRefFields } from '@/modules/common/types/maybeRefFields';
import { useOverlayStore } from '@/modules/overlay/stores/overlayStore';
import { OverlayLayerConfig } from '@/modules/overlay/types/overlayLayerConfig';

export function useOverlay<T>(layerConfig: OverlayLayerConfig) {
  const overlayStore = useOverlayStore();

  function show(componentConfig: MaybeRefFields<T>) {
    overlayStore.addLayer({
      ...layerConfig,
      componentConfig
    });
  }

  return {
    show
  }
}
