import {
  IDxPdfExternalFontsService
} from '@/modules/devExtreme/export/pdf/services/dxPdfExternalFontsService/dxPdfExternalFontsService.interfaces';
import { DxPdfExternalFont } from '@/modules/devExtreme/export/pdf/types/dxPdfExternalFont';
import { injectable } from 'inversify';
import { jsPDF } from 'jspdf';

@injectable()
export default class DxPdfExternalFontsService implements IDxPdfExternalFontsService {
  async setupFontAsync(doc: jsPDF, font: DxPdfExternalFont): Promise<void> {
    const fontContent = await this.fetchFontAsync(font.url);
    const createdFileName = `${font.name}-${font.style}.ttf`;

    doc.addFileToVFS(createdFileName, fontContent);
    doc.addFont(createdFileName, font.name, font.style);
  }

  private async fetchFontAsync(fontUrl: string): Promise<string> {
    const response = await fetch(fontUrl);
    const blob = await response.blob();

    const fileReader = new FileReader();

    return await new Promise<string>(resolve => {
      fileReader.onload = e => {
        const content = (e.target?.result as string | undefined) ?? '';
        const normalizedContent = content.substring(content.indexOf('base64,') + 7);

        resolve(normalizedContent);
      }

      fileReader.readAsDataURL(blob);
    });
  }
}
