import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { ParserInputDataType } from '@/modules/dataCollection/types/api/parserInputDataType';
import { ApiEntityDetailFilesRecord } from '@/modules/import/types/api/apiEntityDetailFilesRecord';
import { ApiImportProgress } from '@/modules/import/types/api/apiImportProgress';
import { MainEntityFile } from '@/modules/import/types/api/mainEntityFile';
import { AxiosResponse } from 'axios';
import { InjectionKey } from 'vue';

export interface IDataImportService {
  makeProcessingZup(body: FormData): Promise<AxiosResponse<ApiResponse<string[]>>>;
  makeProcessingOnec(body: FormData): Promise<AxiosResponse<ApiResponse<string>>>;
  importZupProgressPercent(
    importProgressId: string,
  ): Promise<AxiosResponse<ApiResponse<ApiImportProgress>>>;
  loadMainPhotoCompany(file: File, entityId: string): Promise<AxiosResponse<undefined>>;
  loadMainPhotoPerson(file: File, entityId: string): Promise<AxiosResponse<undefined>>;
  addFile(
    body: FormData,
    inputDataType: ParserInputDataType,
  ): Promise<AxiosResponse<ApiResponse<ApiEntityDetailFilesRecord[]>>>;
  addFileConcent(body: FormData): Promise<AxiosResponse<ApiResponse<ApiEntityDetailFilesRecord[]>>>;
  removeFile(
    idFile: string,
    inputDataType: ParserInputDataType,
    personId?: string,
  ): Promise<AxiosResponse<ApiResponse<MainEntityFile[]>>>;
  removeFileConcent(idFile: string, personId: string): Promise<AxiosResponse<ApiResponse<MainEntityFile[]>>>;
  loadPhotoPerson(body: FormData): Promise<AxiosResponse<undefined>>;
  loadPhotoCompanies(body: FormData): Promise<AxiosResponse<undefined>>;
}

export const IDataImportServiceKey: InjectionKey<IDataImportService> = Symbol('IDataImportService');
