import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ProcessingSettings } from '@/modules/processingSettings/types/processingSettings';
import { processingSettingsDefaultConfig } from '@/modules/processingSettings/configuration/processingSettingsDefaultConfig';

export const useProcessingSettingsStore = defineStore('processSettings', () => {
  const settings = ref<ProcessingSettings>(processingSettingsDefaultConfig.defaultSettings);

  return {
    settings,
  };
});
