import { injectable } from 'inversify';
import { IPersonInnRequisiteMatchingService } from '@/modules/requisites/services/matchers/personInnRequisiteMatchingService.interfaces';

@injectable()
export class PersonInnRequisiteMatchingService implements IPersonInnRequisiteMatchingService {
  match(value: string): boolean {
    const regExpInnInclude = /^\d{0,12}$/g;
    const result = value.match(regExpInnInclude);
    return Array.isArray(result) && value.length != 0;
  }

  matchPartial(value: string): boolean {
    const regExpInnInclude = /^\d{12}$/;
    const result = value.match(regExpInnInclude);
    return Array.isArray(result);
  }
}
