import { injectable } from 'inversify';
import { ICompanyArbitrationMappingService } from '@/modules/dossier/company/arbitrations/services/companyArbitrationMappingService.interface';
import { NumbersUtils } from '@/utils/numbersUtils';
import { Arbitration } from '@/modules/dossier/company/arbitrations/types/arbitration';
import { ArbitrationIndividualTypeStr } from '@/modules/dossier/company/arbitrations/types/arbitrationIndividualTypeStr';
import { ArbitrationSummaryData } from '@/modules/dossier/company/arbitrations/types/companyArbitrationSummaryData';
import { GetGroupedArbitrationByYearResult } from '@/modules/dossier/company/arbitrations/types/getGroupedArbitrationByYearResult';
import { GetArbitrationListTableDataResult } from '@/modules/dossier/company/arbitrations/types/getArbitrationListTableDataResult';
import { DossierTagData } from '@/modules/common/types/dossierTag';

@injectable()
export default class companyArbitrationMappingService implements ICompanyArbitrationMappingService {
  getArbitrationStatusTagData(status: string): DossierTagData {
    switch (status) {
      case 'Рассматривается в первой инстанции':
        return { theme: 'danger', text: status };
      case 'Рассмотрение дела завершено':
        return { theme: 'success', text: status };
      case 'Рассматривается в апелляционной инстанции':
        return { theme: 'common', text: status };
      default:
        return { theme: 'common', text: 'Неизвестно' };
    }
  }

  getArbitrationSummaryDataByType(
    arbitrationSummaryData: Array<ArbitrationSummaryData>,
    type: ArbitrationIndividualTypeStr,
  ) {
    return arbitrationSummaryData.find(
      (arbitrationSummary) => arbitrationSummary.individualTypeString == type,
    );
  }

  getArbitrationListByType(
    arbitrationList: Array<Arbitration>,
    type: ArbitrationIndividualTypeStr,
  ) {
    return arbitrationList.filter(
      (arbitration) => arbitration.arbitrationIndividualTypeStr == type,
    );
  }

  getArbitrationListTableData(
    arbitrationList: Array<Arbitration>,
  ): Array<GetArbitrationListTableDataResult> {
    return arbitrationList.map((arbitration) => {
      const act = arbitration.arbitrationActs[0];
      return {
        arbitrationIndividualTypeStr: arbitration.arbitrationIndividualTypeStr,
        arbNumber: arbitration.arbNumber,
        arbTypeStr: arbitration.arbTypeStr,
        date: arbitration.date,
        judicature: arbitration.judicature,
        judge: arbitration.judge,
        sum: `${NumbersUtils.separateByThreeDigits(arbitration.sum)} ₽`,
        status: this.getArbitrationStatusTagData(arbitration.status),
        statusDate: arbitration.statusDate,
        coincidence: arbitration.coincidence,
        instance: act?.instance,
        actTypeStr: act?.actTypeStr,
        actName: act?.actName,
        url: { url: arbitration.url, title: 'Ссылка', isOpenInNewWindow: true },
        actInfo: act?.info,
        actDate: act?.date,
      };
    });
  }

  getGroupedArbitrationByYear(arbitrationList: Array<Arbitration>) {
    const groupedDefendantsByYear = arbitrationList.reduce(
      (acc: Record<string, GetGroupedArbitrationByYearResult>, arbitration) => {
        const year = new Date(arbitration.date).getFullYear().toString();

        if (acc[year] == undefined) {
          acc[year] = { year, sum: arbitration.sum, casesCount: 1 };
        } else {
          acc[year].sum += arbitration.sum;
          acc[year].casesCount += 1;
        }
        return acc;
      },
      {},
    );

    return Object.values(groupedDefendantsByYear);
  }
}
