import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import PersonJobsNormalizationService from '@/modules/dossier/person/common/services/normalization/personJobsNormalizationService';
import { IPersonJobsNormalizationServiceKey } from '@/modules/dossier/person/common/services/normalization/personJobsNormalizationService.interfaces';
import PersonRelativesNormalizationService from '@/modules/dossier/person/common/services/normalization/personRelativesNormalizationService';
import { IPersonRelativesNormalizationServiceKey } from '@/modules/dossier/person/common/services/normalization/personRelativesNormalizationService.interfaces';
import PersonEducationsNormalizationService from '@/modules/dossier/person/common/services/normalization/personEducationsNormalizationService';
import { IPersonEducationsNormalizationServiceKey } from '@/modules/dossier/person/common/services/normalization/personEducationsNormalizationService.interfaces';

export function bindPersonDossierNormalizationServices(bind: IContainerBinder) {
  bind(IPersonJobsNormalizationServiceKey).to(PersonJobsNormalizationService);
  bind(IPersonRelativesNormalizationServiceKey).to(PersonRelativesNormalizationService);
  bind(IPersonEducationsNormalizationServiceKey).to(PersonEducationsNormalizationService);
}
