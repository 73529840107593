import { INumberNormalizationService } from '@/modules/normalization/services/numberNormalizationService/numberNormalizationService.interfaces';
import { INumberParserService, INumberParserServiceKey } from '@/modules/common/modules/numbers/services/numberParserService/numberParserService.interfaces';
import { injectable, inject } from 'inversify';
import { isNumber, isString } from 'lodash';

@injectable()
export default class NumberNormalizationService implements INumberNormalizationService {
  constructor(
    @inject(INumberParserServiceKey as symbol) private numberParserService: INumberParserService,
  ) {
  }

  normalize(value: any): number | undefined {
    if(isNumber(value))
      return value
    else if(isString(value))
      return this.numberParserService.parse(value)
    else
      return undefined
  }
}
