import {
  ICompanyListsEntityIdsExtractionService
} from '@/modules/lists/services/companyListsEntityIdsExtractionService.interfaces';
import {
  ICompanyListsService, ICompanyListsServiceKey
} from '@/modules/lists/services/companyListsService.interfaces';
import ListsEntityIdsExtractionService
  from '@/modules/lists/services/listsEntityIdsExtractionService';
import { inject, injectable } from 'inversify';

@injectable()
export default class CompanyListsEntityIdsExtractionService extends ListsEntityIdsExtractionService implements ICompanyListsEntityIdsExtractionService {
  constructor(
    @inject(ICompanyListsServiceKey as symbol) private companyListsService: ICompanyListsService
  ) {
    super();
  }

  protected async getListEntityIdsAsync(listId: string): Promise<string[]> {
    const {
      data: {
        data: {
          ulIds
        }
      }
    } = await this.companyListsService.getListDetails(listId);

    return ulIds;
  }
}
