export type TStatisticData = {
  count: number;
  name: string;
};

export type TStatisticsCompanyPersonsCount = {
  count: number;
  companyName: string;
};

export type StatisticsState = {
  isLoad: boolean;
  personStatistics: {
    personsCountForCounterParties: TStatisticsCompanyPersonsCount[];
    risks: TStatisticData[];
    employmentStatuses: TStatisticData[];
    individualTypes: TStatisticData[];
  };
  companyStatistics: {
    risks: TStatisticData[];
    counterPartyTypes: TStatisticData[];
    proceedTypes: TStatisticData[];
    personsCountForEmployers: TStatisticsCompanyPersonsCount[];
  };
};

export enum MutationTypesEnum {
  SET_PERSON_STATISTICS = 'SET_PERSON_STATISTICS',
  SET_COMPANY_STATISTICS = 'SET_COMPANY_STATISTICS',
}

export type Mutations<S = StatisticsState> = {
  [MutationTypesEnum.SET_PERSON_STATISTICS](
    state: S,
    payload: Pick<StatisticsState, 'personStatistics'>,
  ): void;
  [MutationTypesEnum.SET_COMPANY_STATISTICS](
    state: S,
    payload: Pick<StatisticsState, 'companyStatistics'>,
  ): void;
};

export enum ActionTypesEnum {
  GET_STATISTICS = 'GET_STATISTICS',
}
