import { injectable } from 'inversify';
import { ApiPersonDetailPersonDebtDataRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailPersonDebtDataRecord';
import { IPersonTaxDebtInformationStatisticsService } from './personTaxDebtInformationStatisticsService.interface';

@injectable()
export default class personTaxDebtInformationStatisticsService
  implements IPersonTaxDebtInformationStatisticsService
{
  getTaxDebtInformationCount(taxDebt: Array<ApiPersonDetailPersonDebtDataRecord>): number {
    return taxDebt.reduce((count, taxDebtItem) => count + taxDebtItem.taxDebtsResult.length, 0);
  }

  getTaxDebtInformationSum(taxDebt: Array<ApiPersonDetailPersonDebtDataRecord>): string {
    return Math.floor(
      taxDebt.reduce((sum, taxDebtItem) => {
        taxDebtItem.taxDebtsResult.forEach((item) => {
          sum += Number(item.price);
        });
        return sum;
      }, 0),
    ).toLocaleString('ru');
  }
}
