import { ApiGridUlRecord } from '@/modules/dataGrid/company/types/api/apiGridUlRecord';
import { injectable } from 'inversify';
import { IGridCompanyTypeParserService } from '@/modules/dataGrid/services/gridCompanyTypeParserService.interfaces';
import { GridCompanyItemType } from '@/modules/dataGrid/type/gridCompanyItemType';

@injectable()
export class GridCompanyTypeParserService implements IGridCompanyTypeParserService {
  getItemType(gridItem: ApiGridUlRecord): GridCompanyItemType {
    const inspectedInn = gridItem.inn;
    const inspectedOgrn = gridItem.ogrn ?? '';

    if (inspectedInn.length > 0) {
      return inspectedInn.length == 10 ? GridCompanyItemType.ul : GridCompanyItemType.ip;
    }
    if (inspectedOgrn.length > 0) {
      return inspectedOgrn.length == 13 ? GridCompanyItemType.ul : GridCompanyItemType.ip;
    }

    return GridCompanyItemType.unknown;
  }
}
