import { InjectionKey } from 'vue';
import { ApiPersonDetailOkRecordStringItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordStringItem';

export interface IPersonSocialNetworkAdditionalInfoOKGetLastValueService {
  getPersonSocialNetworkOKMainInfoLatestValue(
    detailOkItemValue: Array<ApiPersonDetailOkRecordStringItem>,
  ): string;
}

export const IPersonSocialNetworkAdditionalInfoOKGetLastValueServiceKey: InjectionKey<IPersonSocialNetworkAdditionalInfoOKGetLastValueService> =
  Symbol('IPersonSocialNetworkAdditionalInfoOKGetLastValueServiceKey');
