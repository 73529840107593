import { inject, injectable } from 'inversify';
import { IReportsWebVersionUrlService } from '@/modules/reports/common/services/reportsWebVersionUrlService.interfaces';
import { GuidUtils } from '@/modules/common/utils/guidUtils';
import { IApiSettings, IApiSettingsKey } from '@/modules/common/settings/apiSettings.interfaces';
import { ReportTypeInput } from '@/modules/reports/common/types/ReportTypeInput';

@injectable()
export default class ReportsWebVersionUrlService implements IReportsWebVersionUrlService {
  constructor(@inject(IApiSettingsKey as symbol) private apiSettings: IApiSettings) {}

  getWebVersionUrl(inputType?: ReportTypeInput, reportTaskId?: string | null): string | undefined {
    // ts тупой, не понимает, что мы проверяем reportTaskId на undefined в утиле
    if (
      GuidUtils.isEmptyOrNull(reportTaskId) ||
      reportTaskId == undefined ||
      inputType == undefined
    ) {
      return undefined;
    }

    // prettier-ignore
    switch (inputType) {
      case ReportTypeInput.carNumber: return this.getWebVersionVehicleUrl(reportTaskId);
      case ReportTypeInput.phone: return this.getWebVersionPhoneNumberUrl(reportTaskId);
      case ReportTypeInput.inn: return this.getWebVersionLegalEntitiesUrl(reportTaskId);
      case ReportTypeInput.fullName: return this.getWebVersionIndividualUrl(reportTaskId);
      default: return undefined;
    }
  }

  protected getWebVersionIndividualUrl(reportTaskId: string): string {
    return `${this.apiSettings.reportsBaseUrl}/report/individual?taskId=${reportTaskId}`;
  }

  protected getWebVersionLegalEntitiesUrl(reportTaskId: string): string {
    return `${this.apiSettings.reportsBaseUrl}/report/legal-entities?taskId=${reportTaskId}`;
  }

  protected getWebVersionPhoneNumberUrl(reportTaskId: string): string {
    return `${this.apiSettings.reportsBaseUrl}/report/phone-number?taskId=${reportTaskId}`;
  }

  protected getWebVersionVehicleUrl(reportTaskId: string): string {
    return `${this.apiSettings.reportsBaseUrl}/report/vehicle?taskId=${reportTaskId}`;
  }
}
