import { injectable } from 'inversify';
import { UITagTheme } from '@/modules/tags/types/tagTheme';
import { IPersonCarTagsService } from '@/modules/dossier/person/vehicles/cars/services/personCarTagsService.interface';
import { UseDxTagConfig } from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';

@injectable()
export default class PersonCarsOwnerRegistrationTypeService implements IPersonCarTagsService {
  private getTagThemeByContent(content: string): UITagTheme {
    switch (content) {
      case 'Текущий':
        return 'success';
      case 'Бывший':
        return 'warning';
      default:
        return 'common';
    }
  }

  getOwnerRegistrationTypeTag(content: string): UseDxTagConfig {
    const trimmedContent = content?.trim();

    return {
      theme: this.getTagThemeByContent(trimmedContent),
      content: trimmedContent,
    };
  }
}
