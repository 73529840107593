import { injectable } from 'inversify';
import { ApiPersonDetailPassportsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailPassportsRecord';
import { IPersonPassportInnCheckingParserService } from '@/modules/dossier/person/passports/services/parsers/personPassportInnCheckingParserService.interface';
import { ApiPersonDetailInnPassportAccordanceChecks } from '@/modules/dossier/person/common/types/api/apiPersonDetailInnPassportAccordanceChecks';
import { ApiPersonDetailInnPassportAccordanceCheckRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailInnPassportAccordanceCheckRecord';

@injectable()
export default class PersonPassportInnCheckingParserService
  implements IPersonPassportInnCheckingParserService
{
  parse(
    innChecksList: ApiPersonDetailInnPassportAccordanceChecks,
    passportItem: ApiPersonDetailPassportsRecord,
  ): ApiPersonDetailInnPassportAccordanceCheckRecord | undefined {
    if (innChecksList.list.length !== 0) {
      const record = innChecksList.list.find(
        (item: { checkedPassportNumber: string }) =>
          item.checkedPassportNumber ==
          `${passportItem.passportSeries}${passportItem.passportNumber}`,
      );
      if (record) return record;
    }
    return undefined;
  }
}
