import { InjectionKey } from 'vue';

export interface IYandexMapsApiSettings {
  apiKey: string;
  coordorder: string;
  debug: boolean;
  lang: string;
  version: string;
}

export const IYandexMapsApiSettingsKey: InjectionKey<IYandexMapsApiSettings> =
  Symbol('IYandexMapsApiSettings');
