import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IPersonMentionsMappingServiceKey } from '@/modules/dossier/person/mentions/services/personMentionsMappingService.interface';
import PersonMentionsMappingService from '@/modules/dossier/person/mentions/services/personMentionsMappingService';
import { IPersonMentionsIconsServiceKey } from '@/modules/dossier/person/mentions/services/personMentionsIconsService.interface';
import PersonMentionsIconsService from '@/modules/dossier/person/mentions/services/personMentionsIconsService';

export function bindPersonMentionsMappingService(bind: IContainerBinder) {
  bind(IPersonMentionsMappingServiceKey).to(PersonMentionsMappingService);
  bind(IPersonMentionsIconsServiceKey).to(PersonMentionsIconsService);
}
