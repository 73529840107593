import { MaskOptions } from 'maska';

export const carNumberRequisiteMask: MaskOptions = {
  mask: 'RDDDRRDDD',
  tokens: {
    R: {
      pattern: /[авекмнорстух]/i,
      transform: (v: string) => v.toLocaleUpperCase(),
    },
    D: {
      pattern: /[0-9]/,
    },
  },
};
