import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IPersonParticipationUIAnalyticServiceKey } from '@/modules/dossier/person/participation/services/statistics/personParticipationUIAnalyticService.interface';
import { IPersonParticipationIpAnalyticServiceKey } from '@/modules/dossier/person/participation/services/statistics/personParticipationIpAnalyticService.interface';
import { IPersonParticipationUITagThemeServiceKey } from '@/modules/dossier/person/participation/services/tags/personParticipationUITagThemeService.interface';
import { IPersonParticipationIpTagThemeServiceKey } from '@/modules/dossier/person/participation/services/tags/personParticipationIpTagThemeService.interface';
import PersonParticipationUIAnalyticService from '@/modules/dossier/person/participation/services/statistics/personParticipationUIAnalyticService';
import PersonParticipationIpAnalyticService from '@/modules/dossier/person/participation/services/statistics/personParticipationIpAnalyticService';
import PersonParticipationUITagThemeService from '@/modules/dossier/person/participation/services/tags/personParticipationUITagThemeService';
import PersonParticipationIpTagThemeService from '@/modules/dossier/person/participation/services/tags/personParticipationIpTagThemeService';

export function bindPersonParticipationAnalyticService(bind: IContainerBinder) {
  bind(IPersonParticipationUIAnalyticServiceKey).to(PersonParticipationUIAnalyticService);
  bind(IPersonParticipationIpAnalyticServiceKey).to(PersonParticipationIpAnalyticService);
  bind(IPersonParticipationUITagThemeServiceKey).to(PersonParticipationUITagThemeService);
  bind(IPersonParticipationIpTagThemeServiceKey).to(PersonParticipationIpTagThemeService);
}
