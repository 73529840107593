import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { bindPersonDossierFormatters } from '@/modules/dossier/person/common/configuration/inversify/container.personDossier.formatters';
import PeoplePlaceholderService from '@/modules/dossier/person/common/services/peoplePlaceholderService';
import { IPeoplePlaceholderServiceKey } from '@/modules/dossier/person/common/services/peoplePlaceholderService.interfaces';
import PeopleService from '@/modules/dossier/person/common/services/peopleService';
import { IPeopleServiceKey } from '@/modules/dossier/person/common/services/peopleService.interfaces';
import { IPersonDossierIdServiceKey } from '@/modules/dossier/person/common/services/personDossierIdService/personDossierIdService.interfaces';
import PersonDossierIdService from '@/modules/dossier/person/common/services/personDossierIdService/personDossierIdService';
import { bindPersonDriverLicenseServices } from '@/modules/dossier/person/vehicles/driverLicenses/configuration/inversify/container.personDriverLicense';
import { bindPersonDossierParticipationUlChecksServices } from '@/modules/dossier/person/participation/configuration/inversify/container.personDossierParticipation';
import { bindPersonDossierTaxDebtInformationServices } from '@/modules/dossier/person/taxDebtInformation/configuration/inversify/container.personDossierTaxDebtInformation';
import { bindPersonFinancialScoringsServices } from '@/modules/dossier/person/financialScorings/configuration/inversify/container.personFinancialScorings';
import { bindPersonDossierNegativeDebtsServices } from '@/modules/dossier/person/negativeDebts/configuration/inversify/container.personDossierNegativeDebts';
import { bindPersonContactsServices } from '@/modules/dossier/person/contacts/configuration/inversify/container.personDossierContacts';
import { bindPersonMentionsMappingService } from '@/modules/dossier/person/mentions/configuration/inversify/container.personDossierMentions';
import { bindPersonDossierPledgesRegistriesServices } from '@/modules/dossier/person/pledgesRegistries/configuration/inversify/container.personDossierPledgesRegistries';
import { bindPersonSocialNetworksMappingService } from '@/modules/dossier/person/socialNetworks/configuration/inversify/container.personDossierSocialNetworks';
import { bindPersonFinesServices } from '@/modules/dossier/person/fines/configuration/inversify/container.personFines';
import { bindPersonDossierArbitrationServices } from '@/modules/dossier/person/arbitration/configuration/inversify/container.personDossierArbitration';
import { bindPersonDossierEnforcementProceedingsServices } from '@/modules/dossier/person/enforcementProceedings/configuration/inversify/container.personDossierEnforcementProceedings';
import { bindPersonRoadAccidentsServices } from '@/modules/dossier/person/roadAccidents/configuration/inversify/container.roadAccidents';
import { bindPersonDossierNegativeOverviewServices } from '@/modules/dossier/person/negativeOverview/configuration/inversify/container.personDossierNegativeOverview';
import { bindPersonDossierSocialNetworkAdditionalInfoServices } from '@/modules/dossier/person/socialNetworkAdditionalInfo/configuration/inversify/container.personDossierSocialNetworkAdditional';
import { bindPersonPassportServices } from '@/modules/dossier/person/passports/configuration/inversify/container.personPassports';
import { bindPersonDossierRestrictionsServices } from '@/modules/dossier/person/restrictions/configuration/inversify/container.personDossierRestrictions';
import { bindPersonAddressesServices } from '@/modules/dossier/person/addresses/configuration/inversify/container.personDossierAddresses';
import { bindPersonIntegrityScoringsServices } from '@/modules/dossier/person/personIntegrityScoring/configuration/inversify/container.personIntegrityScoring';
import { bindPersonParticipationAnalyticService } from '@/modules/dossier/person/participation/configuration/inversify/container.personParticipationAnalytic';
import { bindPersonNegativeCriminalDossierServices } from '@/modules/dossier/person/negativeCriminals/configuration/inversify/container.personNegativeCriminalDossier';
import { bindPersonCarsServices } from '@/modules/dossier/person/vehicles/cars/configuration/inversify/container.personCars';

export function bindPersonDossierServices(bind: IContainerBinder) {
  bindPersonDossierFormatters(bind);
  bindPersonFinancialScoringsServices(bind);
  bindPersonDossierNegativeDebtsServices(bind);
  bindPersonParticipationAnalyticService(bind);
  bindPersonSocialNetworksMappingService(bind);
  bindPersonFinesServices(bind);
  bindPersonContactsServices(bind);
  bindPersonDossierEnforcementProceedingsServices(bind);
  bindPersonRoadAccidentsServices(bind);
  bindPersonDossierPledgesRegistriesServices(bind);
  bindPersonDossierArbitrationServices(bind);
  bindPersonDossierNegativeOverviewServices(bind);
  bindPersonDossierRestrictionsServices(bind);
  bindPersonIntegrityScoringsServices(bind);
  bindPersonAddressesServices(bind);
  bindPersonMentionsMappingService(bind);

  bind(IPeopleServiceKey).to(PeopleService);
  bind(IPeoplePlaceholderServiceKey).to(PeoplePlaceholderService);
  bind(IPersonDossierIdServiceKey).to(PersonDossierIdService);

  bindPersonCarsServices(bind);
  bindPersonDriverLicenseServices(bind);
  bindPersonDossierTaxDebtInformationServices(bind);
  bindPersonPassportServices(bind);

  bindPersonDossierParticipationUlChecksServices(bind);
  bindPersonDossierSocialNetworkAdditionalInfoServices(bind);
  bindPersonNegativeCriminalDossierServices(bind);
}
