import { InjectionKey } from 'vue';
import { ApiDossierLink } from '@/modules/dossier/common/types/apiDossierLink';

export interface IPersonDossierIdService {
  getIdFromDossierLink(dossierId?: ApiDossierLink): string | undefined;
  getIdFromUrl(url?: string): string | undefined;
}

export const IPersonDossierIdServiceKey: InjectionKey<IPersonDossierIdService> =
  Symbol('IPersonDossierIdService');
