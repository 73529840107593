export enum SystemIconsNames {
  unknown = 'unknown',
  socialVk = 'socialVk',
  socialOk = 'socialOk',
  socialFaceBook = 'socialFaceBook',
  socialInstagram = 'socialInstagram',
  socialTwitter = 'socialTwitter',
  socialTikTok = 'socialTikTok',
  socialTelegram = 'socialTelegram',
  socialWhatsApp = 'socialWhatsApp',
  socialViber = 'socialViber',
  socialSkype = 'socialSkype',
  authBg = 'authBg',
  authErrorIcon = 'authErrorIcon',
  authImg = 'authImg',
  authInstagramIcon = 'authInstagramIcon',
  authSiteIcon = 'authSiteIcon',
  authTelegramIcon = 'authTelegramIcon',
  authVkIcon = 'authVkIcon',
  sidebarArrowTopRight = 'sidebarArrowTopRight',
  sidebarCollection = 'sidebarCollection',
  sidebarDataAndAnalysis = 'sidebarDataAndAnalysis',
  sidebarDataCollection = 'sidebarDataCollection',
  sidebarDataImport = 'sidebarDataImport',
  sidebarDataLists = 'sidebarDataLists',
  sidebarDoubleArrowLeft = 'sidebarDoubleArrowLeft',
  sidebarHistory = 'sidebarHistory',
  sidebarHistoryV2 = 'sidebarHistoryV2',
  sidebarHistoryExternalData = 'sidebarHistoryExternalData',
  sidebarHome = 'sidebarHome',
  sidebarHomeV2 = 'sidebarHomeV2',
  sidebarLegalEntity = 'sidebarLegalEntity',
  sidebarDataCollection1 = 'sidebarDataCollection1',
  sidebarLogo = 'sidebarLogo',
  sidebarLogOutIcon = 'sidebarLogOutIcon',
  sidebarLogOutIconV2 = 'sidebarLogOutIconV2',
  sidebarPlay = 'sidebarPlay',
  sidebarReports = 'sidebarReports',
  sidebarReportsV2 = 'sidebarReportsV2',
  uiV2AdressType = 'uiV2AdressType',
  uiV2ArrowSelect = 'uiV2ArrowSelect',
  uiV2CarType = 'uiV2CarType',
  uiV2EmailType = 'uiV2EmailType',
  uiV2ErrorMassage = 'uiV2ErrorMassage',
  uiV2FullNameType = 'uiV2FullNameType',
  uiV2InnCompanyType = 'uiV2InnCompanyType',
  uiV2InnType = 'uiV2InnType',
  uiV2MassengerType = 'uiV2MassengerType',
  uiV2OgrnType = 'uiV2OgrnType',
  uiV2PassportType = 'uiV2PassportType',
  uiV2PdfIcon = 'uiV2PdfIcon',
  uiV2PersonType = 'uiV2PersonType',
  uiV2PhoneType = 'uiV2PhoneType',
  uiV2PhotoIcon = 'uiV2PhotoIcon',
  uiV2SnilsType = 'uiV2SnilsType',
  uiV2SocietyType = 'uiV2SocietyType',
  uiV2UlType = 'uiV2UlType',
  uiV2SidebarArrowTopRight = 'uiV2SidebarArrowTopRight',
  uiV2SidebarCollection = 'uiV2SidebarCollection',
  uiV2SidebarDataAndAnalysis = 'uiV2SidebarDataAndAnalysis',
  uiV2SidebarDataCollection = 'uiV2SidebarDataCollection',
  uiV2SidebarDataImport = 'uiV2SidebarDataImport',
  uiV2SidebarDataLists = 'uiV2SidebarDataLists',
  uiV2SidebarDoubleArrowLeft = 'uiV2SidebarDoubleArrowLeft',
  uiV2SidebarHistory = 'uiV2SidebarHistory',
  uiV2SidebarHistoryExternalData = 'uiV2SidebarHistoryExternalData',
  uiV2SidebarHome = 'uiV2SidebarHome',
  uiV2SidebarIndividualEntity = 'uiV2SidebarIndividualEntity',
  uiV2SidebarLegalEntity = 'uiV2SidebarLegalEntity',
  uiV2SidebarLogo = 'uiV2SidebarLogo',
  uiV2SidebarLogOutIcon = 'uiV2SidebarLogOutIcon',
  sidebarMenuMobileIcon = 'sidebarMenuMobileIcon',
  uiV2SidebarPlay = 'uiV2SidebarPlay',
  uiV2SidebarReports = 'uiV2SidebarReports',
  uiV2Sidebar = 'uiV2Sidebar',
  uiV3ArrowLeft = 'uiV3ArrowLeft',
  uiV3ArrowLeftHover = 'uiV3ArrowLeftHover',
  uiV3ArrowRight = 'uiV3ArrowRight',
  uiV3ArrowRightHover = 'uiV3ArrowRightHover',
  uiV3CheckBox = 'uiV3CheckBox',
  uiV3DoubleArrowLeft = 'uiV3DoubleArrowLeft',
  uiV3DoubleArrowLeftHover = 'uiV3DoubleArrowLeftHover',
  uiV3DoubleArrowRight = 'uiV3DoubleArrowRight',
  uiV3DoubleArrowRightHover = 'uiV3DoubleArrowRightHover',
  uiV3TableFilter = 'uiV3TableFilter',
  uiV3TrashBasket = 'uiV3TrashBasket',
  uiV3TrashBasketWhite = 'uiV3TrashBasketWhite',
  addFile = 'addFile',
  adressType = 'adressType',
  arrowLeftIcon = 'arrowLeftIcon',
  arrowRight = 'arrowRight',
  arrowTail = 'arrowTail',
  arrowTailShort = 'arrowTailShort',
  arrowSelect = 'arrowSelect',
  barBlueIcon = 'barBlueIcon',
  barRedIcon = 'barRedIcon',
  burger = 'burger',
  calendar = 'calendar',
  calendarChartIcon = 'calendarChartIcon',
  carType = 'carType',
  chartBarsCompanyIcon = 'chartBarsCompanyIcon',
  chartDonutCompanyIcon = 'chartDonutCompanyIcon',
  checkedConsiderIcon = 'checkedConsiderIcon',
  checkedDefaultIcon = 'checkedDefaultIcon',
  checkedIcon = 'checkedIcon',
  checkedNotConsiderIcon = 'checkedNotConsiderIcon',
  checkMarkPinIcon = 'checkMarkPinIcon',
  clearSearchCollection = 'clearSearchCollection',
  close = 'close',
  closeIcon = 'closeIcon',
  closeSmall = 'closeSmall',
  company = 'company',
  contacts = 'contacts',
  contract = 'contract',
  copyIcon = 'copyIcon',
  copyIcon2 = 'copyIcon2',
  cross = 'cross',
  dismissal = 'dismissal',
  documentIcon = 'documentIcon',
  downloadChartIcon = 'downloadChartIcon',
  downloadCloudIcon = 'downloadCloudIcon',
  downloadFileIcon = 'downloadFileIcon',
  downloadIcon = 'downloadIcon',
  editIcon = 'editIcon',
  email = 'email',
  emailType = 'emailType',
  errorPinIcon = 'errorPinIcon',
  export = 'export',
  export2 = 'export2',
  eye = 'eye',
  filter = 'filter',
  filters = 'filters',
  fullNameType = 'fullNameType',
  globe = 'globe',
  importIcon = 'importIcon',
  importIcon2 = 'importIcon2',
  innType = 'innType',
  eftSliderArrowIcon = 'eftSliderArrowIcon',
  logo = 'logo',
  mapIcon = 'mapIcon',
  markerAttention = 'markerAttention',
  massengerType = 'massengerType',
  mobileView = 'mobileView',
  note = 'note',
  numberDeal = 'numberDeal',
  odnoklassniki = 'odnoklassniki',
  okIcon = 'okIcon',
  passportType = 'passportType',
  pdfIcon = 'pdfIcon',
  personType = 'personType',
  photoIcon = 'photoIcon',
  plus = 'plus',
  position = 'position',
  preloader = 'preloader',
  printer = 'printer',
  reception = 'reception',
  phoneType = 'phoneType',
  reliability = 'reliability',
  reliabilityIconWithBack = 'reliabilityIconWithBack',
  reliabilityWithBack = 'reliabilityWithBack',
  reply = 'reply',
  rightSliderArrowIcon = 'rightSliderArrowIcon',
  search = 'search',
  settings = 'settings',
  showCheckMarkIndex = 'showCheckMarkIndex',
  showCrossIndex = 'showCrossIndex',
  showDefaultIndex = 'showDefaultIndex',
  site = 'site',
  societyType = 'societyType',
  status = 'status',
  subdivision = 'subdivision',
  tableView = 'tableView',
  toClose = 'toClose',
  triangleGreen = 'triangleGreen',
  triangleRed = 'triangleRed',
  trustworthyMarker = 'trustworthyMarker',
  twoLetters = 'twoLetters',
  ulType = 'ulType',
  uncheck = 'uncheck',
  uncheckedIcon = 'uncheckedIcon',
  unreliability = 'unreliability',
  unreliableMarker = 'unreliableMarker',
  up = 'up',
  upload = 'upload',
  vkIcon = 'vkIcon',
  vkIconWithBack = 'vkIconWithBack',
  vkontakte = 'vkontakte',
  website = 'website',
  subMenu = 'subMenu',
  chevronBreadcrumbs = 'chevronBreadcrumbs',
  uiV3ArrowLeftTail = 'uiV3ArrowLeftTail',
  uiV3DataFlow = 'uiV3DataFlow',
  uiV3Plus = 'uiV3Plus',
  uiV3Internet = 'uiV3Internet',
  uiV3Trash = 'uiV3Trash',
  uiV3Upload = 'uiV3Upload',
  uiV3File = 'uiV3File',
  uiV3TrashBasket2 = 'uiV3TrashBasket2',
  uiV3Cross = 'uiV3Cross',
  checkSuccessOutlineIcon = 'checkSuccessOutlineIcon',
  uiV3Search = 'uiV3Search',
  uiV3ImagePlaceholder = 'uiV3ImagePlaceholder',
  snackBarInfo = 'snackBarInfo',
  snackBarError = 'snackBarError',
  snackBarSuccess = 'snackBarSuccess',
  uiV3Correctly = 'uiV3Correctly',
  uiV3Mistakes = 'uiV3Mistakes',
  checkedRoundedIcon = 'checkedRoundedIcon',
  closeRoundedIcon = 'closeRoundedIcon',
  squareFilledTick = 'squareFilledTick',
  squareFilledMinus = 'squareFilledMinus',
  squareFilledPartly = 'squareFilledPartly',
  squareFilledQuestion = 'squareFilledQuestion',
  squareFilledReview = 'squareFilledReview',
  squareFilledX = 'squareFilledX',
  caret = 'caret',
  eventsAb100 = 'eventsAb100',
  eventsBfo = 'eventsBfo',
  eventsDamia = 'eventsDamia',
  eventsFns = 'eventsFns',
  eventsIdx = 'eventsIdx',
  eventsImport = 'eventsImport',
  eventsIndirect = 'eventsIndirect',
  eventsKontur = 'eventsKontur',
  eventsKronos = 'eventsKronos',
  eventsMvd = 'eventsMvd',
  eventsOdissey = 'eventsOdissey',
  eventsUnknown = 'eventsUnknown',
  eventsXneo = 'eventsXneo',
  konturFocus = 'konturFocus',
  av100 = 'av100',
  damia = 'damia',
  xneo = 'xneo',
  fns = 'fns',
  cronos = 'cronos',
  mvd = 'mvd',
  odyssey = 'odyssey',
  idx = 'idx',
  bfo = 'bfo',
  uiV3RefreshIcon = 'uiV3RefreshIcon',
  uiV3CheckCircleIcon = 'uiV3CheckCircleIcon',
  infoRoundedStroke = 'infoRoundedStroke',
  warningRoundedStroke = 'warningRoundedStroke',
  uiV3Edit = 'uiV3Edit',
  uiV3warningTriangle = 'uiV3warningTriangle',
  sidebarImportRoot = 'sidebarImportRoot',
  sidebarImportV2 = 'sidebarImportV2',
  sidebarSearch = 'sidebarSearch',
  sidebarSearchV2 = 'sidebarSearchV2',
  sidebarPersons = 'sidebarPersons',
  sidebarPersonsV2 = 'sidebarPersonsV2',
  sidebarPersonsV3 = 'sidebarPersonsV3',
  sidebarAnalys = 'sidebarAnalys',
  sidebarAnalysV3 = 'sidebarAnalysisV3',
  sidebarLists = 'sidebarLists',
  sidebarListsV2 = 'sidebarListsV2',
  circleWarning = 'circleWarning',
  dangerTriangle = 'dangerTriangle',
  reset = 'reset',
  searchSmall = 'searchSmall',
  uiV3SearchWithCheck = 'uiV3SearchWithCheck',
  uiV3PlusWithoutStroke = 'uiV3PlusWithoutStroke',
  uiV3Refresh = 'uiV3Refresh',
  uiV3CheckRoundedStroke = 'uiV3CheckRoundedStroke',
  uiV3Error = 'uiV3Error',
  uiV3Warning = 'uiV3Warning',
  uiV3ChevronSmall = 'uiV3ChevronSmall',
  closeRoundedSmall = 'closeRoundedSmall',
  checkRoundedSmall = 'checkRoundedSmall',
  actionsPrint = 'actionsPrint',
  actionsDownloadFile = 'actionsDownloadFile',
  actionsAddFile = 'actionsAddFile',
  actionsGraph = 'actionsGraph',
  actionsRoundedPlus = 'actionsRoundedPlus',
  actionsWeb = 'actionsWeb',
  actionsRoundedCross = 'actionsRoundedCross',
  uiV3SortAsc = 'uiV3SortAsc',
  uiV3SortDesc = 'uiV3SortDesc',
  getContact = 'getContact',
  trueCaller = 'trueCaller',
  mentionsGetContact = 'mentionsGetContact',
  mentionsTrueCaller = 'mentionsTrueCaller',
  mentionsOdyssey = 'mentionsOdyssey',
  circleExclamation = 'circleExclamation',
  triangleExclamation = 'triangleExclamation',
  uiV3Settings = 'uiV3Settings',
  moreVertical = 'moreVertical',
  downloadCloud2 = 'downloadCloud2',
  image = 'image',
  zoomIn = 'zoomIn',
  download = 'download',
  downloadFile = 'downloadFile',
  rotateCw = 'rotateCw',
  uiV3PersonIcon = 'uiV3PersonIcon',
  uiV3BriefcaseIcon = 'uiV3BriefcaseIcon',
  uiV3StatusRounded = 'uiV3StatusRounded',
  uiV3CheckIconBold = 'uiV3CheckIconBold',
  uiV3CloseIcon = 'uiV3CloseIcon',
  uiV3dxTableEmptyState = 'uiV3dxTableEmptyState',
  linkArrow = 'linkArrow',
  uiV3Calendar = 'uiV3Calendar',
  analysisSelection = 'analysisSelection',
  analysisLists = 'analysisLists',
  analysisLogo = 'analysisLogo',
  sidebarRegulationCheck = 'sidebarRegulationCheck',
  sidebarRegulationCheckV2 = 'sidebarRegulationCheckV2',
  uploadFile2 = 'uploadFile2',
  generateReport = 'generateReport',
  uiV3Analysis = 'uiV3Analysis',
  reportsRequisitesCar = 'reportsRequisitesCar',
  reportsRequisitesFullName = 'reportsRequisitesFullName',
  reportsRequisitesInn = 'reportsRequisitesInn',
  reportsRequisitesPhone = 'reportsRequisitesPhone',
  reportsRequisitesMessengerDisabled = 'reportsRequisitesMessengerDisabled',
  reportsRequisitesPassportDisabled = 'reportsRequisitesPassportDisabled',
  reportsRequisitesPhotoDisabled = 'reportsRequisitesPhotoDisabled',
  reportsRequisitesEmailDisabled = 'reportsRequisitesEmailDisabled',
  reportsRequisitesSocialDisabled = 'reportsRequisitesSocialDisabled',
  reportsRequisitesAddressDisabled = 'reportsRequisitesAddressDisabled',
  reportsRequisitesInnDisabled = 'reportsRequisitesInnDisabled',
  reportsRequisitesPersonType = 'reportsRequisitesPersonType',
  reportsRequisitesCompanyType = 'reportsRequisitesCompanyType',
  reportsRequisitesPhoto = 'reportsRequisitesPhoto',
  searchOptions = 'searchOptions',
  chevron = 'chevron',
  dossierFlPlaceholder = 'dossierFlPlaceholder',
  dossierUlPlaceholder = 'dossierUlPlaceholder',
  reportsConfigurationPersonTypeMobile = 'reportsConfigurationPersonTypeMobile',
  reportsConfigurationCompanyTypeMobile = 'reportsConfigurationCompanyTypeMobile',
  backBtn = 'backBtn',
  nextBtn = 'nextBtn',
  reportsMobileLogout = 'reportsMobileLogout',
  reportsMobileHistory = 'reportsMobileHistory',
  reportsMobileHistoryActive = 'reportsMobileHistoryActive',
  reportsMobileConfiguration = 'reportsMobileConfiguration',
  reportsMobileConfigurationActive = 'reportsMobileConfigurationActive',
  reportsMobileConfigurationBlue = 'reportsMobileConfigurationBlue',
  pdfLinkIcon = 'pdfLinkIcon',
  webLinkIcon = 'webLinkIcon',
  filterIconMobile = 'filterIconMobile',
  increaseIcon = 'increaseIcon',
  decreaseIcon = 'decreaseIcon',
  uiV3PasswordShown = 'uiV3PasswordShown',
  uiV3PasswordHidden = 'uiV3PasswordHidden',
  uiV3threeDotsHorizontal = 'uiV3threeDotsHorizontal',
  arrowUpRight = 'arrowUpRight',
  redTriangleRounded = 'redTriangleRounded',
  triangleWarning = 'triangleWarning',
  statisticsPerson = 'statisticsPerson',
  statisticsCompany = 'statisticsCompany',
  sweepnetLogo = 'sweepnetLogo',
}
