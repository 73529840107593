export enum ParentDomainType {
  Unknown = 0,
  Person = 1,
  Company = 50,
}

export enum CompanyRelationType {
  Unknown = 0,
  Phone = 1,
  Email = 10,
  Address = 20,
  Founder = 30,
  Head = 40,
}

export enum CompanyOrganisationType {
  Unknown = 0,
  Ul = 1,
  Ip = 10,
  Hp = 20,
}

export enum FoundedInRelationType {
  Unknown = 0,
  OgrnInn = 1,
  OgrnIpInnIp = 2,
  FounderInn = 10,
  FounderFullname = 11,
  DirectorInn = 20,
  DirectorFullname = 21,
  HeadInn = 30,
  CompanyName = 40,
  IndividualEntrepreneurFullname = 41,
  Address = 50,
  AuthorizedCapitalParticipant = 60,
  Filter = 70,
}

export type CompanyRelationsItem = {
  id: string;
  parentRelationId: string;
  parentRelationType: ParentDomainType;
  inn: string;
  ogrn: string;
  fullname: string;
  registrationDate: string;
  registrationDateStr: string;
  status: string;
  canceledDate: string;
  canceledDateStr: string;
  address: string;
  mainActivity: string;
  relationType: CompanyRelationType;
  relationTypeStr: string;
  companyOrganisationType: CompanyOrganisationType;
  companyOrganisationTypeStr: string;
  foundedInRelationType: FoundedInRelationType;
  foundedInRelationTypeStr: string;
  searchValue: string;
  created: string;
  modified: string;
};

export type CompanyRelations = {
  list: Array<CompanyRelationsItem>;
};
