import { injectable } from 'inversify';
import { ApiPersonDetailFineRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailFineRecord';
import { IPersonFineStatisticsService } from '@/modules/dossier/person/fines/services/personFinesStatisticService.interface';

@injectable()
export default class PersonFineStatisticsService implements IPersonFineStatisticsService {
  calculateFineCountByType (items: Array<ApiPersonDetailFineRecord>, type: string): number {
    return items.filter(({ status }) => status == type).length;
  }
  calculateFineSumByType(items: Array<ApiPersonDetailFineRecord>, type: string): number {
    return items
      .filter(({ status }) => status == type)
      .reduce((acc, item) => {
        if (item.sum) {
          return acc + item.sum;
        } else {
          return acc;
        }
      }, 0);
  }
}
