import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { GridOutput } from '@/modules/common/types/api/gridOutput';
import { DictionaryUtils } from '@/modules/common/utils/dictionaryUtils';
import {
  IDataGridBatchLoaderService
} from '@/modules/dataGrid/common/services/dataGridBatchLoaderService.interfaces';
import { ApiGridInput } from '@/modules/dataGrid/common/types/api/apiGridInput';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import _ from 'lodash';

const chunkSize = 250;

@injectable()
export abstract class DataGridBatchLoaderService<T> implements IDataGridBatchLoaderService<T> {
  async getItemsAsync(ids: string[]): Promise<T[]> {
    if(ids.length == 0)
      return [];

    const tasks = _.chunk(ids, chunkSize).map(ids => this.loadChunk(ids));
    const chunks = await Promise.all(tasks);

    return _.uniqBy(chunks.flat(), x => this.getKey(x));
  }

  protected abstract getKey(item: T): any
  protected abstract getItemsInternalAsync(payload: ApiGridInput): Promise<AxiosResponse<ApiResponse<GridOutput<T>>>>

  private async loadChunk(ids: string[]): Promise<T[]> {
    const {
      data: {
        data: {
          items
        }
      }
    } = await this.getItemsInternalAsync({
      paramsDevextreme: {},
      paramsMain: {
        isFilterBySelectedIndividualsActive: true,
        selectedIndividualsIdDict: DictionaryUtils.getDictionaryFromIdsList(ids)
      },
    });

    return items
  }
}
