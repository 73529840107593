import { injectable } from 'inversify';
import { IDxStringsNormalizationService } from '@/modules/devExtreme/services/dxStringsNormalizationService.interfaces';
import { StringUtils } from '@/modules/common/utils/stringUtils';

@injectable()
export default class DxStringsNormalizationService implements IDxStringsNormalizationService {
  format(value: any): Array<string> {
    if (StringUtils.isString(value)) {
      return [value];
    }

    if (Array.isArray(value)) {
      return value.filter((v) => StringUtils.isString(v));
    }

    return [];
  }
}
