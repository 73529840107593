import { injectable } from 'inversify';
import { ICompanyDossierIdService } from '@/modules/dossier/company/common/services/companyDossierIdService/companyDossierIdService.interfaces';
import DossierIdService from '@/modules/dossier/common/services/dossierIdService/dossierIdService';
import { GuidUtils } from '@/modules/common/utils/guidUtils';

@injectable()
export default class CompanyDossierIdService
  extends DossierIdService
  implements ICompanyDossierIdService
{
  getIdFromUrl(url?: string): string | undefined {
    const match = url?.match(
      /(\/company\/)(?<guid>[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/,
    );

    const guid = match?.groups?.guid;

    if (GuidUtils.isEmptyOrNull(guid)) {
      return undefined;
    }

    return guid;
  }
}
