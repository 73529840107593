import { InjectionKey } from 'vue';
import { PersonalSocialNetworkAdditionalInfoOkUserLink } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/types/personalSocialNetworkAdditionalInfoOkUserLink';
import { ApiPersonDetailOkRecordUserItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordUserItem';
import { ApiPersonDetailOkRecordRelativeItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordRelativeItem';

export interface IPersonSocialNetworkAdditionalInfoOkUserLinkService {
  getPersonOkUserLink(
    listItemOkRelative: ApiPersonDetailOkRecordRelativeItem | ApiPersonDetailOkRecordUserItem,
  ): PersonalSocialNetworkAdditionalInfoOkUserLink;
}

export const IPersonSocialNetworkAdditionalInfoOkUserLinkServiceKey: InjectionKey<IPersonSocialNetworkAdditionalInfoOkUserLinkService> =
  Symbol('IPersonSocialNetworkAdditionalInfoOkUserLinkServiceKey');
