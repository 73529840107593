import { AccessScopeEnum } from '@/modules/rights/type/AccessScopeEnum';

export enum AccessScopeAdmin {
  isAuthorized = AccessScopeEnum.isAuthorized,
  hasAccessToDataGrid = AccessScopeEnum.hasAccessToDataGrid,

  hasAccessToHistory = AccessScopeEnum.hasAccessToHistory,
  hasAccessToSearch = AccessScopeEnum.hasAccessToSearch,
  hasAccessToDataAndAnalysis = AccessScopeEnum.hasAccessToDataAndAnalysis,
  hasAccessToDataCollection = AccessScopeEnum.hasAccessToDataCollection,
  hasAccessToAdministrationRoot = AccessScopeEnum.hasAccessToAdministrationRoot,
  hasAccessToUsers = AccessScopeEnum.hasAccessToUsers,
  hasAccessToPerson = AccessScopeEnum.hasAccessToPerson,
  hasAccessToCompany = AccessScopeEnum.hasAccessToCompany,
  hasAccessToImportRootView = AccessScopeEnum.hasAccessToImportRootView,
  hasAccessToCollection = AccessScopeEnum.hasAccessToCollection,
  hasAccessToHistoryExternalData = AccessScopeEnum.hasAccessToHistoryExternalData,
}
