import { injectable } from 'inversify';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { PassportResultCheckingEnum } from '@/modules/dossier/person/passports/types/passportResultCheckingEnum';
import { ICheckingResultMapper } from '@/modules/dossier/person/passports/services/mapping/personPassportComplexCheckingResultMapper.interfaces';
import { PersonPassportResultCheckingTag } from '@/modules/dossier/person/passports/types/personPassportResultCheckingTag';

@injectable()
export default class PersonPassportComplexCheckingResultMapper implements ICheckingResultMapper {
  getPersonPassportComplexCheckingResultTagConfig(
    status: PassportResultCheckingEnum,
  ): PersonPassportResultCheckingTag {
    switch (status) {
      case PassportResultCheckingEnum.Unknown:
        return {
          iconName: SystemIconsNames.uiV3Error,
          type: 'error',
          title: 'Паспорт недействителен',
          tooltipText: 'Паспорт недействителен',
          tooltip: 'auto',
        };
      case PassportResultCheckingEnum.NotFound:
        return {
          iconName: SystemIconsNames.uiV3Error,
          type: 'error',
          title: 'Сведения о паспорте не были найдены',
          tooltipText: 'Сведения о паспорте не были найдены',
          tooltip: 'auto',
        };
      case PassportResultCheckingEnum.Verified50:
        return {
          iconName: SystemIconsNames.uiV3Warning,
          type: 'warning',
          title:
            'Связка ФИО-дата рождения-номер паспорта не найдена в источниках данных, номер паспорта и дата выдачи верифицированы, паспорт действителен, но связи номера с ФИО и ДР не найдено',
          tooltipText:
            'Связка ФИО-дата рождения-номер паспорта не найдена в источниках данных, номер паспорта и дата выдачи верифицированы, паспорт действителен, но связи номера с ФИО и ДР не найдено',
          tooltip: 'auto',
        };
      case PassportResultCheckingEnum.Verified80:
        return {
          iconName: SystemIconsNames.uiV3Warning,
          type: 'warning',
          title:
            'Связка ФИО-дата рождения-номер паспорта не найдена в источниках данных, номер паспорта и дата выдачи верифицированы, паспорт действителен, но точной связи с ФИО и ДР не найдено, тк возможны небольшие опечатки в ФИО (возможно, смена фамилии)',
          tooltipText:
            'Связка ФИО-дата рождения-номер паспорта не найдена в источниках данных, номер паспорта и дата выдачи верифицированы, паспорт действителен, но точной связи с ФИО и ДР не найдено, тк возможны небольшие опечатки в ФИО (возможно, смена фамилии)',
          tooltip: 'auto',
        };
      case PassportResultCheckingEnum.Verified100:
        return {
          iconName: SystemIconsNames.uiV3CheckRoundedStroke,
          type: 'success',
          title: 'Верифицирована связка ФИО-дата рождения-номер паспорта, паспорт действителен',
          tooltipText:
            'Верифицирована связка ФИО-дата рождения-номер паспорта, паспорт действителен',
          tooltip: 'auto',
        };
      default:
        return {
          iconName: SystemIconsNames.uiV3Warning,
          type: 'warning',
          title: 'Неизвестно',
          tooltipText: 'Необходимо проверить вручную',
          tooltip: 'always',
        };
    }
  }
}
