import { InjectionKey } from 'vue';
import { ApiPersonDetailVkSchoolRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkSchoolRecord';
import { ApiPersonDetailVkUniversityRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkUniversityRecord';

export interface IPersonSocialNetworkAdditionalInfoVkUserInfoFormatter {
  getPersonVkUserSchools(vkUserSchools: Array<ApiPersonDetailVkSchoolRecord>): string;

  getPersonVkUserUniversities(vkUserUniversities: Array<ApiPersonDetailVkUniversityRecord>): string;
}

export const IPersonSocialNetworkAdditionalInfoVkUserInfoFormatterKey: InjectionKey<IPersonSocialNetworkAdditionalInfoVkUserInfoFormatter> =
  Symbol('IPersonSocialNetworkAdditionalInfoVkUserInfoFormatterKey');
