import { useEvent } from '@/modules/events/composables/useEvent';

export function useRegulationCheckChangedEvent() {
  const { on, trigger } = useEvent<[id: string]>('RegulationCheckChanged');

  return {
    onRegulationCheckChanged: on,
    triggerRegulationCheckChangedEvent: trigger
  }
}
