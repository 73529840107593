import { injectable } from 'inversify';
import { ChartJSLineChartLine } from '@/modules/charts/modules/chartjs/components/UILineChart/types/ChartJSLineChartLine';
import { ChartJSLineChartDataset } from '@/modules/charts/modules/chartjs/components/UILineChart/types/ChartJSLineChartDataset';
import { useInjectable } from '@/hooks/useInjectable';
import { IChartJSLineDatasetsService } from '@/modules/charts/modules/chartjs/components/UILineChart/services/chartJSLineDatasetsService.interface';
import {
  ChartColorsFactoryKey,
  IChartColorsFactory,
} from '@/modules/charts/factories/colors/chartColorsFactory.interfaces';

@injectable()
export class ChartJSLineDatasetsService implements IChartJSLineDatasetsService {
  chartColorsFactory: IChartColorsFactory = useInjectable(ChartColorsFactoryKey);

  createDatasets(items: ChartJSLineChartLine[]): ChartJSLineChartDataset[] {
    return items.map((item, index) => {
      const color = this.chartColorsFactory.getD3ColorByIndex(index);
      return {
        label: item.label,
        data: item.data,
        backgroundColor: item.color || color,
        borderColor: item.color || color,
        fill: false,
        pointRadius: 5,
        pointHoverRadius: 7.5,
        pointHoverBorderWidth: 4,
        pointBackgroundColor: 'white',
        pointBorderColor: item.color || color,
        pointBorderWidth: 3,
        tooltip: item.tooltip,
      };
    });
  }
}
