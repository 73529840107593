import {
  IDxPdfOrientationToJsPdfOrientationMappingService
} from '@/modules/devExtreme/export/pdf/services/dxPdfOrientationToJsPdfOrientationMappingService/dxPdfOrientationToJsPdfOrientationMappingService.interfaces';
import { DxPdfOrientation } from '@/modules/devExtreme/export/pdf/types/dxPdfOrientation';
import { JsPdfOrientation } from '@/modules/jspdf/types/jsPdfOrientation';
import { injectable } from 'inversify';

@injectable()
export default class DxPdfOrientationToJsPdfOrientationMappingService implements IDxPdfOrientationToJsPdfOrientationMappingService {
  map(orientation: DxPdfOrientation): JsPdfOrientation {
    switch (orientation) {
      case DxPdfOrientation.portrait: return 'portrait';
      case DxPdfOrientation.landscape: return 'landscape';
    }
  }
}
