import { MaskOptions } from 'maska';

export const fullNameRequisiteMask: MaskOptions = {
  mask: 'Fe Fe Fe',
  tokens: {
    F: {
      pattern: /[А-ЯЁ]/,
      transform: (v: string) => v.toUpperCase(),
    },
    e: {
      pattern: /[а-яё]/,
      multiple: true,
    },
  },
};
