import { injectable } from 'inversify';
import { IIdentifiersMappingService } from '@/modules/import/services/identifiersMappingService.interface';
import { ValidationIdentifier } from '@/modules/import/types/validationIdentifier';

@injectable()
export default class IdentifiersMappingService implements IIdentifiersMappingService {
  getPersonIdentifiersList(identifiers: string): Array<ValidationIdentifier> {
    return identifiers.split(' ').reduce<Array<ValidationIdentifier>>((acc, item) => {
      if (item.length > 0) {
        if (item.length >= 12) {
          acc.push({
            title: 'ИНН',
            identifierType: 0,
            value: item,
          });
        }

        if (item.length <= 11) {
          acc.push({
            title: 'СНИЛС',
            identifierType: 1,
            value: item,
          });
        }
      }
      return acc;
    }, []);
  }

  getCompanyIdentifiersList(identifiers: string): Array<ValidationIdentifier> {
    return identifiers.split(' ').reduce<Array<ValidationIdentifier>>((acc, item) => {
      if (item.length > 0) {
        if (item.length <= 12) {
          acc.push({
            title: 'ИНН',
            identifierType: 0,
            value: item,
          });
        }

        if (item.length >= 13) {
          acc.push({
            title: 'ОГРН',
            identifierType: 1,
            value: item,
          });
        }
      }
      return acc;
    }, []);
  }
}
