import { injectable } from 'inversify';
import { ICompanyInnRequisiteMatchingService } from '@/modules/requisites/services/matchers/companyInnRequisiteMatchingService.interfaces';

@injectable()
export class CompanyInnRequisiteMatchingService implements ICompanyInnRequisiteMatchingService {
  match(value: string): boolean {
    const regExpInnInclude = /^\d{0,10}$/;
    const result = value.match(regExpInnInclude);
    return Array.isArray(result) && value.length != 0;
  }

  matchPartial(value: string): boolean {
    const regExpInnInclude = /^\d{10}$/;
    const result = value.match(regExpInnInclude);
    return Array.isArray(result);
  }
}
