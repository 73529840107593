import { UseAsyncUpdatesOptions } from '@/hooks/useAsyncUpdates.interfaces';
import { shallowRef, watch, WatchSource } from 'vue';

/**
 * @deprecated Use useUpdateQueue instead of this
 */
export function useAsyncUpdates(source: WatchSource | WatchSource[], updateAsync: () => Promise<void>, options?: UseAsyncUpdatesOptions) {
  const isUpdateRequired = shallowRef(false);
  const isUpdating = shallowRef(false);

  watch(source, () => {
    isUpdateRequired.value = true;
  }, { immediate: options?.immediate });

  watch(
    () => isUpdateRequired.value && !isUpdating.value,
    async (isAllowedToStart) => {
      if (isAllowedToStart) {
        isUpdating.value = true;
        isUpdateRequired.value = false;

        await updateAsync();

        isUpdating.value = false;
      }
    }, { immediate: true });
}
