import {
  ActionContext,
  ActionTree,
  MutationTree,
  Module,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
} from 'vuex';
import { RootState } from '@/store/type';
import dataCollectionApi from '@/api/eventLog';
import { ActionTypes, DataCollectionPageState, Mutations, MutationTypes } from './types';

const moduleState: DataCollectionPageState = {
  data: [],
  namespaced: true,
};

const mutations: MutationTree<DataCollectionPageState> & Mutations = {
  [MutationTypes.SET_DATA](state, payload) {
    state.data = payload.data;
  },
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<DataCollectionPageState, RootState>, 'commit'>;

interface Actions {
  [ActionTypes.GET_DATA_DATA_COLLECTION]({ commit }: AugmentedActionContext): void;
}

const actions: ActionTree<DataCollectionPageState, RootState> & Actions = {
  async [ActionTypes.GET_DATA_DATA_COLLECTION]({ commit }) {
    const { data } = await dataCollectionApi.getData();
    commit(MutationTypes.SET_DATA, { data: data.logItems });
  },
};

export type DataCollectionPageStore<S = DataCollectionPageState> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions,
  ): ReturnType<Actions[K]>;
};

export const DataCollectionPageModule: Module<DataCollectionPageState, RootState> = {
  state: moduleState,
  mutations,
  actions,
};
