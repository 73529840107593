import { injectable } from 'inversify';
import { IPersonRelativesNormalizationService } from '@/modules/dossier/person/common/services/normalization/personRelativesNormalizationService.interfaces';
import { ApiPersonDetailFamilyRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailFamilyRecord';

@injectable()
export default class PersonRelativesNormalizationService
  implements IPersonRelativesNormalizationService
{
  normalize(item: ApiPersonDetailFamilyRecord): Required<ApiPersonDetailFamilyRecord> {
    return {
      id: item.id,
      relation: item.relation,
      firstName: item.firstName ?? '',
      lastName: item.lastName ?? '',
      patronymic: item.patronymic ?? '',
      birthday: item.birthday ?? '',
      inn: item.inn ?? '',
      birthdayStr: item.birthdayStr ?? '',
      dossierId: item.dossierId,
    };
  }
}
