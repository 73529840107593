import { injectable } from 'inversify';
import { INumberParserService } from '@/modules/common/modules/numbers/services/numberParserService/numberParserService.interfaces';

const searchDashAndSpaceRegex = /[\-_\s]/g;

@injectable()
export default class NumberParserService implements INumberParserService {
  parse(value: string): number | undefined {
    const trimmedValue = value.trim();
    const firstChar = trimmedValue.charAt(0);
    const isNegative = firstChar == '-';
    const normalizedValue = trimmedValue.replace(searchDashAndSpaceRegex, '');
    const parsedValue = parseFloat(normalizedValue);

    if (Number.isNaN(parsedValue)) {
      return undefined;
    }

    return isNegative ? -parsedValue : parsedValue;
  }
}
