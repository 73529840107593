import { InjectionKey } from 'vue';
import { ImageGroupItem } from '@/modules/images/types/imageGroupItem';
import { ApiPersonDetailVkPhotoRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkPhotoRecord';

export interface IPersonSocialNetworkAdditionalInfoVkGalleryMappingService {
  mapSocialNetworkAdditionalInfoVkGallery(
    photos: Array<ApiPersonDetailVkPhotoRecord>,
  ): Array<ImageGroupItem>;
}

export const IPersonSocialNetworkAdditionalInfoVkGalleryMappingServiceKey: InjectionKey<IPersonSocialNetworkAdditionalInfoVkGalleryMappingService> =
  Symbol('IPersonSocialNetworkAdditionalInfoVkGalleryMappingServiceKey');
