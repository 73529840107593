import { useEvent } from '@/modules/events/composables/useEvent';

export function useReportsGenerationTaskCancelledEvent() {
  const { on, trigger } = useEvent<[taskId: string]>('ReportsGenerationTaskCancelled');

  return {
    triggerGenerationTaskCancelledEvent: trigger,
    onGenerationTaskCancelled: on,
  };
}
