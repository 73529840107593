import { useEvent } from '@/modules/events/composables/useEvent';
import { DataTableConfigProfiles } from '@/modules/profile/types/api/dataTableConfigProfile';

export function useDataTableSettingsChangedEvent() {
  const { on, trigger} = useEvent<[profile: DataTableConfigProfiles]>('DataTableSettingsChanged');

  return {
    triggerDataTableSettingsChangedEvent: trigger,
    onDataTableSettingsChanged: on
  }
}
