import { RootState } from '@/store/type';
import { MutationTree, Module } from 'vuex';
import {
  ManualCompanyState,
  Mutations,
  MutationTypes,
} from '@/store/modules/ImportManuals/ImportManualCompanies/types';

const moduleState: ManualCompanyState = {
  companyName: '',
  inn: '',
  ogrn: '',
  phoneType: '',
  phoneNumber: '',
  email: '',
  socialNetworkType: '',
  socialNetworkId: '',
  employeeSocialNetworkType: '',
  employeeSocialNetworkId: '',
  transportNumber: '',
  transportVin: '',
  note: '',
};

const mutations: MutationTree<ManualCompanyState> & Mutations = {
  [MutationTypes.SET_NEW_VALUE_COMPANIES](state, payload: { name: string; value: string }) {
    state[payload.name] = payload.value;
  },
  [MutationTypes.RESET_VALUES_COMPANIES](state) {
    Array.of(...Object.keys(state)).forEach((field) => (state[field] = ''));
  },
};

const ImportManualCompaniesModule: Module<ManualCompanyState, RootState> = {
  state: moduleState,
  mutations,
};

export default ImportManualCompaniesModule;
