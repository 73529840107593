import { UseDxTagConfig } from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';
import { ReportHistoryItem } from '@/modules/reports/common/types/reportHistoryItem';
import { InjectionKey } from 'vue';

export interface IDesktopReportsHistoryItemTagsFactory {
  getTag(report: ReportHistoryItem): UseDxTagConfig;
}

export const IDesktopReportsHistoryItemTagsFactoryKey: InjectionKey<IDesktopReportsHistoryItemTagsFactory> =
  Symbol('IDesktopReportsHistoryItemTagsFactory');
