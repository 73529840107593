import {
  SearchConceptOptionsGroupType
} from '@/modules/searchConcept/common/types/searchConceptOptionsGroupType';
import { InjectionKey } from 'vue';

export interface ISearchConceptOptionsGroupTypeFormatter {
  format(groupType: SearchConceptOptionsGroupType): string
}

export const ISearchConceptOptionsGroupTypeFormatterKey: InjectionKey<ISearchConceptOptionsGroupTypeFormatter> = Symbol('ISearchConceptOptionsGroupTypeFormatter');
