import { MaskOptions } from 'maska';

export const carNumberMask: MaskOptions = {
  mask: 'A###A ##Z',
  tokens: {
    A: { pattern: /[А-я]/, transform: (s: string) => s.toUpperCase() },
    Z: { pattern: /[0-9]/, repeated: true },
  },
};

export const carNumberTwoDigitRegionMask: MaskOptions = {
  mask: 'A###A ##',
  tokens: {
    A: { pattern: /[А-я]/, transform: (s: string) => s.toUpperCase() },
  },
};

export const carNumberMaskPlaceholder = 'х000х 000';
