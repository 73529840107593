import DefaultAPIInstance from '@/api/client';
import { RatePayload } from '@/types/api/rate/ratePayload';
import { ApiMassImportPersonRequest } from '@/modules/import/types/api/apiMassImportPersonRequest';
import { ApiManualImportCreatePerson } from '@/modules/import/types/api/person/apiManualImportCreatePerson';

const personApi = {
  makeProcessingPerson(body: FormData) {
    const url = '/person/file/add';
    return DefaultAPIInstance.post(url, body);
  },

  updatePersonRate(body: RatePayload) {
    const url = '/person/rate/update';
    return DefaultAPIInstance.post(url, body);
  },

  importPersonsProgressPercent(importProgressId: string) {
    const url = `/person/import-progress-percent/${importProgressId}`;
    return DefaultAPIInstance.get(url);
  },

  getPersonsImportResult(importProgressId: string) {
    const url = `/person/import-result/${importProgressId}`;
    return DefaultAPIInstance.get(url);
  },

  createPerson(body: ApiManualImportCreatePerson) {
    const url = '/person/add';
    return DefaultAPIInstance.post(url, body);
  },

  deletePersons(personIds: Array<string>) {
    const url = '/person/delete';
    return DefaultAPIInstance.post(url, personIds);
  },

  massPersonImport(payload: ApiMassImportPersonRequest) {
    const url = '/person/bulk/add';
    return DefaultAPIInstance.post(url, payload);
  },

  addToReport(ids: Array<string>) {
    const url = '/reports/report-person-dossier';
    return DefaultAPIInstance.post(url, { ids, reportDate: new Date() });
  },

  getJsonReport(personId: string) {
    const url = `reports/example/report-person-json/${personId}`;
    return DefaultAPIInstance.post(url);
  },
};

export default personApi;
