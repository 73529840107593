import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import PersonFinancialScoringsMappingService from '@/modules/dossier/person/financialScorings/services/mappings/personFinancialScoringsMappingService';
import { IPersonFinancialScoringsMappingServiceKey } from '@/modules/dossier/person/financialScorings/services/mappings/personFinancialScoringsMappingService.interface';

import AnalyticsScoreThemeFactory from '@/modules/dossier/person/financialScorings/services/formatters/analyticsScoreThemeFactory';
import { IAnalyticsScoreThemeFactoryKey } from '@/modules/dossier/person/financialScorings/services/formatters/analyticsScoreThemeFactory.interface';
import AnalyticsScoreTypeFormatterService from '@/modules/dossier/person/financialScorings/services/formatters/analyticsScoreTypeFormatterService';
import { IAnalyticsScoreTypeFormatterServiceKey } from '@/modules/dossier/person/financialScorings/services/formatters/analyticsScoreTypeFormatterService.interface';
import AnalyticsTypeDescriptionFactory from '@/modules/dossier/person/financialScorings/services/formatters/analyticsTypeDescriptionFactory';
import { IAnalyticsTypeDescriptionFactoryKey } from '@/modules/dossier/person/financialScorings/services/formatters/analyticsTypeDescriptionFactory.interface';
import AnalyticsTypeFormatterService from '@/modules/dossier/person/financialScorings/services/formatters/analyticsTypeFormatterService';
import { IAnalyticsTypeFormatterServiceKey } from '@/modules/dossier/person/financialScorings/services/formatters/analyticsTypeFormatterService.interface';

import { IAnalyticsTypeParserServiceKey } from '@/modules/dossier/person/financialScorings/services/parsers/analyticsTypeParserService.interface';
import AnalyticsTypeParserService from '@/modules/dossier/person/financialScorings/services/parsers/analyticsTypeParserService';
import { IAnalyticsScoreTypeParserServiceKey } from '@/modules/dossier/person/financialScorings/services/parsers/analyticsScoreTypeParserService.interface';
import AnalyticsScoreTypeParserService from '@/modules/dossier/person/financialScorings/services/parsers/analyticsScoreTypeParserService';
import { IAnalyticsScoreOKBTypeParserServiceKey } from '@/modules/dossier/person/financialScorings/services/parsers/analyticsScoreOKBTypeParserService.interface';
import AnalyticsScoreOKBTypeParserService from '@/modules/dossier/person/financialScorings/services/parsers/analyticsScoreOKBTypeParserService';

export function bindPersonFinancialScoringsServices(bind: IContainerBinder) {
  bind(IPersonFinancialScoringsMappingServiceKey).to(PersonFinancialScoringsMappingService);
  bind(IAnalyticsTypeParserServiceKey).to(AnalyticsTypeParserService);
  bind(IAnalyticsScoreTypeParserServiceKey).to(AnalyticsScoreTypeParserService);
  bind(IAnalyticsScoreThemeFactoryKey).to(AnalyticsScoreThemeFactory);
  bind(IAnalyticsScoreTypeFormatterServiceKey).to(AnalyticsScoreTypeFormatterService);
  bind(IAnalyticsTypeDescriptionFactoryKey).to(AnalyticsTypeDescriptionFactory);
  bind(IAnalyticsTypeFormatterServiceKey).to(AnalyticsTypeFormatterService);
  bind(IAnalyticsScoreOKBTypeParserServiceKey).to(AnalyticsScoreOKBTypeParserService);
}
