import SearchConceptOptionGroupsFactory
  from '@/modules/searchConcept/common/factories/searchConceptOptionGroupsFactory';
import {
  ICompanySearchConceptOptionGroupsFactory
} from '@/modules/searchConcept/company/factories/companySearchConceptOptionGroupsFactory.interfaces';
import {
  ICompanySearchConceptOptionsFactory, ICompanySearchConceptOptionsFactoryKey
} from '@/modules/searchConcept/company/factories/companySearchConceptOptionsFactory.interfaces';
import { inject, injectable } from 'inversify';

@injectable()
export default class CompanySearchConceptOptionGroupsFactory extends SearchConceptOptionGroupsFactory implements ICompanySearchConceptOptionGroupsFactory {
  constructor(
    @inject(ICompanySearchConceptOptionsFactoryKey as symbol) companyOptionsFactory: ICompanySearchConceptOptionsFactory
  ) {
    super(companyOptionsFactory);
  }
}
