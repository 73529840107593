import { injectable } from 'inversify';
import { IPersonMentionsIconsService } from '@/modules/dossier/person/mentions/services/personMentionsIconsService.interface';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';

@injectable()
export default class PersonMentionsIconsService implements IPersonMentionsIconsService {
  getMentionIconName(value: string | undefined): SystemIconsNames {
    if (value != undefined) {
      switch (value.toLowerCase().trim()) {
        case 'getcontact':
          return SystemIconsNames.mentionsGetContact;
        case 'truecaller':
          return SystemIconsNames.mentionsTrueCaller;
        case 'odyssey':
          return SystemIconsNames.mentionsOdyssey;
        default:
          return SystemIconsNames.unknown;
      }
    }
    return SystemIconsNames.unknown;
  }
}
