import { AccessScopeEnum } from '@/modules/rights/type/AccessScopeEnum';
import RouteNames from '@/modules/router/common/types/routeNames';
import { RouteLocationNormalizedLoaded, RouteRecordRaw } from 'vue-router';

export const generalRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: RouteNames.HOME,
    component: () => import('@/modules/home/views/HomeRootView.vue'),
  },
  {
    path: '/dashboard',
    name: RouteNames.DASHBOARD,
    component: () => import('@/modules/dashboard/views/DashboardView.vue'),
  },
  {
    path: '/analysis-v2',
    name: RouteNames.ANALYSIS_V2,
    component: () => import('@/modules/analysis/views/AnalysisV2View.vue'),
  },
  {
    path: '/lists',
    name: RouteNames.DATA_LISTS_ROOT,
    component: () => import('@/modules/lists/views/ListsRootView.vue'),
    redirect: { name: RouteNames.DATA_ALL_LISTS },
    children: new Array<RouteRecordRaw>(
      {
        path: '/lists',
        name: RouteNames.DATA_ALL_LISTS,
        component: () => import('@/modules/lists/views/ListsAllListsView.vue'),
        redirect: { name: RouteNames.DATA_PERSONS_LIST },
        children: new Array<RouteRecordRaw>(
          {
            path: 'persons-lists',
            name: RouteNames.DATA_PERSONS_LIST,
            component: () => import('@/modules/lists/views/ListsPersonsView.vue'),
          },
          {
            path: 'companies-lists',
            name: RouteNames.DATA_COMPANIES_LIST,
            component: () => import('@/modules/lists/views/ListsCompaniesView.vue'),
          },
        ),
      },
      {
        path: '/persons-lists/:personsListId',
        name: RouteNames.DATA_PERSONS_DETAIL_LIST,
        component: () => import('@/modules/lists/views/ListsPersonsDetailView.vue'),
        redirect: { name: RouteNames.DATA_PERSONS_DETAIL_LIST_ANALYTICS },
        children: new Array<RouteRecordRaw>(
          {
            path: '/persons-lists/:personsListId/analytics',
            name: RouteNames.DATA_PERSONS_DETAIL_LIST_ANALYTICS,
            component: () => import('@/modules/lists/views/ListsPersonsAnalyticsView.vue'),
          },
          {
            path: '/persons-lists/:personsListId/details',
            name: RouteNames.DATA_PERSONS_DETAIL_LIST_TABLE,
            component: () => import('@/modules/lists/views/ListsPersonsDetailTableView.vue'),
          },
        ),
      },
      {
        path: '/companies-lists/:companiesListId',
        name: RouteNames.DATA_COMPANIES_DETAIL_LIST,
        component: () => import('@/modules/lists/views/ListsCompaniesDetailView.vue'),
        redirect: { name: RouteNames.DATA_COMPANIES_DETAIL_LIST_ANALYTICS },
        children: new Array<RouteRecordRaw>(
          {
            path: '/companies-lists/:companiesListId/analytics',
            name: RouteNames.DATA_COMPANIES_DETAIL_LIST_ANALYTICS,
            component: () => import('@/modules/lists/views/ListsCompaniesAnalyticsView.vue'),
          },
          {
            path: '/companies-lists/:companiesListId/details',
            name: RouteNames.DATA_COMPANIES_DETAIL_LIST_TABLE,
            component: () => import('@/modules/lists/views/ListsCompaniesDetailTableView.vue'),
          },
        ),
      },
    ),
  },
  {
    path: '/data-and-analysis/search',
    name: RouteNames.SEARCH_CONCEPT,
    component: () => import('@/modules/searchConcept/general/views/SearchConceptView.vue'),
    meta: { accessScopes: [AccessScopeEnum.hasAccessToSearch] },
  },
  {
    path: '/data-grid/',
    name: RouteNames.DATA_GRID,
    component: () => import('@/views/DataGridView.vue'),
    redirect: { name: RouteNames.DATA_GRID_PERSONS_V2 },
    meta: { accessScopes: [AccessScopeEnum.hasAccessToDataGrid] },
    children: new Array<RouteRecordRaw>(
      {
        path: '/data-grid/persons',
        name: RouteNames.DATA_GRID_PERSONS_V2,
        component: () => import('@/modules/dataGrid/person/components/PersonDataGrid.vue'),
      },
      {
        path: '/data-grid/companies',
        name: RouteNames.DATA_GRID_COMPANIES_V2,
        component: () => import('@/modules/dataGrid/company/components/CompanyDataGrid.vue'),
      },
    ),
  },
  {
    // старая версия для '/history', '/data-collection' можно удалить со всеми дочерними компонентами
    path: '/data-collection',
    name: RouteNames.COLLECTION,
    component: () => import('@/views/DataCollectionView.vue'),
    meta: { accessScopes: [AccessScopeEnum.hasAccessToDataCollection] },
  },
  {
    path: '/history',
    name: RouteNames.HISTORY,
    redirect: { name: RouteNames.HISTORY_PERSON },
    component: () => import('@/modules/history/views/HistoryView.vue'),
    meta: { accessScopes: [AccessScopeEnum.hasAccessToHistory] },
    children: new Array<RouteRecordRaw>(
      {
        path: 'person',
        name: RouteNames.HISTORY_PERSON,
        component: () => import('@/modules/history/views/HistoryPersonView.vue'),
      },
      {
        path: 'company',
        name: RouteNames.HISTORY_COMPANY,
        component: () => import('@/modules/history/views/HistoryCompanyView.vue'),
      },
    ),
  },
  {
    path: '/data-and-analysis/persons',
    name: RouteNames.INDIVIDUAL_ENTITY_DATA,
    component: () => import('@/views/DataAndAnalysisView.vue'),
  },
  {
    path: '/data-and-analysis/companies',
    name: RouteNames.LEGAL_ENTITY_DATA,
    component: () => import('@/views/DataAndAnalysisView.vue'),
  },
  {
    path: '/data-and-analysis/lists',
    name: RouteNames.LISTS_DATA,
    component: () => null,
  },
  {
    path: '/data-and-analysis/import',
    name: RouteNames.IMPORT_DATA,
    component: () => null,
  },
  {
    path: '/regulations-check',
    name: RouteNames.COMMON_REGULATIONS_CHECK,
    redirect: { name: RouteNames.COMMON_REGULATIONS_CHECK_PERSON },
    component: () => import('@/modules/regulationsCheck/common/views/RegulationsCheckRootView.vue'),
    children: new Array<RouteRecordRaw>(
      {
        path: 'person',
        name: RouteNames.COMMON_REGULATIONS_CHECK_PERSON,
        component: () =>
          import('@/modules/regulationsCheck/common/views/RegulationsCheckPersonView.vue'),
      },
      {
        path: 'company',
        name: RouteNames.COMMON_REGULATIONS_CHECK_COMPANY,
        component: () =>
          import('@/modules/regulationsCheck/common/views/RegulationsCheckCompanyView.vue'),
      },
    ),
  },
  {
    path: '/administration',
    name: RouteNames.ADMINISTRATION_ROOT,
    component: () => import('@/modules/administration/views/AdministrationRootView.vue'),
    redirect: { name: RouteNames.ADMINISTRATION_USERS },
    meta: { accessScopes: [AccessScopeEnum.hasAccessToAdministrationRoot] },
    children: new Array<RouteRecordRaw>(
      {
        path: '/users',
        name: RouteNames.ADMINISTRATION_USERS,
        component: () => import('@/modules/administration/users/views/AdministrationUsersView.vue'),
        meta: { accessScopes: [AccessScopeEnum.hasAccessToUsers] },
      },
      {
        path: '/administration/settings',
        name: RouteNames.ADMINISTRATION_SETTINGS,
        component: () => import('@/modules/administration/views/AdministrationSettingsView.vue'),
      },
      {
        path: '/administration/collecting-data',
        name: RouteNames.ADMINISTRATION_COLLECTING_DATA,
        component: () =>
          import('@/modules/administration/views/AdministrationDataCollectionView.vue'),
      },
      {
        path: '/administration/companies-types',
        name: RouteNames.ADMINISTRATION_COMPANIES_TYPES,
        component: () =>
          import('@/modules/administration/views/AdministrationCompaniesTypesView.vue'),
      },

      {
        path: '/administration/marker-weight',
        name: RouteNames.ADMINISTRATION_MARKER_WEIGHT,
        component: () =>
          import('@/modules/administration/views/AdministrationMarkersWeightView.vue'),
      },

      {
        path: '/administration/statistics',
        name: RouteNames.ADMINISTRATION_STATISTICS,
        component: () => import('@/modules/administration/views/AdministrationStatisticsView.vue'),
      },
    ),
  },
  {
    path: '/person/:personId/',
    name: RouteNames.PERSON,
    component: () => import('@/modules/dossier/person/main/views/PersonDataView.vue'),
    redirect: { name: RouteNames.PERSON_OVERVIEW },
    props: route => ({ personId: route.params.personId }),
    meta: { accessScopes: [AccessScopeEnum.hasAccessToPerson] },
    children: new Array<RouteRecordRaw>(
      {
        path: 'overview',
        name: RouteNames.PERSON_OVERVIEW,
        component: () => import('@/modules/dossier/person/main/views/PersonOverviewView.vue'),
      },
      {
        path: 'passports',
        name: RouteNames.PERSON_PASSPORTS_ROOT,
        redirect: { name: RouteNames.PERSON_PASSPORTS_DATA },
        component: () =>
          import('@/modules/dossier/person/passports/views/PersonPassportsRootView.vue'),
        children: [
          {
            path: 'data',
            name: RouteNames.PERSON_PASSPORTS_DATA,
            component: () =>
              import('@/modules/dossier/person/passports/views/PersonPassportsDataView.vue'),
          },
          {
            path: 'checks',
            name: RouteNames.PERSON_PASSPORTS_CHECKS,
            component: () =>
              import('@/modules/dossier/person/passports/views/PersonPassportsChecksView.vue'),
          },
        ],
      },
      {
        path: 'businessTrip',
        name: RouteNames.PERSON_BUSINESS_TRIP,
        component: () =>
          import('@/modules/dossier/person/common/views/PersonBusinessTripsView.vue'),
      },
      {
        path: 'airTravel',
        name: RouteNames.PERSON_AIR_TRAVEL,
        component: () => import('@/modules/dossier/person/common/views/PersonAirTravelView.vue'),
      },
      {
        path: 'friends',
        name: RouteNames.PERSON_FRIENDS,
        component: () => import('@/modules/dossier/person/common/views/PersonFriendsView.vue'),
      },
      {
        path: 'relatives',
        name: RouteNames.PERSON_RELATIVES,
        component: () => import('@/modules/dossier/person/common/views/PersonRelativesView.vue'),
      },
      {
        path: 'education',
        name: RouteNames.PERSON_EDUCATION,
        component: () => import('@/modules/dossier/person/common/views/PersonEducationView.vue'),
      },
      {
        path: 'connections',
        name: RouteNames.PERSON_CONNECTIONS,
        component: () => import('@/modules/dossier/person/common/views/PersonConnectionsView.vue'),
      },
      {
        path: 'counterparty',
        name: RouteNames.PERSON_COUNTERPARTIES_RELATIONS,
        component: () =>
          import(
            '@/modules/dossier/relationsGraph/person/views/PersonCounterpartyRelationsView.vue'
          ),
      },
      {
        path: 'black-list',
        name: RouteNames.PERSON_BLACK_LIST_RELATIONS,
        component: () =>
          import('@/modules/dossier/relationsGraph/person/views/PersonBlacklistRelationsView.vue'),
      },
      {
        path: 'competitor',
        name: RouteNames.PERSON_COMPETITORS_RELATIONS,
        component: () =>
          import(
            '@/modules/dossier/relationsGraph/person/views/PersonCompetitorsRelationsView.vue'
          ),
      },
      {
        path: 'inner-loop-team',
        name: RouteNames.PERSON_INNER_LOOP_TEAM_RELATIONS,
        component: () =>
          import(
            '@/modules/dossier/relationsGraph/person/views/PersonInnerLoopTeamRelationsView.vue'
          ),
      },
      {
        path: 'related-persons',
        name: RouteNames.PERSON_RELATED_PERSONS_RELATIONS,
        component: () =>
          import('@/modules/dossier/person/relatedPersons/views/PersonRelatedPersonsView.vue'),
      },
      {
        path: 'relatives',
        name: RouteNames.PERSON_RELATIVES,
        component: () => import('@/modules/dossier/person/common/views/PersonRelativesView.vue'),
      },
      {
        path: 'affiliated',
        name: RouteNames.PERSON_AFFILIATED,
        component: () => import('@/modules/dossier/person/common/views/PersonAffiliatedView.vue'),
      },
      {
        path: 'contacts',
        name: RouteNames.PERSON_CONTACTS_ROOT,
        redirect: { name: RouteNames.PERSON_CONTACTS_PHONES },
        component: () =>
          import('@/modules/dossier/person/contacts/views/PersonContactsRootView.vue'),
        children: [
          {
            path: 'phones',
            name: RouteNames.PERSON_CONTACTS_PHONES,
            component: () =>
              import('@/modules/dossier/person/contacts/views/PersonContactsPhonesView.vue'),
          },
          {
            path: 'mail',
            name: RouteNames.PERSON_CONTACTS_MAILS,
            component: () =>
              import('@/modules/dossier/person/contacts/views/PersonContactsMailView.vue'),
          },
        ],
      },
      {
        path: 'addresses',
        name: RouteNames.PERSON_ADDRESSES,
        component: () => import('@/modules/dossier/person/addresses/views/PersonAddressesView.vue'),
      },
      {
        path: 'announcements',
        name: RouteNames.PERSON_ANNOUNCEMENTS,
        component: () =>
          import('@/modules/dossier/person/announcements/views/PersonAnnouncementsView.vue'),
      },
      {
        path: 'social-networks',
        name: RouteNames.PERSON_SOCIAL_NETWORKS,
        component: () =>
          import('@/modules/dossier/person/socialNetworks/views/PersonSocialNetworksView.vue'),
      },
      {
        path: 'mentions',
        name: RouteNames.PERSON_MENTIONS,
        component: () => import('@/modules/dossier/person/mentions/views/PersonMentionsView.vue'),
      },
      {
        path: 'negative-overview',
        name: RouteNames.PERSON_NEGATIVE_OVERVIEW_ROOT,
        component: () =>
          import(
            '@/modules/dossier/person/negativeOverview/views/PersonNegativeOverviewRootView.vue'
          ),
        redirect: { name: RouteNames.PERSON_NEGATIVE_OVERVIEW_STATISTICS },
        children: new Array<RouteRecordRaw>(
          {
            path: 'statistics',
            name: RouteNames.PERSON_NEGATIVE_OVERVIEW_STATISTICS,
            component: () =>
              import(
                '@/modules/dossier/person/negativeOverview/views/PersonNegativeOverviewStatisticsView.vue'
              ),
          },
          {
            path: 'clarification',
            name: RouteNames.PERSON_NEGATIVE_OVERVIEW_CLARIFICATION_DATA,
            component: () =>
              import(
                '@/modules/dossier/person/negativeOverview/views/PersonNegativeOverviewClarificationDataView.vue'
              ),
          },
        ),
      },
      {
        path: 'enforcement-proceedings',
        name: RouteNames.PERSON_ENFORCEMENT_PROCEEDINGS_ROOT,
        component: () =>
          import(
            '@/modules/dossier/person/enforcementProceedings/views/PersonEnforcementProceedingsRootView.vue'
          ),
        redirect: { name: RouteNames.PERSON_ENFORCEMENT_PROCEEDINGS_STATISTICS },
        children: new Array<RouteRecordRaw>(
          {
            path: 'statistics',
            name: RouteNames.PERSON_ENFORCEMENT_PROCEEDINGS_STATISTICS,
            component: () =>
              import(
                '@/modules/dossier/person/enforcementProceedings/views/PersonEnforcementProceedingsStatisticsView.vue'
              ),
          },
          {
            path: 'data',
            name: RouteNames.PERSON_ENFORCEMENT_PROCEEDINGS_DATA,
            component: () =>
              import(
                '@/modules/dossier/person/enforcementProceedings/views/PersonEnforcementProceedingsDataView.vue'
              ),
          },
        ),
      },
      {
        path: 'arbitration-cases',
        name: RouteNames.PERSON_ARBITRATION_ROOT,
        component: () =>
          import('@/modules/dossier/person/arbitration/views/PersonArbitrationRootView.vue'),
        redirect: { name: RouteNames.PERSON_ARBITRATION_ANALYTICS },
        children: [
          {
            path: 'analytics',
            name: RouteNames.PERSON_ARBITRATION_ANALYTICS,
            component: () =>
              import(
                '@/modules/dossier/person/arbitration/views/PersonArbitrationAnalyticsView.vue'
              ),
          },
          {
            path: 'cases',
            name: RouteNames.PERSON_ARBITRATION_CASES,
            component: () =>
              import('@/modules/dossier/person/arbitration/views/PersonArbitrationCasesView.vue'),
          },
        ],
      },
      {
        path: 'criminal-cases',
        name: RouteNames.PERSON_CRIMINAL_CASES,
        component: () =>
          import(
            '@/modules/dossier/person/negativeCriminals/views/PersonNegativeCriminalsView.vue'
          ),
      },
      {
        path: 'debts',
        name: RouteNames.PERSON_DEBTS_ROOT,
        component: () =>
          import('@/modules/dossier/person/negativeDebts/views/PersonNegativeDebtsRootView.vue'),
        redirect: { name: RouteNames.PERSON_DEBTS_ANALYTICS },
        children: new Array<RouteRecordRaw>(
          {
            path: 'analytics',
            name: RouteNames.PERSON_DEBTS_ANALYTICS,
            component: () =>
              import(
                '@/modules/dossier/person/negativeDebts/views/PersonNegativeDebtsAnalyticsView.vue'
              ),
          },
          {
            path: 'debts-list',
            name: RouteNames.PERSON_DEBTS_DEBTS_LIST,
            component: () =>
              import(
                '@/modules/dossier/person/negativeDebts/views/PersonNegativeDebtsListView.vue'
              ),
          },
        ),
      },
      {
        path: 'wanted',
        name: RouteNames.PERSON_WANTED,
        component: () =>
          import('@/modules/dossier/person/negativeWanteds/views/PersonNegativeWantedsView.vue'),
      },
      {
        path: 'participation',
        name: RouteNames.PERSON_PARTICIPATION_ROOT,
        redirect: { name: RouteNames.PERSON_PARTICIPATION_UL_ROOT },
        component: () =>
          import('@/modules/dossier/person/participation/views/PersonParticipationRootView.vue'),
        children: [
          {
            path: 'ul',
            name: RouteNames.PERSON_PARTICIPATION_UL_ROOT,
            redirect: { name: RouteNames.PERSON_PARTICIPATION_UL_ANALYTIC },
            component: () =>
              import(
                '@/modules/dossier/person/participation/views/PersonParticipationUlRootView.vue'
              ),
            children: [
              {
                path: 'analytic',
                name: RouteNames.PERSON_PARTICIPATION_UL_ANALYTIC,
                component: () =>
                  import(
                    '@/modules/dossier/person/participation/views/PersonParticipationUlAnalyticView.vue'
                  ),
              },
              {
                path: 'data',
                name: RouteNames.PERSON_PARTICIPATION_UL_DATA,
                component: () =>
                  import(
                    '@/modules/dossier/person/participation/views/PersonParticipationUlDataView.vue'
                  ),
              },
              {
                path: 'checks',
                name: RouteNames.PERSON_PARTICIPATION_UL_CHECKS,
                component: () =>
                  import(
                    '@/modules/dossier/person/participation/views/PersonParticipationUlChecksView.vue'
                  ),
              },
            ],
          },
          {
            path: 'ip',
            name: RouteNames.PERSON_PARTICIPATION_IP_ROOT,
            redirect: { name: RouteNames.PERSON_PARTICIPATION_IP_ANALYTIC },
            component: () =>
              import(
                '@/modules/dossier/person/participation/views/PersonParticipationIpRootView.vue'
              ),
            children: [
              {
                path: 'analytic',
                name: RouteNames.PERSON_PARTICIPATION_IP_ANALYTIC,
                component: () =>
                  import(
                    '@/modules/dossier/person/participation/views/PersonParticipationIpAnalyticView.vue'
                  ),
              },
              {
                path: 'data',
                name: RouteNames.PERSON_PARTICIPATION_IP_DATA,
                component: () =>
                  import(
                    '@/modules/dossier/person/participation/views/PersonParticipationIpDataView.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'affiliated',
        name: RouteNames.PERSON_AFFILIATED,
        component: () => import('@/modules/dossier/person/common/views/PersonAffiliatedView.vue'),
      },
      {
        path: 'work',
        name: RouteNames.PERSON_WORK,
        component: () => import('@/modules/dossier/person/jobs/views/PersonWorkView.vue'),
      },
      {
        path: 'bankruptcy-overview',
        name: RouteNames.PERSON_BANKRUPTCY_OVERVIEW,
        component: () =>
          import('@/modules/dossier/person/bankruptcy/views/PersonBankruptcyOverviewView.vue'),
      },
      {
        path: 'bankruptcy-stages',
        name: RouteNames.PERSON_BANKRUPTCY_STAGES,
        component: () =>
          import('@/modules/dossier/person/bankruptcy/views/PersonBankruptcyStagesView.vue'),
      },
      {
        path: 'bankruptcy-messages',
        name: RouteNames.PERSON_BANKRUPTCY_MESSAGES,
        component: () =>
          import('@/modules/dossier/person/bankruptcy/views/PersonBankruptcyMessagesView.vue'),
      },
      {
        path: 'regulations-check',
        name: RouteNames.PERSON_REGULATIONS_CHECK_ROOT,
        redirect: { name: RouteNames.PERSON_REGULATIONS_CHECK_INIT },
        component: () =>
          import('@/modules/regulationsCheck/person/views/PersonRegulationsCheckRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'init',
            name: RouteNames.PERSON_REGULATIONS_CHECK_INIT,
            redirect: { name: RouteNames.PERSON_REGULATIONS_CHECK_ACTUAL_INFO },
            component: () =>
              import('@/modules/regulationsCheck/person/views/PersonRegulationsChecksInitView.vue'),
            children: new Array<RouteRecordRaw>(
              {
                path: 'actual-info',
                name: RouteNames.PERSON_REGULATIONS_CHECK_ACTUAL_INFO,
                redirect: { name: RouteNames.PERSON_REGULATIONS_CHECK_AUTO_INDICATORS },
                component: () =>
                  import(
                    '@/modules/regulationsCheck/person/views/PersonRegulationsChecksActualInfo.vue'
                  ),
                children: new Array<RouteRecordRaw>(
                  {
                    path: 'auto-indicators',
                    name: RouteNames.PERSON_REGULATIONS_CHECK_AUTO_INDICATORS,
                    component: () =>
                      import(
                        '@/modules/regulationsCheck/person/views/PersonRegulationsChecksAutoIndicatorsView.vue'
                      ),
                  },
                  {
                    path: 'check-required-indicators',
                    name: RouteNames.PERSON_REGULATIONS_CHECK_CHECK_REQUIRED_INDICATORS,
                    component: () =>
                      import(
                        '@/modules/regulationsCheck/person/views/PersonRegulationsChecksCheckRequiredIndicatorsView.vue'
                      ),
                  },
                ),
              },
              {
                path: 'grouped-history',
                name: RouteNames.PERSON_REGULATIONS_CHECK_HISTORY_GROUPED,
                component: () =>
                  import(
                    '@/modules/regulationsCheck/person/views/PersonRegulationsChecksHistoryGroupedView.vue'
                  ),
              },
            ),
          },
          {
            path: 'history-group/:groupId',
            name: RouteNames.PERSON_REGULATIONS_CHECK_DETAIL_HISTORY,
            component: () =>
              import(
                '@/modules/regulationsCheck/person/views/PersonRegulationChecksHistoryGroupView.vue'
              ),
          },

          {
            path: 'result/:itemId',
            name: RouteNames.PERSON_REGULATIONS_CHECK_RESULT,
            redirect: { name: RouteNames.PERSON_REGULATIONS_CHECK_RESULT_AUTO_INDICATORS },
            component: () =>
              import(
                '@/modules/regulationsCheck/person/views/PersonRegulationsCheckResultsView.vue'
              ),
            children: new Array<RouteRecordRaw>(
              {
                path: 'auto-indicators',
                name: RouteNames.PERSON_REGULATIONS_CHECK_RESULT_AUTO_INDICATORS,
                component: () =>
                  import(
                    '@/modules/regulationsCheck/person/views/PersonRegulationsChecksAutoIndicatorsView.vue'
                  ),
              },
              {
                path: 'check-required-indicators',
                name: RouteNames.PERSON_REGULATIONS_CHECK_RESULT_CHECK_REQUIRED_INDICATORS,
                component: () =>
                  import(
                    '@/modules/regulationsCheck/person/views/PersonRegulationsChecksCheckRequiredIndicatorsView.vue'
                  ),
              },
            ),
          },
        ),
      },
      {
        path: 'history',
        name: RouteNames.PERSON_HISTORY,
        component: () => import('@/modules/dossier/person/common/views/PersonHistoryView.vue'),
      },
      {
        path: 'social-network-search',
        name: RouteNames.PERSON_SOCIAL_NETWORK_SEARCH,
        component: () =>
          import('@/modules/dossier/person/common/views/PersonSocialNetworkSearchView.vue'),
      },
      {
        path: 'files',
        name: RouteNames.PERSON_FILES_ROOT,
        redirect: {
          name: RouteNames.PERSON_FILES_LIST,
        },
        component: () => import('@/modules/dossier/person/files/views/PersonFilesRootView.vue'),
        children: [
          {
            path: 'list',
            name: RouteNames.PERSON_FILES_LIST,
            component: () => import('@/modules/dossier/person/files/views/PersonFilesListView.vue'),
          },
          {
            path: 'consent-processing',
            name: RouteNames.PERSON_FILES_CONSENT_PROCESSING,
            component: () =>
              import('@/modules/dossier/person/files/views/PersonFilesConsentProcessingView.vue'),
          },
        ],
      },
      {
        path: 'attachments-and-links',
        name: RouteNames.PERSON_ATTACHMENTS_AND_LINKS,
        component: () =>
          import('@/modules/dossier/person/common/views/PersonAttachmentsAndLinksView.vue'),
      },
      {
        path: 'reports',
        name: RouteNames.PERSON_REPORTS,
        component: () => import('@/modules/dossier/person/common/views/PersonReportsView.vue'),
      },
      {
        path: 'cars',
        name: RouteNames.PERSON_CARS_ROOT,
        redirect: { name: RouteNames.PERSON_CARS_ANALYTIC },
        component: () =>
          import('@/modules/dossier/person/vehicles/cars/views/PersonCarsRootView.vue'),
        children: [
          {
            path: 'analytic',
            name: RouteNames.PERSON_CARS_ANALYTIC,
            component: () =>
              import('@/modules/dossier/person/vehicles/cars/views/PersonCarsAnalyticView.vue'),
          },
          {
            path: 'all',
            name: RouteNames.PERSON_CARS_ALL,
            component: () =>
              import('@/modules/dossier/person/vehicles/cars/views/PersonCarsAllView.vue'),
          },
        ],
      },
      {
        path: 'parking',
        name: RouteNames.PERSON_PARKING_ROOT,
        redirect: { name: RouteNames.PERSON_PARKING_ANALYTIC },
        component: () =>
          import('@/modules/dossier/person/vehicles/parking/views/PersonParkingRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'analytic',
            name: RouteNames.PERSON_PARKING_ANALYTIC,
            component: () =>
              import(
                '@/modules/dossier/person/vehicles/parking/views/PersonParkingAnalyticView.vue'
              ),
          },
          {
            path: 'all',
            name: RouteNames.PERSON_PARKING_ALL,
            component: () =>
              import('@/modules/dossier/person/vehicles/parking/views/PersonParkingAllView.vue'),
          },
        ),
      },
      {
        path: 'car-accidents',
        name: RouteNames.PERSON_CAR_ACCIDENT_ROOT,
        redirect: { name: RouteNames.PERSON_CAR_ACCIDENT_ANALYTIC },
        component: () =>
          import('@/modules/dossier/person/roadAccidents/views/PersonRoadAccidentsRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'main',
            name: RouteNames.PERSON_CAR_ACCIDENT_MAIN,
            component: () =>
              import(
                '@/modules/dossier/person/roadAccidents/views/PersonRoadAccidentsMainView.vue'
              ),
            children: new Array<RouteRecordRaw>(
              {
                path: 'analytic',
                name: RouteNames.PERSON_CAR_ACCIDENT_ANALYTIC,
                component: () =>
                  import(
                    '@/modules/dossier/person/roadAccidents/views/PersonRoadAccidentsAnalyticView.vue'
                  ),
              },
              {
                path: 'accident-list',
                name: RouteNames.PERSON_CAR_ACCIDENT_LIST,
                component: () =>
                  import(
                    '@/modules/dossier/person/roadAccidents/views/PersonRoadAccidentListView.vue'
                  ),
              },
            ),
          },
          {
            path: ':accidentVin',
            name: RouteNames.PERSON_CAR_ACCIDENT_VIN,
            component: () =>
              import('@/modules/dossier/person/roadAccidents/views/PersonRoadAccidentsVinView.vue'),
          },
        ),
      },
      {
        path: 'restrictions',
        name: RouteNames.PERSON_RESTRICTIONS,
        component: () =>
          import('@/modules/dossier/person/restrictions/views/PersonRestrictionsView.vue'),
      },
      {
        path: 'pledges-registries',
        name: RouteNames.PERSON_PLEDGES_REGISTRIES_ROOT,
        component: () =>
          import(
            '@/modules/dossier/person/pledgesRegistries/views/PersonPledgesRegistriesRootView.vue'
          ),
        redirect: { name: RouteNames.PERSON_PLEDGES_REGISTRIES_ANALYTICS },
        children: new Array<RouteRecordRaw>(
          {
            path: 'analytics',
            name: RouteNames.PERSON_PLEDGES_REGISTRIES_ANALYTICS,
            component: () =>
              import(
                '@/modules/dossier/person/pledgesRegistries/views/PersonPledgesRegistriesAnalyticsView.vue'
              ),
          },
          {
            path: 'data',
            name: RouteNames.PERSON_PLEDGES_REGISTRIES_LIST,
            component: () =>
              import(
                '@/modules/dossier/person/pledgesRegistries/views/PersonPledgesRegistriesDataView.vue'
              ),
          },
          {
            path: 'scoring',
            name: RouteNames.PERSON_PLEDGES_REGISTRIES_SCORING_LIST,
            component: () =>
              import(
                '@/modules/dossier/person/pledgesRegistries/views/PersonPledgesRegistriesScoringView.vue'
              ),
          },
        ),
      },
      {
        path: 'fines',
        name: RouteNames.PERSON_FINES_ROOT,
        component: () => import('@/modules/dossier/person/fines/views/PersonFinesRootView.vue'),
        redirect: { name: RouteNames.PERSON_FINES_ANALYTICS },
        children: new Array<RouteRecordRaw>(
          {
            path: 'analytics',
            name: RouteNames.PERSON_FINES_ANALYTICS,
            component: () =>
              import('@/modules/dossier/person/fines/views/PersonFinesAnalyticsView.vue'),
          },
          {
            path: 'all-fines',
            name: RouteNames.PERSON_FINES_DATA,
            component: () => import('@/modules/dossier/person/fines/views/PersonFinesDataView.vue'),
          },
        ),
      },
      {
        path: 'car-wanted',
        name: RouteNames.PERSON_CAR_WANTED,
        component: () =>
          import('@/modules/dossier/person/vehicles/wanted/views/PersonCarsWantedView.vue'),
      },
      {
        path: 'av-100',
        name: RouteNames.PERSON_AV100,
        component: () => import('@/modules/dossier/person/parsers/views/PersonAv100View.vue'),
      },
      {
        path: 'contour',
        name: RouteNames.PERSON_CONTOUR,
        component: () => import('@/modules/dossier/person/parsers/views/PersonContourView.vue'),
      },
      {
        path: 'cronos',
        name: RouteNames.PERSON_CRONOS,
        component: () => import('@/modules/dossier/person/parsers/views/PersonCronosView.vue'),
      },
      {
        path: 'mvd',
        name: RouteNames.PERSON_MVD,
        component: () => import('@/modules/dossier/person/parsers/views/PersonMvdView.vue'),
      },
      {
        path: 'fns',
        name: RouteNames.PERSON_FNS,
        component: () => import('@/modules/dossier/person/parsers/views/PersonFnsView.vue'),
      },
      {
        path: 'xneo',
        name: RouteNames.PERSON_XNEO,
        component: () => import('@/modules/dossier/person/parsers/views/PersonXneoView.vue'),
      },
      {
        path: 'event-journal',
        name: RouteNames.PERSON_EVENT_JOURNAL,
        component: () =>
          import('@/modules/dossier/person/eventJournal/views/PersonEventJournalView.vue'),
      },
      {
        path: 'financial-scoring',
        name: RouteNames.PERSON_FINANCIAL_SCORING_ROOT,
        redirect: { name: RouteNames.PERSON_FINANCIAL_SCORING_ANALYTICS },
        component: () =>
          import(
            '@/modules/dossier/person/financialScorings/views/PersonFinancialScoringsRootView.vue'
          ),
        children: new Array<RouteRecordRaw>(
          {
            path: 'analytics',
            name: RouteNames.PERSON_FINANCIAL_SCORING_ANALYTICS,
            component: () =>
              import(
                '@/modules/dossier/person/financialScorings/views/PersonFinancialScoringsAnalyticsView.vue'
              ),
          },
          {
            path: 'scoring-list',
            name: RouteNames.PERSON_FINANCIAL_SCORING_LIST,
            component: () =>
              import(
                '@/modules/dossier/person/financialScorings/views/PersonFinancialScoringsListView.vue'
              ),
          },
        ),
      },
      {
        path: 'disqualifications',
        name: RouteNames.PERSON_DISQUALIFICATION,
        component: () =>
          import('@/modules/dossier/person/common/views/PersonDisqualificationsView.vue'),
      },
      {
        path: 'tax-debt-information',
        name: RouteNames.PERSON_TAX_DEBT_INFORMATION,
        component: () =>
          import(
            '@/modules/dossier/person/taxDebtInformation/views/PersonTaxDebtInformationView.vue'
          ),
      },
      {
        path: 'integrity-scoring',
        name: RouteNames.PERSON_INTEGRITY_SCORING,
        component: () =>
          import(
            '@/modules/dossier/person/personIntegrityScoring/views/PersonIntegrityScoringRootView.vue'
          ),
      },
      {
        path: 'personal-data-consent',
        name: RouteNames.PERSON_PERSONAL_DATA_CONSENT,
        component: () =>
          import('@/modules/dossier/person/common/views/PersonPersonalDataConsentView.vue'),
      },
      {
        path: 'number-initials-connection-check',
        name: RouteNames.PERSON_NUMBER_INITIALS_CONNECTION_CHECK,
        component: () =>
          import(
            '@/modules/dossier/person/common/views/PersonNumberInitialsConnectionCheckView.vue'
          ),
      },
      {
        path: 'phone-number-by-inn',
        name: RouteNames.PERSON_PHONE_NUMBER_BY_INN,
        component: () =>
          import('@/modules/dossier/person/common/views/PersonPhoneNumberByInnView.vue'),
      },
      {
        path: 'check-registry',
        name: RouteNames.PERSON_CHECK_REGISTRY,
        component: () =>
          import(
            '@/modules/dossier/person/terroristRegistryInformation/views/PersonCheckRegistryTerroristView.vue'
          ),
      },
      {
        path: 'driver-licenses',
        name: RouteNames.PERSON_DRIVER_LICENSES_ROOT,
        component: () =>
          import(
            '@/modules/dossier/person/vehicles/driverLicenses/views/PersonDriverLicensesRootView.vue'
          ),
      },
      {
        path: 'pledge-registry-check',
        name: RouteNames.PERSON_CHECK_PLEDGES_REGISTRY,
        component: () =>
          import('@/modules/dossier/person/common/views/PersonPledgeRegistryCheckView.vue'),
      },
      {
        path: 'social-networks-additional-info',
        name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_ROOT,
        redirect: { name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_ROOT },
        component: () =>
          import(
            '@/modules/dossier/person/socialNetworkAdditionalInfo/views/PersonSocialNetworkAdditionalInfoRootView.vue'
          ),
        children: new Array<RouteRecordRaw>(
          {
            path: 'vk',
            name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_ROOT,
            redirect: { name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_ACCOUNTS_LIST },
            component: () =>
              import(
                '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/views/PersonSocialNetworkAdditionalInfoVkRootView.vue'
              ),
            children: new Array<RouteRecordRaw>(
              {
                path: 'accounts',
                name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_ACCOUNTS_LIST,
                component: () =>
                  import(
                    '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/views/PersonSocialNetworkAdditionalInfoVKAccountsList.vue'
                  ),
              },
              {
                path: ':vkId',
                name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_ACCOUNT_ROOT,
                redirect: { name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_MAIN_INFO },
                component: () =>
                  import(
                    '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/views/PersonSocialNetworkAdditionalInfoVKAccountRootView.vue'
                  ),
                children: new Array<RouteRecordRaw>(
                  {
                    path: 'main-info',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_MAIN_INFO,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/views/PersonSocialNetworkAdditionalInfoVkMainInfoView.vue'
                      ),
                  },
                  {
                    path: 'relatives',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_RELATIVES,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/views/PersonSocialNetworkAdditionalInfoVkRelativesView.vue'
                      ),
                  },
                  {
                    path: 'friends',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_FRIENDS,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/views/PersonSocialNetworkAdditionalInfoVkFriendsView.vue'
                      ),
                  },
                  {
                    path: 'likers',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_LIKERS,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/views/PersonSocialNetworkAdditionalInfoVkLikersView.vue'
                      ),
                  },
                  {
                    path: 'commentators',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_COMMENTATORS,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/views/PersonSocialNetworkAdditionalInfoVkCommentatorsView.vue'
                      ),
                  },
                  {
                    path: 'marks-photo',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_MARKS_PHOTO,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/views/PersonSocialNetworkAdditionalInfoVkMarksPhotoView.vue'
                      ),
                  },
                  {
                    path: 'geography-of-connections',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_GEOGRAPHY_OF_CONNECTION,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/views/PersonSocialNetworkAdditionalInfoVkGeographyOfConnectionView.vue'
                      ),
                  },
                  {
                    path: 'gallery',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_VK_GALLERY,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/views/PersonSocialNetworkAdditionalInfoVkGalleryView.vue'
                      ),
                  },
                ),
              },
            ),
          },
          {
            path: 'ok',
            name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_ROOT,
            redirect: { name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_ACCOUNTS_LIST },
            component: () =>
              import(
                '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/views/PersonSocialNetworkAdditionalInfoOkRootView.vue'
              ),
            children: new Array<RouteRecordRaw>(
              {
                path: 'accounts',
                name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_ACCOUNTS_LIST,
                component: () =>
                  import(
                    '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/views/PersonSocialNetworkAdditionalInfoOkAccountsList.vue'
                  ),
              },
              {
                path: ':okId',
                name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_ACCOUNT_ROOT,
                redirect: { name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_MAIN_INFO },
                component: () =>
                  import(
                    '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/views/PersonSocialNetworkAdditionalInfoOkAccountRootView.vue'
                  ),
                children: new Array<RouteRecordRaw>(
                  {
                    path: 'main-info',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_MAIN_INFO,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/views/PersonSocialNetworkAdditionalInfoOkMainInfoView.vue'
                      ),
                  },
                  {
                    path: 'gallery',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_GALLERY,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/views/PersonSocialNetworkAdditionalInfoOkGalleryView.vue'
                      ),
                  },
                  {
                    path: 'relatives',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_RELATIVES,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/views/PersonSocialNetworkAdditionalInfoOkRelativesView.vue'
                      ),
                  },
                  {
                    path: 'friends',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_FRIENDS,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/views/PersonSocialNetworkAdditionalInfoOkFriendsView.vue'
                      ),
                  },
                  {
                    path: 'likers',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_LIKERS,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/views/PersonSocialNetworkAdditionalInfoOkLikersView.vue'
                      ),
                  },
                  {
                    path: 'commentators',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_COMMENTATORS,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/views/PersonSocialNetworkAdditionalInfoOkCommentatorsView.vue'
                      ),
                  },
                  {
                    path: 'marks-photo',
                    name: RouteNames.PERSON_ADDITIONAL_SOCIAL_NETWORK_INFO_OK_MARKS_PHOTO,
                    component: () =>
                      import(
                        '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/views/PersonSocialNetworkAdditionalInfoOkMarksPhotoView.vue'
                      ),
                  },
                ),
              },
            ),
          },
        ),
      },
    ),
  },
  {
    path: '/company/:companyId/',
    name: RouteNames.COMPANY,
    component: () => import('@/modules/dossier/company/main/views/CompanyDataRootView.vue'),
    redirect: { name: RouteNames.COMPANY_COMMON },
    props: (route: RouteLocationNormalizedLoaded) => ({ companyId: route.params.companyId }),
    meta: { accessScopes: [AccessScopeEnum.hasAccessToCompany] },
    children: new Array<RouteRecordRaw>(
      {
        path: ' ',
        name: RouteNames.COMPANY_COMMON,
        props: true,
        component: () => import('@/modules/dossier/company/main/views/CompanyCommonDataView.vue'),
      },
      {
        path: 'connections',
        name: RouteNames.COMPANY_CONNECTIONS,
        component: () =>
          import('@/modules/dossier/company/common/views/CompanyConnectionsView.vue'),
      },
      {
        path: 'leaders',
        name: RouteNames.COMPANY_LEADERS,
        component: () => import('@/modules/dossier/company/heads/views/CompanyHeadsView.vue'),
      },
      {
        path: 'beneficiaries',
        name: RouteNames.COMPANY_BENEFICIARIES,
        redirect: { name: RouteNames.COMPANY_BENEFICIARIES_FOUNDERS },
        component: () =>
          import('@/modules/dossier/company/founders/views/CompanyBeneficiariesRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'founders',
            name: RouteNames.COMPANY_BENEFICIARIES_FOUNDERS,
            component: () =>
              import('@/modules/dossier/company/common/views/CompanyBeneficiariesFoundersView.vue'),
          },
          {
            path: 'shareholders',
            name: RouteNames.COMPANY_BENEFICIARIES_SHAREHOLDERS,
            component: () =>
              import(
                '@/modules/dossier/company/common/views/CompanyBeneficiariesShareholdersView.vue'
              ),
          },
        ),
      },
      {
        path: 'vehicles',
        name: RouteNames.COMPANY_VEHICLES,
        redirect: { name: RouteNames.COMPANY_VEHICLES_ANALYTICS },
        component: () => import('@/modules/dossier/company/vehicles/views/CompanyVehiclesView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'analytics',
            name: RouteNames.COMPANY_VEHICLES_ANALYTICS,
            component: () =>
              import('@/modules/dossier/company/vehicles/views/CompanyVehiclesAnalyticsView.vue'),
          },
          {
            path: 'data',
            name: RouteNames.COMPANY_VEHICLES_DATA,
            component: () =>
              import('@/modules/dossier/company/vehicles/views/CompanyVehiclesDataView.vue'),
          },
          {
            path: 'penalty',
            name: RouteNames.COMPANY_VEHICLES_PENALTY,
            component: () =>
              import('@/modules/dossier/company/vehicles/views/CompanyVehiclesFinesView.vue'),
          },
          {
            path: 'parking',
            name: RouteNames.COMPANY_VEHICLES_PARKING,
            component: () =>
              import('@/modules/dossier/company/vehicles/views/CompanyVehiclesParkingView.vue'),
          },
        ),
      },
      {
        path: 'activities',
        name: RouteNames.COMPANY_ACTIVITIES,
        component: () => import('@/components/CompanyActivitiesData'),
      },
      {
        path: 'capital',
        name: RouteNames.COMPANY_CAPITAL,
        component: () =>
          import('@/modules/dossier/company/capital/views/CompanySharedCapitalView.vue'),
      },
      {
        path: 'branches',
        name: RouteNames.COMPANY_BRANCHES,
        redirect: { name: RouteNames.COMPANY_BRANCHES_STATISTIC },
        component: () =>
          import('@/modules/dossier/company/common/views/CompanyBranchesRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'statistic',
            name: RouteNames.COMPANY_BRANCHES_STATISTIC,
            component: () =>
              import('@/modules/dossier/company/common/views/CompanyBranchesStatisticView.vue'),
          },
          {
            path: 'data',
            name: RouteNames.COMPANY_BRANCHES_DATA,
            component: () =>
              import('@/modules/dossier/company/common/views/CompanyBranchesDataView.vue'),
          },
        ),
      },
      {
        path: 'licenses',
        name: RouteNames.COMPANY_LICENSES,
        component: () => import('@/modules/dossier/company/common/views/CompanyLicensesView.vue'),
      },
      {
        path: 'leasing',
        name: RouteNames.COMPANY_LEASING,
        redirect: { name: RouteNames.COMPANY_LEASING_STATISTICS_ROOT },
        component: () =>
          import('@/modules/dossier/company/leasing/views/CompanyLeasingRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'statistics',
            name: RouteNames.COMPANY_LEASING_STATISTICS_ROOT,
            component: () =>
              import(
                '@/modules/dossier/company/leasing/views/CompanyLeasingStatisticsRootView.vue'
              ),
            redirect: { name: RouteNames.COMPANY_LEASING_STATISTICS_SUBJECTS },
            children: new Array<RouteRecordRaw>(
              {
                path: 'subjects',
                name: RouteNames.COMPANY_LEASING_STATISTICS_SUBJECTS,
                component: () =>
                  import(
                    '@/modules/dossier/company/leasing/views/CompanyLeasingStatisticsSubjectsView.vue'
                  ),
              },
              {
                path: 'lessors',
                name: RouteNames.COMPANY_LEASING_STATISTICS_LESSORS,
                component: () =>
                  import(
                    '@/modules/dossier/company/leasing/views/CompanyLeasingStatisticsLessorsView.vue'
                  ),
              },
            ),
          },
          {
            path: 'contracts',
            name: RouteNames.COMPANY_LEASING_CONTRACTS,
            component: () =>
              import('@/modules/dossier/company/leasing/views/CompanyLeasingContractsView.vue'),
          },
        ),
      },
      {
        path: 'pledges',
        name: RouteNames.COMPANY_PLEDGES,
        component: () =>
          import('@/modules/dossier/company/pledges/views/CompanyPledgesRootView.vue'),
        redirect: { name: RouteNames.COMPANY_PLEDGES_STATISTICS },
        children: new Array<RouteRecordRaw>(
          {
            path: 'pledges-statistics',
            name: RouteNames.COMPANY_PLEDGES_STATISTICS,
            component: () =>
              import('@/modules/dossier/company/pledges/views/CompanyPledgesStatisticsView.vue'),
          },
          {
            path: 'pledges-list',
            name: RouteNames.COMPANY_PLEDGES_LIST,
            component: () =>
              import('@/modules/dossier/company/pledges/views/CompanyPledgesListView.vue'),
          },
        ),
      },
      {
        path: 'certificates',
        name: RouteNames.COMPANY_CERTIFICATES_ROOT,
        redirect: { name: RouteNames.COMPANY_CERTIFICATES_ANALYTIC },
        component: () =>
          import('@/modules/dossier/company/common/views/CompanyCertificatesRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'analytic',
            name: RouteNames.COMPANY_CERTIFICATES_ANALYTIC,
            component: () =>
              import('@/modules/dossier/company/common/views/CompanyCertificatesAnalyticsView.vue'),
          },
          {
            path: 'certificates-list',
            name: RouteNames.COMPANY_CERTIFICATES_LIST,
            component: () =>
              import('@/modules/dossier/company/common/views/CompanyCertificatesListView.vue'),
          },
        ),
      },
      {
        path: 'trademarks',
        name: RouteNames.COMPANY_TRADEMARKS,
        component: () =>
          import('@/modules/dossier/company/trademark/views/CompanyTrademarksView.vue'),
      },
      {
        path: 'purchases',
        name: RouteNames.COMPANY_PURCHASES,
        redirect: { name: RouteNames.COMPANY_PURCHASES_STATISTICS },
        component: () =>
          import('@/modules/dossier/company/common/views/CompanyPurchasesRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'statistics',
            name: RouteNames.COMPANY_PURCHASES_STATISTICS,
            redirect: {
              name: RouteNames.COMPANY_PURCHASES_STATISTICS_MAJOR_SUPPLIERS_AND_CUSTOMERS,
            },
            component: () =>
              import('@/modules/dossier/company/common/views/CompanyPurchasesStatisticsView.vue'),
            children: new Array<RouteRecordRaw>(
              {
                path: 'major-suppliers-and-customers',
                name: RouteNames.COMPANY_PURCHASES_STATISTICS_MAJOR_SUPPLIERS_AND_CUSTOMERS,
                component: () =>
                  import(
                    '@/modules/dossier/company/common/components/CompanyPurchasesStatisticsMajorSuppliersAndCustomersTable.vue'
                  ),
              },
              {
                path: 'most-common-product',
                name: RouteNames.COMPANY_PURCHASES_STATISTICS_MOST_COMMON_PRODUCT,
                component: () =>
                  import(
                    '@/modules/dossier/company/common/components/CompanyPurchasesStatisticsMostCommonProductTable.vue'
                  ),
              },
              {
                path: 'execution-statuses',
                name: RouteNames.COMPANY_PURCHASES_STATISTICS_EXECUTION_STATUSES,
                component: () =>
                  import(
                    '@/modules/dossier/company/common/components/CompanyPurchasesStatisticsExecutionStatusesTable.vue'
                  ),
              },
            ),
          },
          {
            path: 'purchase-data',
            name: RouteNames.COMPANY_PURCHASES_PURCHASE_DATA,
            component: () =>
              import('@/modules/dossier/company/common/views/CompanyPurchasesPurchaseDataView.vue'),
          },
        ),
      },
      {
        path: 'SROParticipation',
        name: RouteNames.COMPANY_SRO_PARTICIPATION,
        redirect: { name: RouteNames.COMPANY_SRO_PARTICIPATION_DATA },
        component: () =>
          import('@/modules/dossier/company/sro/views/CompanySROParticipationView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'founders',
            name: RouteNames.COMPANY_SRO_PARTICIPATION_ANALYTICS,
            component: () =>
              import(
                '@/modules/dossier/company/sro/views/CompanySROParticipationAnalyticsView.vue'
              ),
          },
          {
            path: 'shareholders',
            name: RouteNames.COMPANY_SRO_PARTICIPATION_DATA,
            component: () =>
              import('@/modules/dossier/company/sro/views/CompanySROParticipationDataView.vue'),
          },
        ),
      },
      {
        path: 'activity',
        name: RouteNames.COMPANY_ACTIVITIES_ROOT,
        redirect: { name: RouteNames.COMPANY_ACTIVITIES_ANALYTICS },
        component: () =>
          import('@/modules/dossier/company/activities/views/CompanyActivitiesView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'analytics',
            name: RouteNames.COMPANY_ACTIVITIES_ANALYTICS,
            component: () =>
              import(
                '@/modules/dossier/company/activities/views/CompanyActivitiesAnalyticsView.vue'
              ),
          },
          {
            path: 'all-activities',
            name: RouteNames.COMPANY_ACTIVITIES_DATA,
            component: () =>
              import('@/modules/dossier/company/activities/views/CompanyActivitiesDataView.vue'),
          },
        ),
      },
      {
        path: 'arbitration',
        name: RouteNames.COMPANY_ARBITRATION_ROOT,
        redirect: { name: RouteNames.COMPANY_ARBITRATION_STATISTIC },
        component: () =>
          import('@/modules/dossier/company/arbitrations/views/CompanyArbitrationRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'statistic',
            name: RouteNames.COMPANY_ARBITRATION_STATISTIC,
            redirect: { name: RouteNames.COMPANY_ARBITRATION_STATISTIC_RISKS_PLAINTIFF },
            component: () =>
              import(
                '@/modules/dossier/company/arbitrations/views/CompanyArbitrationStatisticView.vue'
              ),
            children: new Array<RouteRecordRaw>(
              {
                path: 'risks-defendant',
                name: RouteNames.COMPANY_ARBITRATION_STATISTIC_RISKS_DEFENDANT,
                component: () =>
                  import(
                    '@/modules/dossier/company/arbitrations/views/CompanyArbitrationStatisticRisksDefendantView.vue'
                  ),
              },
              {
                path: 'risks-plaintiff',
                name: RouteNames.COMPANY_ARBITRATION_STATISTIC_RISKS_PLAINTIFF,
                component: () =>
                  import(
                    '@/modules/dossier/company/arbitrations/views/CompanyArbitrationStatisticRisksPlaintiffView.vue'
                  ),
              },
            ),
          },

          {
            path: 'companies',
            name: RouteNames.COMPANY_ARBITRATION_COMPANIES,
            component: () =>
              import(
                '@/modules/dossier/company/arbitrations/views/CompanyArbitrationCompaniesView.vue'
              ),
          },
          {
            path: 'list',
            name: RouteNames.COMPANY_ARBITRATION_LIST,
            component: () =>
              import('@/modules/dossier/company/arbitrations/views/CompanyArbitrationListView.vue'),
          },
        ),
      },
      {
        path: 'inspections',
        name: RouteNames.COMPANY_INSPECTIONS_ROOT,
        redirect: { name: RouteNames.COMPANY_INSPECTIONS_ANALYTIC_ROOT },
        component: () =>
          import('@/modules/dossier/company/common/views/CompanyInspectionsRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'data',
            name: RouteNames.COMPANY_INSPECTIONS_DATA,
            component: () =>
              import('@/modules/dossier/company/common/views/CompanyInspectionsDataView.vue'),
          },
          {
            path: 'analytic',
            name: RouteNames.COMPANY_INSPECTIONS_ANALYTIC_ROOT,
            redirect: { name: RouteNames.COMPANY_INSPECTIONS_ANALYTIC_TYPE },
            component: () =>
              import(
                '@/modules/dossier/company/common/views/CompanyInspectionsAnalyticRootView.vue'
              ),
            children: new Array<RouteRecordRaw>(
              {
                path: 'type',
                name: RouteNames.COMPANY_INSPECTIONS_ANALYTIC_TYPE,
                component: () =>
                  import(
                    '@/modules/dossier/company/common/views/CompanyInspectionsAnalyticByTypeView.vue'
                  ),
              },
              {
                path: 'by-date',
                name: RouteNames.COMPANY_INSPECTIONS_ANALYTIC_BY_DATE,
                component: () =>
                  import(
                    '@/modules/dossier/company/common/views/CompanyInspectionsAnalyticByDateView.vue'
                  ),
              },
            ),
          },
        ),
      },
      {
        path: 'enforcementProceedings',
        name: RouteNames.COMPANY_ENFORCEMENT_PROCEEDINGS_ROOT,
        redirect: { name: RouteNames.COMPANY_ENFORCEMENT_PROCEEDINGS_STATISTICS },
        component: () =>
          import(
            '@/modules/dossier/company/enforcementProceedings/views/CompanyEnforcementProceedingsRootView.vue'
          ),
        children: new Array<RouteRecordRaw>(
          {
            path: 'statistics',
            name: RouteNames.COMPANY_ENFORCEMENT_PROCEEDINGS_STATISTICS,
            component: () =>
              import(
                '@/modules/dossier/company/enforcementProceedings/views/CompanyEnforcementProceedingsStatisticsView.vue'
              ),
          },
          {
            path: 'data',
            name: RouteNames.COMPANY_ENFORCEMENT_PROCEEDINGS_DATA,
            component: () =>
              import(
                '@/modules/dossier/company/enforcementProceedings/views/CompanyEnforcementProceedingsDataView.vue'
              ),
          },
        ),
      },
      {
        path: 'production',
        name: RouteNames.COMPANY_PRODUCTION,
        component: () => import('@/components/CompanyProductionData'),
      },
      {
        path: 'condition',
        name: RouteNames.COMPANY_CONDITION,
        props: true,
        component: () => import('@/components/CompanyFinance'),
      },
      {
        path: 'markers',
        name: RouteNames.COMPANY_MARKERS,
        props: true,
        component: () => import('@/components/CompanyMarkers'),
      },
      {
        path: 'tax-authority',
        name: RouteNames.COMPANY_TAX_INFORMATION,
        component: () =>
          import('@/modules/dossier/company/taxes/views/CompanyTaxAuthorityView.vue'),
      },
      {
        path: 'social-insurance-funds',
        name: RouteNames.COMPANY_SOCIAL_INSURANCE_FUNDS,
        component: () =>
          import('@/modules/dossier/company/funds/views/CompanySocialInsuranceFundsView.vue'),
      },
      {
        path: 'pension-funds',
        name: RouteNames.COMPANY_PENSION_INSURANCE_FUNDS,
        component: () =>
          import('@/modules/dossier/company/funds/views/CompanyPensionFundInfoView.vue'),
      },
      {
        path: 'medicine-funds',
        name: RouteNames.COMPANY_MEDICINE_INSURANCE_FUNDS,
        component: () =>
          import('@/modules/dossier/company/funds/views/CompanyMedicineInsuranceFundView.vue'),
      },
      {
        path: 'egr-records',
        name: RouteNames.COMPANY_EGR_RECORDS,
        component: () =>
          import('@/modules/dossier/company/egrRecords/views/CompanyEgrRecordsView.vue'),
      },
      {
        path: 'name-list',
        name: RouteNames.COMPANY_NAME_LIST,
        props: true,
        component: () => import('@/modules/dossier/company/common/views/CompanyNameListView.vue'),
      },
      {
        path: 'participation',
        name: RouteNames.COMPANY_PARTICIPATION_MANAGEMENT,
        props: true,
        component: () =>
          import('@/modules/dossier/company/participations/views/CompanyParticipationView.vue'),
      },
      {
        path: 'relations',
        name: RouteNames.COMPANY_RELATIONS,
        props: true,
        component: () =>
          import('@/modules/dossier/company/relations/views/CompanyRelationsView.vue'),
      },
      {
        path: 'counterparties-relations',
        name: RouteNames.COMPANY_COUNTERPARTY_RELATIONS,
        props: true,
        component: () =>
          import(
            '@/modules/dossier/relationsGraph/company/views/CompanyCounterpartyRelationsView.vue'
          ),
      },
      {
        path: 'black-list-relations',
        name: RouteNames.COMPANY_BLACK_LIST_RELATIONS,
        props: true,
        component: () =>
          import(
            '@/modules/dossier/relationsGraph/company/views/CompanyBlackListRelationsView.vue'
          ),
      },
      {
        path: 'competitor-relations',
        name: RouteNames.COMPANY_COMPETITOR_RELATIONS,
        props: true,
        component: () =>
          import(
            '@/modules/dossier/relationsGraph/company/views/CompanyCompetitorRelationsView.vue'
          ),
      },
      {
        path: 'employee-relations',
        name: RouteNames.COMPANY_EMPLOYEE_RELATIONS,
        props: true,
        component: () =>
          import('@/modules/dossier/relationsGraph/company/views/CompanyEmployeeRelationsView.vue'),
      },
      {
        path: 'accounting-old',
        name: RouteNames.COMPANY_ACCOUNTING_OLD,
        component: () => import('@/modules/dossier/company/common/views/CompanyReportingView.vue'),
      },
      {
        path: 'accounting',
        name: RouteNames.COMPANY_ACCOUNTING_ROOT,
        redirect: { name: RouteNames.COMPANY_ACCOUNTING_BALANCE },
        component: () => import('@/modules/dossier/company/accounting/views/RootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'balance',
            name: RouteNames.COMPANY_ACCOUNTING_BALANCE,
            component: () => import('@/modules/dossier/company/accounting/views/BalanceView.vue'),
          },
          {
            path: 'reports',
            name: RouteNames.COMPANY_ACCOUNTING_REPORTS_ROOT,
            redirect: { name: RouteNames.COMPANY_ACCOUNTING_REPORTS_RESULTS },
            component: () =>
              import('@/modules/dossier/company/accounting/views/ReportsRootView.vue'),
            children: new Array<RouteRecordRaw>(
              {
                path: 'results',
                name: RouteNames.COMPANY_ACCOUNTING_REPORTS_RESULTS,
                component: () =>
                  import('@/modules/dossier/company/accounting/views/ReportsResultsView.vue'),
              },
              {
                path: 'usage',
                name: RouteNames.COMPANY_ACCOUNTING_REPORTS_USAGE,
                component: () =>
                  import('@/modules/dossier/company/accounting/views/ReportsUsageView.vue'),
              },
              {
                path: 'changes',
                name: RouteNames.COMPANY_ACCOUNTING_REPORTS_CHANGES,
                component: () =>
                  import('@/modules/dossier/company/accounting/views/ReportsChangesView.vue'),
              },
              {
                path: 'movements',
                name: RouteNames.COMPANY_ACCOUNTING_REPORTS_MOVEMENTS,
                component: () =>
                  import('@/modules/dossier/company/accounting/views/ReportsMovementsView.vue'),
              },
            ),
          },
        ),
      },
      {
        path: 'company-finance-analysis',
        name: RouteNames.COMPANY_FINANCE_ANALYSIS,
        redirect: { name: RouteNames.COMPANY_FINANCE_ANALYSIS_ANALYTIC },
        component: () =>
          import(
            '@/modules/dossier/company/financeAnalysis/views/CompanyFinanceAnalysisRootView.vue'
          ),
        children: new Array<RouteRecordRaw>(
          {
            path: 'analytic',
            name: RouteNames.COMPANY_FINANCE_ANALYSIS_ANALYTIC,
            redirect: { name: RouteNames.COMPANY_FINANCE_ANALYSIS_ANALYTIC_REVENUE },
            component: () =>
              import(
                '@/modules/dossier/company/financeAnalysis/views/CompanyFinanceAnalysisAnalyticView.vue'
              ),
            children: new Array<RouteRecordRaw>(
              {
                path: 'revenue',
                name: RouteNames.COMPANY_FINANCE_ANALYSIS_ANALYTIC_REVENUE,
                component: () =>
                  import(
                    '@/modules/dossier/company/financeAnalysis/views/CompanyFinanceAnalyticRevenueDynamicsView.vue'
                  ),
              },
              {
                path: 'liability',
                name: RouteNames.COMPANY_FINANCE_ANALYSIS_ANALYTIC_LIABILITY,
                component: () =>
                  import(
                    '@/modules/dossier/company/financeAnalysis/views/CompanyFinanceAnalyticLiabilityStructureView.vue'
                  ),
              },
            ),
          },
          {
            path: 'short-analysis',
            name: RouteNames.COMPANY_FINANCE_ANALYSIS_SHORT,
            component: () =>
              import(
                '@/modules/dossier/company/financeAnalysis/views/CompanyFinanceAnalysisShortView.vue'
              ),
          },
          {
            path: 'advanced-analysis',
            name: RouteNames.COMPANY_FINANCE_ANALYSIS_ADVANCED,
            component: () =>
              import(
                '@/modules/dossier/company/financeAnalysis/views/CompanyFinanceAnalysisExtendedView.vue'
              ),
          },
        ),
      },
      {
        path: 'company-event-journal',
        name: RouteNames.COMPANY_JOURNAL,
        component: () =>
          import('@/modules/dossier/company/eventJournal/views/CompanyEventJournalView.vue'),
      },
      {
        path: 'company-bank-guarantees',
        name: RouteNames.COMPANY_BANK_GUARANTEES,
        redirect: { name: RouteNames.COMPANY_BANK_GUARANTEES_ANALYTICS },
        component: () =>
          import('@/modules/dossier/company/common/views/CompanyBankGuaranteesRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'analytics',
            name: RouteNames.COMPANY_BANK_GUARANTEES_ANALYTICS,
            component: () =>
              import(
                '@/modules/dossier/company/common/views/CompanyBankGuaranteesAnalyticsView.vue'
              ),
          },
          {
            path: 'all-guarantees',
            name: RouteNames.COMPANY_BANK_GUARANTEES_ALL,
            component: () =>
              import(
                '@/modules/dossier/company/common/views/CompanyBankGuaranteesAllGuaranteesView.vue'
              ),
          },
        ),
      },
      {
        path: 'company-bank-accounts',
        name: RouteNames.COMPANY_BANK_ACCOUNTS,
        redirect: { name: RouteNames.COMPANY_BANK_ACCOUNTS_ALL },
        component: () =>
          import('@/modules/dossier/company/common/views/CompanyBankAccountsRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'bank-accounts',
            name: RouteNames.COMPANY_BANK_ACCOUNTS_ALL,
            component: () =>
              import(
                '@/modules/dossier/company/common/views/CompanyBankAccountsAllAccountsView.vue'
              ),
          },
          {
            path: 'blocked-accounts',
            name: RouteNames.COMPANY_BANK_ACCOUNTS_BLOCKED,
            component: () =>
              import(
                '@/modules/dossier/company/common/views/CompanyBankAccountsLockedAccountsView.vue'
              ),
          },
        ),
      },
      {
        path: 'company-taxes',
        name: RouteNames.COMPANY_TAXES,
        redirect: { name: RouteNames.COMPANY_TAXES_INDUSTRY_INDICATORS },
        component: () => import('@/modules/dossier/company/common/views/CompanyTaxesRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'industry-indicators',
            name: RouteNames.COMPANY_TAXES_INDUSTRY_INDICATORS,
            component: () =>
              import('@/modules/dossier/company/common/views/CompanyIndustryIndicatorsView.vue'),
          },
          {
            path: 'paid-taxes',
            name: RouteNames.COMPANY_TAXES_PAID_TAXES,
            component: () =>
              import('@/modules/dossier/company/common/views/CompanyPaidTaxesView.vue'),
          },
        ),
      },
      {
        path: 'regulations-check',
        name: RouteNames.COMPANY_REGULATIONS_CHECK_ROOT,
        redirect: { name: RouteNames.COMPANY_REGULATIONS_CHECK_INIT },
        component: () =>
          import('@/modules/regulationsCheck/company/views/CompanyRegulationCheckRootView.vue'),
        children: new Array<RouteRecordRaw>(
          {
            path: 'init',
            name: RouteNames.COMPANY_REGULATIONS_CHECK_INIT,
            redirect: { name: RouteNames.COMPANY_REGULATIONS_CHECK_ACTUAL_INFO },
            component: () =>
              import(
                '@/modules/regulationsCheck/company/views/CompanyRegulationsChecksInitView.vue'
              ),
            children: new Array<RouteRecordRaw>(
              {
                path: 'actual-info',
                name: RouteNames.COMPANY_REGULATIONS_CHECK_ACTUAL_INFO,
                component: () =>
                  import(
                    '@/modules/regulationsCheck/company/views/CompanyRegulationsChecksActualInfo.vue'
                  ),
                redirect: { name: RouteNames.COMPANY_REGULATIONS_CHECK_AUTO_INDICATORS },
                children: new Array<RouteRecordRaw>(
                  {
                    path: 'auto-indicators',
                    name: RouteNames.COMPANY_REGULATIONS_CHECK_AUTO_INDICATORS,
                    component: () =>
                      import(
                        '@/modules/regulationsCheck/company/views/CompanyRegulationsChecksAutoIndicatorsView.vue'
                      ),
                  },
                  {
                    path: 'check-required-indicators',
                    name: RouteNames.COMPANY_REGULATIONS_CHECK_CHECK_REQUIRED_INDICATORS,
                    component: () =>
                      import(
                        '@/modules/regulationsCheck/company/views/CompanyRegulationsChecksCheckRequiredIndicatorsView.vue'
                      ),
                  },
                ),
              },
              {
                path: 'grouped-history',
                name: RouteNames.COMPANY_REGULATIONS_CHECK_HISTORY_GROUPED,
                component: () =>
                  import(
                    '@/modules/regulationsCheck/company/views/CompanyRegulationsChecksHistoryGroupedView.vue'
                  ),
              },
            ),
          },
          {
            path: 'history-group/:groupId',
            name: RouteNames.COMPANY_REGULATIONS_CHECK_DETAIL_HISTORY,
            component: () =>
              import(
                '@/modules/regulationsCheck/company/views/CompanyRegulationChecksHistoryGroupView.vue'
              ),
          },

          {
            path: 'result/:itemId',
            name: RouteNames.COMPANY_REGULATIONS_CHECK_RESULT,
            redirect: { name: RouteNames.COMPANY_REGULATIONS_CHECK_RESULT_AUTO_INDICATORS },
            component: () =>
              import(
                '@/modules/regulationsCheck/company/views/CompanyRegulationsCheckResultsView.vue'
              ),
            children: new Array<RouteRecordRaw>(
              {
                path: 'auto-indicators',
                name: RouteNames.COMPANY_REGULATIONS_CHECK_RESULT_AUTO_INDICATORS,
                component: () =>
                  import(
                    '@/modules/regulationsCheck/company/views/CompanyRegulationsChecksAutoIndicatorsView.vue'
                  ),
              },
              {
                path: 'check-required-indicators',
                name: RouteNames.COMPANY_REGULATIONS_CHECK_RESULT_CHECK_REQUIRED_INDICATORS,
                component: () =>
                  import(
                    '@/modules/regulationsCheck/company/views/CompanyRegulationsChecksCheckRequiredIndicatorsView.vue'
                  ),
              },
            ),
          },
        ),
      },
      {
        path: 'files',
        name: RouteNames.COMPANY_FILES_ROOT,
        component: () => import('@/modules/dossier/company/files/views/CompanyFilesView.vue'),
      },
      {
        path: 'attachments-and-links',
        name: RouteNames.COMPANY_ATTACHMENTS_AND_LINKS,
        component: () =>
          import(
            '@/modules/dossier/company/attachmentsAndLinks/views/CompanyAttachmentsAndLinksView.vue'
          ),
      },
      {
        path: 'address',
        name: RouteNames.COMPANY_ADDRESSES,
        component: () => import('@/modules/dossier/company/address/view/CompanyAddressView.vue'),
      },
      {
        path: 'contacts',
        name: RouteNames.COMPANY_CONTACTS_ROOT,
        redirect: {
          name: RouteNames.COMPANY_CONTACTS_PHONES,
        },
        component: () => import('@/modules/dossier/company/contacts/views/CompanyContactsView.vue'),
        children: [
          {
            path: 'phones',
            name: RouteNames.COMPANY_CONTACTS_PHONES,
            component: () =>
              import('@/modules/dossier/company/contacts/views/CompanyContactsPhonesView.vue'),
          },
          {
            path: 'mails',
            name: RouteNames.COMPANY_CONTACTS_MAILS,
            component: () =>
              import('@/modules/dossier/company/contacts/views/CompanyContactsMailView.vue'),
          },
        ],
      },
      {
        path: 'sites',
        name: RouteNames.COMPANY_SITES,
        component: () => import('@/modules/dossier/company/websites/view/CompanyWebsitesView.vue'),
      },
      {
        path: 'socials',
        name: RouteNames.COMPANY_SOCIALS,
        component: () =>
          import('@/modules/dossier/company/socials/view/CompanySocialNetworksView.vue'),
      },
      {
        path: 'mentions',
        name: RouteNames.COMPANY_MENTIONS,
        component: () => import('@/modules/dossier/company/mentions/views/CompanyMentionsView.vue'),
      },
    ),
  },
  {
    path: '/import',
    name: RouteNames.IMPORT,
    component: () => import('@/views/import/ImportRootView.vue'),
    redirect: { name: RouteNames.IMPORT_PERSON_MANUAL_V2 },
    children: new Array<RouteRecordRaw>(
      {
        path: 'person',
        name: RouteNames.IMPORT_PERSON_V2,
        component: () => import('@/views/import/v2/ImportPersonRootViewV2.vue'),
        redirect: { name: RouteNames.IMPORT_PERSON_MANUAL_V2 },
        children: new Array<RouteRecordRaw>(
          {
            path: 'manual',
            name: RouteNames.IMPORT_PERSON_MANUAL_V2,
            component: () => import('@/modules/import/views/ImportPersonManualViewV2.vue'),
          },
          {
            path: 'mass',
            name: RouteNames.IMPORT_PERSON_MASS_V2,
            component: () =>
              import(
                '@/modules/import/modules/massImport/modules/person/views/MassImportPersonView.vue'
              ),
          },
          {
            path: 'file',
            name: RouteNames.IMPORT_PERSON_FILE_V2,
            component: () => import('@/views/import/v2/ImportPersonFileViewV2.vue'),
          },
        ),
      },
      {
        path: 'company',
        name: RouteNames.IMPORT_COMPANY_V2,
        component: () => import('@/views/import/v2/ImportCompanyRootViewV2.vue'),
        redirect: { name: RouteNames.IMPORT_COMPANY_MANUAL_V2 },
        children: new Array<RouteRecordRaw>(
          {
            path: 'manual',
            name: RouteNames.IMPORT_COMPANY_MANUAL_V2,
            component: () => import('@/modules/import/views/ImportCompanyManualViewV2.vue'),
          },
          {
            path: 'mass',
            name: RouteNames.IMPORT_COMPANY_MASS_V2,
            component: () =>
              import(
                '@/modules/import/modules/massImport/modules/company/views/MassImportCompanyView.vue'
              ),
          },
          {
            path: 'file',
            name: RouteNames.IMPORT_COMPANY_FILE_V2,
            component: () => import('@/views/import/v2/ImportCompanyFileViewV2.vue'),
          },
        ),
      },
      {
        path: 'zup',
        name: RouteNames.IMPORT_ZUP_V2,
        component: () => import('@/modules/import/views/ImportZupViewV2.vue'),
      },
      {
        path: 'onec',
        name: RouteNames.IMPORT_ONEC,
        component: () => import('@/modules/import/views/ImportOnecView.vue'),
      },
      {
        path: 'photos',
        name: RouteNames.IMPORT_PHOTOS_V2,
        component: () => import('@/modules/import/views/ImportPhotosViewV2.vue'),
      },
    ),
  },
  {
    path: '/auth-page',
    name: RouteNames.AUTH_PAGE,
    component: () => import('@/modules/auth/common/views/AuthRootView.vue'),
  },
  {
    path: '/graph-visualization/:id?',
    name: RouteNames.GRAPH_VISUALIZATION,
    props: true,
    component: () => import('@/modules/graph/analysisUnity/views/AnalysisUnityGraphView.vue'),
  },
];
