import { DxFilterFunction } from '@/modules/devExtreme/filters/types/DxFilterFunction';
import { DxFilterObject } from '@/modules/devExtreme/filters/types/DxFilterObject';
import { isObject, isFunction } from 'lodash';

export module DxDataSourceFilterUtils {
  export function isFilterObject(item: any): item is DxFilterObject {
    return isObject(item) && 'dataField' in item;
  }

  export function isFilterFunction(item: any): item is DxFilterFunction {
    return isFunction(item);
  }
}
