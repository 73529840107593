import { injectable } from 'inversify';
import { format, formatISO, parse, parseISO, isValid } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { isDate } from 'lodash';
import { IDateFormatterService } from '@/modules/common/services/dateFormatterService/dateFormatterService.interfaces';

const options = {
  locale: ru,
};

@injectable()
export default class DateFormatterService implements IDateFormatterService {
  parse(date: string | Date, dateFormat?: string): Date | undefined {
    const parsedDate = isDate(date) ? date :
      dateFormat == undefined
        ? parseISO(date)
        : parse(date, dateFormat, new Date(), options);

    if (isValid(parsedDate)) {
      return parsedDate;
    }

    return undefined;
  }

  format(date?: Date, dateFormat?: string): string | undefined {
    if (date == undefined || !isValid(date)) {
      return undefined;
    }

    const formattedDate =
      dateFormat == undefined ? formatISO(date) : format(date, dateFormat, options);

    return formattedDate;
  }
}
