import { useEvent } from '@/modules/events/composables/useEvent';

export function useLogoutEvent() {
  const { on, trigger } = useEvent('Logout');

  return {
    triggerLogoutEvent: trigger,
    onLogout: on,
  };
}
