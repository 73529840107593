import EndpointsFactory from '@/modules/common/factories/endpoints/endpointsFactory';
import {
  IGraphEndpointsFactory
} from '@/modules/common/factories/endpoints/graphEndpointsFactory.interfaces';
import {
  IGraphSettings,
  IGraphSettingsKey
} from '@/modules/graph/common/settings/graphSettings.interfaces';
import { AxiosRequestConfig } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class GraphEndpointsFactory extends EndpointsFactory implements IGraphEndpointsFactory {
  constructor(
    @inject(IGraphSettingsKey as symbol) private graphSettings: IGraphSettings
  ) {
    super();
  }

  protected override getConfig(): AxiosRequestConfig {
    return {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },

      baseURL: this.graphSettings.buildServerURL
    }
  }
}
