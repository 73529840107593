import { injectable } from 'inversify';
import { ICompanySROService } from '@/modules/dossier/company/sro/services/companySROService.interface';
import { CompanySro } from '@/types/companyData/sro';

@injectable()
export default class CompanySROService implements ICompanySROService {
  calculateSROCountByType(items: Array<CompanySro>, type: string): number {
    return items.filter(({ participantSroStatus }) => participantSroStatus == type).length;
  }
}
