import { injectable } from 'inversify';
import { NumbersUtils } from '@/utils/numbersUtils';
import { IPersonArbitrationStatisticService } from '@/modules/dossier/person/arbitration/services/personArbitrationStatisticsService.interface';
import { ApiArbitrationCase } from '@/modules/dossier/person/common/types/api/apiArbitrationCase';

@injectable()
export default class PersonArbitrationService implements IPersonArbitrationStatisticService {
  // eslint-disable-next-line class-methods-use-this
  calculateArbitrationCountByType(
    arbitrationList: Array<ApiArbitrationCase>,
    type: string,
  ): string {
    return NumbersUtils.separateByThreeDigits(
      arbitrationList.filter((arbitration) => arbitration.arbTypeStr == type).length,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  calculateArbitrationSum(arbitrationList: Array<ApiArbitrationCase>): string {
    const sum = arbitrationList.reduce((acc, arbitration) => acc + (arbitration.sum ?? 0), 0);
    return NumbersUtils.separateByThreeDigits(sum);
  }
}
