import { InjectionKey } from 'vue';
import { ApiPersonDetailContactsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailContactsRecord';
import { ApiPersonDetailFioPhoneBondVerificationsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailFioPhoneBondVerificationsRecord';
import { ContactsPhonesTableRow } from '@/modules/dossier/person/contacts/types/contactsPhonesTableRow';

export interface IPersonContactsPhonesMappingService {
  getFioPhoneBondVerificationResultCode(operationResult?: string): number;

  mapContactsPhonesTable(
    contacts: Array<ApiPersonDetailContactsRecord>,
    fioPhoneBondVerifications: Array<ApiPersonDetailFioPhoneBondVerificationsRecord>,
  ): Array<ContactsPhonesTableRow>;
}

export const IPersonContactsPhonesMappingServiceKey: InjectionKey<IPersonContactsPhonesMappingService> =
  Symbol('IPersonContactsPhonesMappingServiceKey');
