import { IPersonMentionsMappingService } from '@/modules/dossier/person/mentions/services/personMentionsMappingService.interface';
import { injectable } from 'inversify';
import { PersonMentionTableItem } from '@/modules/dossier/person/mentions/types/personMentionTableItem';
import { ApiPersonDetailMentions } from '@/modules/dossier/person/common/types/api/apiPersonDetailMentions';
import { PhoneNumberUtils } from '@/utils/phoneNumberUtils';

@injectable()
export default class PersonMentionsMappingService implements IPersonMentionsMappingService {
  mapPersonMentionsDataTable(mentions: Array<ApiPersonDetailMentions>): PersonMentionTableItem[] {
    return mentions
      .map((mention) => ({
        fullName: mention.fullName,
        originalFullName: mention.originalFullName,
        phone: PhoneNumberUtils.formatPhoneNumber(mention.phone),
        source: mention.source,
      }))
      .sort((a, b) => {
        if (a.source.toLowerCase().trim() == 'getcontacts') {
          return 1;
        }
        if (b.source.toLowerCase().trim() == 'getcontacts') {
          return -1;
        }
        if (a.source.toLowerCase().trim() == 'truecaller') {
          return 1;
        }
        if (b.source.toLowerCase().trim() == 'truecaller') {
          return -1;
        }
        if (a.source.toLowerCase().trim() == 'odyssey') {
          return 1;
        }
        return -1;
      });
  }
}
