import { injectable } from 'inversify';
import { PersonalSocialNetworkAdditionalInfoVkUserLink } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personalSocialNetworkAdditionalInfoVkUserLink';
import { IpersonSocialNetworkAdditionalInfoVkUserLinkService } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/parsers/personSocialNetworkAdditionalInfoVkUserLinkService.interface';
import { ApiPersonDetailVkRelativeUserRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRelativeUserRecord';
import { ApiPersonDetailVkSimpleUserRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkSimpleUserRecord';

@injectable()
export default class personSocialNetworkAdditionalInfoVkUserLinkService
  implements IpersonSocialNetworkAdditionalInfoVkUserLinkService
{
  getPersonVkUserLink(
    vkListItem: ApiPersonDetailVkRelativeUserRecord | ApiPersonDetailVkSimpleUserRecord,
  ): PersonalSocialNetworkAdditionalInfoVkUserLink {
    return {
      fullName: `${vkListItem.firstname || ''} ${vkListItem.lastname || ''}`,
      url: vkListItem.profile_url || '',
    };
  }
}
