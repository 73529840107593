export enum SearchConceptOptionsGroupType {
  General = 0,
  PersonalInformation = 1,
  PersonScoring = 2,
  PersonContacts = 3,
  PersonAddresses = 4,
  PersonTransports = 5,
  PersonAds = 6,
  PersonParkings = 7,
  PersonEducations = 8,
  PersonLegalEntityParticipations = 9,
  PersonJobs = 10,
  BusinessTrips = 11,
  PersonMilitary = 12,
  PersonNegativeInfos = 13,
  PersonFamily = 14,
  PersonAffiliationInfos = 15,
  PersonMovements = 16,
  CompanyAddress = 17,
  Phones = 18,
  Tags = 19,
  Socials = 20,
  Head = 21,
  Founder = 22,
  ShareHolder = 23,
  Emails = 24,
  CompanyVehicles = 25,
  IpInfo = 26,
  CompanyRelatedPersons = 27
}
