import { InjectionKey } from 'vue';
import { CompanySocialNetwork } from '@/modules/dossier/company/socials/types/socialNetworks';
import { CompanySocialNetworksTableData } from '@/modules/dossier/company/socials/types/companySocialNetworksTableData';

export interface ICompanySocialNetworksMappingService {
  getCompanySocialNetworksTableData(
    socialNetworks: Array<CompanySocialNetwork>,
  ): Array<CompanySocialNetworksTableData>;
}

export const ICompanySocialNetworksMappingServiceKey: InjectionKey<ICompanySocialNetworksMappingService> =
  Symbol('ICompanySocialNetworksMappingService');
