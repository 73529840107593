import { useInjectable } from '@/hooks/useInjectable';
import { defineDynamicStore } from '@/modules/common/composables/defineDynamicStore/defineDynamicStore';
import { useScopedStore } from '@/modules/common/composables/useScopedStore/useScopedStore';
import { useStatelessUpdateQueue } from '@/modules/common/composables/useStatelessUpdateQueue/useStatelessUpdateQueue';
import { CallbackUtils } from '@/modules/common/utils/callbackUtils';
import { useTaskStatusChangedEvent } from '@/modules/tasks/composables/useTaskStatusChangedEvent/useTaskStatusChangedEvent';
import { ITasksServiceKey } from '@/modules/tasks/services/tasksService/tasksService.interfaces';
import { ITaskStatusInfoDTOMappingServiceKey } from '@/modules/tasks/services/taskStatusInfoDTOMappingService/taskStatusInfoDTOMappingService.interfaces';
import { ApiProcessStatus } from '@/modules/tasks/types/api/apiProcessStatus';
import { ProcessTaskStepType } from '@/modules/tasks/types/api/processTaskStepType';
import { TaskStatusInfoDTO } from '@/modules/tasks/types/dto/taskStatusInfoDTO';
import { TaskUtils } from '@/modules/tasks/utils/taskUtils';
import { defineStore } from 'pinia';
import { computed, ref, shallowRef, watch } from 'vue';

const baseUpdateRate = 2500;

function compareTasks(left: TaskStatusInfoDTO, right: TaskStatusInfoDTO) {
  return (
    left.taskId == right.taskId &&
    left.status == right.status &&
    left.currentStep == right.currentStep &&
    left.progress == right.progress
  );
}

export const useTaskStore = defineDynamicStore((taskId: string) =>
  useScopedStore(
    defineStore(`TaskStore:${taskId}`, () => {
      const tasksService = useInjectable(ITasksServiceKey);
      const dtoMapper = useInjectable(ITaskStatusInfoDTOMappingServiceKey);

      const { triggerTaskStatusChangedEvent } = useTaskStatusChangedEvent();

      const updateRateMultiplier = ref(1);
      const updateRate = computed(() => baseUpdateRate * updateRateMultiplier.value);

      const task = ref<TaskStatusInfoDTO>();

      const isUpdating = shallowRef(false);
      const isReady = shallowRef(false);
      const isError = shallowRef(false);

      const currentStep = computed(() => task.value?.currentStep ?? ProcessTaskStepType.Unknown);
      const progress = computed(() => task.value?.progress ?? 0);
      const status = computed(() => task.value?.status ?? ApiProcessStatus.Pending);

      async function updateInternalAsync() {
        isUpdating.value = true;
        isError.value = false;

        try {
          const newTask = dtoMapper.map(await tasksService.getStatus(taskId));

          if (task.value != undefined && compareTasks(task.value, newTask)) {
            updateRateMultiplier.value += 1;
          } else {
            task.value = newTask;
            updateRateMultiplier.value = 1;
          }

          isReady.value = true;
        } catch (ex) {
          isError.value = true;
          throw ex;
        } finally {
          isUpdating.value = false;
        }
      }

      const update = useStatelessUpdateQueue(updateInternalAsync);

      watch(
        [status, updateRate],
        ([status, updateRate], _, onCleanup) => {
          onCleanup(CallbackUtils.emptyFunc);

          if (TaskUtils.isTaskInProgress(status)) {
            const interval = setInterval(() => update(), updateRate);
            onCleanup(() => clearInterval(interval));
          }
        },
        { immediate: true },
      );

      watch(status, status => {
        triggerTaskStatusChangedEvent(taskId, status);
      });

      return {
        task,
        progress,
        currentStep,
        status,
      };
    }),
  ),
);
