import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IRiskTypeCalculatorServiceKey } from '@/modules/risks/services/riskTypeCalculatorService.interfaces';
import { RiskTypeCalculatorService } from '@/modules/risks/services/riskTypeCalculatorService';
import { RiskTypeFormatterService } from '@/modules/risks/services/riskTypeFormatterService';
import { IRiskTypeFormatterServiceKey } from '@/modules/risks/services/riskTypeFormatterService.interfaces';

export function bindRiskServices(bind: IContainerBinder) {
  bind(IRiskTypeCalculatorServiceKey).to(RiskTypeCalculatorService);
  bind(IRiskTypeFormatterServiceKey).to(RiskTypeFormatterService);
}
