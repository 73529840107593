import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IPersonNegativeOverviewMappingServiceKey } from '@/modules/dossier/person/negativeOverview/services/personNegativeOverviewMappingService.interface';
import PersonNegativeOverviewMappingService from '@/modules/dossier/person/negativeOverview/services/personNegativeOverviewMappingService';
import { IPersonNegativeOverviewStatisticsServiceKey } from '@/modules/dossier/person/negativeOverview/services/personNegativeOverviewStatisticsService.interface';
import PersonNegativeOverviewStatisticsService from '@/modules/dossier/person/negativeOverview/services/personNegativeOverviewStatisticsService';

export function bindPersonDossierNegativeOverviewServices(bind: IContainerBinder) {
  bind(IPersonNegativeOverviewMappingServiceKey).to(
    PersonNegativeOverviewMappingService,
  );
  bind(IPersonNegativeOverviewStatisticsServiceKey).to(
    PersonNegativeOverviewStatisticsService,
  );
}
