export type GridPinsState = {
  personListsPins: string[];
  companyListsPins: string[];
};

export enum MutationTypesEnum {
  SET_PERSON_LISTS_PINS = 'SET_PERSON_LISTS_PINS',
  SET_COMPANY_LISTS_PINS = 'SET_COMPANY_LISTS_PINS',
}

export type Mutations<S = GridPinsState> = {
  [MutationTypesEnum.SET_PERSON_LISTS_PINS](state: S, payload: string[]): void;
  [MutationTypesEnum.SET_COMPANY_LISTS_PINS](state: S, payload: string[]): void;
};
