import { InjectionKey } from 'vue';
import { ApiPersonDetailPersonDebtDataRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailPersonDebtDataRecord';

export interface IPersonTaxDebtInformationStatisticsService {
  getTaxDebtInformationCount(taxDebt: Array<ApiPersonDetailPersonDebtDataRecord>): number;
  getTaxDebtInformationSum(taxDebt: Array<ApiPersonDetailPersonDebtDataRecord>): string;
}

export const IPersonTaxDebtInformationStatisticsServiceKey: InjectionKey<IPersonTaxDebtInformationStatisticsService> =
  Symbol('IPersonTaxDebtInformationStatisticsServiceKey');
