import { injectable, inject } from 'inversify';
import { ApiPersonDetailVkRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecord';
import { PersonSocialNetworkAdditionalInfoVKRecordKeys } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKRecordKeys';
import { PersonSocialNetworkAdditionalInfoVKDataSection } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKDataSection';
import { PersonSocialNetworkAdditionalInfoAccordeonSections } from '@/modules/dossier/person/socialNetworkAdditionalInfo/common/types/personSocialNetworkAdditionalInfoAccordeonSections';
import {
  IPersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter,
  IPersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatterKey,
} from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter.interface';
import { IPersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoTitledFormatter.interface';

@injectable()
export default class PersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter
  implements IPersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter
{
  constructor(
    @inject(IPersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatterKey as symbol)
    private PersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter: IPersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter,
  ) {}

  getPersonSocialNetworkVKMainInfoTitledFormattedData(
    detailVkRecord: ApiPersonDetailVkRecord,
    sectionDetailsName: PersonSocialNetworkAdditionalInfoAccordeonSections,
    sectionDetailsRequiredKeys: PersonSocialNetworkAdditionalInfoVKRecordKeys[],
  ): PersonSocialNetworkAdditionalInfoVKDataSection {
    return {
      name: sectionDetailsName,
      dataList:
        this.PersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter.getPersonSocialNetworkVKMainInfoFormattedDataByKeys(
          detailVkRecord,
          sectionDetailsRequiredKeys,
        ),
    };
  }
}
