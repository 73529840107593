import { InjectionKey } from 'vue';
import { PersonSocialNetworkAdditionalInfoAccordeonSections } from '@/modules/dossier/person/socialNetworkAdditionalInfo/common/types/personSocialNetworkAdditionalInfoAccordeonSections';
import { ApiPersonDetailVkRecordStringItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecordStringItem';
import { PersonSocialNetworkAdditionalInfoVKDataSection } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKDataSection';

export interface IPersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatter {
  getPersonSocialNetworkVKMainInfoFormattedDataByDate(
    detailVkSection: Array<ApiPersonDetailVkRecordStringItem>,
    sectionDetailsName: PersonSocialNetworkAdditionalInfoAccordeonSections,
  ): PersonSocialNetworkAdditionalInfoVKDataSection;
}

export const IPersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatterKey: InjectionKey<IPersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatter> =
  Symbol('IPersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatterKey');
