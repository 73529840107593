import { format, parse, isValid } from 'date-fns';
import ru from 'date-fns/locale/ru';
import uniq from 'lodash/uniq';
import { SortOrder } from '@/modules/common/types/sortOrder';
import { DateFormatConfiguration } from '@/configuration/dateFormatConfiguration';

export module DateUtils {
  export function parseOptionalDate(value: Date | string | undefined): Date | undefined {
    if (value == undefined) {
      return undefined;
    }
    const date = new Date(value);
    return Number.isFinite(date.valueOf()) ? date : undefined;
  }

  export function formatDate(
    value: Date | string | undefined,
    dateFormat: string,
    defaultValue = '',
  ): string {
    const date = parseOptionalDate(value);

    if (date == undefined) {
      return defaultValue;
    }

    return format(date, dateFormat, { locale: ru });
  }

  export function extractYear(str: string): string {
    const match = str.match(/\b\d{4}\b/);
    if (match != null) {
      return match[0];
    }
    return '';
  }

  export function checkActualityToday(value: Date | string): boolean {
    const today = new Date();
    if (typeof value == 'string') {
      return new Date(value) >= today;
    }
    return value >= today;
  }

  export function getSortedUniqueYears(
    yearArray: Array<number>,
    sortOrder: SortOrder,
    count?: number,
  ): Array<number> {
    const uniqueYearArray = uniq(yearArray);

    let sortedUniqueYearArray;
    switch (sortOrder) {
      case SortOrder.desc: {
        sortedUniqueYearArray = uniqueYearArray.sort().reverse();
        break;
      }
      case SortOrder.asc: {
        sortedUniqueYearArray = uniqueYearArray.sort();
        break;
      }
      case SortOrder.unknown:
      default: {
        sortedUniqueYearArray = uniqueYearArray;
        break;
      }
    }

    if (count == undefined) {
      return sortedUniqueYearArray;
    }

    if (sortOrder == SortOrder.unknown) {
      console.warn('Attention! You will receive random years, because they were not sorted');
    }

    return sortedUniqueYearArray.slice(0, count);
  }

  // formatDateWithoutYear принимает дату в формате string (ДД.ММ.ГГГГ), у которой вместо ГГГГ может быть любое значение или не быть вовсе (ДД.ММ), например:
  // dateString = '06.06.????' или '06.06.0001' или '06.06'
  // dateFormat = 'd MMMM'
  export function formatDateWithoutYear(
    dateString: string,
    dateFormat: string,
    defaultValue = '',
  ): string {
    const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[0-2])(\..*)?$/;

    if (!dateRegex.test(dateString)) {
      return defaultValue;
    }

    const dateParts = dateString.split('.');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const parsedDate = new Date(currentYear, month - 1, day);

    if (isValid(parsedDate)) {
      const formattedDate = format(parsedDate, dateFormat, { locale: ru });
      return formattedDate; // Возвращает formattedDate без года, например = '6 июня' (в зависимости от переданного dateFormat)
    }

    return defaultValue;
  }

  export function parseRussianDate(dateString: string): Date | undefined {
    const dateObject = parse(dateString, DateFormatConfiguration.apiDate, new Date(), { locale: ru });
    return dateObject;
  }

  export function getPublicationDateText(dateString: string | undefined): string {
    if (dateString == undefined) {
      return '';
    }

    return `Дата публикации: ${dateString}`;
  }
}
