import { InjectionKey } from 'vue';
import { ApiPersonDetailFineRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailFineRecord';

export interface IPersonFineStatisticsService {
  calculateFineCountByType(items: Array<ApiPersonDetailFineRecord>, type: string): number;
  calculateFineSumByType(items: Array<ApiPersonDetailFineRecord>, type: string): number;
}

export const IPersonFineStatisticsServiceKey: InjectionKey<IPersonFineStatisticsService> =
  Symbol('IPersonFineStatisticsService');
