import { AxiosResponse } from 'axios';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { InjectionKey } from 'vue';
import { RisksDetail } from '@/modules/regulationsCheck/common/types/risksDetail';
import { RateUpdateParameters } from '@/modules/regulationsCheck/common/types/rateUpdateParameters';

export interface IRegulationsCheckMarkersApiService {
  getPersonMarkersWeight(id: string): Promise<AxiosResponse<ApiResponse<RisksDetail>>>;
  getCompanyMarkersWeight(id: string): Promise<AxiosResponse<ApiResponse<RisksDetail>>>;
  updatePersonRating(parameters: RateUpdateParameters): Promise<AxiosResponse<void>>;
  updateCompanyRating(parameters: RateUpdateParameters): Promise<AxiosResponse<void>>;
}

export const IRegulationsCheckMarkersApiServiceKey: InjectionKey<IRegulationsCheckMarkersApiService> =
  Symbol('IRegulationsCheckMarkersApiServiceKey');
