import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { GridOutput } from '@/modules/common/types/api/gridOutput';
import { ApiGridInput } from '@/modules/dataGrid/common/types/api/apiGridInput';
import { ReportInterruptResponseApi } from '@/modules/reports/common/types/reportInterruptResponseApi';
import { TGenerateReportBodyApi } from '@/modules/reports/desktop/types/TGenerateReportBodyApi';
import { TGenerateReportResponseApi } from '@/modules/reports/desktop/types/TGenerateReportResponseApi';
import { THistoryReportApi } from '@/modules/reports/desktop/types/THistoryReportApi';
import { THistoryReportResponseApi } from '@/modules/reports/desktop/types/THistoryReportResponseApi';
import { TStatusTaskResponseApi } from '@/modules/reports/desktop/types/TStatusTaskResponseApi';
import { AxiosResponse } from 'axios';
import { InjectionKey } from 'vue';

export interface IReportsService {
  cancelReportGeneration(taskId: string): Promise<AxiosResponse<ReportInterruptResponseApi>>;

  generateReport(
    payload: TGenerateReportBodyApi,
  ): Promise<AxiosResponse<TGenerateReportResponseApi>>;

  getReportStatus(taskId: string): Promise<AxiosResponse<TStatusTaskResponseApi>>;
  getAllHistoryReports(): Promise<AxiosResponse<THistoryReportResponseApi>>;
  getHistoryReports(
    payload?: ApiGridInput,
  ): Promise<AxiosResponse<ApiResponse<GridOutput<THistoryReportApi>>>>;

  /*
   * Legacy method. interruption won't work without taskId so it's useless
   * */
  postStopGenerateReport(): Promise<AxiosResponse<void>>;
}

export const IReportsServiceKey: InjectionKey<IReportsService> = Symbol('IReportsService');
