import { IReportTypeFormatter } from '@/modules/reports/common/services/reportTypeFormatter.interfaces';
import { injectable } from 'inversify';
import { ReportType } from '@/modules/reports/common/types/ReportType';

@injectable()
export default class ReportTypeFormatter implements IReportTypeFormatter {
  format(reportType: ReportType): string {
    switch (reportType) {
      case ReportType.standard:
        return 'Стандартный';
      case ReportType.extended:
        return 'Расширенный';
      case ReportType.unknown:
      default:
        return 'Неизвестно';
    }
  }
}
