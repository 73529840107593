import {
  IContactTypeFormatter
} from '@/modules/dossier/person/common/services/formatters/contactTypeFormatter.interfaces';
import { ContactTypes } from '@/modules/dossier/person/common/types/api/contactTypes';
import { injectable } from 'inversify';

@injectable()
export default class ContactTypeFormatter implements IContactTypeFormatter {
  format(value: ContactTypes): string {
    switch (value) {
      case ContactTypes.Unknown:
        return 'Неизвестно';
      case ContactTypes.Email:
        return 'Почта';
      case ContactTypes.Phone:
        return 'Телефон';
      default:
        return '';

    }
  }


}
