import { DossierTagData } from '@/modules/common/types/dossierTag';
import {
  RegulationsCheckDecision
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckDecision';
import { InjectionKey } from 'vue';

export interface IRegulationsDecisionsService {
  getRegulationDecisions(status: RegulationsCheckDecision | undefined): DossierTagData | string;
}

export const IRegulationsDecisionsServiceKey: InjectionKey<IRegulationsDecisionsService> = Symbol('IRegulationsDecisionsServiceKey',);
