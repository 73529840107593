import { inject, injectable } from 'inversify';
import { ICarNumberValidationService } from '@/modules/validation/services/carNumberValidationService.interface';
import {
  IMasksService,
  IMasksServiceKey,
} from '@/modules/masks/services/masksService/masksService.interfaces';
import {
  carNumberMask,
  carNumberTwoDigitRegionMask,
} from '@/modules/validation/configuration/masks/carNumberMask';

@injectable()
export default class CarNumberValidationService implements ICarNumberValidationService {
  constructor(@inject(IMasksServiceKey as symbol) private masksService: IMasksService) {}

  validate(value: string): boolean {
    return (
      this.masksService.isCompleted(value, carNumberMask) ||
      this.masksService.isCompleted(value, carNumberTwoDigitRegionMask)
    );
  }
}
