import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { GridOutput } from '@/modules/common/types/api/gridOutput';
import {
  useExternalDataParserStartedEvent
} from '@/modules/dataCollection/composables/useExternalDataParserStartedEvent';
import {
  IExternalDataParserService
} from '@/modules/dataCollection/services/externalDataParserService.interfaces';
import {
  ApiExternalDataParseAvailableRequest
} from '@/modules/dataCollection/types/api/apiExternalDataParseAvailableRequest';
import {
  ApiExternalDataParserAvailableResponse
} from '@/modules/dataCollection/types/api/apiExternalDataParserAvailableResponse';
import {
  ApiExternalDataParseRequest
} from '@/modules/dataCollection/types/api/apiExternalDataParseRequest';
import {
  ApiExternalDataParseRequestWithDefaults
} from '@/modules/dataCollection/types/api/ApiExternalDataParseRequestWithDefaults';
import {
  ApiExternalDataParserStatusMessage
} from '@/modules/dataCollection/types/api/apiExternalDataParserStatusMessage';
import {
  ApiExternalDataParseSpecializedRequestWithDefaults
} from '@/modules/dataCollection/types/api/ApiExternalDataParseSpecializedRequestWithDefaults';
import {
  ParserDataStatusActiveInfo
} from '@/modules/dataCollection/types/api/parserDataStatusActiveInfo';
import { ParserInputDataType } from '@/modules/dataCollection/types/api/parserInputDataType';
import { AxiosInstance, AxiosResponse } from 'axios';
import { LoadOptions } from 'devextreme/data';
import { inject, injectable } from 'inversify';

const { trigger: triggerParserStartedEvent } = useExternalDataParserStartedEvent();

@injectable()
export default class ExternalDataParserService implements IExternalDataParserService {
  constructor(@inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance) {
  }

  getAvailableParsers(payload: ApiExternalDataParseAvailableRequest): Promise<AxiosResponse<ApiResponse<ApiExternalDataParserAvailableResponse[]>>> {
    return this.apiEndpoint.post('/external-data-parser/available', payload);
  }

  async startParserExtended(payload: ApiExternalDataParseRequest): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post('/external-data-parser/run-parser', payload);

    triggerParserStartedEvent(payload.parserInputDataType, payload.selectedIdListEntity);

    return response;
  }

  startParser(payload: ApiExternalDataParseRequestWithDefaults): Promise<AxiosResponse<ApiResponse<string>>> {
    return this.startParserExtended({
      settings: {
        cachingMode: 'With',
        normalizationMode: 'DadataSuggestions',
      },

      ...payload
    });
  }

  startPersonParser(
    payload: ApiExternalDataParseSpecializedRequestWithDefaults,
  ): Promise<AxiosResponse<ApiResponse<string>>> | void {
    return this.startParser({
      parserInputDataType: ParserInputDataType.Person,
      ...payload,
    });
  }

  startCompanyParser(payload: ApiExternalDataParseSpecializedRequestWithDefaults): Promise<AxiosResponse<ApiResponse<string>>> {
    return this.startParser({
      parserInputDataType: ParserInputDataType.Company,
      ...payload
    });
  }

  getStatus(): Promise<AxiosResponse<ApiResponse<ParserDataStatusActiveInfo>>> {
    return this.apiEndpoint.get('/external-data-parser/status-is-active');
  }

  getStatusMessages(data: LoadOptions): Promise<AxiosResponse<ApiResponse<GridOutput<ApiExternalDataParserStatusMessage>>>> {
    return this.apiEndpoint.post('/external-data-parser/status-messages', data);
  }
}
