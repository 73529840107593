import { InjectionKey } from 'vue';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { EventJournalTypes } from '@/modules/eventJournal/types/eventJournalTypes';

export interface IEventJournalService {
  getEventJournalType(type: string): EventJournalTypes;
  getEventJournalIcon(value: string): SystemIconsNames;
}

export const IEventJournalServiceKey: InjectionKey<IEventJournalService> =
  Symbol('IEventJournalService');
