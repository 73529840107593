import { InjectionKey } from 'vue';
import { NotificationConfig } from '@/modules/notifications/types/notificationConfig';

export interface ISetupNotificationCleanupService {
  setupNotificationCleanup(
    notification: NotificationConfig,
    cleanup: (value: NotificationConfig) => void,
  ): void;
}

export const ISetupNotificationCleanupServiceKey: InjectionKey<ISetupNotificationCleanupService> =
  Symbol('ISetupNotificationCleanupService<NotificationConfig>');
