import { useExternalDataParserStore } from '@/modules/dataCollection/stores/useExternalDataParserStore';
import { useExclusiveNotifications } from '@/modules/notifications/composables/useExclusiveNotifications';
import { useProgressNotification } from '@/modules/notifications/composables/useProgressNotification';
import { useStaticNotification } from '@/modules/notifications/composables/useStaticNotification';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';

const { processNotification } = useExclusiveNotifications();

export function useExternalDataParserNotifications() {
  const { fireNotification: fireStaticNotification } = useStaticNotification();
  const { fireNotification: fireProgressNotification } = useProgressNotification();

  const parserStore = useExternalDataParserStore();
  const { progress, isPending, isError, isSuccess } = storeToRefs(parserStore);

  watch(isPending, isPending => {
    if (isPending) {
      processNotification(
        fireProgressNotification({
          value: progress,
          title: 'Сбор данных',
        }),
      );
    }
  });

  watch(isSuccess, isSuccess => {
    if (isSuccess) {
      processNotification(
        fireStaticNotification({
          title: 'Сбор данных завершён',
          status: 'success',
        }),
      );
    }
  });

  watch(isError, isError => {
    if (isError) {
      processNotification(
        fireStaticNotification({
          title: 'Не удалось собрать данные',
          status: 'error',
        }),
      );
    }
  });
}
