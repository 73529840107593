import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import RegulationChecksDecisionTagsFactory
  from '@/modules/regulationsCheck/common/factories/regulationChecksDecisionTagsFactory/regulationChecksDecisionTagsFactory';
import {
  IRegulationChecksDecisionTagsFactoryKey
} from '@/modules/regulationsCheck/common/factories/regulationChecksDecisionTagsFactory/regulationChecksDecisionTagsFactory.interfaces';
import RegulationChecksStatusTagsFactory
  from '@/modules/regulationsCheck/common/factories/regulationChecksStatusTagsFactory/regulationChecksStatusTagsFactory';
import {
  IRegulationChecksStatusTagsFactoryKey
} from '@/modules/regulationsCheck/common/factories/regulationChecksStatusTagsFactory/regulationChecksStatusTagsFactory.interfaces';
import RegulationCheckRootTypeFormatterService
  from '@/modules/regulationsCheck/common/services/regulationCheckRootTypeFormatterService/regulationCheckRootTypeFormatterService';
import {
  IRegulationCheckRootTypeFormatterServiceKey
} from '@/modules/regulationsCheck/common/services/regulationCheckRootTypeFormatterService/regulationCheckRootTypeFormatterService.interfaces';
import RegulationChecksDecisionFormatterService
  from '@/modules/regulationsCheck/common/services/regulationChecksDecisionFormatterService/regulationChecksDecisionFormatterService';
import {
  IRegulationChecksDecisionFormatterServiceKey
} from '@/modules/regulationsCheck/common/services/regulationChecksDecisionFormatterService/regulationChecksDecisionFormatterService.interfaces';
import RegulationChecksHistoryGroupingService
  from '@/modules/regulationsCheck/common/services/regulationChecksHistoryGroupingService/regulationChecksHistoryGroupingService';
import {
  IRegulationChecksHistoryGroupingServiceKey
} from '@/modules/regulationsCheck/common/services/regulationChecksHistoryGroupingService/regulationChecksHistoryGroupingService.interfaces';
import RegulationChecksHistoryItemDTOMappingService
  from '@/modules/regulationsCheck/common/services/regulationChecksHistoryItemDTOMappingService/regulationChecksHistoryItemDTOMappingService';
import {
  IRegulationChecksHistoryItemDTOMappingServiceKey
} from '@/modules/regulationsCheck/common/services/regulationChecksHistoryItemDTOMappingService/regulationChecksHistoryItemDTOMappingService.interfaces';
import RegulationCheckStatusFormatter
  from '@/modules/regulationsCheck/common/services/regulationCheckStatusFormatter/regulationCheckStatusFormatter';
import {
  IRegulationCheckStatusFormatterKey
} from '@/modules/regulationsCheck/common/services/regulationCheckStatusFormatter/regulationCheckStatusFormatter.interfaces';
import RegulationsDecisionsService
  from '@/modules/regulationsCheck/common/services/regulationDecisionsService';
import {
  IRegulationsDecisionsServiceKey
} from '@/modules/regulationsCheck/common/services/regulationDecisionsService.interface';
import RegulationsInputTypesService
  from '@/modules/regulationsCheck/common/services/regulationInputTypesService';
import {
  IRegulationsInputTypesServiceKey
} from '@/modules/regulationsCheck/common/services/regulationInputTypesService.interface';
import RegulationsRootTypesService
  from '@/modules/regulationsCheck/common/services/regulationRootTypesService';
import {
  IRegulationsRootTypesServiceKey
} from '@/modules/regulationsCheck/common/services/regulationRootTypesService.interface';
import RegulationsCheckCheckRequiredIndicatorsMappingService
  from '@/modules/regulationsCheck/common/services/regulationsCheckCheckRequiredIndicatorsMappingService';
import {
  IRegulationsCheckCheckRequiredIndicatorsMappingServiceKey
} from '@/modules/regulationsCheck/common/services/regulationsCheckCheckRequiredIndicatorsMappingService.interface';
import RegulationsCheckMarkersApiService
  from '@/modules/regulationsCheck/common/services/regulationsCheckMarkersApiService';
import {
  IRegulationsCheckMarkersApiServiceKey
} from '@/modules/regulationsCheck/common/services/regulationsCheckMarkersApiService.interface';
import RegulationsCheckMarkersMappingService
  from '@/modules/regulationsCheck/common/services/regulationsCheckMarkersMappingService';
import {
  IRegulationsCheckMarkersMappingServiceKey
} from '@/modules/regulationsCheck/common/services/regulationsCheckMarkersMappingService.interface';
import RegulationsChecksHistoryMappingService
  from '@/modules/regulationsCheck/common/services/regulationsChecksHistoryMappingService';
import {
  IRegulationsChecksHistoryMappingServiceKey
} from '@/modules/regulationsCheck/common/services/regulationsChecksHistoryMappingService.interface';
import RegulationsChecksParametersModesMapperService
  from '@/modules/regulationsCheck/common/services/regulationsChecksParametersModesMapperService';
import {
  IRegulationsChecksParametersModesMapperServiceKey
} from '@/modules/regulationsCheck/common/services/regulationsChecksParametersModesMapperService.interfaces';
import RegulationsChecksRateNameMapperService
  from '@/modules/regulationsCheck/common/services/regulationsChecksRateNameMapperService';
import {
  IRegulationsChecksRateNameMapperServiceKey
} from '@/modules/regulationsCheck/common/services/regulationsChecksRateNameMapperService.interfaces';
import RegulationsChecksService
  from '@/modules/regulationsCheck/common/services/regulationsChecksService/regulationsChecksService';
import {
  IRegulationsChecksServiceKey
} from '@/modules/regulationsCheck/common/services/regulationsChecksService/regulationsChecksService.interfaces';
import RegulationVettingStatusesService
  from '@/modules/regulationsCheck/common/services/regulationVettingStatusesService';
import {
  IRegulationVettingStatusesServiceKey
} from '@/modules/regulationsCheck/common/services/regulationVettingStatusesService.interface';

export function bindRegulationChecksServices(bind: IContainerBinder) {
  bind(IRegulationsChecksServiceKey).to(RegulationsChecksService);
  bind(IRegulationsChecksHistoryMappingServiceKey).to(RegulationsChecksHistoryMappingService);
  bind(IRegulationsDecisionsServiceKey).to(RegulationsDecisionsService);
  bind(IRegulationsInputTypesServiceKey).to(RegulationsInputTypesService);
  bind(IRegulationsRootTypesServiceKey).to(RegulationsRootTypesService);
  bind(IRegulationVettingStatusesServiceKey).to(RegulationVettingStatusesService);
  bind(IRegulationsCheckMarkersApiServiceKey).to(RegulationsCheckMarkersApiService);
  bind(IRegulationsCheckMarkersMappingServiceKey).to(RegulationsCheckMarkersMappingService);
  bind(IRegulationsCheckCheckRequiredIndicatorsMappingServiceKey).to(RegulationsCheckCheckRequiredIndicatorsMappingService);
  bind(IRegulationsChecksRateNameMapperServiceKey).to(RegulationsChecksRateNameMapperService);
  bind(IRegulationsChecksParametersModesMapperServiceKey).to(RegulationsChecksParametersModesMapperService);
  bind(IRegulationChecksHistoryItemDTOMappingServiceKey).to(RegulationChecksHistoryItemDTOMappingService);
  bind(IRegulationChecksHistoryGroupingServiceKey).to(RegulationChecksHistoryGroupingService);
  bind(IRegulationChecksStatusTagsFactoryKey).to(RegulationChecksStatusTagsFactory);
  bind(IRegulationCheckStatusFormatterKey).to(RegulationCheckStatusFormatter);
  bind(IRegulationCheckRootTypeFormatterServiceKey).to(RegulationCheckRootTypeFormatterService);
  bind(IRegulationChecksDecisionFormatterServiceKey).to(RegulationChecksDecisionFormatterService);
  bind(IRegulationChecksDecisionTagsFactoryKey).to(RegulationChecksDecisionTagsFactory);
}
