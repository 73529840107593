import { InjectionKey } from 'vue';
import DxRichFilter from '@/modules/devExtreme/filters/models/dxRichFilter';
import { DxTableColumnSearchInfo } from '@/modules/devExtreme/types/dxTableColumnSearchInfo';

export interface IDxSearchFilterFactory {
  buildFilter(
    dataFields: DxTableColumnSearchInfo | Array<DxTableColumnSearchInfo>,
    searchValue: string | Array<string>,
  ): DxRichFilter | undefined;
}

export const IDxSearchFilterFactoryKey: InjectionKey<IDxSearchFilterFactory> =
  Symbol('IDxSearchFilterFactory');
