import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import PhoneNumberValidationService
  from '@/modules/validation/services/phoneNumberValidationService';
import {
  IPhoneNumberValidationServiceKey
} from '@/modules/validation/services/phoneNumberValidationService.interfaces';
import UrlValidationService from '@/modules/validation/services/urlValidationService';
import {
  IUrlValidationServiceKey
} from '@/modules/validation/services/urlValidationService.interface';
import EmailValidationService from '@/modules/validation/services/emailValidationService';
import { IEmailValidationServiceKey } from '@/modules/validation/services/emailValidationService.interfaces';
import DateValidationService from '@/modules/validation/services/dateValidationService';
import { IDateValidationServiceKey } from '@/modules/validation/services/dateValidationService.interface';
import PastDateValidationService from '@/modules/validation/services/pastDateValidationService';
import { IPastDateValidationServiceKey } from '@/modules/validation/services/pastDateValidationService.interface';
import CarNumberValidationService from '@/modules/validation/services/carNumberValidationService';
import { ICarNumberValidationServiceKey } from '@/modules/validation/services/carNumberValidationService.interface';
import CarVinValidationService from '@/modules/validation/services/carVinValidationService';
import { ICarVinValidationServiceKey } from '@/modules/validation/services/carVinValidationService.interface';
import PersonInnValidationService from '@/modules/validation/services/personInnValidationService';
import { IPersonInnValidationServiceKey } from '@/modules/validation/services/personInnValidationService.interface';
import CompanyInnValidationService from '@/modules/validation/services/companyInnValidationService';
import { ICompanyInnValidationServiceKey } from '@/modules/validation/services/companyInnValidationService.interfaces';
import PassportNumberValidationService from '@/modules/validation/services/passportNumberValidationService';
import { IPassportNumberValidationServiceKey } from '@/modules/validation/services/passportNumberValidationService.interface';
import PassportSeriesValidationService from '@/modules/validation/services/passportSeriesValidationService';
import { IPassportSeriesValidationServiceKey } from '@/modules/validation/services/passportSeriesValidationService.interface';
import PassportValidationService from '@/modules/validation/services/passportValidationService';
import { IPassportValidationServiceKey } from '@/modules/validation/services/passportValidationService.interface';
import PersonSnilsValidationService from '@/modules/validation/services/personSnilsValidationService';
import { IPersonSnilsValidationServiceKey } from '@/modules/validation/services/personSnilsValidationService.interface';
import CompanyOgrnValidationService from '@/modules/validation/services/companyOgrnValidationService';
import { ICompanyOgrnValidationServiceKey } from '@/modules/validation/services/companyOgrnValidationService.interfaces';
import CompanyOgrnipValidationService from '@/modules/validation/services/companyOgrnipValidationService';
import { ICompanyOgrnipValidationServiceKey } from '@/modules/validation/services/companyOgrnipValidationService.interfaces';

export function bindValidationService(bind: IContainerBinder) {
  bind(IUrlValidationServiceKey).to(UrlValidationService);
  bind(IPhoneNumberValidationServiceKey).to(PhoneNumberValidationService);
  bind(IEmailValidationServiceKey).to(EmailValidationService);
  bind(IDateValidationServiceKey).to(DateValidationService);
  bind(ICarNumberValidationServiceKey).to(CarNumberValidationService);
  bind(ICarVinValidationServiceKey).to(CarVinValidationService);
  bind(IPersonInnValidationServiceKey).to(PersonInnValidationService);
  bind(ICompanyInnValidationServiceKey).to(CompanyInnValidationService);
  bind(IPassportNumberValidationServiceKey).to(PassportNumberValidationService);
  bind(IPassportSeriesValidationServiceKey).to(PassportSeriesValidationService);
  bind(IPassportValidationServiceKey).to(PassportValidationService);
  bind(IPastDateValidationServiceKey).to(PastDateValidationService);
  bind(IPersonSnilsValidationServiceKey).to(PersonSnilsValidationService);
  bind(ICompanyOgrnValidationServiceKey).to(CompanyOgrnValidationService);
  bind(ICompanyOgrnipValidationServiceKey).to(CompanyOgrnipValidationService);
}
