import {
  ISearchConceptOptionsGroupTypeFormatter
} from '@/modules/searchConcept/common/services/searchConceptOptionsGroupTypeFormatter/searchConceptOptionsGroupTypeFormatter.interfaces';
import {
  SearchConceptOptionsGroupType
} from '@/modules/searchConcept/common/types/searchConceptOptionsGroupType';
import { injectable } from 'inversify';

@injectable()
export default class SearchConceptOptionsGroupTypeFormatter implements ISearchConceptOptionsGroupTypeFormatter {
  format(groupType: SearchConceptOptionsGroupType): string {
    switch (groupType) {
      case SearchConceptOptionsGroupType.General: return 'Общая информация';
      case SearchConceptOptionsGroupType.PersonalInformation: return 'Личные данные';
      case SearchConceptOptionsGroupType.PersonScoring: return 'Скоринг';
      case SearchConceptOptionsGroupType.PersonContacts: return 'Контактные данные';
      case SearchConceptOptionsGroupType.PersonAddresses: return 'Адреса';
      case SearchConceptOptionsGroupType.PersonTransports: return 'Транспортные средства ';
      case SearchConceptOptionsGroupType.PersonAds: return 'Объявления';
      case SearchConceptOptionsGroupType.PersonParkings: return 'Парковочные сессии';
      case SearchConceptOptionsGroupType.PersonEducations: return 'Образование';
      case SearchConceptOptionsGroupType.PersonLegalEntityParticipations: return 'Участие в ЮЛ';
      case SearchConceptOptionsGroupType.PersonJobs: return 'Трудовая деятельность ';
      case SearchConceptOptionsGroupType.BusinessTrips: return 'Командировки';
      case SearchConceptOptionsGroupType.PersonMilitary: return 'Воинский учет ';
      case SearchConceptOptionsGroupType.PersonNegativeInfos: return 'Негативная информация';
      case SearchConceptOptionsGroupType.PersonFamily: return 'Родственные связи';
      case SearchConceptOptionsGroupType.PersonAffiliationInfos: return 'Аффилированность';
      case SearchConceptOptionsGroupType.PersonMovements: return 'Перемещения';
      case SearchConceptOptionsGroupType.CompanyAddress: return 'Адреса компании';
      case SearchConceptOptionsGroupType.Phones: return 'Телефоны';
      case SearchConceptOptionsGroupType.Tags: return 'Упоминания';
      case SearchConceptOptionsGroupType.Socials: return 'Социальные сети';
      case SearchConceptOptionsGroupType.Head: return 'Руководитель';
      case SearchConceptOptionsGroupType.Founder: return 'Учредитель';
      case SearchConceptOptionsGroupType.ShareHolder: return 'Акционер';
      case SearchConceptOptionsGroupType.Emails: return 'Почта';
      case SearchConceptOptionsGroupType.CompanyVehicles: return 'Авто компаний';
      case SearchConceptOptionsGroupType.IpInfo: return 'ИП';
      case SearchConceptOptionsGroupType.CompanyRelatedPersons: return 'Связанные с компанией';
      default: return '';
    }
  }
}
