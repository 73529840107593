import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import personSocialNetworkAdditionalInfoOkAvatarsMappingService from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkAvatarsMappingService';
import personSocialNetworkAdditionalInfoOkPhotosMappingService from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkPhotosMappingService';
import { IPersonSocialNetworkAdditionalInfoOkAvatarsMappingServiceKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkAvatarsMappingService.interface';
import { IPersonSocialNetworkAdditionalInfoOkPhotosMappingServiceKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkPhotosMappingService.interface';
import { IPersonSocialNetworkAdditionalInfoOkMappingServiceKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkMappingService.interface';
import { IPersonSocialNetworkAdditionalInfoOkUserLinkServiceKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/parsers/personSocialNetworkAdditionalInfoOkUserLinkService.interface';
import PersonSocialNetworkAdditionalInfoOkMappingService from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkMappingService';
import PersonSocialNetworkAdditionalInfoOkUserLinkService from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/parsers/personSocialNetworkAdditionalInfoOkUserLinkService';
import { IPersonSocialNetworkAdditionalInfoOkEntitiesMappingServiceKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkEntitiesMappingService.interface';
import PersonSocialNetworkAdditionalInfoOkEntitiesMappingService from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkEntitiesMappingService';
import { IPersonSocialNetworkAdditionalInfoOKGetLastValueServiceKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/parsers/personSocialNetworkAdditionalInfoOKGetLastValueService.interface';
import PersonSocialNetworkAdditionalInfoOKGetLastValueService from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/parsers/personSocialNetworkAdditionalInfoOKGetLastValueService';

export function bindPersonSocialNetworkAdditionalInfoOKServices(bind: IContainerBinder) {
  bind(IPersonSocialNetworkAdditionalInfoOkMappingServiceKey).to(
    PersonSocialNetworkAdditionalInfoOkMappingService,
  );
  bind(IPersonSocialNetworkAdditionalInfoOkUserLinkServiceKey).to(
    PersonSocialNetworkAdditionalInfoOkUserLinkService,
  );
  bind(IPersonSocialNetworkAdditionalInfoOkEntitiesMappingServiceKey).to(
    PersonSocialNetworkAdditionalInfoOkEntitiesMappingService,
  );
  bind(IPersonSocialNetworkAdditionalInfoOkAvatarsMappingServiceKey).to(
    personSocialNetworkAdditionalInfoOkAvatarsMappingService,
  );
  bind(IPersonSocialNetworkAdditionalInfoOkPhotosMappingServiceKey).to(
    personSocialNetworkAdditionalInfoOkPhotosMappingService,
  );
  bind(IPersonSocialNetworkAdditionalInfoOKGetLastValueServiceKey).to(
    PersonSocialNetworkAdditionalInfoOKGetLastValueService,
  );
}
