import {
  UseUpdateQueueConfig, useUpdateQueueResult
} from '@/modules/common/composables/useUpdateQueue/useUpdateQueue.interfaces';
import { PromiseUtils } from '@/modules/common/utils/promiseUtils';
import { useDebounceFn } from '@vueuse/core';
import { computed, Ref, ref, shallowRef, UnwrapRef, watch } from 'vue';

function buildState<T>(initialValue: T, config: UseUpdateQueueConfig): Ref<UnwrapRef<T>> {
  const state = ref(initialValue);

  if (config.debounce != undefined) {
    return buildDebouncedState(state, config.debounce);
  }

  return state;
}

function buildDebouncedState<T>(state: Ref<UnwrapRef<T>>, delay: number) {
  const setValue = useDebounceFn(value => {
    state.value = value;
  }, delay);

  return computed<UnwrapRef<T>>({
    get() {
      return state.value;
    },

    set(value) {
      setValue(value);
    }
  })
}

export function useUpdateQueue<T>(update: (state: T) => Promise<void>, initialValue: T, config: UseUpdateQueueConfig = {}): useUpdateQueueResult<T> {
  const {
    immediate = false,
    delayBetweenRequests = 0
  } = config;

  const state = buildState(initialValue, config);
  const requestId = shallowRef(0);

  const isUpdateRequired = shallowRef(false);
  const isUpdating = shallowRef(false);

  const isBusy = computed(() => isUpdateRequired.value || isUpdating.value);

  watch([state, requestId], () => {
    if(state != undefined)
      isUpdateRequired.value = true;
  }, { immediate });

  watch(
    [
      () => isUpdateRequired.value && !isUpdating.value,
      state
    ],

    async ([isAllowedToStart, state]) => {
      if (isAllowedToStart && state != undefined) {
        isUpdating.value = true;
        isUpdateRequired.value = false;

        try {
          await update(<T>state);

          if(delayBetweenRequests > 0)
            await PromiseUtils.delay(delayBetweenRequests);
        } catch (ex) {
          console.error(ex);
        } finally {
          isUpdating.value = false;
        }
      }
    }, { immediate: true });

  function requestUpdate(newState: T, force = false) {
    state.value = <any>newState;

    if(force)
      requestId.value += 1;
  }

  function requestRefresh() {
    requestId.value += 1;
  }

  return {
    state,
    update: requestUpdate,
    reload: requestRefresh,

    isBusy
  }
}
