import { inject, injectable } from 'inversify';
import { RequisiteType } from '@/modules/requisites/types/requisiteType';
import { ICompanyRequisitesMatchingService } from '@/modules/requisites/services/matchers/companyRequisitesMatchingService.interfaces';
import {
  ICompanyInnRequisiteMatchingService,
  ICompanyInnRequisiteMatchingServiceKey,
} from '@/modules/requisites/services/matchers/companyInnRequisiteMatchingService.interfaces';
import {
  IIpInnRequisiteMatchingService,
  IIpInnRequisiteMatchingServiceKey,
} from '@/modules/requisites/services/matchers/ipInnRequisiteMatchingService.interfaces';

@injectable()
export class CompanyRequisitesMatchingService implements ICompanyRequisitesMatchingService {
  constructor(
    @inject(ICompanyInnRequisiteMatchingServiceKey as symbol)
    private companyInnRequisiteMatchingService: ICompanyInnRequisiteMatchingService,
    @inject(IIpInnRequisiteMatchingServiceKey as symbol)
    private ipInnRequisiteMatchingService: IIpInnRequisiteMatchingService,
  ) {}

  getRequisiteTypes(value: string): Array<RequisiteType> {
    const detailList = [];
    if (this.companyInnRequisiteMatchingService.match(value)) detailList.push(RequisiteType.innUl);
    if (this.ipInnRequisiteMatchingService.match(value)) detailList.push(RequisiteType.innIp);

    return detailList;
  }
}
