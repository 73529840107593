import { ReportParentType } from '@/modules/reports/common/types/ReportParentType';
import { ReportType } from '@/modules/reports/common/types/ReportType';
import { ReportTypeInput } from '@/modules/reports/common/types/ReportTypeInput';

// TODO SNSIDE_UI-1136 на мобилке перенести все на сервисы
export module easyReportNames {
  export const easyReportInputNames: Record<string, string> = {
    [ReportTypeInput.fullName]: 'ФИО + Дата рождения',
    [ReportTypeInput.inn]: 'ИНН',
    [ReportTypeInput.phone]: 'Абонентский номер',
    [ReportTypeInput.carNumber]: 'Гос. номер',
  };
  export const easyReportTypeNames: Record<string, string> = {
    [ReportType.extended]: 'Расширенный',
    [ReportType.standard]: 'Стандартный',
    [ReportType.unknown]: 'Неизвестно',
  };
  export const easyReportParentTypeNames: Record<string, string> = {
    [ReportParentType.company]: 'Юридическое лицо',
    [ReportParentType.person]: 'Физическое лицо',
    [ReportParentType.unknown]: 'Неизвестно',
  };
}
