import { ApiUlListDataItem } from '@/modules/lists/types/api/ul/apiUlListDataItem';

export type CompanyTypes = {
  id: string;
  name: string;
  isCounterParty: boolean;
  isBlackList: boolean;
  isCompetitor: boolean;
  isGroupCompany: boolean;
  description: string;
  createdStr: string;
  created: string;
  modifiedStr: string;
  modified: string;
};

export type CompaniesTypesState = {
  data: ApiUlListDataItem[];
};

export enum MutationTypes {
  SET_DATA = 'SET_DATA',
}

export type Mutations<S = CompaniesTypesState> = {
  [MutationTypes.SET_DATA](state: S, payload: ApiUlListDataItem[]): void;
};

export enum ActionTypes {
  GET_COMPANIES_TYPES_DATA = 'GET_COMPANIES_TYPES_DATA',
}
