import { useInjectable } from '@/hooks/useInjectable';
import { IReportsServiceKey } from '@/modules/reports/common/services/reportsService/reportsService.interfaces';
import {
  ActionContext,
  ActionTree,
  CommitOptions,
  DispatchOptions,
  Module,
  MutationTree,
  Store as VuexStore,
} from 'vuex';
import type { RootState } from '../../type';
import type { EasyDataParserState, Mutations } from './type';
import { ActionTypesEnum, MutationTypesEnum } from './type';

const moduleState: EasyDataParserState = {
  history: [],
};

const mutations: MutationTree<EasyDataParserState> & Mutations = {
  [MutationTypesEnum.SET_HISTORY](state, payload) {
    state.history = payload;
  },
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<EasyDataParserState, RootState>, 'commit'>;

interface Actions {
  [ActionTypesEnum.GET_HISTORY]({ commit }: AugmentedActionContext): void;
}

const actions: ActionTree<EasyDataParserState, RootState> & Actions = {
  async [ActionTypesEnum.GET_HISTORY]({ commit }) {
    const reportsService = useInjectable(IReportsServiceKey);

    const {
      data: { data },
    } = await reportsService.getAllHistoryReports();

    const result = data.sort((a, b) => +new Date(b.created) - +new Date(a.created));
    commit(MutationTypesEnum.SET_HISTORY, result);
  },
};

export type EasyDataParserStore<S = EasyDataParserState> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions,
  ): ReturnType<Actions[K]>;
};

export const EasyDataParserModule: Module<EasyDataParserState, RootState> = {
  state: moduleState,
  mutations,
  actions,
};
