import { IYandexMapsApiSettings } from '@/modules/common/settings/yandexMapsApiSettings.interfaces';
import {
  IEnvironmentVariables,
  IEnvironmentVariablesKey,
} from '@/modules/common/settings/environmentVariables.interfaces';
import { inject, injectable } from 'inversify';

@injectable()
export default class YandexMapsApiSettings implements IYandexMapsApiSettings {
  constructor(
    @inject(IEnvironmentVariablesKey as symbol) private environmentVariables: IEnvironmentVariables,
  ) {}

  get apiKey() {
    return this.environmentVariables.VUE_APP_YANDEX_KEY;
  }

  get coordorder() {
    return 'latlong';
  }

  get debug() {
    return this.environmentVariables.NODE_ENV != 'production';
  }

  get lang() {
    return 'ru_RU';
  }

  get version() {
    return '2.1';
  }
}
