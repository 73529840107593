import { inject, injectable } from 'inversify';
import { editPhoneMask } from '@/modules/validation/configuration/masks/phoneMask';
import {
  IPhoneNumberValidationService
} from '@/modules/validation/services/phoneNumberValidationService.interfaces';
import {
  IMasksService,
  IMasksServiceKey,
} from '@/modules/masks/services/masksService/masksService.interfaces';

@injectable()
export default class PhoneNumberValidationService implements IPhoneNumberValidationService {
  constructor(@inject(IMasksServiceKey as symbol) private masksService: IMasksService) {}

  validate(value: string): boolean {
    return this.masksService.isCompleted(value, editPhoneMask);
  }
}
