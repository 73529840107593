import { injectable } from 'inversify';
import { UIScoreTheme } from '@/modules/triggers/components/UIScore/types/UIScoreTheme';
import { AnalyticsScore } from '@/modules/dossier/person/financialScorings/types/financialAnalyticsScore';
import { IAnalyticsScoreThemeFactory } from './analyticsScoreThemeFactory.interface';

@injectable()
export default class AnalyticsScoreThemeFactory implements IAnalyticsScoreThemeFactory {
  getAnalyticScoreTheme(score: AnalyticsScore): UIScoreTheme {
    switch (score) {
      case AnalyticsScore.HighScore:
        return 'green';
      case AnalyticsScore.MediumScore:
        return 'orange';
      case AnalyticsScore.PoorScore:
        return 'red';
      case AnalyticsScore.WorstScore:
        return 'red';
      case AnalyticsScore.NotFound:
        return 'gray';
      case AnalyticsScore.OverdueScore:
        return 'red';
      default:
        return 'red';
    }
  }
}
