import { AppMode } from '@/modules/common/types/appMode';
import {
  IAppModeRouterFactory
} from '@/modules/router/common/factories/appModeRouterFactory.interfaces';
import BaseRouterFactory from '@/modules/router/common/factories/baseRouterFactory';
import {
  standaloneGraphRoutes
} from '@/modules/router/standaloneGraph/configuration/standaloneGraphRoutes';
import { injectable } from 'inversify';
import { RouteRecordRaw } from 'vue-router';

@injectable()
export default class StandaloneGraphRouterFactory extends BaseRouterFactory implements IAppModeRouterFactory {
  appMode = AppMode.standaloneGraph;

  protected getRoutes(): RouteRecordRaw[] {
    return standaloneGraphRoutes;
  }
}
