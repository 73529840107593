import { injectable, inject } from 'inversify';
import { IPastDateValidationService } from '@/modules/validation/services/pastDateValidationService.interface';
import {
  IDateFormatterService,
  IDateFormatterServiceKey,
} from '@/modules/common/services/dateFormatterService/dateFormatterService.interfaces';
import { isFuture } from 'date-fns';

@injectable()
export default class PastDateValidationService implements IPastDateValidationService {
  constructor(
    @inject(IDateFormatterServiceKey as symbol)
    private dateFormatterService: IDateFormatterService,
  ) {}

  validate(value: string, format?: string): boolean {
    const date = this.dateFormatterService.parse(value, format);
    return date != undefined && date.toString() != 'Invalid Date' && !isFuture(date);
  }
}
