import { injectable } from 'inversify';
import { IPersonAddressTagVerificationCodeServiceInterface } from '@/modules/dossier/person/addresses/services/personAddressTagVerificationCodeService.interface';
import { UseDxTagConfig } from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';

@injectable()
export class PersonAddressTagVerificationCodeService
  implements IPersonAddressTagVerificationCodeServiceInterface
{
  getVerificationCodeTagData(verificationCode: string): UseDxTagConfig {
    switch (verificationCode) {
      case 'Распознан уверенно':
        return { content: verificationCode, theme: 'success' };
      case 'Остались «лишние» части. Либо в исходном адресе недостаточно данных для уверенного разбора':
        return {
          content: verificationCode,
          theme: 'warning',
          tooltip: 'always',
          tooltipText: 'Необходимо проверить вручную',
        };
      case 'Остались «лишние» части':
        return {
          content: verificationCode,
          theme: 'warning',
          tooltip: 'always',
          tooltipText: 'Необходимо проверить вручную',
        };
      case 'Адрес пустой или заведомо «мусорный»':
        return { content: verificationCode, theme: 'danger' };
      case 'Есть альтернативные варианты':
        return {
          content: verificationCode,
          theme: 'common-alt',
          tooltip: 'always',
          tooltipText: 'Необходимо проверить вручную',
        };
      default:
        return {
          content: 'Неизвестно',
          theme: 'common-alt',
        };
    }
  }
}
