import { InjectionKey } from 'vue';
import { ApiPersonDetailPledgeMemberInfoRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailPledgeMemberInfoRecord';

export interface IPersonPledgesRegistriesScoringsPledgeholdersFormatter {
  getPledgeholdersFormattedNames(
    pledgeholders: Array<ApiPersonDetailPledgeMemberInfoRecord>,
  ): string;
}

export const IPersonPledgesRegistriesScoringsPledgeholdersFormatterKey: InjectionKey<IPersonPledgesRegistriesScoringsPledgeholdersFormatter> =
  Symbol('IPersonPledgesRegistriesScoringsPledgeholdersFormatterKey');
