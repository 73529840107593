import {
  IRegulationCheckRootTypeFormatterService
} from '@/modules/regulationsCheck/common/services/regulationCheckRootTypeFormatterService/regulationCheckRootTypeFormatterService.interfaces';
import {
  RegulationsCheckRootType
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckRootType';
import { injectable } from 'inversify';

@injectable()
export default class RegulationCheckRootTypeFormatterService implements IRegulationCheckRootTypeFormatterService {
  format(rootType: RegulationsCheckRootType): string {
    switch (rootType) {
      case RegulationsCheckRootType.Auto: return 'Автоматическая';
      case RegulationsCheckRootType.Manual: return 'Ручная';
      default: return '';
    }
  }
}
