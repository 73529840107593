import {
  IDataGridCompanyNameFormatterService
} from '@/modules/dataGrid/company/services/formatters/dataGridCompanyNameFormatterService/dataGridCompanyNameFormatterService.interfaces';
import { ApiGridUlRecord } from '@/modules/dataGrid/company/types/api/apiGridUlRecord';
import { injectable } from 'inversify';

@injectable()
export default class DataGridCompanyNameFormatterService implements IDataGridCompanyNameFormatterService {
  format(company: ApiGridUlRecord): string {
    return company.shortName || company.inn || company.ogrn || '';
  }
}
