import { injectable } from 'inversify';
import { ApiPersonDetailOkRecordRelativeItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordRelativeItem';
import { ApiPersonDetailOkRecordUserItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordUserItem';
import { IPersonSocialNetworkAdditionalInfoOkEntitiesMappingService } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkEntitiesMappingService.interface';

@injectable()
export default class PersonSocialNetworkAdditionalInfoOkEntitiesMappingService
  implements IPersonSocialNetworkAdditionalInfoOkEntitiesMappingService
{
  mapListDataByEntity(
    listEntity: Array<any>,
    entity: string,
  ): Array<ApiPersonDetailOkRecordRelativeItem | ApiPersonDetailOkRecordUserItem> {
    return listEntity.flatMap((listItem) => listItem[entity]);
  }
}
