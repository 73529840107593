import { injectable } from 'inversify';
import { ISearchConceptService } from '@/modules/searchConcept/common/services/searchConceptService.interface';
import { SearchConceptCategoryTypes } from '@/modules/searchConcept/common/types/searchConceptCategoryTypes';

export function getSearchConceptCategory(type: string): SearchConceptCategoryTypes {
  switch (type) {
    case 'companyRoot.phone.values':
      return SearchConceptCategoryTypes.companyPhone;
    case 'companyRoot.relatedPersonsAddress.values':
      return SearchConceptCategoryTypes.companyRelated;
    case 'companyRoot.address.values':
      return SearchConceptCategoryTypes.companyAddress;
    case 'personRoot.addresses.values':
      return SearchConceptCategoryTypes.personAddress;
    case 'personRoot.snilses.values':
      return SearchConceptCategoryTypes.personOthers;
    case 'personRoot.jobCompanyNames.values':
      return SearchConceptCategoryTypes.personJob;
    case 'personRoot.tags.values':
      return SearchConceptCategoryTypes.personTag;
    case 'personRoot.negativeInfoSource.values':
      return SearchConceptCategoryTypes.personNegative;
    case 'personRoot.phone.values':
      return SearchConceptCategoryTypes.personPhone;
    default:
      return SearchConceptCategoryTypes.personOthers;
  }
}

@injectable()
export default class SearchConceptService implements ISearchConceptService {
  getServiceConceptCategory(value: string): string {
    const searchConceptCategory = getSearchConceptCategory(value);
    switch (searchConceptCategory) {
      case SearchConceptCategoryTypes.companyPhone:
        return 'телефоны';
      case SearchConceptCategoryTypes.companyRelated:
        return 'связанные с компанией';
      case SearchConceptCategoryTypes.companyAddress:
        return 'адреса компании';
      case SearchConceptCategoryTypes.personAddress:
        return 'адреса персон';
      case SearchConceptCategoryTypes.personOthers:
        return 'другое';
      case SearchConceptCategoryTypes.personJob:
        return 'работа';
      case SearchConceptCategoryTypes.personTag:
        return 'упоминания';
      case SearchConceptCategoryTypes.personNegative:
        return 'негатив';
      case SearchConceptCategoryTypes.personPhone:
        return 'телефоны';
      default:
        return 'другое';
    }
  }
}
