import { injectable } from 'inversify';
import { IAdministrationCompaniesTypesMappingService } from '@/modules/administration/services/mappers/administrationCompaniesTypesMappingService.interface';
import { ApiUlListDataItemCompact } from '@/modules/lists/types/api/ul/apiUlListDataItem';
import { ApiUlListUpdateRequest } from '@/modules/lists/types/api/ul/apiUlListUpdateRequest';

@injectable()
export default class AdministrationCompaniesTypesMappingService
  implements IAdministrationCompaniesTypesMappingService
{
  map(item: ApiUlListDataItemCompact): ApiUlListUpdateRequest {
    return {
      id: item.id,
      name: item.displayName,
      description: item.description,
    };
  }
}
