<template>
  <span class="ui-tag-icon" :class="tagIconWrapperModifiers" v-if="hasContent">
    <UIIcon :name="name" class="ui-tag-icon__icon" :class="tagIconModifiers" />
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import UIIcon from '@/ui-components/UIIcon/UIIcon.vue';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';

interface Props {
  name?: SystemIconsNames
  clickable?: boolean
  theme?: string
}

const props = withDefaults(defineProps<Props>(), {
  clickable: false,
});

const hasContent = computed(() => props.name != undefined);

const tagIconWrapperModifiers = computed(() => ({
  'ui-tag-icon--clickable': props.clickable,
}));

const tagIconModifiers = computed(() => {
  switch (props.theme) {
    case 'success':
      return 'ui-tag-icon__icon--success';
    case 'error':
      return 'ui-tag-icon__icon--error';
    case 'warning':
      return 'ui-tag-icon__icon--warning';
    default:
      return '';
  }
});
</script>

<style lang="scss">
.ui-tag-icon {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: calc(-1 * var(--ui-tag-h-offset));
  padding: var(--ui-tag-v-offset);

  &.ui-tag-icon--clickable {
    border-radius: var(--border-radius-100);
    cursor: pointer;
  }
}

.ui-tag-icon__icon {
  width: 20px;
  height: 20px;

  &.ui-tag-icon__icon--success {
    color: var(--color-green-3);
  }
  &.ui-tag-icon__icon--error {
    color: var(--color-red-1);
  }
  &.ui-tag-icon__icon--warning {
    color: var(--color-orange-3);
  }
}
</style>
