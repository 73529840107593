import { DaDataEndpointKey } from '@/modules/common/endpoints/daDataEndpoint.interfaces';
import { IDaDataService } from '@/modules/dadata/services/DaDataApiService.interfaces';
import { DaDataAddressSuggestionsResponse } from '@/modules/dadata/types/api/dadataAddressSuggestionsResponse';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class DaDataApiService implements IDaDataService {
  constructor(@inject(DaDataEndpointKey as symbol) private daDataEndpoint: AxiosInstance) {}

  getAddressSuggestions(
    query: string,
    quantityOfResponses = 5,
  ): Promise<AxiosResponse<DaDataAddressSuggestionsResponse>> {
    return this.daDataEndpoint.post(
      'suggestions/api/4_1/rs/suggest/address',
      JSON.stringify({ query, count: quantityOfResponses, locations: [{ country: '*' }] }),
    );
  }
}
