import { InjectionKey } from 'vue';
import { UserRole } from '@/modules/rights/type/userRoleEnum';

export interface IUserRoleFormatterService {
  format(value: UserRole): string;
}

export const IUserRoleFormatterServiceKey: InjectionKey<IUserRoleFormatterService> = Symbol(
  'IUserRoleFormatterService',
);
