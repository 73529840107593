import {
  UseDxTagConfig
} from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';
import {
  IRegulationChecksDecisionTagsFactory
} from '@/modules/regulationsCheck/common/factories/regulationChecksDecisionTagsFactory/regulationChecksDecisionTagsFactory.interfaces';
import {
  IRegulationChecksDecisionFormatterService, IRegulationChecksDecisionFormatterServiceKey
} from '@/modules/regulationsCheck/common/services/regulationChecksDecisionFormatterService/regulationChecksDecisionFormatterService.interfaces';
import {
  RegulationsCheckDecision
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckDecision';
import { inject, injectable } from 'inversify';

@injectable()
export default class RegulationChecksDecisionTagsFactory implements IRegulationChecksDecisionTagsFactory {
  constructor(
    @inject(IRegulationChecksDecisionFormatterServiceKey as symbol) private decisionFormatter: IRegulationChecksDecisionFormatterService
  ) { }

  getTag(decision?: RegulationsCheckDecision): UseDxTagConfig | undefined {
    const content = this.decisionFormatter.format(decision);

    switch (decision) {
      case RegulationsCheckDecision.Declined: return { theme: 'danger', content }
      case RegulationsCheckDecision.Agreed: return { theme: 'success', content }
      case RegulationsCheckDecision.AgreedWithComment: return { theme: 'warning', content }
    }
  }
}
