import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import DataGridPersonNameFormatterService from '@/modules/dataGrid/person/services/formatters/dataGridPersonNameFormatterService/dataGridPersonNameFormatterService';
import { IDataGridPersonNameFormatterServiceKey } from '@/modules/dataGrid/person/services/formatters/dataGridPersonNameFormatterService/dataGridPersonNameFormatterService.interfaces';
import PersonProfileToColumnInfoMapper from '@/modules/dataGrid/person/services/mappers/personProfileToColumnInfoMapper/personProfileToColumnInfoMapper';
import { IPersonProfileToColumnInfoMapperKey } from '@/modules/dataGrid/person/services/mappers/personProfileToColumnInfoMapper/personProfileToColumnInfoMapper.interfaces';
import PersonDataGridBatchLoaderService from '@/modules/dataGrid/person/services/personDataGridBatchLoaderService';
import { IPersonDataGridBatchLoaderServiceKey } from '@/modules/dataGrid/person/services/personDataGridBatchLoaderService.interfaces';
import PersonDataGridService from '@/modules/dataGrid/person/services/personDataGridService';
import { IPersonDataGridServiceKey } from '@/modules/dataGrid/person/services/personDataGridService.interfaces';
import { IPersonGridToRegulationCheckTargetMapperKey } from '@/modules/dataGrid/person/services/mappers/personGridToRegulationCheckTargetMapper/personGridToRegulationCheckTargetMapper.interfaces';
import PersonGridToRegulationCheckTargetMapper from '@/modules/dataGrid/person/services/mappers/personGridToRegulationCheckTargetMapper/personGridToRegulationCheckTargetMapper';

export function bindDataGridPersonService(bind: IContainerBinder) {
  bind(IPersonProfileToColumnInfoMapperKey).to(PersonProfileToColumnInfoMapper);
  bind(IPersonDataGridServiceKey).to(PersonDataGridService);
  bind(IPersonDataGridBatchLoaderServiceKey).to(PersonDataGridBatchLoaderService);
  bind(IDataGridPersonNameFormatterServiceKey).to(DataGridPersonNameFormatterService);
  bind(IPersonGridToRegulationCheckTargetMapperKey).to(PersonGridToRegulationCheckTargetMapper);
}
