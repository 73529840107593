import { AccessScope } from '@/modules/rights/type/AccessScope';
import { InjectionKey } from 'vue';
import { AccessScopeEnum } from '@/modules/rights/type/AccessScopeEnum';

export interface IAccessScopeService {
  setAccessScope(roles: Array<string>): AccessScope;
  checkHasScope(accessScopes: Array<AccessScopeEnum>): boolean;
}

export const IAccessScopeServiceKey: InjectionKey<IAccessScopeService> =
  Symbol('IAccessScopeService');
