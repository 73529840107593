import { InjectionKey } from 'vue';
import { ReportTypeInput } from '@/modules/reports/common/types/ReportTypeInput';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';

export interface IReportsTypeService {
  getIconByType(reportType: ReportTypeInput): SystemIconsNames;
}

export const IReportsTypeServiceKey: InjectionKey<IReportsTypeService> =
  Symbol('IReportsTypeServiceKey');
