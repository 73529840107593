import {
  IEnvironmentVariables, IEnvironmentVariablesKey,
} from '@/modules/common/settings/environmentVariables.interfaces';
import { IUiSettings } from '@/modules/common/settings/uiSettings.interfaces';
import { DashboardVersion } from '@/modules/common/types/settings/dashboardVersion';
import { UiVersions } from '@/modules/common/types/settings/uiVersions';
import { inject, injectable } from 'inversify';

@injectable()
export default class UiSettings implements IUiSettings {
  constructor(
    @inject(IEnvironmentVariablesKey as symbol) private environmentVariables: IEnvironmentVariables,
  ) {}

  get version(): UiVersions {
    switch (this.environmentVariables.VUE_APP_UI_VERSION) {
      case 'v2':
        return UiVersions.v2;
      default:
        return UiVersions.v1;
    }
  }

  get dashboardVersion(): DashboardVersion {
    switch (this.environmentVariables.VUE_APP_DASHBOARD_VERSION?.toLowerCase()) {
      case 'v2':
        return DashboardVersion.v2;
      default:
        return DashboardVersion.v1;
    }
  }
}
