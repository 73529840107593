import { InjectionKey } from 'vue';
import { ApiPersonDetailPassportsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailPassportsRecord';
import { ApiPersonDetailInnPassportAccordanceChecks } from '@/modules/dossier/person/common/types/api/apiPersonDetailInnPassportAccordanceChecks';
import { ApiPersonDetailInnPassportAccordanceCheckRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailInnPassportAccordanceCheckRecord';

export interface IPersonPassportInnCheckingParserService {
  parse(
    innChecksList: ApiPersonDetailInnPassportAccordanceChecks,
    passportItem: ApiPersonDetailPassportsRecord,
  ): ApiPersonDetailInnPassportAccordanceCheckRecord | undefined;
}

export const IPersonPassportInnCheckingParserServiceKey: InjectionKey<IPersonPassportInnCheckingParserService> =
  Symbol('IPersonPassportInnCheckingParserServiceKey');
