import flattenedRoutes from '@/modules/router/general/configuration/flattenedRoutes';
import type Middleware from '@/modules/router/common/types/middleware';
import { RouterUtils } from '@/modules/router/common/utils/routerUtils';

const accessMiddleware: Middleware = ({ to, platformType }) => {
  const route =
    flattenedRoutes.find((r) => r.path == to.path) ??
    flattenedRoutes.find((r) => RouterUtils.matchesName(r, to.name));

  if (route == undefined) {
    return;
  }

  const isRouteResolved = RouterUtils.checkAccess(platformType, route);

  const homePageName = RouterUtils.getHomePageName(platformType);
  if (!isRouteResolved && to.name != homePageName) {
    return { name: homePageName };
  }
};

export default accessMiddleware;
