import { InjectionKey } from 'vue';
import { DossierRoutingType } from '@/modules/dossier/common/types/DossierRoutingType';
import { RiskDetailRateParameter } from '@/modules/regulationsCheck/common/types/riskDetailRateParameter';
import { RegulationsCheckCheckRequiredIndicatorsTableRow } from '@/modules/regulationsCheck/common/types/regulationsCheckCheckRequiredIndicatorsTableRow';

export interface IRegulationsCheckCheckRequiredIndicatorsMappingService {
  sortCheckRequiredIndicators(
    markers: Array<RegulationsCheckCheckRequiredIndicatorsTableRow>,
  ): Array<RegulationsCheckCheckRequiredIndicatorsTableRow>;
  mapCheckRequiredIndicators(
    markers: Array<RiskDetailRateParameter>,
    target: DossierRoutingType,
  ): Array<RegulationsCheckCheckRequiredIndicatorsTableRow>;
}

export const IRegulationsCheckCheckRequiredIndicatorsMappingServiceKey: InjectionKey<IRegulationsCheckCheckRequiredIndicatorsMappingService> =
  Symbol('IRegulationsCheckCheckRequiredIndicatorsMappingServiceKey');
