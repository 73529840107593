import { injectable } from 'inversify';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { PersonIntegrityTrustIndicatorColors } from '@/modules/dossier/person/personIntegrityScoring/types/personIntegrityTrustIndicatorColors';
import { PersonIntegrityIndicator } from '@/modules/dossier/person/personIntegrityScoring/types/personIntegrityScoringIndicator';
import { IPersonIntegrityScoringsIndicatorService } from './personIntegrityScoringIndicatorService.interface';

@injectable()
export default class PersonIntegrityScoringIndicatorService
  implements IPersonIntegrityScoringsIndicatorService
{
  // eslint-disable-next-line class-methods-use-this
  getIntegrityScoringIndicator(indicatorColor: string): PersonIntegrityIndicator {
    switch (indicatorColor) {
      case PersonIntegrityTrustIndicatorColors.GREEN:
        return {
          indicatorColor: PersonIntegrityTrustIndicatorColors.GREEN,
          indicatorDescription: 'Инциденты отсутствуют',
          indicatorIcon: SystemIconsNames.checkSuccessOutlineIcon,
        };
      case PersonIntegrityTrustIndicatorColors.HIGH_RED:
        return {
          indicatorColor: PersonIntegrityTrustIndicatorColors.HIGH_RED,
          indicatorDescription: 'Найдено 3 и более инцидентов',
          indicatorIcon: SystemIconsNames.triangleExclamation,
        };
      case PersonIntegrityTrustIndicatorColors.RED:
        return {
          indicatorColor: PersonIntegrityTrustIndicatorColors.RED,
          indicatorDescription: 'Найден 1 либо 2 инцидента',
          indicatorIcon: SystemIconsNames.triangleExclamation,
        };
      default:
        return {
          indicatorColor: PersonIntegrityTrustIndicatorColors.RED,
          indicatorDescription: 'Информация отсутствует',
          indicatorIcon: SystemIconsNames.triangleExclamation,
        };
    }
  }
}
