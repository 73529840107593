import { WeightMarkerItem } from '@/modules/administration/types/weightMarkerItem';

export type AdminMarkersState = {
  markers: WeightMarkerItem[];
};

export enum MutationTypesEnum {
  SET_MARKERS = 'SET_MARKERS',
}

export type Mutations<S = AdminMarkersState> = {
  [MutationTypesEnum.SET_MARKERS](state: S, payload: Pick<AdminMarkersState, 'markers'>): void;
};

export enum ActionTypesEnum {
  GET_MARKERS = 'GET_MARKERS',
}
