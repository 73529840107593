import { inject, injectable } from 'inversify';
import { IReportsApiServiceInterface } from '@/modules/reports/common/services/reportsApiService.interface';
import { ReportParentType } from '@/modules/reports/common/types/ReportParentType';
import { IApiSettings, IApiSettingsKey } from '@/modules/common/settings/apiSettings.interfaces';
import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { AxiosInstance, AxiosResponse } from 'axios';
import { GuidUtils } from '@/modules/common/utils/guidUtils';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { ReportInterruptResponseApi } from '@/modules/reports/common/types/reportInterruptResponseApi';
import { ReportMigrateResponseApi } from '@/modules/reports/desktop/types/reportMigrateResponseApi';
import { useReportsGenerationTaskCancelledEvent } from '@/modules/reports/common/events/useReportsGenerationTaskCancelledEvent';

const { triggerGenerationTaskCancelledEvent } = useReportsGenerationTaskCancelledEvent();

@injectable()
export default class ReportsApiService implements IReportsApiServiceInterface {
  constructor(
    @inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance,
    @inject(IApiSettingsKey as symbol) private apiSettings: IApiSettings,
  ) {}

  // Функция формирует ссылку на json либо pdf. Тип файла зависит от входящего id
  // Для pdf на вход нужно отдать reportInfoId. Для json - reportInfoJsonId
  getReportDownloadUrl(reportInfoId?: string | null): string | undefined {
    if (GuidUtils.isEmptyOrNull(reportInfoId)) {
      return undefined;
    }

    return `${this.apiSettings.v1ApiUrl}easy-reports/result?reportInfoId=${reportInfoId}`;
  }

  async cancelReportGenerationTask(
    taskId: string,
  ): Promise<AxiosResponse<ApiResponse<ReportInterruptResponseApi>>> {
    const url = '/easy-reports/interrupt';
    const payload = { taskId };
    const response = await this.apiEndpoint.post<ApiResponse<ReportInterruptResponseApi>>(
      url,
      payload,
    );

    triggerGenerationTaskCancelledEvent(taskId);

    return response;
  }

  async cancelReportGenerationTasks(
    taskIds: string[],
  ): Promise<Array<AxiosResponse<ApiResponse<ReportInterruptResponseApi>>>> {
    const interruptionTasks = taskIds.map(taskId => this.cancelReportGenerationTask(taskId));

    // TODO SNSIDE_UI-1159 Не учитывается консистентность данных в случае ошибки отмены одной из тасок (еще нужно валидировать taskId как guid)
    return await Promise.all(interruptionTasks);
  }

  // Создает досье из реквизитов отчета. Посмотреть досье можно по parentId отчета
  async migrateReportToSystem(
    originalTaskId: string,
    parentId: string,
    parentType: ReportParentType,
  ): Promise<AxiosResponse<ApiResponse<ReportMigrateResponseApi>>> {
    const url = '/easy-reports/migrate';
    const payload = { originalTaskId, parentId, parentType };
    const response = await this.apiEndpoint.post<ApiResponse<ReportMigrateResponseApi>>(
      url,
      payload,
    );

    return response;
  }
}
