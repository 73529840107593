import RouteNames from '@/modules/router/common/types/routeNames';
import RouteRecord from '@/modules/router/common/types/routeRecord';

export const standaloneGraphRoutes : RouteRecord[] = [
  {
    path: '/graph-visualization/:id?',
    name: RouteNames.GRAPH_VISUALIZATION,
    props: true,
    component: () => import('@/modules/graph/standaloneUnity/views/StandaloneUnityGraphView.vue'),
  }
]
