import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import RouteNames from '@/modules/router/common/types/routeNames';
import { useRouter } from 'vue-router';

const defaultConfig = {
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
};

const DefaultAPIInstance = axios.create(defaultConfig);

export const onRejectedResponse = async (payload: AxiosError) => {
  const { response } = payload;

  if (response?.status === 302) {
    await useRouter().push({ name: RouteNames.AUTH_PAGE });
    Cookies.remove('isAuth');
  }

  return Promise.reject(payload);
};

DefaultAPIInstance.interceptors.response.use(
  (r) => r,
  (error) => onRejectedResponse(error),
);

export default DefaultAPIInstance;
