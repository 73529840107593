import { injectable } from 'inversify';
import { ICarNumberRequisiteMatchingService } from '@/modules/requisites/services/matchers/carNumberRequisiteMatchingService.interfaces';

@injectable()
export class CarNumberRequisiteMatchingService implements ICarNumberRequisiteMatchingService {
  match(value: string): boolean {
    const regExpCarNumberInclude =
      /^([АВЕКМНОРСТУХABEKMHOPCTYX]{1}\d{0,3}[АВЕКМНОРСТУХABEKMHOPCTYX]{0,2})(\d{0,3})$/;
    const result = value.toUpperCase().match(regExpCarNumberInclude);
    return Array.isArray(result) && value.length <= 9;
  }

  matchPartial(value: string): boolean {
    const regExpCarNumberInclude =
      /^([АВЕКМНОРСТУХABEKMHOPCTYX]{1}\d{3}[АВЕКМНОРСТУХABEKMHOPCTYX]{2})(\d{2,3})$/;
    const result = value.match(regExpCarNumberInclude);
    return Array.isArray(result);
  }
}
