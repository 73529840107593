import { IImportFileCompanyServiceKey } from '@/modules/import/services/importFileCompanyService.interfaces';
import { IImportFilePersonServiceKey } from '@/modules/import/services/importFilePersonService.interfaces';
import { IDataImportServiceKey } from '@/modules/import/services/dataImportService.interfaces';
import { IImportFileZupServiceKey } from '@/modules/import/services/importFileZupService.interface';
import { IImportFileOnecServiceKey } from '@/modules/import/services/importFileOnecService.interface';
import { IImportFilePhotosServiceKey } from '@/modules/import/services/importFilePhotosService.interface';
import { IIdentifiersMappingServiceKey } from '@/modules/import/services/identifiersMappingService.interface';
import ImportFileCompanyService from '@/modules/import/services/importFileCompanyService';
import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import ImportFilePersonService from '@/modules/import/services/importFilePersonService';
import DataImportService from '@/modules/import/services/dataImportService';
import ImportFileZupService from '@/modules/import/services/importFileZupService';
import ImportFileOnecService from '@/modules/import/services/importFileOnecService';
import ImportFilePhotosService from '@/modules/import/services/importFilePhotosService';
import IdentifiersMappingService from '@/modules/import/services/identifiersMappingService';
import { IManualImportCreatePersonModelMapperKey } from '@/modules/import/services/mappers/manualImportCreatePersonModelMapper/manualImportCreatePersonModelMapper.interfaces';
import ManualImportCreatePersonModelMapper from '@/modules/import/services/mappers/manualImportCreatePersonModelMapper/manualImportCreatePersonModelMapper';
import { IManualImportCreateCompanyModelMapperKey } from '@/modules/import/services/mappers/manualImportCreateCompanyModelMapper/manualImportCreateCompanyModelMapper.interfaces';
import IManualImportCreateCompanyModelMapper from '@/modules/import/services/mappers/manualImportCreateCompanyModelMapper/manualImportCreateCompanyModelMapper';
import MassImportPersonIdentifierMapperService from '@/modules/import/modules/massImport/modules/person/services/massImportPersonIdentifierMapperService/massImportPersonIdentifierMapperService';
import { IMassImportPersonIdentifierMapperServiceKey } from '@/modules/import/modules/massImport/modules/person/services/massImportPersonIdentifierMapperService/massImportPersonIdentifierMapperService.interfaces';
import MassImportCompanyIdentifierMapperService from '@/modules/import/modules/massImport/modules/company/services/massImportCompanyIdentifierMapperService/massImportCompanyIdentifierMapperService';
import { IMassImportCompanyIdentifierMapperServiceKey } from '@/modules/import/modules/massImport/modules/company/services/massImportCompanyIdentifierMapperService/massImportCompanyIdentifierMapperService.interfaces';
import MassImportPersonIdentifiersParsingService from '@/modules/import/modules/massImport/modules/person/services/massImportPersonIdentifiersParsingService/massImportPersonIdentifiersParsingService';
import { IMassImportPersonIdentifiersParsingServiceKey } from '@/modules/import/modules/massImport/modules/person/services/massImportPersonIdentifiersParsingService/massImportPersonIdentifiersParsingService.interfaces';
import MassImportCompanyIdentifiersParsingService from '@/modules/import/modules/massImport/modules/company/services/massImportCompanyIdentifiersParsingService/massImportCompanyIdentifiersParsingService';
import { IMassImportCompanyIdentifiersParsingServiceKey } from '@/modules/import/modules/massImport/modules/company/services/massImportCompanyIdentifiersParsingService/massImportCompanyIdentifiersParsingService.interfaces';
import MassImportIdentifiersParsingService from '@/modules/import/modules/massImport/services/massImportIdentifiersParsingService/massImportIdentifiersParsingService';
import { IMassImportIdentifiersParsingServiceKey } from '@/modules/import/modules/massImport/services/massImportIdentifiersParsingService/massImportIdentifiersParsingService.interfaces';

export function bindImportServices(bind: IContainerBinder) {
  bind(IImportFileCompanyServiceKey).to(ImportFileCompanyService);
  bind(IImportFilePersonServiceKey).to(ImportFilePersonService);
  bind(IDataImportServiceKey).to(DataImportService);
  bind(IImportFileZupServiceKey).to(ImportFileZupService);
  bind(IImportFileOnecServiceKey).to(ImportFileOnecService);
  bind(IImportFilePhotosServiceKey).to(ImportFilePhotosService);
  bind(IIdentifiersMappingServiceKey).to(IdentifiersMappingService);
  bind(IManualImportCreatePersonModelMapperKey).to(ManualImportCreatePersonModelMapper);
  bind(IManualImportCreateCompanyModelMapperKey).to(IManualImportCreateCompanyModelMapper);
  bind(IMassImportIdentifiersParsingServiceKey).to(MassImportIdentifiersParsingService);
  bind(IMassImportPersonIdentifierMapperServiceKey).to(MassImportPersonIdentifierMapperService);
  bind(IMassImportCompanyIdentifierMapperServiceKey).to(MassImportCompanyIdentifierMapperService);
  bind(IMassImportPersonIdentifiersParsingServiceKey).to(MassImportPersonIdentifiersParsingService);
  bind(IMassImportCompanyIdentifiersParsingServiceKey).to(
    MassImportCompanyIdentifiersParsingService,
  );
}
