import { injectable } from 'inversify';
import { AnalyticsTypes } from '@/modules/dossier/person/financialScorings/types/financialAnalyticsTypes';
import { IAnalyticsTypeDescriptionFactory } from './analyticsTypeDescriptionFactory.interface';

@injectable()
export default class AnalyticsTypeDescriptionFactory implements IAnalyticsTypeDescriptionFactory {
  getAnalyticScoreTypeDescription(type: AnalyticsTypes): string[] {
    switch (type) {
      case AnalyticsTypes.FICO:
        return ['Cмешанная скоринговая модель оценки', 'кредитоспособности физического лица'];
      case AnalyticsTypes.OKB:
        return [
          'Cкоринговая модель оценки кредитоспособности',
          'физического лица Объединенного бюро кредитных историй',
        ];
      default:
        return ['Cмешанная скоринговая модель', 'оценки кредитоспособности физического лица'];
    }
  }
}
