import { useAuthInterceptor } from '@/interceptors/authInterceptor';
import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import {
  IEnvironmentSettings,
  IEnvironmentSettingsKey,
} from '@/modules/common/settings/environmentSettings.interfaces';
import { AppMode } from '@/modules/common/types/appMode';
import { useRoutesAccess } from '@/modules/router/common/composables/useRoutesAccess';
import { IAppModeRouterFactory } from '@/modules/router/common/factories/appModeRouterFactory.interfaces';
import BaseRouterFactory from '@/modules/router/common/factories/baseRouterFactory';
import { routes } from '@/modules/router/general/configuration/routes';
import { AxiosInstance } from 'axios';
import { inject, injectable } from 'inversify';
import { Router, RouteRecordRaw } from 'vue-router';

@injectable()
export default class GeneralRouterFactory
  extends BaseRouterFactory
  implements IAppModeRouterFactory
{
  appMode = AppMode.general;

  constructor(
    @inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance,
    @inject(IEnvironmentSettingsKey as symbol) envSettings: IEnvironmentSettings,
  ) {
    super(envSettings);
  }

  getRouter(): Router {
    const router = super.getRouter();

    useRoutesAccess(router);
    useAuthInterceptor(router, this.apiEndpoint);

    return router;
  }

  protected getRoutes(): RouteRecordRaw[] {
    return routes;
  }
}
