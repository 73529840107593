import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { GridOutput } from '@/modules/common/types/api/gridOutput';
import { ApiGridColumnInput } from '@/modules/dataGrid/common/types/api/apiGridColumnInput';
import {
  ApiGridFilterColumnValueDistinct
} from '@/modules/dataGrid/common/types/api/apiGridFilterColumnValueDistinct';
import { ApiGridInput } from '@/modules/dataGrid/common/types/api/apiGridInput';
import {
  IPersonDataGridService
} from '@/modules/dataGrid/person/services/personDataGridService.interfaces';
import { ApiGridPersonRecord } from '@/modules/dataGrid/person/types/api/apiGridPersonRecord';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class PersonDataGridService implements IPersonDataGridService {
  constructor(
    @inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance
  ) { }

  getContent(payload: ApiGridInput): Promise<AxiosResponse<ApiResponse<GridOutput<ApiGridPersonRecord>>>> {
    return this.apiEndpoint.post(`/grid/main`, payload);
  }

  getColumnValues(payload: ApiGridColumnInput): Promise<AxiosResponse<ApiResponse<ApiGridFilterColumnValueDistinct[]>>> {
    return this.apiEndpoint.post('/grid/main/column/values', payload);
  }
}
