import { ProcessingSettings } from '@/modules/processingSettings/types/processingSettings';
import { ProcessingSettingsNormalizationModes } from '@/modules/processingSettings/types/processingSettingsNormalizationModes';
import { ProcessingSettingsCachingModes } from '@/modules/processingSettings/types/processingSettingsCachingModes';

export module processingSettingsDefaultConfig {
  export const defaultSettings: ProcessingSettings = {
    normalizationMode: ProcessingSettingsNormalizationModes.dadataSuggestions,
    cachingMode: ProcessingSettingsCachingModes.with,
    dadataApiToken: null,
  };
}
