import {
  IDxCellFormatter
} from '@/modules/devExtreme/export/common/services/dxCellFormatter/dxCellFormatter.interfaces';
import { Cell, PdfDataGridCell } from 'devextreme/pdf_exporter';
import { InjectionKey } from 'vue';

export interface IDxPdfCellFormatter extends IDxCellFormatter<Cell, PdfDataGridCell> {
}

export const IDxPdfCellFormatterKey: InjectionKey<IDxPdfCellFormatter> = Symbol('IDxPdfCellFormatter');
