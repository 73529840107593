import { injectable } from 'inversify';
import { IPersonCarsNormalizationService } from '@/modules/dossier/person/vehicles/cars/services/personCarsNormalizationService.interfaces';
import { ApiPersonDetailVehiclesRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVehiclesRecord';
import { NormalizedApiPersonDetailVehiclesRecord } from '@/modules/dossier/person/vehicles/cars/types/normalizedApiPersonDetailVehiclesRecord';

@injectable()
export default class PersonCarsNormalizationService implements IPersonCarsNormalizationService {
  normalize(item: ApiPersonDetailVehiclesRecord): NormalizedApiPersonDetailVehiclesRecord {
    return {
      id: item.id,
      brand: item.brand ?? '',
      model: item.model ?? '',
      source: item.source ?? '',
      name: item.name ?? '',
      nameRu: item.nameRu ?? '',
      verificationCode: item.verificationCode ?? '',
      modelGeneration: item.modelGeneration ?? '',
      modelCode: item.modelCode ?? '',
      color: item.color ?? '',
      bodyType: item.bodyType ?? '',
      doorsCount: item.doorsCount,
      buildCountry: item.buildCountry ?? '',
      engineVolume: item.engineVolume ?? '',
      enginePower: item.enginePower ?? '',
      engineNumber: item.engineNumber ?? '',
      gibddCarTypeOrig: item.gibddCarTypeOrig ?? '',
      gibddCheckCharacterOrig: item.gibddCheckCharacterOrig ?? '',
      manufacturer: item.manufacturer ?? '',
      manufactureDateStart: item.manufactureDateStart ?? '',
      manufactureDateStartStr: item.manufactureDateStartStr ?? '',
      manufacturerAddress: item.manufacturerAddress ?? '',
      manufacturerAdditionalInfo: item.manufacturerAdditionalInfo ?? '',
      carNameMixed: item.carNameMixed ?? '',
      carNumber: item.carNumber ?? '',
      sourceType: item.sourceType ?? '',
      vin: item.vin ?? '',
      year: item.year,
      ownerShipPeriod: item.ownerShipPeriod,
      ownerType: item.ownerType ?? '',
      ownerRegistrationType: item.ownerRegistrationType ?? '',
      regDate: item.regDate ?? '',
      regDateStr: item.regDateStr ?? '',
      deRegDate: item.deRegDate ?? '',
      deRegDateStr: item.deRegDateStr ?? '',
      ownerPeriod: item.ownerPeriod ?? '',
      city: item.city ?? '',
      distance: item.distance,
      price: item.price,
      contactFace: item.contactFace ?? '',
      pts: item.pts ?? '',
      sts: item.sts ?? '',
      registrationOnCompany: item.registrationOnCompany ?? '',
      foundPhone: item.foundPhone ?? '',
      foundPhones: item.foundPhones ?? '',
      foundFirstname: item.foundFirstname ?? '',
      foundLastname: item.foundLastname ?? '',
      foundPatronymic: item.foundPatronymic ?? '',
      relevanceDate: item.relevanceDate ?? '',
      relevanceDateStr: item.relevanceDateStr ?? '',
      previousCarNumber: item.previousCarNumber ?? '',
      legalPersonOwner: item.legalPersonOwner ?? '',
      legalPersonOwnerInn: item.legalPersonOwnerInn ?? '',
      legalPersonPolicyHolder: item.legalPersonPolicyHolder ?? '',
      legalPersonPolicyHolderInn: item.legalPersonPolicyHolderInn ?? '',
      legalPersonVehicleAssociated: item.legalPersonVehicleAssociated ?? '',
      purposeOfUse: item.purposeOfUse ?? '',
      vehiclePassportIssueDate: item.vehiclePassportIssueDate ?? '',
      vehiclePassportIssueDateStr: item.vehiclePassportIssueDateStr ?? '',
      insuranceCompany: item.insuranceCompany ?? '',
      insurancePolicy: item.insurancePolicy ?? '',
      insuranceStatus: item.insuranceStatus ?? '',
      managementLimitations: item.managementLimitations ?? '',
      cbm: item.cbm ?? '',
      relatedPersonFullName: item.relatedPersonFullName ?? '',
      relatedPersonBirthDate: item.relatedPersonBirthDate ?? '',
      relatedPersonBirthDateStr: item.relatedPersonBirthDateStr ?? '',
      relatedPersonAddress: item.relatedPersonAddress ?? '',
      relatedPersonPassport: item.relatedPersonPassport ?? '',
      relatedPersonInn: item.relatedPersonInn ?? '',
    };
  }
}
