import { injectable, inject } from 'inversify';
import { IPassportValidationService } from '@/modules/validation/services/passportValidationService.interface';
import {
  IMasksService,
  IMasksServiceKey,
} from '@/modules/masks/services/masksService/masksService.interfaces';
import { passportMask } from '@/modules/validation/configuration/masks/passportMask';
import {
  IPassportNumberValidationServiceKey,
  IPassportNumberValidationService,
} from '@/modules/validation/services/passportNumberValidationService.interface';
import {
  IPassportSeriesValidationServiceKey,
  IPassportSeriesValidationService,
} from '@/modules/validation/services/passportSeriesValidationService.interface';

@injectable()
export default class PassportValidationService implements IPassportValidationService {
  constructor(
    @inject(IPassportNumberValidationServiceKey as symbol)
    private passportNumberValidationService: IPassportNumberValidationService,
    @inject(IPassportSeriesValidationServiceKey as symbol)
    private passportSeriesValidationService: IPassportSeriesValidationService,
    @inject(IMasksServiceKey as symbol) private masksService: IMasksService,
  ) {}

  validate(value: string): boolean {
    const passport = this.masksService.getRawValue(value, passportMask);
    if (passport == undefined) {
      return false;
    }

    const passportSeries = passport.slice(0, 4);
    const passportNumber = passport.slice(4);

    return (
      this.passportSeriesValidationService.validate(passportSeries) &&
      this.passportNumberValidationService.validate(passportNumber)
    );
  }
}
