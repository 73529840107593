import { useInjectable } from '@/hooks/useInjectable';
import { ICompanyListsServiceKey } from '@/modules/lists/services/companyListsService.interfaces';
import { LoadOptions } from 'devextreme/data';
import {
  ActionContext,
  ActionTree,
  MutationTree,
  Module,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
} from 'vuex';
import { RootState } from '@/store/type';
import { ApiUlListDataItem } from '@/modules/lists/types/api/ul/apiUlListDataItem';
import { ActionTypes, CompaniesTypesState, Mutations, MutationTypes } from './types';

const moduleState: CompaniesTypesState = {
  data: [],
};

const mutations: MutationTree<CompaniesTypesState> & Mutations = {
  [MutationTypes.SET_DATA](state, payload) {
    state.data = payload;
  },
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<CompaniesTypesState, RootState>, 'commit'>;

interface Actions {
  [ActionTypes.GET_COMPANIES_TYPES_DATA](
    { commit }: AugmentedActionContext,
    data: LoadOptions,
  ): void;
}

const actions: ActionTree<CompaniesTypesState, RootState> & Actions = {
  async [ActionTypes.GET_COMPANIES_TYPES_DATA]({ commit }, payload: LoadOptions) {
    const companyListsService = useInjectable(ICompanyListsServiceKey);
    let resultData: ApiUlListDataItem[] = [];

    await companyListsService.getLists().then((result) => {
      const {
        data: { data },
      } = result;
      resultData = data.ulLists;
    });

    commit(MutationTypes.SET_DATA, resultData);
  },
};

export type CompaniesTypesStore<S = CompaniesTypesState> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions,
  ): ReturnType<Actions[K]>;
};

export const CompaniesTypesModule: Module<CompaniesTypesState, RootState> = {
  state: moduleState,
  mutations,
  actions,
};
