import SearchConceptOptionGroupsFactory
  from '@/modules/searchConcept/common/factories/searchConceptOptionGroupsFactory';
import {
  IPersonSearchConceptOptionGroupsFactory
} from '@/modules/searchConcept/person/factories/personSearchConceptOptionGroupsFactory.interfaces';
import {
  IPersonSearchConceptOptionsFactory, IPersonSearchConceptOptionsFactoryKey
} from '@/modules/searchConcept/person/factories/personSearchConceptOptionsFactory.interfaces';
import { inject, injectable } from 'inversify';

@injectable()
export default class PersonSearchConceptOptionGroupsFactory extends SearchConceptOptionGroupsFactory implements IPersonSearchConceptOptionGroupsFactory {
  constructor(
    @inject(IPersonSearchConceptOptionsFactoryKey as symbol) personOptionsFactory: IPersonSearchConceptOptionsFactory
  ) {
    super(personOptionsFactory);
  }
}
