import { injectable, inject } from 'inversify';
import { IPersonSocialNetworkAdditionalInfoOkMappingService } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkMappingService.interface';
import {
  IPersonSocialNetworkAdditionalInfoOkEntitiesMappingService,
  IPersonSocialNetworkAdditionalInfoOkEntitiesMappingServiceKey,
} from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkEntitiesMappingService.interface';
import { ApiPersonDetailOkRecordRelativeItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordRelativeItem';
import { ApiPersonDetailOkRecordUserItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordUserItem';
import {
  IPersonSocialNetworkAdditionalInfoOkUserLinkService,
  IPersonSocialNetworkAdditionalInfoOkUserLinkServiceKey,
} from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/parsers/personSocialNetworkAdditionalInfoOkUserLinkService.interface';
import { PersonSocialNetworkAdditionalInfoOkRelativesTableRow } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/types/personSocialNetworkAdditionalInfoOkRelativesTableRow';

@injectable()
export default class PersonSocialNetworkAdditionalInfoOkMappingService
  implements IPersonSocialNetworkAdditionalInfoOkMappingService
{
  constructor(
    @inject(IPersonSocialNetworkAdditionalInfoOkUserLinkServiceKey as symbol)
    private PersonSocialNetworkAdditionalInfoOkUserLinkService: IPersonSocialNetworkAdditionalInfoOkUserLinkService,
    @inject(IPersonSocialNetworkAdditionalInfoOkEntitiesMappingServiceKey as symbol)
    private PersonSocialNetworkAdditionalInfoOkEntitiesMappingService: IPersonSocialNetworkAdditionalInfoOkEntitiesMappingService,
  ) {}

  mapOkRelativesDataTable(
    okRelativeList: Array<ApiPersonDetailOkRecordRelativeItem>,
    entity: string,
  ): Array<PersonSocialNetworkAdditionalInfoOkRelativesTableRow> {
    const entitiesList =
      this.PersonSocialNetworkAdditionalInfoOkEntitiesMappingService.mapListDataByEntity(
        okRelativeList,
        entity,
      );

    return entitiesList.map(
      (item: ApiPersonDetailOkRecordRelativeItem | ApiPersonDetailOkRecordUserItem) => ({
        thumbnail: `${process.env.VUE_APP_BASE_URL}ok-parser/photo/${item.avatarId}/reduced`,
        fullNameWithLink:
          this.PersonSocialNetworkAdditionalInfoOkUserLinkService.getPersonOkUserLink(item),
        dateBirthday: item.birthday,
        city: item.city,
        historyState: item.historicalState,
        interactionIntervals: `${item.interactionStartDate} - ${item.interactionEndDate}`,
      }),
    );
  }
}
