import { ResultTaskNotificationConfig } from '@/modules/common/composables/useTaskNotifications/types/resultTaskNotificationConfig';
import { UseTaskNotificationsConfig } from '@/modules/common/composables/useTaskNotifications/useTaskNotifications.interfaces';
import { useStaticNotification } from '@/modules/notifications/composables/useStaticNotification';

/**
 * Обертка над try/catch с показом уведомлений
 */
export function useTaskNotifications() {
  const { fireNotification } = useStaticNotification();

  function fireSuccessNotification(notificationConfig: ResultTaskNotificationConfig) {
    fireNotification({
      ...notificationConfig,
      status: 'success',
    });
  }

  function fireErrorNotification(notificationConfig?: ResultTaskNotificationConfig) {
    if (notificationConfig == undefined) {
      fireNotification({
        title: 'Ошибка',
        quickSell: 'Операция произошла с ошибкой',
        status: 'error',
      });
      return;
    }

    fireNotification({
      ...notificationConfig,
      status: 'error',
    });
  }

  async function runTask(config: UseTaskNotificationsConfig) {
    try {
      const result = await config.action();
      const notificationConfig = config.success(result);
      fireSuccessNotification(notificationConfig);
      return result;
    } catch (e) {
      const notificationConfig = config.error?.(e);
      console.error(e);
      // TODO Когда появится система баг-репорта здесь надо будет отправлять баг-репорт
      fireErrorNotification(notificationConfig);
      return e;
    }
  }

  return {
    runTask,
  };
}
