import { IApiSettings, IApiSettingsKey } from '@/modules/common/settings/apiSettings.interfaces';
import {
  IEnvironmentSettings,
  IEnvironmentSettingsKey
} from '@/modules/common/settings/environmentSettings.interfaces';
import {
  IEnvironmentVariablesKey
} from '@/modules/common/settings/environmentVariables.interfaces';
import { IRouterFactory } from '@/modules/router/common/factories/routerFactory.interfaces';
import { inject, injectable } from 'inversify';
import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router';

@injectable()
export default abstract class BaseRouterFactory implements IRouterFactory {
  constructor(
    @inject(IEnvironmentSettingsKey as symbol) private envSettings: IEnvironmentSettings
  ) { }

  getRouter(): Router {
    return createRouter({
      history: createWebHistory(this.envSettings.baseUrl),
      routes: this.getRoutes(),
      linkExactActiveClass: 'nav-item__active',
      scrollBehavior() {
        return { top: 0 };
      },
    });
  }

  protected abstract getRoutes(): RouteRecordRaw[]
}
