import { injectable } from 'inversify';
import { PurchasesList } from '@/modules/dossier/company/common/types/detailsCompanyPurchasesList';
import { ICompanyPurchasesService } from '@/modules/dossier/company/common/services/mappings/companyPurchasesService.interface';
import { PurchasesMappedList } from '@/modules/dossier/company/common/types/detailsCompanyPurchasesMappedList';
import {
  CommonProductsGroupedData,
  ExecutionStatusesGroupedData,
  MajorSuppliersAndCustomersGroupedData,
} from '@/modules/dossier/company/common/types/detailsCompanyPurchases';
import { DossierTagData } from '@/modules/common/types/dossierTag';

@injectable()
export default class companyPurchasesService implements ICompanyPurchasesService {
  getPurchasesStatusTagData(status: string): DossierTagData {
    switch (status) {
      case 'Закупка завершена':
      case 'Контракт заключен':
      case 'Исполнение завершено':
        return { theme: 'success', text: status };
      case 'Исполнение прекращено':
      case 'Закупка отменена':
        return { theme: 'danger', text: status };
      case 'Работа комиссии':
        return { theme: 'warning', text: status };
      case 'Подача заявок':
      case 'Исполнение':
        return { theme: 'primary', text: status };
      default:
        return { theme: 'common', text: 'Неизвестно' };
    }
  }

  getPurchasesData(purchases: PurchasesList[]): PurchasesMappedList[] {
    const sortedList = purchases.reduce(
      (acc, curr) => {
        const purchasesTable = {
          fullname: curr.fullname,
          sum: curr.sum,
          purchaseRoleTypeStr: curr.PurchaseRoleTypeStr,
          purchaseRoleType: curr.PurchaseRoleType,
          purchaseName: curr.purchaseName,
          purchaseNr: curr.purchaseNr,
          purchaseTypeStr: curr.purchaseTypeStr,
          currencyCode: curr.currencyCode,
          publishDate: curr.publishDate,
          statusStr: this.getPurchasesStatusTagData(curr.statusStr),
          fzTypeStr: curr.fzTypeStr,
          reason: curr.reason,
        };
        acc[purchasesTable.statusStr.theme].push(purchasesTable);
        return acc;
      },
      { success: [], danger: [], warning: [], common: [], primary: [] } as Record<
        string,
        Array<PurchasesMappedList>
      >,
    );
    //а это точно должно тут сортироваться, а не в хедере таблицы?
    const { success, danger, warning, common, primary } = sortedList;
    return [...success, ...danger, ...warning, ...common, ...primary];
  }

  getMajorSuppliersAndCustomersGroupedData(
    data: PurchasesMappedList[],
  ): MajorSuppliersAndCustomersGroupedData[] {
    return data
      .filter((record) => record.fullname != null)
      .reduce((acc: MajorSuppliersAndCustomersGroupedData[], record) => {
        let group = acc.find(
          (item) =>
            item.fullname === record.fullname &&
            item.purchaseRoleTypeStr === record.purchaseRoleTypeStr,
        );
        if (!group) {
          group = {
            purchaseRoleTypeStr: record.purchaseRoleTypeStr,
            fullname: record.fullname,
            sum: 0,
            count: 0,
          };
          acc.push(group);
        }
        group.sum += record.sum;
        group.count += 1;
        return acc;
      }, [])
      .sort((a, b) => (a.purchaseRoleTypeStr > b.purchaseRoleTypeStr ? -1 : 1));
  }

  getCommonProductsGroupedData(data: PurchasesMappedList[]): CommonProductsGroupedData {
    return data.reduce((acc: CommonProductsGroupedData, record) => {
      if (!acc[record.purchaseName]) {
        acc[record.purchaseName] = {
          purchaseName: record.purchaseName,
          sum: 0,
          count: 0,
        };
      }
      acc[record.purchaseName].sum += record.sum;
      acc[record.purchaseName].count += 1;
      return acc;
    }, {});
  }

  getExecutionStatusesGroupedData(data: PurchasesMappedList[]): ExecutionStatusesGroupedData {
    return data.reduce((acc: ExecutionStatusesGroupedData, record) => {
      if (!acc[record.statusStr.text]) {
        acc[record.statusStr.text] = {
          status: this.getPurchaseStatusTagData(record.statusStr.text),
          count: 0,
        };
      }
      acc[record.statusStr.text].count += 1;
      return acc;
    }, {});
  }

  getPurchaseStatusTagData(status: string): DossierTagData {
    switch (status) {
      case 'Исполнение завершено':
        return { theme: 'common', text: status };
      case 'Закупка завершена':
      case 'Контракт заключен':
        return { theme: 'success', text: status };
      case 'Исполнение прекращено':
      case 'Закупка отменена':
        return { theme: 'danger', text: status };
      case 'Исполнение':
      case 'Подача заявок':
        return { theme: 'primary', text: status };
      case 'Работа комиссии':
        return { theme: 'warning', text: status };
      default:
        return { theme: 'common', text: status };
    }
  }
}
