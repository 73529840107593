import { InjectionKey } from 'vue';
import { ApiPersonDetailPassportsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailPassportsRecord';
import { ApiPersonDetailPassportComplexChecks } from '../../../common/types/api/apiPersonDetailPassportComplexChecks';

export interface IPersonPassportComplexCheckingParserService {
  parse(
    complexChecksList: ApiPersonDetailPassportComplexChecks,
    passportItem: ApiPersonDetailPassportsRecord,
  ): any;
}

export const IPersonPassportComplexCheckingParserServiceKey: InjectionKey<IPersonPassportComplexCheckingParserService> =
  Symbol('IPersonPassportComplexCheckingParserServiceKey');
