import {
  RegulationChecksGroupedHistoryItemDTOs
} from '@/modules/regulationsCheck/common/types/dto/regulationChecksGroupedHistoryItemDTOs';
import {
  RegulationsChecksHistoryItemDTO
} from '@/modules/regulationsCheck/common/types/dto/regulationChecksHistoryItemDTO';
import { InjectionKey } from 'vue';

export interface IRegulationChecksHistoryGroupingService {
  group(items: RegulationsChecksHistoryItemDTO[]): RegulationChecksGroupedHistoryItemDTOs[]
}

export const IRegulationChecksHistoryGroupingServiceKey: InjectionKey<IRegulationChecksHistoryGroupingService> = Symbol('IRegulationChecksHistoryGroupingService');
