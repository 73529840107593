import { ApiManualImportCreateCompany } from '@/modules/import/types/api/company/apiManualImportCreateCompany';
import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import {
  ICompaniesService
} from '@/modules/dossier/company/common/services/companiesService/companiesService.interfaces';
import {
  CompanyImportResponse
} from '@/modules/dossier/company/common/types/api/companyImportResponse';
import { ImportResponse } from '@/modules/dossier/company/common/types/api/importResponse';
import {
  ManualInsertUlResponse
} from '@/modules/dossier/company/common/types/api/manualInsertUlResponse';
import {
  MergeDataResultResponse
} from '@/modules/dossier/company/common/types/api/mergeDataResultResponse';
import {
  CompanyFinanceAnalysisTotalRisk
} from '@/modules/dossier/company/financeAnalysis/types/api/companyFinanceTotalRisk';
import { ApiImportProgress } from '@/modules/import/types/api/apiImportProgress';
import { ApiMassImportCompanyRequest } from '@/modules/import/types/api/apiMassImportCompanyRequest';
import {
  ExtendedFinanceCompany, FinanceCompany,
  TDetailsCompany
} from '@/types/companyData/companyData';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class CompaniesService implements ICompaniesService {
  constructor(
    @inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance
  ) { }

  makeProcessingCompanies(body: FormData) {
    return this.apiEndpoint.post<ApiResponse<CompanyImportResponse>>('/ul/file/add', body);
  }

  importCompaniesProgressPercent(importProgressId: string) {
    return this.apiEndpoint.get<ApiResponse<ApiImportProgress>>(`/ul/import-progress-percent/${importProgressId}`);
  }

  getCompaniesImportResult(importProgressId: string) {
    return this.apiEndpoint.get<ApiResponse<ImportResponse>>(`/ul/import-result/${importProgressId}`);
  }

  createCompany(body: ApiManualImportCreateCompany) {
    return this.apiEndpoint.post<ManualInsertUlResponse>('/ul/add', body);
  }

  deleteCompanies(companyIds: Array<string>) {
    return this.apiEndpoint.post<ApiResponse<MergeDataResultResponse>>('/ul/delete', companyIds);
  }

  getCompanyDetails(id: string) {
    return this.apiEndpoint.get<ApiResponse<TDetailsCompany>>(`/ul/${id}`);
  }

  getCompanyFinance(id: string) {
    return this.apiEndpoint.get<ApiResponse<FinanceCompany>>(`/financial-analysis/${id}`);
  }

  getCompanyExtendedFinance(id: string) {
    return this.apiEndpoint.get<ApiResponse<ExtendedFinanceCompany>>(`/extended-financial-analysis/${id}`);
  }

  massCompanyImport(payload: ApiMassImportCompanyRequest) {
    return this.apiEndpoint.post<ApiResponse<CompanyImportResponse>>('/ul/bulk/add', payload);
  }

  getCompanyTotalRisk(id: string) {
    return this.apiEndpoint.get<ApiResponse<CompanyFinanceAnalysisTotalRisk>>(`/total-risk/${id}`);
  }

  getJsonReport(companyId: string) {
    return this.apiEndpoint.get<string>(`reports/example/report-company-json/${companyId}`);
  }
}
