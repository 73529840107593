import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import PersonSearchConceptOptionGroupsFactory
  from '@/modules/searchConcept/person/factories/personSearchConceptOptionGroupsFactory';
import {
  IPersonSearchConceptOptionGroupsFactoryKey
} from '@/modules/searchConcept/person/factories/personSearchConceptOptionGroupsFactory.interfaces';
import PersonSearchConceptOptionsFactory
  from '@/modules/searchConcept/person/factories/personSearchConceptOptionsFactory';
import {
  IPersonSearchConceptOptionsFactoryKey
} from '@/modules/searchConcept/person/factories/personSearchConceptOptionsFactory.interfaces';
import {
  ElasticHitToGridPersonMapper
} from '@/modules/searchConcept/person/services/elasticHitToGridPersonMapper';
import {
  IElasticHitToGridPersonMapperKey
} from '@/modules/searchConcept/person/services/elasticHitToGridPersonMapper.interfaces';

export function bindPersonSearchConceptService(bind: IContainerBinder) {
  bind(IPersonSearchConceptOptionsFactoryKey).to(PersonSearchConceptOptionsFactory);
  bind(IPersonSearchConceptOptionGroupsFactoryKey).to(PersonSearchConceptOptionGroupsFactory);
  bind(IElasticHitToGridPersonMapperKey).to(ElasticHitToGridPersonMapper);
}
