import { ReportsHistoryListItem } from '@/modules/reports/mobile/history/general/types/ReportHistoryListItem';
import { InjectionKey } from 'vue';
import { ExtendedReportGenerationPayload } from '@/modules/reports/common/types/extendedReportGenerationPayload';

export interface IReportsExtendedReportGenerationPayloadMappingService {
  mapExtendedReportGenerationPayload(
    report: ReportsHistoryListItem,
  ): ExtendedReportGenerationPayload;
}

export const IReportsExtendedReportGenerationPayloadMappingServiceKey: InjectionKey<IReportsExtendedReportGenerationPayloadMappingService> =
  Symbol('IReportsExtendedReportGenerationPayloadMappingService');
