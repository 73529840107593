import { InjectionKey } from 'vue';
import { ParticipationUlChecksTableRow } from '@/modules/dossier/person/participation/types/participationUlChecksTableRow';
import { ApiPersonDetailDisqualifiedPersonCheckDataRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailDisqualifiedPersonCheckDataRecord';

export interface IPersonParticipationUlChecksMappingService {
  mapParticipationUlChecksTable(
    participationUlChecks: Array<ApiPersonDetailDisqualifiedPersonCheckDataRecord>,
  ): Array<ParticipationUlChecksTableRow>;
}

export const IPersonParticipationUlChecksMappingServiceKey: InjectionKey<IPersonParticipationUlChecksMappingService> =
  Symbol('IPersonParticipationUlChecksMappingServiceKey');
