import { THistoryReportApi } from '@/modules/reports/desktop/types/THistoryReportApi';

export type EasyDataParserState = {
  history: THistoryReportApi[];
};

export enum MutationTypesEnum {
  SET_HISTORY = 'SET_HISTORY',
}

export type Mutations<S = EasyDataParserState> = {
  [MutationTypesEnum.SET_HISTORY](state: S, payload: THistoryReportApi[]): void;
};

export enum ActionTypesEnum {
  GET_HISTORY = 'GET_HISTORY',
}
