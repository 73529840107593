import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { GridOutput } from '@/modules/common/types/api/gridOutput';
import {
  DataGridBatchLoaderService
} from '@/modules/dataGrid/common/services/dataGridBatchLoaderService';
import { ApiGridInput } from '@/modules/dataGrid/common/types/api/apiGridInput';
import {
  IPersonDataGridBatchLoaderService
} from '@/modules/dataGrid/person/services/personDataGridBatchLoaderService.interfaces';
import {
  IPersonDataGridService, IPersonDataGridServiceKey
} from '@/modules/dataGrid/person/services/personDataGridService.interfaces';
import { ApiGridPersonRecord } from '@/modules/dataGrid/person/types/api/apiGridPersonRecord';
import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class PersonDataGridBatchLoaderService extends DataGridBatchLoaderService<ApiGridPersonRecord> implements IPersonDataGridBatchLoaderService {
  constructor(
    @inject(IPersonDataGridServiceKey as symbol) private personDataGridService: IPersonDataGridService
  ) {
    super();
  }

  protected getKey(item: ApiGridPersonRecord): any {
    return item.person_original_id;
  }

  protected getItemsInternalAsync(payload: ApiGridInput): Promise<AxiosResponse<ApiResponse<GridOutput<ApiGridPersonRecord>>>> {
    return this.personDataGridService.getContent(payload);
  }
}
