export enum NegativeInfoTypes {
  Unknown = 0,
  WantedPerson = 10,
  InvalidPassportRus = 50,
  EnforcementProceedingsAvailable = 100,
  BadCreditHistory = 200,
  PrejudicedCriminalPenalties = 210,
  PrejudicedAdministrativePenalties = 220,
  PsychoNeurologicalOrDrugTreatmentCentres = 240,
  RegisterWithOVD = 250,
  WeaponLicense = 260,
  Incident = 270,
  DocumentExchange = 280,
  Gambling = 290,
  Erotic = 300,
  IllegalSoftware = 310,
  Malware = 320,
  Phishing = 330,
  Drugs = 340,
  Violence = 350,
  Obscene = 360,
  Guns = 370,
  ForumsChatsDating = 380,
  Bratva = 390,
  InternetMail = 400,
  InternetShop = 410,
  CasualGames = 420,
  SocialNetworks = 430,
  RecruitingPortals = 440,
  AnonymousProxyServers = 450,
  CreditCardPaymentSites = 460,
  Thieves = 470,
  Anticollectors = 480,
  EarningsOnTheInternet = 490,
  SexPornPlus = 500,
  InvestmentTrading = 510,
  CreditsLoans = 520,
  ProtestActivity = 530,
  Suicide = 540,
  Debt = 550,
  BlackList = 560,
  SpecialAccounting = 570,
  SpecialPunishment = 580,
  CriminalLiability = 590,
}
