import {
  IIndividualTypeFormatterService
} from '@/modules/dossier/person/common/services/formatters/individualTypeFormatterService/individualTypeFormatterService.interfaces';
import { IndividualType } from '@/modules/dossier/person/common/types/api/individualType';
import { injectable } from 'inversify';

@injectable()
export default class IndividualTypeFormatterService implements IIndividualTypeFormatterService {
  format(type: IndividualType): string {
    switch (type) {
      case IndividualType.Employee: return 'Сотрудник';
      default: return 'Неизвестно';
    }
  }
}
