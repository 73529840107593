import { InjectionKey } from 'vue';
import { Mask } from '@/modules/masks/types/mask';

export interface IMasksService {
  getMaskedValue(value: string | undefined, mask: Mask, defaultValue?: string): string | undefined;
  getRawValue(value: string | undefined, mask: Mask, defaultValue?: string): string | undefined;
  isCompleted(value: string | undefined, mask: Mask): boolean;
}

export const IMasksServiceKey: InjectionKey<IMasksService> = Symbol('IMasksService');
