export enum DataTableConfigProfiles
{
  /// <summary>
  /// Неизвестно
  /// </summary>
  Unknown = 0,

  /// <summary>
  /// Таблица персон
  /// </summary>
  Person = 1,

  /// <summary>
  /// Таблица компаний
  /// </summary>
  Company = 2,
}
