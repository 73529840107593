import { InjectionKey } from 'vue';
import { riskType } from '@/modules/risks/types/riskType';

export interface IRiskTypeFormatterService {
  formatRiskType(status: riskType): string;
}

export const IRiskTypeFormatterServiceKey: InjectionKey<IRiskTypeFormatterService> = Symbol(
  'IRiskTypeFormatterService',
);
