import { InjectionKey } from 'vue';
import { InspectionsInfo } from '@/types/companyInspectionsInfo/companyInspectionsInfo';
import { InspectionInfoStatus } from '@/types/companyInspectionsInfo/companyInspectionInfoStatus';

export interface ICompanyInspectionsService {
  calculateCountInspectionsByStatus(
    inspections: Array<InspectionsInfo>,
    status: InspectionInfoStatus,
  ): number;
}

export const ICompanyInspectionsServiceKey: InjectionKey<ICompanyInspectionsService> = Symbol(
  'ICompanyInspectionsServiceKey',
);
