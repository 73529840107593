import {
  IListsEntityIdsExtractionService
} from '@/modules/lists/services/listsEntityIdsExtractionService.interfaces';
import { injectable } from 'inversify';
import _ from 'lodash';

@injectable()
export default abstract class ListsEntityIdsExtractionService implements IListsEntityIdsExtractionService {
  async getIdsAsync(listIds: string[]): Promise<string[]> {
    const idsLists = await Promise.all(listIds.map((x) => this.getListEntityIdsAsync(x)));
    const ids = _.uniq(idsLists.flat());

    return ids;
  }

  protected abstract getListEntityIdsAsync(listId: string): Promise<string[]>
}
