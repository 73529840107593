import { injectable } from 'inversify';
import { IChartScaleService } from '@/modules/charts/services/chartScaleService.interface';
import { Dot } from '@/modules/common/types/dot';
import { Scale } from '@/modules/common/types/scale';

@injectable()
export class ChartScaleService implements IChartScaleService {
  calculateScale(dots: Dot[]): Scale {
    const flattenedItems = dots.map((dot) => dot.y);
    const defaultMin = Math.min(...flattenedItems);
    const defaultMax = Math.max(...flattenedItems);

    return { min: defaultMin, max: defaultMax };
  }
}
