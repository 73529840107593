import {
  IAnalysisRelationTypeFormatterService
} from '@/modules/analysis/services/analysisRelationTypeFormatterService.interfaces';
import { AnalysisRelationType } from '@/modules/analysis/types/analysisRelationType';
import { injectable } from 'inversify';

@injectable()
export default class AnalysisRelationTypeFormatterService implements IAnalysisRelationTypeFormatterService {
  format(value?: AnalysisRelationType): string {
    switch (value) {
      case AnalysisRelationType.ZupCompany: return 'Работа в компании с учетом периода'
      case AnalysisRelationType.ZupDepartment: return 'Общее подразделение';
      case AnalysisRelationType.JobsWithPeriod: return 'Работа в компании с учетом периода';
      case AnalysisRelationType.Jobs: return 'Места работы по наименованию компании';
      case AnalysisRelationType.IndividualEntrepreneur: return 'Индивидуальный предприниматель';

      case AnalysisRelationType.CompanyFounder: return 'Учредители';
      case AnalysisRelationType.CompanyHead: return 'Руководители';
      case AnalysisRelationType.CompanyFounderHistory: return 'Бывшие учредители';
      case AnalysisRelationType.CompanyHeadHistory: return 'Бывшие руководители';
      case AnalysisRelationType.RelatedEmployees: return 'Сотрудники';

      case AnalysisRelationType.ZupRelative: return 'Возможный родственник';
      case AnalysisRelationType.RelativeInn: return 'Родственники';
      case AnalysisRelationType.RelativeBirthday: return 'Вероятный родственник с учетом даты рождения';

      case AnalysisRelationType.Vehicles: return 'Владелец транспортного средства';

      case AnalysisRelationType.SocialFriends: return 'Общий друг';
      case AnalysisRelationType.ReciprocalSocialFriends: return 'Друзья в соц. сети';

      case AnalysisRelationType.ZupBirthplace: return 'Место рождения';

      case AnalysisRelationType.Address: return 'Полное совпадения адреса';
      case AnalysisRelationType.AddressToHouse: return 'Совпадение адреса до дома';
      case AnalysisRelationType.ZupRegistryAddress: return 'Общий адрес регистрации';
      case AnalysisRelationType.ZupActualAddress: return 'Общий адрес проживания';
      case AnalysisRelationType.PhoneNumber: return 'Абонентский номер';
      case AnalysisRelationType.Email: return 'Общий E-mail';
      case AnalysisRelationType.RelatedPhoneNumber: return 'Связанный абонентский номер';

      case AnalysisRelationType.VehicleParkings: return 'Парковочная сессия';
      case AnalysisRelationType.ParksNotOwner: return 'Паркует не владелец';
      case AnalysisRelationType.ParksDifferentsDriver: return 'Паркуют разные люди';
      case AnalysisRelationType.CovidMovements: return 'COVID перемещения';
      case AnalysisRelationType.Avia: return 'Совместные авиаперелёты';

      case AnalysisRelationType.ZupEducation: return 'Учебное заведение';
      case AnalysisRelationType.EducationWithPeriod: return 'Учебное заведение с учетом периода';

      default: return '';
    }
  }
}
