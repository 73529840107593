import { InjectionKey } from 'vue';
import { ApiPersonDetailRoadAccidentRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailRoadAccidentRecord';

export interface IPersonRoadAccidentsStatisticService {
  calculateRoadAccidentsCountByYear(
    accidentList: Array<ApiPersonDetailRoadAccidentRecord>,
    year: number,
  ): number;
}

export const IPersonRoadAccidentsStatisticServiceKey: InjectionKey<IPersonRoadAccidentsStatisticService> =
  Symbol('IPersonRoadAccidentsStatisticService');
