import { injectable, inject } from 'inversify';
import { ImageGroupItem } from '@/modules/images/types/imageGroupItem';
import { IFilesService, IFilesServiceKey } from '@/modules/files/services/filesService.interface';
import { IPersonSocialNetworkAdditionalInfoOkPhotosMappingService } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkPhotosMappingService.interface';
import { ApiPersonDetailOkRecordPhotoItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordPhotoItem';

@injectable()
export default class PersonSocialNetworkAdditionalInfoOkPhotosMappingService
  implements IPersonSocialNetworkAdditionalInfoOkPhotosMappingService
{
  constructor(
    @inject(IFilesServiceKey as symbol)
    private FilesService: IFilesService,
  ) {}

  mapSocialNetworkAdditionalInfoOkPhotos(
    photos: Array<ApiPersonDetailOkRecordPhotoItem>,
  ): Array<ImageGroupItem> {
    return photos.map((photo) => ({
      title: '',
      src: this.FilesService.getDownloadUrl(`/api/v1/ok-parser/photo/${photo.Id}`),
    }));
  }
}
