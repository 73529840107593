import { defineStore } from 'pinia';
import { ref } from 'vue';
import { IRightsServiceKey } from '@/modules/rights/services/rightsService.interfaces';
import { useInjectable } from '@/hooks/useInjectable';
import { IAccessScopeServiceKey } from '@/modules/rights/services/AccessScopeService.interfaces';
import { ApiUserManagement } from '@/modules/rights/type/ApiUserManagement';

export const useUserRightsStore = defineStore('rightsStore', () => {
  const rightsService = useInjectable(IRightsServiceKey);
  const accessScope = useInjectable(IAccessScopeServiceKey);
  const currentUser = ref<ApiUserManagement>();
  async function getUserRights() {
    try {
      if (currentUser.value == undefined) {
        const {
          data: { data },
        } = await rightsService.getUsers();
        currentUser.value = data;
        currentUser.value.accessScope = await accessScope.setAccessScope(data.roles);
        currentUser.value.accessScope.isAuthorized = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  return {
    currentUser,
    getUserRights,
  };
});
