import { injectable } from 'inversify';
import { IDossierIdService } from '@/modules/dossier/common/services/dossierIdService/dossierIdService.interfaces';
import { ApiDossierLink } from '@/modules/dossier/common/types/apiDossierLink';
import { GuidUtils } from '@/modules/common/utils/guidUtils';

@injectable()
export default abstract class DossierIdService implements IDossierIdService {
  getIdFromDossierLink(dossierId?: ApiDossierLink): string | undefined {
    if (dossierId == undefined) {
      return undefined;
    }

    if (dossierId.isAvailable && !GuidUtils.isEmptyGuid(dossierId.baseGuid)) {
      return dossierId.baseGuid;
    }

    const mayBeGuid = this.getIdFromUrl(dossierId.url);
    if (mayBeGuid != undefined && !GuidUtils.isEmptyGuid(mayBeGuid)) {
      return mayBeGuid;
    }

    return undefined;
  }

  abstract getIdFromUrl(url?: string): string | undefined;
}
