import { injectable } from 'inversify';
import { IDateRequisiteMatchingService } from '@/modules/requisites/services/matchers/dateRequisiteMatchingService.interfaces';

@injectable()
export class DateRequisiteMatchingService implements IDateRequisiteMatchingService {
  match(value: string): boolean {
    const regExpFullName = /^(\d{0,2})\.(\d{0,2})\.(\d{0,4})$/;
    const result = value.match(regExpFullName);
    return Array.isArray(result);
  }

  matchPartial(value: string): boolean {
    const regExpFullName = /^(\d{2})\.(\d{2})\.(\d{4})$/;
    const result = value.match(regExpFullName);
    return Array.isArray(result);
  }
}
