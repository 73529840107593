import { InjectionKey } from 'vue';
import { ApiPersonDetailFinancialScoringDataRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailFinancialScoringDataRecord';
import { FinancialScoringAnalyticsBlockByPerson } from '@/modules/dossier/person/financialScorings/types/financialScoringAnalyticsBlockByPerson';

export interface IPersonFinancialScoringsMappingService {
  mapFinancialScoringsAnalytics(
    scorings: Array<ApiPersonDetailFinancialScoringDataRecord>,
  ): Array<FinancialScoringAnalyticsBlockByPerson>;
}

export const IPersonFinancialScoringsMappingServiceKey: InjectionKey<IPersonFinancialScoringsMappingService> =
  Symbol('IPersonFinancialScoringsMappingServiceKey');
