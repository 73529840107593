import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IPersonEnforcementProceedingsStatisticsServiceKey } from '@/modules/dossier/person/enforcementProceedings/services/personEnforcementProceedingsStatisticsService.interface';
import PersonEnforcementProceedingsStatisticsService from '@/modules/dossier/person/enforcementProceedings/services/personEnforcementProceedingsStatisticsService';
import { IPersonEnforcementProceedingsTagConfigServiceKey } from '@/modules/dossier/person/enforcementProceedings/services/personEnforcementProceedingsTagConfigService.interface';
import PersonEnforcementProceedingsTagConfigService from '@/modules/dossier/person/enforcementProceedings/services/personEnforcementProceedingsTagConfigService';

export function bindPersonDossierEnforcementProceedingsServices(bind: IContainerBinder) {
  bind(IPersonEnforcementProceedingsStatisticsServiceKey).to(
    PersonEnforcementProceedingsStatisticsService,
  );
  bind(IPersonEnforcementProceedingsTagConfigServiceKey).to(
    PersonEnforcementProceedingsTagConfigService,
  );
}
