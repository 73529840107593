import { useEvent } from '@/modules/events/composables/useEvent';

export function useRegulationCheckPersonMarkersChangedEvent() {
  const { trigger, on } = useEvent<[personId: string]>('RegulationCheckPersonMarkersChangedEvent');

  return {
    triggerRegulationCheckPersonMarkersChangedEvent: trigger,
    onRegulationCheckPersonMarkersChanged: on
  }
}
