export type EventLogRowType = {
  created: string;
  createdStr: string;
  description: string;
  fullName: string;
  id: string;
  originalId: string;
  parentDomainType: string;
  sourceType: string;
  url: string;
};

export type EventLogDataType = EventLogRowType[];

export type DataCollectionPageState = {
  data: EventLogDataType[];
  namespaced: boolean;
};

export enum MutationTypes {
  SET_DATA = 'SET_DATA',
}

export type Mutations<S = DataCollectionPageState> = {
  [MutationTypes.SET_DATA](state: S, payload: Pick<DataCollectionPageState, 'data'>): void;
};

export enum ActionTypes {
  GET_DATA_DATA_COLLECTION = 'GET_DATA_DATA_COLLECTION',
}
