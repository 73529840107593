import { MaybeRefFields } from '@/modules/common/types/maybeRefFields';
import { unref } from 'vue';

export module RefUtils {
  export function unwrapConfig(config: MaybeRefFields): Record<any, any> {
    if(config == undefined)
      return {};

    return Object.keys(config).reduce((result, key) => {
      result[key] = unref(config[key]);
      return result;
    }, {} as Record<string, any>)
  }
}
