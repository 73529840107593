import { inject, injectable } from 'inversify';
import { IRegulationsCheckCheckRequiredIndicatorsMappingService } from '@/modules/regulationsCheck/common/services/regulationsCheckCheckRequiredIndicatorsMappingService.interface';
import {
  IRegulationsCheckMarkersMappingService,
  IRegulationsCheckMarkersMappingServiceKey,
} from '@/modules/regulationsCheck/common/services/regulationsCheckMarkersMappingService.interface';
import { DossierRoutingType } from '@/modules/dossier/common/types/DossierRoutingType';
import { RiskDetailRateParameter } from '@/modules/regulationsCheck/common/types/riskDetailRateParameter';
import { RegulationsCheckCheckRequiredIndicatorsTableRow } from '@/modules/regulationsCheck/common/types/regulationsCheckCheckRequiredIndicatorsTableRow';

@injectable()
export default class RegulationsCheckCheckRequiredIndicatorsMappingService
  implements IRegulationsCheckCheckRequiredIndicatorsMappingService
{
  constructor(
    @inject(IRegulationsCheckMarkersMappingServiceKey as symbol)
    private regulationsCheckMarkersMappingService: IRegulationsCheckMarkersMappingService,
  ) {}

  sortCheckRequiredIndicators(
    markers: Array<RegulationsCheckCheckRequiredIndicatorsTableRow>,
  ): Array<RegulationsCheckCheckRequiredIndicatorsTableRow> {
    return markers.sort((a, b) => {
      if (a.mode == 'default' && b.mode != 'default') {
        return 1;
      }
      if (a.mode != 'default' && b.mode == 'default') {
        return -1;
      }
      if (!a.isAuto && b.isAuto) {
        return -1;
      }
      if (a.isAuto && !b.isAuto) {
        return 1;
      }
      if (a.isAuto && !a.autoCalculatedValue && (!b.isAuto || b.autoCalculatedValue)) {
        return -1;
      }
      if (b.isAuto && !b.autoCalculatedValue && (!a.isAuto || a.autoCalculatedValue)) {
        return 1;
      }
      return 0;
    });
  }

  mapCheckRequiredIndicators(
    markers: Array<RiskDetailRateParameter>,
    target: DossierRoutingType,
  ): Array<RegulationsCheckCheckRequiredIndicatorsTableRow> {
    return markers.map((marker: RiskDetailRateParameter) => {
      const { groupName, statusIcon, statusIconColor } =
        this.regulationsCheckMarkersMappingService.getGroupNameAndStatusIcon(marker, target);
      return {
        rateParameterGroup: groupName,
        statusIcon: {
          color: statusIconColor,
          icon: statusIcon,
        },
        name: marker.name,
        nameEng: marker.nameEng,
        isAuto: marker.isAuto,
        mode: marker.mode,
        autoCalculatedValue: marker.autoCalculatedValue,
        parameterId: marker.parameterId,
      };
    });
  }
}
