import {
  IEnvironmentVariables,
  IEnvironmentVariablesKey,
} from '@/modules/common/settings/environmentVariables.interfaces';
import { inject, injectable } from 'inversify';
import { IImportSettings } from '@/modules/common/settings/importSettings.interfaces';
import { ImportVersions } from '@/modules/common/types/settings/importVersions';

@injectable()
export default class ImportSettings implements IImportSettings {
  constructor(
    @inject(IEnvironmentVariablesKey as symbol) private environmentVariables: IEnvironmentVariables,
  ) {}

  get version(): ImportVersions {
    switch (this.environmentVariables.VUE_APP_IMPORT_VERSION) {
      case 'v2':
        return ImportVersions.v2;
      default:
        return ImportVersions.v1;
    }
  }
}
