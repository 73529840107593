export enum SearchConceptCategoryTypes {
  companyPhone = 0,
  companyRelated = 1,
  companyAddress = 2,
  personAddress = 3,
  personOthers = 4,
  personJob = 5,
  personTag = 6,
  personNegative = 7,
  personPhone = 8,
}
