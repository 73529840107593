import { InjectionKey } from 'vue';
import { Pledge } from '@/modules/dossier/company/pledges/types/pledge';
import { PledgeStatisticsTableRow } from '@/modules/dossier/company/pledges/types/pledgeStatisticsTableRow';
import { PledgeTableRow } from '@/modules/dossier/company/pledges/types/pledgeTableRow';

export interface ICompanyPledgesMappingService {
  mapPledgesListTableData(pledges: Array<Pledge>): Array<PledgeTableRow>;
  getPledgeholdersByPledges(pledges: Array<Pledge>): Array<PledgeStatisticsTableRow>
}

export const ICompanyPledgesMappingServiceKey: InjectionKey<ICompanyPledgesMappingService> = Symbol(
  'ICompanyPledgesMappingService',
);
