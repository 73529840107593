export module AddressUtils {
  export function checkAddressHasCity(address: string) {
    return address.includes('г ');
  }

  export function getCityByAddress(address: string) {
    const hasCity = AddressUtils.checkAddressHasCity(address);

    if (hasCity) {
      return address.split('г ')[1].split(',')[0];
    }
    return address;
  }
}
