import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import AddressTypeFormatter
  from '@/modules/dossier/person/common/services/formatters/addressTypeFormatter';
import {
  IAddressTypeFormatterKey
} from '@/modules/dossier/person/common/services/formatters/addressTypeFormatter.interfaces';
import ContactSubtypeFormatter
  from '@/modules/dossier/person/common/services/formatters/contactSubtypeFormatter';
import {
  IContactSubtypeFormatterKey
} from '@/modules/dossier/person/common/services/formatters/contactSubtypeFormatter.interfaces';
import ContactTypeFormatter
  from '@/modules/dossier/person/common/services/formatters/contactTypeFormatter';
import {
  IContactTypeFormatterKey
} from '@/modules/dossier/person/common/services/formatters/contactTypeFormatter.interfaces';
import FamilyRelationTypeFormatter
  from '@/modules/dossier/person/common/services/formatters/familyRelationTypeFormatter';
import {
  IFamilyRelationTypeFormatterKey
} from '@/modules/dossier/person/common/services/formatters/familyRelationTypeFormatter.interfaces';
import HousingTypeFormatter
  from '@/modules/dossier/person/common/services/formatters/housingTypeFormatter';
import {
  IHousingTypeFormatterKey
} from '@/modules/dossier/person/common/services/formatters/housingTypeFormatter.interfaces';
import IndividualTypeFormatterService
  from '@/modules/dossier/person/common/services/formatters/individualTypeFormatterService/individualTypeFormatterService';
import {
  IIndividualTypeFormatterServiceKey
} from '@/modules/dossier/person/common/services/formatters/individualTypeFormatterService/individualTypeFormatterService.interfaces';
import NegativeInfoTypeFormatter
  from '@/modules/dossier/person/common/services/formatters/negativeInfoTypeFormatter';
import {
  INegativeInfoTypeFormatterKey
} from '@/modules/dossier/person/common/services/formatters/negativeInfoTypeFormatter.interfaces';
import PassportTypeFormatter
  from '@/modules/dossier/person/common/services/mappers/passportTypeMapper';
import {
  IPassportTypeMapperKey
} from '@/modules/dossier/person/common/services/mappers/passportTypeMapper.interfaces';
import SocialNetworkTypeFormatter
  from '@/modules/dossier/person/common/services/formatters/socialNetworkTypeFormatter';
import {
  ISocialNetworkTypeFormatterKey
} from '@/modules/dossier/person/common/services/formatters/socialNetworkTypeFormatter.interfaces';
import PersonDossierCRUDService from '@/modules/dossier/person/common/services/personDossierCRUDService';
import {
  IPersonDossierCRUDServiceKey
} from '@/modules/dossier/person/common/services/personDossierCRUDService.interfaces';

export function bindPersonDossierFormatters(bind: IContainerBinder) {
  bind(IPersonDossierCRUDServiceKey).to(PersonDossierCRUDService);
  bind(IAddressTypeFormatterKey).to(AddressTypeFormatter);
  bind(IHousingTypeFormatterKey).to(HousingTypeFormatter);
  bind(INegativeInfoTypeFormatterKey).to(NegativeInfoTypeFormatter);
  bind(IContactTypeFormatterKey).to(ContactTypeFormatter);
  bind(IContactSubtypeFormatterKey).to(ContactSubtypeFormatter);
  bind(IPassportTypeMapperKey).to(PassportTypeFormatter);
  bind(IFamilyRelationTypeFormatterKey).to(FamilyRelationTypeFormatter);
  bind(ISocialNetworkTypeFormatterKey).to(SocialNetworkTypeFormatter);
  bind(IIndividualTypeFormatterServiceKey).to(IndividualTypeFormatterService);
}
