import { Ref } from 'vue';
import { LoadOptions, SearchOperation } from 'devextreme/data';
import { DxTableEnumItem } from '@/modules/devExtreme/types/dxTableEnumItem';
import { IDevExtremeChangesObject } from '@/modules/devExtreme/types/dxDataSource';
import { DxTableColumn } from '@/modules/devExtreme/types/dxTableColumn';
import { ApiDataTableFieldProfile } from '@/modules/profile/types/api/apiDataTableFieldProfile';
import { DxCompareOperations } from '@/modules/devExtreme/types/DxCompareOperations';
import { UISortOrderType } from '@/modules/devExtreme/components/UISortOrder/types/UISortOrderType';

export module DxUtils {
  export function convertEnumToDxItems(items: Record<string, number>): Array<DxTableEnumItem> {
    return Object.entries(items)
      .filter(([_, id]) => typeof id == 'number')
      .reduce((items, [name, id]) => {
        items.push({
          id,
          name,
        });
        return items;
      }, new Array<DxTableEnumItem>());
  }

  export function convertStringToSortOrder(order?: string): UISortOrderType {
    switch (order) {
      case 'asc':
        return 'asc';
      case 'desc':
        return 'desc';
      case 'off':
      default:
        return 'off';
    }
  }

  export function convertDxCompareOperationsToString(
    filterCompareType: DxCompareOperations,
  ): SearchOperation {
    switch (filterCompareType) {
      case DxCompareOperations.NotEquals:
        return '<>';
      case DxCompareOperations.Less:
        return '<';
      case DxCompareOperations.LessOrEquals:
        return '<=';
      case DxCompareOperations.Greater:
        return '>';
      case DxCompareOperations.GreaterOrEquals:
        return '>=';
      case DxCompareOperations.StartsWith:
        return 'startswith';
      case DxCompareOperations.EndsWith:
        return 'endswith';
      case DxCompareOperations.Contains:
        return 'contains';
      case DxCompareOperations.NotContains:
        return 'notcontains';
      case DxCompareOperations.Equals:
      default:
        return '=';
    }
  }

  export function prepareGridMainColumns(
    columns: Array<DxTableColumn>,
    columnSettings: Ref<Array<ApiDataTableFieldProfile>>,
  ): Array<DxTableColumn> {
    return columns
      .filter((column) =>
        columnSettings.value.some((item: any) => item.fieldCode == column.dataField),
      )
      .sort((column1, column2) => {
        const c1 = columnSettings.value.find((item) => item.fieldCode == column1.dataField);
        const c2 = columnSettings.value.find((item) => item.fieldCode == column2.dataField);
        if (c1?.index != undefined && c2?.index != undefined) {
          return c1.index - c2.index;
        }
        return 0;
      });
  }

  export function isOptionNotEmpty(value: any): boolean {
    return value != undefined && value != '';
  }

  export function prepareLoadOptions<T = any>(
    loadOptions: LoadOptions<T>,
    currentOptions: Array<keyof LoadOptions<T>> = [
      'requireTotalCount',
      'skip',
      'take',
      'filter',
      'sort',
      'select',
      'searchOperation',
      'searchValue',
      'searchExpr',
    ],
  ): LoadOptions<T> {
    const paramsDevextreme: LoadOptions<T> = {};

    currentOptions.forEach((option) => {
      if (DxUtils.isOptionNotEmpty(loadOptions[option])) {
        paramsDevextreme[`${option}`] = loadOptions[option];
      }
    });

    return paramsDevextreme;
  }

  export async function saveChanges<T>(
    changeObject: IDevExtremeChangesObject<T>[],
    crudOperations: {
      update?: (data: T[]) => Promise<any>;
      insert?: (data: T[]) => Promise<any>;
      remove?: (ids: string[]) => Promise<any>;
    },
  ): Promise<void> {
    const updateChangeObjects = changeObject.filter((x) => x.type === 'update');
    const insertChangeObjects = changeObject.filter((x) => x.type === 'insert');
    const removeChangeObjects = changeObject.filter((x) => x.type === 'remove');

    if (crudOperations.update != undefined && updateChangeObjects.length > 0) {
      await crudOperations.update(updateChangeObjects.map((item) => item.key));
    }

    if (crudOperations.insert != undefined && insertChangeObjects.length > 0) {
      await crudOperations.insert(insertChangeObjects.map((item) => item.data));
    }

    if (crudOperations.remove != undefined && removeChangeObjects.length > 0) {
      await crudOperations.remove(removeChangeObjects.map((item) => item.key.id));
    }
  }

  export function normalizeComponentConfig(config: Record<string, any>, value: any) {
    const result: Record<string, any> = { value, ...config };

    if (config.value == undefined) {
      config.value = value;
    }

    if (config.rowData != undefined) {
      result.rowData = config.rowData;
    }

    return result;
  }
}
