import { useInjectable } from '@/hooks/useInjectable';
import { useStaticNotification } from '@/modules/notifications/composables/useStaticNotification';
import { useEasyReportHistoryStore } from '@/modules/reports/common/stores/easyReportHistoryStore';
import { ReportHistoryItemDTO } from '@/modules/reports/common/types/reportHistoryItemDTO';
import { ReportStatusType } from '@/modules/reports/common/types/ReportStatusType';
import { IReportHistoryRequisitesFactoryKey } from '@/modules/reports/common/services/reportHistoryRequisitesFactory.interfaces';
import { IReportParentTypeFormatterKey } from '@/modules/reports/common/services/reportParentTypeFormatter.interfaces';
import { useTaskStatusChangedEvent } from '@/modules/tasks/composables/useTaskStatusChangedEvent/useTaskStatusChangedEvent';
import { useGlobalTasksStore } from '@/modules/tasks/stores/useGlobalTasksStore';
import { ApiProcessStatus } from '@/modules/tasks/types/api/apiProcessStatus';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';

export function useReportsFinishNotifications() {
  const reportHistoryRequisitesFactory = useInjectable(IReportHistoryRequisitesFactoryKey);
  const reportParentTypeFormatter = useInjectable(IReportParentTypeFormatterKey);

  const { fireNotification } = useStaticNotification();
  const { onTaskStatusChanged } = useTaskStatusChangedEvent();

  const globalTasksStore = useGlobalTasksStore();

  const historyStore = useEasyReportHistoryStore();
  const { historyItems } = storeToRefs(historyStore);

  function getQuicksell(report: ReportHistoryItemDTO) {
    const requisiteLabel = reportHistoryRequisitesFactory.getRequisite(report);
    const entityTypeLabel = reportParentTypeFormatter.format(report.parentType);

    return `${requisiteLabel}\r\n${entityTypeLabel}`;
  }

  function fireErrorNotification(report: ReportHistoryItemDTO) {
    fireNotification({
      title: 'Ошибка',
      quickSell: getQuicksell(report),
      status: 'error',
    });
  }

  function fireSuccessNotification(report: ReportHistoryItemDTO) {
    fireNotification({
      title: 'Успешно',
      quickSell: getQuicksell(report),
      status: 'success',
    });
  }

  const taskHandlers = new Map<ApiProcessStatus, (item: ReportHistoryItemDTO) => void>([
    [ApiProcessStatus.Complete, x => fireSuccessNotification(x)],
    [ApiProcessStatus.Error, x => fireErrorNotification(x)],
  ]);

  watch(historyItems, historyItems => {
    historyItems.forEach(x => {
      if (x.status == ReportStatusType.progress) {
        globalTasksStore.registerTask(x.taskId);
      }
    });
  });

  onTaskStatusChanged((taskId, status) => {
    const handler = taskHandlers.get(status);

    if (handler == undefined) {
      return;
    }

    const historyItem = historyItems.value.find(x => x.taskId == taskId);

    if (historyItem != undefined) {
      handler(historyItem);
    }
  });
}
