import { InjectionKey } from 'vue';
import { ChartJSLineChartLine } from '@/modules/charts/modules/chartjs/components/UILineChart/types/ChartJSLineChartLine';
import { ChartJSLineChartDataset } from '@/modules/charts/modules/chartjs/components/UILineChart/types/ChartJSLineChartDataset';

export interface IChartJSLineDatasetsService {
  createDatasets(items: ChartJSLineChartLine[]): ChartJSLineChartDataset[];
}

export const IChartJSLineDatasetsServiceKey: InjectionKey<IChartJSLineDatasetsService> = Symbol(
  'IChartJSLineDatasetsServiceKey',
);
