import { InjectionKey } from 'vue';
import { AxiosResponse } from 'axios';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { ReportInterruptResponseApi } from '@/modules/reports/common/types/reportInterruptResponseApi';
import { ReportMigrateResponseApi } from '@/modules/reports/desktop/types/reportMigrateResponseApi';
import { ReportParentType } from '../types/ReportParentType';

export interface IReportsApiServiceInterface {
  getReportDownloadUrl(reportInfoId?: string | null): string | undefined;
  cancelReportGenerationTask(
    taskId: string,
  ): Promise<AxiosResponse<ApiResponse<ReportInterruptResponseApi>>>;
  cancelReportGenerationTasks(
    taskIds: string[],
  ): Promise<Array<AxiosResponse<ApiResponse<ReportInterruptResponseApi>>>>;
  migrateReportToSystem(
    taskId: string,
    parentId: string,
    parentType: ReportParentType,
  ): Promise<AxiosResponse<ApiResponse<ReportMigrateResponseApi>>>;
}

export const ReportsApiServiceKey: InjectionKey<IReportsApiServiceInterface> =
  Symbol('ReportsApiServiceKey');
