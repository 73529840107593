import { injectable } from 'inversify';
import { ICompanyContactsTableService } from '@/modules/dossier/company/contacts/services/CompanyContactsTableService.interface';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { UseDxTagConfig } from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';
import { useUITagIcon } from '@/modules/tags/components/UITagIcon/composables/useUITagIcon/useUITagIcon';
import { useUITagText } from '@/modules/tags/components/UITagText/composables/useUITagText/useUITagText';

@injectable()
export default class CompanyContactsTableService implements ICompanyContactsTableService {
  calculatePhoneVerificationCodeCellValue(
    phoneVerificationCode: string,
  ): UseDxTagConfig {
    switch (phoneVerificationCode) {
      case 'Телефон распознан уверенно':
      case 'Российский телефон, распознан уверенно':
      case 'Иностранный телефон, распознан уверенно ':
        return {
          theme: 'success',
          content: phoneVerificationCode,
        };
      case 'Телефон пустой или заведомо «мусорный»':
        return {
          theme: 'danger',
          content: phoneVerificationCode,
        };
      case 'Телефон распознан с допущениями или не распознан':
      case 'Исходное значение распознано с допущениями или не распознано':
      case 'Обнаружено несколько телефонов, распознан первый':
        return {
          theme: 'warning',
          content: [
            useUITagIcon({ name: SystemIconsNames.infoRoundedStroke }),
            useUITagText({ content: phoneVerificationCode }),
          ],
          tooltip: 'always',
          tooltipText: 'Необходимо проверить вручную',
        };
      default:
        return {
          theme: 'common-alt',
          content: 'Неизвестный',
        };
    }
  }

  calculateEmailVerificationCodeCellValue(
    emailVerificationCode: string,
  ): UseDxTagConfig {
    switch (emailVerificationCode) {
      case 'Адрес распознан уверенно':
      case 'Корректное значение':
        return {
          theme: 'success',
          content: [
            useUITagIcon({ name: SystemIconsNames.infoRoundedStroke }),
            useUITagText({ content: emailVerificationCode }),
          ],
          tooltip: 'always',
          tooltipText:
            'Соответствует общепринятым правилам, реальное существование адреса не проверяется',
        };
      case 'Пустое или заведомо «мусорное» значение':
        return {
          theme: 'danger',
          content: emailVerificationCode,
        };
      case 'Временная электронная почта':
      case '«Одноразовый» адрес':
        return {
          theme: 'warning',
          content: emailVerificationCode,
        };
      case 'Некорректное значение':
        return {
          theme: 'danger',
          content: [
            useUITagIcon({ name: SystemIconsNames.infoRoundedStroke }),
            useUITagText({ content: emailVerificationCode }),
          ],
          tooltip: 'always',
          tooltipText: 'Не соответствует общепринятым правилам',
        };
      default:
        return {
          theme: 'common-alt',
          content: 'Неизвестно',
        };
    }
  }
}
