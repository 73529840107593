import { InjectionKey } from 'vue';
import { AnalyticsTypes } from '@/modules/dossier/person/financialScorings/types/financialAnalyticsTypes';

export interface IAnalyticsTypeParserService {
  parseAnalyticType(type: string): AnalyticsTypes;
}

export const IAnalyticsTypeParserServiceKey: InjectionKey<IAnalyticsTypeParserService> = Symbol(
  'IAnalyticsTypeParserServiceKey',
);
