import { ApiManualImportCreateCompany } from '@/modules/import/types/api/company/apiManualImportCreateCompany';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import {
  CompanyImportResponse
} from '@/modules/dossier/company/common/types/api/companyImportResponse';
import { ImportResponse } from '@/modules/dossier/company/common/types/api/importResponse';
import {
  MergeDataResultResponse
} from '@/modules/dossier/company/common/types/api/mergeDataResultResponse';
import {
  CompanyFinanceAnalysisTotalRisk
} from '@/modules/dossier/company/financeAnalysis/types/api/companyFinanceTotalRisk';
import { ApiImportProgress } from '@/modules/import/types/api/apiImportProgress';
import { ApiMassImportCompanyRequest } from '@/modules/import/types/api/apiMassImportCompanyRequest';
import {
  ExtendedFinanceCompany, FinanceCompany,
  TDetailsCompany
} from '@/types/companyData/companyData';
import { AxiosResponse } from 'axios';
import { InjectionKey } from 'vue';
import { ManualInsertUlResponse } from '../../types/api/manualInsertUlResponse';

export interface ICompaniesService {
  makeProcessingCompanies(body: FormData): Promise<AxiosResponse<ApiResponse<CompanyImportResponse>>>
  importCompaniesProgressPercent(importProcessId: string): Promise<AxiosResponse<ApiResponse<ApiImportProgress>>>
  getCompaniesImportResult(importProcessId: string): Promise<AxiosResponse<ApiResponse<ImportResponse>>>
  createCompany(body: ApiManualImportCreateCompany): Promise<AxiosResponse<ManualInsertUlResponse>>
  deleteCompanies(companyIds: Array<string>): Promise<AxiosResponse<ApiResponse<MergeDataResultResponse>>>
  getCompanyDetails(id: string): Promise<AxiosResponse<ApiResponse<TDetailsCompany>>>
  getCompanyFinance(id: string): Promise<AxiosResponse<ApiResponse<FinanceCompany>>>
  getCompanyExtendedFinance(id: string): Promise<AxiosResponse<ApiResponse<ExtendedFinanceCompany>>>
  massCompanyImport(payload: ApiMassImportCompanyRequest): Promise<AxiosResponse<ApiResponse<CompanyImportResponse>>>
  getCompanyTotalRisk(id: string): Promise<AxiosResponse<ApiResponse<CompanyFinanceAnalysisTotalRisk>>>
  getJsonReport(companyId: string): Promise<AxiosResponse<string>>
}

export const ICompaniesServiceKey: InjectionKey<ICompaniesService> = Symbol('ICompaniesService');
