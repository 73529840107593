import { InjectionKey } from 'vue';
import { RequisiteType } from '@/modules/requisites/types/requisiteType';
import { ReportRequisiteLegend } from '@/modules/reports/desktop/types/ReportRequisiteLegend';
import { ReportConfigurationPayloadApi } from '../types/ReportConfigurationPayloadApi';

export interface IReportsConfigurationService {
  checkValidInn(value: string[]): boolean;
  checkValidUlInn(value: string[]): boolean;
  validFullName(value: string): string;
  formatBirthday(birthday: string): string;
  validPayload(
    inputValue: string,
    inputBirthdayValue: string,
    currentLegend: string,
    isExtended: boolean,
  ): ReportConfigurationPayloadApi;
  getReportLegend(value: RequisiteType): ReportRequisiteLegend;
}

export const ReportsConfigurationServiceKey: InjectionKey<IReportsConfigurationService> = Symbol(
  'ReportsConfigurationServiceKey',
);
