import { IEnvironmentSettings } from '@/modules/common/settings/environmentSettings.interfaces';
import {
  IEnvironmentVariables, IEnvironmentVariablesKey,
} from '@/modules/common/settings/environmentVariables.interfaces';
import { AppMode } from '@/modules/common/types/appMode';
import { inject, injectable } from 'inversify';

@injectable()
export default class EnvironmentSettings implements IEnvironmentSettings {
  constructor(
    @inject(IEnvironmentVariablesKey as symbol) private environmentVariables: IEnvironmentVariables,
  ) {}

  get baseUrl(): string {
    return this.environmentVariables.BASE_URL;
  }

  get mode(): AppMode {
    switch (this.environmentVariables.VUE_APP_MODE?.toLowerCase()) {
      case 'standalone-graph': return AppMode.standaloneGraph;
      default: return AppMode.general;
    }
  }

  get commitHash(): string {
    return this.environmentVariables.VUE_APP_CI_COMMIT_SHORT_SHA ?? '';
  }

  get isDev(): boolean {
    return this.environmentVariables.NODE_ENV == 'development';
  }

  get isProd(): boolean {
    return this.environmentVariables.NODE_ENV == 'production';
  }
}
