import { inject, injectable } from 'inversify';
import { ICompanyInnValidationService } from '@/modules/validation/services/companyInnValidationService.interfaces';
import {
  IMasksService,
  IMasksServiceKey,
} from '@/modules/masks/services/masksService/masksService.interfaces';
import {
  companyInnLength,
  companyInnMask,
} from '@/modules/validation/configuration/masks/companyInnMask';

@injectable()
export default class CompanyInnValidationService implements ICompanyInnValidationService {
  constructor(@inject(IMasksServiceKey as symbol) private masksService: IMasksService) {}

  validate(value: string): boolean {
    if (!this.masksService.isCompleted(value, companyInnMask)) {
      return false;
    }

    return value.length == companyInnLength;
  }
}
