import { injectable } from 'inversify';
import { DateUtils } from '@/utils/dateUtils';
import { IPersonRoadAccidentsStatisticService } from '@/modules/dossier/person/roadAccidents/services/personRoadAccidentsStatisticService.interface';
import { ApiPersonDetailRoadAccidentRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailRoadAccidentRecord';
import { DateFormatConfiguration } from '@/configuration/dateFormatConfiguration';

@injectable()
export default class PersonRoadAccidentsStatisticService
  implements IPersonRoadAccidentsStatisticService
{
  calculateRoadAccidentsCountByYear(
    accidentList: Array<ApiPersonDetailRoadAccidentRecord>,
    year: number,
  ) {
    const yearString = `${year}`;
    return accidentList.reduce((acc, curr) => {
      const accidentYear = DateUtils.formatDate(curr.date, DateFormatConfiguration.year);
      if (accidentYear == yearString) {
        acc += 1;
      }
      return acc;
    }, 0);
  }
}
