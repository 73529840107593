import {
  IDxXlsxCellFormatter, IDxXlsxCellFormatterKey
} from '@/modules/devExtreme/export/xlsx/services/dxXlsxCellFormatter/dxXlsxCellFormatter.interfaces';
import { IDxXlsxExportService } from '@/modules/devExtreme/export/xlsx/services/dxXlsxExportService/dxXlsxExportService.interfaces';
import {
  IDxXlsxWorksheetColumnsAligner, IDxXlsxWorksheetColumnsAlignerKey
} from '@/modules/devExtreme/export/xlsx/services/dxXlsxWorksheetColumnsAligner/dxXlsxWorksheetColumnsAligner.interfaces';
import { DxTableExtendedColumn } from '@/modules/devExtreme/types/dxTableExtendedColumn';
import { ExcelDataGridCell, exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { inject, injectable, multiInject } from 'inversify';

function getDxColumn(columns: DxTableExtendedColumn[], gridCell?: ExcelDataGridCell): DxTableExtendedColumn | undefined {
  if(gridCell?.column != undefined) {
    const dataField = gridCell.column.dataField;
    return columns.find(x => x.dataField == dataField);
  }
}

@injectable()
export default class DxXlsxExportService implements IDxXlsxExportService {
  constructor(
    @multiInject(IDxXlsxCellFormatterKey as symbol) private cellFormatters: IDxXlsxCellFormatter[],
    @inject(IDxXlsxWorksheetColumnsAlignerKey as symbol) private columnsAligner: IDxXlsxWorksheetColumnsAligner
  ) { }

  async exportData(tableInstance: any, columns: DxTableExtendedColumn[], tableExportName: string, selectedRowsOnly: boolean) {
    const cellFormatters = this.cellFormatters;
    const columnsAligner = this.columnsAligner;

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Основная страница');

    await exportDataGrid({
      component: tableInstance,
      selectedRowsOnly,
      worksheet,

      customizeCell({ excelCell, gridCell}) {
        if (excelCell != undefined && gridCell != undefined) {
          const formatter = cellFormatters.find(x => x.cellType == gridCell.rowType);
          const dxColumn = getDxColumn(columns, gridCell);

          if (formatter != undefined && dxColumn != undefined)
            formatter.format(excelCell, gridCell, dxColumn);
        }
      }
    });

    columnsAligner.alignColumns(worksheet);

    const buffer = await workbook.xlsx.writeBuffer();

    await saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${tableExportName}.xlsx`);
  }
}
