import { InjectionKey } from 'vue';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';

export interface IPersonMentionsIconsService {
  getMentionIconName(value: string): SystemIconsNames;
}

export const IPersonMentionsIconsServiceKey: InjectionKey<IPersonMentionsIconsService> = Symbol(
  'IPersonMentionsIconsServiceKey',
);
