import { InjectionKey } from 'vue';
import { PassportResultCheckingEnum } from '@/modules/dossier/person/passports/types/passportResultCheckingEnum';
import { PersonPassportResultCheckingTag } from '@/modules/dossier/person/passports/types/personPassportResultCheckingTag';

export interface ICheckingResultMapper {
  getPersonPassportComplexCheckingResultTagConfig(value: PassportResultCheckingEnum): PersonPassportResultCheckingTag;
}

export const IPersonPassportComplexCheckingResultMapperKey: InjectionKey<ICheckingResultMapper> =
  Symbol('IPersonPassportComplexCheckingResultMapperKey');
