import { injectable } from 'inversify';
import { IPersonAddressTagCodeSuitabilityMailingServiceInterface } from '@/modules/dossier/person/addresses/services/personAddressTagCodeSuitabilityMailingService.interface';
import { UseDxTagConfig } from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';

@injectable()
export class PersonAddressTagCodeSuitabilityMailingService
  implements IPersonAddressTagCodeSuitabilityMailingServiceInterface
{
  getMailingSuitabilityTagData(mailingSuitabilityCode: string | undefined): UseDxTagConfig {
    switch (mailingSuitabilityCode) {
      case 'Пригоден для почтовой рассылки':
        return { content: mailingSuitabilityCode, theme: 'success' };
      case 'Дома нет в ФИАС':
      case 'Нет квартиры. Подходит для юридических лиц или частных владений':
      case 'До почтового отделения — абонентский ящик или адрес до востребования. Подходит для писем, но не для курьерской доставки.':
      case 'Сначала проверьте, правильно ли Дадата разобрала исходный адрес':
      case 'Требуется ручная проверка':
        return { content: mailingSuitabilityCode, theme: 'warning' };
      case 'Нет региона':
      case 'Нет города':
      case 'Нет улицы':
      case 'Нет дома':
      case 'Адрес неполный':
      case 'Иностранный адрес':
        return { content: mailingSuitabilityCode, theme: 'danger' };
      default:
        return { content: 'Неизвестно', theme: 'common-alt' };
    }
  }
}
