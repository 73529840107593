import { injectable } from 'inversify';
import { ICompanyAddressMappingService } from '@/modules/dossier/company/address/services/companyAddressMappingService.interface';
import { DossierTagData } from '@/modules/common/types/dossierTag';
import { CompanyAddressTagData } from '@/modules/dossier/company/address/types/companyAddressTagData';
import { TCompanyAdress } from '@/types/companyData/companyData';
import { CompanyAddressTableData } from '@/modules/dossier/company/address/types/сompanyAddressTableData';

@injectable()
export default class CompanyAddressMappingService implements ICompanyAddressMappingService {
  // eslint-disable-next-line class-methods-use-this
  protected getIsHistoricTagData(isHistoric: boolean): DossierTagData {
    switch (isHistoric) {
      case true:
        return {
          theme: 'success',
          text: 'Актуальные сведения',
        };
      case false:
        return {
          theme: 'warning',
          text: 'Исторические сведения',
        };
      default:
        return {
          theme: 'warning',
          text: 'Исторические сведения',
        };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  protected getUnreliabilityData(value: string): boolean {
    switch (value.split(' ')[0]) {
      case 'Достоверный':
        return false;
      default:
        return true;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  protected getCodeSuitabilityMailingData(value: string): CompanyAddressTagData {
    switch (value) {
      case 'Пригоден для почтовой рассылки':
        return {
          text: value,
          theme: 'success',
        };

      case 'До почтового отделения — абонентский ящик или адрес до востребования.':
        return {
          text: value,
          theme: 'warning',
          tooltipText: 'Подходит для писем, но не для курьерской доставки.',
        };

      case 'Нет квартиры':
        return {
          text: value,
          theme: 'warning',
          tooltipText: 'Подходит для юридических лиц или частных владений',
        };

      case 'Дома нет в ФИАС':
      case 'Сначала проверьте, правильно ли Дадата разобрала исходный адрес':
        return {
          text: value,
          theme: 'warning',
        };
      case 'Нет региона':
      case 'Нет города':
      case 'Нет улицы':
      case 'Нет дома':
      case 'Адрес неполный':
      case 'Иностранный адрес':
        return {
          text: value,
          theme: 'danger',
        };
      default:
        return {
          text: value,
          theme: 'danger',
        };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  protected getVerificationData(verificationCode: string): CompanyAddressTagData {
    switch (verificationCode) {
      case 'Распознан уверенно':
        return {
          text: 'Адрес распознан уверенно',
          theme: 'success',
        };
      case 'Остались лишние части':
        return {
          text: verificationCode,
          theme: 'warning',
          tooltipText: 'Необходимо проверить вручную',
        };
      case 'Либо в исходном адресе недостаточно данных для уверенного разбора':
        return {
          text: verificationCode,
          theme: 'warning',
        };
      case 'Адрес пустой или заведомо «мусорный»':
        return {
          text: verificationCode,
          theme: 'danger',
        };
      case 'Есть альтернативные варианты':
        return {
          text: verificationCode,
          theme: 'common',
          tooltipText: 'Необходимо проверить вручную',
        };
      default:
        return {
          text: verificationCode,
          theme: 'common',
        };
    }
  }

  getCompanyAddressTableData(
    legalAddressesList: Array<TCompanyAdress>,
  ): Array<CompanyAddressTableData> {
    return legalAddressesList.map((curr) => ({
      value: curr.value,
      isHistoric: curr.isHistoric,
      enterDataDate: curr.enterDataDate,
      unreliabilityCode: this.getUnreliabilityData(curr.unreliabilityCode),
      unreliabilityDate: curr.unreliabilityDate,
      codeSuitabilityMailing: this.getCodeSuitabilityMailingData(curr.codeSuitabilityMailing),
      verificationCode: this.getVerificationData(curr.verificationCode),
      flatAreaStr: curr.flatAreaStr,
      postalBox: curr.postalBox,
      flatPriceStr: curr.flatPriceStr,
      federalDistrict: curr.federalDistrict,
      postalCode: curr.postalCode,
      flatType: curr.flatType,
      flatTypeFull: curr.flatTypeFull,
      flatArea: curr.flatArea,
      squareMeterPrice: curr.squareMeterPrice,
      flatPrice: curr.flatPrice,
      fiasId: curr.fiasId,
      fiasLevel: curr.fiasLevel,
      kladrCode: curr.kladrCode,
      capitalMarker: curr.capitalMarker,
      taxOffice: curr.taxOffice,
      taxOfficeLegal: curr.taxOfficeLegal,
      timeZone: curr.timeZone,
      geoLat: curr.geoLat,
      geoLon: curr.geoLon,
      house: curr.house,
      unparsedParts: curr.unparsedParts,
      nameStationMetros: curr.nameStationMetros,
    }));
  }
}
