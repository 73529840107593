import { injectable } from 'inversify';
import { IReportHistoryItemsService } from '@/modules/reports/common/services/reportHistoryItemsService.interface';
import { ReportHistoryItem } from '@/modules/reports/common/types/reportHistoryItem';
import { ReportStatusType } from '@/modules/reports/common/types/ReportStatusType';
import { ReportType } from '@/modules/reports/common/types/ReportType';
import { GuidUtils } from '@/modules/common/utils/guidUtils';
import { ReportTypeInput } from '@/modules/reports/common/types/ReportTypeInput';

@injectable()
export default class ReportHistoryItemsService implements IReportHistoryItemsService {
  hasDownloadPdfLink(rawData: ReportHistoryItem): boolean {
    return (
      rawData.status == ReportStatusType.complete && !GuidUtils.isEmptyOrNull(rawData.reportInfoId)
    );
  }

  hasDownloadJsonLink(rawData: ReportHistoryItem): boolean {
    return (
      rawData.status == ReportStatusType.complete &&
      !GuidUtils.isEmptyOrNull(rawData.reportInfoJsonId)
    );
  }

  hasWebLink(rawData: ReportHistoryItem): boolean {
    return (
      rawData.status == ReportStatusType.complete &&
      !GuidUtils.isEmptyOrNull(rawData.reportTaskId) &&
      ![ReportTypeInput.unknown, ReportTypeInput.photo, undefined].includes(rawData.inputType)
    );
  }

  canBeInterrupted(rawData: ReportHistoryItem): boolean {
    return rawData.status == ReportStatusType.progress && !GuidUtils.isEmptyOrNull(rawData.taskId);
  }

  canBeMigratedToSystem(rawData: ReportHistoryItem): boolean {
    return (
      rawData.status == ReportStatusType.complete &&
      !GuidUtils.isEmptyOrNull(rawData.taskId) &&
      !GuidUtils.isEmptyOrNull(rawData.parentId) &&
      !rawData.parentType != undefined &&
      !rawData.isMigrated
    );
  }

  canBeAddedToGraph(rawData: ReportHistoryItem): boolean {
    return false;
  }

  canBeExtendedReportGenerated(rawData: ReportHistoryItem): boolean {
    return rawData.reportType != ReportType.extended;
  }
}
