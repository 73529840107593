export const PLACEHOLDERS = {
  defaultType: 'Введите реквизит',
  fullNameType: 'Введите ФИО',
  phoneType: '(__) ___ __ __',
  carType: 'x000xx000',
  ogrnUl: '_________',
  innTypeUl: '__________',
  innTypePerson: '___________',
  innType: '____________',
};

export const LEGENDS: Record<string, string> = {
  fullNameType: "ФИО + Дата рождения",
  phoneType: 'Абонентский номер',
  carType: 'Гос. номер',
  ogrnUl: 'ОГРН/ОГРНИП',
  innTypeUl: 'ИНН ЮЛ',
  innType: 'ИНН ЮЛ',
  innTypePerson: 'ИНН ФЛ',
  birthday: 'ФИО + Дата рождения',
};

export const BIRTHDAY_PATTERN = /(\d{2})\.(\d{2})\.(\d{4})/;

export const TIMEOUT_FOR_STATUS = 1000;

const patterns = {
  everySymbol: {
    pattern: /[\w\W]/,
  },
  cyrillic: {
    pattern: /[а-яёА-ЯЁ]/,
  },
  cyrillicSpace: {
    pattern: /[а-яёА-ЯЁ\s-]/,
  },
  cyrillicAndLatin: {
    pattern: /[a-zA-Zа-яА-я]/,
  },
  digits: {
    pattern: /[0-9]/,
  },
  firstNumberPhone: {
    pattern: /[7-8]/,
  },
  upperCyrillicAndLatin: {
    pattern: /[a-zA-Zа-яА-я\s]/,
    transform: (v: string) => v.toLocaleUpperCase(),
  },
  upperCarLatinic: {
    pattern: /[авекмнорстух]/i,
    transform: (v: string) => v.toLocaleUpperCase(),
  },
};

export const MASKS = {
  every: {
    mask: ['EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE'],
    tokens: {
      E: patterns.everySymbol,
    },
  },
  innMask: ['####-######-##'],
  innMaskUl: ['####-#####-#'],
  phoneNumberMask: {
    mask: ['+C BBB BBB BB BB'],
    tokens: {
      C: patterns.firstNumberPhone,
      B: patterns.digits,
    },
  },
  transportNumberMask: {
    mask: ['RDDDRRDDD'],
    tokens: {
      R: patterns.upperCarLatinic,
      D: patterns.digits,
    },
  },
  fullname: {
    mask: [
      'FEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
    ],
    tokens: {
      E: patterns.cyrillicSpace,
      F: patterns.cyrillic,
    },
  },
  birthday: ['##.##.####'],
};

export const coefficientINN = [2, 4, 10, 3, 5, 9, 4, 6, 8];
export const firstCoefficientINNUl = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
export const secondCoefficientINNUl = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8];

export const DATES_AND_TIMES = [
  '21.12.2021, 16:28:09',
  '21.12.2021, 11:28:01',
  '21.12.2021, 10:28:02',
  '21.12.2021, 16:28:09',
  '21.12.2021, 11:28:01',
];
