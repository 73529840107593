import { DossierTagData } from '@/modules/common/types/dossierTag';
import {
  IRegulationsDecisionsService
} from '@/modules/regulationsCheck/common/services/regulationDecisionsService.interface';
import {
  RegulationsCheckDecision
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckDecision';
import { injectable } from 'inversify';

@injectable()
export default class RegulationsDecisionsService implements IRegulationsDecisionsService {
  getRegulationDecisions(status: RegulationsCheckDecision | undefined): DossierTagData | string {
    switch (status) {
      case RegulationsCheckDecision.Declined:
        return { text: 'Отклонить', theme: 'danger' };
      case RegulationsCheckDecision.Agreed:
      case RegulationsCheckDecision.AgreedWithComment:
        return { text: 'Согласовать', theme: 'success' };
      default:
        return '';
    }
  }
}
