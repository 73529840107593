import { injectable, inject } from 'inversify';

import { TPassportChecksTableRow } from '@/modules/dossier/person/passports/types/passportChecksTableRow';

import { IPersonPassportCheckingMappingService } from '@/modules/dossier/person/passports/services/mapping/personPassportCheckingMappingService.interface';

import { ApiPersonDetailPassportComplexChecks } from '@/modules/dossier/person/common/types/api/apiPersonDetailPassportComplexChecks';
import { ApiPersonDetailPassports } from '@/modules/dossier/person/common/types/api/apiPersonDetailPassports';
import { ApiPersonDetailInnPassportAccordanceChecks } from '@/modules/dossier/person/common/types/api/apiPersonDetailInnPassportAccordanceChecks';

import {
  IPersonPassportComplexCheckingParserServiceKey,
  IPersonPassportComplexCheckingParserService,
} from '@/modules/dossier/person/passports/services/parsers/personPassportComplexCheckingParserService.interface';
import {
  IPersonPassportInnCheckingParserServiceKey,
  IPersonPassportInnCheckingParserService,
} from '@/modules/dossier/person/passports/services/parsers/personPassportInnCheckingParserService.interface';
import { InnResultCheckingEnum } from '@/modules/dossier/person/passports/types/innResultCheckingEnum';

@injectable()
export default class PersonPassportCheckingMappingService
  implements IPersonPassportCheckingMappingService
{
  constructor(
    @inject(IPersonPassportComplexCheckingParserServiceKey as symbol)
    private PersonPassportComplexCheckingParserService: IPersonPassportComplexCheckingParserService,
    @inject(IPersonPassportInnCheckingParserServiceKey as symbol)
    private PersonPassportInnCheckingParserService: IPersonPassportInnCheckingParserService,
  ) {}

  // TODO костыль который надо будет убрать когда бэк будет возвращать числовые значение
  getInnCheckingStatus(value?: string): number {
    switch (value) {
      case 'Паспортные данные соответствуют ИНН физического лица':
        return InnResultCheckingEnum.corresponds;
      case 'Сведения не соответствуют':
        return InnResultCheckingEnum.notCorresponds;
      case 'Сведения не были найдены':
        return InnResultCheckingEnum.notFound;
      default:
        return InnResultCheckingEnum.notFound;
    }
  }

  mapPassportCheckingDataTable(
    passportsList: ApiPersonDetailPassports,
    complexChecksList: ApiPersonDetailPassportComplexChecks,
    innChecksList: ApiPersonDetailInnPassportAccordanceChecks,
  ): Array<TPassportChecksTableRow> {
    return passportsList.list.map((passportItem) => {
      const complexCheck = this.PersonPassportComplexCheckingParserService.parse(
        complexChecksList,
        passportItem,
      );

      const innCheck = this.PersonPassportInnCheckingParserService.parse(
        innChecksList,
        passportItem,
      );

      return {
        id: passportItem.id,
        resultOfComplexChecking: complexCheck?.score,
        resultOfInnChecking: this.getInnCheckingStatus(innCheck?.validationMessage),
        passportType: passportItem.passportType,
        passportSeries: passportItem.passportSeries,
        passportNumber: passportItem.passportNumber,
        passportIssuedDate: passportItem.passportIssuedDate,
        passportIssuedBy: passportItem.passportIssuedBy || '-',
        passportDepartmentCode: passportItem.passportDepartmentCode || '-',
        initialSeriesAndNumber: '-',
        initialCodeDepartament: '-',
        departmentDadataType: passportItem.departmentDadataType,
        regionCode: passportItem.regionCode || '-',
        verificationCode: passportItem.verificationCode,
      };
    });
  }
}
