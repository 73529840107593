import { PassportTypes } from '@/modules/dossier/person/common/types/api/passportTypes';
import { UITagTheme } from '@/modules/tags/types/tagTheme';
import { InjectionKey } from 'vue';

export interface IMappedTag {
  label: string;
  type: UITagTheme;
}
export interface IMapperToTag<T> {
  format(value: T): IMappedTag;
}

export type IPassportTypeMapper = IMapperToTag<PassportTypes>;
export const IPassportTypeMapperKey: InjectionKey<IPassportTypeMapper> =
  Symbol('IPassportTypeMapper');
