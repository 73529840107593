import { injectable, inject } from 'inversify';
import { IRegulationsChecksParametersModesMapperService } from '@/modules/regulationsCheck/common/services/regulationsChecksParametersModesMapperService.interfaces';
import { RiskDetailRateParameter } from '@/modules/regulationsCheck/common/types/riskDetailRateParameter';
import { RateUpdateParametersMode } from '@/modules/regulationsCheck/common/types/rateUpdateParametersMode';
import {
  IRegulationsChecksRateNameMapperServiceKey,
  IRegulationsChecksRateNameMapperService,
} from '@/modules/regulationsCheck/common/services/regulationsChecksRateNameMapperService.interfaces';

@injectable()
export default class RegulationsChecksParametersModesMapperService
  implements IRegulationsChecksParametersModesMapperService
{
  constructor(
    @inject(IRegulationsChecksRateNameMapperServiceKey as symbol)
    private regulationsChecksRateNameMapperService: IRegulationsChecksRateNameMapperService,
  ) {}

  map({
    parameterId,
    nameEng,
    mode,
  }: RiskDetailRateParameter): RateUpdateParametersMode | undefined {
    const name = this.regulationsChecksRateNameMapperService.map(nameEng);

    if (name == undefined) {
      return undefined;
    }

    return {
      parameterId,
      name,
      mode,
    };
  }
}
