import { InjectionKey } from 'vue';
import { RegulationInputTypes } from '@/modules/regulationsCheck/common/types/regulationInputTypes';

export interface IRegulationsInputTypesService {
  getRegulationInputTypes(status: RegulationInputTypes): string;
}

export const IRegulationsInputTypesServiceKey: InjectionKey<IRegulationsInputTypesService> = Symbol(
  'IRegulationsInputTypesServiceKey',
);
