import { IPersonSocialNetworksMappingService } from '@/modules/dossier/person/socialNetworks/services/personSocialNetworksMappingService.interface';
import { PersonSocialNetworkTableItem } from '@/modules/dossier/person/socialNetworks/types/personSocialNetworkTableItem';
import { injectable } from 'inversify';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { ApiPersonDetailSocialNetworksRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailSocialNetworksRecord';

@injectable()
export default class PersonSocialNetworksMappingService
  implements IPersonSocialNetworksMappingService
{
  mapPersonSocialNetworksDataTable(
    socialNetworks: Array<ApiPersonDetailSocialNetworksRecord>,
  ): Array<PersonSocialNetworkTableItem> {
    return socialNetworks.map((item: ApiPersonDetailSocialNetworksRecord) => ({
      type: item.type,
      isVerified: {
        theme: item.isVerified ? 'success' : 'danger',
        name: item.isVerified
          ? SystemIconsNames.checkedRoundedIcon
          : SystemIconsNames.closeRoundedIcon,
        label: item.isVerified ? 'Верифицирована' : 'Неверифицирована',
      },
      socialId: item.socialId,
    }));
  }
}
