import { ReportHistoryItemDTO } from '@/modules/reports/common/types/reportHistoryItemDTO';
import { InjectionKey } from 'vue';
import { THistoryReportApi } from '@/modules/reports/desktop/types/THistoryReportApi';

export interface IReportsHistoryItemDTOMappingService {
  map(item: THistoryReportApi): ReportHistoryItemDTO;
  mapReverse(item: ReportHistoryItemDTO): THistoryReportApi;
}
export const IReportsHistoryItemDTOMappingServiceKey: InjectionKey<IReportsHistoryItemDTOMappingService> =
  Symbol('IReportsHistoryItemDTOMappingService');
