import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IPersonSocialNetworkAdditionalInfoVKMainInfoFormatterKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoFormatter.interface';
import PersonSocialNetworkAdditionalInfoVKMainInfoFormatter from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoFormatter';
import personSocialNetworkAdditionalInfoVkGalleryMappingService from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/mappings/personSocialNetworkAdditionalInfoVkGalleryMappingService';
import { IPersonSocialNetworkAdditionalInfoVkGalleryMappingServiceKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/mappings/personSocialNetworkAdditionalInfoVkGalleryMappingService.interface';
import { IPersonSocialNetworkAdditionalInfoVKGetLastValueServiceKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/parsers/personSocialNetworkAdditionalInfoVKGetLastValueService.interface';
import PersonSocialNetworkAdditionalInfoVKGetLastValueService from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/parsers/personSocialNetworkAdditionalInfoVKGetLastValueService';
import { IPersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatterKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter.interface';
import PersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter';
import { IPersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatterKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoTitledFormatter.interface';
import PersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoTitledFormatter';
import { IPersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatterKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoByDateFormatter.interface';
import PersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatter from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoByDateFormatter';
import { IPersonSocialNetworkAdditionalInfoVkUserLinkServiceKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/parsers/personSocialNetworkAdditionalInfoVkUserLinkService.interface';
import personSocialNetworkAdditionalInfoVkUserLinkService from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/parsers/personSocialNetworkAdditionalInfoVkUserLinkService';
import { IPersonSocialNetworkAdditionalInfoVkUserInfoFormatterKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVkUserInfoFormatter.interface';
import PersonSocialNetworkAdditionalInfoVkUserInfoFormatter from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVkUserInfoFormatter';
import { IDaDataMapCoordinatesServiceKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/common/services/DaDataMapCoordinatesService.interface';
import DaDataMapCoordinatesService from '@/modules/dossier/person/socialNetworkAdditionalInfo/common/services/DaDataMapCoordinatesService';
import { IPersonSocialNetworkAdditionalInfoVKCardInfoFormatterKey } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKCardInfoFormatter.interface';
import PersonSocialNetworkAdditionalInfoVKCardInfoFormatter from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKCardInfoFormatter';

export function bindPersonSocialNetworkAdditionalInfoVKServices(bind: IContainerBinder) {
  bind(IPersonSocialNetworkAdditionalInfoVKMainInfoFormatterKey).to(
    PersonSocialNetworkAdditionalInfoVKMainInfoFormatter,
  );
  bind(IPersonSocialNetworkAdditionalInfoVkGalleryMappingServiceKey).to(
    personSocialNetworkAdditionalInfoVkGalleryMappingService,
  );
  bind(IPersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatterKey).to(
    PersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter,
  );
  bind(IPersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatterKey).to(
    PersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter,
  );
  bind(IPersonSocialNetworkAdditionalInfoVKGetLastValueServiceKey).to(
    PersonSocialNetworkAdditionalInfoVKGetLastValueService,
  );
  bind(IPersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatterKey).to(
    PersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatter,
  );

  bind(IPersonSocialNetworkAdditionalInfoVkUserLinkServiceKey).to(
    personSocialNetworkAdditionalInfoVkUserLinkService,
  );
  bind(IPersonSocialNetworkAdditionalInfoVkUserInfoFormatterKey).to(
    PersonSocialNetworkAdditionalInfoVkUserInfoFormatter,
  );
  bind(IPersonSocialNetworkAdditionalInfoVKCardInfoFormatterKey).to(
    PersonSocialNetworkAdditionalInfoVKCardInfoFormatter,
  );
  bind(IDaDataMapCoordinatesServiceKey).to(DaDataMapCoordinatesService);
}
