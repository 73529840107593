import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import TasksService from '@/modules/tasks/services/tasksService/tasksService';
import { ITasksServiceKey } from '@/modules/tasks/services/tasksService/tasksService.interfaces';
import TaskStatusInfoDTOMappingService
  from '@/modules/tasks/services/taskStatusInfoDTOMappingService/taskStatusInfoDTOMappingService';
import {
  ITaskStatusInfoDTOMappingServiceKey
} from '@/modules/tasks/services/taskStatusInfoDTOMappingService/taskStatusInfoDTOMappingService.interfaces';
import TaskStatusToApiStatusMapperService
  from '@/modules/tasks/services/taskStatusToApiStatusMapperService/taskStatusToApiStatusMapperService';
import {
  ITaskStatusToApiStatusMapperServiceKey
} from '@/modules/tasks/services/taskStatusToApiStatusMapperService/taskStatusToApiStatusMapperService.interfaces';

export function bindTaskServices(bind: IContainerBinder) {
  bind(ITasksServiceKey).to(TasksService);
  bind(ITaskStatusToApiStatusMapperServiceKey).to(TaskStatusToApiStatusMapperService);
  bind(ITaskStatusInfoDTOMappingServiceKey).to(TaskStatusInfoDTOMappingService);
}
