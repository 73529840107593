import {
  IGraphStatsPlaceholderService
} from '@/modules/graph/table/services/graphStatsPlaceholderService.interfaces';
import { ApiGraphResultStats } from '@/modules/graph/table/types/api/apiGraphResultStats';
import { injectable } from 'inversify';

@injectable()
export default class GraphStatsPlaceholderService implements IGraphStatsPlaceholderService {
  getStats(): ApiGraphResultStats {
    return {
      serverDataGenerationTimeDisplay: '',
      serverDataGenerationTimePartHours: 0,
      serverDataGenerationTimePartMilliseconds: 0,
      serverDataGenerationTimePartMinutes: 0,
      serverDataGenerationTimePartSeconds: 0,
      serverDataGenerationTimeTotalSeconds: 0,
      totalEdges: 0,
      totalEmployees: 0,
      totalFoundEmployees: 0,
      totalInitEmployees: 0,
      totalNodes: 0,
      totalRelationObjects: 0,
    }
  }
}
