export module StringUtils {
  export function isString(value: any): value is string {
    return typeof value == 'string';
  }

  export function isLikeEquals(str1: string, str2: string) {
    return str1.trim().toLowerCase() == str2.trim().toLowerCase();
  }

  export function isLikeSubstring(str1: string, str2: string) {
    return str1.trim().toLowerCase().includes(str2.trim().toLowerCase());
  }

  export function getLowerCaseTrimFormat(value: string): string {
    return value.trim().toLowerCase().replaceAll("'", '"').replaceAll(/  +/g, ' ');
  }

  export function checkEqualStrings(firstValue: string, secondValue: string): boolean {
    return getLowerCaseTrimFormat(firstValue) == getLowerCaseTrimFormat(secondValue);
  }

  export function flatten(value: string, delimiter = ''): string {
    return value.replace(/[\n\r]/g, delimiter);
  }

  export function isStringEmpty(value?: string): boolean {
    const trimmedValue = value?.trim();
    return trimmedValue == undefined || trimmedValue.length == 0;
  }

  export function isStringNotEmpty(value?: string): boolean {
    return !isStringEmpty(value);
  }

  export function getValueOrPlaceholder(value?: string, placeholder = '-'): string {
    if (value == undefined || value == '') {
      return placeholder;
    }

    return value;
  }
}
