import { InjectionKey } from 'vue';
import { TCompanyAdress } from '@/types/companyData/companyData';
import { CompanyAddressTableData } from '@/modules/dossier/company/address/types/сompanyAddressTableData';

export interface ICompanyAddressMappingService {
  getCompanyAddressTableData(
    legalAddressesList: Array<TCompanyAdress>,
  ): Array<CompanyAddressTableData>;
}

export const ICompanyAddressMappingServiceKey: InjectionKey<ICompanyAddressMappingService> = Symbol(
  'ICompanyAddressMappingServiceKey',
);
