import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { IElasticService } from '@/modules/elastic/services/elasticService.interfaces';
import { ApiElasticField } from '@/modules/elastic/types/api/apiElasticField';
import { ApiElasticRequest } from '@/modules/elastic/types/api/apiElasticRequest';
import { ApiElasticResponse } from '@/modules/elastic/types/api/apiElasticResponse';
import {
  ApiElasticSimpleRequestOptions
} from '@/modules/elastic/types/api/apiElasticSimpleRequestOptions';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class ElasticService implements IElasticService {
  constructor(
    @inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance
  ) { }

  searchAdvanced(payload: ApiElasticRequest): Promise<AxiosResponse<ApiElasticResponse>> {
    return this.apiEndpoint.post('/proxies/elastic/query?queryPath=_search', payload);
  }

  search(query: string, fields: ApiElasticField[] = [], options?: ApiElasticSimpleRequestOptions): Promise<AxiosResponse<ApiElasticResponse>> {
    const payload: ApiElasticRequest = {
      size: options?.size ?? 10000,
      highlight: {
        fields: { '*.*.values': {} },
        pre_tags: ['<span style="color:#0E193B">'],
        post_tags: ['</span>'],
      },

      query: {
        multi_match: {
          query,
          type: options?.type,
          fuzziness: options?.fuzzyness
        }
      }
    }

    if (fields.length > 0)
      payload.query.multi_match.fields = fields.map(x => `*.${x}.values`);

    return this.searchAdvanced(payload);
  }
}
