import { AccessScopeEnum } from '@/modules/rights/type/AccessScopeEnum';
import RouteNames from '@/modules/router/common/types/routeNames';
import { RouteRecordRaw } from 'vue-router';

export const reportsRoutes: RouteRecordRaw[] = [
  {
    path: '/reports-v1',
    name: RouteNames.REPORTS_ROOT_V1,
    component: () => import('@/modules/reports/desktop/views/ReportsRootViewV1.vue'),
    redirect: { name: RouteNames.REPORTS_CONFIGURATION_V1 },
    children: new Array<RouteRecordRaw>(
      {
        path: 'configuration-v1',
        name: RouteNames.REPORTS_CONFIGURATION_V1,
        component: () => import('@/modules/reports/desktop/views/ReportsConfigurationViewV1.vue'),
      },
      {
        path: 'history-v1',
        name: RouteNames.REPORTS_HISTORY_V1,
        component: () => import('@/modules/reports/desktop/views/ReportsHistoryView.vue'),
      },
    ),
  },
  {
    path: '/reports',
    name: RouteNames.REPORTS_ROOT_V2,
    component: () => import('@/modules/reports/desktop/views/ReportsRootViewV2.vue'),
    redirect: { name: RouteNames.REPORTS_CONFIGURATION_V2 },
    children: new Array<RouteRecordRaw>(
      {
        path: 'configuration',
        name: RouteNames.REPORTS_CONFIGURATION_V2,
        component: () => import('@/modules/reports/desktop/views/ReportsConfigurationViewV2.vue'),
        redirect: { name: RouteNames.REPORTS_CONFIGURATION_PERSON },
        children: new Array<RouteRecordRaw>(
          {
            path: 'person',
            name: RouteNames.REPORTS_CONFIGURATION_PERSON,
            component: () =>
              import('@/modules/reports/desktop/views/ReportsConfigurationPersonView.vue'),
          },
          {
            path: 'company',
            name: RouteNames.REPORTS_CONFIGURATION_COMPANY,
            component: () =>
              import('@/modules/reports/desktop/views/ReportsConfigurationCompanyView.vue'),
          },
        ),
      },
      {
        path: 'history',
        name: RouteNames.REPORTS_HISTORY_V2,
        component: () => import('@/modules/reports/desktop/views/ReportsHistoryView.vue'),
      },
    ),
  },
  {
    path: '/reports/configuration/input/person',
    name: RouteNames.REPORTS_REQUISITE_INPUT_PERSON,
    component: () => import('@/modules/reports/desktop/views/ReportsRequisiteInputPersonView.vue'),
    props: route => ({
      requisiteId: route.query.requisiteId,
      requisiteValue: route.query.requisiteValue,
    }),
  },
  {
    path: '/reports/configuration/input/company',
    name: RouteNames.REPORTS_REQUISITE_INPUT_COMPANY,
    component: () => import('@/modules/reports/desktop/views/ReportsRequisiteInputCompanyView.vue'),
    props: route => ({
      requisiteId: route.query.requisiteId,
      requisiteValue: route.query.requisiteValue,
    }),
  },
  {
    path: '/mobile-reports',
    name: RouteNames.MOBILE_REPORTS_ROOT,
    component: () => import('@/modules/reports/mobile/common/views/MobileReportsRootView.vue'),
    access: () => true,
    children: new Array<RouteRecordRaw>(
      {
        path: 'configuration',
        name: RouteNames.MOBILE_REPORTS_CONFIGURATION,
        component: () => import('@/modules/reports/mobile/home/views/MobileReportsHomeView.vue'),
        access: () => true,
      } as RouteRecordRaw,
      {
        path: 'history',
        name: RouteNames.MOBILE_REPORTS_HISTORY,
        component: () =>
          import('@/modules/reports/mobile/history/general/views/MobileReportsHistoryView.vue'),
        access: () => true,
      } as RouteRecordRaw,
      {
        path: 'reqisite-input',
        name: RouteNames.MOBILE_REPORTS_REQUISITE_INPUT,
        component: () =>
          import(
            '@/modules/reports/mobile/requisiteInput/views/MobileReportsRequisiteInputView.vue'
          ),
        access: () => true,
        props: route => ({
          entityType: route.query.entityType,
          requisiteId: route.query.requisiteId,
          requisiteValue: route.query.requisiteValue,
        }),
      } as RouteRecordRaw,
      {
        path: 'progress',
        name: RouteNames.MOBILE_REPORTS_PROGRESS,
        component: () =>
          import('@/modules/reports/mobile/reportsProgress/views/MobileReportsProgressView.vue'),
        access: () => true,
        props: route => ({
          reportTaskId: route.query.reportTaskId,
        }),
      } as RouteRecordRaw,
    ),
  } as RouteRecordRaw,
  {
    path: '/reports-old',
    name: RouteNames.REPORTS_OLD,
    component: () => import('@/modules/reports/oldReports/ReportsView.vue'),
    access: () => true,
  } as RouteRecordRaw,
  {
    path: '/collection-external-data',
    name: RouteNames.COLLECTION_EXTERNAL_DATA,
    component: () =>
      import('@/modules/reports/oldCollectionExternalData/CollectionExternalDataRootView.vue'),
    access: () => true,
    meta: { accessScopes: [AccessScopeEnum.hasAccessToCollection] },
  } as RouteRecordRaw,
  {
    path: '/history-external-data',
    name: RouteNames.HISTORY_EXTERNAL_DATA,
    component: () =>
      import('@/modules/reports/oldHistoryExternalData/HistoryExternalDataRootView.vue'),
    access: () => true,
    meta: { accessScopes: [AccessScopeEnum.hasAccessToHistoryExternalData] },
  } as RouteRecordRaw,
];
