import { ApiGridUlRecord } from '@/modules/dataGrid/company/types/api/apiGridUlRecord';
import { ApiGridPersonRecord } from '@/modules/dataGrid/person/types/api/apiGridPersonRecord';
import { ApiDataTableFieldProfile } from '@/modules/profile/types/api/apiDataTableFieldProfile';

export type TEntitiesGridType = 'person' | 'company';

export type TGridColumnValues = { display: string; value: string };
export type TGridSettings = {
  fieldCode: string;
  index: number;
  sortIndex?: number;
  sortOrder: number;
};

export type GridMainState = {
  personSettings: ApiDataTableFieldProfile[];
  companySettings: ApiDataTableFieldProfile[];
  personsData: ApiGridPersonRecord[];
  companysData: ApiGridUlRecord[];
  personDataCount: number;
  companyDataCount: number;
  skip: number;
  take: number;
  allPersonsData: ApiGridPersonRecord[];
  allCompanysData: ApiGridUlRecord[];
  uniqCompanysData: ApiGridUlRecord[];
  uniqPersonData: ApiGridPersonRecord[];
  columnValues: TGridColumnValues[];
  applyedFilter: {
    filter?: Array<Array<string> | string>;
    sort?: { selector: string; desc: boolean }[];
  };
};

export enum MutationTypesEnum {
  SET_PERSON_SETTINGS = 'SET_PERSON_SETTINGS',
  SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS',
  SET_COMPANY_DATA = 'SET_COMPANY_DATA',
  SET_PERSON_DATA = 'SET_PERSON_DATA',
  SET_DATA_PERSON_COUNT = 'SET_DATA_PERSON_COUNT',
  SET_DATA_COMPANY_COUNT = 'SET_DATA_COMPANY_COUNT',
  SET_ALL_DATA_COMPANY = 'SET_ALL_DATA_COMPANY',
  SET_ALL_DATA_PERSONS = 'SET_ALL_DATA_PERSONS',
  SET_UNIQ_DATA_PERSONS = 'SET_UNIQ_DATA_PERSONS',
  SET_UNIQ_DATA_COMPANY = 'SET_UNIQ_DATA_COMPANY',
  SET_SKIP = 'SET_SKIP',
  SET_TAKE = 'SET_TAKE',
  SET_COLUMN_VALUES = 'SET_COLUMN_VALUES',
  SET_APPLYED_FILTER = 'SET_APPLYED_FILTER',
}

export type Mutations<S = GridMainState> = {
  [MutationTypesEnum.SET_PERSON_SETTINGS](
    state: S,
    payload: Pick<GridMainState, 'personSettings'>,
  ): void;
  [MutationTypesEnum.SET_COMPANY_SETTINGS](
    state: S,
    payload: Pick<GridMainState, 'companySettings'>,
  ): void;
  [MutationTypesEnum.SET_COMPANY_DATA](
    state: S,
    payload: Pick<GridMainState, 'companysData'>,
  ): void;
  [MutationTypesEnum.SET_PERSON_DATA](state: S, payload: Pick<GridMainState, 'personsData'>): void;
  [MutationTypesEnum.SET_DATA_COMPANY_COUNT](
    state: S,
    payload: Pick<GridMainState, 'companyDataCount'>,
  ): void;
  [MutationTypesEnum.SET_DATA_PERSON_COUNT](
    state: S,
    payload: Pick<GridMainState, 'personDataCount'>,
  ): void;
  [MutationTypesEnum.SET_SKIP](state: S, payload: number): void;
  [MutationTypesEnum.SET_TAKE](state: S, payload: number): void;
  [MutationTypesEnum.SET_ALL_DATA_PERSONS](state: S, payload: ApiGridPersonRecord[]): void;
  [MutationTypesEnum.SET_ALL_DATA_COMPANY](state: S, payload: ApiGridUlRecord[]): void;
  [MutationTypesEnum.SET_UNIQ_DATA_COMPANY](state: S, payload: ApiGridUlRecord[]): void;
  [MutationTypesEnum.SET_UNIQ_DATA_PERSONS](state: S, payload: ApiGridPersonRecord[]): void;
  [MutationTypesEnum.SET_COLUMN_VALUES](state: S, payload: TGridColumnValues[]): void;
  [MutationTypesEnum.SET_APPLYED_FILTER](
    state: S,
    payload: {
      filter?: Array<Array<string> | string>;
      sort?: { selector: string; desc: boolean }[];
    },
  ): void;
};

export type Getters = {
  gridMainPersonPages(state: GridMainState): number;
  gridMainCompanyPages(state: GridMainState): number;
};

export enum ActionTypesEnum {
  GET_PERSON_SETTINGS = 'GET_PERSON_SETTINGS',
  GET_COMPANY_SETTINGS = 'GET_COMPANY_SETTINGS',
  GET_COMPANY_DATA = 'GET_COMPANY_DATA',
  GET_PERSON_DATA = 'GET_PERSON_DATA',
  GET_COLUMN_VALUES = 'GET_COLUMN_VALUES',
}

export type TTypeGrid = 'person' | 'ul';
