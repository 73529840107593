import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { ApiNewPersonListData } from '@/modules/lists/types/api/fl/apiNewPersonListData';
import { ApiPersonListAppendRequest } from '@/modules/lists/types/api/fl/apiPersonListAppendRequest';
import { ApiPersonListCreationRequest } from '@/modules/lists/types/api/fl/apiPersonListCreationRequest';
import { ApiPersonListUpdateRequest } from '@/modules/lists/types/api/fl/apiPersonListUpdateRequest';
import { ApiPersonListData } from '@/modules/lists/types/api/fl/apiPersonListData';
import { ApiPersonListDetails } from '@/modules/lists/types/api/fl/apiPersonListDetails';
import { ApiPersonListRemoveItemsRequest } from '@/modules/lists/types/api/fl/apiPersonListRemoveItemsRequest';
import { ApiPersonListRemoveRequest } from '@/modules/lists/types/api/fl/apiPersonListRemoveRequest';
import { AxiosResponse } from 'axios';
import { InjectionKey } from 'vue';

export interface IPersonListsService {
  getLists(): Promise<AxiosResponse<ApiResponse<ApiPersonListData>>>;
  createList(
    payload: ApiPersonListCreationRequest,
  ): Promise<AxiosResponse<ApiResponse<ApiNewPersonListData>>>;
  updateList(payload: ApiPersonListUpdateRequest): Promise<AxiosResponse<ApiResponse<string>>>;
  addToList(payload: ApiPersonListAppendRequest): Promise<AxiosResponse<ApiResponse<string>>>;
  deleteList(payload: ApiPersonListRemoveRequest): Promise<AxiosResponse<ApiResponse<string>>>;
  getListDetails(id: string): Promise<AxiosResponse<ApiResponse<ApiPersonListDetails>>>;
  deleteEntities(
    payload: ApiPersonListRemoveItemsRequest,
  ): Promise<AxiosResponse<ApiResponse<string>>>;
}

export const IPersonListsServiceKey: InjectionKey<IPersonListsService> =
  Symbol('IPersonListsService');
