type EffectCleanupFunc = () => void

export function useEffects() {
  let callbacks = new Array<() => void>();

  function register(callback: EffectCleanupFunc) {
    callbacks.push(callback);
  }

  function dispose() {
    for(const callback of callbacks)
      callback();

    callbacks = [];
  }

  return { register, dispose }
}
