import { InjectionKey } from 'vue';
import { ApiPersonDetailVehiclesRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVehiclesRecord';
import { NormalizedApiPersonDetailVehiclesRecord } from '@/modules/dossier/person/vehicles/cars/types/normalizedApiPersonDetailVehiclesRecord';

export interface IPersonCarsNormalizationService {
  normalize(item: ApiPersonDetailVehiclesRecord): NormalizedApiPersonDetailVehiclesRecord;
}

export const IPersonCarsNormalizationServiceKey: InjectionKey<IPersonCarsNormalizationService> =
  Symbol('IPersonCarsNormalizationService');
