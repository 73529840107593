import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import ElasticCompanyFieldFormatter
  from '@/modules/elastic/services/elasticCompanyFieldFormatter/elasticCompanyFieldFormatter';
import {
  IElasticCompanyFieldFormatterKey
} from '@/modules/elastic/services/elasticCompanyFieldFormatter/elasticCompanyFieldFormatter.interfaces';
import ElasticHighlightParser from '@/modules/elastic/services/elasticHighlightParser';
import {
  IElasticHighlightParserKey
} from '@/modules/elastic/services/elasticHighlightParser.interfaces';
import ElasticPassportParser from '@/modules/elastic/services/elasticPassportParser';
import {
  IElasticPassportParserKey
} from '@/modules/elastic/services/elasticPassportParser.interfaces';
import ElasticPersonFieldFormatter
  from '@/modules/elastic/services/elasticPersonFieldFormatter/elasticPersonFieldFormatter';
import {
  IElasticPersonFieldFormatterKey
} from '@/modules/elastic/services/elasticPersonFieldFormatter/elasticPersonFieldFormatter.interfaces';
import ElasticService from '@/modules/elastic/services/elasticService';
import { IElasticServiceKey } from '@/modules/elastic/services/elasticService.interfaces';

export function bindElasticService(bind: IContainerBinder) {
  bind(IElasticServiceKey).to(ElasticService);
  bind(IElasticCompanyFieldFormatterKey).to(ElasticCompanyFieldFormatter);
  bind(IElasticPersonFieldFormatterKey).to(ElasticPersonFieldFormatter);
  bind(IElasticPassportParserKey).to(ElasticPassportParser);
  bind(IElasticHighlightParserKey).to(ElasticHighlightParser);
}
