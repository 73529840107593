import { injectable } from 'inversify';
import { CompanySocialNetwork } from '@/modules/dossier/company/socials/types/socialNetworks';
import { ICompanySocialNetworksMappingService } from '@/modules/dossier/company/socials/services/companySocialNetworksMappingService.interface';
import { CompanySocialNetworksTableData } from '@/modules/dossier/company/socials/types/companySocialNetworksTableData';

@injectable()
export default class CompanySocialNetworksMappingService
  implements ICompanySocialNetworksMappingService
{
  protected compareSocialNetworks(
    a: CompanySocialNetworksTableData,
    b: CompanySocialNetworksTableData,
  ) {
    if (a.isVerified && !b.isVerified) {
      return -1;
    }
    if (!a.isVerified && b.isVerified) {
      return 1;
    }

    return 0;
  }

  getCompanySocialNetworksTableData(socialNetworks: Array<CompanySocialNetwork>) {
    return socialNetworks
      .map((socialNetwork) => ({
        type: socialNetwork.type,
        socialId: socialNetwork.socialId,
        linkToProfile: socialNetwork.linkToProfile,
        isVerified: socialNetwork.isVerified,
      }))
      .sort(this.compareSocialNetworks)
      .sort((a, b) => a.type - b.type);
  }
}
