import { injectable } from 'inversify';
import { IPersonArbitrationMappingService } from '@/modules/dossier/person/arbitration/services/personArbitrationMappingService.interface';
import { DossierTagData } from '@/modules/common/types/dossierTag';
import { ApiArbitrationCase } from '@/modules/dossier/person/common/types/api/apiArbitrationCase';

@injectable()
export default class PersonArbitrationMappingService implements IPersonArbitrationMappingService {
  // eslint-disable-next-line class-methods-use-this
  getArbitrationStatusTagData(status: string): DossierTagData {
    switch (status) {
      case 'Рассматривается в первой инстанции':
        return { theme: 'danger', text: status };
      case 'Рассмотрение дела завершено':
        return { theme: 'success', text: status };
      case 'Рассматривается в апелляционной инстанции':
        return { theme: 'common', text: status };
      default:
        return { theme: 'common', text: 'Неизвестно' };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getArbitrationByStatus(
    arbitrationList: Array<ApiArbitrationCase>,
    status: string,
  ): Array<ApiArbitrationCase> {
    return arbitrationList.filter((arbitration) => arbitration.status == status);
  }

  // eslint-disable-next-line class-methods-use-this
  getArbitrationByRole(
    arbitrationList: Array<ApiArbitrationCase>,
    role: string,
  ): Array<ApiArbitrationCase> {
    return arbitrationList.filter(
      (arbitration) => arbitration.arbitrationIndividualTypeStr == role,
    );
  }
}
