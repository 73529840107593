import {
  DxXlsxExportConfiguration
} from '@/modules/devExtreme/export/xlsx/configuration/dxXlsxExportConfiguration';
import { IDxXlsxCellFormatter } from '@/modules/devExtreme/export/xlsx/services/dxXlsxCellFormatter/dxXlsxCellFormatter.interfaces';
import { DxTableExtendedColumn } from '@/modules/devExtreme/types/dxTableExtendedColumn';
import { ExcelDataGridCell } from 'devextreme/excel_exporter';
import { injectable } from 'inversify';

@injectable()
export default class DxXlsxHeaderCellFormatter implements IDxXlsxCellFormatter {
  cellType = 'header'

  format(excelCell: any, gridCell: ExcelDataGridCell, dxColumn: DxTableExtendedColumn) {
    excelCell.font = {
      name: DxXlsxExportConfiguration.defaultFontName,
      size: DxXlsxExportConfiguration.defaultFontSize,
      bold: true
    }

    excelCell.alignment = {
      horizontal: 'left'
    }
  }
}
