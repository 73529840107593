import { injectable } from 'inversify';
import { IMassImportPersonIdentifierMapperService } from '@/modules/import/modules/massImport/modules/person/services/massImportPersonIdentifierMapperService/massImportPersonIdentifierMapperService.interfaces';
import { MassImportPersonIdentifier } from '@/modules/import/modules/massImport/modules/person/types/massImportPersonIdentifier';
import { ApiMassImportPersonIdentifier } from '@/modules/import/types/api/apiMassImportPersonIdentifier';

@injectable()
export default class MassImportPersonIdentifierMapperService
  implements IMassImportPersonIdentifierMapperService
{
  map(identifier: MassImportPersonIdentifier): ApiMassImportPersonIdentifier {
    return {
      identifierType: identifier.type,
      personIdentifier: identifier.value,
    };
  }
}
