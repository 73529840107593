import { ApiGridUlRecord } from '@/modules/dataGrid/company/types/api/apiGridUlRecord';
import { InjectionKey } from 'vue';
import { GridCompanyItemType } from '@/modules/dataGrid/type/gridCompanyItemType';

export interface IGridCompanyTypeParserService {
  getItemType(gridItem: ApiGridUlRecord): GridCompanyItemType;
}

export const IGridCompanyTypeParserServiceKey: InjectionKey<IGridCompanyTypeParserService> = Symbol(
  'IGridCompanyTypeParserService',
);
