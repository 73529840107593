import {
  IDataGridCompanyNameFormatterService,
  IDataGridCompanyNameFormatterServiceKey,
} from '@/modules/dataGrid/company/services/formatters/dataGridCompanyNameFormatterService/dataGridCompanyNameFormatterService.interfaces';
import { ApiGridUlRecord } from '@/modules/dataGrid/company/types/api/apiGridUlRecord';
import { inject, injectable } from 'inversify';
import { ICompanyGridToRegulationCheckTargetMapper } from '@/modules/dataGrid/company/services/mappers/companyGridToRegulationCheckTargetMapper/companyGridToRegulationCheckTargetMapper.interfaces';
import { RegulationCheckTarget } from '@/modules/regulationsCheck/common/components/RegulationChecksModal/types/RegulationCheckTarget';
import { ParentDomainType } from '@/modules/dossier/company/relations/types/companyRelations';

@injectable()
export default class CompanyGridToRegulationCheckTargetMapper implements ICompanyGridToRegulationCheckTargetMapper {
  constructor(@inject(IDataGridCompanyNameFormatterServiceKey as symbol) private companyNameFormatter: IDataGridCompanyNameFormatterService) {}

  map(item: ApiGridUlRecord): RegulationCheckTarget {
    return {
      id: item.company_original_id,
      name: this.companyNameFormatter.format(item),
      parentType: ParentDomainType.Company,
    };
  }
}
