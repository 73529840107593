import { injectable } from 'inversify';
import { IMessagePointerService } from '@/modules/messages/plural/messagePointerService.interfaces';

@injectable()
export class MessagePointerService implements IMessagePointerService {
  getMessagePointer(value: number): number {
    const casesPointers = [2, 0, 1, 1, 1, 2];
    const absoluteNumber = Math.abs(value);
    if (absoluteNumber % 100 > 4 && absoluteNumber % 100 < 20) return 2;
    if (absoluteNumber % 10 < 5) return casesPointers[absoluteNumber % 10];
    return 2;
  }
}
