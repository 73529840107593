import {
  IEnvironmentVariables,
  IEnvironmentVariablesKey,
} from '@/modules/common/settings/environmentVariables.interfaces';
import { IGraphSettings } from '@/modules/graph/common/settings/graphSettings.interfaces';
import { inject, injectable } from 'inversify';

@injectable()
export default class GraphSettings implements IGraphSettings {
  constructor(
    @inject(IEnvironmentVariablesKey as symbol) private environmentVariables: IEnvironmentVariables,
  ) {}

  get buildServerURL() {
    return this.environmentVariables.VUE_APP_GRAPH_VIEWER_ENDPOINT;
  }

  get signalrURL() {
    return this.environmentVariables.VUE_APP_GRAPH_VIEWER_ENDPOINT_HUB;
  }
}
