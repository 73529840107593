import { IRightsService } from '@/modules/rights/services/rightsService.interfaces';
import { inject, injectable } from 'inversify';
import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ApiUserData } from '@/modules/administration/users/types/apiUserData';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';

const url = (method: string) => `/auth/${method}`;
@injectable()
export class RightsService implements IRightsService {
  constructor(@inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance) {}

  getUsers(): Promise<AxiosResponse<ApiResponse<ApiUserData>>> {
    return this.apiEndpoint.get(url('roles'));
  }

  checkValidToken(): Promise<AxiosResponse<ApiResponse<ApiUserData>>> {
    return this.apiEndpoint.get(url('/api/v1/auth/validate-antiforgery-request'));
  }
}
