<template>
  <div class="ui-dialog" :class="rootModifiers">
    <template v-if="!busy">
      <div class="ui-dialog__header">
        <div class="ui-dialog__header-info">
          <div class="ui-dialog__title">{{ title }}</div>
          <div class="ui-dialog__header-controls">
            <slot name="header-controls" />
          </div>
        </div>

        <div v-if="hasQuicksell" class="ui-dialog__quicksell">{{ quicksell }}</div>
      </div>

      <template v-if="isReady">
        <div class="ui-dialog__body">
          <slot name="body" />
        </div>

        <div class="ui-dialog__footer">
          <slot name="footer" />
        </div>
      </template>

      <UIPreloader v-else />
    </template>

    <UISpinner v-else />
  </div>
</template>

<script setup lang="ts">
import UISpinner from '@/modules/common/components/UISpinner/UISpinner.vue';
import { computed, toRefs } from 'vue';
import UIPreloader from '@/ui-components/UIPreloader/UIPreloader.vue';

interface Props {
  title: string;
  quicksell?: string;
  ready?: boolean;
  busy?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  quicksell: '',
  ready: true,
  busy: false,
});

const { title, quicksell, ready: isReady } = toRefs(props);

const rootModifiers = computed(() => ({
  'ui-dialog--busy': props.busy,
}));

const hasQuicksell = computed(() => props.quicksell.length > 0);
</script>

<style lang="scss">
@import '@/styles/_mixins.scss';

.ui-dialog {
  width: var(--ui-dialog-size, 800px);
  max-width: 100%;
  max-height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  background: var(--color-white-1);
  box-shadow: var(--shadow-2);
  backdrop-filter: var(--filter-blur-10);

  &.ui-dialog--busy {
    height: 250px;
    min-width: 600px;
    justify-content: center;
  }
}

.ui-dialog__header {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-direction: column;
}

.ui-dialog__header-info {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
}

.ui-dialog__title {
  font-size: 24px;
  font-weight: 450;
  line-height: 32px;
}

.ui-dialog__header-controls {
  display: flex;
  align-items: center;
}

.ui-dialog__quicksell {
  color: var(--color-gray-5);
  white-space: break-spaces;
}

.ui-dialog__body {
  @include webkitScrollbar();
  padding-right: 8px;
  margin-right: -12px;
  overflow: auto;
  scrollbar-gutter: stable;
}

@include mobile {
  .ui-dialog {
    gap: 15px;
  }

  .ui-dialog__header {
    gap: 10px;
  }

  .ui-dialog__title {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
  }

  .ui-dialog__quicksell {
    font-size: 15px;
    line-height: 20px;
  }
}
</style>
