import { InjectionKey } from 'vue';
import { ApiPersonDetailVkRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecord';
import { PersonSocialNetworkAdditionalInfoVKCardInfo } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKCardInfo';

export interface IPersonSocialNetworkAdditionalInfoVKCardInfoFormatter {
  getAdditionalInfoVKCardInfoFormattedData(
    detailVkRecord: ApiPersonDetailVkRecord,
  ): PersonSocialNetworkAdditionalInfoVKCardInfo;
}

export const IPersonSocialNetworkAdditionalInfoVKCardInfoFormatterKey: InjectionKey<IPersonSocialNetworkAdditionalInfoVKCardInfoFormatter> =
  Symbol('IPersonSocialNetworkAdditionalInfoVKCardInfoFormatterKey');
