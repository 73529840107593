import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import {
  IStatisticsService
} from '@/modules/statistics/services/statisticsService/statisticsService.interfaces';
import { RateCacheMode } from '@/modules/statistics/types/rateCacheMode';
import { AxiosInstance } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class StatisticsService implements IStatisticsService {
  constructor(
    @inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance
  ) {
  }

  async startGlobalEntitiesStatisticsTask(cacheMode?: RateCacheMode): Promise<string> {
    const response = await this.apiEndpoint.get<ApiResponse<string>>('/statistics/global-entities', {
      params: {
        cacheMode
      }
    });

    return response.data.data;
  }
}
