import { InjectionKey } from 'vue';
import { AnalyticsScore } from '@/modules/dossier/person/financialScorings/types/financialAnalyticsScore';
import { UIScoreTheme } from '@/modules/triggers/components/UIScore/types/UIScoreTheme';

export interface IAnalyticsScoreThemeFactory {
  getAnalyticScoreTheme(score: AnalyticsScore): UIScoreTheme;
}

export const IAnalyticsScoreThemeFactoryKey: InjectionKey<IAnalyticsScoreThemeFactory> = Symbol(
  'IAnalyticsScoreThemeFactoryKey',
);
