import { ApiPersonDetailContactsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailContactsRecord';
import { PersonContactsMailTableRow } from '@/modules/dossier/person/contacts/types/personContactsMailTableRow';
import { InjectionKey } from 'vue';

export interface IPersonContactsEmailMappingService {
  mapPersonEmailDataTable(
    contacts: Array<ApiPersonDetailContactsRecord>,
  ): PersonContactsMailTableRow[];
}

export const IPersonContactsEmailMappingServiceKey: InjectionKey<IPersonContactsEmailMappingService> =
  Symbol('IPersonContactsEmailMappingServiceKey');
