import {
  UseDxTagConfig
} from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';
import {
  RegulationsCheckDecision
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckDecision';
import { InjectionKey } from 'vue';

export interface IRegulationChecksDecisionTagsFactory {
  getTag(decision?: RegulationsCheckDecision): UseDxTagConfig | undefined
}

export const IRegulationChecksDecisionTagsFactoryKey: InjectionKey<IRegulationChecksDecisionTagsFactory> = Symbol('IRegulationChecksDecisionTagsFactory');
