import { injectable } from 'inversify';
import { IManualImportCreatePersonModelMapper } from '@/modules/import/services/mappers/manualImportCreatePersonModelMapper/manualImportCreatePersonModelMapper.interfaces';
import {
  ImportManualInfoModelsBirth,
  ImportManualInfoModelsPass,
  ImportManualInfoModelsPhone,
  ImportManualInfoModelsSocial,
  ImportManualInfoModelsAddress,
  ImportManualInfoModelsRelatives,
  ImportManualInfoModelsType,
  ImportManualInfoModelsDriverLicence,
  ImportManualInfoModelsSnils,
} from '@/modules/import/types/api/importManualInfoModelsTypes';
import { ManualImportCreatePersonModel } from '@/modules/import/types/person/manualImportCreatePersonModel';
import { ApiManualImportCreatePerson } from '@/modules/import/types/api/person/apiManualImportCreatePerson';
import { ApiManualImportCreatePersonSnils } from '@/modules/import/types/api/person/apiManualImportCreatePersonSnils';
import { ApiManualImportCreatePersonSocial } from '@/modules/import/types/api/person/apiManualImportCreatePersonSocial';
import { ApiManualImportCreatePersonBirthday } from '@/modules/import/types/api/person/apiManualImportCreatePersonBirthday';
import { ApiManualImportCreatePersonPassport } from '@/modules/import/types/api/person/apiManualImportCreatePersonPassport';
import { ApiManualImportCreatePersonPhone } from '@/modules/import/types/api/person/apiManualImportCreatePersonPhone';
import { ApiManualImportCreatePersonAddress } from '@/modules/import/types/api/person/apiManualImportCreatePersonAddress';
import { ApiManualImportCreatePersonIndividualType } from '@/modules/import/types/api/person/apiManualImportCreatePersonIndividualType';
import { ApiManualImportCreatePersonRelative } from '@/modules/import/types/api/person/apiManualImportCreatePersonRelative';
import { ApiManualImportCreatePersonDriverLicence } from '@/modules/import/types/api/person/apiManualImportCreatePersonDriverLicence';

@injectable()
export default class ManualImportCreatePersonModelMapper
  implements IManualImportCreatePersonModelMapper
{
  map(formModel: ManualImportCreatePersonModel): ApiManualImportCreatePerson {
    return {
      snilses: formModel.snilses.map(snils => this.mapSnilses(snils)),
      inns: formModel.inns,
      fios: formModel.fios,
      jobs: formModel.jobs,
      autos: formModel.autos,
      educations: formModel.educations,
      birthdays: formModel.birthdays.map(birthday => this.mapBirthday(birthday)),
      passports: formModel.passports.map(passport => this.mapPassport(passport)),
      individualTypes: formModel.individualTypes.map(individualType =>
        this.mapIndividualType(individualType),
      ),
      addresses: formModel.addresses.map(address => this.mapAddress(address)),
      socials: formModel.socials.map(social => this.mapSocial(social)),
      phones: formModel.phones.map(phone => this.mapPhone(phone)),
      familyRelations: formModel.familyRelations.map(familyRelation =>
        this.mapFamilyRelation(familyRelation),
      ),
      driverLicenses: formModel.driverLicenses.map(driverLicense =>
        this.mapDriverLicense(driverLicense),
      ),
      note: formModel.note,
      settings: formModel.settings,
      listData: formModel.listData,
    };
  }

  private mapSnilses(snils: ImportManualInfoModelsSnils): ApiManualImportCreatePersonSnils {
    return {
      snilsNumber: snils.snilsNumber.replaceAll('-', ''),
    };
  }

  private mapBirthday(birthday: ImportManualInfoModelsBirth): ApiManualImportCreatePersonBirthday {
    return {
      birthdayDate: birthday.birthday,
    };
  }

  private mapPassport(passport: ImportManualInfoModelsPass): ApiManualImportCreatePersonPassport {
    return {
      passportType: passport.type.id,
      passportSeries: passport.series,
      passportNumber: passport.number,
      passportDate: passport.issueDate,
      passportDepartment: passport.issuePlace,
      departmentCode: passport.divisionCode,
    };
  }

  private mapIndividualType(
    individualType: ImportManualInfoModelsType,
  ): ApiManualImportCreatePersonIndividualType {
    return {
      type: individualType.type.id,
    };
  }

  private mapAddress(address: ImportManualInfoModelsAddress): ApiManualImportCreatePersonAddress {
    return {
      addressType: address.addressType.name,
      addressValue: address.addressValue,
    };
  }

  private mapPhone(phone: ImportManualInfoModelsPhone): ApiManualImportCreatePersonPhone {
    return {
      phoneNumber: phone.phoneNumber,
      phoneType: phone.phoneType.id,
    };
  }

  private mapSocial(social: ImportManualInfoModelsSocial): ApiManualImportCreatePersonSocial {
    return {
      id: social.id,
      type: social.type.id,
    };
  }

  private mapFamilyRelation(
    familyRelation: ImportManualInfoModelsRelatives,
  ): ApiManualImportCreatePersonRelative {
    return {
      ...familyRelation,
      birthday: familyRelation.birthday,
      relation: familyRelation.relation.id,
    };
  }

  private mapDriverLicense(
    driverLicense: ImportManualInfoModelsDriverLicence,
  ): ApiManualImportCreatePersonDriverLicence {
    const [driverLicenseNumber, driverLicenseSeries] = driverLicense.driverLicence.split(' ');

    return {
      driverLicenseIssueDate: driverLicense.issueDate,
      driverLicenseNumber,
      driverLicenseSeries,
    };
  }
}
