import { injectable } from 'inversify';
import { LegalEntitiesParticipationTypes } from '@/modules/dossier/person/common/types/api/legalEntitiesParticipationTypes';
import { IPersonParticipationUIAnalyticService } from '@/modules/dossier/person/participation/services/statistics/personParticipationUIAnalyticService.interface';
import { ApiPersonDetailLegalEntitiesParticipationRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailLegalEntitiesParticipationRecord';

@injectable()
export default class PersonParticipationUIAnalyticService
  implements IPersonParticipationUIAnalyticService
{

  calculateActivitiesAsCompanyHead(
    legalEntitiesParticipationList: Array<ApiPersonDetailLegalEntitiesParticipationRecord>,
  ): number {
    const activitiesAsCompanyHead = legalEntitiesParticipationList.filter((entity) => {
      if (entity.positionType == LegalEntitiesParticipationTypes.companyHead) {
        return entity;
      }
    });

    return activitiesAsCompanyHead.length;
  }

  calculateActivitiesAsFounder(
    legalEntitiesParticipationList: Array<ApiPersonDetailLegalEntitiesParticipationRecord>,
  ): number {
    const activitiesAsFounder = legalEntitiesParticipationList.filter((entity) => {
      if (entity.positionType == LegalEntitiesParticipationTypes.founder) {
        return entity;
      }
    });

    return activitiesAsFounder.length;
  }
}
