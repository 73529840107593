import { accessMiddleware, authMiddleware } from '@/modules/router/common/middleware';
import { accessGuardMiddleware } from '@/modules/router/common/middleware/accessGuard.middleware';
import {
  fetchAuthDataMiddleware
} from '@/modules/router/common/middleware/fetchAuthData.middleware';
import useDeviceStore from '@/stores/device';
import MiddlewareContext from '@/modules/router/common/types/middlewareContext';
import { RouterUtils } from '@/modules/router/common/utils/routerUtils';
import { Router } from 'vue-router';

export function useRoutesAccess(router: Router) {
  router.beforeEach((to, from) => {
    const deviceStore = useDeviceStore();
    const middlewareContext: MiddlewareContext = { to, from, platformType: deviceStore.platformType };
    return RouterUtils.composeMiddleware(middlewareContext, [authMiddleware, accessMiddleware]);
  });
  router.beforeEach(fetchAuthDataMiddleware);
  router.beforeEach(accessGuardMiddleware);
}
