import { IImportFileCompanyService } from '@/modules/import/services/importFileCompanyService.interfaces';
import { ImportListSettings } from '@/modules/import/types/api/importListSettings';
import AImportFileService from '@/modules/import/services/importFileService.abstract';
import { ImportFileProcessId } from '@/modules/import/types/api/importFileProcessId';
import { inject, injectable } from 'inversify';
import {
  IDataImportServiceKey,
  IDataImportService,
} from '@/modules/import/services/dataImportService.interfaces';
import {
  IProcessingSettingsService,
  IProcessingSettingsServiceKey,
} from '@/modules/processingSettings/services/processingSettingsService.interface';
import { ApiImportProgress } from '@/modules/import/types/api/apiImportProgress';

@injectable()
export default class ImportFileService
  extends AImportFileService
  implements IImportFileCompanyService
{
  constructor(
    @inject(IDataImportServiceKey as symbol) private dataImportService: IDataImportService,
    @inject(IProcessingSettingsServiceKey as symbol)
    private processingSettingsService: IProcessingSettingsService,
  ) {
    super();
  }
  public async importFile(
    file: Blob,
    listSettings?: ImportListSettings,
  ): Promise<ImportFileProcessId> {
    const data = this.getFormDataForImportFile(
      file,
      listSettings,
      this.processingSettingsService.settings,
    );

    const response = await this.dataImportService.makeProcessingOnec(data);
    // TODO: на текущий момент прогресс импорта не реализован на бэке,
    // в связи с чем сделана затычка с возвращением методом importFile пустой строки
    return ''
  }

}
