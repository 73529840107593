import {
  IRegulationChecksDecisionFormatterService
} from '@/modules/regulationsCheck/common/services/regulationChecksDecisionFormatterService/regulationChecksDecisionFormatterService.interfaces';
import {
  RegulationsCheckDecision
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckDecision';
import { injectable } from 'inversify';

@injectable()
export default class RegulationChecksDecisionFormatterService implements IRegulationChecksDecisionFormatterService {
  format(decision?: RegulationsCheckDecision): string {
    switch (decision) {
      case RegulationsCheckDecision.Declined: return 'Отклонить';
      case RegulationsCheckDecision.Agreed: return 'Согласовать';
      case RegulationsCheckDecision.AgreedWithComment: return 'Согласовать с замечаниями';
      default: return 'Без решения';
    }
  }
}
