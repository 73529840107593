import EndpointsFactory from '@/modules/common/factories/endpoints/endpointsFactory';
import { IApiSettings, IApiSettingsKey } from '@/modules/common/settings/apiSettings.interfaces';
import { AxiosRequestConfig } from 'axios';
import { inject, injectable } from 'inversify';
import qs from 'qs';

@injectable()
export default class ApiEndpointsFactory extends EndpointsFactory {
  constructor(
    @inject(IApiSettingsKey as symbol) private apiSettings: IApiSettings
  ) {
    super();
  }

  protected override getConfig(): AxiosRequestConfig {
    return {
      baseURL: this.apiSettings.v1ApiUrl,
      withCredentials: true,

      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat" })
      }
    };
  }
}
