import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { ICompanyPledgesServiceKey } from '@/modules/dossier/company/pledges/services/companyPledgesService.interface';
import CompanyPledgesService from '@/modules/dossier/company/pledges/services/companyPledgesService';
import { ICompanyPledgesMappingServiceKey } from '@/modules/dossier/company/pledges/services/companyPledgesMappingService.interface';
import СompanyPledgesMappingService from '@/modules/dossier/company/pledges/services/companyPledgesMappingService';

export function bindCompanyDossierPledgesServices(bind: IContainerBinder) {
  bind(ICompanyPledgesServiceKey).to(CompanyPledgesService);
  bind(ICompanyPledgesMappingServiceKey).to(СompanyPledgesMappingService);
}
