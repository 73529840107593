export enum CurrencyTag {
  unknown = 0,
  rub = 1,
  usd = 2,
  eur = 3,
  gbr = 4,
  chf = 5,
  try = 6,
  inr = 7,
  ils = 8,
  kzt = 9,
  vnd = 10,
  krw = 11,
  thb = 12,
  afn = 13,
}
