import { useInjectable } from '@/hooks/useInjectable';
import { useStatelessUpdateQueue } from '@/modules/common/composables/useStatelessUpdateQueue/useStatelessUpdateQueue';
import { useReportsGenerationTaskCancelledEvent } from '@/modules/reports/common/events/useReportsGenerationTaskCancelledEvent';
import { useReportsGenerationTaskStartedEvent } from '@/modules/reports/common/events/useReportsGenerationTaskStartedEvent';
import { IReportsHistoryItemDTOMappingServiceKey } from '@/modules/reports/common/services/reportsHistoryItemDTOMappingService.interfaces';
import { IReportsServiceKey } from '@/modules/reports/common/services/reportsService/reportsService.interfaces';
import { ReportHistoryItemDTO } from '@/modules/reports/common/types/reportHistoryItemDTO';
import { THistoryReportApi } from '@/modules/reports/desktop/types/THistoryReportApi';
import { useTaskStatusChangedEvent } from '@/modules/tasks/composables/useTaskStatusChangedEvent/useTaskStatusChangedEvent';
import { sortBy } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useEasyReportHistoryStore = defineStore('easyReportHistoryStore', () => {
  const reportsService = useInjectable(IReportsServiceKey);

  const { onGenerationTaskCancelled } = useReportsGenerationTaskCancelledEvent();
  const { onGenerationTaskStarted } = useReportsGenerationTaskStartedEvent();
  const { onTaskStatusChanged } = useTaskStatusChangedEvent();

  const historyItems = ref<Array<ReportHistoryItemDTO>>([]);
  const historyItemDTOMappingService = useInjectable(IReportsHistoryItemDTOMappingServiceKey);

  const isInitialized = ref(false);

  function setItems(newItems: ReportHistoryItemDTO[]) {
    historyItems.value = sortBy(newItems, x => -x.created.getTime());
  }

  async function loadItemByTaskIdAsync(taskId: string) {
    const {
      data: {
        data: { items },
      },
    } = await reportsService.getHistoryReports({
      paramsMain: {
        isFilterBySelectedIndividualsActive: true,
        selectedIndividualsIdDict: {
          [taskId]: '',
        },
      },
      paramsDevextreme: {},
    });

    if (items.length > 0) {
      return historyItemDTOMappingService.map(items[0]);
    }
  }

  async function updateOrLoadItemByTaskIdAsync(taskId: string) {
    const newHistoryItem = await loadItemByTaskIdAsync(taskId);

    if (newHistoryItem != undefined) {
      const others = historyItems.value.filter(x => x.taskId != taskId);
      setItems([newHistoryItem, ...others]);
    }
  }

  async function updateAsync() {
    const {
      data: { data },
    } = await reportsService.getAllHistoryReports();
    const newHistoryItems = data.map(x => historyItemDTOMappingService.map(x));

    setItems(newHistoryItems);
    isInitialized.value = true;
  }

  function getReportByTaskId(taskId: string): THistoryReportApi | undefined {
    return historyItems.value.find(item => item.taskId == taskId);
  }

  useStatelessUpdateQueue(updateAsync, {
    delayBetweenRequests: 1000,
    debounce: 100,
  });

  onGenerationTaskCancelled(taskId => updateOrLoadItemByTaskIdAsync(taskId));
  onGenerationTaskStarted(taskId => updateOrLoadItemByTaskIdAsync(taskId));
  onTaskStatusChanged(taskId => updateOrLoadItemByTaskIdAsync(taskId));

  return {
    historyItems,
    isInitialized,
    getReportByTaskId,
  };
});
