import { DashboardVersion } from '@/modules/common/types/settings/dashboardVersion';
import { InjectionKey } from 'vue';
import { UiVersions } from '@/modules/common/types/settings/uiVersions';

export interface IUiSettings {
  version: UiVersions
  dashboardVersion: DashboardVersion
}

export const IUiSettingsKey: InjectionKey<IUiSettings> = Symbol('IUiSettings');
