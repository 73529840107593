import { InjectionKey } from 'vue';
import GraphBuildRequest from '../types/GraphBuildRequest';
import ResultGraph from '../types/ResultGraph';

export interface IUnityGraphBuildService {
  build(request: GraphBuildRequest): Promise<string>
  getGraph(id: string): Promise<ResultGraph>
  getRequest(id: string): Promise<GraphBuildRequest>
}

export const IUnityGraphBuildServiceKey: InjectionKey<IUnityGraphBuildService> = Symbol('IGraphBuildService');
