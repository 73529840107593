import {
  IRiskTagsFactory
} from '@/modules/indicators/factories/riskTagsFactory/riskTagsFactory.interfaces';
import {
  IRiskTypeCalculatorService, IRiskTypeCalculatorServiceKey
} from '@/modules/risks/services/riskTypeCalculatorService.interfaces';
import {
  IRiskTypeFormatterService, IRiskTypeFormatterServiceKey
} from '@/modules/risks/services/riskTypeFormatterService.interfaces';
import { riskType } from '@/modules/risks/types/riskType';
import {
  UIContentTagConfig
} from '@/modules/tags/components/UIContentTag/types/UIContentTagConfig';
import { UITagTheme } from '@/modules/tags/types/tagTheme';
import { inject, injectable } from 'inversify';

@injectable()
export default class RiskTagsFactory implements IRiskTagsFactory {
  constructor(
    @inject(IRiskTypeFormatterServiceKey as symbol) private riskTypeFormatter: IRiskTypeFormatterService,
    @inject(IRiskTypeCalculatorServiceKey as symbol) private riskTypeCalculator: IRiskTypeCalculatorService
  ) { }
  getTag(rate: number): UIContentTagConfig {
    const type = this.riskTypeCalculator.calculateRiskType(rate);
    const formattedType = this.riskTypeFormatter.formatRiskType(type);

    return {
      content: `${formattedType} (${rate})`,
      theme: this.getTagTheme(type)
    }
  }

  private getTagTheme(value: riskType): UITagTheme {
    switch (value) {
      case riskType.Low:
        return 'success';
      case riskType.Medium:
        return 'warning';
      case riskType.High:
      default:
        return 'danger';
    }
  }

}


