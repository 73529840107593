import { InjectionKey } from 'vue';
import { TDetailsCompanyBranchesList } from '@/types/companyData/companyData';
import { Dictionary } from '@/types/common';

export interface MostBranchesInCity {
  name: string;
  count: number;
}
export interface ICompanyBranchesService {
  calculateBranchesCountByType(branches: Array<TDetailsCompanyBranchesList>, type: string): number;
  groupBranchesByCity(
    companyBranchData: Array<TDetailsCompanyBranchesList>,
  ): Dictionary<TDetailsCompanyBranchesList[]>;
  calculateCountCityBranches(
    companyBranchData: Array<TDetailsCompanyBranchesList>,
  ): MostBranchesInCity;
}

export const ICompanyBranchesServiceKey: InjectionKey<ICompanyBranchesService> =
  Symbol('ICompanyBranchesService');
