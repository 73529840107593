import { MaskOptions } from 'maska';
import { InjectionKey } from 'vue';
import { RequisiteType } from '@/modules/requisites/types/requisiteType';

export interface IRequisiteMasks {
  getRequisiteMask(value: RequisiteType): MaskOptions;
}

export type IRequisiteMasksService = IRequisiteMasks;

export const IRequisiteMasksServiceKey: InjectionKey<IRequisiteMasksService> =
  Symbol('IRequisiteMasksService');
