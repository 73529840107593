import { injectable } from 'inversify';
import { ICompanyContactsTypeCheckService } from '@/modules/dossier/company/contacts/services/companyContactsTypeCheckService.interface';

@injectable()
export default class companyContactsTypeCheckService implements ICompanyContactsTypeCheckService {
  getContactType(type: number): string {
    if (type === 1) return 'Почта';
    if (type === 10) return 'Телефон';
    return '';
  }
  getVerifiedValue(field: string): boolean {
    return field === 'Российский телефон, распознан уверенно' || field === 'Корректное значение';
  }
}
