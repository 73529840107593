import { injectable } from 'inversify';
import { Lease } from '../types/lease';
import { LeasingContractsTableRow } from '../types/leasingContractsTableRow';
import { LeasingSubjectsTableRow } from '../types/leasingSubjectsTableRow';
import { LessorsTableRow } from '../types/lessorsTableRow';
import { ICompanyLeasingMappingService } from './companyLeasingMappingService.interface';

type LeasingContractsByCompanyAndSubject = Record<
  string,
  Record<string, { activeContracts: number; archivedContracts: number }>
>;

@injectable()
export default class companyLeasingMappingService implements ICompanyLeasingMappingService {
  mapLeasingSubjectsTable(leases: Array<Lease>): Array<LeasingSubjectsTableRow> {
    const today = new Date();
    const subjectsByContracts = leases.reduce((acc, lease) => {
      lease.lesseeSubject.forEach((subject) => {
        if (subject.description in acc) {
          if (new Date(lease.leaseEndDate) > today) {
            acc[subject.description].activeContracts += 1;
          } else {
            acc[subject.description].archivedContracts += 1;
          }
        } else {
          acc[subject.description] = {
            description: subject.description,
            activeContracts: 0,
            archivedContracts: 0,
          };
          if (new Date(lease.leaseEndDate) > today) {
            acc[subject.description].activeContracts += 1;
          } else {
            acc[subject.description].archivedContracts += 1;
          }
        }
      });
      return acc;
    }, {} as Record<string, { description: string; activeContracts: number; archivedContracts: number }>);

    return Object.entries(subjectsByContracts).map((entry) => ({
      lesseeSubject: entry[1].description,
      activeContracts: entry[1].activeContracts.toString(),
      archivedContracts: entry[1].archivedContracts.toString(),
    }));
  }

  mapLessorsTable(leases: Array<Lease>): Array<LessorsTableRow> {
    const today = new Date();
    const lessorsByContracts = leases.reduce((acc, lease) => {
      lease.lessors.forEach((lessor) => {
        if (lessor.fullname == null) {
          return;
        }

        if (!(lessor.fullname in acc)) {
          acc[lessor.fullname] = {};
        }

        lease.lesseeSubject.forEach((subject) => {
          const subjectName =
            subject.classifierName != null ? subject.classifierName : 'Неизвестно';
          if (!(subjectName in acc[lessor.fullname])) {
            acc[lessor.fullname][subjectName] = {
              activeContracts: 0,
              archivedContracts: 0,
            };
          }

          if (new Date(lease.leaseEndDate) > today) {
            acc[lessor.fullname][subjectName].activeContracts += 1;
          } else {
            acc[lessor.fullname][subjectName].archivedContracts += 1;
          }
        });
      });
      return acc;
    }, {} as LeasingContractsByCompanyAndSubject);

    return Object.entries(lessorsByContracts)
      .map((lessor) =>
        Object.entries(lessor[1]).map((subject) => ({
          lessorName: lessor[0],
          lesseeSubject: subject[0],
          activeContracts: subject[1].activeContracts.toString(),
          archivedContracts: subject[1].archivedContracts.toString(),
        })),
      )
      .flat();
  }

  mapLeasingContractsTable(leases: Array<Lease>): Array<LeasingContractsTableRow> {
    return leases.map((lease) => {
      const compiledLessorsData = lease.lessors.reduce(
        (acc, { inn, ogrn, fullname, country }) => ({
          inn: [...acc.inn, inn],
          ogrn: [...acc.ogrn, ogrn],
          names: [...acc.names, fullname],
          countries: [...acc.countries, country],
        }),
        { inn: [], ogrn: [], names: [], countries: [] } as Record<string, Array<string>>,
      );

      const compiledLeaseSubjectsData = lease.lesseeSubject.reduce(
        (acc, { classifierCode, classifierName, description, subjectId }) => ({
          classifierCode: [...acc.classifierCode, classifierCode],
          classifierName: [...acc.classifierName, classifierName],
          description: [...acc.description, description],
          subjectId: [...acc.subjectId, subjectId],
        }),
        { classifierCode: [], classifierName: [], description: [], subjectId: [] } as Record<
          string,
          Array<string>
        >,
      );
      return {
        contractNumber: lease.contractNumber,
        subleaseContractNumber: lease.subleaseContractNumber,
        contractDate: lease.contractDate,
        leaseStartDate: lease.leaseStartDate,
        leaseEndDate: lease.leaseEndDate,
        terminationDate: lease.terminationDate,
        terminationReason: lease.terminationReason,
        lessorName: compiledLessorsData.names.join(', '),
        lessorInn: compiledLessorsData.inn.join(', '),
        lessorOgrn: compiledLessorsData.ogrn.join(', '),
        lessorCountry: compiledLessorsData.countries.join(', '),
        lesseeSubjectClassifierCode: compiledLeaseSubjectsData.classifierCode.join(', '),
        lesseeSubjectClassifierName: compiledLeaseSubjectsData.classifierName.join(', '),
        lesseeSubjectDescription: compiledLeaseSubjectsData.description.join(', '),
        lesseeSubjectId: compiledLeaseSubjectsData.subjectId.join(', '),
      };
    });
  }
}
