import { InjectionKey } from 'vue';
import { RequisiteType } from '../types/requisiteType';

export interface IRequisiteTypeFormatter {
  getRequisiteType(set: RequisiteType): string;
}

export type IRequisiteTypeFormatterService = IRequisiteTypeFormatter;

export const IRequisiteTypeFormatterServiceKey: InjectionKey<IRequisiteTypeFormatterService> =
  Symbol('IRequisiteTypeFormatter');
