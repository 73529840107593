<template>
  <UIConfirmation
    class="ui-mobile-confirmation"
    :title="title"
    :quicksell="quicksell"
    :confirm="confirm"
    :cancel="cancel"
    @close="close"
  >
    <template #footer="{ cancel, confirm }">
      <div class="ui-mobile-confirmation__footer">
        <UIButtonV3
          class="ui-mobile-confirmation__footer-button"
          theme="primary-gradient"
          @click="confirm"
        >
          {{ confirmLabel }}
        </UIButtonV3>
        <UIButtonV3 class="ui-mobile-confirmation__footer-button" theme="gray" @click="cancel">
          {{ cancelLabel }}
        </UIButtonV3>
      </div>
    </template>
  </UIConfirmation>
</template>

<script setup lang="ts">
import UIConfirmation from '@/modules/dialogs/components/UIConfirmation/UIConfirmation.vue';
import UIButtonV3 from '@/ui-components/UIButtonV3/UIButtonV3.vue';
import { MaybePromise } from '@/modules/common/types/maybePromise';

interface Props {
  title: string;
  quicksell?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  confirm?: () => MaybePromise<void>;
  cancel?: () => MaybePromise<void>;
}

interface Emits {
  (e: 'close'): void;
}

const props = withDefaults(defineProps<Props>(), {
  confirmLabel: 'Да',
  cancelLabel: 'Нет',
});

const emits = defineEmits<Emits>();

function close() {
  emits('close');
}
</script>

<style lang="scss">
.ui-mobile-confirmation {
  --ui-dialog-size: calc(100% - 40px);
}

.ui-mobile-confirmation__footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.ui-mobile-confirmation__footer-button {
  width: 100%;
}
</style>
