import { InjectionKey } from 'vue';

export interface IApiSettings {
  v1ApiUrl: string;
  rootApiUrl: string;
  rootUrl: string;
  staticFilesUrl: string;
  reportsBaseUrl: string;
}

export const IApiSettingsKey: InjectionKey<IApiSettings> = Symbol('IApiSettings');
