import { InjectionKey } from 'vue';
import { ApiPersonDetailNegativeDebtsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailNegativeDebtsRecord';

export interface IPersonNegativeDebtsStatisticsService {
  calculateActiveDebtsSum(debts: Array<ApiPersonDetailNegativeDebtsRecord>): number;
  calculateArchivedDebtsSum(debts: Array<ApiPersonDetailNegativeDebtsRecord>): number;
  calculateActiveDebtsCount(debts: Array<ApiPersonDetailNegativeDebtsRecord>): number;
}

export const IPersonNegativeDebtsStatisticsServiceKey: InjectionKey<IPersonNegativeDebtsStatisticsService> =
  Symbol('IPersonNegativeDebtsStatisticsServiceKey');
