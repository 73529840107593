import {
  RegulationsCheckDecision
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckDecision';
import { InjectionKey } from 'vue';

export interface IRegulationChecksDecisionFormatterService {
  format(decision?: RegulationsCheckDecision): string
}

export const IRegulationChecksDecisionFormatterServiceKey: InjectionKey<IRegulationChecksDecisionFormatterService> = Symbol('IRegulationChecksDecisionFormatterService');
