import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { FullNameRequisiteMatchingService } from '@/modules/requisites/services/matchers/fullNameRequisiteMatchingService';
import { PersonInnRequisiteMatchingService } from '@/modules/requisites/services/matchers/personInnRequisiteMatchingService';
import { CompanyInnRequisiteMatchingService } from '@/modules/requisites/services/matchers/companyInnRequisiteMatchingService';
import { IpInnRequisiteMatchingService } from '@/modules/requisites/services/matchers/ipInnRequisiteMatchingService';
import { PhoneRequisiteMatchingService } from '@/modules/requisites/services/matchers/phoneRequisiteMatchingService';
import { CarNumberRequisiteMatchingService } from '@/modules/requisites/services/matchers/carNumberRequisiteMatchingService';
import { RequisiteMasksService } from '@/modules/requisites/services/requisiteMasksService';
import { PersonRequisitesMatchingService } from '@/modules/requisites/services/matchers/personRequisitesMatchingService';
import { DateRequisiteMatchingService } from '@/modules/requisites/services/matchers/dateRequisiteMatchingService';
import { CompanyRequisitesMatchingService } from '@/modules/requisites/services/matchers/companyRequisitesMatchingService';
import { IRequisiteMasksServiceKey } from '@/modules/requisites/services/requisiteMasksService.interfaces';
import { ICarNumberRequisiteMatchingServiceKey } from '@/modules/requisites/services/matchers/carNumberRequisiteMatchingService.interfaces';
import { ICompanyRequisitesMatchingServiceKey } from '@/modules/requisites/services/matchers/companyRequisitesMatchingService.interfaces';
import { IDateRequisiteMatchingServiceKey } from '@/modules/requisites/services/matchers/dateRequisiteMatchingService.interfaces';
import { IFullNameRequisiteMatchingServiceKey } from '@/modules/requisites/services/matchers/fullNameRequisiteMatchingService.interfaces';
import { IIpInnRequisiteMatchingServiceKey } from '@/modules/requisites/services/matchers/ipInnRequisiteMatchingService.interfaces';
import { ICompanyInnRequisiteMatchingServiceKey } from '@/modules/requisites/services/matchers/companyInnRequisiteMatchingService.interfaces';
import { IPersonInnRequisiteMatchingServiceKey } from '@/modules/requisites/services/matchers/personInnRequisiteMatchingService.interfaces';
import { IPersonRequisitesMatchingServiceKey } from '@/modules/requisites/services/matchers/personRequisitesMatchingService.interfaces';
import { IPhoneRequisiteMatchingServiceKey } from '@/modules/requisites/services/matchers/phoneRequisiteMatchingService.interfaces';
import { RequisiteTypeFormatterService } from '../../services/requisiteTypeFormatterService';
import { IRequisiteTypeFormatterServiceKey } from '../../services/requisiteTypeFormatterService.interfaces';

export function bindRequisiteMatchingServices(bind: IContainerBinder) {
  bind(IFullNameRequisiteMatchingServiceKey).to(FullNameRequisiteMatchingService);
  bind(IPhoneRequisiteMatchingServiceKey).to(PhoneRequisiteMatchingService);
  bind(IPersonInnRequisiteMatchingServiceKey).to(PersonInnRequisiteMatchingService);
  bind(ICarNumberRequisiteMatchingServiceKey).to(CarNumberRequisiteMatchingService);
  bind(ICompanyInnRequisiteMatchingServiceKey).to(CompanyInnRequisiteMatchingService);
  bind(IIpInnRequisiteMatchingServiceKey).to(IpInnRequisiteMatchingService);
  bind(IDateRequisiteMatchingServiceKey).to(DateRequisiteMatchingService);

  bind(IPersonRequisitesMatchingServiceKey).to(PersonRequisitesMatchingService);
  bind(ICompanyRequisitesMatchingServiceKey).to(CompanyRequisitesMatchingService);

  bind(IRequisiteMasksServiceKey).to(RequisiteMasksService);
  bind(IRequisiteTypeFormatterServiceKey).to(RequisiteTypeFormatterService);
}
