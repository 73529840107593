import { InjectionKey } from 'vue';
import { ApiPersonDetailTrustScoringRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailTrustScoringRecord';
import { PersonIntegrityScoringRow } from '@/modules/dossier/person/personIntegrityScoring/types/personIntegrityScoringRow';

export interface IPersonIntegrityScoringFormatterService {
  prepareIntegrityScoringsTable(
    trust: ApiPersonDetailTrustScoringRecord,
  ): Array<PersonIntegrityScoringRow>;
}

export const IPersonIntegrityScoringFormatterServiceKey: InjectionKey<IPersonIntegrityScoringFormatterService> =
  Symbol('IPersonIntegrityScoringFormatterServiceKey');
