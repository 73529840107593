import {
  IContactSubtypeFormatter
} from '@/modules/dossier/person/common/services/formatters/contactSubtypeFormatter.interfaces';
import { ContactSubTypes } from '@/types/companyContacts/contactSubTypes';
import { injectable } from 'inversify';

@injectable()
export default class ContactSubtypeFormatter implements IContactSubtypeFormatter {
  format(value: ContactSubTypes): string {
    switch (value) {
      case ContactSubTypes.Unknown:
        return 'Неизвестно';
      case ContactSubTypes.Work:
        return 'Рабочий';
      case ContactSubTypes.Home:
        return 'Домашний';
      case ContactSubTypes.Mobile:
        return 'Мобильный';
      default:
        return '';
    }
  }
}
