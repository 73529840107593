import { injectable } from 'inversify';
import { ICompanyPledgesService } from '@/modules/dossier/company/pledges/services/companyPledgesService.interface';
import { Pledge } from '@/modules/dossier/company/pledges/types/pledge';

@injectable()
export default class companyPledgesService implements ICompanyPledgesService {
  calculateActivePledges(pledges: Array<Pledge>): number {
    const today = new Date();
    const activePledges = pledges.filter((pledge) => pledge.executionDate > today);
    return activePledges.length;
  }
}
