import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { ApiDataTableFieldProfile } from '@/modules/profile/types/api/apiDataTableFieldProfile';
import { DataTableConfigProfiles } from '@/modules/profile/types/api/dataTableConfigProfile';
import { AxiosResponse } from 'axios';
import { InjectionKey } from 'vue';

export interface IDataTableSettingsService {
  getSettings(name: DataTableConfigProfiles): Promise<AxiosResponse<ApiResponse<ApiDataTableFieldProfile[]>>>
  postSettings(name: DataTableConfigProfiles, data: ApiDataTableFieldProfile[]): Promise<AxiosResponse<ApiResponse<string>>>
}

export const IDataTableSettingsServiceKey: InjectionKey<IDataTableSettingsService> = Symbol('IDataTableSettingsService');
