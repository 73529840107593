import { injectable } from 'inversify';
import { IReportsHistoryItemDTOMappingService } from '@/modules/reports/common/services/reportsHistoryItemDTOMappingService.interfaces';
import { ReportHistoryItemDTO } from '@/modules/reports/common/types/reportHistoryItemDTO';
import { DateUtils } from '@/utils/dateUtils';
import { THistoryReportApi } from '@/modules/reports/desktop/types/THistoryReportApi';

@injectable()
export default class ReportsHistoryItemDTOMappingService
  implements IReportsHistoryItemDTOMappingService
{
  map(item: THistoryReportApi): ReportHistoryItemDTO {
    return {
      ...item,

      birthday: DateUtils.parseOptionalDate(item.birthday),
      created: new Date(item.created),
      modified: DateUtils.parseOptionalDate(item.modified),
    };
  }

  mapReverse(item: ReportHistoryItemDTO): THistoryReportApi {
    return {
      ...item,

      birthday: item.birthday?.toISOString(),
      created: item.created.toISOString(),
      modified: item.modified?.toISOString(),
    };
  }
}
