import { injectable } from 'inversify';
import { IPersonContactsPhoneVerificationCodeService } from '@/modules/dossier/person/contacts/services/personContactsPhoneVerificationCodeService.interface';
import { DossierTagData } from '@/modules/common/types/dossierTag';
import { DossierTagWithTooltip } from '@/modules/common/types/dossierTagWithTooltip';

@injectable()
export default class PersonContactsPhoneVerificationCodeService
  implements IPersonContactsPhoneVerificationCodeService
{
  getPhoneVerificationCodeTagData(
    phoneVerificationCode: string | undefined,
  ): DossierTagData | DossierTagWithTooltip {
    switch (phoneVerificationCode) {
      case 'Телефон распознан уверенно':
      case 'Российский телефон, распознан уверенно':
      case 'Иностранный телефон, распознан уверенно':
        return {
          theme: 'success',
          text: phoneVerificationCode,
        };
      case 'Телефон распознан с допущениями или не распознан':
      case 'Обнаружено несколько телефонов, распознан первый':
        return {
          theme: 'warning',
          text: phoneVerificationCode,
          tooltipText: 'Необходимо проверить вручную',
        };
      case 'Телефон пустой или заведомо «мусорный»':
        return {
          theme: 'danger',
          text: phoneVerificationCode,
        };
      case 'Неизвестный':
        return {
          theme: 'common-alt',
          text: phoneVerificationCode,
          tooltipText: 'Необходимо проверить вручную',
        };
      default:
        return {
          theme: 'common-alt',
          text: 'Неизвестный',
          tooltipText: 'Необходимо проверить вручную',
        };
    }
  }
}
