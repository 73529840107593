import { MaskOptions } from 'maska';

export const dateRequisiteMask: MaskOptions = {
  mask: 'XX.XX.XXXX',
  tokens: {
    X: {
      pattern: /[0-9]/,
    },
  },
};
