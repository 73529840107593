export type ManualPersonState = {
  [k: string]: string;
  snils: string;
  inn: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  birthDate: string;
  personType: string;
  passportType: string;
  passportNumber: string;
  passportSeries: string;
  departmentCode: string;
  passportDepartment: string;
  passportDate: string;
  phoneType: string;
  phoneNumber: string;
  socialNetworkType: string;
  socialNetworkId: string;
  addressType: string;
  addressLocation: string;
  educationInstitution: string;
  specialityName: string;
  educationSeries: string;
  educationNumber: string;
  educationStartDate: string;
  educationEndDate: string;
  companyName: string;
  companyInn: string;
  companyDivisionName: string;
  position: string;
  workStartDate: string;
  workEndDate: string;
  transportNumber: string;
  transportVin: string;
  relativesFirstName: string;
  relativesLastName: string;
  relativesPatronymic: string;
  relativeBirthDate: string;
  relativesType: string;
  innRelatives: string;
  note: string;
};

export enum MutationTypes {
  SET_NEW_VALUE_PERSONS = 'SET_NEW_VALUE_PERSONS',
  RESET_VALUES_PERSONS = 'RESET_VALUES_PERSONS',
}

export type Mutations<S = ManualPersonState> = {
  [MutationTypes.SET_NEW_VALUE_PERSONS](state: S, payload: { name: string; value: string }): void;
  [MutationTypes.RESET_VALUES_PERSONS](state: S): void;
};
