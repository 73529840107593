import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { ReportsConfigurationServiceKey } from '@/modules/reports/desktop/services/reportsConfigurationService.interfaces';
import ReportsConfigurationService from '@/modules/reports/desktop/services/reportsConfigurationService';
import DesktopReportsHistoryItemTagsFactory from '@/modules/reports/desktop/services/desktopReportsHistoryItemTagsFactory';
import { IDesktopReportsHistoryItemTagsFactoryKey } from '@/modules/reports/desktop/services/desktopReportsHistoryItemTagsFactory.interfaces';
import { IDesktopReportsStatusFormatterKey } from '@/modules/reports/desktop/services/desktopReportsStatusFormatter.interfaces';
import DesktopReportsStatusFormatter from '@/modules/reports/desktop/services/desktopReportsStatusFormatter';
import { IDesktopReportsInputTypeFormatterKey } from '@/modules/reports/desktop/services/desktopReportsInputTypeFormatter.interfaces';
import DesktopReportsInputTypeFormatter from '@/modules/reports/desktop/services/desktopReportsInputTypeFormatter';

export function bindDesktopReportsServices(bind: IContainerBinder) {
  bind(ReportsConfigurationServiceKey).to(ReportsConfigurationService); // TODO SNSIDE_UI-1136: на удаление, сервис устарел
  bind(IDesktopReportsHistoryItemTagsFactoryKey).to(DesktopReportsHistoryItemTagsFactory);
  bind(IDesktopReportsStatusFormatterKey).to(DesktopReportsStatusFormatter);
  bind(IDesktopReportsInputTypeFormatterKey).to(DesktopReportsInputTypeFormatter);
}
