import {
  DxXlsxExportConfiguration
} from '@/modules/devExtreme/export/xlsx/configuration/dxXlsxExportConfiguration';
import { IDxXlsxCellFormatter } from '@/modules/devExtreme/export/xlsx/services/dxXlsxCellFormatter/dxXlsxCellFormatter.interfaces';
import { DxTableExtendedColumn } from '@/modules/devExtreme/types/dxTableExtendedColumn';
import { ExcelDataGridCell } from 'devextreme/excel_exporter';
import { injectable } from 'inversify';

@injectable()
export default class DxXlsxDataCellFormatter implements IDxXlsxCellFormatter {
  cellType = 'data'

  format(excelCell: any, gridCell: ExcelDataGridCell, dxColumn: DxTableExtendedColumn) {
    excelCell.font = {
      name: DxXlsxExportConfiguration.defaultFontName,
      size: DxXlsxExportConfiguration.defaultFontSize
    }

    excelCell.alignment = {
      horizontal: DxXlsxExportConfiguration.defaultAlignment
    }

    excelCell.value = this.getDataCellValue(dxColumn, gridCell);
  }

  private getDataCellValue(dxColumn: DxTableExtendedColumn, gridCell: ExcelDataGridCell) {
    if (dxColumn.calculateExportValue != undefined)
      return dxColumn.calculateExportValue(gridCell.value, gridCell.data);

    return gridCell.value;
  }


}
