import {
  IElasticCompanyFieldFormatter
} from '@/modules/elastic/services/elasticCompanyFieldFormatter/elasticCompanyFieldFormatter.interfaces';
import { ApiElasticCompanyField } from '@/modules/elastic/types/api/apiElasticCompanyField';
import { injectable } from 'inversify';

@injectable()
export default class ElasticCompanyFieldFormatter implements IElasticCompanyFieldFormatter {
  format(type: ApiElasticCompanyField): string {
    switch (type) {
      case 'shortName': return 'Название компании';
      case 'fullName': return 'Полное название компании';
      case 'inn': return 'ИНН';
      case 'ogrn': return 'ОГРН';
      case 'mainActivity': return 'Основной вид деятельности';
      case 'address': return 'Адрес';
      case 'phone': return 'Телефон';
      case 'tags': return 'Упоминания';
      case 'socials': return 'Социальные сети ';
      case 'socialsEmployee': return 'Сотрудник';
      case 'headFio': return 'ФИО';
      case 'headInn': return 'ИНН';
      case 'founderFio': return 'ФИО';
      case 'founderInn': return 'ИНН';
      case 'shareholderFio': return 'ФИО';
      case 'trusteeFio': return 'ФИО';
      case 'documentNumber': return 'Номер';
      case 'email': return 'Почта';
      case 'site': return 'Сайт';
      case 'userLists': return 'Списки';
      case 'driverIds': return 'Водительское удостоверение';
      case 'carNumbers': return 'Гос.номер транспортного средства ';
      case 'carVins': return 'VIN транспортного средства ';
      case 'carMarks': return 'Марка транспортного средства';
      case 'carModels': return 'Модель транспортного средства';
      case 'carOwnerFullNames': return 'Полное имя собственника';
      case 'carOwnerPhones': return 'Абонентский номер собственника';
      case 'carOwnerInns': return 'ИНН собственника';
      case 'carOwnerAddresses': return 'Адрес собственника';
      case 'isIp': return 'ИП';
      case 'ipType': return 'Тип ИП';
      case 'ipFio': return 'ФИО';
      case 'ipInn': return 'ИНН';
      case 'ipOgrn': return 'ОГРНИП';
      case 'ipStatus': return 'Статус';
      case 'ipRegion': return 'Регион';
      case 'ipCitizenship': return 'Гражданство';
      case 'ipEmail': return 'Почта';
      case 'ipPhone': return 'Телефон';
      case 'ipMainActivity': return 'Основная деятельность';
      case 'ipNote': return 'Заметка';
      case 'relatedPersonsFio': return 'ФИО';
      case 'relatedPersonsInn': return 'ИНН';
      case 'relatedPersonsPassport': return 'Паспорт';
      case 'relatedPersonsAddress': return 'Адрес';
      case 'relatedPersonsPhone': return 'Телефон';
      case 'relatedPersonsJob': return 'Работа';
      case 'relatedPersonsJobInn': return 'ИНН';
    }
  }
}
