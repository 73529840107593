import { injectable } from 'inversify';
import { IChartJSBarDatasetsService } from '@/modules/charts/modules/chartjs/components/UIBarChart/services/chartJSBarDatasetsService.interface';
import { ChartJSBarChartItem } from '@/modules/charts/modules/chartjs/components/UIBarChart/types/ChartJSBarChartItem';

@injectable()
export class ChartJSBarDatasetsService implements IChartJSBarDatasetsService {
  createDatasets(items: ChartJSBarChartItem[]): any[] {
    return [
      {
        data: items.map((item, index) => ({ ...item })),
        labelValue: items.map((item) => item.labelValue),
        labelCurrency: items.map((item) => item.labelCurrency),
        gradientColors: items.map((item) => item.gradientColors),
      },
    ];
  }
}
