import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IPersonAddressTagCodeSuitabilityMailingServiceInterfaceKey } from '@/modules/dossier/person/addresses/services/personAddressTagCodeSuitabilityMailingService.interface';
import { PersonAddressTagCodeSuitabilityMailingService } from '@/modules/dossier/person/addresses/services/personAddressTagCodeSuitabilityMailingService';
import { IPersonAddressTagVerificationCodeServiceInterfaceKey } from '@/modules/dossier/person/addresses/services/personAddressTagVerificationCodeService.interface';
import { PersonAddressTagVerificationCodeService } from '@/modules/dossier/person/addresses/services/personAddressTagVerificationCodeService';

export function bindPersonAddressesServices(bind: IContainerBinder) {
  bind(IPersonAddressTagCodeSuitabilityMailingServiceInterfaceKey).to(
    PersonAddressTagCodeSuitabilityMailingService,
  );
  bind(IPersonAddressTagVerificationCodeServiceInterfaceKey).to(
    PersonAddressTagVerificationCodeService,
  );
}
