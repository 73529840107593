import { InjectionKey } from 'vue';
import { ApiPersonDetailVehicleRestrictsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVehicleRestrictsRecord';

export interface IPersonRestrictionsStatisticsService {
  calculateStatisticByYearItems(
    restrictions: Array<ApiPersonDetailVehicleRestrictsRecord>,
    yearsCount: number,
  ): Array<Record<string, number>>;
}

export const IPersonRestrictionsStatisticsServiceKey: InjectionKey<IPersonRestrictionsStatisticsService> =
  Symbol('IPersonRestrictionsStatisticsServiceKey');
