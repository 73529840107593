import { InjectionKey } from 'vue';
import { RoadAccidentAnalyticTableDataResult } from '@/modules/dossier/person/roadAccidents/types/roadAccidentAnalyticTableDataResult';
import { RoadAccidentsVinTableDataResult } from '@/modules/dossier/person/roadAccidents/types/roadAccidentsVinTableData';
import { ApiPersonDetailRoadAccidentRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailRoadAccidentRecord';

export interface IPersonRoadAccidentsMappingService {
  getUniqRoadAccidentsDatesByVin(
    accidentList: Array<ApiPersonDetailRoadAccidentRecord>,
    vin: string,
  ): Array<string>;
  getRoadAccidentsVinTableData(
    accidentList: Array<ApiPersonDetailRoadAccidentRecord>,
    vin: string,
    date: string,
  ): Array<RoadAccidentsVinTableDataResult>;
  getRoadAccidentAnalyticTableData(
    accidentList: Array<ApiPersonDetailRoadAccidentRecord>,
  ): Array<RoadAccidentAnalyticTableDataResult>;
}

export const IPersonRoadAccidentsMappingServiceKey: InjectionKey<IPersonRoadAccidentsMappingService> =
  Symbol('IPersonRoadAccidentsMappingService');
