import {
  ITaskStatusInfoDTOMappingService
} from '@/modules/tasks/services/taskStatusInfoDTOMappingService/taskStatusInfoDTOMappingService.interfaces';
import {
  ITaskStatusToApiStatusMapperService, ITaskStatusToApiStatusMapperServiceKey
} from '@/modules/tasks/services/taskStatusToApiStatusMapperService/taskStatusToApiStatusMapperService.interfaces';
import { ApiTaskStatusInfo } from '@/modules/tasks/types/api/apiTaskStatusInfo';
import { TaskStatusInfoDTO } from '@/modules/tasks/types/dto/taskStatusInfoDTO';
import { inject, injectable } from 'inversify';

@injectable()
export default class TaskStatusInfoDTOMappingService implements ITaskStatusInfoDTOMappingService {
  constructor(
    @inject(ITaskStatusToApiStatusMapperServiceKey as symbol) private statusMapper: ITaskStatusToApiStatusMapperService
  ) {
  }
  map(task: ApiTaskStatusInfo): TaskStatusInfoDTO {
    return {
      taskId: task.taskId,
      progress: task.progress,
      currentStep: task.currentStep,
      status: this.statusMapper.map(task.status)
    }
  }
}
