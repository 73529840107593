import { InjectionKey } from 'vue';
import { ApiPersonDetailVkRecordStringItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecordStringItem';

export interface IPersonSocialNetworkAdditionalInfoVKGetLastValueService {
  getPersonSocialNetworkVKMainInfoLatestValue(
    detailVkItemValue: Array<ApiPersonDetailVkRecordStringItem>,
  ): string;
}

export const IPersonSocialNetworkAdditionalInfoVKGetLastValueServiceKey: InjectionKey<IPersonSocialNetworkAdditionalInfoVKGetLastValueService> =
  Symbol('IPersonSocialNetworkAdditionalInfoVKGetLastValueServiceKey');
