import {
  IEnvironmentSettings,
  IEnvironmentSettingsKey
} from '@/modules/common/settings/environmentSettings.interfaces';
import { AppMode } from '@/modules/common/types/appMode';
import {
  IAppModeRouterFactory, IAppModeRouterFactoryKey
} from '@/modules/router/common/factories/appModeRouterFactory.interfaces';
import { IRouterFactory } from '@/modules/router/common/factories/routerFactory.interfaces';
import { inject, injectable, multiInject } from 'inversify';
import { Router } from 'vue-router';

@injectable()
export default class RouterFactory implements IRouterFactory {
  constructor(
    @inject(IEnvironmentSettingsKey as symbol) private envSettings: IEnvironmentSettings,
    @multiInject(IAppModeRouterFactoryKey as symbol) private routerFactories: IAppModeRouterFactory[]
  ) { }

  getRouter(): Router {
    const factory = this.routerFactories.find(x => x.appMode == this.envSettings.mode);

    if(factory == undefined)
      throw new Error(`Router factory is not found. App mode: ${AppMode[this.envSettings.mode]}`);

    return factory.getRouter();
  }
}
