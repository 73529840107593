import { MaskOptions } from 'maska';
import { injectable } from 'inversify';
import { displayPhoneMask } from '@/modules/validation/configuration/masks/phoneMask';
import { IRequisiteMasksService } from '@/modules/requisites/services/requisiteMasksService.interfaces';
import { RequisiteType } from '@/modules/requisites/types/requisiteType';
import { fullNameRequisiteMask } from '@/modules/requisites/configuration/masks/fullNameRequisiteMask';
import { dateRequisiteMask } from '@/modules/requisites/configuration/masks/dateRequisiteMask';
import { carNumberRequisiteMask } from '@/modules/requisites/configuration/masks/carNumberRequisiteMask';
import { personInnRequisiteMask } from '@/modules/requisites/configuration/masks/personInnRequisiteMask';
import { companyInnRequisiteMask } from '@/modules/requisites/configuration/masks/companyInnRequisiteMask';
import { ipInnRequisiteMask } from '@/modules/requisites/configuration/masks/ipInnRequisiteMask';
import { generalRequisiteMask } from '@/modules/requisites/configuration/masks/generalRequisiteMask';

@injectable()
export class RequisiteMasksService implements IRequisiteMasksService {
  getRequisiteMask(value: RequisiteType): MaskOptions {
    switch (value) {
      case RequisiteType.fullName:
        return fullNameRequisiteMask;
      case RequisiteType.date:
        return dateRequisiteMask;
      case RequisiteType.carNumber:
        return carNumberRequisiteMask;
      case RequisiteType.phone:
        return displayPhoneMask;
      case RequisiteType.innFl:
        return personInnRequisiteMask;
      case RequisiteType.innUl:
        return companyInnRequisiteMask;
      case RequisiteType.innIp:
        return ipInnRequisiteMask;
      default:
        return generalRequisiteMask;
    }
  }
}
