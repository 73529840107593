import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';

export module IconsUtils {
  export function getIconPath(icon: SystemIconsNames): string | undefined {
    // prettier-ignore
    switch (icon) {
      case SystemIconsNames.socialVk: return 'socialNetworks/Vk';
      case SystemIconsNames.socialOk: return 'socialNetworks/Одноклассники';
      case SystemIconsNames.socialFaceBook: return 'socialNetworks/FaceBook';
      case SystemIconsNames.socialInstagram: return 'socialNetworks/Instagram';
      case SystemIconsNames.socialTwitter: return 'socialNetworks/Twitter';
      case SystemIconsNames.socialTikTok: return 'socialNetworks/TikTok';
      case SystemIconsNames.socialTelegram: return 'socialNetworks/Telegram';
      case SystemIconsNames.socialWhatsApp: return 'socialNetworks/WhatsApp';
      case SystemIconsNames.socialViber: return 'socialNetworks/Viber';
      case SystemIconsNames.socialSkype: return 'socialNetworks/Skype';
      case SystemIconsNames.authBg: return 'authPage/authBg';
      case SystemIconsNames.authErrorIcon: return 'authPage/errorAuthIcon';
      case SystemIconsNames.authImg: return 'authPage/imgAuth';
      case SystemIconsNames.authInstagramIcon: return 'authPage/instagramIcon';
      case SystemIconsNames.authSiteIcon: return 'authPage/siteIcon';
      case SystemIconsNames.authTelegramIcon: return 'authPage/telegramIcon';
      case SystemIconsNames.authVkIcon: return 'authPage/vkIcon';
      case SystemIconsNames.sidebarArrowTopRight: return 'sidebar/arrow-top-right';
      case SystemIconsNames.sidebarCollection: return 'sidebar/collection';
      case SystemIconsNames.sidebarDataAndAnalysis: return 'sidebar/data-and-analysis';
      case SystemIconsNames.sidebarAnalysV3: return 'sidebar/analysis-v3';
      case SystemIconsNames.sidebarDataCollection: return 'sidebar/data-collection';
      case SystemIconsNames.sidebarDataImport: return 'sidebar/data-import';
      case SystemIconsNames.sidebarDataLists: return 'sidebar/data-lists';
      case SystemIconsNames.sidebarDoubleArrowLeft: return 'sidebar/double-arrow-left';
      case SystemIconsNames.sidebarHistory: return 'sidebar/history';
      case SystemIconsNames.sidebarHistoryV2: return 'sidebar/history-v2';
      case SystemIconsNames.sidebarHistoryExternalData: return 'sidebar/historyExternalData';
      case SystemIconsNames.sidebarHome: return 'sidebar/home';
      case SystemIconsNames.sidebarHomeV2: return 'sidebar/home-v2';
      case SystemIconsNames.sidebarLegalEntity: return 'sidebar/legal-entity';
      case SystemIconsNames.sidebarDataCollection1: return 'sidebar/data-collection';
      case SystemIconsNames.sidebarLogo: return 'sidebar/logo';
      case SystemIconsNames.sidebarLogOutIcon: return 'sidebar/logOutIcon';
      case SystemIconsNames.sidebarLogOutIconV2: return 'sidebar/logOutIcon-v2';
      case SystemIconsNames.sidebarPlay: return 'sidebar/play';
      case SystemIconsNames.sidebarReports: return 'sidebar/reports';
      case SystemIconsNames.sidebarReportsV2: return 'sidebar/reports-v2';
      case SystemIconsNames.uiV2AdressType: return 'uiV2/adressType';
      case SystemIconsNames.uiV2ArrowSelect: return 'uiV2/arrowSelect';
      case SystemIconsNames.uiV2CarType: return 'uiV2/carType';
      case SystemIconsNames.uiV2EmailType: return 'uiV2/emailType';
      case SystemIconsNames.uiV2ErrorMassage: return 'uiV2/errorMessage';
      case SystemIconsNames.uiV2FullNameType: return 'uiV2/fullNameType';
      case SystemIconsNames.uiV2InnCompanyType: return 'uiV2/innCompanyType';
      case SystemIconsNames.uiV2InnType: return 'uiV2/innType';
      case SystemIconsNames.uiV2MassengerType: return 'uiV2/massengerType';
      case SystemIconsNames.uiV2OgrnType: return 'uiV2/ogrnType';
      case SystemIconsNames.uiV2PassportType: return 'uiV2/passportType';
      case SystemIconsNames.uiV2PdfIcon: return 'uiV2/pdfIcon';
      case SystemIconsNames.uiV2PersonType: return 'uiV2/personType';
      case SystemIconsNames.uiV2PhoneType: return 'uiV2/phoneType';
      case SystemIconsNames.uiV2PhotoIcon: return 'uiV2/photoIcon';
      case SystemIconsNames.uiV2SnilsType: return 'uiV2/snilsType';
      case SystemIconsNames.uiV2SocietyType: return 'uiV2/societyType';
      case SystemIconsNames.uiV2UlType: return 'uiV2/ulType';
      case SystemIconsNames.uiV2SidebarArrowTopRight: return 'uiV2/sidebar/arrow-top-right';
      case SystemIconsNames.uiV2SidebarCollection: return 'uiV2/sidebar/collection';
      case SystemIconsNames.uiV2SidebarDataAndAnalysis: return 'uiV2/sidebar/data-and-analysis';
      case SystemIconsNames.uiV2SidebarDataCollection: return 'uiV2/sidebar/data-collection';
      case SystemIconsNames.uiV2SidebarDataImport: return 'uiV2/sidebar/data-import';
      case SystemIconsNames.uiV2SidebarDataLists: return 'uiV2/sidebar/data-lists';
      case SystemIconsNames.uiV2SidebarDoubleArrowLeft: return 'uiV2/sidebar/double-arrow-left';
      case SystemIconsNames.uiV2SidebarHistory: return 'uiV2/sidebar/history';
      case SystemIconsNames.uiV2SidebarHistoryExternalData: return 'uiV2/sidebar/historyExternalData';
      case SystemIconsNames.uiV2SidebarHome: return 'uiV2/sidebar/home';
      case SystemIconsNames.uiV2SidebarIndividualEntity: return 'uiV2/sidebar/individual-entity';
      case SystemIconsNames.uiV2SidebarLegalEntity: return 'uiV2/sidebar/legal-entity';
      case SystemIconsNames.uiV2SidebarLogo: return 'uiV2/sidebar/logo';
      case SystemIconsNames.uiV2SidebarLogOutIcon: return 'uiV2/sidebar/logOutIcon';
      case SystemIconsNames.sidebarMenuMobileIcon: return 'uiV2/sidebar/menuMobileIcon';
      case SystemIconsNames.uiV2SidebarPlay: return 'uiV2/sidebar/play';
      case SystemIconsNames.uiV2SidebarReports: return 'uiV2/sidebar/reports';
      case SystemIconsNames.uiV2Sidebar: return 'uiV2/sidebar/home';
      case SystemIconsNames.uiV3ArrowLeft: return 'uiV3/arrowLeft';
      case SystemIconsNames.uiV3ArrowLeftHover: return 'uiV3/arrowLeftHover';
      case SystemIconsNames.uiV3ArrowRight: return 'uiV3/arrowRight';
      case SystemIconsNames.uiV3ArrowRightHover: return 'uiV3/arrowRightHover';
      case SystemIconsNames.uiV3CheckBox: return 'uiV3/checkBox';
      case SystemIconsNames.uiV3DoubleArrowLeft: return 'uiV3/doubleArrowLeft';
      case SystemIconsNames.uiV3DoubleArrowLeftHover: return 'uiV3/doubleArrowLeftHover';
      case SystemIconsNames.uiV3DoubleArrowRight: return 'uiV3/doubleArrowRight';
      case SystemIconsNames.uiV3DoubleArrowRightHover: return 'uiV3/doubleArrowRightHover';
      case SystemIconsNames.uiV3TableFilter: return 'uiV3/tableFilter';
      case SystemIconsNames.uiV3TrashBasket: return 'uiV3/trashBasket';
      case SystemIconsNames.uiV3TrashBasketWhite: return 'uiV3/trashBasketWhite';
      case SystemIconsNames.uiV3ArrowLeftTail: return 'uiV3/arrowLeftTail';
      case SystemIconsNames.uiV3DataFlow: return 'uiV3/dataFlow';
      case SystemIconsNames.uiV3Plus: return 'uiV3/plus';
      case SystemIconsNames.uiV3Internet: return 'uiV3/internet';
      case SystemIconsNames.uiV3Trash: return 'uiV3/trash';
      case SystemIconsNames.uiV3TrashBasket2: return 'uiV3/trashBasket2';
      case SystemIconsNames.uiV3Upload: return 'uiV3/upload';
      case SystemIconsNames.uiV3File: return 'uiV3/file';
      case SystemIconsNames.uiV3Search: return 'uiV3/search';
      case SystemIconsNames.addFile: return 'addFile';
      case SystemIconsNames.adressType: return 'adressType';
      case SystemIconsNames.arrowLeftIcon: return 'arrows/arrowLeftIcon';
      case SystemIconsNames.arrowRight: return 'arrows/arrowRight';
      case SystemIconsNames.arrowSelect: return 'arrows/arrowSelect';
      case SystemIconsNames.arrowTail: return 'arrows/arrowTail';
      case SystemIconsNames.arrowTailShort: return 'arrows/arrowTailShort';
      case SystemIconsNames.barBlueIcon: return 'barBlueIcon';
      case SystemIconsNames.barRedIcon: return 'barRedIcon';
      case SystemIconsNames.burger: return 'burger';
      case SystemIconsNames.calendar: return 'calendar';
      case SystemIconsNames.calendarChartIcon: return 'calendarChartIcon';
      case SystemIconsNames.carType: return 'carType';
      case SystemIconsNames.chartBarsCompanyIcon: return 'chartBarsCompanyIcon';
      case SystemIconsNames.chartDonutCompanyIcon: return 'chartDonutCompanyIcon';
      case SystemIconsNames.checkedConsiderIcon: return 'checkedConsiderIcon';
      case SystemIconsNames.checkedDefaultIcon: return 'checkedDefaultIcon';
      case SystemIconsNames.checkedIcon: return 'checkedIcon';
      case SystemIconsNames.checkedNotConsiderIcon: return 'checkedNotConsiderIcon';
      case SystemIconsNames.checkMarkPinIcon: return 'checkMarkPinIcon';
      case SystemIconsNames.clearSearchCollection: return 'clearSearchCollection';
      case SystemIconsNames.close: return 'close';
      case SystemIconsNames.closeIcon: return 'closeIcon';
      case SystemIconsNames.closeSmall: return 'closeSmall';
      case SystemIconsNames.company: return 'company';
      case SystemIconsNames.contacts: return 'contacts';
      case SystemIconsNames.contract: return 'contract';
      case SystemIconsNames.copyIcon: return 'copyIcon';
      case SystemIconsNames.copyIcon2: return 'copyIcon2';
      case SystemIconsNames.cross: return 'cross';
      case SystemIconsNames.dismissal: return 'dismissal';
      case SystemIconsNames.documentIcon: return 'documentIcon';
      case SystemIconsNames.downloadChartIcon: return 'downloadChartIcon';
      case SystemIconsNames.downloadCloudIcon: return 'downloadCloudIcon';
      case SystemIconsNames.downloadFileIcon: return 'downloadFileIcon';
      case SystemIconsNames.downloadIcon: return 'downloadIcon';
      case SystemIconsNames.editIcon: return 'editIcon';
      case SystemIconsNames.email: return 'email';
      case SystemIconsNames.emailType: return 'emailType';
      case SystemIconsNames.errorPinIcon: return 'errorPinIcon';
      case SystemIconsNames.export: return 'export';
      case SystemIconsNames.export2: return 'export2';
      case SystemIconsNames.eye: return 'eye';
      case SystemIconsNames.filter: return 'filter';
      case SystemIconsNames.filters: return 'filters';
      case SystemIconsNames.fullNameType: return 'fullNameType';
      case SystemIconsNames.globe: return 'globe';
      case SystemIconsNames.importIcon: return 'importIcon';
      case SystemIconsNames.importIcon2: return 'importIcon2';
      case SystemIconsNames.innType: return 'innType';
      case SystemIconsNames.eftSliderArrowIcon: return 'leftSliderArrowIcon';
      case SystemIconsNames.logo: return 'logo';
      case SystemIconsNames.mapIcon: return 'mapIcon';
      case SystemIconsNames.markerAttention: return 'markerAttention';
      case SystemIconsNames.massengerType: return 'massengerType';
      case SystemIconsNames.mobileView: return 'mobileView';
      case SystemIconsNames.note: return 'note';
      case SystemIconsNames.numberDeal: return 'numberDeal';
      case SystemIconsNames.odnoklassniki: return 'odnoklassniki';
      case SystemIconsNames.okIcon: return 'okIcon';
      case SystemIconsNames.passportType: return 'passportType';
      case SystemIconsNames.pdfIcon: return 'pdfIcon';
      case SystemIconsNames.personType: return 'personType';
      case SystemIconsNames.photoIcon: return 'photoIcon';
      case SystemIconsNames.plus: return 'plus';
      case SystemIconsNames.position: return 'position';
      case SystemIconsNames.preloader: return 'preloader';
      case SystemIconsNames.printer: return 'printer';
      case SystemIconsNames.reception: return 'reception';
      case SystemIconsNames.phoneType: return 'phoneType';
      case SystemIconsNames.reliability: return 'reliability';
      case SystemIconsNames.reliabilityIconWithBack: return 'reliabilityIconWithBack';
      case SystemIconsNames.reliabilityWithBack: return 'reliabilityWithBack';
      case SystemIconsNames.reply: return 'reply';
      case SystemIconsNames.rightSliderArrowIcon: return 'rightSliderArrowIcon';
      case SystemIconsNames.search: return 'search';
      case SystemIconsNames.settings: return 'settings';
      case SystemIconsNames.showCheckMarkIndex: return 'showCheckMarkIndex';
      case SystemIconsNames.showCrossIndex: return 'showCrossIndex';
      case SystemIconsNames.showDefaultIndex: return 'showDefaultIndex';
      case SystemIconsNames.site: return 'site';
      case SystemIconsNames.societyType: return 'societyType';
      case SystemIconsNames.status: return 'status';
      case SystemIconsNames.subdivision: return 'subdivision';
      case SystemIconsNames.tableView: return 'tableView';
      case SystemIconsNames.toClose: return 'toClose';
      case SystemIconsNames.triangleGreen: return 'triangleGreen';
      case SystemIconsNames.triangleRed: return 'triangleRed';
      case SystemIconsNames.trustworthyMarker: return 'trustworthyMarker';
      case SystemIconsNames.twoLetters: return 'twoLetters';
      case SystemIconsNames.ulType: return 'ulType';
      case SystemIconsNames.uncheck: return 'uncheck';
      case SystemIconsNames.uncheckedIcon: return 'uncheckedIcon';
      case SystemIconsNames.unreliability: return 'unreliability';
      case SystemIconsNames.unreliableMarker: return 'unreliableMarker';
      case SystemIconsNames.up: return 'up';
      case SystemIconsNames.upload: return 'upload';
      case SystemIconsNames.vkIcon: return 'vkIcon';
      case SystemIconsNames.vkIconWithBack: return 'vkIconWithBack';
      case SystemIconsNames.vkontakte: return 'vkontakte';
      case SystemIconsNames.website: return 'website';
      case SystemIconsNames.subMenu: return 'subMenu';
      case SystemIconsNames.chevronBreadcrumbs: return 'chevronBreadcrumbs';
      case SystemIconsNames.uiV3Cross: return 'uiV3/cross';
      case SystemIconsNames.snackBarError: return 'snackBar/error';
      case SystemIconsNames.snackBarInfo: return 'snackBar/info';
      case SystemIconsNames.snackBarSuccess: return 'snackBar/success';
      case SystemIconsNames.uiV3Correctly: return 'uiV3/correctly';
      case SystemIconsNames.uiV3Mistakes: return 'uiV3/mistakes';
      case SystemIconsNames.checkSuccessOutlineIcon: return 'checkSuccessOutlineIcon';
      case SystemIconsNames.triangleExclamation: return 'triangleExclamation';
      case SystemIconsNames.uiV3ImagePlaceholder: return 'uiV3/imagePlaceholder';
      case SystemIconsNames.uiV3RefreshIcon: return 'uiV3/refreshStroke';
      case SystemIconsNames.uiV3CheckCircleIcon: return 'uiV3/checkRoundedStroke';
      case SystemIconsNames.eventsAb100: return 'eventsJournal/ab100';
      case SystemIconsNames.eventsBfo: return 'eventsJournal/bfo';
      case SystemIconsNames.eventsDamia: return 'eventsJournal/damia';
      case SystemIconsNames.eventsFns: return 'eventsJournal/fns';
      case SystemIconsNames.eventsIdx: return 'eventsJournal/idx';
      case SystemIconsNames.eventsImport: return 'eventsJournal/import';
      case SystemIconsNames.eventsIndirect: return 'eventsJournal/indirect';
      case SystemIconsNames.eventsKontur: return 'eventsJournal/kontur';
      case SystemIconsNames.eventsKronos: return 'eventsJournal/kronos';
      case SystemIconsNames.eventsMvd: return 'eventsJournal/mvd';
      case SystemIconsNames.eventsOdissey: return 'eventsJournal/odissey';
      case SystemIconsNames.eventsUnknown: return 'eventsJournal/unknown';
      case SystemIconsNames.eventsXneo: return 'eventsJournal/xneo';
      case SystemIconsNames.getContact: return 'dataCollection/getContact';
      case SystemIconsNames.trueCaller: return 'dataCollection/trueCaller';
      case SystemIconsNames.squareFilledTick: return 'squareFilledTick';
      case SystemIconsNames.squareFilledMinus: return 'squareFilledMinus';
      case SystemIconsNames.squareFilledPartly: return 'squareFilledPartly';
      case SystemIconsNames.squareFilledQuestion: return 'squareFilledQuestion';
      case SystemIconsNames.squareFilledReview: return 'squareFilledReview';
      case SystemIconsNames.squareFilledX: return 'squareFilledX';
      case SystemIconsNames.caret: return 'caret';
      case SystemIconsNames.reset: return 'reset';
      case SystemIconsNames.konturFocus: return 'dataCollection/kontrfocus';
      case SystemIconsNames.av100: return 'dataCollection/av100';
      case SystemIconsNames.damia: return 'dataCollection/damia';
      case SystemIconsNames.xneo: return 'dataCollection/IXneo';
      case SystemIconsNames.fns: return 'dataCollection/fns';
      case SystemIconsNames.cronos: return 'dataCollection/cronos';
      case SystemIconsNames.mvd: return 'dataCollection/mvd';
      case SystemIconsNames.odyssey: return 'dataCollection/odyssey';
      case SystemIconsNames.idx: return 'dataCollection/idx';
      case SystemIconsNames.bfo: return 'dataCollection/bfo';
      case SystemIconsNames.uiV3SearchWithCheck: return 'uiV3/searchWithCheck';
      case SystemIconsNames.uiV3PlusWithoutStroke: return 'uiV3/plusWithoutStroke';
      case SystemIconsNames.uiV3Refresh: return 'uiV3/refresh';
      case SystemIconsNames.uiV3CheckRoundedStroke: return 'uiV3/checkRoundedStroke';
      case SystemIconsNames.uiV3Error: return 'uiV3/error';
      case SystemIconsNames.uiV3Warning: return 'uiV3/warning';
      case SystemIconsNames.infoRoundedStroke: return 'infoRoundedStroke'
      case SystemIconsNames.closeRoundedSmall: return 'closeRoundedSmall'
      case SystemIconsNames.checkRoundedSmall: return 'checkRoundedSmall'
      case SystemIconsNames.warningRoundedStroke: return 'warningRoundedStroke';
      case SystemIconsNames.uiV3Edit: return 'uiV3/edit';
      case SystemIconsNames.sidebarImportRoot: return 'sidebar/importRootView';
      case SystemIconsNames.sidebarImportV2: return 'sidebar/import-v2';
      case SystemIconsNames.sidebarSearch: return 'sidebar/search';
      case SystemIconsNames.sidebarSearchV2: return 'sidebar/search-v2';
      case SystemIconsNames.sidebarPersons: return 'sidebar/persons';
      case SystemIconsNames.sidebarPersonsV2: return 'sidebar/personsV2';
      case SystemIconsNames.sidebarPersonsV3: return 'sidebar/personsV3';
      case SystemIconsNames.sidebarAnalys: return 'sidebar/analys';
      case SystemIconsNames.sidebarLists: return 'sidebar/lists';
      case SystemIconsNames.sidebarListsV2: return 'sidebar/lists-v2';
      case SystemIconsNames.closeRoundedIcon: return 'closeRoundedIcon';
      case SystemIconsNames.checkedRoundedIcon: return 'checkedRoundedIcon';
      case SystemIconsNames.actionsPrint: return 'actions/print';
      case SystemIconsNames.actionsDownloadFile: return 'actions/downloadFile';
      case SystemIconsNames.actionsAddFile: return 'actions/addFile';
      case SystemIconsNames.actionsGraph: return 'actions/graph';
      case SystemIconsNames.actionsRoundedPlus: return 'actions/roundedPlus';
      case SystemIconsNames.actionsWeb: return 'actions/web';
      case SystemIconsNames.actionsRoundedCross: return 'actions/roundedCross';
      case SystemIconsNames.circleWarning: return 'circleWarning';
      case SystemIconsNames.searchSmall: return 'searchSmall';
      case SystemIconsNames.mentionsOdyssey: return 'mentions/odyssey';
      case SystemIconsNames.mentionsGetContact: return 'mentions/getContact';
      case SystemIconsNames.mentionsTrueCaller: return 'mentions/truecaller';
      case SystemIconsNames.uiV3ChevronSmall: return 'uiV3/chevronSmall';
      case SystemIconsNames.uiV3SortAsc: return 'uiV3/sortAsc';
      case SystemIconsNames.uiV3SortDesc: return 'uiV3/sortDesc';
      case SystemIconsNames.circleExclamation: return 'circleExclamation';
      case SystemIconsNames.uiV3Settings: return 'uiV3/settings';
      case SystemIconsNames.moreVertical: return 'moreVertical';
      case SystemIconsNames.downloadCloud2: return 'downloadCloud2';
      case SystemIconsNames.image: return 'image';
      case SystemIconsNames.zoomIn: return 'zoomIn';
      case SystemIconsNames.download: return 'download';
      case SystemIconsNames.downloadFile: return 'downloadFile';
      case SystemIconsNames.rotateCw: return 'rotateCw';
      case SystemIconsNames.uiV3PersonIcon: return 'uiV3/personIcon';
      case SystemIconsNames.uiV3BriefcaseIcon: return 'uiV3/briefcase';
      case SystemIconsNames.uiV3StatusRounded: return 'uiV3/statusRounded';
      case SystemIconsNames.uiV3CheckIconBold: return 'uiV3/checkIconBold';
      case SystemIconsNames.uiV3CloseIcon: return 'uiV3/closeIcon';
      case SystemIconsNames.uiV3dxTableEmptyState: return 'uiV3/dxTableEmptyState';
      case SystemIconsNames.linkArrow: return 'linkArrow';
      case SystemIconsNames.uiV3Calendar: return 'uiV3/calendar';
      case SystemIconsNames.analysisSelection: return 'analysis/selection';
      case SystemIconsNames.analysisLists: return 'analysis/lists';
      case SystemIconsNames.analysisLogo: return 'analysis/logo';
      case SystemIconsNames.sidebarRegulationCheck: return 'sidebar/regulationCheck';
      case SystemIconsNames.sidebarRegulationCheckV2: return 'sidebar/regulationCheck-v2';
      case SystemIconsNames.uploadFile2: return 'uploadFile2'
      case SystemIconsNames.reportsRequisitesCar: return 'reportsRequisites/reportsRequisitesCar'
      case SystemIconsNames.reportsRequisitesFullName: return 'reportsRequisites/reportsRequisitesFullName'
      case SystemIconsNames.reportsRequisitesInn: return 'reportsRequisites/reportsRequisitesInn'
      case SystemIconsNames.reportsRequisitesPhone: return 'reportsRequisites/reportsRequisitesPhone'
      case SystemIconsNames.reportsRequisitesMessengerDisabled: return 'reportsRequisites/reportsRequisitesMessengerDisabled'
      case SystemIconsNames.reportsRequisitesPassportDisabled: return 'reportsRequisites/reportsRequisitesPassportDisabled'
      case SystemIconsNames.reportsRequisitesPhotoDisabled: return 'reportsRequisites/reportsRequisitesPhotoDisabled'
      case SystemIconsNames.reportsRequisitesEmailDisabled: return 'reportsRequisites/reportsRequisitesEmailDisabled'
      case SystemIconsNames.reportsRequisitesSocialDisabled: return 'reportsRequisites/reportsRequisitesSocialDisabled'
      case SystemIconsNames.reportsRequisitesAddressDisabled: return 'reportsRequisites/reportsRequisitesAddressDisabled'
      case SystemIconsNames.reportsRequisitesInnDisabled: return 'reportsRequisites/reportsRequisitesInnDisabled'
      case SystemIconsNames.reportsRequisitesPhoto: return 'reportsRequisites/reportsRequisitesPhoto'
      case SystemIconsNames.reportsRequisitesPersonType: return 'reportsRequisitesPersonType'
      case SystemIconsNames.reportsRequisitesCompanyType: return 'reportsRequisitesCompanyType'
      case SystemIconsNames.uiV3Analysis: return 'uiV3/uiV3Analysis';
      case SystemIconsNames.searchOptions: return 'search/options';
      case SystemIconsNames.chevron: return 'chevron';
      case SystemIconsNames.uiV3warningTriangle: return 'uiV3/uiV3warningTriangle'
      case SystemIconsNames.dossierUlPlaceholder: return 'dossier/ulPlaceholder';
      case SystemIconsNames.dossierFlPlaceholder: return 'dossier/flPlaceholder';
      case SystemIconsNames.reportsConfigurationPersonTypeMobile: return 'reportsMobile/personType'
      case SystemIconsNames.reportsConfigurationCompanyTypeMobile: return 'reportsMobile/companyType'
      case SystemIconsNames.backBtn: return 'reportsMobile/backBtn'
      case SystemIconsNames.nextBtn: return 'reportsMobile/nextBtn'
      case SystemIconsNames.reportsMobileLogout: return 'reportsMobile/logout'
      case SystemIconsNames.reportsMobileConfiguration: return 'reportsMobile/configuration'
      case SystemIconsNames.reportsMobileConfigurationActive: return 'reportsMobile/configurationActive'
      case SystemIconsNames.reportsMobileConfigurationBlue: return 'reportsMobile/configurationBlue'
      case SystemIconsNames.reportsMobileHistory: return 'reportsMobile/history'
      case SystemIconsNames.reportsMobileHistoryActive: return 'reportsMobile/historyActive'
      case SystemIconsNames.webLinkIcon: return 'reportsMobile/webLinkIcon'
      case SystemIconsNames.pdfLinkIcon: return 'reportsMobile/pdfLinkIcon'
      case SystemIconsNames.filterIconMobile: return 'reportsMobile/filters'
      case SystemIconsNames.decreaseIcon: return 'decreaseIcon'
      case SystemIconsNames.increaseIcon: return 'increaseIcon'
      case SystemIconsNames.uiV3PasswordShown: return 'uiV3/passwordShown'
      case SystemIconsNames.uiV3PasswordHidden: return 'uiV3/passwordHidden'
      case SystemIconsNames.uiV3threeDotsHorizontal: return 'uiV3/threeDotsHorizontal'
      case SystemIconsNames.arrowUpRight: return 'arrows/arrowUpRight'
      case SystemIconsNames.redTriangleRounded: return 'reportsMobile/redTriangleRounded'
      case SystemIconsNames.triangleWarning: return 'triangleWarning'
      case SystemIconsNames.statisticsPerson: return 'statistics/person'
      case SystemIconsNames.statisticsCompany: return 'statistics/company'
      case SystemIconsNames.sweepnetLogo: return 'logo/sweepnetLogo'
      default: return undefined;
    }
  }
}
