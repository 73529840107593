import { injectable } from 'inversify';
import { IDateValidationService } from '@/modules/validation/services/dateValidationService.interface';
import { parse, parseISO } from 'date-fns';

@injectable()
export default class DateValidationService implements IDateValidationService {
  validate(value: string, format?: string): boolean {
    const date = format == undefined ? parseISO(value) : parse(value, format, new Date());
    return date.toString() != 'Invalid Date';
  }
}
