import { ComponentDefinition } from '@/modules/common/types/componentDefinition';
import {
  UseUITagTextConfig
} from '@/modules/tags/components/UITagText/composables/useUITagText/useUITagText.interfaces';
import UITagText from '@/modules/tags/components/UITagText/UITagText.vue';

export function useUITagText(config: UseUITagTextConfig): ComponentDefinition {
  return {
    component: UITagText,
    config
  }
}
