import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IPersonContactsPhoneVerificationCodeServiceKey } from '@/modules/dossier/person/contacts/services/personContactsPhoneVerificationCodeService.interface';
import PersonContactsPhoneVerificationCodeService from '@/modules/dossier/person/contacts/services/personContactsPhoneVerificationCodeService';
import { IPersonContactsEmailVerificationCodeServiceKey } from '@/modules/dossier/person/contacts/services/personContactsEmailVerificationCodeService.interface';
import PersonContactsEmailVerificationCodeService from '@/modules/dossier/person/contacts/services/personContactsEmailVerificationCodeService';
import { IPersonContactsEmailMappingServiceKey } from '@/modules/dossier/person/contacts/services/personContactsEmailMappingService.interface';
import PersonContactsEmailMappingService from '@/modules/dossier/person/contacts/services/personContactsEmailMappingService';
import { IPersonContactsPhonesFioPhoneBondVerificationServiceKey } from '@/modules/dossier/person/contacts/services/personContactsPhonesFioPhoneBondVerificationService.interface';
import PersonContactsPhonesFioPhoneBondVerificationService from '@/modules/dossier/person/contacts/services/personContactsPhonesFioPhoneBondVerificationService';
import { IPersonContactsPhonesMappingServiceKey } from '@/modules/dossier/person/contacts/services/personContactsPhonesMappingService.interface';
import PersonContactsPhonesMappingService from '@/modules/dossier/person/contacts/services/personContactsPhonesMappingService';

export function bindPersonContactsServices(bind: IContainerBinder) {
  bind(IPersonContactsEmailVerificationCodeServiceKey).to(
    PersonContactsEmailVerificationCodeService,
  );
  bind(IPersonContactsEmailMappingServiceKey).to(PersonContactsEmailMappingService);

  bind(IPersonContactsPhoneVerificationCodeServiceKey).to(
    PersonContactsPhoneVerificationCodeService,
  );

  bind(IPersonContactsPhonesFioPhoneBondVerificationServiceKey).to(
    PersonContactsPhonesFioPhoneBondVerificationService,
  );

  bind(IPersonContactsPhonesMappingServiceKey).to(PersonContactsPhonesMappingService);
}
