import { injectable } from 'inversify';
import { Lease } from '../types/lease';
import { ICompanyLeasingService } from './companyLeasingStatisticsService.interface';

@injectable()
export default class companyLeasingService implements ICompanyLeasingService {
  calculateActiveLeases(leases: Array<Lease>): number {
    const today = new Date();
    const activeLeases = leases.filter((lease) => new Date(lease.leaseEndDate) > today);
    return activeLeases.length;
  }

  calculateArchivedLeases(leases: Array<Lease>): number {
    const today = new Date();
    const archivedLeases = leases.filter((lease) => new Date(lease.leaseEndDate) < today);
    return archivedLeases.length;
  }

  calculateLesseeSubjects(leases: Array<Lease>): number {
    const subjects = leases.reduce((acc, lease) => {
      lease.lesseeSubject.forEach((subject) => {
        if (!acc.includes(subject.id)) {
          acc.push(subject.id);
        }
      });
      return acc;
    }, [] as Array<string>);
    return subjects.length;
  }
}
