import {
  UseDxTagConfig
} from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';
import {
  IRegulationChecksStatusTagsFactory
} from '@/modules/regulationsCheck/common/factories/regulationChecksStatusTagsFactory/regulationChecksStatusTagsFactory.interfaces';
import {
  IRegulationCheckStatusFormatter, IRegulationCheckStatusFormatterKey
} from '@/modules/regulationsCheck/common/services/regulationCheckStatusFormatter/regulationCheckStatusFormatter.interfaces';
import {
  RegulationCheckStatus
} from '@/modules/regulationsCheck/common/types/regulationCheckStatus';
import { inject, injectable } from 'inversify';

@injectable()
export default class RegulationChecksStatusTagsFactory implements IRegulationChecksStatusTagsFactory {
  constructor(
    @inject(IRegulationCheckStatusFormatterKey as symbol) private statusFormatter: IRegulationCheckStatusFormatter
  ) { }
  getTag(status: RegulationCheckStatus): UseDxTagConfig {
    const content = this.statusFormatter.format(status);

    switch (status) {
      case RegulationCheckStatus.Canceled:
      case RegulationCheckStatus.Error:
        return { content, theme: 'danger' };
      case RegulationCheckStatus.Complete:
        return { content, theme: 'success' };
      default:
        return { content, theme: 'common-alt' };
    }
  }
}
