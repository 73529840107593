import { injectable } from 'inversify';
import { UITagTheme } from '@/modules/tags/types/tagTheme';
import { IPersonParticipationUITagThemeService } from '@/modules/dossier/person/participation/services/tags/personParticipationUITagThemeService.interface';

@injectable()
export default class PersonParticipationUITagThemeService
  implements IPersonParticipationUITagThemeService
{
  getRelevanceTheme(isHistoric: boolean): UITagTheme {
    return isHistoric ? 'danger' : 'success';
  }

  getRiskIndicatorTheme(riskIndicator: string): UITagTheme {
    switch (riskIndicator) {
      case 'Высокий':
        return 'danger';
      case 'Средний':
        return 'warning';
      case 'Низкий':
        return 'success';
      default:
        return 'common';
    }
  }
}
