import { ApiProcessStatus } from '@/modules/tasks/types/api/apiProcessStatus';

export module TaskUtils {
  export function isTaskInProgress(status: ApiProcessStatus) {
    return status == ApiProcessStatus.Pending || status == ApiProcessStatus.Progress;
  }

  export function isTaskFinished(status: ApiProcessStatus) {
    return status == ApiProcessStatus.Error || status == ApiProcessStatus.Complete;
  }

  export function isTaskFailed(status: ApiProcessStatus) {
    return status == ApiProcessStatus.Error;
  }
}
