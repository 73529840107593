import {
  IHousingTypeFormatter
} from '@/modules/dossier/person/common/services/formatters/housingTypeFormatter.interfaces';
import { HousingTypes } from '@/modules/dossier/person/common/types/api/housingTypes';
import { injectable } from 'inversify';

@injectable()
export default class HousingTypeFormatter implements IHousingTypeFormatter {
  format(value: HousingTypes): string {
    switch (value) {
      case HousingTypes.Unknown: return 'Неизвестно';
      case HousingTypes.LandPlot: return 'Земельный участок';
      case HousingTypes.Building: return 'Здание';
      case HousingTypes.Flat: return 'Квартира';
      case HousingTypes.Construction: return 'Сооружение';
      case HousingTypes.Room: return 'Помещение';
      case HousingTypes.ParkingSpace: return 'Машино-место';
      case HousingTypes.NotFinishedConstruction: return 'Объект незавершенного строительства';
      case HousingTypes.SingleRealEstateComplex: return 'Единый недвижимый комплекс';
      case HousingTypes.EnterprisePropertyComplex: return 'Предприятие как имущественный комплекс';
      case HousingTypes.Other: return 'Другой';
      default: return '';
    }
  }
}
