import { injectable } from 'inversify';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { ICheckingResultMapper } from '@/modules/dossier/person/passports/services/mapping/personPassportInnCheckingResultMapper.interfaces';
import { PersonPassportResultCheckingTag } from '@/modules/dossier/person/passports/types/personPassportResultCheckingTag';
import { InnResultCheckingEnum } from '@/modules/dossier/person/passports/types/innResultCheckingEnum';

@injectable()
export default class PersonPassportInnCheckingResultMapper implements ICheckingResultMapper {
  getPersonPassportInnCheckingResultTagConfig(
    status: number,
  ): PersonPassportResultCheckingTag | undefined {
    switch (status) {
      case InnResultCheckingEnum.corresponds:
        return {
          iconName: SystemIconsNames.uiV3CheckRoundedStroke,
          type: 'success',
          title: 'Паспортные данные соответствуют ИНН физического лица',
          tooltipText: 'Паспортные данные соответствуют ИНН физического лица',
          tooltip: 'auto',
        };
      case InnResultCheckingEnum.notCorresponds:
        return {
          iconName: SystemIconsNames.uiV3Error,
          type: 'error',
          title: 'Сведения не соответствуют',
          tooltipText: 'Сведения не соответствуют',
          tooltip: 'auto',
        };
      case InnResultCheckingEnum.notFound:
        return {
          iconName: SystemIconsNames.uiV3Warning,
          type: 'warning',
          title: 'Сведения не были найдены',
          tooltipText: 'Необходимо проверить вручную',
          tooltip: 'always',
        };
      default:
        return undefined;
    }
  }
}
