import { IPersonContactsEmailMappingService } from '@/modules/dossier/person/contacts/services/personContactsEmailMappingService.interface';
import { ContactTypes } from '@/modules/dossier/person/common/types/api/contactTypes';
import { DateUtils } from '@/utils/dateUtils';
import { ApiPersonDetailContactsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailContactsRecord';
import { PersonContactsMailTableRow } from '@/modules/dossier/person/contacts/types/personContactsMailTableRow';
import {
  IPersonContactsEmailVerificationCodeService,
  IPersonContactsEmailVerificationCodeServiceKey,
} from '@/modules/dossier/person/contacts/services/personContactsEmailVerificationCodeService.interface';
import { inject, injectable } from 'inversify';
import { DateFormatConfiguration } from '@/configuration/dateFormatConfiguration';

@injectable()
export default class PersonContactsEmailMappingService
  implements IPersonContactsEmailMappingService
{
  constructor(
    @inject(IPersonContactsEmailVerificationCodeServiceKey as symbol)
    private personContactsEmailVerificationCodeService: IPersonContactsEmailVerificationCodeService,
  ) {}

  mapPersonEmailDataTable(
    contacts: Array<ApiPersonDetailContactsRecord>,
  ): PersonContactsMailTableRow[] {
    return contacts
      .filter((contact) => contact.contactType == ContactTypes.Email)
      .map((contact) => ({
        value: contact.value,
        isVerified: contact.isVerified,
        modified: contact.modified,
        sourceStr: contact.sourceStr,
        contactType: contact.contactType,
        emailVerificationCode:
          this.personContactsEmailVerificationCodeService.getEmailVerificationCodeTagData(
            contact.emailVerificationCode,
          ),
        domainEmail: contact.domainEmail,
        source: contact.source,
      }));
  }
}
