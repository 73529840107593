import { InjectionKey } from 'vue';
import { DriverLicenseStatus } from '@/modules/dossier/person/vehicles/driverLicenses/types/driverLicenseStatus';
import { DriverLicenseFormattedTag } from '@/modules/dossier/person/vehicles/driverLicenses/types/driverLicenseFormattedTag';

export interface IDriverLicenseStatusDocumentMapper {
  format(status: DriverLicenseStatus): DriverLicenseFormattedTag;
}

export const IDriverLicenseStatusDocumentMapperKey: InjectionKey<IDriverLicenseStatusDocumentMapper> =
  Symbol('IDriverLicenseStatusDocumentMapperKey');
