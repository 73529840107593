import { injectable, inject } from 'inversify';
import { ImageGroupItem } from '@/modules/images/types/imageGroupItem';
import { IFilesService, IFilesServiceKey } from '@/modules/files/services/filesService.interface';
import { DateUtils } from '@/utils/dateUtils';
import { IPersonSocialNetworkAdditionalInfoOkAvatarsMappingService } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/mappings/personSocialNetworkAdditionalInfoOkAvatarsMappingService.interface';
import { ApiPersonDetailOkRecordAvatarItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordAvatarItem';

@injectable()
export default class PersonSocialNetworkAdditionalInfoOkAvatarsMappingService
  implements IPersonSocialNetworkAdditionalInfoOkAvatarsMappingService
{
  constructor(
    @inject(IFilesServiceKey as symbol)
    private FilesService: IFilesService,
  ) {}

  mapSocialNetworkAdditionalInfoOkAvatars(
    avatars: Array<ApiPersonDetailOkRecordAvatarItem>,
  ): Array<ImageGroupItem> {
    return avatars.map((avatar) => {
      const publicationDate = DateUtils.getPublicationDateText(avatar.date);
      return {
        title: publicationDate,
        src: this.FilesService.getDownloadUrl(`/api/v1/ok-parser/photo/${avatar.id}`),
      };
    });
  }
}
