import { ApiElasticSourceType } from '@/modules/elastic/types/api/apiElasticSourceType';
import {
  ISearchConceptOptionsSerializationService
} from '@/modules/searchConcept/common/services/searchConceptOptionsSerializationService/searchConceptOptionsSerializationService.interfaces';
import { SearchConceptOption } from '@/modules/searchConcept/common/types/searchConceptOption';
import {
  ICompanySearchConceptOptionsFactory, ICompanySearchConceptOptionsFactoryKey
} from '@/modules/searchConcept/company/factories/companySearchConceptOptionsFactory.interfaces';
import {
  IPersonSearchConceptOptionsFactory, IPersonSearchConceptOptionsFactoryKey
} from '@/modules/searchConcept/person/factories/personSearchConceptOptionsFactory.interfaces';
import { inject, injectable } from 'inversify';

const separator = ';';
const itemRegex = /(\w):(\w+)/

@injectable()
export default class SearchConceptOptionsSerializationService implements ISearchConceptOptionsSerializationService {
  private allOptions: SearchConceptOption[]

  constructor(@inject(IPersonSearchConceptOptionsFactoryKey as symbol) private personOptionGroupsFactory: IPersonSearchConceptOptionsFactory, @inject(ICompanySearchConceptOptionsFactoryKey as symbol) private companyOptionGroupsFactory: ICompanySearchConceptOptionsFactory) {
    this.allOptions = personOptionGroupsFactory.getOptions()
      .concat(companyOptionGroupsFactory.getOptions());
  }

  serialize(options: SearchConceptOption[]): string {
    return options
      .map(x => `${this.getSourceTag(x.source)}:${x.field.toLowerCase()}`)
      .join(separator);
  }

  deserialize(value: string): SearchConceptOption[] {
    const items = value.split(separator);

    return items.reduce((result, item) => {
      const option = this.parseOption(item);

      if (option != undefined) result.push(option);

      return result;
    }, new Array<SearchConceptOption>());
  }

  private parseOption(value: string): SearchConceptOption | undefined {
    const match = itemRegex.exec(value);

    if (match != undefined) {
      const [_, tag, field] = match;
      const source = this.getSourceFromTag(tag);
      const normalizedField = field.toLowerCase();

      return this.allOptions.find(x => x.source == source && x.field.toLowerCase() == normalizedField);
    }
  }

  private getSourceTag(source: ApiElasticSourceType) {
    switch (source) {
      case ApiElasticSourceType.Person:
        return 'p';
      case ApiElasticSourceType.Company:
        return 'c';
      default:
        return '_';
    }
  }

  private getSourceFromTag(tag: string): ApiElasticSourceType {
    switch (tag.toLowerCase()) {
      case 'p':
        return ApiElasticSourceType.Person;
      case 'c':
        return ApiElasticSourceType.Company;
      default:
        return ApiElasticSourceType.Unknown;
    }
  }
}
