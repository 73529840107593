import { IUsersService } from '@/modules/administration/users/services/usersService.interfaces';
import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiUsersDeletePayload } from '@/modules/administration/users/types/apiUsersDeletePayload';
import { ApiUsersLockPayload } from '@/modules/administration/users/types/apiUsersLockPayload';
import { ApiUserData } from '@/modules/administration/users/types/apiUserData';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { GridOutput } from '@/modules/common/types/api/gridOutput';
import { ApiUserPayload } from '@/modules/administration/users/types/apiUserPayload';
import { AxiosInstance, AxiosResponse } from 'axios';
import { LoadOptions } from 'devextreme/data';
import { inject, injectable } from 'inversify';
import { useUsersListChangedEvent } from '@/modules/administration/users/services/events/useUsersListChangedEvent';

const { trigger: triggerUsersListChangedEvent } = useUsersListChangedEvent();

@injectable()
export default class UsersService implements IUsersService {
  constructor(@inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance) {}

  getUsers(payload?: LoadOptions): Promise<AxiosResponse<ApiResponse<GridOutput<ApiUserData>>>> {
    return this.apiEndpoint.post('/auth/users', payload ?? {});
  }

  async blockAndUnblockUsers(payload: ApiUsersLockPayload) {
    await this.apiEndpoint.post('/auth/user/lockout', payload);
    triggerUsersListChangedEvent();
  }

  async deleteUsers(payload: ApiUsersDeletePayload) {
    await this.apiEndpoint.post('/auth/user/delete', payload);
    triggerUsersListChangedEvent();
  }

  async createUser(payload: ApiUserPayload) {
    await this.apiEndpoint.post('/auth/register', payload);
    triggerUsersListChangedEvent();
  }

  async updateUser(id: string, payload: ApiUserPayload) {
    await this.apiEndpoint.post(`/auth/user/${id}/update`, payload);
    triggerUsersListChangedEvent();
  }
}
