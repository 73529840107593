import { defineStore } from 'pinia';
import { computed, onScopeDispose, ref } from 'vue';
import throttle from 'lodash/throttle';
import { DevicePlatformTypes } from '@/types/devicePlatform';

const useDeviceStore = defineStore('device', () => {
  const windowWidth = ref(window.innerWidth);
  const isDesktop = computed(() => windowWidth.value > 1279);
  const isTablet = computed(() => windowWidth.value > 767 && windowWidth.value <= 1279);
  const isMobile = computed(() => windowWidth.value <= 767);

  const platformType = computed(() => {
    if (isDesktop.value) {
      return DevicePlatformTypes.DESKTOP;
    }
    if (isMobile.value) {
      return DevicePlatformTypes.MOBILE;
    }
    return DevicePlatformTypes.TABLET;
  });

  function setupWindowResizeHandler() {
    const updateWindowWidth = (width: number) => {
      windowWidth.value = width;
    };

    const windowResizeHandler = () => updateWindowWidth(window.innerWidth);
    const windowResizeHandlerThrottled = throttle(windowResizeHandler, 300);

    window.addEventListener('resize', windowResizeHandlerThrottled);
    onScopeDispose(() => window.removeEventListener('resize', windowResizeHandlerThrottled));
  }
  setupWindowResizeHandler();

  return {
    windowWidth,
    isDesktop,
    isTablet,
    isMobile,
    platformType,
  };
});

export default useDeviceStore;
