import { AppMode } from '@/modules/common/types/appMode';
import { InjectionKey } from 'vue';

export interface IEnvironmentSettings {
  mode: AppMode
  baseUrl: string
  commitHash: string
  isDev: boolean;
  isProd: boolean;
}

export const IEnvironmentSettingsKey: InjectionKey<IEnvironmentSettings> =
  Symbol('IEnvironmentSettings');
