import { IImportFilePhotosService } from '@/modules/import/services/importFilePhotosService.interface';
import { inject, injectable } from 'inversify';
import {
  IDataImportServiceKey,
  IDataImportService,
} from '@/modules/import/services/dataImportService.interfaces';
import { ParentDomainTypes } from '@/modules/common/types/api/parentDomainType';

@injectable()
export default class ImportFilePhotosService implements IImportFilePhotosService {
  constructor(
    @inject(IDataImportServiceKey as symbol) private dataImportService: IDataImportService,
  ) {}

  importFile(file: Blob, parentEntityType: ParentDomainTypes) {
    const data = new FormData();
    data.append('file', file);
    switch (parentEntityType) {
      case ParentDomainTypes.Company:
        return this.dataImportService.loadPhotoCompanies(data);
      case ParentDomainTypes.Person:
        return this.dataImportService.loadPhotoPerson(data);
      default:
        return undefined;
    }
  }
}
