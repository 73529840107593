import { injectable } from 'inversify';
import { IMassImportCompanyIdentifierMapperService } from '@/modules/import/modules/massImport/modules/company/services/massImportCompanyIdentifierMapperService/massImportCompanyIdentifierMapperService.interfaces';
import { MassImportCompanyIdentifier } from '@/modules/import/modules/massImport/modules/company/types/massImportCompanyIdentifier';
import { ApiMassImportCompanyIdentifier } from '@/modules/import/types/api/apiMassImportCompanyIdentifier';

@injectable()
export default class MassImportCompanyIdentifierMapperService
  implements IMassImportCompanyIdentifierMapperService
{
  map(identifier: MassImportCompanyIdentifier): ApiMassImportCompanyIdentifier {
    return {
      identifierType: identifier.type,
      companyIdentifier: identifier.value,
    };
  }
}
