import {
  ISearchConceptOptionGroupsFactory
} from '@/modules/searchConcept/common/factories/searchConceptOptionGroupsFactory.interfaces';
import {
  ISearchConceptOptionsFactory
} from '@/modules/searchConcept/common/factories/searchConceptOptionsFactory.interfaces';
import { SearchConceptOption } from '@/modules/searchConcept/common/types/searchConceptOption';
import {
  SearchConceptOptionsGroup
} from '@/modules/searchConcept/common/types/searchConceptOptionsGroup';
import {
  SearchConceptOptionsGroupType
} from '@/modules/searchConcept/common/types/searchConceptOptionsGroupType';
import { injectable, unmanaged } from 'inversify';

@injectable()
export default class SearchConceptOptionGroupsFactory implements ISearchConceptOptionGroupsFactory {
  constructor(@unmanaged() private optionsFactory: ISearchConceptOptionsFactory) {
  }

  getGroups(): SearchConceptOptionsGroup[] {
    const options = this.optionsFactory.getOptions();

    const optionsMap = options.reduce((result, x) => {
      let options = result.get(x.groupType);

      if (options == undefined) {
        options = new Array<SearchConceptOption>();
        result.set(x.groupType, options);
      }

      options.push(x);

      return result;
    }, new Map<SearchConceptOptionsGroupType, Array<SearchConceptOption>>());

    return [...optionsMap.entries()].map(([type, options]) => ({
      type,
      options
    }));
  }
}
