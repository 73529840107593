import { injectable } from 'inversify';
import { IPersonDossierIdService } from '@/modules/dossier/person/common/services/personDossierIdService/personDossierIdService.interfaces';
import DossierIdService from '@/modules/dossier/common/services/dossierIdService/dossierIdService';
import { GuidUtils } from '@/modules/common/utils/guidUtils';

@injectable()
export default class PersonDossierIdService
  extends DossierIdService
  implements IPersonDossierIdService
{
  getIdFromUrl(url?: string): string | undefined {
    const match = url?.match(
      /(\/person\/)(?<guid>[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/,
    );

    const guid = match?.groups?.guid;

    if (GuidUtils.isEmptyOrNull(guid)) {
      return undefined;
    }

    return guid;
  }
}
