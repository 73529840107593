import { InjectionKey } from 'vue';
import { BuhIndicators } from '@/modules/dossier/company/accounting/types/buhIndicators';
import { BuhIndicatorsList } from '@/modules/dossier/company/accounting/types/buhIndicatorsList';
import { BuhIndicator } from '@/modules/dossier/company/accounting/types/buhIndicator';

export interface IMappingService {
  getSortedUniqueYears(data: any): Array<number>;
  mapTableData(data: any, buhIndicatorsConfig: BuhIndicatorsList): Array<any>;
  mapReportsChangesMovementsTableData(
    data: any,
    buhIndicatorsConfig: Array<BuhIndicator>,
  ): Array<any>;
}
export const ICompanyAccountingMappingServiceKey: InjectionKey<IMappingService> = Symbol(
  'ICompanyAccountingMappingService',
);
