import {
  ICompaniesService, ICompaniesServiceKey
} from '@/modules/dossier/company/common/services/companiesService/companiesService.interfaces';
import { IImportFileCompanyService } from '@/modules/import/services/importFileCompanyService.interfaces';
import { ImportListSettings } from '@/modules/import/types/api/importListSettings';
import AImportFileService from '@/modules/import/services/importFileService.abstract';
import { ImportFileProcessId } from '@/modules/import/types/api/importFileProcessId';
import { inject, injectable } from 'inversify';
import {
  IProcessingSettingsService,
  IProcessingSettingsServiceKey,
} from '@/modules/processingSettings/services/processingSettingsService.interface';

@injectable()
export default class ImportFileCompanyService
  extends AImportFileService
  implements IImportFileCompanyService
{
  constructor(
    @inject(IProcessingSettingsServiceKey as symbol) private processingSettingsService: IProcessingSettingsService,
    @inject(ICompaniesServiceKey as symbol) private companiesService: ICompaniesService
  ) {
    super();
  }

  public async importFile(
    file: Blob,
    listSettings?: ImportListSettings,
  ): Promise<ImportFileProcessId> {
    const data = this.getFormDataForImportFile(
      file,
      listSettings,
      this.processingSettingsService.settings,
    );

    const response = await this.companiesService.makeProcessingCompanies(data);
    return response.data.data.importProgressId;
  }
}
