import { ApiUserLoginResult } from '@/modules/auth/common/types/api/apiUserLoginResult';
import { ApiUserLogoutResult } from '@/modules/auth/common/types/api/apiUserLogoutResult';
import { ApiUserData } from '@/modules/administration/users/types/apiUserData';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { AxiosResponse } from 'axios';
import { InjectionKey } from 'vue';

export interface IAuthService {
  login(
    email: string,
    password: string,
  ): Promise<AxiosResponse<ApiResponse<ApiUserData | ApiUserLoginResult>>>;
  logout(): Promise<AxiosResponse<ApiResponse<ApiUserLogoutResult>>>;
}

export const IAuthServiceKey: InjectionKey<IAuthService> = Symbol('IAuthService');
