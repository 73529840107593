import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import CompanyDataGridBatchLoaderService from '@/modules/dataGrid/company/services/companyDataGridBatchLoaderService';
import { ICompanyDataGridBatchLoaderServiceKey } from '@/modules/dataGrid/company/services/companyDataGridBatchLoaderService.interfaces';
import CompanyDataGridService from '@/modules/dataGrid/company/services/companyDataGridService';
import { ICompanyDataGridServiceKey } from '@/modules/dataGrid/company/services/companyDataGridService.interfaces';
import DataGridCompanyNameFormatterService from '@/modules/dataGrid/company/services/formatters/dataGridCompanyNameFormatterService/dataGridCompanyNameFormatterService';
import { IDataGridCompanyNameFormatterServiceKey } from '@/modules/dataGrid/company/services/formatters/dataGridCompanyNameFormatterService/dataGridCompanyNameFormatterService.interfaces';
import CompanyProfileToColumnInfoMapper from '@/modules/dataGrid/company/services/mappers/companyProfileToColumnInfoMapper/companyProfileToColumnInfoMapper';
import { ICompanyProfileToVisibleColumnMapperKey } from '@/modules/dataGrid/company/services/mappers/companyProfileToColumnInfoMapper/companyProfileToColumnInfoMapper.interfaces';
import { ICompanyGridToRegulationCheckTargetMapperKey } from '@/modules/dataGrid/company/services/mappers/companyGridToRegulationCheckTargetMapper/companyGridToRegulationCheckTargetMapper.interfaces';
import CompanyGridToRegulationCheckTargetMapper from '@/modules/dataGrid/company/services/mappers/companyGridToRegulationCheckTargetMapper/companyGridToRegulationCheckTargetMapper';

export function bindDataGridCompanyService(bind: IContainerBinder) {
  bind(ICompanyProfileToVisibleColumnMapperKey).to(CompanyProfileToColumnInfoMapper);
  bind(ICompanyDataGridServiceKey).to(CompanyDataGridService);
  bind(ICompanyDataGridBatchLoaderServiceKey).to(CompanyDataGridBatchLoaderService);
  bind(IDataGridCompanyNameFormatterServiceKey).to(DataGridCompanyNameFormatterService);
  bind(ICompanyGridToRegulationCheckTargetMapperKey).to(CompanyGridToRegulationCheckTargetMapper);
}
