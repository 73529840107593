import { ApiPersonDetailOkRecordRelativeItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordRelativeItem';
import { ApiPersonDetailOkRecordUserItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordUserItem';
import { InjectionKey } from 'vue';

export interface IPersonSocialNetworkAdditionalInfoOkEntitiesMappingService {
  mapListDataByEntity(
    listEntity: Array<any>,
    entity: string,
  ): Array<ApiPersonDetailOkRecordRelativeItem | ApiPersonDetailOkRecordUserItem>;
}

export const IPersonSocialNetworkAdditionalInfoOkEntitiesMappingServiceKey: InjectionKey<IPersonSocialNetworkAdditionalInfoOkEntitiesMappingService> =
  Symbol('IPersonSocialNetworkAdditionalInfoOkEntitiesMappingServiceKey');
