import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import {
  bindCompanyAccountingServices
} from '@/modules/dossier/company/accounting/configuration/inversify/container.accounting';
import {
  bindCompanyAddressesServices
} from '@/modules/dossier/company/address/configuration/inversify/container.dossierCompanyAddress';
import {
  bindCompanyArbitrationServices
} from '@/modules/dossier/company/arbitrations/configuration/inversify/container.companyArbitration';
import CompaniesService
  from '@/modules/dossier/company/common/services/companiesService/companiesService';
import {
  ICompaniesServiceKey
} from '@/modules/dossier/company/common/services/companiesService/companiesService.interfaces';
import CompanyBranchesMappingService
  from '@/modules/dossier/company/common/services/mappings/companyBranchesMappingService';
import {
  ICompanyBranchesMappingServiceKey
} from '@/modules/dossier/company/common/services/mappings/companyBranchesMappingService.interface';
import companyCertificatesMappingService
  from '@/modules/dossier/company/common/services/mappings/companyCertificatesMappingService';
import {
  ICompanyCertificatesMappingServiceKey
} from '@/modules/dossier/company/common/services/mappings/companyCertificatesMappingService.interface';
import companyInspectionsMappingService
  from '@/modules/dossier/company/common/services/mappings/companyInspectionsMappingService';
import {
  ICompanyInspectionsMappingServiceKey
} from '@/modules/dossier/company/common/services/mappings/companyInspectionsMappingService.interface';
import companyLicensesService
  from '@/modules/dossier/company/common/services/mappings/companyLicensesService';
import {
  ICompanyLicensesServiceKey
} from '@/modules/dossier/company/common/services/mappings/companyLicensesService.interface';
import companyPurchasesService
  from '@/modules/dossier/company/common/services/mappings/companyPurchasesService';
import {
  ICompanyPurchasesServiceKey
} from '@/modules/dossier/company/common/services/mappings/companyPurchasesService.interface';
import companyBranchesService
  from '@/modules/dossier/company/common/services/statistics/companyBranchesService';
import {
  ICompanyBranchesServiceKey
} from '@/modules/dossier/company/common/services/statistics/companyBranchesService.interface';
import {
  companyInspectionsService
} from '@/modules/dossier/company/common/services/statistics/companyInspectionsService';
import {
  ICompanyInspectionsServiceKey
} from '@/modules/dossier/company/common/services/statistics/companyInspectionsService.interface';
import companyCertificatesService
  from '@/modules/dossier/company/common/services/statistics/companySertificatesService';
import {
  ICompanyCertificatesServiceKey
} from '@/modules/dossier/company/common/services/statistics/companySertificatesService.interface';
import {
  bindCompanyContactsTypeCheckService
} from '@/modules/dossier/company/contacts/configuration/inversify/container.companyDossierContacts';
import {
  bindCompanyDossierEnforcementProceedingsServices
} from '@/modules/dossier/company/enforcementProceedings/configuration/inversify/container.companyDossierEnforcementProceedings';
import {
  bindCompanyFinanceAnalysisServices
} from '@/modules/dossier/company/financeAnalysis/configurations/inversify/container.financeAnalysis';
import {
  bindCompanyDossierLeasingServices
} from '@/modules/dossier/company/leasing/configuration/inversify/container.companyDossierLeasing';
import {
  bindCompanyParticipationServices
} from '@/modules/dossier/company/participations/configurations/inversify/container.companyParticipations';
import {
  bindCompanyDossierPledgesServices
} from '@/modules/dossier/company/pledges/configuration/inversify/container.companyDossierPledges';
import {
  bindCompanyRelationsServices
} from '@/modules/dossier/company/relations/configuration/inversify/container.companyRelations';
import {
  bindCompanySocialNetworksServices
} from '@/modules/dossier/company/socials/configurations/inversify/container.companySocialNetworks';
import {
  bindCompanySROServices
} from '@/modules/dossier/company/sro/configuration/inversify/container.companyDossierSRO';
import {
  bindCompanyDossierTrademarkServices
} from '@/modules/dossier/company/trademark/configuration/inversify/container.companyDossierTrademark';
import {
  bindCompanyVehicleServices
} from '@/modules/dossier/company/vehicles/configuration/inversify/container.companyVehicles';
import { ICompanyDossierIdServiceKey } from '@/modules/dossier/company/common/services/companyDossierIdService/companyDossierIdService.interfaces';
import CompanyDossierIdService from '@/modules/dossier/company/common/services/companyDossierIdService/companyDossierIdService';

export function bindCompanyDossierServices(bind: IContainerBinder) {
  bindCompanyDossierLeasingServices(bind);
  bindCompanyContactsTypeCheckService(bind);
  bindCompanySROServices(bind);
  bindCompanyArbitrationServices(bind);
  bindCompanyVehicleServices(bind);
  bindCompanyFinanceAnalysisServices(bind);
  bindCompanyRelationsServices(bind);
  bindCompanyDossierEnforcementProceedingsServices(bind);
  bindCompanyDossierPledgesServices(bind);
  bindCompanyParticipationServices(bind);
  bindCompanyAccountingServices(bind);
  bindCompanyAddressesServices(bind);
  bindCompanyDossierTrademarkServices(bind);
  bindCompanySocialNetworksServices(bind);

  bind(ICompaniesServiceKey).to(CompaniesService);
  bind(ICompanyBranchesServiceKey).to(companyBranchesService);
  bind(ICompanyBranchesMappingServiceKey).to(CompanyBranchesMappingService);
  bind(ICompanyLicensesServiceKey).to(companyLicensesService);
  bind(ICompanyPurchasesServiceKey).to(companyPurchasesService);
  bind(ICompanyInspectionsMappingServiceKey).to(companyInspectionsMappingService);
  bind(ICompanyCertificatesServiceKey).to(companyCertificatesService);
  bind(ICompanyCertificatesMappingServiceKey).to(companyCertificatesMappingService);
  bind(ICompanyInspectionsServiceKey).to(companyInspectionsService);
  bind(ICompanyDossierIdServiceKey).to(CompanyDossierIdService);
}
