import { InjectionKey } from 'vue';
import { Arbitration } from '@/modules/dossier/company/arbitrations/types/arbitration';
import { ArbitrationIndividualTypeStr } from '@/modules/dossier/company/arbitrations/types/arbitrationIndividualTypeStr';
import { ArbitrationSummaryData } from '@/modules/dossier/company/arbitrations/types/companyArbitrationSummaryData';
import { GetGroupedArbitrationByYearResult } from '@/modules/dossier/company/arbitrations/types/getGroupedArbitrationByYearResult';
import { GetArbitrationListTableDataResult } from '@/modules/dossier/company/arbitrations/types/getArbitrationListTableDataResult';
import { DossierTagData } from '@/modules/common/types/dossierTag';

export interface ICompanyArbitrationMappingService {
  getArbitrationStatusTagData(status: string): DossierTagData;
  getArbitrationListTableData(
    arbitrations: Array<Arbitration>,
  ): Array<GetArbitrationListTableDataResult>;
  getArbitrationSummaryDataByType(
    arbitrationSummaryData: Array<ArbitrationSummaryData>,
    type: ArbitrationIndividualTypeStr,
  ): ArbitrationSummaryData | undefined;
  getArbitrationListByType(
    arbitrationList: Array<Arbitration>,
    type: ArbitrationIndividualTypeStr,
  ): Array<Arbitration>;
  getGroupedArbitrationByYear(
    arbitrationList: Array<Arbitration>,
  ): Array<GetGroupedArbitrationByYearResult>;
}
export const ICompanyArbitrationMappingServiceKey: InjectionKey<ICompanyArbitrationMappingService> =
  Symbol('ICompanyArbitrationMappingService');
