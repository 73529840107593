import { IReportParentTypeFormatter } from '@/modules/reports/common/services/reportParentTypeFormatter.interfaces';
import { ReportParentType } from '@/modules/reports/common/types/ReportParentType';
import { injectable } from 'inversify';

@injectable()
export default class ReportParentTypeFormatter implements IReportParentTypeFormatter {
  format(parentType: ReportParentType): string {
    switch (parentType) {
      case ReportParentType.company:
        return 'Юридическое лицо';
      case ReportParentType.person:
        return 'Физическое лицо';
      case ReportParentType.unknown:
      default:
        return 'Неизвестно';
    }
  }
}
