import {
  IElasticPersonFieldFormatter
} from '@/modules/elastic/services/elasticPersonFieldFormatter/elasticPersonFieldFormatter.interfaces';
import { ApiElasticPersonField } from '@/modules/elastic/types/api/apiElasticPersonField';
import { injectable } from 'inversify';

@injectable()
export default class ElasticPersonFieldFormatter implements IElasticPersonFieldFormatter {
  format(type: ApiElasticPersonField): string {
    switch (type) {
      case 'firstName': return 'Имя';
      case 'lastName': return 'Фамилия';
      case 'patronymic': return 'Отчество';
      case 'fullNames': return 'Полное имя';
      case 'inns': return 'ИНН';
      case 'snilses': return 'СНИЛС';
      case 'birthPlaces': return 'Место рождения';
      case 'citizenships': return 'Гражданство';
      case 'nationalities': return 'Национальность';
      case 'passports': return 'Паспортные данные';
      case 'ratings': return 'Скоринговая оценка персоны';
      case 'phones': return 'Телефон';
      case 'emails': return 'E-mail';
      case 'sites': return 'Сайт';
      case 'messengers': return 'Месенджер';
      case 'addresses': return 'Адрес';
      case 'adds': return 'Объявления';
      case 'phonesRelatedCarNumber': return 'Гос. номер';
      case 'phonesRelatedDossierObject': return 'Досье';
      case 'parkingZones': return 'Номер парковочной зоны';
      case 'parkingCarNumbers': return 'Гос.номер транспортного средства';
      case 'educationNames': return 'Учебное заведение';
      case 'educationSpecialties': return 'Специальность';
      case 'educationQualifications': return 'Квалификация';
      case 'educationProffesions': return 'Профессия';
      case 'legalEnitityParticipationCompanyShortNames': return 'Короткое наименование компании';
      case 'legalEnitityParticipationCompanyFullNames': return 'Полное наименование организации';
      case 'legalEnitityParticipationCompanyInns': return 'ИНН';
      case 'legalEnitityParticipationCompanyOgrns': return 'ОГРН';
      case 'jobCompanyNames': return 'Полное наименование организации';
      case 'jobCompanyInns': return 'ИНН';
      case 'jobCompanyOgrns': return 'ОГРН';
      case 'jobDepartments': return 'Подразделение';
      case 'jobPositions': return 'Должность';
      case 'jobPhones': return 'Телефон организации';
      case 'jobFriendFios': return 'Полное имя рекомендателя';
      case 'businessTripCompanySenders': return 'Командирующая компания';
      case 'businessTripCompanyReceiver': return 'Принимающая компания';
      case 'militaryRecordIds': return 'Военный билет';
      case 'negativeInfos': return 'Тип негатива';
      case 'negativeInfoSource': return 'Источник ';
      case 'familyStatus': return 'Семейное положение';
      case 'familyFullNames': return 'ФИО родственника';
      case 'familyInns': return 'ИНН';
      case 'affiliatedCompanyNames': return 'Связанные компании';
      case 'affiliatedCompanyInns': return 'ИНН связанных компаний';
      case 'affiliatedCompanyOgrns': return 'ОГРН связанных компаний';
      case 'affiliatedFlFullNames': return 'Связанные персоны';
      case 'affiliatedFlInns': return 'ИНН связанных персон';
      case 'affiliatedFlPhones': return 'Телефоны связанных персон';
      case 'affiliatedFlAddresses': return 'Адреса связанных персон';
      case 'movementAirportFrom': return 'Аэропорт вылета';
      case 'movementAirportTo': return 'Аэропорт прибытия';
      case 'movementCovidAndPassFrom': return 'Место отправления';
      case 'movementCovidAndPassTo': return 'Место прибытия';
      case 'movementCovidAndPassCarNumbers': return 'гос номер автомобиля';
      case 'movementTaxiFrom': return 'Место отправления';
      case 'movementTaxiTo': return 'Место прибытия';
      case 'notes': return 'Примечание';
      case 'tags': return 'Упоминания';
      case 'socials': return 'Социальные сети ';
      case 'driverIds': return 'Водительское удостоверение';
      case 'carNumbers': return 'Гос.номер транспортного средства  ';
      case 'carVins': return 'VIN транспортного средства';
      case 'carMarks': return 'Марка транспортного средства';
      case 'carModels': return 'Модель транспортного средства';
      case 'carOwnerFullNames': return 'Полное имя собственника';
      case 'carOwnerPhones': return 'Абонентский номер собственника';
      case 'carOwnerInns': return 'ИНН собственника';
      case 'carOwnerAddresses': return 'Адрес собственника';
    }
  }
}
