import { AnalysisEntity } from '@/modules/analysis/types/analysisEntity';
import { ApiPersonListDataItem } from '@/modules/lists/types/api/fl/apiPersonListDataItem';
import { ApiUlListDataItem } from '@/modules/lists/types/api/ul/apiUlListDataItem';
import { InjectionKey } from 'vue';

export interface IPersonListToAnalysisEntityMapper {
  map(item: ApiPersonListDataItem): AnalysisEntity
}

export const IPersonListToAnalysisEntityMapperKey: InjectionKey<IPersonListToAnalysisEntityMapper> = Symbol('IPersonListToAnalysisEntityMapper');
