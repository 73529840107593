import { InjectionKey } from 'vue';
import { FinancialStatementsFileTypes } from '@/modules/files/types/FinancialStatementsFileTypes';

export interface IFilesService {
  getImportPersonSchemeUrl(): string;
  getImportCompanySchemeUrl(): string;
  getDownloadUrl(relativeUrl?: string): string;
  getDownloadEGRN(companyId: string): string;
  getDownloadFinancialStatementsUrl(
    companyId: string,
    fileType: FinancialStatementsFileTypes,
    year: number,
  ): string;
  downloadFile(response: string | Uint8Array, filename: string, type?: string): void;
  convertBase64ToUnit8Array(base64: string): Uint8Array;
}

export const IFilesServiceKey: InjectionKey<IFilesService> = Symbol('IFilesService');
