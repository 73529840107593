import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { AdministrationMarkerWeightsGroup } from '@/modules/administration/types/administrationMarkerWeightsGroup';

export const markerWeightsCompanyGroups: Array<AdministrationMarkerWeightsGroup> = [
  {
    groupName: 'Юридические признаки',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'совладелец (>50%) или руководитель являются ИП',
      'есть сведения о смерти руководителя, учредителя или индивидуального предпринимателя',
      'статус требует внимания',
      'индивидуальный предприниматель зарегистрирован недавно',
      'юридический адрес был изменен в течение последнего месяца',
      'регион регистрации был изменен в течение последнего года',
      'юридический адрес был изменен более 2 раз в течение последнего года',
      'руководитель возглавляет более 5 действующих компаний',
      'физическое лицо является руководителем или учредителем более 10 действующих юридических лиц',
      'физическое лицо является руководителем или учредителем более 50 действующих юридических лиц',
      'руководитель возглавлял более 3 ликвидированных компаний',
      'руководитель упоминается как действующий или бывший руководитель более 50 юридических лиц',
      'произошло исключение из реестра МСП',
      'учредитель участвовал более чем в 3 ликвидированных компаниях',
      'зафиксирована одновременная смена руководителя и единственного учредителя в течение последнего года',
      'произошла смена руководителя более 2 раз в течение последнего года',
      'единственный учредитель и руководитель объекта проверки – одно лицо',
      'совладелец или руководитель являются ИП',
      'адрес указан как местонахождение нескольких юридических лиц',
      'учредитель участвует в более 5 действующих компаниях',
      'телефон указан несколькими действующими юридическими лицами в качестве конктактного',
      'произошло банкротство связанных компаний',
      'уставный капитал имеет минимально допустимый размер',
      'зафиксировано уменьшение уставного капитала',
      'управляющая компания находится в процессе ликвидации или реорганизации',
      'зафиксировано банкротство компании-учредителя',
    ],
  },
  {
    groupName: 'Юридические признаки',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: ['Деятельность прекращена'],
  },
  {
    groupName: 'Особые реестры',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'Деятельность прекращена',
      'юридический адрес указан при государственной регистрации несколькими юридическими лицами ("массовый" адрес)',
      'руководитель является единоличным исполнителем руководящих обязанностей в нескольких юридических лицах',
      'учредитель является учредителем нескольких юридических лиц',
      'учредитель находится в реестре "массовых" руководителей',
      'ИП находится реестре "массовых" учредителей',
      'ИП находится реестре "массовых" руководителей',
      'Физическое лицо имеет ограничения участия в юридических лицах',
      'Физическое лицо входит в реестр лиц, уволенных в связи с утратой доверия',
      'сведения об адресе недостоверны',
      'сведения о руководителе или учредителе недостоверны',
      'ранее сведения об адресе были признаны недостоверными',
      'ранее сведения о руководителе или учредителе были признаны недостоверными',
      'руководитель входит в реестр дисквалифицированных лиц',
      'налоговая отчетность не представлялась более 1 года',
      'зафиксирована задолженность по уплате налогов',
      'имеется действующее решение ФНС о приостановлении операций по счетам',
      'найдена запись в реестре недобросовестных поставщиков',
      'найдены признаки "финансовой пирамиды"',
      'найдены признаки нелегального кредитора',
      'найдены признаки нелегального профессионального участника рынка ценных бумаг',
      'зафиксировано вероятное совпадение руководителя или учредителя со списком организаций и (или) физических лиц, включаемых в перечень организаций и физических лиц, в отношении которых имеются сведения об их причастности к распространению оружия массового уничтожения',
      'зафиксировано вероятное совпадение с перечнем организаций и физических лиц, в отношении которых имеются сведения об их причастности к экстремистской деятельности или терроризму',
      'компания привлекалась к административной ответственности за незаконное вознаграждение',
    ],
  },
  {
    groupName: 'Особые реестры',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'связанная компания входит в негативный список',
      'деятельность связана с операциями повышенной степени риска в рамках 115-ФЗ',
      'выявлены нарушения в ходе проверки органами государственной власти',
      'юридическое лицо-учредитель входит в негативный реестр',
    ],
  },
  {
    groupName: 'Финансовое состояние',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'за последний доступный год выручка снизилась более, чем на 50%',
      'выручка снизилась более чем на 50%',
      'выручка снижается третий год подряд',
      'деятельность убыточна',
      'деятельность убыточна третий год подряд',
      'чистые активы снизились',
      'стоимость чистых активов меньше уставного капитала',
      'имеется высокая кредитная нагрузка',
      'зафиксированы нулевые показатели в бухгалтерской отчетности',
      'показатели бухгалтерской отчетности идентичны за 2 последних года',
      'собственный капитал отсутствует',
      'Оборотные активы отсутствуют',
      'Внеоборотные активы отсутствуют',
      'зафиксирован низкий рейтинг финансовой отчетности',
    ],
  },
  {
    groupName: 'Признаки процедуры банкротства',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'имеется акт о введении процедуры банкротства в картотеке арбитражных дел',
      'зафиксировано намерение обратиться в суд с заявлением о банкротстве',
      'имеются признаки завершенной процедуры банкротства',
      'найдены сообщения о банкротстве',
      'идет текущая стадия банкротства',
      'найдены сообщения о банкротстве руководителя, учредителя либо ИП',
    ],
  },
  {
    groupName: 'Требования к компании и от нее',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'величина исковых требований к компании значительна',
      'величина исковых требований от компании значительна',
    ],
  },
  {
    groupName: 'Требования к компании и от нее (Для ИП - требования к ИП и от него)',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'сумма исполнительных производств значительна',
      'сумма недоимок по платежам в бюджет значительна',
      'найдены незавершенные исполнительные производства',
      'произошел значительный рост исковой нагрузки в течение последнего года',
      'компания или ИП выступают в арбитражных судах только в роли ответчика',
      'найдены непогашенные обязательства по арбитражным делам',
      'найдены исполнительные производства, предметом которых является заработная плата',
      'найдены исполнительные производства, предметом которых является наложение ареста',
      'найдены исполнительные производства, предметом которых является кредитные платежи',
      'найдены исполнительные производства, предметом которых является обращение взыскания на заложенное имущество',
      'найдены исполнительные производства, предметом которых являются налоги и сборы',
      'найдены исполнительные производства, предметом которых являются страховые взносы',
      'найдены исполнительные производства, возвращенные взыскателю по причине невозможности взыскания. Например, из-за невозможности установить местонахождение должника или его имущества',
      'найдены арбитражные дела, которые связаны с проведением процедуры банкротства',
      'найдены арбитражные дела, которые связаны с обязательствами по договорам займа, кредита, лизинга',
      'найдены арбитражные дела, которые связаны с налогами',
      'найдены арбитражные дела, которые связаны с обязательствами по договорам на оказание услуг',
      'найдены арбитражные дела, которые связаны с обязательствами по договорам поставки',
    ],
  },
  {
    groupName: 'Санкционные списки',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'компания находится в санкционном списке США',
      'компания находится в секторальном санкционном списке США',
      'компания находится в санкционном списке Европейского союза',
      'компания находится в санкционном списке Великобритании',
      'компания находится в санкционном списке Украины',
      'компания находится в санкционном списке Швейцарии',
      'совладельцы компании попадают под санкции США, и их суммарная доля владения не меньше 50%',
      'совладельцы компании попадают под секторальные санкции США, и их суммарная доля владения не меньше 50%',
      'совладельцы компании попадают под санкции Европейского союза, и их суммарная доля владения не меньше 50%',
      'совладельцы компании попадают под санкции Великобритании, и их суммарная доля владения не меньше 50%',
      'совладельцы компании попадают под санкции Украины, и их суммарная доля владения не меньше 50%',
      'совладельцы компании попадают под санкции Швейцарии, и их суммарная доля владения не меньше 50%',
    ],
  },
  {
    groupName: 'Активность',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'Отсутствуют данные о доменах',
      'среднесписочная численность значительно снизилась в течение последнего года',
      'информация о деятельности контрагента в открытых источниках отсутствует',
      'признаки активности отсутствуют',
      'государственные контракты составляют значительную долю в выручке',
      'зафиксирован высокий процент проигрышей при участии в торгах',
      'установлен факт недопуска к государственным закупкам',
      'имеются государственные контракты, исполнение по которым прекращено',
      'подано заявление в ЕГРЮЛ, связанное с прекращением деятельности в качестве ИП или КФХ',
      'подано заявление в ЕГРЮЛ, связанное с планируемой ликвидацией компании',
      'подано заявление в ЕГРЮЛ, связанное с изменением руководителя или управляющей компании',
      'подано заявление в ЕГРЮЛ, связанное с изменением состава участников (владельцев)',
      'подано заявление в ЕГРЮЛ, связанное с изменением уставного капитала',
      'подано заявление в ЕГРЮЛ об изменении сведений по форме Р13014',
      'подано заявление в ЕГРЮЛ, связанное с изменением юридического адреса',
      'среднесписочная численность 0 или 1 (т.е. штатные работники отсутствуют)',
      'нет сведений о персонале по данным ФНС',
      'установлена негативная деловая репутация',
      'лицензия в случае заключения договора на этот вид деятельности отсутствует',
      'компания-клон',
      'срок действия всех товарных знаков истек',
      'срок действия всех выданных лицензий истек',
      'контактные телефоны недоступны',
      'отсутствуют помещения (офисные, складские, производственные и т.д.)',
      'отсутствует e-mail',
      'отсутствует опыт аналогичной работы или необходимые технологии',
      'сайт содержит недостоверные сведения',
      'установлена связь с рисковой юрисдикцией',
    ],
  },

  {
    groupName: 'Связи',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'установлены криминальные связи',
      'установлена связь с контрагентами',
      'установлена связь с конкурентами',
      'установлена связь с сотрудниками',
      'установлена связь с родственниками сотрудников',
      'установлена связь с "черным" списком',
      'объект проверки входит в число конкурентов',
      'объект проверки входит в число контрагентов',
      'объект проверки является(-лся) сотрудником',
      'объект проверки входит в "черный" список',
    ],
  },
  {
    groupName: 'Связи',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'зафиксировано совпадение руководителя или учредителя со списками ИПДЛ',
      'зафиксировано совпадение руководителя или учредителя со списками МПДЛ',
      'зафиксировано совпадение руководителя или учредителя со списками РПДЛ',
      'зафиксировано совпадение руководителя или учредителя со списками близкого окружения РПДЛ/МПДЛ/ИПДЛ',
    ],
  },
  {
    groupName: 'Риск налоговой проверки',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'Налоговая нагрузка ниже среднеотраслевого показателя',
      'расходы превышают доходы',
      'Рентабельность ниже среднеотраслевого показателя',
      'доходы близки к лимиту по УСН',
      'среднемесячная заработная плата не превышает 15 тыс. руб.',
    ],
  },
  {
    groupName: 'Имущество',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: ['зафиксировано обременение доли в уставном капитале'],
  },
  {
    groupName: 'Ретроспектива взаимоотношений',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'имеются контракты, расторгнутые по соглашению сторон',
      'имеются контракты, расторгнутые по решению суда',
      'имеются контракты, расторгнутые по вине контрагента',
      'компания или ИП являлись ответчиком в деле, истцом по которому выступала компания внутреннего контура',
      'компания или ИП являлись истцом в деле, ответчиком по которому выступала компания внутреннего контура',
      'имеется дебиторская задолженность перед компанией внутреннего контура',
      'имеются факты порчи, утраты, уничтожения документов',
      'контрагент предлагает нестандартные способы осуществления расчетов (бартер, уступка прав требования и др.)',
      'предмет и условия предлагаемого договора противоречат требованиям законодательства',
      'условия заключаемого договора противоречат экономическим интересам контрагента либо не имели экономического смысла',
    ],
  },
  {
    groupName: 'Структура компании',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'управляющая компания имеет высокий сводный индикатор риска',
      'учредитель-юридическое лицо имеет высокий сводный индикатор риска',
    ],
  },

  {
    groupName: 'Структура компании',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'управляющая компания имеет средний сводный индикатор риска',
      'учредитель-юридическое лицо имеет средний сводный индикатор риска',
    ],
  },
  {
    groupName: 'Физические лица',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'индивидуальный предприниматель имеет высокий сводный индикатор риска',
      'руководитель или учредитель имеет высокий сводный индикатор риска',
    ],
  },
  {
    groupName: 'Физические лица',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'руководитель или учредитель имеет средний сводный индикатор риска',
      'индивидуальный предприниматель имеет средний сводный индикатор риска',
      'информация о руководителе, учредителе, индивидуальном предпринимателе не запрашивалась',
    ],
  },
  {
    groupName: 'Маркеры благонадежности',
    statusIcon: SystemIconsNames.checkSuccessOutlineIcon,
    statusIconColor: 'green',
    list: [
      'компания зарегистрирована недавно',
      'компания зарегистрирована более 3 лет назад',
      'индивидуальный предприниматель зарегистрирован более 3 лет назад',
      'уставный капитал превышает 50 тыс. руб.',
      'уставный капитал превышает 100 тыс. руб.',
      'уставный капитал превышает 500 тыс. руб.',
      'уставный капитал превышает 1 млн. руб.',
      'уставный капитал превышает 10 млн. руб.',
      'уставный капитал превышает 100 млн. руб.',
      'зафиксированы признаки активности в течение года',
      'отсутствуют упоминания в особых реестрах',
      'отсутствуют сведения о признаках банкротства',
      'найдена запись в реестре МСП',
      'компания найдена в перечне стратегических предприятий и стратегических акционерных обществ, который утвержден Указом Президента Российской Федерации от 04 августа 2004 г. № 1009',
      'компания найдена в перечне акционерных обществ по Распоряжению Правительства № 91 Р ("золотая акция")',
      'компания является членом СРО НОСТРОЙ',
      'компания является членом СРО НОПРИЗ',
      'финансовое состояние удовлетворительное',
      'финансовое состояние хорошее',
      'выручка компании превышает 1 млрд. руб.',
      'компания имеет филиалы',
      'сумма исковых требований к компании и от нее незначительна',
      'сумма исполнительных производств незначительна',
      'сумма исковых требований к ИП и от него незначительна',
      'компания или ИП не выступали в арбитражных судах в роли ответчика',
      'отсутствуют незавершенные исполнительные производства',
      'не найдены записи в санкционных списках',
      'негативные сведения в отношении физических лиц не установлены',
      'есть контактные данные',
      'есть зарегистрированные домены',
      'есть действующие лицензии',
      'есть действующие сертификаты или декларации',
      'компания подавала заявки на регистрацию новых товарных знаков или изобретений',
      'компания является правообладателем интеллектуальной собственности',
      'Имущество в лизинге',
      'есть бухгалтерская отчетность',
      'есть транспортные средства в собственности или аренде',
      'компания является заказчиком в государственных закупках',
      'компания является поставщиком в государственных закупках',
      'в ходе проверок органами государственной власти нарушения не выявлялись',
      'учредительные документы прошли проверку',
      'доверенности прошли проверку',
      'имеется лицензия, необходимая для исполнения контракта',
      'условия заключаемого договора не противоречат экономическим интересам контрагента и имеют экономический смысл',
      'предмет и условия предлагаемого договора не противоречат требованиям законодательства',
      'связи с конкурентами не установлены',
      'связи с контрагентами не установлены',
      'связи с сотрудниками не установлены',
      'связи с родственниками сотрудников не установлены',
      'связи с "черными" списками не установлены',
      'ретроспектива отношений отсутствует или хорошая',
    ],
  },
];
