import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import GraphDisplayInfoPlaceholderService
  from '@/modules/graph/table/services/graphDisplayInfoPlaceholderService';
import {
  IGraphDisplayInfoPlaceholderServiceKey
} from '@/modules/graph/table/services/graphDisplayInfoPlaceholderService.interfaces';
import GraphService from '@/modules/graph/table/services/graphService';
import { IGraphServiceKey } from '@/modules/graph/table/services/graphService.interfaces';
import GraphStatsPlaceholderService from '@/modules/graph/table/services/graphStatsPlaceholderService';
import {
  IGraphStatsPlaceholderServiceKey
} from '@/modules/graph/table/services/graphStatsPlaceholderService.interfaces';

export function bindTableGraphServices(bind: IContainerBinder) {
  bind(IGraphServiceKey).to(GraphService);
  bind(IGraphStatsPlaceholderServiceKey).to(GraphStatsPlaceholderService);
  bind(IGraphDisplayInfoPlaceholderServiceKey).to(GraphDisplayInfoPlaceholderService);
}
