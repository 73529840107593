<template>
  <UIConfirmation
    class="ui-desktop-confirmation"
    :class="uiConfirmationModifiers"
    :title="title"
    :quicksell="quicksell"
    :confirm="confirm"
    :cancel="cancel"
    @close="close"
  >
    <template #header-controls="{ cancel }">
      <UIIcon :name="SystemIconsNames.close" clickable @click="cancel" />
    </template>

    <template #footer="{ cancel, confirm }">
      <div class="ui-desktop-confirmation__footer">
        <UIButtonV3
          class="ui-desktop-confirmation__footer-button"
          variant="outlined"
          @click="cancel"
        >
          {{ cancelLabel }}
        </UIButtonV3>
        <UIButtonV3 class="ui-desktop-confirmation__footer-button" @click="confirm">
          {{ confirmLabel }}
        </UIButtonV3>
      </div>
    </template>
  </UIConfirmation>
</template>

<script setup lang="ts">
import { MaybePromise } from '@/modules/common/types/maybePromise';
import UIConfirmation from '@/modules/dialogs/components/UIConfirmation/UIConfirmation.vue';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import UIButtonV3 from '@/ui-components/UIButtonV3/UIButtonV3.vue';
import UIIcon from '@/ui-components/UIIcon/UIIcon.vue';
import { shallowRef, computed } from 'vue';
import { UIConfirmationSizeType } from '@/modules/dialogs/components/UIConfirmation/types/UIConfirmationSizeType';

interface Props {
  title: string;
  quicksell?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  size?: UIConfirmationSizeType;
  confirm?: () => MaybePromise<void>;
  cancel?: () => MaybePromise<void>;
}

interface Emits {
  (e: 'close'): void;
}

const props = withDefaults(defineProps<Props>(), {
  confirmLabel: 'Да',
  cancelLabel: 'Нет',
  size: 'normal',
});

const emits = defineEmits<Emits>();

const uiConfirmationModifiers = computed(() => ({
  'ui-desktop-confirmation--size-normal': props.size == 'normal',
  'ui-desktop-confirmation--size-wide': props.size == 'wide',
}));

function close() {
  emits('close');
}
</script>

<style lang="scss">
.ui-desktop-confirmation {
  &.ui-desktop-confirmation--size-normal {
    --ui-dialog-size: 552px;
  }

  &.ui-desktop-confirmation--size-wide {
    --ui-dialog-size: 720px;
  }
}

.ui-desktop-confirmation__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.ui-desktop-confirmation__footer-button {
  min-width: 80px;
}
</style>
