import uniq from 'lodash/uniq';
import { injectable } from 'inversify';
import { ICompanyCertificatesService } from '@/modules/dossier/company/common/services/statistics/companySertificatesService.interface';
import { CompanyCertificates } from '@/types/companyData/CompanyCertificates';
import { CompanyCertificateTypes } from '@/types/companyData/companyCertificatesTypes';

@injectable()
export default class companyCertificatesService implements ICompanyCertificatesService {
  calculateCountUniqCertificatesByType(
    type: CompanyCertificateTypes,
    certificates: Array<CompanyCertificates>,
  ): number {
    const filteredUniqCertificates = uniq(
      certificates
        .filter((certificate) => certificate.type == type)
        .map((certificate) => certificate.number),
    );
    return filteredUniqCertificates.length;
  }

  calculateCountUniqActualCertificatesByType(
    type: CompanyCertificateTypes,
    certificates: Array<CompanyCertificates>,
  ) {
    const actualUniqCertificates = uniq(
      certificates
        .filter((certificate) => certificate.type == type && certificate.status == 'Актуальный')
        .map((certificate) => certificate.number),
    );
    return actualUniqCertificates.length;
  }
}
