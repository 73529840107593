import { injectable } from 'inversify';
import { NumbersUtils } from '@/utils/numbersUtils';
import { EnforcementProceedings } from '@/modules/dossier/company/enforcementProceedings/types/enforcementProceedings';
import { EnforcementProceedingsStatisticsTableRow } from '@/modules/dossier/company/enforcementProceedings/types/enforcementProceedingsStatisticsTableRow';
import { EpsBySubjects } from '@/modules/dossier/company/enforcementProceedings/types/epsBySubjects';
import { EnforcementProceedingsDataTableRow } from '@/modules/dossier/company/enforcementProceedings/types/enforcementProceedingsDataTableRow';
import { ICompanyEnforcementProceedingsMappingService } from './companyEnforcementProceedingsMappingService.interface';
import { DossierTagData } from '@/modules/common/types/dossierTag';

function determineEPTagTheme(status: string): DossierTagData {
  if (status == null || status == '') {
    return { text: 'Неизвестно', theme: 'common' };
  }
  if (status.toLowerCase() == 'погашено') {
    return { text: status, theme: 'success' };
  }
  return { text: status, theme: 'danger' };
}

@injectable()
export default class CompanyEnforcementProceedingsMappingService
  implements ICompanyEnforcementProceedingsMappingService
{
  mapEnforcementProceedingsStatisticsTable(
    epList: Array<EnforcementProceedings>,
  ): Array<EnforcementProceedingsStatisticsTableRow> {
    const epsBySubjects: EpsBySubjects = epList.reduce((acc, ep) => {
      const date = new Date(ep.date).getFullYear().toString();
      if (ep.subject_of_exec in acc) {
        if (date in acc[ep.subject_of_exec]) {
          acc[ep.subject_of_exec][date] += 1;
        } else {
          acc[ep.subject_of_exec] = { [date]: 1 };
        }
      } else {
        acc[ep.subject_of_exec] = { [date]: 1 };
      }
      return acc;
    }, {} as EpsBySubjects);

    return Object.entries(epsBySubjects)
      .map((ep) =>
        Object.entries(ep[1]).map((year) => ({
          subject: ep[0],
          year: year[0],
          amountOfContracts: year[1].toString(),
        })),
      )
      .flat();
  }

  mapEnforcementProceedingsDataTable(
    epList: Array<EnforcementProceedings>,
  ): Array<EnforcementProceedingsDataTableRow> {
    return epList.map((ep) => {
      const tagConfig = determineEPTagTheme(ep.status);
      return {
        enforceProceedings: ep.enforce_proceedings,
        subjectOfExec: ep.subject_of_exec,
        status: {
          theme: tagConfig.theme,
          text: tagConfig.text,
        },
        amountOwed: `${NumbersUtils.separateByThreeDigits(ep.amount_owed)}`,
        enforcementProceedingEndDate: ep.enforcementProceedingEndDate,
        enforceProceedingsEndReason: ep.enforce_proceedings_end_reason,
        executoryDocumentNumber: ep.executoryDocumentNumber,
        bailiffsAddress: ep.bailiffs_office_address,
        date: ep.date,
        terminationEnforcementProceedingTypeStr: ep.terminationEnforcementProceedingTypeStr,
        bailiffNumber: ep.bailiff_number,
        enforcementProceedingEndDateStr: ep.enforcementProceedingEndDateStr,
        execDocAgencyName: ep.exec_doc_agency_name,
        execDocProps: ep.exec_doc_props,
        executoryDocument: ep.executoryDocument,
        executoryDocumentDate: ep.executoryDocumentDate,
        executoryDocumentDateStr: ep.executoryDocumentDateStr,
        officialAggregatedNum: ep.officialAggregatedNum,
        rest: ep.rest,
        bailiff: ep.bailiff,
      };
    });
  }
}
