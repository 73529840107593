import { RouteLocationNormalized } from 'vue-router';
import { useInjectable } from '@/hooks/useInjectable';
import { IAccessScopeServiceKey } from '@/modules/rights/services/AccessScopeService.interfaces';
import { AccessScopeEnum } from '@/modules/rights/type/AccessScopeEnum';
import { useStaticNotification } from '@/modules/notifications/composables/useStaticNotification';
import { useUserRightsStore } from '@/modules/rights/stores/useUserRights';
import { storeToRefs } from 'pinia';
import RouteNames from '@/modules/router/common/types/routeNames';

export function accessGuardMiddleware(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  const userRightsStore = useUserRightsStore();
  const { currentUser } = storeToRefs(userRightsStore);

  const { accessScopes } = to.meta;
  const accessScopeService = useInjectable(IAccessScopeServiceKey);
  const { fireNotification } = useStaticNotification();

  if (!accessScopes) return;
  if (!currentUser.value?.accessScope?.isAuthorized) return { name: RouteNames.AUTH_PAGE };
  if (accessScopeService.checkHasScope(accessScopes as Array<AccessScopeEnum>)) return;

  fireNotification({
    title: 'У вас недостаточно прав',
    status: 'warning',
  });
  return { name: from.path };
}
