import { InjectionKey } from 'vue';
import { MassImportPersonIdentifier } from '@/modules/import/modules/massImport/modules/person/types/massImportPersonIdentifier';
import { ApiMassImportPersonIdentifier } from '@/modules/import/types/api/apiMassImportPersonIdentifier';

export interface IMassImportPersonIdentifierMapperService {
  map(identifier: MassImportPersonIdentifier): ApiMassImportPersonIdentifier;
}

export const IMassImportPersonIdentifierMapperServiceKey: InjectionKey<IMassImportPersonIdentifierMapperService> =
  Symbol('IMassImportPersonIdentifierMapperService');
