import { InjectionKey } from 'vue';
import { PersonSocialNetworkTableItem } from '@/modules/dossier/person/socialNetworks/types/personSocialNetworkTableItem';
import { ApiPersonDetailSocialNetworksRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailSocialNetworksRecord';

export interface IPersonSocialNetworksMappingService {
  mapPersonSocialNetworksDataTable(
    socialNetworks: Array<ApiPersonDetailSocialNetworksRecord>,
  ): PersonSocialNetworkTableItem[];
}

export const IPersonSocialNetworksMappingServiceKey: InjectionKey<IPersonSocialNetworksMappingService> =
  Symbol('IPersonSocialNetworksMappingServiceKey');
