import { InjectionKey } from 'vue';
import { MapCoordinates } from '@/modules/maps/types/MapCoordinates';

export interface IDaDataMapCoordinatesService {
  getCoordinatesByCityName(city: string): Promise<MapCoordinates | undefined>;
  getCountryByCityName(city: string): Promise<string | undefined>;
}

export const IDaDataMapCoordinatesServiceKey: InjectionKey<IDaDataMapCoordinatesService> = Symbol(
  'IDaDataMapCoordinatesService',
);
