import { InjectionKey } from 'vue';
import { NegativeCriminal } from '@/modules/dossier/person/negativeCriminals/types/negativeCriminal';
import { NegativeCriminalTableRow } from '@/modules/dossier/person/negativeCriminals/types/negativeCriminalTableRow';

export interface INegativeCriminalService {
  prepareNegativeCriminalTable(
    negativeCriminal: Array<NegativeCriminal>,
  ): Array<NegativeCriminalTableRow>;
}

export const INegativeCriminalServiceKey: InjectionKey<INegativeCriminalService> = Symbol(
  'INegativeCriminalServiceKey',
);
