import {
  ApiGraphCreationQueryPersons
} from '@/modules/graph/table/types/api/apiGraphCreationQueryPersons';
import { GraphCounterPartyChain } from '@/modules/graph/table/types/api/graphCounterPartyChain';
import GraphBuildRequest from '@/modules/graph/unity/types/GraphBuildRequest';
import { InjectionKey } from 'vue';

export interface IAnalysisGraphBuildRequestFactory {
  buildRequestAsync(config: IAnalysisGraphBuildRequestConfig): Promise<GraphBuildRequest>
}

export interface IAnalysisGraphBuildRequestConfig {
  chains: GraphCounterPartyChain[],
  request: ApiGraphCreationQueryPersons,
  type?: number,
  hiddenPersons?: string[]
}

export const IAnalysisGraphBuildRequestFactoryKey: InjectionKey<IAnalysisGraphBuildRequestFactory> = Symbol('IAnalysisGraphBuildRequestFactory');
