export enum AccessScopeEnum {
  isAuthorized = 'isAuthorized',
  hasAccessToDataAndAnalysis = 'hasAccessToDataAndAnalysis',
  hasAccessToSearch = 'hasAccessToSearch',
  hasAccessToDataGrid = 'hasAccessToDataGrid',
  hasAccessToHistory = 'hasAccessToHistory',
  hasAccessToDataCollection = 'hasAccessToDataCollection',
  hasAccessToAdministrationRoot = 'hasAccessToAdministrationRoot',
  hasAccessToUsers = 'hasAccessToUsers',
  hasAccessToPerson = 'hasAccessToPerson',
  hasAccessToCompany = 'hasAccessToCompany',
  hasAccessToImportRootView = 'hasAccessToImportRootView',
  hasAccessToCollection = 'hasAccessToCollection',
  hasAccessToHistoryExternalData = 'hasAccessToHistoryExternalData',
}
