export enum DxCompareOperations {
  Equals = 0,
  NotEquals = 1,
  Less = 2,
  LessOrEquals = 3,
  Greater = 4,
  GreaterOrEquals = 5,
  StartsWith = 6,
  EndsWith = 7,
  Contains = 8,
  NotContains = 9,
}
