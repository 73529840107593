import {
  RegulationsCheckDecision
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckDecision';
import {
  RegulationsCheckRootType
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckRootType';
import { inject, injectable } from 'inversify';
import { IRegulationsChecksHistoryMappingService } from '@/modules/regulationsCheck/common/services/regulationsChecksHistoryMappingService.interface';
import { RegulationsChecksHistoryTableRow } from '@/modules/regulationsCheck/common/types/regulationsChecksHistoryTableRow';
import { RegulationsChecksHistoryItem } from '@/modules/regulationsCheck/common/types/regulationsChecksHistoryItem';
import {
  IRegulationsDecisionsService,
  IRegulationsDecisionsServiceKey,
} from '@/modules/regulationsCheck/common/services/regulationDecisionsService.interface';
import {
  IRegulationsInputTypesService,
  IRegulationsInputTypesServiceKey,
} from '@/modules/regulationsCheck/common/services/regulationInputTypesService.interface';
import {
  IRegulationsRootTypesService,
  IRegulationsRootTypesServiceKey,
} from '@/modules/regulationsCheck/common/services/regulationRootTypesService.interface';
import {
  IRegulationVettingStatusesService,
  IRegulationVettingStatusesServiceKey,
} from '@/modules/regulationsCheck/common/services/regulationVettingStatusesService.interface';

@injectable()
export default class RegulationsChecksHistoryMappingService
  implements IRegulationsChecksHistoryMappingService
{
  constructor(
    @inject(IRegulationsDecisionsServiceKey as symbol)
    private regulationsDecisionsService: IRegulationsDecisionsService,

    @inject(IRegulationsInputTypesServiceKey as symbol)
    private regulationsInputTypesService: IRegulationsInputTypesService,

    @inject(IRegulationsRootTypesServiceKey as symbol)
    private regulationsRootTypesService: IRegulationsRootTypesService,

    @inject(IRegulationVettingStatusesServiceKey as symbol)
    private regulationVettingStatusesService: IRegulationVettingStatusesService,
  ) {}

  getRegulationsChecksHistoryTableData(
    regulationsChecks: Array<RegulationsChecksHistoryItem>,
  ): Array<RegulationsChecksHistoryTableRow> {
    const filteredChecks = regulationsChecks.filter(
      (item) =>
        !(
          item.rootType == RegulationsCheckRootType.Manual &&
          item.decision == RegulationsCheckDecision.Unknown
        ),
    );
    const sortedChecks = filteredChecks.sort(
      (a, b) => Number(new Date(a.created).getTime()) - Number(new Date(b.created).getTime()),
    );
    const tableData: Array<RegulationsChecksHistoryTableRow> = [];
    let currentTaskIndex = 0;
    if (regulationsChecks.length == 0) {
      return [];
    }
    for (let i = 0; i < sortedChecks.length; i += 1) {
      if (sortedChecks[i].rootType && sortedChecks[i].rootType == RegulationsCheckRootType.Manual) {
        const subChecks = sortedChecks.slice(currentTaskIndex + 1, i);
        let j = i + 1;
        while (
          j < sortedChecks.length &&
          sortedChecks[j].rootType &&
          sortedChecks[j].rootType == RegulationsCheckRootType.Auto
        ) {
          if (
            sortedChecks[i].generationEnd != undefined &&
            sortedChecks[i].generationEnd! < sortedChecks[j].created
          ) {
            break;
          }
          subChecks.push(sortedChecks[j]);
          j += 1;
          currentTaskIndex = j;
        }
        tableData.push({
          subChecks,
          inn: sortedChecks[i].inn,
          created: sortedChecks[i].created,
          comment: sortedChecks[i].comment,
          companyName: sortedChecks[i].companyName,
          fio: sortedChecks[i].fio,
          generationEnd: sortedChecks[i].generationEnd,
          ogrn: sortedChecks[i].ogrn,
          taskId: sortedChecks[i].taskId,
          rootType: sortedChecks[i].rootType,
          rootTypeStr: this.regulationsRootTypesService.getRegulationRootTypes(
            sortedChecks[i].rootType,
          ),
          userFullName: sortedChecks[i].userFullName,
          vettingStatus: this.regulationVettingStatusesService.getRegulationVettingStatuses(
            sortedChecks[i].vettingStatus,
          ),
          decision: this.regulationsDecisionsService.getRegulationDecisions(
            sortedChecks[i].decision,
          ),
          inputType: ''
        });
      }
    }
    if (
      sortedChecks[sortedChecks.length - 1].rootType &&
      sortedChecks[sortedChecks.length - 1].rootType == RegulationsCheckRootType.Auto
    ) {
      for (let i = currentTaskIndex; i < sortedChecks.length; i += 1) {
        tableData.push({
          subChecks: [],
          inn: sortedChecks[i].inn,
          created: sortedChecks[i].created,
          comment: sortedChecks[i].comment,
          companyName: sortedChecks[i].companyName,
          taskId: sortedChecks[i].taskId,
          fio: sortedChecks[i].fio,
          generationEnd: sortedChecks[i].generationEnd,
          ogrn: sortedChecks[i].ogrn,
          rootType: sortedChecks[i].rootType,
          rootTypeStr: this.regulationsRootTypesService.getRegulationRootTypes(
            sortedChecks[i].rootType,
          ),
          userFullName: sortedChecks[i].userFullName,
          vettingStatus: this.regulationVettingStatusesService.getRegulationVettingStatuses(
            sortedChecks[i].vettingStatus,
          ),
          decision: this.regulationsDecisionsService.getRegulationDecisions(
            sortedChecks[i].decision,
          ),
          inputType: ''
        });
      }
    }
    return tableData;
  }
}
