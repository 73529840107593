import { injectable } from 'inversify';
import { ApiPersonDetailVehicleRestrictsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVehicleRestrictsRecord';
import { IPersonRestrictionsStatisticsService } from '@/modules/dossier/person/restrictions/services/personRestrictionsStatisticsService.interface';

@injectable()
export default class PersonRestrictionsStatisticsService
  implements IPersonRestrictionsStatisticsService
{
  calculateStatisticByYearItems(
    restrictions: Array<ApiPersonDetailVehicleRestrictsRecord>,
    yearsCount: number,
  ): Array<Record<string, number>> {
    const currentYear = new Date().getFullYear();
    const lastYears: Array<number> = [];

    for (let i = yearsCount - 1; i >= 0; i -= 1) {
      lastYears.push(currentYear - i);
    }

    const lastYearsMapInitValue: [number, number][] = [];

    for (let i = 0; i < lastYears.length; i += 1) {
      lastYearsMapInitValue.push([lastYears[i], 0]);
    }

    const personRestrictsStatisticItems: Map<number, number> = new Map<number, number>(
      lastYearsMapInitValue,
    );

    restrictions.forEach((restriction) => {
      const restrictionDate = restriction.dateStr.split('.');
      const year = Number(restrictionDate[restrictionDate.length - 1]);
      if (lastYears.includes(year)) {
        const yearCount = personRestrictsStatisticItems.get(year);
        if (yearCount != undefined) {
          personRestrictsStatisticItems.set(year, yearCount + 1);
        } else {
          personRestrictsStatisticItems.set(year, 0);
        }
      }
    });
    return [...personRestrictsStatisticItems.entries()].map(([year, count]) => ({
      year,
      count,
    }));
  }
}
