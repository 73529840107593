import { InjectionKey } from 'vue';
import { ApiPersonDetailVkRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecord';
import { PersonSocialNetworkAdditionalInfoVKRecordKeys } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKRecordKeys';
import { PersonSocialNetworkAdditionalInfoAccordeonSections } from '@/modules/dossier/person/socialNetworkAdditionalInfo/common/types/personSocialNetworkAdditionalInfoAccordeonSections';
import { PersonSocialNetworkAdditionalInfoVKDataSection } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKDataSection';

export interface IPersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter {
  getPersonSocialNetworkVKMainInfoTitledFormattedData(
    detailVkRecord: ApiPersonDetailVkRecord,
    sectionDetailsName: PersonSocialNetworkAdditionalInfoAccordeonSections,
    sectionDetailsRequiredKeys: Array<PersonSocialNetworkAdditionalInfoVKRecordKeys>,
  ): PersonSocialNetworkAdditionalInfoVKDataSection;
}

export const IPersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatterKey: InjectionKey<IPersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatter> =
  Symbol('IPersonSocialNetworkAdditionalInfoVKMainInfoTitledFormatterKey');
