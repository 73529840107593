import { injectable } from 'inversify';
import { RouteTab } from '@/modules/tabs/routeTabs/types/routeTab';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { IRouteMatcherNested } from '@/modules/tabs/routeTabs/routeMatchers/routeMatcherNested.interfaces';

@injectable()
export default class RouteMatcherNested implements IRouteMatcherNested {
  public match(tab: RouteTab, route: RouteLocationNormalizedLoaded) {
    return route.matched.some((r) => tab.routeName == r.name);
  }
}
