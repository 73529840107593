import { injectable } from 'inversify';
import { IRegulationVettingStatusesService } from '@/modules/regulationsCheck/common/services/regulationVettingStatusesService.interface';
import { RegulationCheckStatus } from '@/modules/regulationsCheck/common/types/regulationCheckStatus';
import { DossierTagData } from '@/modules/common/types/dossierTag';

@injectable()
export default class RegulationVettingStatusesService implements IRegulationVettingStatusesService {
  getRegulationVettingStatuses(status: RegulationCheckStatus): DossierTagData {
    switch (status) {
      case RegulationCheckStatus.Canceled:
        return { text: 'Отменено', theme: 'danger' };
      case RegulationCheckStatus.Error:
        return { text: 'Ошибка', theme: 'danger' };
      case RegulationCheckStatus.Complete:
        return { text: 'Завершено', theme: 'success' };
      case RegulationCheckStatus.Progress:
        return { text: 'В процессе', theme: 'common-alt' };
      default:
        return { text: 'Неизвестно', theme: 'common-alt' };
    }
  }
}
