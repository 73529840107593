import { injectable } from 'inversify';
import { IUserRoleFormatterService } from '@/modules/administration/users/services/formatters/userRoleFormatterService.interfaces';
import { UserRole } from '@/modules/rights/type/userRoleEnum';

@injectable()
export default class UserRoleFormatterService implements IUserRoleFormatterService {
  format(value: UserRole): string {
    switch (value) {
      case UserRole.user:
        return 'Пользователь';
      case UserRole.admin:
        return 'Администратор';
      case UserRole.personCreator:
        return 'Создатель досье';
      default:
        return '';
    }
  }
}
