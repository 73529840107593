import { InjectionKey } from 'vue';
import { ReportsHistoryListItem } from '@/modules/reports/mobile/history/general/types/ReportHistoryListItem';
import { ReportHistoryListFilters } from '@/modules/reports/mobile/history/filters/types/ReportHistoryListFilters';

export interface IMobileReportsHistoryFilterService {
  search(
    listFilters: ReportHistoryListFilters,
    items: ReportsHistoryListItem[],
  ): ReportsHistoryListItem[];
}

export const IMobileReportsHistoryFilterServiceKey: InjectionKey<IMobileReportsHistoryFilterService> =
  Symbol('IMobileReportsHistoryFilterServiceKey');
