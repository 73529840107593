import { injectable } from 'inversify';
import { UITagTheme } from '@/modules/tags/types/tagTheme';
import { IPersonParticipationIpTagThemeService } from '@/modules/dossier/person/participation/services/tags/personParticipationIpTagThemeService.interface';

@injectable()
export default class PersonParticipationIpTagThemeService
  implements IPersonParticipationIpTagThemeService
{
  getStatusTheme(status: string): UITagTheme {
    return status == 'Действующее' ? 'success' : 'danger';
  }
}
