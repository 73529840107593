export enum RequisiteType {
  unknown = 0,
  fullName = 1,
  phone = 2,
  carNumber = 3,
  innFl = 4,
  innUl = 5,
  innIp = 6,
  photo = 7,
  date = 8,
}
