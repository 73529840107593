import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import CompanySearchConceptOptionGroupsFactory
  from '@/modules/searchConcept/company/factories/companySearchConceptOptionGroupsFactory';
import {
  ICompanySearchConceptOptionGroupsFactoryKey
} from '@/modules/searchConcept/company/factories/companySearchConceptOptionGroupsFactory.interfaces';
import CompanySearchConceptOptionsFactory
  from '@/modules/searchConcept/company/factories/companySearchConceptOptionsFactory';
import {
  ICompanySearchConceptOptionsFactoryKey
} from '@/modules/searchConcept/company/factories/companySearchConceptOptionsFactory.interfaces';
import {
  ElasticHitToGridCompanyMapper
} from '@/modules/searchConcept/company/services/elasticHitToGridCompanyMapper';
import {
  IElasticHitToGridCompanyMapperKey
} from '@/modules/searchConcept/company/services/elasticHitToGridCompanyMapper.interfaces';

export function bindCompanySearchConceptService(bind: IContainerBinder) {
  bind(ICompanySearchConceptOptionsFactoryKey).to(CompanySearchConceptOptionsFactory);
  bind(ICompanySearchConceptOptionGroupsFactoryKey).to(CompanySearchConceptOptionGroupsFactory);
  bind(IElasticHitToGridCompanyMapperKey).to(ElasticHitToGridCompanyMapper);
}
