import { injectable } from 'inversify';
import { IReportsConfigurationService } from '@/modules/reports/desktop/services/reportsConfigurationService.interfaces';
import {
  BIRTHDAY_PATTERN,
  coefficientINN,
  firstCoefficientINNUl,
  secondCoefficientINNUl,
  LEGENDS,
} from '@/modules/reports/common/configuration/ReportsConfiguration';
import { ReportTypeInput } from '@/modules/reports/common/types/ReportTypeInput';
import { ReportType } from '@/modules/reports/common/types/ReportType';
import { ReportConfigurationReportType } from '@/modules/reports/desktop/types/ReportConfigurationReportType';
import { RequisiteType } from '@/modules/requisites/types/requisiteType';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { ReportRequisiteLegend } from '@/modules/reports/desktop/types/ReportRequisiteLegend';
import { ReportConfigurationPayloadApi } from '../types/ReportConfigurationPayloadApi';

@injectable()
export default class ReportsConfigurationService implements IReportsConfigurationService {
  checkValidInn(array: string[]) {
    const controlNumber = array.reduce((acc, item, index) => {
      if (index === 9) {
        return acc;
      }

      return (acc += +item * coefficientINN[index]);
    }, 0);

    let coefficient = controlNumber % 11;

    if (coefficient >= 9) {
      coefficient %= 10;
    }

    return +array[9] === coefficient;
  }

  checkValidUlInn(array: string[]) {
    const controlNumberFirst = array.reduce((acc, item, index) => {
      if (index >= 10) {
        return acc;
      }

      return (acc += +item * firstCoefficientINNUl[index]);
    }, 0);

    const controlNumbeSecond = array.reduce((acc, item, index) => {
      if (index >= 11) {
        return acc;
      }

      return (acc += +item * secondCoefficientINNUl[index]);
    }, 0);

    let firstCoefficient = controlNumberFirst % 11;
    if (firstCoefficient >= 9) {
      firstCoefficient %= 10;
    }

    let secondCoefficient = controlNumbeSecond % 11;
    if (secondCoefficient >= 9) {
      secondCoefficient %= 10;
    }

    return +array[10] === firstCoefficient && secondCoefficient === +array[11];
  }

  validFullName(payload: string) {
    return payload
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  formatBirthday(birthday: string) {
    if (birthday.trim().length == 0) {
      return birthday;
    }

    const match = birthday.match(BIRTHDAY_PATTERN);

    if (match == undefined) {
      return birthday;
    }

    const [_, day, month, year] = match;

    return `${year}-${month}-${day}`;
  }

  validPayload(
    inputValue: string,
    inputBirthdayValue: string,
    currentLegend: string,
    isExtended: boolean,
  ) {
    const payload: ReportConfigurationPayloadApi = {
      fio: '',
      birthday: '',
      inn: '',
      phoneNumber: '',
      carNumber: '',
      inputType: 0,
      reportType: isExtended ? ReportType.extended : ReportType.standard,
      reportResultType: ReportConfigurationReportType.Pdf,
    };

    switch (currentLegend) {
      case LEGENDS.fullNameType:
        payload.fio = inputValue;
        payload.birthday = this.formatBirthday(inputBirthdayValue);
        payload.inputType = ReportTypeInput.fullName;
        break;
      case LEGENDS.phoneType:
        payload.phoneNumber = `7${inputValue.slice(2).trim().replace(/\s/g, '')}`;
        payload.inputType = ReportTypeInput.phone;
        break;
      case LEGENDS.carType:
        payload.carNumber = inputValue;
        payload.inputType = ReportTypeInput.carNumber;
        break;
      case LEGENDS.innType:
      case LEGENDS.innTypeUl:
      case LEGENDS.innTypePerson:
        payload.inn = inputValue.replace(/-/g, '');
        payload.inputType = ReportTypeInput.inn;
        break;
      default:
        break;
    }

    return payload;
  }

  getReportLegend(type: RequisiteType): ReportRequisiteLegend {
    switch (type) {
      case RequisiteType.innFl:
        return {
          icon: SystemIconsNames.reportsRequisitesInn,
          title: LEGENDS.innType,
        };
      case RequisiteType.innIp:
        return {
          icon: SystemIconsNames.reportsRequisitesInn,
          title: LEGENDS.innTypeIp,
        };
      case RequisiteType.innUl:
        return {
          icon: SystemIconsNames.reportsRequisitesInn,
          title: LEGENDS.innTypeUl,
        };

      case RequisiteType.carNumber:
        return {
          icon: SystemIconsNames.reportsRequisitesCar,
          title: LEGENDS.carType,
        };
      case RequisiteType.phone:
        return {
          icon: SystemIconsNames.reportsRequisitesPhone,
          title: LEGENDS.phoneType,
        };
      case RequisiteType.fullName:
      default:
        return {
          icon: SystemIconsNames.reportsRequisitesFullName,
          title: LEGENDS.fullNameType,
        };
    }
  }
}
