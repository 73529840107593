import { InjectionKey } from 'vue';
import { RiskDetailRateParameter } from '@/modules/regulationsCheck/common/types/riskDetailRateParameter';
import { RateUpdateParametersMode } from '@/modules/regulationsCheck/common/types/rateUpdateParametersMode';

export interface IRegulationsChecksParametersModesMapperService {
  map(item: RiskDetailRateParameter): RateUpdateParametersMode | undefined;
}

export const IRegulationsChecksParametersModesMapperServiceKey: InjectionKey<IRegulationsChecksParametersModesMapperService> =
  Symbol('IRegulationsChecksParametersModesMapperService');
