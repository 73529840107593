import { injectable } from 'inversify';
import { DriverLicenseFormattedTag } from '@/modules/dossier/person/vehicles/driverLicenses/types/driverLicenseFormattedTag';
import { IDriverLicenseStatusDeprivationMapper } from '@/modules/dossier/person/vehicles/driverLicenses/services/mappers/personDriverLicenseStatusDeprivationMapper.interfaces';

@injectable()
export default class PersonDriverLicenseStatusDeprivationMapper
  implements IDriverLicenseStatusDeprivationMapper
{
  format(value: boolean): DriverLicenseFormattedTag {
    switch (value) {
      case true:
        return {
          type: 'danger',
          label: 'Лишён права',
        };
      case false:
        return {
          type: 'primary',
          label: 'Не лишён',
        };
      default:
        return {
          type: 'secondary',
          label: 'Неизвестно',
        };
    }
  }
}
