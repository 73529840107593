import { injectable } from 'inversify';
import { ApiPersonDetailNegativeWantedsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailNegativeWantedsRecord';
import { ApiPersonDetailNegativeCriminalsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailNegativeCriminalsRecord';
import { ApiPersonDetailNegativeDebtsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailNegativeDebtsRecord';
import { ApiPersonDetailNegativeInfoRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailNegativeInfoRecord';
import { NegativeInfoTypes } from '@/modules/dossier/person/common/types/api/negativeInfoType';
import { IPersonNegativeOverviewStatisticsService } from './personNegativeOverviewStatisticsService.interface';

@injectable()
export default class PersonNegativeOverviewStatisticsService
  implements IPersonNegativeOverviewStatisticsService
{
  calculateWantedCasesAmount(wantedsList: Array<ApiPersonDetailNegativeWantedsRecord>): number {
    return wantedsList.length;
  }

  calculateNegativeMentions(
    negativeMentionsList: Array<ApiPersonDetailNegativeInfoRecord>,
  ): number {
    return negativeMentionsList.length;
  }

  calculateDebtsAmout(debtsList: Array<ApiPersonDetailNegativeDebtsRecord>): number {
    return debtsList.length;
  }

  calculateBlackListCasesAmount(
    negativeMentionsList: Array<ApiPersonDetailNegativeInfoRecord>,
  ): number {
    return negativeMentionsList.filter(
      (entry) => entry.negativeInfoType == NegativeInfoTypes.BlackList,
    ).length;
  }

  calculateSpecialTreatmentCases(
    negativeMentionsList: Array<ApiPersonDetailNegativeInfoRecord>,
  ): number {
    return negativeMentionsList.filter(
      (entry) => entry.negativeInfoType == NegativeInfoTypes.SpecialAccounting,
    ).length;
  }

  calculateCriminalCharges(criminalsList: Array<ApiPersonDetailNegativeCriminalsRecord>): number {
    return criminalsList.length;
  }

  calculateAdministrativeCharges(
    criminalsList: Array<ApiPersonDetailNegativeCriminalsRecord>,
    negativeMentionsList: Array<ApiPersonDetailNegativeInfoRecord>,
  ): number {
    return negativeMentionsList.length - criminalsList.length;
  }
}
