import { MutationTree, Module, Store as VuexStore, CommitOptions } from 'vuex';
import { RootState } from '../../type';
import type { ToastState, Mutations } from './type';
import { MutationTypesEnum } from './type';

const moduleState: ToastState = {
  namespaced: true,
  toasts: [],
};

const mutations: MutationTree<ToastState> & Mutations = {
  [MutationTypesEnum.ADD_TOAST](state, payload) {
    state.toasts = [...state.toasts, payload];
  },
  [MutationTypesEnum.POP_TOAST](state, payload) {
    state.toasts = state.toasts.filter(({ id }) => id !== payload.id);
  },
};

export type ToastStore<S = ToastState> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
};

export const ToastModule: Module<ToastState, RootState> = {
  state: moduleState,
  mutations,
};
