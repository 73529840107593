import { InjectionKey } from 'vue';
import { ChartJSBarChartItem } from '@/modules/charts/modules/chartjs/components/UIBarChart/types/ChartJSBarChartItem';

export interface IChartJSBarDatasetsService {
  createDatasets(items: ChartJSBarChartItem[]): any[];
}

export const IChartJSBarDatasetsServiceKey: InjectionKey<IChartJSBarDatasetsService> = Symbol(
  'IChartJSBarDatasetsServiceKey',
);
