import { getContainer } from '@/configuration/inversify/container';
import containerAccessor from '@/configuration/inversify/containerAccessor';
import { useInjectable } from '@/hooks/useInjectable';
import { IRouterFactoryKey } from '@/modules/router/common/factories/routerFactory.interfaces';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import '@/styles/dx.generic.custom-scheme.css';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import InlineSvg from 'vue-inline-svg';
import VueTheMask from 'vue-the-mask';
import { useTable } from '@/modules/graph/unity/configuration/vxeTable';
import App from './App.vue';
import store from './store/index';
import '@/styles/_root.scss';

containerAccessor.updateContainer(getContainer());
const routerFactory = useInjectable(IRouterFactoryKey);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const router = routerFactory.getRouter();

createApp(App)
  .use(VueTheMask as any)
  .use(router)
  .use(store)
  .use(pinia)
  .use(VueSvgInlinePlugin)
  .component('inline-svg', InlineSvg)
  .use(FloatingVue)
  .use(useTable)
  .mount('#app');
