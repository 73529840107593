import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { usePersonListChangedEvent } from '@/modules/lists/composable/usePersonListChangedEvent/usePersonListChangedEvent';
import { usePersonListsChangedEvent } from '@/modules/lists/composable/usePersonListsChangedEvent/usePersonListsChangedEvent';
import { IPersonListsService } from '@/modules/lists/services/personListsService.interfaces';
import { ApiNewPersonListData } from '@/modules/lists/types/api/fl/apiNewPersonListData';
import { ApiPersonListAppendRequest } from '@/modules/lists/types/api/fl/apiPersonListAppendRequest';
import { ApiPersonListCreationRequest } from '@/modules/lists/types/api/fl/apiPersonListCreationRequest';
import { ApiPersonListUpdateRequest } from '@/modules/lists/types/api/fl/apiPersonListUpdateRequest';
import { ApiPersonListData } from '@/modules/lists/types/api/fl/apiPersonListData';
import { ApiPersonListDetails } from '@/modules/lists/types/api/fl/apiPersonListDetails';
import { ApiPersonListRemoveItemsRequest } from '@/modules/lists/types/api/fl/apiPersonListRemoveItemsRequest';
import { ApiPersonListRemoveRequest } from '@/modules/lists/types/api/fl/apiPersonListRemoveRequest';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

const { trigger: triggerPersonListsChangedEvent } = usePersonListsChangedEvent();
const { trigger: triggerPersonListChangedEvent } = usePersonListChangedEvent();

@injectable()
export class PersonListsService implements IPersonListsService {
  constructor(@inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance) {}

  getLists(): Promise<AxiosResponse<ApiResponse<ApiPersonListData>>> {
    return this.apiEndpoint.get(`/person-lists`);
  }

  async createList(
    payload: ApiPersonListCreationRequest,
  ): Promise<AxiosResponse<ApiResponse<ApiNewPersonListData>>> {
    const response = await this.apiEndpoint.post<ApiResponse<ApiNewPersonListData>>(
      `/person-lists/create`,
      payload,
    );
    triggerPersonListsChangedEvent();
    return response;
  }

  async updateList(
    payload: ApiPersonListUpdateRequest,
  ): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post(`/person-lists/update`, payload);
    triggerPersonListsChangedEvent();
    return response;
  }

  async addToList(
    payload: ApiPersonListAppendRequest,
  ): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post(`/person-lists/add`, payload);

    triggerPersonListsChangedEvent();
    payload.personListIds.forEach((x) => triggerPersonListChangedEvent(x));

    return response;
  }

  async deleteList(
    payload: ApiPersonListRemoveRequest,
  ): Promise<AxiosResponse<ApiResponse<string>>> {
    const result = await this.apiEndpoint.post(`/person-lists/remove`, payload);
    triggerPersonListsChangedEvent();
    return result;
  }

  getListDetails(id: string): Promise<AxiosResponse<ApiResponse<ApiPersonListDetails>>> {
    return this.apiEndpoint.get(`/person-lists/${id}`);
  }

  async deleteEntities(
    payload: ApiPersonListRemoveItemsRequest,
  ): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post(
      `/person-lists/${payload.personListId}/items/remove`,
      payload,
    );

    triggerPersonListsChangedEvent();
    triggerPersonListChangedEvent(payload.personListId);

    return response;
  }
}
