import {
  IAnalysisRelationTypesClusterizationService
} from '@/modules/analysis/services/analysisRelationTypesClusterizationService.interfaces';
import { AnalysisRelationClusterType } from '@/modules/analysis/types/analysisRelationClusterType';
import { AnalysisRelationType } from '@/modules/analysis/types/analysisRelationType';
import { injectable } from 'inversify';

@injectable()
export default class AnalysisRelationTypesClusterizationService implements IAnalysisRelationTypesClusterizationService {
  getClusterType(relationType: AnalysisRelationType): AnalysisRelationClusterType {
    switch (relationType) {
      case AnalysisRelationType.ZupCompany:
      case AnalysisRelationType.ZupDepartment:
      case AnalysisRelationType.JobsWithPeriod:
      case AnalysisRelationType.Jobs:
      case AnalysisRelationType.IndividualEntrepreneur: return AnalysisRelationClusterType.jobs;

      case AnalysisRelationType.CompanyFounder:
      case AnalysisRelationType.CompanyHead:
      case AnalysisRelationType.CompanyFounderHistory:
      case AnalysisRelationType.CompanyHeadHistory:
      case AnalysisRelationType.RelatedEmployees: return AnalysisRelationClusterType.affiliation;

      case AnalysisRelationType.ZupRelative:
      case AnalysisRelationType.RelativeInn:
      case AnalysisRelationType.RelativeBirthday: return AnalysisRelationClusterType.relatedPersons;

      case AnalysisRelationType.Vehicles: return AnalysisRelationClusterType.movableProperty;

      case AnalysisRelationType.SocialFriends:
      case AnalysisRelationType.ReciprocalSocialFriends: return AnalysisRelationClusterType.socialNetworks;

      case AnalysisRelationType.ZupBirthplace: return AnalysisRelationClusterType.birthplace;

      case AnalysisRelationType.Address:
      case AnalysisRelationType.AddressToHouse:
      case AnalysisRelationType.ZupRegistryAddress:
      case AnalysisRelationType.ZupActualAddress:
      case AnalysisRelationType.PhoneNumber:
      case AnalysisRelationType.Email:
      case AnalysisRelationType.RelatedPhoneNumber: return AnalysisRelationClusterType.contacts;

      case AnalysisRelationType.VehicleParkings:
      case AnalysisRelationType.ParksNotOwner:
      case AnalysisRelationType.ParksDifferentsDriver:
      case AnalysisRelationType.CovidMovements:
      case AnalysisRelationType.Avia: return AnalysisRelationClusterType.movements;

      case AnalysisRelationType.ZupEducation:
      case AnalysisRelationType.EducationWithPeriod: return AnalysisRelationClusterType.education;

      default: return AnalysisRelationClusterType.unknown;
    }
  }
}
