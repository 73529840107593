import { injectable } from 'inversify';
import { ParticipationUlChecksTableRow } from '@/modules/dossier/person/participation/types/participationUlChecksTableRow';
import { ApiPersonDetailDisqualifiedPersonCheckDataRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailDisqualifiedPersonCheckDataRecord';
import { IPersonParticipationUlChecksMappingService } from './personParticipationUlChecksMappingService.interface';

@injectable()
export default class personParticipationUlChecksMappingService
  implements IPersonParticipationUlChecksMappingService
{
  mapParticipationUlChecksTable(
    participationUlChecks: Array<ApiPersonDetailDisqualifiedPersonCheckDataRecord>,
  ): Array<ParticipationUlChecksTableRow> {
    return participationUlChecks.flatMap((participationUlCheck) =>
      participationUlCheck.disqualifiedPersonDecisions.map((personDecision) => ({
        participationUlCheckStatus: participationUlCheck.found,
        participationUlCheckRecordNumber: personDecision.rdl,
        participationUlCheckBirthDate: personDecision.birthDate,
        participationUlChecksCompany: personDecision.company,
        participationUlCheckCompanyInn: personDecision.companyInn,
        participationUlCheckPost: personDecision.post,
        participationUlCheckArticle: personDecision.article,
        participationUlCheckAgency: personDecision.agency,
        participationUlCheckFullJudgeFullName: personDecision.judgeFullName,
        participationUlCheckJudgePost: personDecision.judgePost,
        participationUlCheckdisDualificationStart: personDecision.disqualificationStart,
        participationUlCheckdisDualificationEnd: personDecision.disqualificationEnd,
      })),
    );
  }
}
