import { InjectionKey } from 'vue';
import { ParentDomainTypes } from '@/modules/common/types/api/parentDomainType';
import { AxiosResponse } from 'axios';

export interface IImportFilePhotosService {
  importFile: (
    file: Blob,
    parentEntityType: ParentDomainTypes,
  ) => Promise<AxiosResponse<undefined>> | undefined;
}

export const IImportFilePhotosServiceKey: InjectionKey<IImportFilePhotosService> = Symbol(
  'IImportFilePhotosService',
);
