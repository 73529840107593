import { InjectionKey } from 'vue';
import { MassImportEntityIdentifier } from '@/modules/import/modules/massImport/types/massImportEntityIdentifier';
import { ValidationService } from '@/modules/validation/types/validationService';

export interface IMassImportIdentifiersParsingService {
  parse<T>(
    identifier: string,
    validationService: ValidationService,
    type: T,
  ): MassImportEntityIdentifier<T> | undefined;
}

export const IMassImportIdentifiersParsingServiceKey: InjectionKey<IMassImportIdentifiersParsingService> =
  Symbol('IMassImportIdentifiersParsingService');
