import { injectable } from 'inversify';
import { AnalyticsScore } from '@/modules/dossier/person/financialScorings/types/financialAnalyticsScore';
import { IAnalyticsScoreTypeParserService } from './analyticsScoreTypeParserService.interface';

@injectable()
export default class AnalyticsScoreTypeParserService implements IAnalyticsScoreTypeParserService {
  parseAnalyticScore(score: number): AnalyticsScore {
    switch (true) {
      case score >= 650:
        return AnalyticsScore.HighScore;
      case score >= 600:
        return AnalyticsScore.MediumScore;
      case score >= 550:
        return AnalyticsScore.PoorScore;
      case score < 550 && score > 0:
        return AnalyticsScore.WorstScore;
      case score == 0:
        return AnalyticsScore.NotFound;
      case score < 0:
        return AnalyticsScore.OverdueScore;
      default:
        return AnalyticsScore.NotFound;
    }
  }
}
