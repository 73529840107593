import { IPersonProfileToColumnInfoMapper } from '@/modules/dataGrid/person/services/mappers/personProfileToColumnInfoMapper/personProfileToColumnInfoMapper.interfaces';
import { DxTableColumnInfo } from '@/modules/devExtreme/types/dxTableColumnInfo';
import { injectable } from 'inversify';
import { ApiDataTableFieldProfile } from '@/modules/profile/types/api/apiDataTableFieldProfile';

@injectable()
export default class PersonProfileToColumnInfoMapper implements IPersonProfileToColumnInfoMapper
{
  map(item: ApiDataTableFieldProfile): DxTableColumnInfo {
    return {
      dataField: this.mapPersonSettingsField(item.fieldCode),
      visible: true,
      visibleIndex: item.index,
    };
  }

  reverseMap(item: DxTableColumnInfo): ApiDataTableFieldProfile {
    return {
      fieldCode: this.mapPersonSettingsField(item.dataField),
      index: item.visibleIndex,
      sortOrder: 0,
      sortIndex: 0,
    };
  }

  private mapPersonSettingsField(field: string) {
    switch (field) {
      case 'individualTypes':
        return 'individualTypesRu';
      case 'person_lists':
        return 'personLists';
      default:
        return field;
    }
  }
}
