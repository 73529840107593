import { injectable, inject } from 'inversify';
import { INumberFormatterService } from '@/modules/common/modules/numbers/services/numberFormatterService/numberFormatterService.interfaces';
import { NumberFormatterOptions } from '@/modules/common/modules/numbers/types/numberFormatterOptions';
import {
  INumberTruncationService,
  INumberTruncationServiceKey,
} from '@/modules/common/modules/numbers/services/numberTruncationService/numberTruncationService.interfaces';

@injectable()
export default class NumberFormatterService implements INumberFormatterService {
  private decimalSearchRegex = /^(-?\d*)((?:[,.]\d*)?|)$/;

  private numberDigitGroupsExtractionRegex = /\B(?=(?:\d{3})*$)/g;

  constructor(
    @inject(INumberTruncationServiceKey as symbol)
    private numberTruncationService: INumberTruncationService,
  ) {}

  format(value: number | undefined, options?: NumberFormatterOptions | undefined): string {
    const { fractionDigits, separateDigitGroups = false } = options ?? {};
    const truncatedValue = this.numberTruncationService.truncateMode(value, options?.truncateMode);

    if (truncatedValue == undefined) {
      return '';
    }

    const formattedValue = this.formatFraction(fractionDigits, truncatedValue);

    return separateDigitGroups ? this.formatDigitGroups(formattedValue) : formattedValue;
  }

  private formatFraction(fractionDigits: number | undefined, truncatedValue: number): string {
    return fractionDigits == undefined
      ? truncatedValue.toString()
      : truncatedValue.toFixed(fractionDigits);
  }

  private formatDigitGroups(formattedValue: string): string {
    const [, integerValue, decimalValue]: string[] =
      formattedValue.match(this.decimalSearchRegex) ?? [];

    const formattedIntegerValue = integerValue.replace(this.numberDigitGroupsExtractionRegex, ' ');
    return `${formattedIntegerValue}${decimalValue}`;
  }
}
