import { InjectionKey } from 'vue';
import { UITagTheme } from '@/modules/tags/types/tagTheme';

export interface IPersonParticipationUITagThemeService {
  getRelevanceTheme(isHistoric: boolean): UITagTheme;
  getRiskIndicatorTheme(riskIndicator: string): UITagTheme;
}

export const IPersonParticipationUITagThemeServiceKey: InjectionKey<IPersonParticipationUITagThemeService> =
  Symbol('IPersonParticipationUITagThemeService');
