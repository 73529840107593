import { injectable } from 'inversify';
import { AnalyticsTypes } from '@/modules/dossier/person/financialScorings/types/financialAnalyticsTypes';
import { IAnalyticsTypeFormatterService } from './analyticsTypeFormatterService.interface';

@injectable()
export default class AnalyticsTypeFormatterService implements IAnalyticsTypeFormatterService {
  getAnalyticsScoreTypeName(type: AnalyticsTypes): string {
    switch (type) {
      case AnalyticsTypes.FICO:
        return `FICO`;
      case AnalyticsTypes.OKB:
        return `ОКБ`;
      default:
        return `Классический`;
    }
  }
}
