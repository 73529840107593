import { DxPdfExternalFont } from '@/modules/devExtreme/export/pdf/types/dxPdfExternalFont';

export module DxPdfExternalFontsConfiguration {
  export const fonts: Array<DxPdfExternalFont> = [
    {
      url: require(`@/fonts/Roboto-Regular.ttf`),
      name: 'Roboto',
      style: 'normal'
    },

    {
      url: require(`@/fonts/Roboto-Bold.ttf`),
      name: 'Roboto',
      style: 'bold'
    }
  ]
}
