import {
  ActionContext,
  ActionTree,
  MutationTree,
  Module,
  Store as VuexStore,
  CommitOptions,
  DispatchOptions,
} from 'vuex';
import AdminAPI from '@/api/ratings';
import { RootState } from '@/store/type';
import { ActionTypesEnum, AdminMarkersState, Mutations, MutationTypesEnum } from './type';

const moduleState: AdminMarkersState = {
  markers: [],
};

const mutations: MutationTree<AdminMarkersState> & Mutations = {
  [MutationTypesEnum.SET_MARKERS](state, { markers }) {
    state.markers = markers;
  },
};

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<AdminMarkersState, RootState>, 'commit'>;

interface Actions {
  [ActionTypesEnum.GET_MARKERS]({ commit }: AugmentedActionContext): void;
}

const actions: ActionTree<AdminMarkersState, RootState> & Actions = {
  async [ActionTypesEnum.GET_MARKERS]({ commit }) {
    const {
      data: { data },
    } = await AdminAPI.getWeights();
    commit(MutationTypesEnum.SET_MARKERS, { markers: data });
  },
};

export type AdminMarkersStore<S = AdminMarkersState> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions,
  ): ReturnType<Actions[K]>;
};

export const AdminMarkersModule: Module<AdminMarkersState, RootState> = {
  state: moduleState,
  mutations,
  actions,
};
