import { InjectionKey } from 'vue';
import { PersonalSocialNetworkAdditionalInfoVkUserLink } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personalSocialNetworkAdditionalInfoVkUserLink';
import { ApiPersonDetailVkRelativeUserRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRelativeUserRecord';
import { ApiPersonDetailVkSimpleUserRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkSimpleUserRecord';

export interface IpersonSocialNetworkAdditionalInfoVkUserLinkService {
  getPersonVkUserLink(
    vkListItem: ApiPersonDetailVkRelativeUserRecord | ApiPersonDetailVkSimpleUserRecord,
  ): PersonalSocialNetworkAdditionalInfoVkUserLink;
}

export const IPersonSocialNetworkAdditionalInfoVkUserLinkServiceKey: InjectionKey<IpersonSocialNetworkAdditionalInfoVkUserLinkService> =
  Symbol('IPersonSocialNetworkAdditionalInfoVkUserLinkServiceKey');
