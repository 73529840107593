import { injectable } from 'inversify';
import { ICompanyParticipationsTableService } from '@/modules/dossier/company/participations/services/companyParticipationsTableService.interface';
import { DxTableSortingMethodResult } from '@/modules/devExtreme/types/DxTableSortConfig';

@injectable()
export class CompanyParticipationsTableService implements ICompanyParticipationsTableService {
  sortByStatusTag(item1: string, item2: string): DxTableSortingMethodResult {
    const textOrder = [
      'Действующее',
      'Ликвидировано',
      'Юридическое лицо признано несостоятельным (банкротом) и в отношении него открыто конкурсное производство',
      'Ликвидировано по суду',
      'Ликвидировано по 129-ФЗ',
      'Прекратило деятельность (реорганизовано в форме слияния)',
      'Прекратило деятельность (реорганизовано в форме присоединения)',
      'Прекратило деятелньость (реорганизовано в форме преобразования)',
      'В процессе реорганизации в форме присоединения к нему других юридических лиц',
      'В процессе реорганизации в форме присоединения к другому юридическому лицу',
      'Прекратило деятельность',
    ];

    if (textOrder.indexOf(item1) < textOrder.indexOf(item2)) {
      return -1;
    }
    if (textOrder.indexOf(item1) > textOrder.indexOf(item2)) {
      return 1;
    }
    return 0;
  }
}
