import ReportsService from '@/modules/reports/common/services/reportsService/reportsService';
import { IReportsServiceKey } from '@/modules/reports/common/services/reportsService/reportsService.interfaces';
import { bindReportsMobileServices } from '@/modules/reports/mobile/common/configuration/inversify/container.reportsMobile';
import { bindDesktopReportsServices } from '@/modules/reports/desktop/configuration/inversify/container.reportsDesktop';
import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { ReportsApiServiceKey } from '@/modules/reports/common/services/reportsApiService.interface';
import ReportsApiService from '@/modules/reports/common/services/reportsApiService';
import { IReportsSettingsKey } from '@/modules/reports/common/settings/reportsSettings.interfaces';
import ReportsSettings from '@/modules/reports/common/settings/reportsSettings';
import { IReportsTypeServiceKey } from '@/modules/reports/common/services/reportsTypeService.interfaces';
import ReportsTypeService from '@/modules/reports/common/services/reportsTypeService';
import { IRequisiteConfigurationServiceKey } from '@/modules/reports/common/services/requisiteConfigurationService.interfaces';
import RequisiteConfigurationService from '@/modules/reports/common/services/requisiteConfigurationService';
import { IReportsHistoryItemDTOMappingServiceKey } from '@/modules/reports/common/services/reportsHistoryItemDTOMappingService.interfaces';
import ReportsHistoryItemDTOMappingService from '@/modules/reports/common/services/reportsHistoryItemDTOMappingService';
import { IReportHistoryRequisitesFactoryKey } from '@/modules/reports/common/services/reportHistoryRequisitesFactory.interfaces';
import ReportHistoryRequisitesFactory from '@/modules/reports/common/services/reportHistoryRequisitesFactory';
import { IReportHistoryItemsServiceKey } from '@/modules/reports/common/services/reportHistoryItemsService.interface';
import ReportHistoryItemsService from '@/modules/reports/common/services/reportHistoryItemsService';
import { IReportTypeFormatterKey } from '@/modules/reports/common/services/reportTypeFormatter.interfaces';
import ReportTypeFormatter from '@/modules/reports/common/services/reportTypeFormatter';
import { IReportParentTypeFormatterKey } from '@/modules/reports/common/services/reportParentTypeFormatter.interfaces';
import ReportParentTypeFormatter from '@/modules/reports/common/services/reportParentTypeFormatter';
import { IReportsExtendedReportGenerationPayloadMappingServiceKey } from '@/modules/reports/common/services/reportsExtendedReportGenerationPayloadMappingService.interfaces';
import ReportsExtendedReportGenerationPayloadMappingService from '@/modules/reports/common/services/reportsExtendedReportGenerationPayloadMappingService';
import { IReportsWebVersionUrlServiceKey } from '@/modules/reports/common/services/reportsWebVersionUrlService.interfaces';
import ReportsWebVersionUrlService from '@/modules/reports/common/services/reportsWebVersionUrlService';

export function bindReportsServices(bind: IContainerBinder) {
  bind(IReportsSettingsKey).to(ReportsSettings).inSingletonScope();
  bind(IReportsServiceKey).to(ReportsService);
  bind(ReportsApiServiceKey).to(ReportsApiService); // TODO SNSIDE_UI-1136: объединить IReportsService и ReportsApiService, ReportsApiServiceKey привести к код-стайлу
  bind(IReportsTypeServiceKey).to(ReportsTypeService);
  bind(IRequisiteConfigurationServiceKey).to(RequisiteConfigurationService);
  bind(IReportsWebVersionUrlServiceKey).to(ReportsWebVersionUrlService);
  bind(IReportsHistoryItemDTOMappingServiceKey).to(ReportsHistoryItemDTOMappingService);
  bind(IReportHistoryRequisitesFactoryKey).to(ReportHistoryRequisitesFactory);
  bind(IReportHistoryItemsServiceKey).to(ReportHistoryItemsService); // TODO SNSIDE_UI-1136: в мобилке возможность действий проверять через этот сервис
  bind(IReportTypeFormatterKey).to(ReportTypeFormatter); // TODO SNSIDE_UI-1136: на мобилке переделать форматирование через этот сервис
  bind(IReportParentTypeFormatterKey).to(ReportParentTypeFormatter); // TODO SNSIDE_UI-1136: на мобилке переделать форматирование через этот сервис
  bind(IReportsExtendedReportGenerationPayloadMappingServiceKey).to(
    ReportsExtendedReportGenerationPayloadMappingService,
  );
  bindDesktopReportsServices(bind);
  bindReportsMobileServices(bind);
}
