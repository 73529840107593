import { InjectionKey } from 'vue';
import { ApiUlListDataItemCompact } from '@/modules/lists/types/api/ul/apiUlListDataItem';
import { ApiUlListUpdateRequest } from '@/modules/lists/types/api/ul/apiUlListUpdateRequest';

export interface IAdministrationCompaniesTypesMappingService {
  map(item: ApiUlListDataItemCompact): ApiUlListUpdateRequest;
}

export const IAdministrationCompaniesTypesMappingServiceKey: InjectionKey<IAdministrationCompaniesTypesMappingService> =
  Symbol('IAdministrationCompaniesTypesMappingServiceKey');
