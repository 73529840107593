import { inject, injectable } from 'inversify';
import { IPassportNumberValidationService } from '@/modules/validation/services/passportNumberValidationService.interface';
import {
  IMasksService,
  IMasksServiceKey,
} from '@/modules/masks/services/masksService/masksService.interfaces';
import { passportNumberMask } from '@/modules/validation/configuration/masks/passportNumberMask';

@injectable()
export default class PassportNumberValidationService implements IPassportNumberValidationService {
  constructor(@inject(IMasksServiceKey as symbol) private masksService: IMasksService) {}

  validate(value: string): boolean {
    return this.masksService.isCompleted(value, passportNumberMask);
  }
}
