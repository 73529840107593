import { injectable } from 'inversify';
import { riskType } from '@/modules/risks/types/riskType';
import { IRiskTypeFormatterService } from '@/modules/risks/services/riskTypeFormatterService.interfaces';

@injectable()
export class RiskTypeFormatterService implements IRiskTypeFormatterService {
  formatRiskType(status: riskType): string {
    switch (status) {
      case riskType.Low:
        return 'Низкий';
      case riskType.Medium:
        return 'Средний';
      case riskType.High:
        return 'Высокий';
      default:
        return 'Не установлено';
    }
  }
}
