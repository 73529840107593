import {
  ApiRegulationsChecksTemplatesListRequest
} from '@/modules/regulationsCheck/common/types/api/apiRegulationsChecksTemplatesListRequest';
import {
  ApiVettingInterruptionRequest
} from '@/modules/regulationsCheck/common/types/api/apiVettingInterruptionRequest';
import {
  CommonRegulationsCheckHistory
} from '@/modules/regulationsCheck/common/types/api/commonRegulationsCheckHistory';
import {
  CommonRegulationsProgress
} from '@/modules/regulationsCheck/common/types/api/commonRegulationsProgress';
import { InjectionKey } from 'vue';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { RegulationsChecksHistoryItem } from '@/modules/regulationsCheck/common/types/regulationsChecksHistoryItem';
import { AxiosResponse } from 'axios';
import { GeneratedRegulationsCheckParams } from '@/modules/regulationsCheck/common/types/generatedRegulationsCheckParams';
import { CompletedRegulationsCheckParams } from '@/modules/regulationsCheck/common/types/completedRegulationsCheckParams';

export interface IRegulationsChecksService {
  startRegulationsCheck(params: GeneratedRegulationsCheckParams): Promise<AxiosResponse<ApiResponse<string>>>
  completeRegulationsCheck(params: CompletedRegulationsCheckParams): Promise<AxiosResponse<ApiResponse<string>>>
  interruptRegulationCheck(request: ApiVettingInterruptionRequest): Promise<AxiosResponse<void>>
  getTemplatesList(request: ApiRegulationsChecksTemplatesListRequest): Promise<AxiosResponse<ApiResponse<any>>>
  getHistory(id?: string): Promise<AxiosResponse<ApiResponse<Array<RegulationsChecksHistoryItem>>>>;
  getProgress(taskId: string): Promise<AxiosResponse<ApiResponse<CommonRegulationsProgress>>>;
}

export const IRegulationsChecksServiceKey: InjectionKey<IRegulationsChecksService> = Symbol('IRegulationsChecksService');
