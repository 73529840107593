import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import {
  useCompanyListChangedEvent
} from '@/modules/lists/composable/useCompanyListChangedEvent/useCompanyListChangedEvent';
import {
  useCompanyListsChangedEvent
} from '@/modules/lists/composable/useCompanyListsChangedEvent/useCompanyListsChangedEvent';
import { ICompanyListsService } from '@/modules/lists/services/companyListsService.interfaces';
import { ApiNewUlListData } from '@/modules/lists/types/api/ul/apiNewUlListData';
import { ApiUlListAppendRequest } from '@/modules/lists/types/api/ul/apiUlListAppendRequest';
import { ApiUlListCreationRequest } from '@/modules/lists/types/api/ul/apiUlListCreationRequest';
import { ApiUlListUpdateRequest } from '@/modules/lists/types/api/ul/apiUlListUpdateRequest';
import { ApiUlListData } from '@/modules/lists/types/api/ul/apiUlListData';
import { ApiUlListDetails } from '@/modules/lists/types/api/ul/apiUlListDetails';
import { ApiUlListRemoveItemsRequest } from '@/modules/lists/types/api/ul/apiUlListRemoveItemsRequest';
import { ApiUlListRemoveRequest } from '@/modules/lists/types/api/ul/apiUlListRemoveRequest';
import { ApiUlListsAddTags } from '@/modules/lists/types/api/ul/apiUlListsAddTags';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

const { trigger: triggerCompanyListsChangedEvent } = useCompanyListsChangedEvent();
const { trigger: triggerCompanyListChangedEvent } = useCompanyListChangedEvent();

@injectable()
export default class CompanyListsService implements ICompanyListsService {
  constructor(
    @inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance
  ) { }

  getLists(): Promise<AxiosResponse<ApiResponse<ApiUlListData>>> {
    return this.apiEndpoint.get(`/ul-lists`);
  }

  async createList(payload: ApiUlListCreationRequest): Promise<AxiosResponse<ApiResponse<ApiNewUlListData>>> {
    const response = await this.apiEndpoint.post<ApiResponse<ApiNewUlListData>>(`/ul-lists/create`, payload);
    triggerCompanyListsChangedEvent();
    return response;
  }

  async updateList(payload: ApiUlListUpdateRequest): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post(`/ul-lists/update`, payload);

    triggerCompanyListsChangedEvent();
    triggerCompanyListChangedEvent(payload.id);

    return response;
  }

  async addToList(payload: ApiUlListAppendRequest): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post(`/ul-lists/add`, payload);

    triggerCompanyListsChangedEvent();
    payload.ulListIds.forEach(x => triggerCompanyListChangedEvent(x));

    return response;
  }

  async deleteList(payload: ApiUlListRemoveRequest): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post(`/ul-lists/remove`, payload);
    triggerCompanyListsChangedEvent();
    return response;
  }

  getListDetails(id: string): Promise<AxiosResponse<ApiResponse<ApiUlListDetails>>> {
    return this.apiEndpoint.get(`/ul-lists/${id}`);
  }

  async deleteEntities(payload: ApiUlListRemoveItemsRequest): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post(`/ul-lists/${payload.ulListId}/items/remove`, payload);

    triggerCompanyListsChangedEvent();
    triggerCompanyListChangedEvent(payload.ulListId);

    return response
  }

  async addToCounterParty(payload: ApiUlListsAddTags): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post('/ul-lists/counterparty-list/add', payload);

    triggerCompanyListsChangedEvent();
    payload.ulListIds.forEach(x => triggerCompanyListChangedEvent(x));

    return response;
  }

  async addToCompetitor(payload: ApiUlListsAddTags): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post('/ul-lists/competitor-lists/add', payload);

    triggerCompanyListsChangedEvent();
    payload.ulListIds.forEach(x => triggerCompanyListChangedEvent(x));

    return response;
  }

  async addToGroupCompany(payload: ApiUlListsAddTags): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post('/ul-lists/group-company-lists/add', payload);

    triggerCompanyListsChangedEvent();
    payload.ulListIds.forEach(x => triggerCompanyListChangedEvent(x));

    return response;
  }

  async addToBlackList(payload: ApiUlListsAddTags): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post('/ul-lists/black-lists/add', payload);

    triggerCompanyListsChangedEvent();
    payload.ulListIds.forEach(x => triggerCompanyListChangedEvent(x));

    return response;
  }
}
