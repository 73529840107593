import { injectable } from 'inversify';
import { IEventJournalService } from '@/modules/eventJournal/services/eventJournalService.interface';
import { EventJournalTypes } from '@/modules/eventJournal/types/eventJournalTypes';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';

@injectable()
export default class EventJournalService implements IEventJournalService {
  getEventJournalType(type: string): EventJournalTypes {
    switch (type) {
      case 'Неизвестно':
        return EventJournalTypes.unknown;
      case 'Импорт':
        return EventJournalTypes.import;
      case 'ЗУП':
        return EventJournalTypes.zup;
      case 'Создана по косвенным связям':
        return EventJournalTypes.indirect;
      case 'АВ 100':
        return EventJournalTypes.ab100;
      case 'Контур.Фокус':
        return EventJournalTypes.kontur;
      case 'Кронос':
        return EventJournalTypes.kronos;
      case 'Xneo':
        return EventJournalTypes.xneo;
      case 'МВД':
        return EventJournalTypes.mvd;
      case 'ФНС':
        return EventJournalTypes.fns;
      case 'Одиссей.ФЛ':
        return EventJournalTypes.odisseyFl;
      case 'БФО':
        return EventJournalTypes.bfo;
      case 'IDX':
        return EventJournalTypes.idx;
      case 'Одиссей.ЮЛ':
        return EventJournalTypes.odisseyUl;
      case 'Одноклассники':
        return EventJournalTypes.odnoklassniki;
      case 'Vk':
        return EventJournalTypes.vk;
      case 'Дамия':
        return EventJournalTypes.damia;
      default:
        return EventJournalTypes.unknown;
    }
  }

  getEventJournalIcon(value: string): SystemIconsNames {
    const eventJournalType = this.getEventJournalType(value);
    switch (eventJournalType) {
      case EventJournalTypes.unknown:
        return SystemIconsNames.eventsUnknown;
      case EventJournalTypes.import:
        return SystemIconsNames.eventsImport;
      case EventJournalTypes.zup:
        return SystemIconsNames.socialOk;
      case EventJournalTypes.indirect:
        return SystemIconsNames.eventsIndirect;
      case EventJournalTypes.ab100:
        return SystemIconsNames.eventsAb100;
      case EventJournalTypes.kontur:
        return SystemIconsNames.eventsKontur;
      case EventJournalTypes.kronos:
        return SystemIconsNames.eventsKronos;
      case EventJournalTypes.xneo:
        return SystemIconsNames.eventsXneo;
      case EventJournalTypes.mvd:
        return SystemIconsNames.eventsMvd;
      case EventJournalTypes.fns:
        return SystemIconsNames.eventsFns;
      case EventJournalTypes.damia:
        return SystemIconsNames.eventsDamia;
      case EventJournalTypes.odisseyFl:
        return SystemIconsNames.eventsOdissey;
      case EventJournalTypes.bfo:
        return SystemIconsNames.eventsOdissey;
      case EventJournalTypes.idx:
        return SystemIconsNames.eventsBfo;
      case EventJournalTypes.odisseyUl:
        return SystemIconsNames.eventsIdx;
      case EventJournalTypes.odnoklassniki:
        return SystemIconsNames.socialOk;
      case EventJournalTypes.vk:
        return SystemIconsNames.socialVk;
      default:
        return SystemIconsNames.unknown;
    }
  }
}
