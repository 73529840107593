import { injectable } from 'inversify';
import { ApiPersonDetailVkRecordStringItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecordStringItem';
import { orderBy } from 'lodash';
import { IPersonSocialNetworkAdditionalInfoVKGetLastValueService } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/parsers/personSocialNetworkAdditionalInfoVKGetLastValueService.interface';

@injectable()
export default class PersonSocialNetworkAdditionalInfoVKGetLastValueService
  implements IPersonSocialNetworkAdditionalInfoVKGetLastValueService
{
  getPersonSocialNetworkVKMainInfoLatestValue(
    detailVkItemValue: ApiPersonDetailVkRecordStringItem[],
  ): string {
    return (
      orderBy(detailVkItemValue, (value) => new Date(value.date), 'desc').shift()?.value ||
      'Информация отсутствует'
    );
  }
}
