import { IAccessScopeService } from '@/modules/rights/services/AccessScopeService.interfaces';
import { AccessScope } from '@/modules/rights/type/AccessScope';
import { UserRole } from '@/modules/rights/type/userRoleEnum';
import { AccessScopeEnum } from '@/modules/rights/type/AccessScopeEnum';
import { AccessScopeUser } from '@/modules/rights/type/AccessScopeUser';
import { AccessScopePersonCreator } from '@/modules/rights/type/AccessScopePersonCreator';
import { AccessScopeAdmin } from '@/modules/rights/type/AccessScopeAdmin';
import { injectable } from 'inversify';
import { useUserRightsStore } from '@/modules/rights/stores/useUserRights';
import { storeToRefs } from 'pinia';

@injectable()
export class AccessScopeService implements IAccessScopeService {
  private modifyFields(accessScopeObject: any, accessScope: object) {
    Object.entries(accessScopeObject).forEach(([key, value]) => {
      if (Object.keys(accessScope).includes(key)) {
        accessScopeObject[key] = true;
      }
    });
  }

  setAccessScope(roles: Array<string>): AccessScope {
    const accessScopeObject: any = {};
    Object.keys(AccessScopeEnum).forEach((item) => {
      accessScopeObject[item] = false;
    });

    if (roles.includes(UserRole.admin)) {
      this.modifyFields(accessScopeObject, AccessScopeAdmin);
    }

    if (roles.includes(UserRole.user)) {
      this.modifyFields(accessScopeObject, AccessScopeUser);
    }

    if (roles.includes(UserRole.personCreator)) {
      this.modifyFields(accessScopeObject, AccessScopePersonCreator);
    }

    return accessScopeObject;
  }

  checkHasScope(accessScopes: Array<AccessScopeEnum>): boolean {
    const userRightsStore = useUserRightsStore();
    const { currentUser } = storeToRefs(userRightsStore);

    if (currentUser.value?.accessScope != undefined) {
      const { accessScope } = currentUser.value;
      return accessScopes.some((item) => accessScope[item] == true);
    }
    return false;
  }
}
