import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { IPeopleService } from '@/modules/dossier/person/common/services/peopleService.interfaces';
import { ApiPersonDetail } from '@/modules/dossier/person/common/types/api/apiPersonDetail';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class PeopleService implements IPeopleService {
  constructor(
    @inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance
  ) {
  }

  getPerson(id: string): Promise<AxiosResponse<ApiResponse<ApiPersonDetail>>> {
    const url = `/person/${id}`;
    return this.apiEndpoint.get(url);
  }

  getJsonReport(personId: string): Promise<AxiosResponse> {
    const url = `reports/example/report-person-json/${personId}`;
    return this.apiEndpoint.get(url);
  }
}
