import { InjectionKey } from 'vue';
import { Trademark } from '@/modules/dossier/company/trademark/types/trademark';

export interface ICompanyTrademarkService {
  calculateCountTotalTrademarks(trademark: Array<Trademark>): number;
  calculateCountActualTrademarks(trademark: Array<Trademark>): number;
}

export const ICompanyTrademarkServiceKey: InjectionKey<ICompanyTrademarkService> = Symbol(
  'ICompanyTrademarkService',
);
