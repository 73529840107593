import { injectable } from 'inversify';
import { IMappingService } from '@/modules/dossier/company/accounting/services/mappingService.interface';
import { DateUtils } from '@/utils/dateUtils';
import { SortOrder } from '@/modules/common/types/sortOrder';
import groupBy from 'lodash/groupBy';
import { BuhIndicatorsList } from '@/modules/dossier/company/accounting/types/buhIndicatorsList';
import { BuhIndicator } from '@/modules/dossier/company/accounting/types/buhIndicator';

@injectable()
export default class MappingService implements IMappingService {
  getSortedUniqueYears(data: any): Array<number> {
    const yearsArray = data.map((buhData: any) => buhData.year);
    return DateUtils.getSortedUniqueYears(yearsArray, SortOrder.desc);
  }

  mapTableData(data: any, config: BuhIndicatorsList): Array<any> {
    // Отфильтровываем все ненужные данные, ориентируясь на поле "code" в config'е
    const configCodes = config.flatMap(({ indicators }) => indicators.map(({ code }) => code));
    const filteredDataByConfig = data.filter((dataRow: any) => configCodes.includes(dataRow.code));

    // Получаем сгруппированный по "code" массив:
    // [
    //   [{ code: 3400, year: 2019, value: 11 }, { code: 3400, year: 2020, value: 21 }]
    //   [{ code: 3401, year: 2019, value: 12 }, { code: 3401, year: 2020, value: 22 }]
    // ]
    const groupedObjectDataByCode = groupBy(filteredDataByConfig, (dataRow) => dataRow.code);
    const groupedDataByCode = Object.values(groupedObjectDataByCode);

    // Схлопываем массив:
    // [
    //   { code: 3400, value2019: 11, value2020: 21 },
    //   { code: 3401, value2019: 12, value2020: 22 },
    // ]
    const flattenDataByYear = groupedDataByCode.map((dataRowArray) =>
      dataRowArray.reduce((acc, cur) => {
        // Вначале приводим каждый объект в следующий вид: { code2020: 3400, year2020: 2020, value2020: 21 }
        const yearPrefix = cur.year;
        const curFieldsWithYearPrefix = Object.entries(cur).reduce(
          (fields, [fieldKey, fieldValue]) => ({
            ...fields,
            [`${fieldKey}${yearPrefix}`]: fieldValue,
          }),
          {},
        );

        // Затем уже из массива этих объектов слепливаем 1 объект
        // При этом в объекте появляется много лишних полей по типу year2020, code2020 и id2020
        return {
          ...acc,
          ...curFieldsWithYearPrefix,
          code: cur.code,
        };
      }, {}),
    );

    // Дополняем объекты полями category, title, sortId и categorySortId из config
    const dataWithConfigFields = flattenDataByYear.map((dataObject) => {
      const category = config.find((configInfo) => {
        const categoryCodes = configInfo.indicators.map((indicators) => indicators.code);
        return categoryCodes.includes(dataObject.code);
      });

      const categoryIndicator = category?.indicators?.find(
        (indicator) => indicator.code == dataObject.code,
      );

      if (categoryIndicator == undefined) {
        console.error('Accounting reporting indicator not found in config');
      }

      return {
        ...dataObject,
        text: categoryIndicator?.text ?? dataObject.text2021,
        sortId: categoryIndicator?.sortId,
        isBold: categoryIndicator?.isBold,
        categoryRoot: category?.categoryRoot,
        category: category?.category,
        categorySortId: category?.sortId,
      };
    });

    // Отсортировываем внутри категории и по категориям
    const sortedDataWithConfigFieldsFlattenByYearGroupedByCodeFilteredByConfig =
      dataWithConfigFields
        .sort((a, b) => a.sortId - b.sortId)
        .sort((a, b) => a.categorySortId - b.categorySortId);

    return sortedDataWithConfigFieldsFlattenByYearGroupedByCodeFilteredByConfig;
  }

  mapReportsChangesMovementsTableData(
    data: any,
    buhIndicatorsConfig: Array<BuhIndicator>,
  ): Array<any> {
    // Отфильтровываем все ненужные данные, ориентируясь на поле "code" в config'е
    const configCodes = buhIndicatorsConfig.map((indicator) => indicator.code);
    const filteredDataByConfig = data.filter((dataRow: any) => configCodes.includes(dataRow.code));

    // Дополняем объекты полями title и sortId из config и полем categoryYear из year
    const dataWithConfigFields = filteredDataByConfig.map((dataObject: any) => {
      const configIndicator = buhIndicatorsConfig.find(
        (indicator) => indicator.code == dataObject.code,
      );

      if (configIndicator == undefined) {
        console.error('Accounting reporting indicator not found in config');
      }

      let textWithCurrentYear = configIndicator?.text ?? dataObject.text;
      if (dataObject.code == 3300) {
        textWithCurrentYear = `Величина капитала на 31 декабря ${dataObject.year} года`;
      }

      return {
        categoryYear: `За ${dataObject.year} г.`,
        text: textWithCurrentYear,
        code: dataObject.code,
        sortId: configIndicator?.sortId,
        isBold: configIndicator?.isBold,
        page12AuthorizedCapital: dataObject.page12AuthorizedCapital,
        page12OwnSharesFromShareholders: dataObject.page12OwnSharesFromShareholders,
        page12ExtraCapital: dataObject.page12ExtraCapital,
        page12Reservecapital: dataObject.page12Reservecapital,
        page12UndistributedProfits: dataObject.page12UndistributedProfits,
        page12Total: dataObject.page12Total,
      };
    });

    const sortedData = dataWithConfigFields.sort((a: any, b: any) => a.sortId - b.sortId);

    return sortedData;
  }
}
