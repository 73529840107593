import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { ICurrencyTagParserServiceKey } from '@/modules/currency/services/currencyTagParserService/currencyTagParserService.interfaces';
import CurrencyTagParserService from '@/modules/currency/services/currencyTagParserService/currencyTagParserService';
import { ICurrencyTagFormatterServiceKey } from '@/modules/currency/services/currencyTagFormatterService/currencyTagFormatterService.interfaces';
import CurrencyTagFormatterService from '@/modules/currency/services/currencyTagFormatterService/currencyTagFormatterService';
import { ICurrencyParserServiceKey } from '@/modules/currency/services/сurrencyParserService/сurrencyParserService.interfaces';
import CurrencyParserService from '@/modules/currency/services/сurrencyParserService/сurrencyParserService';

export function bindCurrencyServices(bind: IContainerBinder) {
  bind(ICurrencyTagParserServiceKey).to(CurrencyTagParserService);
  bind(ICurrencyTagFormatterServiceKey).to(CurrencyTagFormatterService);
  bind(ICurrencyParserServiceKey).to(CurrencyParserService);
}
