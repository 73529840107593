import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { ParentDomainTypes } from '@/modules/common/types/api/parentDomainType';
import { ParserInputDataType } from '@/modules/dataCollection/types/api/parserInputDataType';
import { useCompanyDossierUpdatedEvent } from '@/modules/dossier/company/common/composables/useCompanyDossierUpdatedEvent';
import { usePersonDossierUpdatedEvent } from '@/modules/dossier/person/common/composables/usePersonDossierUpdatedEvent';
import { IDataImportService } from '@/modules/import/services/dataImportService.interfaces';
import { ApiEntityDetailFilesRecord } from '@/modules/import/types/api/apiEntityDetailFilesRecord';
import { ApiImportProgress } from '@/modules/import/types/api/apiImportProgress';
import { MainEntityFile } from '@/modules/import/types/api/mainEntityFile';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

const { trigger: triggerPersonDossierUpdatedEvent } = usePersonDossierUpdatedEvent();
const { trigger: triggerCompanyDossierUpdatedEvent } = useCompanyDossierUpdatedEvent();
@injectable()
export default class DataImportService implements IDataImportService {
  constructor(@inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance) {}

  makeProcessingZup(body: FormData): Promise<AxiosResponse<ApiResponse<string[]>>> {
    return this.apiEndpoint.post(
      '/data-import/zup?normalizationMode=DadataSuggestions&cachingMode=With',
      body,
    );
  }

  importZupProgressPercent(
    importProgressId: string,
  ): Promise<AxiosResponse<ApiResponse<ApiImportProgress>>> {
    return this.apiEndpoint.get(`/data-import/zup-progress/${importProgressId}`);
  }

  makeProcessingOnec(body: FormData): Promise<AxiosResponse<ApiResponse<string>>> {
    return this.apiEndpoint.post(
      '/data-import/onec',
      body,
    );
  }

  loadMainPhotoCompany(file: File, entityId: string): Promise<AxiosResponse<undefined>> {
    return this.loadMainPhoto(file, entityId, ParentDomainTypes.Company);
  }

  loadMainPhotoPerson(file: File, entityId: string) {
    return this.loadMainPhoto(file, entityId, ParentDomainTypes.Person);
  }

  async addFile(
    body: FormData,
    inputDataType: ParserInputDataType,
  ): Promise<AxiosResponse<ApiResponse<ApiEntityDetailFilesRecord[]>>> {
    const response = await this.apiEndpoint.post('data-import/file/add', body);
    const id = body.get('fileParentId') as string;

    if (inputDataType == ParserInputDataType.Person) {
      triggerPersonDossierUpdatedEvent(id);
    }
    if (inputDataType == ParserInputDataType.Company) {
      triggerCompanyDossierUpdatedEvent(id);
    }

    return response;
  }

  removeFile(
    idFile: string,
    inputDataType: ParserInputDataType,
    dossierId?: string,
  ): Promise<AxiosResponse<ApiResponse<MainEntityFile[]>>> {
    const response = this.apiEndpoint.post(`data-import/file/${idFile}/remove`);

    if (inputDataType == ParserInputDataType.Person && dossierId != undefined) {
      triggerPersonDossierUpdatedEvent(dossierId);
    }
    if (inputDataType == ParserInputDataType.Company && dossierId != undefined) {
      triggerCompanyDossierUpdatedEvent(dossierId);
    }

    return response;
  }

  loadPhotoPerson(body: FormData): Promise<AxiosResponse<undefined>> {
    return this.loadPhoto(body, ParentDomainTypes.Person);
  }

  loadPhotoCompanies(body: FormData): Promise<AxiosResponse<undefined>> {
    return this.loadPhoto(body, ParentDomainTypes.Company);
  }

  addFileConcent(
    body: FormData,
  ): Promise<AxiosResponse<ApiResponse<ApiEntityDetailFilesRecord[]>>> {
    const response = this.apiEndpoint.post('data-import/personal-data-consent-agreement/add', body);

    const id = body.get('fileParentId') as string;
    triggerPersonDossierUpdatedEvent(id);

    return response;
  }

  removeFileConcent(idFile: string, personId: string): Promise<AxiosResponse<ApiResponse<MainEntityFile[]>>> {
    const response = this.apiEndpoint.post(
      `data-import/personal-data-consent-agreements/remove-file/${idFile}`,
    );
    triggerPersonDossierUpdatedEvent(personId);

    return response;
  }

  private loadMainPhoto(
    file: File,
    entityId: string,
    parentEntityType: ParentDomainTypes,
  ): Promise<AxiosResponse<undefined>> {
    const formData = new FormData();
    formData.append('file', file);

    return this.apiEndpoint.post('/data-import/main-photo/add', formData, {
      params: {
        entityId,
        parentEntityType,
      },
    });
  }

  private loadPhoto(
    body: FormData,
    parentEntityType: ParentDomainTypes,
  ): Promise<AxiosResponse<undefined>> {
    return this.apiEndpoint.post(
      `/data-import/main-photos/update?parentEntityType=${parentEntityType}`,
      body,
    );
  }
}
