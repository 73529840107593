export enum PersonSocialNetworkAdditionalInfoAccordeonSections {
  basicInfo = 'basicInfo',
  relatives = 'relatives',
  contacts = 'contacts',
  education = 'education',
  career = 'career',
  military = 'military',
  interests = 'interests',
  lifePosition = 'lifePosition',
  aboutMe = 'aboutMe',
  activity = 'activity',
  books = 'books',
  movies = 'movies',
  games = 'games',
  music = 'music',
  quote = 'quote',
  tvs = 'tvs',
  statuses = 'statuses',
  familyStatus = 'familyStatus',
  domains = 'domains',
  avatars = 'avatars',
  profilePhotos = 'profilePhotos',
}
