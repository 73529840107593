import {
  useRegulationCheckChangedEvent
} from '@/modules/regulationsCheck/common/composables/events/useRegulationCheckChangedEvent/useRegulationCheckChangedEvent';
import {
  IRegulationsChecksService
} from '@/modules/regulationsCheck/common/services/regulationsChecksService/regulationsChecksService.interfaces';
import {
  ApiRegulationsChecksTemplatesListRequest
} from '@/modules/regulationsCheck/common/types/api/apiRegulationsChecksTemplatesListRequest';
import {
  ApiVettingInterruptionRequest
} from '@/modules/regulationsCheck/common/types/api/apiVettingInterruptionRequest';
import {
  ApiVettingTemplateDetails
} from '@/modules/regulationsCheck/common/types/api/apiVettingTemplateDetails';
import {
  CommonRegulationsCheckHistory
} from '@/modules/regulationsCheck/common/types/api/commonRegulationsCheckHistory';
import {
  CommonRegulationsProgress
} from '@/modules/regulationsCheck/common/types/api/commonRegulationsProgress';
import { inject, injectable } from 'inversify';
import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { RegulationsChecksHistoryItem } from '@/modules/regulationsCheck/common/types/regulationsChecksHistoryItem';
import { GeneratedRegulationsCheckParams } from '@/modules/regulationsCheck/common/types/generatedRegulationsCheckParams';
import { CompletedRegulationsCheckParams } from '@/modules/regulationsCheck/common/types/completedRegulationsCheckParams';

const { triggerRegulationCheckChangedEvent } = useRegulationCheckChangedEvent();

@injectable()
export default class RegulationsChecksService implements IRegulationsChecksService {
  constructor(
    @inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance
  ) { }

  getHistory(id?: string): Promise<AxiosResponse<ApiResponse<Array<RegulationsChecksHistoryItem>>>> {
    return this.apiEndpoint.get('/vetting/history', {
      params: { id }
    });
  }

  async startRegulationsCheck(params: GeneratedRegulationsCheckParams): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post<ApiResponse<string>>('/vetting/generate', params);

    triggerRegulationCheckChangedEvent(params.id);

    return response;
  }

  async completeRegulationsCheck(params: CompletedRegulationsCheckParams): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post('/vetting/complete', params);

    triggerRegulationCheckChangedEvent(params.id);

    return response;
  }

  getTemplatesList({ historic, types }: ApiRegulationsChecksTemplatesListRequest): Promise<AxiosResponse<ApiResponse<Array<ApiVettingTemplateDetails>>>> {
    return this.apiEndpoint.get('/vetting/templates', {
      params: {
        types,
        historic
      }
    });
  }

  getProgress(taskId: string): Promise<AxiosResponse<ApiResponse<CommonRegulationsProgress>>> {
    return this.apiEndpoint.get('/process-task/status', {
      params: { taskId }
    });
  }

  async interruptRegulationCheck({ id, taskId }: ApiVettingInterruptionRequest): Promise<AxiosResponse<void>> {
    const result = await this.apiEndpoint.post('/vetting/interrupt', { taskId });

    triggerRegulationCheckChangedEvent(id);

    return result;
  }
}
