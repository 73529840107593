import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { IPersonDossierCRUDService } from '@/modules/dossier/person/common/services/personDossierCRUDService.interfaces';
import {
  ApiPersonAddEditDataRequest
} from '@/modules/dossier/person/common/types/api/apiPersonAddEditDataRequest';
import { DeleteApiModel } from '@/modules/dossier/person/common/types/api/deleteApiModel';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class DossierCRUDService implements IPersonDossierCRUDService {
  constructor(
    @inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance
  ) { }

  addEntities(payload: ApiPersonAddEditDataRequest): Promise<AxiosResponse<void>> {
    return this.apiEndpoint.post(`/dossier-crud-operations/add`, payload);
  }

  updateEntities(payload: ApiPersonAddEditDataRequest): Promise<AxiosResponse<void>> {
    return this.apiEndpoint.post(`/dossier-crud-operations/update`, payload);
  }

  deleteEntities(payload: DeleteApiModel): Promise<AxiosResponse<void>> {
    return this.apiEndpoint.post(`/dossier-crud-operations/delete`, payload);
  }
}
