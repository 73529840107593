import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { AdministrationMarkerWeightsGroup } from '@/modules/administration/types/administrationMarkerWeightsGroup';

export const markerWeightsPersonGroups: Array<AdministrationMarkerWeightsGroup> = [
  {
    groupName: 'Деятельность в коммерческих структурах',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'физическое лицо зарегистрировано в качестве индивидуального предпринимателя',
      'физическое лицо было зарегистировано в качестве индивидуального предпринимателя',
      'физическое лицо является руководителем или учредителем действующего юридического лица',
      'физическое лицо зарегистрировано в качестве самозанятого',
    ],
  },
  {
    groupName: 'Деятельность в коммерческих структурах',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'физическое лицо является руководителем или учредителем действующего юридического лица (более 5)',
      'физическое лицо является руководителем или учредителем действующего юридического лица (более 10)',
      'физическое лицо вляется руководителем или учредителем действующего юридического лица (более 50)',
      'физическое лицо владеет долей в компании, входящей в негативные списки',
      'физическое лицо руководит компанией, входящей в негативные списки',
    ],
  },
  {
    groupName: 'Завершенные исполнительные производства',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'найдено от 5 до 9 завершенных исполнительных производств',
      'найдено свыше 10 завершенных исполнительных производств',
      'имелись исполнительные производства на сумму от 2 000 руб. до 10 000 руб.',
      'имелись исполнительные производства на сумму от 10 000 руб. до 100 000 руб.',
      'имелись исполнительные производства на сумму свыше 100 000 руб.',
    ],
  },
  {
    groupName: 'Завершенные исполнительные производства',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'найдено от 1 до 4 завершенных исполнительных производств',
      'имелись исполнительные производства на сумму до 2 000 руб.',
    ],
  },

  {
    groupName: 'Интернет-активность',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'найдено большое количество публикаций личной информации в социальных сетях',
      'в аккаунте в социальных сетях найден запрещенный или нежелательный контент',
      'зафиксирована высокая интернет-активность',
      'физическое лицо способно заинтересовывать пользователей социальных сетей публикуемым контентом и формировать неформальные группы',
    ],
  },
  {
    groupName: 'Информация о начатой или завершенной процедуре банкротства',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: ['физическое лицо является банкротом', 'являлся банкротом'],
  },

  {
    groupName: 'Инциденты',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'физическое лицо являлось участником происшествий',
      'производилась внеплановая замена документов',
    ],
  },

  {
    groupName: 'Иные задолженности перед государством',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'имеет задолженность по оплате государственных пошлин',
      'имеет судебную задолженность',
      'имеет задолженность по оплате счетов за услуги',
    ],
  },

  {
    groupName: 'Компрометирующий круг общения',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'имеются криминальные связи',
      'установлена принадлежность к малым неформальным группам, сектам',
    ],
  },

  {
    groupName: 'Ложные или неполные сведения в досье',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'досье не содержит действительный паспорт',
      'досье не содержит корректный СНИЛС',
      'досье не содержит корректный ИНН',
      'досье не содержит верифицированный абонентский номер',
      'физическим лицом предоставлены недостоверные сведения',
      'физическим лицом предоставлены неполные сведения',
      'физическим лицом предоставлены поддельные документы',
      'установлено несоответствие занимаемой или предполагаемой должности',
      'не найдены действительные разрешения на работу и патенты на осуществление трудовой деятельности (для иностранных граждан и лиц без гражданства)',
      'не найдены действительные лицензии на трудоустройство за пределами территории РФ',
      'не найдены действительные приглашения на въезд в РФ (для иностранных граждан и лиц без гражданства)',
      'есть основания для неразрешения въезда на территорию РФ по линии МВД России (для иностранных граждан и лиц без гражданства)',
    ],
  },

  {
    groupName: 'Налоговые задолженности',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'найдена налоговая задолженность на общую сумму от 2 000 руб. до 10 000 руб.',
      'найдена налоговая задолженность на общую сумму от 10 000 руб. до 100 000 руб.',
      'налоговая задолженность превышает 100 000 руб.',
    ],
  },

  {
    groupName: 'Налоговые задолженности',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: [
      'имеется налоговая задолженность, не превышающая 500 руб.',
      'найдена налоговая задолженность на общую сумму от 500 до 2 000 руб.',
    ],
  },

  {
    groupName: 'Незавершенные исполнительные производства',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'найдено от 1 до 4 незавершенных исполнительных производств',
      'найдено от 5 до 9 незавершенных исполнительных производств',
      'найдено свыше 10 незавершенных исполнительных производств',
      'найдены незавершенные исполнительные производства на сумму от 2 000 руб. до 10 000 руб.',
      'найдены незавершенные исполнительные производства на сумму от 10 000 руб. до 100 000 руб.',
      'найдены незавершенные исполнительные производства на сумму свыше 100 000 руб.',
    ],
  },

  {
    groupName: 'Незавершенные исполнительные производства',
    statusIcon: SystemIconsNames.circleWarning,
    statusIconColor: 'orange',
    list: ['найдены незавершенные исполнительные производства на сумму до 2 000 руб.'],
  },

  {
    groupName: 'Оружие',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'физическое лицо имеет право на ношение оружия',
      'физическое лицо совершало нарушения правил производства, приобретения, продажи, передачи, хранения, ношения, коллекционирования, экспонирования, уничтожения или учета оружия и патронов к нему, а также нарушение правил производства, продажи, хранения, уничтожения или учета взрывчатых веществ и взрывных устройств, пиротехнических изделий, порядка выдачи свидетельства о прохождении подготовки и проверки знания правил безопасного обращения с оружием и наличия навыков безопасного обращения с оружием или медицинских заключений об отсутствии противопоказаний к владению оружием',
    ],
  },

  {
    groupName: 'Привлечение к ответственности',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'физическое лицо привлекалось к уголовной ответственности',
      'физическое лицо привлекалось к административной ответственности',
      'физическое лицо имеет неснятую/непогашенную судимость',
    ],
  },

  {
    groupName: 'Психические отклонения',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: ['имеются зависимости', 'имеет другие психические отклонения'],
  },

  {
    groupName: 'Розыск',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'физическое лицо находилось в розыске МВД',
      'физическое лицо находится в розыске ФСИН',
      'физическое лицо находится в розыске ФССП',
    ],
  },

  {
    groupName: 'Санкционные списки',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: ['найдены записи в санкционных списках'],
  },

  {
    groupName: 'Сведения с мест работы, требующие внимания',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'зафиксирована частая смена мест работы',
      'физическое лицо ранее работало в компании внутреннего контура',
      'родственники физического лица работают в компании внутреннего контура',
      'родственники физического лица работали в компании внутреннего контура',
      'установлен факт трудоустройства в компании, входящей в негативные списки',
      'имеются негативные отзывы с прошлых мест работы',
      'предоставлены рекомендации от сотрудника или руководителя компании внутреннего контура',
    ],
  },

  {
    groupName: 'Связи',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'установлена связь с конкурентами',
      'установлена связь с контрагентами',
      'установлена связь с "черным" списком',
      'установлена связь с сотрудниками',
      'физическое лицо входит в число конкурентов',
      'физическое лицо входит в число контрагентов',
      'физическое лицо является(-лся) сотрудником',
      'физическое лицо входит в пользовательский "черный" список',
      'работает(-л) в компании-конкуренте',
      'владеет(-л) долей в компании-конкуренте',
      'руководит(-л) компанией-конкурентом',
      'совмещает(-л) должности в компании-конкуренте',
      'родственники связаны с компанией-конкурентом',
      'поддерживает дружеские отношения с представителями компании-конкурента',
      'владеет(-л) долей в компании-контрагенте',
      'руководит(-л) компанией-контрагентом',
      'совмещает(-л) должности в компании-контрагенте',
      'работает(-л) в компании-контрагенте',
      'родственники связаны с компанией-контрагентом',
      'поддерживает дружеские отношения с представителями компании-контрагента',
    ],
  },

  {
    groupName: 'Совпадение со списками ИПДЛ/МПДЛ/РПДЛ',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: ['установлена принадлежность к ИПДЛ/МПДЛ/РПДЛ или к их ближайшему окружению'],
  },

  {
    groupName: 'Специальный учет',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'физическое лицо состоит на учете в психоневрологических, наркологических и др. диспансерах',
      'физическое лицо состоит на учете в ОВД РФ',
    ],
  },

  {
    groupName: 'Судебные дела',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'физическое лицо участвовало в судопроизводстве в роли ответчика',
      'физическое лицо участвовало в судопроизводстве в роли истца',
      'физическое лицо подавало судебный иск в отношении работодателя',
      'физическое лицо подавало судебный иск в отношении компании внутреннего контура',
      'физическое лицо являлось ответчиком в деле, истцом по которому выступал работодатель',
      'физическое лицо являлось ответчиком в деле, истцом по которому выступала компания внутреннего контура',
    ],
  },

  {
    groupName: 'Финансы и имущество',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'финансовый скоринг (ОКБ) показал плохой результат',
      'Финансовый скоринг (классический) показал плохой результат',
      'Финансовый скоринг (FICO) показал плохой результат',
      'установлена негативная кредитная история',
      'имеется заложенное движимое имущество',
      'зафиксирован факт лишения права управления транспортным средством',
      'транспортное средство находится в розыске',
      'скоринговый балл (ОКБ) имеет низкое значение',
      'имеются установленные законом или уполномоченными органами в предусмотренном законом порядке условия, запрещения, стесняющие правообладателя при осуществлении права собственности либо иных вещных прав на конкретный объект имущества',
      'имеются значительные долговые обязательства',
    ],
  },

  {
    groupName: 'Черные списки',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'найдена запись в списке террористов и экстремистов',
      'найдена запись в черном списке и (или) стоплистах',
      'имеются ограничения участия в юридических лицах',
      'найдена запись в реестре дисквалифицированных лиц',
      'найдена запись в реестре лиц, уволенных в связи с утратой доверия',
      'установлена негативная деловая репутация',
      'Входил в реестр дисквалифицированных лиц',
    ],
  },

  {
    groupName: 'Штрафы ГИБДД',
    statusIcon: SystemIconsNames.triangleExclamation,
    statusIconColor: 'red',
    list: [
      'имеется от 1 до 10 неоплаченных штрафов ГИБДД',
      'имеется свыше 10 неоплаченных штрафов ГИБДД',
      'имелось свыше 10 штрафов ГИБДД',
      'имелось свыше 30 штрафов ГИБДД',
    ],
  },
];
