import { injectable } from 'inversify';
import { ICompanyBranchesMappingService } from '@/modules/dossier/company/common/services/mappings/companyBranchesMappingService.interface';
import { Dictionary } from '@/types/common';
import { TDetailsCompanyBranchesList } from '@/types/companyData/companyData';
import { GetCompanyBranchesStaticsTableData } from '@/modules/dossier/company/common/types/getCompanyBranchesStaticsTableData';

@injectable()
export default class CompanyBranchesMappingService implements ICompanyBranchesMappingService {
  getCompanyBranchesStaticsTableData(groupedBranches: Dictionary<TDetailsCompanyBranchesList[]>) {
    const sortPlaces = ['Севастополь', 'Симферополь', 'Санкт-Петербург', 'Москва'];
    const test = Object.entries(groupedBranches)
      .map(([city, branchesList]) => ({
        city,
        branchCount: `${branchesList.length}`,
      }))
      .reduce(
        (acc, curr) => {
          if (sortPlaces.includes(curr.city)) {
            acc.hightPriority.push(curr);
          } else {
            acc.others.push(curr);
          }
          return acc;
        },
        { hightPriority: [], others: [] } as Record<
          string,
          Array<GetCompanyBranchesStaticsTableData>
        >,
      );
    return [
      ...test.hightPriority.sort((item1, item2) => {
        if (sortPlaces.indexOf(item1.city) > sortPlaces.indexOf(item2.city)) {
          return -1;
        }
        return 1;
      }),
      ...test.others.sort((item1, item2) => {
        if (item1.city < item2.city) {
          return -1;
        }

        return 1;
      }),
    ];
  }
}
