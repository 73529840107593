import DefaultAPIInstance from '@/api/client';
import { AxiosResponse } from 'axios';
import { EventJournal } from '@/modules/eventJournal/types/eventJournal';

const eventLogApi = {
  getData() {
    const url = '/event-log';
    return DefaultAPIInstance.get(url);
  },
  getEventJournalApi(id: string): Promise<AxiosResponse<EventJournal>> {
    const url = 'event-log/event-logs-by-id';
    return DefaultAPIInstance.get(url, {
      params: {
        id,
      },
    });
  },
};

export default eventLogApi;
