import { InjectionKey } from 'vue';
import { UseDxTagConfig } from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';

export interface ICompanyContactsTableService {
  calculatePhoneVerificationCodeCellValue(
    phoneVerificationCode: string,
  ): UseDxTagConfig;

  calculateEmailVerificationCodeCellValue(
    emailVerificationCode: string,
  ): UseDxTagConfig;
}

export const ICompanyContactsTableServiceKey: InjectionKey<ICompanyContactsTableService> = Symbol(
  'ICompanyContactsTableServiceKey',
);
