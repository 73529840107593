import { OkvedConfiguration } from '@/modules/okved/configuration/okvedConfiguration';
import { IOkvedService } from '@/modules/okved/services/okvedService/okvedService.interfaces';
import { OkvedInfo } from '@/modules/okved/types/okvedInfo';
import { RawOkvedInfo } from '@/modules/okved/types/rawOkvedInfo';
import { RawOkvedInfoSection } from '@/modules/okved/types/rawOkvedInfoSection';
import { injectable } from 'inversify';

const sections = OkvedConfiguration.sections;
const defaultSection = OkvedConfiguration.defaultSection;

@injectable()
export default class OkvedService implements IOkvedService {
  getOkvedInfo(code: string): OkvedInfo | undefined {
    const parsedCode = parseInt(code, 10);

    return this.getOkvedInfoInternal(parsedCode);
  }

  getOkvedInfoOrDefault(code?: string): OkvedInfo {
    if(code == undefined)
      return this.getDefaultInfo();

    return this.getOkvedInfo(code) ?? this.getDefaultInfo();
  }

  private getDefaultInfo() {
    return this.buildOkvedInfo(defaultSection, defaultSection.children[0]);
  }

  private getOkvedInfoInternal(code: number): OkvedInfo | undefined {
    for(const section of sections) {
      const info = section.children.find(x => x.code == code);

      if(info != undefined)
        return this.buildOkvedInfo(section, info);
    }
  }

  private buildOkvedInfo(section: RawOkvedInfoSection, info: RawOkvedInfo): OkvedInfo {
    return {
      code: info.code,
      name: info.name,
      sectionName: section.name,
      imageURL: require(`@/modules/okved/assets/okvedImages/${info.imageName}`)
    }
  }
}
