import {
  IRegulationCheckStatusFormatter
} from '@/modules/regulationsCheck/common/services/regulationCheckStatusFormatter/regulationCheckStatusFormatter.interfaces';
import {
  RegulationCheckStatus
} from '@/modules/regulationsCheck/common/types/regulationCheckStatus';
import { injectable } from 'inversify';

@injectable()
export default class RegulationCheckStatusFormatter implements IRegulationCheckStatusFormatter {
  format(status: RegulationCheckStatus): string {
    switch (status) {
      case RegulationCheckStatus.Canceled: return 'Отменено';
      case RegulationCheckStatus.Error: return 'Ошибка';
      case RegulationCheckStatus.Complete: return 'Завершено';
      case RegulationCheckStatus.Progress: return 'В процессе';
      default: return 'Неизвестно';
    }
  }
}
