import { injectable } from 'inversify';
import { ICompanyArbitrationRisksStatisticService } from '@/modules/dossier/company/arbitrations/services/companyArbitrationRisksStatisticService.interface';
import { ArbitrationMarkers } from '@/modules/dossier/company/arbitrations/types/arbitrationMarkers';
import { ArbitrationRisksStatistic } from '@/modules/dossier/company/arbitrations/types/arbitrationRisksStatistic';

@injectable()
export default class CompanyArbitrationRisksStatisticDefendantService
  implements ICompanyArbitrationRisksStatisticService
{
  // eslint-disable-next-line class-methods-use-this
  getArbitrationRisksStatisticData(
    arbitrationMarkers: ArbitrationMarkers,
  ): ArbitrationRisksStatistic {
    return {
      outcomeCasesOverPastThreeYearsClaimSatisfiedInFullPercent:
        arbitrationMarkers.defendantOutcomeCasesOverPastThreeYearsClaimSatisfiedInFullPercent,
      outcomeCasesOverPastThreeYearsClaimPartiallySatisfiedPercent:
        arbitrationMarkers.defendantOutcomeCasesOverPastThreeYearsClaimPartiallySatisfiedPercent,
      outcomeCasesOverPastThreeYearsClaimNotSatisfiedPercent:
        arbitrationMarkers.defendantOutcomeCasesOverPastThreeYearsClaimNotSatisfiedPercent,
      outcomeCasesOverPastThreeYearsDiscontinuedPercent:
        arbitrationMarkers.defendantOutcomeCasesOverPastThreeYearsDiscontinuedPercent,
      outcomeCasesOverPastThreeYearsUndefinedPercent:
        arbitrationMarkers.defendantOutcomeCasesOverPastThreeYearsUndefinedPercent,
      outcomeCasesOverPastThreeYearsUnderReviewPercent:
        arbitrationMarkers.defendantOutcomeCasesOverPastThreeYearsUnderReviewPercent,
    };
  }
}
