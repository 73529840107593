import { ApiElasticField } from '@/modules/elastic/types/api/apiElasticField';
import { ApiElasticRequest } from '@/modules/elastic/types/api/apiElasticRequest';
import { ApiElasticResponse } from '@/modules/elastic/types/api/apiElasticResponse';
import {
  ApiElasticSimpleRequestOptions
} from '@/modules/elastic/types/api/apiElasticSimpleRequestOptions';
import { AxiosResponse } from 'axios';
import { InjectionKey } from 'vue';

export interface IElasticService {
  searchAdvanced(payload: ApiElasticRequest): Promise<AxiosResponse<ApiElasticResponse>>
  search(query: string, fields?: ApiElasticField[], options?: ApiElasticSimpleRequestOptions): Promise<AxiosResponse<ApiElasticResponse>>
}

export const IElasticServiceKey: InjectionKey<IElasticService> = Symbol('IElasticService');
