<template>
  <div v-if="hasNotifications" class="notification-root">
    <component
      :is="notificationItem.component"
      v-for="notificationItem in notifications"
      v-bind="RefUtils.unwrapConfig(notificationItem.config)"
      :key="valueKeys(notificationItem)"
      @close="handleItemClose(notificationItem)"
    />
  </div>
</template>

<script lang="ts" setup>
import { RefUtils } from '@/modules/common/utils/refUtils';
import { useNotificationStore } from '@/modules/notifications/stores/notificationStore';
import { computed, unref } from 'vue';
import { storeToRefs } from 'pinia';
import useCollectionKeys from '@/modules/common/composables/useCollectionKeys';
import { NotificationConfig } from '@/modules/notifications/types/notificationConfig';

const notificationStore = useNotificationStore();
const { notifications } = storeToRefs(notificationStore);
const hasNotifications = computed(() => notifications.value.length !== 0);

const valueKeys = useCollectionKeys(notifications);

function handleItemClose(item: NotificationConfig) {
  notificationStore.clearNotification(item);
}
</script>

<style lang="scss">
.notification-root {
  width: 352px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  bottom: 0;
  right: 0;
  margin: 0 12px 12px 0;
}
</style>
