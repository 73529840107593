import { injectable } from 'inversify';

import { easyReportNames } from '@/configuration/easyReport/easyReportNames';
import { endOfMonth, endOfWeek, isWithinInterval, startOfMonth, startOfWeek } from 'date-fns';
import { ReportTypeInput } from '@/modules/reports/common/types/ReportTypeInput';
import { IMobileReportsHistoryFilterService } from '@/modules/reports/mobile/history/filters/services/mobileReportsHistoryFilterService.interfaces';
import { ReportsHistoryListItem } from '@/modules/reports/mobile/history/general/types/ReportHistoryListItem';
import { ReportHistoryListFilters } from '@/modules/reports/mobile/history/filters/types/ReportHistoryListFilters';
import { ReportDateFilterEnum } from '@/modules/reports/mobile/history/filters/types/ReportDateFilterEnum';

@injectable()
export default class MobileReportsHistoryFilterService
  implements IMobileReportsHistoryFilterService
{
  search(
    listFilters: ReportHistoryListFilters,
    items?: ReportsHistoryListItem[],
  ): ReportsHistoryListItem[] {
    const { query, parentType } = listFilters;
    if (!items) {
      return [];
    }
    let filteredItems = items;

    filteredItems = items.filter((item) => {
      if (
        item.fio?.toLowerCase().includes(query.toLowerCase()) ||
        item.reportTypeFormatted?.toLowerCase().includes(query.toLowerCase()) ||
        item.date?.toLowerCase().includes(query.toLowerCase())
      ) {
        return true;
      }
      return false;
    });

    filteredItems = filteredItems.filter(
      (item) => item.parentType === easyReportNames.easyReportParentTypeNames[parentType],
    );

    filteredItems = this.filterByDate(listFilters.date, filteredItems);
    filteredItems = this.filterByRequisite(listFilters.requisite, filteredItems);

    return filteredItems;
  }

  protected filterByDate(
    date: ReportDateFilterEnum,
    items: ReportsHistoryListItem[],
  ): ReportsHistoryListItem[] {
    let filteredItems: ReportsHistoryListItem[] = items;
    const now = new Date();

    switch (date) {
      case ReportDateFilterEnum.today:
        filteredItems = items?.filter(
          (item) => new Date(item.date).getDate() === new Date().getDate(),
        );
        break;

      case ReportDateFilterEnum.week:
        filteredItems = items?.filter((item) => {
          const start = startOfWeek(now, { weekStartsOn: 1 }); // 1 - это понедельник
          const end = endOfWeek(now, { weekStartsOn: 1 });
          return isWithinInterval(new Date(item.date), { start, end });
        });
        break;

      case ReportDateFilterEnum.month:
        filteredItems = items?.filter((item) => {
          const start = startOfMonth(now);
          const end = endOfMonth(now);

          return isWithinInterval(new Date(item.date), { start, end });
        });
        break;
      default:
        break;
    }
    return filteredItems;
  }

  protected filterByRequisite(
    requisite: ReportTypeInput,
    items: ReportsHistoryListItem[],
  ): ReportsHistoryListItem[] {
    let filteredItems: ReportsHistoryListItem[] = items;
    switch (requisite) {
      case ReportTypeInput.fullName:
        filteredItems = items.filter((item) => item.inputType === ReportTypeInput.fullName);
        break;

      case ReportTypeInput.phone:
        filteredItems = items.filter((item) => item.inputType === ReportTypeInput.phone);
        break;

      case ReportTypeInput.carNumber:
        filteredItems = items.filter((item) => item.inputType === ReportTypeInput.carNumber);
        break;

      case ReportTypeInput.inn:
        filteredItems = items.filter((item) => item.inputType === ReportTypeInput.inn);
        break;

      case ReportTypeInput.photo:
      default:
        break;
    }
    return filteredItems;
  }
}
