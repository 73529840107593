import { injectable } from 'inversify';
import { IAdministrationUsersDataNormalizationService } from '@/modules/administration/users/services/normalization/administrationUsersDataNormalizationService.interfaces';
import { ApiUserData } from '@/modules/administration/users/types/apiUserData';

@injectable()
export default class AdministrationUsersDataNormalizationService
  implements IAdministrationUsersDataNormalizationService
{
  normalize(item: ApiUserData): Required<ApiUserData> {
    return {
      id: item.id,
      email: item.email ?? '',
      firstName: item.firstName ?? '',
      lastName: item.lastName ?? '',
      patronymic: item.patronymic ?? '',
      fullname: item.fullname ?? '',
      company: item.company ?? '',
      department: item.department ?? '',
      position: item.position ?? '',
      isLocked: item.isLocked ?? false,
      isLockedBefore: item.isLockedBefore ?? '',
      roles: item.roles ?? [],
      rolesRus: item.rolesRus ?? [],
      created: item.created ?? '',
      createdStr: item.createdStr ?? '',
      modified: item.modified ?? '',
      modifiedStr: item.modifiedStr ?? '',
    };
  }
}
