import { injectable } from 'inversify';
import { IBooleanNormalizationService } from '@/modules/normalization/services/booleanNormalizationService/booleanNormalizationService.interfaces';
import { isBoolean } from 'lodash';

@injectable()
export default class NumberNormalizationService implements IBooleanNormalizationService {
  normalize(value: any): boolean | undefined {
    if (isBoolean(value)) {
      return value;
    }

    return undefined;
  }
}
