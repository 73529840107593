import { injectable } from 'inversify';
import { IRouteMatcherExactly } from '@/modules/tabs/routeTabs/routeMatchers/routeMatcherExactly.interfaces';
import { RouteTab } from '@/modules/tabs/routeTabs/types/routeTab';
import { RouteLocationNormalizedLoaded } from 'vue-router';

@injectable()
export default class RouteMatcherExactly implements IRouteMatcherExactly {
  public match(tab: RouteTab, route: RouteLocationNormalizedLoaded) {
    return tab.routeName == route.name;
  }
}
