import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { GridOutput } from '@/modules/common/types/api/gridOutput';
import {
  DataGridBatchLoaderService
} from '@/modules/dataGrid/common/services/dataGridBatchLoaderService';
import { ApiGridInput } from '@/modules/dataGrid/common/types/api/apiGridInput';
import {
  ICompanyDataGridBatchLoaderService
} from '@/modules/dataGrid/company/services/companyDataGridBatchLoaderService.interfaces';
import {
  ICompanyDataGridService, ICompanyDataGridServiceKey
} from '@/modules/dataGrid/company/services/companyDataGridService.interfaces';
import { ApiGridUlRecord } from '@/modules/dataGrid/company/types/api/apiGridUlRecord';
import { AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

@injectable()
export default class CompanyDataGridBatchLoaderService extends DataGridBatchLoaderService<ApiGridUlRecord> implements ICompanyDataGridBatchLoaderService {
  constructor(
    @inject(ICompanyDataGridServiceKey as symbol) private companyDataGridService: ICompanyDataGridService
  ) {
    super();
  }

  protected getKey(item: ApiGridUlRecord): any {
    return item.company_original_id;
  }

  protected getItemsInternalAsync(payload: ApiGridInput): Promise<AxiosResponse<ApiResponse<GridOutput<ApiGridUlRecord>>>> {
    return this.companyDataGridService.getContent(payload);
  }
}
