import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import DxPdfExportServiceFactory
  from '@/modules/devExtreme/export/pdf/factories/dxPdfExportServiceFactory/dxPdfExportServiceFactory';
import {
  IDxPdfExportServiceFactoryKey
} from '@/modules/devExtreme/export/pdf/factories/dxPdfExportServiceFactory/dxPdfExportServiceFactory.interfaces';
import {
  IDxPdfCellFormatterKey
} from '@/modules/devExtreme/export/pdf/services/dxPdfCellFormatter/dxPdfCellFormatter.interfaces';
import DxPdfDataCellFormatter
  from '@/modules/devExtreme/export/pdf/services/dxPdfDataCellFormatter/dxPdfDataCellFormatter';
import DxPdfExternalFontsService
  from '@/modules/devExtreme/export/pdf/services/dxPdfExternalFontsService/dxPdfExternalFontsService';
import {
  IDxPdfExternalFontsServiceKey
} from '@/modules/devExtreme/export/pdf/services/dxPdfExternalFontsService/dxPdfExternalFontsService.interfaces';
import DxPdfHeaderCellFormatter
  from '@/modules/devExtreme/export/pdf/services/dxPdfHeaderCellFormatter/dxPdfHeaderCellFormatter';
import DxPdfOrientationToJsPdfOrientationMappingService
  from '@/modules/devExtreme/export/pdf/services/dxPdfOrientationToJsPdfOrientationMappingService/dxPdfOrientationToJsPdfOrientationMappingService';
import {
  IDxPdfOrientationToJsPdfOrientationMappingServiceKey
} from '@/modules/devExtreme/export/pdf/services/dxPdfOrientationToJsPdfOrientationMappingService/dxPdfOrientationToJsPdfOrientationMappingService.interfaces';
import {
  IDxXlsxCellFormatterKey
} from '@/modules/devExtreme/export/xlsx/services/dxXlsxCellFormatter/dxXlsxCellFormatter.interfaces';
import DxXlsxDataCellFormatter
  from '@/modules/devExtreme/export/xlsx/services/dxXlsxDataCellFormatter/dxXlsxDataCellFormatter';
import DxXlsxExportService
  from '@/modules/devExtreme/export/xlsx/services/dxXlsxExportService/dxXlsxExportService';
import {
  IDxXlsxExportServiceKey
} from '@/modules/devExtreme/export/xlsx/services/dxXlsxExportService/dxXlsxExportService.interfaces';
import DxXlsxHeaderCellFormatter
  from '@/modules/devExtreme/export/xlsx/services/dxXlsxHeaderCellFormatter/dxXlsxHeaderCellFormatter';
import DxXlsxWorksheetColumnsAligner
  from '@/modules/devExtreme/export/xlsx/services/dxXlsxWorksheetColumnsAligner/dxXlsxWorksheetColumnsAligner';
import {
  IDxXlsxWorksheetColumnsAlignerKey
} from '@/modules/devExtreme/export/xlsx/services/dxXlsxWorksheetColumnsAligner/dxXlsxWorksheetColumnsAligner.interfaces';
import DxDateComparatorService
  from '@/modules/devExtreme/services/dxDateComparatorService/dxDateComparatorService';
import {
  IDxDateComparatorServiceKey
} from '@/modules/devExtreme/services/dxDateComparatorService/dxDateComparatorService.interfaces';
import DxSearchFilterFactory
  from '@/modules/devExtreme/services/factories/dxSearchFilterFactory';
import {
  IDxSearchFilterFactoryKey
} from '@/modules/devExtreme/services/factories/dxSearchFilterFactory.interface';
import DxStringsNormalizationService
  from '@/modules/devExtreme/services/dxStringsNormalizationService';
import {
  IDxStringsNormalizationServiceKey
} from '@/modules/devExtreme/services/dxStringsNormalizationService.interfaces';
import DxTableExtendedColumnToDxTableColumnInfoMapperService
  from '@/modules/devExtreme/services/mappers/dxTableExtendedColumnToDxTableColumnInfoMapperService';
import {
  IDxTableExtendedColumnToDxTableColumnInfoMapperServiceKey
} from '@/modules/devExtreme/services/mappers/dxTableExtendedColumnToDxTableColumnInfoMapperService.interfaces';
import DxDataSourceFiltDxDataSourceFilterFactoryerFactoryKey from '@/modules/devExtreme/components/UITableDx/factories/dxDataSourceFilterFactory';
import { IDxDataSourceFilterFactoryKey } from '@/modules/devExtreme/components/UITableDx/factories/dxDataSourceFilterFactory.interfaces';

export function bindDxServices(bind: IContainerBinder) {
  bind(IDxStringsNormalizationServiceKey).to(DxStringsNormalizationService);
  bind(IDxSearchFilterFactoryKey).to(DxSearchFilterFactory);
  bind(IDxTableExtendedColumnToDxTableColumnInfoMapperServiceKey).to(DxTableExtendedColumnToDxTableColumnInfoMapperService);
  bind(IDxXlsxExportServiceKey).to(DxXlsxExportService);
  bind(IDxXlsxCellFormatterKey).to(DxXlsxDataCellFormatter);
  bind(IDxXlsxCellFormatterKey).to(DxXlsxHeaderCellFormatter);
  bind(IDxXlsxWorksheetColumnsAlignerKey).to(DxXlsxWorksheetColumnsAligner);
  bind(IDxPdfCellFormatterKey).to(DxPdfDataCellFormatter);
  bind(IDxPdfCellFormatterKey).to(DxPdfHeaderCellFormatter);
  bind(IDxPdfExternalFontsServiceKey).to(DxPdfExternalFontsService);
  bind(IDxPdfExportServiceFactoryKey).to(DxPdfExportServiceFactory);
  bind(IDxPdfOrientationToJsPdfOrientationMappingServiceKey).to(DxPdfOrientationToJsPdfOrientationMappingService);
  bind(IDxDateComparatorServiceKey).to(DxDateComparatorService);
  bind(IDxDataSourceFilterFactoryKey).to(DxDataSourceFiltDxDataSourceFilterFactoryerFactoryKey);
}
