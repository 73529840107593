import { InjectionKey } from 'vue';
import { EnforcementProceedings } from '@/modules/dossier/company/enforcementProceedings/types/enforcementProceedings';
import { EnforcementProceedingsStatisticsTableRow } from '@/modules/dossier/company/enforcementProceedings/types/enforcementProceedingsStatisticsTableRow';
import { EnforcementProceedingsDataTableRow } from '@/modules/dossier/company/enforcementProceedings/types/enforcementProceedingsDataTableRow';

export interface ICompanyEnforcementProceedingsMappingService {
  mapEnforcementProceedingsStatisticsTable(
    epList: Array<EnforcementProceedings>,
  ): Array<EnforcementProceedingsStatisticsTableRow>;
  mapEnforcementProceedingsDataTable(
    epList: Array<EnforcementProceedings>,
  ): Array<EnforcementProceedingsDataTableRow>;
}

export const ICompanyEnforcementProceedingsMappingServiceKey: InjectionKey<ICompanyEnforcementProceedingsMappingService> =
  Symbol('ICompanyEnforcementProceedingsMappingServiceKey');
