import { injectable } from 'inversify';
import { ApiPersonDetailEnforcementProceeding } from '@/modules/dossier/person/common/types/api/apiPersonDetailEnforcementProceeding';
import { IPersonEnforcementProceedingsStatisticsService } from '@/modules/dossier/person/enforcementProceedings/services/personEnforcementProceedingsStatisticsService.interface';

@injectable()
export default class PersonEnforcementProceedingsStatisticsService
  implements IPersonEnforcementProceedingsStatisticsService
{
  calculateActiveEPAmount(epList: Array<ApiPersonDetailEnforcementProceeding>): number {
    return epList.filter((ep) => ep.status != 'Погашено').length;
  }

  calculateActiveEPSum(epList: Array<ApiPersonDetailEnforcementProceeding>): number {
    return epList.reduce((acc, ep) => {
      if (ep.status != 'Погашено') {
        acc += +ep.amount_owed;
      }
      return acc;
    }, 0);
  }
}
