import {
  DaDataAddressSuggestionsResponse
} from '@/modules/dadata/types/api/dadataAddressSuggestionsResponse';
import { AxiosResponse } from 'axios';
import { InjectionKey } from 'vue';

export interface IDaDataService {
  getAddressSuggestions(query: string, quantityOfResponses?: number): Promise<AxiosResponse<DaDataAddressSuggestionsResponse>>
}

export const IDaDataServiceKey: InjectionKey<IDaDataService> = Symbol('IDaDataService');
