import { inject, injectable } from 'inversify';
import { IPassportSeriesValidationService } from '@/modules/validation/services/passportSeriesValidationService.interface';
import {
  IMasksService,
  IMasksServiceKey,
} from '@/modules/masks/services/masksService/masksService.interfaces';
import { passportSeriesMask } from '@/modules/validation/configuration/masks/passportSeriesMask';

@injectable()
export default class PassportSeriesValidationService implements IPassportSeriesValidationService {
  constructor(@inject(IMasksServiceKey as symbol) private masksService: IMasksService) {}

  validate(value: string): boolean {
    return this.masksService.isCompleted(value, passportSeriesMask);
  }
}
