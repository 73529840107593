export module NumbersUtils {
  export function parseOptionalNumber(value: string | null | undefined): number | undefined {
    if (value != undefined) {
      const result = parseFloat(value);

      if (!Number.isNaN(result)) {
        return result;
      }
    }

    return undefined;
  }

  export function getNumberPercent(value: number, percent: number): number {
    const onePercentHeight = value / 100;

    return onePercentHeight * percent;
  }

  export function separateByThreeDigits(originalNumber: string | number): string {
    return Math.trunc(Number(originalNumber)).toLocaleString('ru-RU');
  }

  export function isNumber(value: any): value is number {
    return typeof value == 'number';
  }

  export function isNumeric(value: any): boolean {
    return Number(value) == value;
  }

  export function convertPercentToNumber(value: string): number {
    return Number(value.replace('%', ''));
  }
}
