import { InjectionKey } from 'vue';
import { ApiPersonDetailVkRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecord';
import { PersonSocialNetworkAdditionalInfoVKRecordKeys } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKRecordKeys';
import { PersonSocialNetworkAdditionalInfoDataItem } from '@/modules/dossier/person/socialNetworkAdditionalInfo/common/types/personSocialNetworkAdditionalInfoDataItem';

export interface IPersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter {
  getPersonSocialNetworkVKMainInfoFormattedDataByKeys(
    detailVkRecord: ApiPersonDetailVkRecord,
    detailVkRecordKeys: Array<PersonSocialNetworkAdditionalInfoVKRecordKeys>,
  ): Array<PersonSocialNetworkAdditionalInfoDataItem>;
}

export const IPersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatterKey: InjectionKey<IPersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter> =
  Symbol('IPersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatterKey');
