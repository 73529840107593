import RouteRecord from '@/modules/router/common/types/routeRecord';
import { RouteRecordName } from 'vue-router';
import RouteNames from '@/modules/router/common/types/routeNames';
import Middleware from '@/modules/router/common/types/middleware';
import MiddlewareContext from '@/modules/router/common/types/middlewareContext';
import MiddlewareResult from '@/modules/router/common/types/middlewareResult';
import { DevicePlatformTypes } from '@/types/devicePlatform';

export module RouterUtils {
  export function flattenRoutes(routeRecords: RouteRecord[]): RouteRecord[] {
    return routeRecords.flatMap((x) => [x, ...flattenRoutes(x.children ?? [])]);
  }

  export function matchesName(route: RouteRecord, name: RouteRecordName | null | undefined) {
    return route.name == name;
  }

  export function getHomePageName(platform: DevicePlatformTypes): RouteNames {
    switch (platform) {
      case DevicePlatformTypes.MOBILE:
        return RouteNames.MOBILE_REPORTS_HISTORY;

      case DevicePlatformTypes.TABLET:
      case DevicePlatformTypes.DESKTOP:
      default:
        return RouteNames.HOME;
    }
  }

  export function composeMiddleware(context: MiddlewareContext, middlewares: Middleware[]) {
    return middlewares.reduce(
      (result, middleware) => result ?? middleware(context),
      <MiddlewareResult>undefined,
    );
  }

  export function checkAccess(platform: DevicePlatformTypes, routeRecord: RouteRecord) {
    if (platform != DevicePlatformTypes.MOBILE || routeRecord.name == RouteNames.AUTH_PAGE) {
      return true;
    }

    if (routeRecord.access == undefined) {
      return false;
    }

    return routeRecord.access();
  }
}
