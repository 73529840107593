import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import NumberNormalizationService
  from '@/modules/normalization/services/numberNormalizationService/numberNormalizationService';
import {
  INumberNormalizationServiceKey
} from '@/modules/normalization/services/numberNormalizationService/numberNormalizationService.interfaces';
import StringNormalizationService from '@/modules/normalization/services/stringNormalizationService';
import { IStringNormalizationServiceKey } from '@/modules/normalization/services/stringNormalizationService.interfaces';
import IBooleanNormalizationService from '@/modules/normalization/services/booleanNormalizationService/booleanNormalizationService';
import { IBooleanNormalizationServiceKey } from '@/modules/normalization/services/booleanNormalizationService/booleanNormalizationService.interfaces';
import AddressNormalizationService
  from "@/modules/normalization/services/addressNormalizationService/addressNormalizationService";
import {
  IAddressNormalizationServiceKey
} from "@/modules/normalization/services/addressNormalizationService/addressNormalizationService.interfaces";

export function bindNormalizationServices(bind: IContainerBinder) {
  bind(IStringNormalizationServiceKey).to(StringNormalizationService);
  bind(INumberNormalizationServiceKey).to(NumberNormalizationService);
  bind(IBooleanNormalizationServiceKey).to(IBooleanNormalizationService);
  bind(IAddressNormalizationServiceKey).to(AddressNormalizationService);
}
