import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import AnalysisRelationClusterTypeFormatterService
  from '@/modules/analysis/services/analysisRelationClusterTypeFormatterService';
import {
  IAnalysisRelationClusterTypeFormatterServiceKey
} from '@/modules/analysis/services/analysisRelationClusterTypeFormatterService.interfaces';
import AnalysisRelationTypeFormatterService
  from '@/modules/analysis/services/analysisRelationTypeFormatterService';
import {
  IAnalysisRelationTypeFormatterServiceKey
} from '@/modules/analysis/services/analysisRelationTypeFormatterService.interfaces';
import AnalysisRelationTypesClusterizationService
  from '@/modules/analysis/services/analysisRelationTypesClusterizationService';
import {
  IAnalysisRelationTypesClusterizationServiceKey
} from '@/modules/analysis/services/analysisRelationTypesClusterizationService.interfaces';
import CompanyListToAnalysisEntityMapper
  from '@/modules/analysis/services/mappers/companyListToAnalysisEntityMapper';
import {
  ICompanyListToAnalysisEntityMapperKey
} from '@/modules/analysis/services/mappers/companyListToAnalysisEntityMapper.interfaces';
import GridCompanyToAnalysisEntityMapper
  from '@/modules/analysis/services/mappers/gridCompanyToAnalysisEntityMapper';
import {
  IGridCompanyToAnalysisEntityMapperKey
} from '@/modules/analysis/services/mappers/gridCompanyToAnalysisEntityMapper.interfaces';
import GridPersonToAnalysisEntityMapper
  from '@/modules/analysis/services/mappers/gridPersonToAnalysisEntityMapper';
import {
  IGridPersonToAnalysisEntityMapperKey
} from '@/modules/analysis/services/mappers/gridPersonToAnalysisEntityMapper.interfaces';
import PersonListToAnalysisEntityMapper
  from '@/modules/analysis/services/mappers/personListToAnalysisEntityMapper';
import {
  IPersonListToAnalysisEntityMapperKey
} from '@/modules/analysis/services/mappers/personListToAnalysisEntityMapper.interfaces';

export function bindAnalysisService(bind: IContainerBinder) {
  bind(IGridPersonToAnalysisEntityMapperKey).to(GridPersonToAnalysisEntityMapper);
  bind(IGridCompanyToAnalysisEntityMapperKey).to(GridCompanyToAnalysisEntityMapper);
  bind(IPersonListToAnalysisEntityMapperKey).to(PersonListToAnalysisEntityMapper);
  bind(ICompanyListToAnalysisEntityMapperKey).to(CompanyListToAnalysisEntityMapper);
  bind(IAnalysisRelationTypeFormatterServiceKey).to(AnalysisRelationTypeFormatterService);
  bind(IAnalysisRelationTypesClusterizationServiceKey).to(AnalysisRelationTypesClusterizationService);
  bind(IAnalysisRelationClusterTypeFormatterServiceKey).to(AnalysisRelationClusterTypeFormatterService);
}
