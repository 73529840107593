import { InjectionKey } from 'vue';
import { ManualImportCreateCompanyModel } from '@/modules/import/types/company/manualImportCreateCompanyModel';
import { ApiManualImportCreateCompany } from '@/modules/import/types/api/company/apiManualImportCreateCompany';

export interface IManualImportCreateCompanyModelMapper {
  map(item: ManualImportCreateCompanyModel): ApiManualImportCreateCompany;
}

export const IManualImportCreateCompanyModelMapperKey: InjectionKey<IManualImportCreateCompanyModelMapper> =
  Symbol('IManualImportCreateCompanyModelMapper');
