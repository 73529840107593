import { InjectionKey } from 'vue';
import { CompanyCertificates } from '@/types/companyData/CompanyCertificates';
import { CompanyCertificateTypes } from '@/types/companyData/companyCertificatesTypes';

export interface ICompanyCertificatesService {
  calculateCountUniqCertificatesByType(
    type: CompanyCertificateTypes,
    certificates: Array<CompanyCertificates>,
  ): number;
  calculateCountUniqActualCertificatesByType(
    type: CompanyCertificateTypes,
    certificates: Array<CompanyCertificates>,
  ): number;
}

export const ICompanyCertificatesServiceKey: InjectionKey<ICompanyCertificatesService> = Symbol(
  'ICompanyCertificatesService',
);
