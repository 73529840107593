import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IDriverLicenseSignDeprivationMapperKey } from '@/modules/dossier/person/vehicles/driverLicenses/services/mappers/personDriverLicenseSignDeprivationMapper.interfaces';
import PersonDriverLicenseSignDeprivationMapper from '@/modules/dossier/person/vehicles/driverLicenses/services/mappers/personDriverLicenseSignDeprivationMapper';
import { IDriverLicenseStatusDocumentMapperKey } from '@/modules/dossier/person/vehicles/driverLicenses/services/mappers/personDriverLicenseStatusDocumentMapper.interfaces';
import { IDriverLicenseStatusDeprivationMapperKey } from '@/modules/dossier/person/vehicles/driverLicenses/services/mappers/personDriverLicenseStatusDeprivationMapper.interfaces';
import PersonDriverLicenseStatusDocumentMapper from '@/modules/dossier/person/vehicles/driverLicenses/services/mappers/personDriverLicenseStatusDocumentMapper';
import PersonDriverLicenseStatusDeprivationMapper from '@/modules/dossier/person/vehicles/driverLicenses/services/mappers/personDriverLicenseStatusDeprivationMapper';

export function bindPersonDriverLicenseServices(bind: IContainerBinder) {
  bind(IDriverLicenseStatusDocumentMapperKey).to(PersonDriverLicenseStatusDocumentMapper);
  bind(IDriverLicenseStatusDeprivationMapperKey).to(PersonDriverLicenseStatusDeprivationMapper);
  bind(IDriverLicenseSignDeprivationMapperKey).to(PersonDriverLicenseSignDeprivationMapper);
}
