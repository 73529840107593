import {
  IRegulationChecksHistoryGroupingService
} from '@/modules/regulationsCheck/common/services/regulationChecksHistoryGroupingService/regulationChecksHistoryGroupingService.interfaces';
import {
  RegulationsCheckDecision
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckDecision';
import {
  RegulationsCheckRootType
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckRootType';
import {
  RegulationChecksGroupedHistoryItemDTOs
} from '@/modules/regulationsCheck/common/types/dto/regulationChecksGroupedHistoryItemDTOs';
import {
  RegulationsChecksHistoryItemDTO
} from '@/modules/regulationsCheck/common/types/dto/regulationChecksHistoryItemDTO';
import { injectable } from 'inversify';
import { last, sortBy } from 'lodash';

function isSubItem(group: RegulationChecksGroupedHistoryItemDTOs | undefined, item: RegulationsChecksHistoryItemDTO) {
  if (group == undefined) return false;

  const { root } = group;

  return root.rootType == RegulationsCheckRootType.Manual &&
    item.rootType == RegulationsCheckRootType.Auto &&
    root.generationEnd != undefined && root.generationEnd >= item.created;
}

@injectable()
export default class RegulationChecksHistoryGroupingService implements IRegulationChecksHistoryGroupingService {
  group(items: RegulationsChecksHistoryItemDTO[]): RegulationChecksGroupedHistoryItemDTOs[] {
    if (items.length == 0)
      return [];

    const filteredChecks = items.filter(x =>
      x.rootType != RegulationsCheckRootType.Manual || x.decision != RegulationsCheckDecision.Unknown);

    const sortedChecks = sortBy(filteredChecks, x => x.created);

    return sortedChecks.reduce((result, item) => {
      let group = last(result);

      if(group == undefined || !isSubItem(group, item)) {
        result.push({
          root: item,
          items: []
        });

        return result;
      }

      group.items.push(item);

      return result;
    }, new Array<RegulationChecksGroupedHistoryItemDTOs>());
  }
}
