import { injectable, inject } from 'inversify';
import { ICurrencyParserService } from '@/modules/currency/services/сurrencyParserService/сurrencyParserService.interfaces';
import { CurrencyParsingResult } from '@/modules/currency/types/currencyParsingResult';
import { ICurrencyTagParserServiceKey, ICurrencyTagParserService } from '@/modules/currency/services/currencyTagParserService/currencyTagParserService.interfaces';
import { INumberNormalizationServiceKey, INumberNormalizationService } from '@/modules/normalization/services/numberNormalizationService/numberNormalizationService.interfaces';

@injectable()
export default class CurrencyParserService implements ICurrencyParserService {
  constructor(
    @inject(ICurrencyTagParserServiceKey as symbol) private currencyTagParserService: ICurrencyTagParserService,
    @inject(INumberNormalizationServiceKey as symbol) private numberNormalizationService: INumberNormalizationService
  ) {
  }

  parse(value: string): CurrencyParsingResult {
    const [, rawValue = '', rawTag = ''] = /([\d.,-_\s ]+)(.+)/g.exec(value) ?? [];
    const numberNormalization = this.numberNormalizationService.normalize(rawValue);
    const parsedTag = this.currencyTagParserService.parse(rawTag);

    return {
      rawValue: rawValue,
      value: numberNormalization,
      tag: parsedTag,
      rawTag: rawTag,
    }
  }
}
