<template>
  <div class="ui-circular-progress" :class="circularProgressModifers">
    <div v-if="enableLabel" class="ui-circular-progress__backdrop">
      {{ progressLabel }}
    </div>

    <svg class="ui-circular-progress__progress-bar" :class="progressBarModifers">
      <linearGradient
        v-for="theme in UICircularProgressConfiguration.linearGradient"
        :id="theme.id"
        :key="theme.id"
        :x1="theme.x1"
        :y1="theme.y1"
        :x2="theme.x2"
        :y2="theme.y2"
      >
        <stop
          v-for="color in theme.colors"
          :key="color.offset"
          :offset="color.offset"
          :stop-color="color.color"
        />
      </linearGradient>

      <circle
        :r="circularProgressRadius"
        cx="50%"
        cy="50%"
        class="ui-circular-progress__progress-path"
      />

      <circle
        :r="circularProgressRadius"
        cx="50%"
        cy="50%"
        :stroke="strokeTheme"
        :stroke-width="strokeWidth"
        :stroke-dashoffset="strokeDashoffset"
        :stroke-dasharray="strokeDasharray"
        transform="rotate(180)"
        class="ui-circular-progress__progress"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import clamp from 'lodash/clamp';
import { UICircularProgressTheme } from '@/modules/progress/components/UICircularProgress/types/uiCircularProgressTheme';
import { UICircularProgressSize } from '@/modules/progress/components/UICircularProgress/types/uiCircularProgressSize';
import { UICircularProgressConfiguration } from '@/modules/progress/components/UICircularProgress/configuration/uiCircularProgressConfiguration';

type Props = {
  value: number;
  enableLabel?: boolean;
  theme?: UICircularProgressTheme;
  size?: UICircularProgressSize;
};

const props = withDefaults(defineProps<Props>(), {
  enableLabel: false,
  theme: 'primary',
  size: 'size-5',
});

const minValue = 0;
const maxValue = 100;
const progress = computed(() => clamp(props.value, minValue, maxValue));
const progressLabel = computed(() => `${progress.value}%`);

const circularProgressRadius = computed(() => {
  switch (props.size) {
    case 'size-1':
      return 24;
    case 'size-2':
      return 28;
    case 'size-3':
      return 36;
    case 'size-4':
      return 52;
    case 'size-5':
    default:
      return 88;
  }
});

const circularProgressModifers = computed(() => {
  switch (props.size) {
    case 'size-1':
      return 'ui-circular-progress--size-1';
    case 'size-2':
      return 'ui-circular-progress--size-2';
    case 'size-3':
      return 'ui-circular-progress--size-3';
    case 'size-4':
      return 'ui-circular-progress--size-4';
    case 'size-5':
    default:
      return 'ui-circular-progress--size-5';
  }
});

const progressBarModifers = computed(() => ({
  'ui-circular-progress__progress-bar--labeled': props.enableLabel,
}));

const strokeDasharray = computed(() => 2 * Math.PI * circularProgressRadius.value);
const strokeDashoffset = computed(() => ((100 - progress.value) / 100) * strokeDasharray.value);

const strokeWidth = computed(() => {
  switch (props.size) {
    case 'size-1':
    case 'size-2':
    case 'size-3':
      return 6;
    case 'size-4':
      return 8;
    case 'size-5':
    default:
      return 16;
  }
});

const strokeTheme = computed(() => {
  switch (props.theme) {
    case 'blue':
      return 'url(#blueTheme)';
    case 'gray':
      return 'url(#grayTheme)';
    case 'green':
      return 'url(#greenTheme)';
    case 'red':
      return 'url(#redTheme)';
    case 'primary':
    default:
      return 'url(#primaryTheme)';
  }
});
</script>

<style lang="scss">
.ui-circular-progress {
  font-weight: var(--font-weight-450);
  color: var(--color-gray-1);

  &.ui-circular-progress--size-1 {
    font-size: var(--font-size-10);
    line-height: var(--font-line-height-14);
    padding: 7px;
    height: 48px;
    width: 48px;
    margin: 3px;
  }

  &.ui-circular-progress--size-2 {
    font-size: var(--font-size-12);
    line-height: var(--font-line-height-16);
    padding: 8px;
    height: 56px;
    width: 56px;
    margin: 3px;
  }

  &.ui-circular-progress--size-3 {
    font-size: var(--font-size-14);
    line-height: var(--font-line-height-20);
    padding: 8px;
    height: 72px;
    width: 73px;
    margin: 3px;
  }

  &.ui-circular-progress--size-4 {
    font-size: var(--font-size-16);
    line-height: var(--font-line-height-20);
    padding: 12px;
    height: 104px;
    width: 104px;
    margin: 4px;
  }

  &.ui-circular-progress--size-5 {
    font-size: var(--font-size-24);
    line-height: var(--font-line-height-32);
    padding: 20px;
    height: 176px;
    width: 176px;
    margin: 8px;
  }
}

.ui-circular-progress__backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--color-white-8);
  box-shadow: var(--shadow-3);
  border-radius: var(--border-radius-100);
}

.ui-circular-progress__progress-bar {
  width: 100%;
  height: 100%;
  overflow: visible !important;
}

.ui-circular-progress__progress-bar--labeled {
  position: relative;
  top: -100%;
}

.ui-circular-progress__progress-path {
  stroke-width: 1px;
  stroke: var(--color-blue-2);
  fill: none;
}

.ui-circular-progress__progress {
  stroke-linecap: round;
  fill: none;
  transform-origin: center;
  transition: stroke-dashoffset var(--transition-1);
}
</style>
