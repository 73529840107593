import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IPersonRoadAccidentsMappingServiceKey } from '@/modules/dossier/person/roadAccidents/services/personRoadAccidentsMappingService.interface';
import PersonRoadAccidentsMappingService from '@/modules/dossier/person/roadAccidents/services/personRoadAccidentsMappingService';
import { IPersonRoadAccidentsStatisticServiceKey } from '@/modules/dossier/person/roadAccidents/services/personRoadAccidentsStatisticService.interface';
import PersonRoadAccidentsStatisticService from '@/modules/dossier/person/roadAccidents/services/personRoadAccidentsStatisticService';

export function bindPersonRoadAccidentsServices(bind: IContainerBinder) {
  bind(IPersonRoadAccidentsMappingServiceKey).to(PersonRoadAccidentsMappingService);
  bind(IPersonRoadAccidentsStatisticServiceKey).to(PersonRoadAccidentsStatisticService);
}
