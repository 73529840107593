import VXETable from 'vxe-table';
import i18n from '@/modules/graph/i18n';
import 'vxe-table/lib/style.css';

VXETable.setup({
  loadingText: 'Загрузка...',
  table: {
    border: false,
    emptyText: 'Нет данных',
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  i18n: (key, args) => i18n.global.t(key, args),
});

export const useTable = (app: any) => {
  app.use(VXETable);
};
