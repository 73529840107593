import { InjectionKey } from 'vue';
import { PersonMentionTableItem } from '@/modules/dossier/person/mentions/types/personMentionTableItem';
import { ApiPersonDetailMentions } from '@/modules/dossier/person/common/types/api/apiPersonDetailMentions';

export interface IPersonMentionsMappingService {
  mapPersonMentionsDataTable(mentions: Array<ApiPersonDetailMentions>): PersonMentionTableItem[];
}

export const IPersonMentionsMappingServiceKey: InjectionKey<IPersonMentionsMappingService> = Symbol(
  'IPersonMentionsMappingServiceKey',
);
