import { UISnackBarStatus } from '@/modules/snacks/UISnackBar/types/UISnackBarStatus';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';

export module SnackBarUtils {
  export function getIconNameByStatus(value: UISnackBarStatus): SystemIconsNames {
    switch (value) {
      case 'success':
        return SystemIconsNames.snackBarSuccess;
      case 'error':
        return SystemIconsNames.snackBarError;
      case 'warning':
        return SystemIconsNames.snackBarInfo;
      case 'info':
      default:
        return SystemIconsNames.snackBarInfo;
    }
  }
}
