import { IPeoplePlaceholderService } from '@/modules/dossier/person/common/services/peoplePlaceholderService.interfaces';
import { ApiPersonDetail } from '@/modules/dossier/person/common/types/api/apiPersonDetail';
import { HumanGenders } from '@/modules/dossier/person/common/types/api/numanGender';
import { injectable } from 'inversify';
import { ApiIndividualTypes } from '@/modules/dossier/person/common/types/api/apiIndividualTypes';

@injectable()
export default class PeoplePlaceholderService implements IPeoplePlaceholderService {
  getPersonPlaceholder(): ApiPersonDetail {
    return {
      main: {
        id: '',
        actualAddress: '',
        actualAddressCity: '',
        birthCity: '',
        driverLicenceExpireDateStr: '',
        gender: HumanGenders.unknown,
        driverLicenceIssueDateStr: '',
        driverLicenceIssuePlace: '',
        driverLicenceNumder: '',
        driverLicenceSeria: '',
        isSelfEmployed: false,
        isMilitaryDutyStr: '',
        isWorkingStr: '',
        maritalStatusRaw: '',
        maritalStatusType: '',
        militaryIdNumber: '',
        militaryIdNumberExpireDateStr: '',
        personTags: [],
        militaryIdNumberIssueDateStr: '',
        militaryIdNumberIssuePlace: '',
        militaryIdSeria: '',
        militaryRecords: {
          list: [],
        },
        nationality: '',
        passportForeignExpireDateStr: '',
        passportForeignIssueDateStr: '',
        passportForeignIssuePlace: '',
        passportForeignNumber: '',
        passportForeignSeria: '',
        registryAddress: '',
        registryAddressCity: '',
        resultFiles: {
          resultFileXlsxUrl: '',
        },
        snils: '',
        selfEmployedText: '',
        snilsFormatted: '',
        picture: '',
        rate: 0,
        rateOrangeLimit: 0,
        rateRedLimit: 0,
        lastName: '',
        firstName: '',
        patronymic: '',
        individualTypeStr: ApiIndividualTypes.unknown,
        birthdayStr: '',
        contractType: '',
        note: '',
        fios: {
          list: [],
        },
        genderRus: '',
        lastUpdate: '',
        birthPlace: '',
        citizenship: '',
        passportRusListStr: '',
        mainPhoto: {
          id: '',
          parentRelationId: '',
          parentRelationType: 0,
          fileName: '',
          filenameFull: '',
          comment: '',
          created: '',
          createdStr: '',
          isUrl: false,
          url: '',
          downloadLink: '',
        },
        createdStr: '',
      },
      av100: {
        list: [],
      },
      arbitration: {
        list: [],
      },
      bankruptcyStory: {
        bankruptcies: {
          list: [],
        },
        stagesBankruptcy: {
          list: [],
        },
        messagesAboutBankruptcy: {
          list: [],
        },
      },
      birthplaces: {
        list: [],
      },
      boundPhotoLinks: {
        list: [],
      },
      cronos: {
        list: [],
      },
      deathInfos: {
        list: [],
      },
      disqualifiedPersonChecks: {
        list: [],
      },
      driverLicenses: {
        list: [],
      },
      enforcementProceedings: {
        list: [],
      },
      idx: {
        list: [],
      },
      fines: {
        list: [],
        summery: '',
      },
      enforcementProceedingsSummaryData: {
        list: [],
      },
      financialScorings: {
        list: [],
      },
      fioPhoneBondVerifications: {
        list: [],
      },
      hasCounterpartyRelations: false,
      individualTypes: {
        list: [],
      },
      mvd: {
        list: [],
      },
      innPassportAccordanceChecks: {
        list: [],
      },
      konturFocus: {
        list: [],
      },
      messageBoards: {
        list: [],
      },
      messengers: {
        list: [],
      },
      movements: {
        list: [],
      },
      nalog: {
        list: [],
      },
      nationalities: {
        list: [],
      },
      negativeCriminals: {
        list: [],
      },
      negativeDebts: {
        list: [],
      },
      negativeWanteds: {
        list: [],
      },
      nominalChiefChecks: {
        list: [],
      },
      passportComplexChecks: {
        list: [],
      },
      personalData: {
        list: [],
      },
      payments: {
        list: [],
      },
      personDebts: {
        list: [],
      },
      pledges: {
        list: [],
      },
      personalConsentAgreementFiles: {
        list: [],
      },
      relatedPersons: {
        list: [],
      },
      realty: {
        list: [],
      },
      pledgesRegistries: {
        list: [],
      },
      schools: {
        list: [],
      },
      roadAccidents: {
        list: [],
      },
      socialNetworkAdditionalInfo: {
        socialNetworkAboutInfos: {
          list: [],
        },
        socialNetworkActivity: {
          list: [],
        },
        socialNetworkDomain: {
          list: [],
        },
        socialNetworkFamilyStatus: {
          list: [],
        },
        socialNetworkFavoriteBook: {
          list: [],
        },
        socialNetworkFavoriteFilm: {
          list: [],
        },
        socialNetworkFavoriteGame: {
          list: [],
        },
        socialNetworkFavoriteMusic: {
          list: [],
        },
        socialNetworkFavoriteQuote: {
          list: [],
        },
        socialNetworkInterests: {
          list: [],
        },
        socialNetworkStatus: {
          list: [],
        },
        socialNetworkTelevisionProgram: {
          list: [],
        },
      },
      terroristInfos: {
        list: [],
      },
      trustScorings: {
        list: [],
      },
      vehicleParkings: {
        list: [],
      },
      vehicleRestricts: {
        list: [],
      },
      xneo: {
        list: [],
      },
      wantedCars: {
        list: [],
      },
      jobs: {
        displayJobs: [],
        list: [],
      },
      birthdays: {
        list: [
          {
            credibility: 0,
            id: '',
            valueStr: '',
            value: '',
          },
        ],
      },
      snilses: {
        list: [],
      },
      inns: {
        list: [
          {
            value: '',
            isValid: false,
            id: '',
          },
        ],
      },
      mentions: {
        list: [],
      },
      vehicles: {
        list: [],
      },
      socialFriends: {
        list: [],
      },
      businessTrips: {
        list: [],
      },
      education: { list: [] },
      negativeInfo: {
        list: [],
      },
      passports: {
        list: [],
      },
      avias: {
        list: [],
      },
      addresses: {
        list: [],
      },
      socialNetworks: {
        list: [],
      },
      affiliates: {
        list: [],
      },
      legalEntitiesParticipation: {
        list: [],
      },
      individualEntrepreneurs: {
        list: [],
      },
      family: {
        list: [],
      },
      contacts: {
        list: [],
      },
      files: {
        list: [],
      },
      rateParameters: {
        list: [],
      },
      ok: {
        list: [],
        infoExist: false,
      },
      vk: {
        list: [],
        is_avatars_exist: false,
        is_commentators_exist: false,
        is_companies_exist: false,
        is_followers_exist: false,
        is_friends_exist: false,
        is_likers_exist: false,
        is_mentions_exist: false,
        is_military_units_exist: false,
        is_photos_exist: false,
        is_relatives_exist: false,
        is_schools_exist: false,
        is_tagged_exist: false,
        is_universities_exist: false,
        is_who_tagged_exist: false,
      },
    };
  }
}
