import { inject, injectable } from 'inversify';
import { IPersonInnValidationService } from '@/modules/validation/services/personInnValidationService.interface';
import {
  IMasksService,
  IMasksServiceKey,
} from '@/modules/masks/services/masksService/masksService.interfaces';
import {
  personInnLength,
  personInnMask,
} from '@/modules/validation/configuration/masks/personInnMask';

@injectable()
export default class PersonInnValidationService implements IPersonInnValidationService {
  constructor(@inject(IMasksServiceKey as symbol) private masksService: IMasksService) {}

  validate(value: string): boolean {
    if (!this.masksService.isCompleted(value, personInnMask)) {
      return false;
    }

    return value.length == personInnLength;
  }
}
