import { injectable } from 'inversify';
import { IPhoneRequisiteMatchingService } from '@/modules/requisites/services/matchers/phoneRequisiteMatchingService.interfaces';

@injectable()
export class PhoneRequisiteMatchingService implements IPhoneRequisiteMatchingService {
  match(value: string): boolean {
    const regExpPhoneInclude = /^[()\-\+\s\d]{0,12}$/;
    const result = value.match(regExpPhoneInclude);
    return Array.isArray(result) && value.length != 0;
  }

  matchPartial(value: string): boolean {
    const regExpPhoneInclude = /^\d{11}$/;
    const result = value.match(regExpPhoneInclude);
    return Array.isArray(result);
  }
}
