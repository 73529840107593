import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IChartScaleServiceKey } from '@/modules/charts/services/chartScaleService.interface';
import { ChartScaleService } from '@/modules/charts/services/chartScaleService';
import { ChartColorsFactoryKey } from '@/modules/charts/factories/colors/chartColorsFactory.interfaces';
import ChartColorsFactory from '@/modules/charts/factories/colors/chartColorsFactory';
import { IChartJSLineDatasetsServiceKey } from '@/modules/charts/modules/chartjs/components/UILineChart/services/chartJSLineDatasetsService.interface';
import { ChartJSLineDatasetsService } from '@/modules/charts/modules/chartjs/components/UILineChart/services/chartJSLineDatasetsService';
import { ChartJSTiltedBarDatasetsService } from '@/modules/charts/modules/chartjs/components/UITiltedBarChart/services/chartJSTiltedBarDatasetsService';
import { IChartJSTiltedBarDatasetsServiceKey } from '@/modules/charts/modules/chartjs/components/UITiltedBarChart/services/chartJSTiltedBarDatasetsService.interface';
import { IChartJSBarDatasetsServiceKey } from '@/modules/charts/modules/chartjs/components/UIBarChart/services/chartJSBarDatasetsService.interface';
import { ChartJSBarDatasetsService } from '@/modules/charts/modules/chartjs/components/UIBarChart/services/chartJSBarDatasetsService';

export function bindChartsServices(bind: IContainerBinder) {
  bind(IChartJSLineDatasetsServiceKey).to(ChartJSLineDatasetsService);
  bind(IChartScaleServiceKey).to(ChartScaleService);
  bind(IChartJSTiltedBarDatasetsServiceKey).to(ChartJSTiltedBarDatasetsService);
  bind(IChartJSBarDatasetsServiceKey).to(ChartJSBarDatasetsService);
  bind(ChartColorsFactoryKey).to(ChartColorsFactory).inSingletonScope();
}
