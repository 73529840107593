import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import PassportCheckingMappingService from '@/modules/dossier/person/passports/services/mapping/personPassportCheckingMappingService';
import { IPersonPassportCheckingMappingServiceKey } from '@/modules/dossier/person/passports/services/mapping/personPassportCheckingMappingService.interface';
import PassportComplexCheckingResultMapper from '@/modules/dossier/person/passports/services/mapping/personPassportComplexCheckingResultMapper';
import PassportInnCheckingResultMapper from '@/modules/dossier/person/passports/services/mapping/personPassportInnCheckingResultMapper';
import { IPersonPassportComplexCheckingResultMapperKey } from '@/modules/dossier/person/passports/services/mapping/personPassportComplexCheckingResultMapper.interfaces';
import { IPersonPassportInnCheckingResultMapperKey } from '@/modules/dossier/person/passports/services/mapping/personPassportInnCheckingResultMapper.interfaces';
import { IPersonPassportComplexCheckingParserServiceKey } from '@/modules/dossier/person/passports/services/parsers/personPassportComplexCheckingParserService.interface';
import PersonPassportComplexCheckingParserService from '@/modules/dossier/person/passports/services/parsers/personPassportComplexCheckingParserService';
import { IPersonPassportInnCheckingParserServiceKey } from '@/modules/dossier/person/passports/services/parsers/personPassportInnCheckingParserService.interface';
import PersonPassportInnCheckingParserService from '@/modules/dossier/person/passports/services/parsers/personPassportInnCheckingParserService';
import { IPersonPassportNormalizationServiceKey } from '@/modules/dossier/person/passports/services/normalization/personPassportNormalizationService.interfaces';
import PersonPassportNormalizationService from '@/modules/dossier/person/passports/services/normalization/personPassportNormalizationService';

export function bindPersonPassportServices(bind: IContainerBinder) {
  bind(IPersonPassportComplexCheckingParserServiceKey).to(
    PersonPassportComplexCheckingParserService,
  );
  bind(IPersonPassportInnCheckingParserServiceKey).to(PersonPassportInnCheckingParserService);
  bind(IPersonPassportCheckingMappingServiceKey).to(PassportCheckingMappingService);
  bind(IPersonPassportComplexCheckingResultMapperKey).to(PassportComplexCheckingResultMapper);
  bind(IPersonPassportInnCheckingResultMapperKey).to(PassportInnCheckingResultMapper);
  bind(IPersonPassportNormalizationServiceKey).to(PersonPassportNormalizationService);
}
