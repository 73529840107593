import { InjectionKey } from 'vue';
import { AxiosResponse } from 'axios';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { ApiGraphCounterpartyResult } from '@/modules/graph/table/types/api/apiGraphCounterpartyResult';
import { ApiGraphCreationQueryPersons } from '@/modules/graph/table/types/api/apiGraphCreationQueryPersons';

export interface IGraphService {
  searchSelectionToAnalysis(
    payload: Partial<ApiGraphCreationQueryPersons>,
  ): Promise<AxiosResponse<ApiResponse<ApiGraphCounterpartyResult>>>;
}

export const IGraphServiceKey: InjectionKey<IGraphService> = Symbol('IGraphService');
