import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import CompanyListsEntityIdsExtractionService
  from '@/modules/lists/services/companyListsEntityIdsExtractionService';
import {
  ICompanyListsEntityIdsExtractionServiceKey
} from '@/modules/lists/services/companyListsEntityIdsExtractionService.interfaces';
import CompanyListsService from '@/modules/lists/services/companyListsService';
import { ICompanyListsServiceKey } from '@/modules/lists/services/companyListsService.interfaces';
import PersonListsEntityIdsExtractionService
  from '@/modules/lists/services/personListsEntityIdsExtractionService';
import {
  IPersonListsEntityIdsExtractionServiceKey
} from '@/modules/lists/services/personListsEntityIdsExtractionService.interfaces';
import { PersonListsService } from '@/modules/lists/services/personListsService';
import { IPersonListsServiceKey } from '@/modules/lists/services/personListsService.interfaces';
import { IListsToAnalysisEntityMapperKey } from '@/modules/lists/services/listsToAnalysisEntityMapper.interfaces';
import ListsToAnalysisEntityMapper from '@/modules/lists/services/listsToAnalysisEntityMapper';

export function bindListsServices(bind: IContainerBinder) {
  bind(ICompanyListsServiceKey).to(CompanyListsService);
  bind(IPersonListsServiceKey).to(PersonListsService);
  bind(IListsToAnalysisEntityMapperKey).to(ListsToAnalysisEntityMapper);
  bind(IPersonListsEntityIdsExtractionServiceKey).to(PersonListsEntityIdsExtractionService);
  bind(ICompanyListsEntityIdsExtractionServiceKey).to(CompanyListsEntityIdsExtractionService);
}
