import { injectable } from 'inversify';
import { IChartJSTiltedBarDatasetsService } from '@/modules/charts/modules/chartjs/components/UITiltedBarChart/services/chartJSTiltedBarDatasetsService.interface';
import { ChartJSTiltedBarChartItem } from '@/modules/charts/modules/chartjs/components/UITiltedBarChart/types/ChartJSTiltedBarChartItem';

@injectable()
export class ChartJSTiltedBarDatasetsService implements IChartJSTiltedBarDatasetsService {
  createDatasets(items: ChartJSTiltedBarChartItem[]): any[] {
    const maxYValues = items.map((item) => Math.max(...item.y));
    return [
      {
        data: items.map((item, index) => ({ ...item, maxYValue: maxYValues[index] })),
        labelValue: items.map((item) => item.labelValue),
        labelCurrency: items.map((item) => item.labelCurrency),
      },
    ];
  }
}
