import { injectable } from 'inversify';
import { IDriverLicenseStatusDocumentMapper } from '@/modules/dossier/person/vehicles/driverLicenses/services/mappers/personDriverLicenseStatusDocumentMapper.interfaces';
import { DriverLicenseStatus } from '@/modules/dossier/person/vehicles/driverLicenses/types/driverLicenseStatus';
import { DriverLicenseFormattedTag } from '@/modules/dossier/person/vehicles/driverLicenses/types/driverLicenseFormattedTag';

@injectable()
export default class PersonDriverLicenseStatusDocumentMapper
  implements IDriverLicenseStatusDocumentMapper
{
  format(status: DriverLicenseStatus): DriverLicenseFormattedTag {
    switch (status) {
      case DriverLicenseStatus.T:
        return {
          type: 'success',
          label: 'Действителен',
        };
      case DriverLicenseStatus.A:
        return {
          type: 'danger',
          label: 'Не действителен',
        };
      default:
        return {
          type: 'secondary',
          label: 'Неизвестно',
        };
    }
  }
}
