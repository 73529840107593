import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { ICompanyEnforcementProceedingsMappingServiceKey } from '@/modules/dossier/company/enforcementProceedings/services/companyEnforcementProceedingsMappingService.interface';
import CompanyEnforcementProceedingsMappingService from '@/modules/dossier/company/enforcementProceedings/services/companyEnforcementProceedingsMappingService';
import { ICompanyEnforcementProceedingsStatisticsServiceKey } from '@/modules/dossier/company/enforcementProceedings/services/companyEnforcementProceedingsStatisticsService.interface';
import CompanyEnforcementProceedingStatisticsService from '@/modules/dossier/company/enforcementProceedings/services/companyEnforcementProceedingsStatisticsService';

export function bindCompanyDossierEnforcementProceedingsServices(bind: IContainerBinder) {
  bind(ICompanyEnforcementProceedingsMappingServiceKey).to(
    CompanyEnforcementProceedingsMappingService,
  );
  bind(ICompanyEnforcementProceedingsStatisticsServiceKey).to(
    CompanyEnforcementProceedingStatisticsService,
  );
}
