import { injectable } from 'inversify';
import { ICurrencyTagParserService } from '@/modules/currency/services/currencyTagParserService/currencyTagParserService.interfaces';
import { CurrencyTag } from '@/modules/currency/types/currencyTag';

@injectable()
export default class CurrencyTagParserService implements ICurrencyTagParserService {
  parse(value: string): CurrencyTag{
    switch (value.toLowerCase().trim()) {
      case '₽':
        return CurrencyTag.rub;
      case '$':
        return CurrencyTag.usd;
      case '€':
        return CurrencyTag.eur;
      case '£':
        return CurrencyTag.gbr;
      case '¥':
        return CurrencyTag.chf;
      case '₺':
        return CurrencyTag.chf;
      case '₹':
        return CurrencyTag.inr;
      case '₪':
        return CurrencyTag.ils;
      case '₸':
        return CurrencyTag.kzt;
      case '₫':
        return CurrencyTag.vnd;
      case '₩':
        return CurrencyTag.krw;
      case '฿':
        return CurrencyTag.thb;
      case '؋':
        return CurrencyTag.afn;
      default:
        return CurrencyTag.unknown;
    }
  }
}
