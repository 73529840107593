import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { GridOutput } from '@/modules/common/types/api/gridOutput';
import {
  ApiExternalDataParseAvailableRequest
} from '@/modules/dataCollection/types/api/apiExternalDataParseAvailableRequest';
import {
  ApiExternalDataParserAvailableResponse
} from '@/modules/dataCollection/types/api/apiExternalDataParserAvailableResponse';
import {
  ApiExternalDataParseRequest
} from '@/modules/dataCollection/types/api/apiExternalDataParseRequest';
import {
  ApiExternalDataParseRequestWithDefaults
} from '@/modules/dataCollection/types/api/ApiExternalDataParseRequestWithDefaults';
import {
  ApiExternalDataParserStatusMessage
} from '@/modules/dataCollection/types/api/apiExternalDataParserStatusMessage';
import {
  ApiExternalDataParseSpecializedRequestWithDefaults
} from '@/modules/dataCollection/types/api/ApiExternalDataParseSpecializedRequestWithDefaults';
import {
  ParserDataStatusActiveInfo
} from '@/modules/dataCollection/types/api/parserDataStatusActiveInfo';
import { AxiosResponse } from 'axios';
import { LoadOptions } from 'devextreme/data';
import { InjectionKey } from 'vue';

export interface IExternalDataParserService {
  getAvailableParsers(payload: ApiExternalDataParseAvailableRequest): Promise<AxiosResponse<ApiResponse<ApiExternalDataParserAvailableResponse[]>>>
  startParserExtended(payload: ApiExternalDataParseRequest): Promise<AxiosResponse<ApiResponse<string>>>
  startParser(payload: ApiExternalDataParseRequestWithDefaults): Promise<AxiosResponse<ApiResponse<string>>>
  startPersonParser(payload: ApiExternalDataParseSpecializedRequestWithDefaults): Promise<AxiosResponse<ApiResponse<string>>> | void
  startCompanyParser(payload: ApiExternalDataParseSpecializedRequestWithDefaults): Promise<AxiosResponse<ApiResponse<string>>>
  getStatus(): Promise<AxiosResponse<ApiResponse<ParserDataStatusActiveInfo>>>
  getStatusMessages(data: LoadOptions): Promise<AxiosResponse<ApiResponse<GridOutput<ApiExternalDataParserStatusMessage>>>>
}

export const IExternalDataParserServiceKey: InjectionKey<IExternalDataParserService> = Symbol('IExternalDataParserService');
