import { OverlayLayerObject } from '@/modules/overlay/types/overlayLayerObject';
import { OverlayLayerPublicConfig } from '@/modules/overlay/types/overlayLayerPublicConfig';
import { defineStore } from 'pinia';
import { shallowRef } from 'vue';

export const useOverlayStore = defineStore('overlay', () => {
  const layers = shallowRef(new Array<OverlayLayerObject>());

  function addLayer(config: OverlayLayerPublicConfig) {
    const {
      component,
      componentConfig,

      ...layerConfig
    } = config;

    const layer: OverlayLayerObject = {
      component: {
        component,
        config: componentConfig
      },

      verticalAlignment: layerConfig.verticalAlignment ?? 'center',
      horizontalAlignment: layerConfig.horizontalAlignment ?? 'center',
      closeOnBackgroundClick: false,

      close: () => removeLayer(layer)
    };

    layers.value = [...layers.value, layer];

    return layer;
  }

  function removeLayer(layer: OverlayLayerObject): void {
    layers.value = layers.value.filter(x => x !== layer);
  }

  return {
    layers,
    addLayer
  }
});
