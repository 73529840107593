import { inject, injectable } from 'inversify';
import { ICarVinValidationService } from '@/modules/validation/services/carVinValidationService.interface';
import {
  IMasksService,
  IMasksServiceKey,
} from '@/modules/masks/services/masksService/masksService.interfaces';
import { carVinMask } from '@/modules/validation/configuration/masks/carVinMask';

@injectable()
export default class CarVinValidationService implements ICarVinValidationService {
  constructor(@inject(IMasksServiceKey as symbol) private masksService: IMasksService) {}

  validate(value: string): boolean {
    return this.masksService.isCompleted(value, carVinMask);
  }
}
