import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import PersonCarsOwnerRegistrationTypeService from '@/modules/dossier/person/vehicles/cars/services/personCarTagsService';
import { IPersonCarTagsServiceKey } from '@/modules/dossier/person/vehicles/cars/services/personCarTagsService.interface';
import PersonCarsNormalizationService from '@/modules/dossier/person/vehicles/cars/services/personCarsNormalizationService';
import { IPersonCarsNormalizationServiceKey } from '@/modules/dossier/person/vehicles/cars/services/personCarsNormalizationService.interfaces';

export function bindPersonCarsServices(bind: IContainerBinder) {
  bind(IPersonCarTagsServiceKey).to(PersonCarsOwnerRegistrationTypeService);
  bind(IPersonCarsNormalizationServiceKey).to(PersonCarsNormalizationService);
}
