import { inject, injectable } from 'inversify';
import { IPersonRequisitesMatchingService } from '@/modules/requisites/services/matchers/personRequisitesMatchingService.interfaces';
import { RequisiteType } from '@/modules/requisites/types/requisiteType';
import {
  ICarNumberRequisiteMatchingService,
  ICarNumberRequisiteMatchingServiceKey,
} from '@/modules/requisites/services/matchers/carNumberRequisiteMatchingService.interfaces';
import {
  IFullNameRequisiteMatchingService,
  IFullNameRequisiteMatchingServiceKey,
} from '@/modules/requisites/services/matchers/fullNameRequisiteMatchingService.interfaces';
import {
  IPersonInnRequisiteMatchingService,
  IPersonInnRequisiteMatchingServiceKey,
} from '@/modules/requisites/services/matchers/personInnRequisiteMatchingService.interfaces';
import {
  IPhoneRequisiteMatchingService,
  IPhoneRequisiteMatchingServiceKey,
} from '@/modules/requisites/services/matchers/phoneRequisiteMatchingService.interfaces';

@injectable()
export class PersonRequisitesMatchingService implements IPersonRequisitesMatchingService {
  constructor(
    @inject(IFullNameRequisiteMatchingServiceKey as symbol)
    private fullNameRequisiteMatchingService: IFullNameRequisiteMatchingService,
    @inject(IPhoneRequisiteMatchingServiceKey as symbol)
    private phoneRequisiteMatchingService: IPhoneRequisiteMatchingService,
    @inject(IPersonInnRequisiteMatchingServiceKey as symbol)
    private personInnRequisiteMatchingService: IPersonInnRequisiteMatchingService,
    @inject(ICarNumberRequisiteMatchingServiceKey as symbol)
    private carNumberRequisiteMatchingService: ICarNumberRequisiteMatchingService,
  ) {}

  getRequisiteTypes(value: string): Array<RequisiteType> {
    const detailList = [];
    if (this.carNumberRequisiteMatchingService.match(value))
      detailList.push(RequisiteType.carNumber);
    if (this.personInnRequisiteMatchingService.match(value)) detailList.push(RequisiteType.innFl);
    if (this.phoneRequisiteMatchingService.match(value)) detailList.push(RequisiteType.phone);
    if (this.fullNameRequisiteMatchingService.match(value)) detailList.push(RequisiteType.fullName);

    return detailList;
  }
}
