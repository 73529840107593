import {
  IElasticPassportParser
} from '@/modules/elastic/services/elasticPassportParser.interfaces';
import { ElasticPassport } from '@/modules/elastic/types/elasticPassport';
import { injectable } from 'inversify';

const passportRegex = /(\d{4})\s+(\d{6})/

@injectable()
export default class ElasticPassportParser implements IElasticPassportParser {
  parse(value: string): ElasticPassport | undefined {
    const match = passportRegex.exec(value);

    if(match != undefined) {
      const [, passportSeries, passportNumber] = match;

      return {
        passportSeries,
        passportNumber
      }
    }
  }

}
