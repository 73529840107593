import {
  IElasticCompanyFieldFormatter, IElasticCompanyFieldFormatterKey
} from '@/modules/elastic/services/elasticCompanyFieldFormatter/elasticCompanyFieldFormatter.interfaces';
import {
  IElasticPersonFieldFormatter, IElasticPersonFieldFormatterKey
} from '@/modules/elastic/services/elasticPersonFieldFormatter/elasticPersonFieldFormatter.interfaces';
import { ApiElasticCompanyField } from '@/modules/elastic/types/api/apiElasticCompanyField';
import { ApiElasticPersonField } from '@/modules/elastic/types/api/apiElasticPersonField';
import { ApiElasticSourceType } from '@/modules/elastic/types/api/apiElasticSourceType';
import {
  ISearchConceptOptionFormatter
} from '@/modules/searchConcept/common/services/searchConceptOptionFormatter/searchConceptOptionFormatter.interfaces';
import { SearchConceptOption } from '@/modules/searchConcept/common/types/searchConceptOption';
import { inject, injectable } from 'inversify';

@injectable()
export default class SearchConceptOptionFormatter implements ISearchConceptOptionFormatter {
  constructor(
    @inject(IElasticPersonFieldFormatterKey as symbol) private personFieldFormatter: IElasticPersonFieldFormatter,
    @inject(IElasticCompanyFieldFormatterKey as symbol) private companyFieldFormatter: IElasticCompanyFieldFormatter
  ) { }

  format(option: SearchConceptOption): string {
    switch (option.source) {
      case ApiElasticSourceType.Person: return this.personFieldFormatter.format(option.field as ApiElasticPersonField);
      case ApiElasticSourceType.Company: return this.companyFieldFormatter.format(option.field as ApiElasticCompanyField);
      default: return '';
    }
  }
}
