import Cookies from 'js-cookie';
import type Middleware from '@/modules/router/common/types/middleware';
import RouteNames from '@/modules/router/common/types/routeNames';

const authMiddleware: Middleware = ({ to }) => {
  const isAuth = Cookies.get('isAuth');

  if (to.name != RouteNames.AUTH_PAGE && isAuth == undefined) {
    return { name: RouteNames.AUTH_PAGE };
  }
};

export default authMiddleware;
