export enum SocialNetworkTypes {
  Unknown = 0,
  Vk = 1,
  Ok = 2,
  Fb = 3,
  Instagram = 4,
  Twitter = 5,
  TikTok = 6,
  Telegram = 7,
  WhatsApp = 8,
  Viber = 9,
  Skype = 10,
}
