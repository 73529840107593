import { InjectionKey } from 'vue';
import { WeightMarkerItem } from '@/modules/administration/types/weightMarkerItem';
import { GroupNameResult } from '@/modules/administration/types/groupNameResult';

export interface IAdministrationMarkersMappingService {
  getGroupNameAndStatusIcon(marker: WeightMarkerItem): GroupNameResult;
}

export const IAdministrationMarkersMappingServiceKey: InjectionKey<IAdministrationMarkersMappingService> =
  Symbol('IAdministrationMarkersMappingServiceKey');
