import { AnalysisEntity } from '@/modules/analysis/types/analysisEntity';
import { AnalysisEntityType } from '@/modules/analysis/types/analysisEntityType';
import {
  IListsToAnalysisEntityMapper
} from '@/modules/lists/services/listsToAnalysisEntityMapper.interfaces';
import { ApiPersonListDataItem } from '@/modules/lists/types/api/fl/apiPersonListDataItem';
import { ApiUlListDataItem } from '@/modules/lists/types/api/ul/apiUlListDataItem';
import { injectable } from 'inversify';

@injectable()
export default class ListsToAnalysisEntityMapper implements IListsToAnalysisEntityMapper {
  mapCompaniesLists(item: ApiUlListDataItem): AnalysisEntity {
    return {
      id: item.id,
      type: AnalysisEntityType.companiesList,
      title: item.displayName ?? '',
    };
  }

  mapPersonsLists(item: ApiPersonListDataItem): AnalysisEntity {
    return {
      id: item.id,
      type: AnalysisEntityType.personsList,
      title: item.displayName,
    };
  }
}
