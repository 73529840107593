import { injectable } from 'inversify';
import { AnalyticsScore } from '@/modules/dossier/person/financialScorings/types/financialAnalyticsScore';
import { IAnalyticsScoreTypeFormatterService } from './analyticsScoreTypeFormatterService.interface';

@injectable()
export default class AnalyticsScoreTypeFormatterService
  implements IAnalyticsScoreTypeFormatterService
{
  getAnalyticsScoreTypeFormattedName(score: AnalyticsScore): string {
    switch (score) {
      case AnalyticsScore.HighScore:
        return `Хороший`;
      case AnalyticsScore.MediumScore:
        return `Средний`;
      case AnalyticsScore.PoorScore:
        return `Плохой`;
      case AnalyticsScore.WorstScore:
        return `Крайне плохой`;
      case AnalyticsScore.NotFound:
        return `Информация не найдена`;
      case AnalyticsScore.OverdueScore:
        return `Наличие текущей просрочки более 90 дней`;
      default:
        return `Информация не найдена`;
    }
  }
}
