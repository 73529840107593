export enum EventJournalTypes {
  unknown = 1,
  import = 2,
  zup = 3,
  indirect = 4,
  ab100 = 5,
  kontur = 6,
  kronos = 7,
  xneo = 8,
  mvd = 9,
  fns = 10,
  damia = 11,
  odisseyFl = 12,
  bfo = 13,
  idx = 14,
  odisseyUl = 15,
  odnoklassniki = 16,
  vk = 17,
}
