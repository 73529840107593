import { StringUtils } from '@/modules/common/utils/stringUtils';
import {
  IUrlValidationService
} from '@/modules/validation/services/urlValidationService.interface';
import { injectable } from 'inversify';

@injectable()
export default class UrlValidationService implements IUrlValidationService {
  validate(value: any): value is string {
    if(StringUtils.isString(value)) {
      const parsedValue = `${process.env.VUE_APP_BASE_URL}${value}`;

      try {
        new URL(parsedValue);
        return true;
      } catch (error) {
        return false;
      }
    }

    return false;
  }
}
