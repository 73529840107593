import { uniqueId } from 'lodash';
import { unref, watch, shallowRef } from 'vue';
import { MaybeRef } from '@vueuse/core';

function useCollectionKeys<T>(collection: MaybeRef<T[]>) {
  const unrefCollection = unref(collection);
  const keys = shallowRef(new Map(unrefCollection.map(x => [x, uniqueId('use-collection-keys-')])));

  watch(collection, newCollection => {
    const unrefNewCollection = unref(newCollection);

    const newKeys = new Map(
      unrefNewCollection.map(x => [x, keys.value.get(x) ?? uniqueId('use-collection-keys-')]),
    );

    keys.value = newKeys;
  });

  return (item: T) => keys.value.get(item) as string;
}

export default useCollectionKeys;
