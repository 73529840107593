import { useLogoutEvent } from '@/modules/auth/composables/events/useLogoutEvent/useLogoutEvent';
import { useReportsGenerationTaskCancelledEvent } from '@/modules/reports/common/events/useReportsGenerationTaskCancelledEvent';
import { useReportsGenerationTaskStartedEvent } from '@/modules/reports/common/events/useReportsGenerationTaskStartedEvent';
import { useTaskStatusChangedEvent } from '@/modules/tasks/composables/useTaskStatusChangedEvent/useTaskStatusChangedEvent';
import { ApiProcessStatus } from '@/modules/tasks/types/api/apiProcessStatus';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useReportsProgressNotificationsStore = defineStore(
  'ReportsProgressNotificationsStore',
  () => {
    const { onGenerationTaskStarted } = useReportsGenerationTaskStartedEvent();
    const { onGenerationTaskCancelled } = useReportsGenerationTaskCancelledEvent();
    const { onTaskStatusChanged } = useTaskStatusChangedEvent();
    const { onLogout } = useLogoutEvent();

    const notificationTasksSet = ref(new Set<string>());
    const finishedNotificationTasksSet = new Set<string>();
    const areNotificationsAllowed = ref(true);

    const notificationTasks = computed(() => [...notificationTasksSet.value]);

    function removeFinishedTasks() {
      finishedNotificationTasksSet.forEach(x => notificationTasksSet.value.delete(x));
      finishedNotificationTasksSet.clear();
    }

    function addTask(taskId: string) {
      notificationTasksSet.value.add(taskId);
      removeFinishedTasks();
    }

    function removeTask(taskId: string) {
      notificationTasksSet.value.delete(taskId);
      removeFinishedTasks();
    }

    function handleTaskStatusChange(taskId: string, status: ApiProcessStatus) {
      if (status == ApiProcessStatus.Complete || status == ApiProcessStatus.Error) {
        finishedNotificationTasksSet.add(taskId);
      }
    }

    function reset() {
      notificationTasksSet.value.clear();
      finishedNotificationTasksSet.clear();
    }

    onGenerationTaskStarted(taskId => addTask(taskId));
    onGenerationTaskCancelled(taskId => removeTask(taskId));
    onTaskStatusChanged((taskId, status) => handleTaskStatusChange(taskId, status));

    onLogout(() => reset());

    return {
      notificationTasks,
      areNotificationsAllowed,

      reset,
    };
  },
);
