import { GuidUtils } from '@/modules/common/utils/guidUtils';
import {
  ApiGridPersonCompanyDetails
} from '@/modules/dataGrid/person/types/api/apiGridPersonCompanyDetails';
import { ApiGridPersonRecord } from '@/modules/dataGrid/person/types/api/apiGridPersonRecord';
import {
  IElasticPassportParser, IElasticPassportParserKey
} from '@/modules/elastic/services/elasticPassportParser.interfaces';
import { ApiElasticHit } from '@/modules/elastic/types/api/apiElasticHit';
import {
  IElasticHitToGridPersonMapper
} from '@/modules/searchConcept/person/services/elasticHitToGridPersonMapper.interfaces';
import { inject, injectable } from 'inversify';
import _ from 'lodash';

@injectable()
export class ElasticHitToGridPersonMapper implements IElasticHitToGridPersonMapper {
  constructor(
    @inject(IElasticPassportParserKey as symbol) private passportParser: IElasticPassportParser
  ) { }

  map(hit: ApiElasticHit): ApiGridPersonRecord {
    const source = hit._source;
    const person = source.personRoot;

    const id = source.id;

    const fullname = person.fullNames.values[0] ?? '';
    const inn = person.inns.values[0];
    const snils = person.snilses.values[0];
    const note = person.notes.values[0];

    const isWorking = false;

    const passports =  person.passports.values.map(x => this.passportParser.parse(x));
    const passportRusSeria = passports[0]?.passportSeries;
    const passportRusNumber = passports[0]?.passportNumber;

    const addresses = person.addresses.values.join('\n');
    const phones = person.phones.values.join('\n');

    const educations = _.zip(person.educationNames.values, person.educationSpecialties.values)
      .map(([educationName, speciality]) => `${educationName} ${speciality}`)
      .join('\n');

    const companies = person.jobCompanyNames.values.join('\n');
    const departments = person.jobDepartments.values.join('\n');
    const positions = person.jobPositions.values.join('\n');

    const companiesDetails = person.jobCompanyNames.values.map<ApiGridPersonCompanyDetails>(x => ({
      companyName: x,
      boundDossierId: GuidUtils.emptyGuid
    }));

    const personListsArray = new Array<string>();

    const personDetailUrl = `/person/${id}`;

    return {
      id,
      person_original_id: id,
      fullname,
      passportRusSeria,
      passportRusNumber,
      inn,
      snils,
      isWorking,
      addresses,
      phones,
      educations,
      companies,
      companiesDetails,
      departments,
      positions,
      note,
      personListsArray,
      personDetailUrl
    }
  }
}
