export type TToast = {
  message: string;
  id: number;
  duration?: number;
  type?: string;
  handler: () => any;
};

export type ToastState = {
  namespaced: boolean;
  toasts: TToast[];
};

export enum MutationTypesEnum {
  ADD_TOAST = 'ADD_TOAST',
  POP_TOAST = 'POP_TOAST',
}

export type Mutations<S = ToastState> = {
  [MutationTypesEnum.ADD_TOAST](state: S, payload: TToast): void;
  [MutationTypesEnum.POP_TOAST](state: S, payload: { id: number }): void;
};
