import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { DaDataEndpointKey } from '@/modules/common/endpoints/daDataEndpoint.interfaces';
import { GraphEndpointKey } from '@/modules/common/endpoints/graphEndpoint.interfaces';
import ApiEndpointsFactory from '@/modules/common/factories/endpoints/apiEndpointsFactory';
import {
  ApiEndpointsFactoryKey
} from '@/modules/common/factories/endpoints/apiEndpointsFactory.interfaces';
import DaDataEndpointsFactory from '@/modules/common/factories/endpoints/daDataEndpointsFactory';
import {
  DaDataEndpointsFactoryKey
} from '@/modules/common/factories/endpoints/daDataEndpointsFactory.interfaces';
import {
  IEndpointsFactory
} from '@/modules/common/factories/endpoints/endpointsFactory.interfaces';
import GraphEndpointsFactory from '@/modules/common/factories/endpoints/graphEndpointsFactory';
import {
  IGraphEndpointsFactory, IGraphEndpointsFactoryKey
} from '@/modules/common/factories/endpoints/graphEndpointsFactory.interfaces';

export function bindEndpoints(bind: IContainerBinder) {
  bind(ApiEndpointsFactoryKey).to(ApiEndpointsFactory).inSingletonScope();
  bind(ApiEndpointKey).toDynamicValue(({ container }) => container.get<IEndpointsFactory>(ApiEndpointsFactoryKey as symbol).get()).inSingletonScope();
  bind(DaDataEndpointsFactoryKey).to(DaDataEndpointsFactory).inSingletonScope();
  bind(DaDataEndpointKey).toDynamicValue(({ container }) => container.get<IEndpointsFactory>(DaDataEndpointsFactoryKey as symbol).get()).inSingletonScope();
  bind(IGraphEndpointsFactoryKey).to(GraphEndpointsFactory).inSingletonScope();
  bind(GraphEndpointKey).toDynamicValue(({ container }) => container.get<IGraphEndpointsFactory>(IGraphEndpointsFactoryKey as symbol).get()).inSingletonScope();
}
