import { UICircularProgressGradientConfiguration } from '@/modules/progress/components/UICircularProgress/types/uiCircularProgressGradientConfiguration';

export module UICircularProgressConfiguration {
  export const linearGradient: Array<UICircularProgressGradientConfiguration> = [
    {
      id: 'primaryTheme',
      x1: 0,
      y1: 1,
      x2: 1,
      y2: 0,
      colors: [
        {
          offset: '54%',
          color: 'var(--color-blue-6)',
        },
        {
          offset: '100%',
          color: 'var(--color-blue-7)',
        },
      ],
    },
    {
      id: 'blueTheme',
      x1: 0,
      y1: 1,
      x2: 1,
      y2: 0,
      colors: [
        {
          offset: '72%',
          color: 'var(--color-blue-7)',
        },
        {
          offset: '100%',
          color: 'var(--color-blue-8)',
        },
      ],
    },
    {
      id: 'grayTheme',
      x1: 0,
      y1: 1,
      x2: 1,
      y2: 0,
      colors: [
        {
          offset: '45%',
          color: 'var(--color-gray-6)',
        },
        {
          offset: '100%',
          color: 'var(--color-blue-8)',
        },
      ],
    },
    {
      id: 'greenTheme',
      x1: 0,
      y1: 1,
      x2: 1,
      y2: 0,
      colors: [
        {
          offset: '45%',
          color: 'var(--color-green-5)',
        },
        {
          offset: '100%',
          color: 'var(--color-green-4)',
        },
      ],
    },
    {
      id: 'redTheme',
      x1: 0,
      y1: 1,
      x2: 1,
      y2: 0,
      colors: [
        {
          offset: '45%',
          color: 'var(--color-orange-1)',
        },
        {
          offset: '100%',
          color: 'var(--color-red-4)',
        },
      ],
    },
  ];
}
