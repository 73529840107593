import { injectable } from 'inversify';
import { IFinanceAnalysisMappingService } from '@/modules/dossier/company/financeAnalysis/services/financeAnalysisMappingService.interface';
import { FinanceAnalysisShortTableData } from '@/modules/dossier/company/financeAnalysis/types/financeAnalysisShortTableData';
import { IndicatorsOfCashFlowTableData } from '@/modules/dossier/company/financeAnalysis/types/IndicatorsOfCashFlowTableData';
import { CashFlow } from '@/modules/dossier/company/financeAnalysis/types/api/cashFlow';
import { LiquidityAndSolvency } from '@/modules/dossier/company/financeAnalysis/types/api/liquidityAndSolvency';
import { FinancialStability } from '@/modules/dossier/company/financeAnalysis/types/api/financialStability';
import { IndicatorsOfProfitability } from '@/modules/dossier/company/financeAnalysis/types/api/indicatorsOfProfitability';
import { FinanceAnalysisTableData } from '@/modules/dossier/company/financeAnalysis/types/financeAnalysisTableData';
import { DxTextWrapperTheme } from '@/modules/devExtreme/components/DxTextWrapper/types/DxTextWrapperTheme';
import { FinanceDetailsList } from '@/modules/dossier/company/financeAnalysis/types/financeDetailsList';
import { ShortFinanceDebtShare } from '@/modules/dossier/company/financeAnalysis/types/financeShortDebtShare';
import { DossierTagData } from '@/modules/common/types/dossierTag';

@injectable()
export default class FinanceAnalysisMappingService implements IFinanceAnalysisMappingService {
  // eslint-disable-next-line class-methods-use-this
  protected getOfTwoYearsCashPercentDelta(
    canCalculate: boolean,
    firstYear: number,
    secondYear: number,
  ) {
    if (!canCalculate) {
      return { text: '-' };
    }
    const onePercent = firstYear / 100;
    const delta = firstYear - secondYear;
    const deltaPercent = Math.floor(delta / onePercent);
    const percent = `${deltaPercent}%`;
    return {
      text: percent,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  protected getTableStringTheme(
    isGreen: boolean,
    isYellow: boolean,
    isRed: boolean,
  ): DxTextWrapperTheme {
    if (isGreen) {
      return 'success';
    }
    if (isRed) {
      return 'error';
    }
    if (isYellow) {
      return 'warning';
    }
    return 'default';
  }

  // eslint-disable-next-line class-methods-use-this
  protected getTableRowTooltip(s: string) {
    const arr = s.split(':');
    return {
      text: arr[0],
      tooltip: arr[1],
      hasTooltip: arr[1] != undefined,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  protected getFinanceAnalysisShortTableDeltaCellData(
    percent: string | undefined,
    hasIcon: boolean,
  ) {
    return {
      hasIcon,
      text: percent ?? '',
    };
  }

  getActualRatioCell(actualRatioText: string): DossierTagData {
    switch (actualRatioText) {
      case 'Fail':
        return {
          theme: 'danger',
          text: actualRatioText,
        };

      case 'Ok':
        return {
          theme: 'success',
          text: actualRatioText,
        };

      default:
        return {
          theme: 'common',
          text: actualRatioText,
        };
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getFinanceAnalysisShortTableData(
    financeDetailsList: Array<FinanceDetailsList>,
  ): Array<FinanceAnalysisShortTableData> {
    return financeDetailsList.map((item) => ({
      title: item.title,
      twoYearsAgo: item.twoYearsAgoStr,
      lastYear: item.lastYearStr,
      twoYearsAgoToLastYearStr: this.getFinanceAnalysisShortTableDeltaCellData(
        item.twoYearsAgoToLastYearStr,
        !(item as ShortFinanceDebtShare)?.isDebtShare,
      ),
      currentYear: item.currentYearStr ? item.currentYearStr : '',
      lastYearToCurrentYearStr: this.getFinanceAnalysisShortTableDeltaCellData(
        item.lastYearToCurrentYearStr,
        !(item as ShortFinanceDebtShare)?.isDebtShare,
      ),
      summery: item?.summery,
    }));
  }

  getIndicatorsOfCashFlowTableData(
    indicatorsOfCashFlowList: Array<CashFlow>,
  ): Array<IndicatorsOfCashFlowTableData> {
    return indicatorsOfCashFlowList.map((item) => {
      if (item.title.split(' ')[0] == 'DSCR') {
        return {
          title: item?.title,
          twoYearsAgo: {
            items: [item?.twoYearsAgoText],
            theme: this.getTableStringTheme(false, false, item.twoYearsAgo < 1.1),
          },
          lastYear: {
            items: [item?.oneYearAgoText],
            theme: this.getTableStringTheme(false, false, item.oneYearAgo < 1.1),
          },
          twoYearsAgoToLastYearStr: {
            items: [
              this.getOfTwoYearsCashPercentDelta(
                item?.deltaOneYearAgoToTwoYearsAgo != undefined,
                item?.twoYearsAgo,
                item?.oneYearAgo,
              ),
            ],
            theme: this.getTableStringTheme(false, false, item.twoYearsAgo < 1.1),
          },
          currentYear: {
            items: [item?.currentYearText],
            theme: this.getTableStringTheme(false, false, item.currentYear < 1.1),
          },
          lastYearToCurrentYearStr: {
            items: [
              this.getOfTwoYearsCashPercentDelta(
                item?.deltaCurrentYearToOneYearAgo != undefined,
                item?.oneYearAgo,
                item?.currentYear,
              ),
            ],
            theme: this.getTableStringTheme(false, false, item.twoYearsAgo < 1.1),
          },
          summery: item?.commentaries,
        };
      }
      return {
        title: item?.title,
        twoYearsAgo: {
          items: [item.twoYearsAgoText],
          theme: this.getTableStringTheme(false, false, false),
        },
        lastYear: {
          items: [item?.oneYearAgoText],
          theme: this.getTableStringTheme(false, false, false),
        },
        twoYearsAgoToLastYearStr: {
          items: [
            this.getOfTwoYearsCashPercentDelta(
              item?.deltaOneYearAgoToTwoYearsAgo != undefined,
              item?.twoYearsAgo,
              item?.oneYearAgo,
            ),
          ],
          theme: this.getTableStringTheme(false, false, false),
        },
        currentYear: {
          items: [item?.currentYearText],
          theme: this.getTableStringTheme(false, false, false),
        },
        lastYearToCurrentYearStr: {
          items: [
            this.getOfTwoYearsCashPercentDelta(
              item?.deltaCurrentYearToOneYearAgo != undefined,
              item?.oneYearAgo,
              item?.currentYear,
            ),
          ],
          theme: this.getTableStringTheme(false, false, false),
        },
        summery: item?.commentaries,
      };
    });
  }

  getLiquidityAndSolvencyTableData(
    liquidityAndSolvency: LiquidityAndSolvency,
  ): Array<FinanceAnalysisTableData> {
    const {
      ownWorkingCapital,
      ratioOfReceivablesToTotalAssets,
      ratioOfCurrentLiquidityRatioOfCurrentAssetsToCurrentLiabilities: ratioOfCurrentLiquidity,
      ratioOfSolvencyRatioOfCashShortTermFinancialInvestmentsToShortTermLiabilities:
        ratioOfSolvency,
      degreeOfSolvencyForCurrentObligationsPeriodDuringWhichCompanyCanRepayCurrentDebt:
        degreeOfSolvency,
    } = liquidityAndSolvency;

    return [
      {
        title: this.getTableRowTooltip(ownWorkingCapital.title),
        twoYearsAgo: {
          items: [ownWorkingCapital.twoYearsAgoText],
          theme: this.getTableStringTheme(false, false, ownWorkingCapital.twoYearsAgo < 0),
        },
        oneYearAgo: {
          items: [ownWorkingCapital.oneYearAgoText],
          theme: this.getTableStringTheme(false, false, ownWorkingCapital.oneYearAgo < 0),
        },
        currentYear: {
          items: [ownWorkingCapital.currentYearText],
          theme: this.getTableStringTheme(false, false, ownWorkingCapital.currentYear < 0),
        },
        referenceValue: ownWorkingCapital.referenceValue,
        commentary: ownWorkingCapital.commentary,
      },
      {
        title: this.getTableRowTooltip(ratioOfCurrentLiquidity.title),
        twoYearsAgo: {
          items: [ratioOfCurrentLiquidity.twoYearsAgoText],
          theme: this.getTableStringTheme(false, false, ratioOfCurrentLiquidity.twoYearsAgo < 1.5),
        },
        oneYearAgo: {
          items: [ratioOfCurrentLiquidity.oneYearAgoText],
          theme: this.getTableStringTheme(false, false, ratioOfCurrentLiquidity.oneYearAgo < 1.5),
        },
        currentYear: {
          items: [ratioOfCurrentLiquidity.currentYearText],
          theme: this.getTableStringTheme(false, false, ratioOfCurrentLiquidity.currentYear < 1.5),
        },
        referenceValue: ratioOfCurrentLiquidity.referenceValue,
        commentary: ratioOfCurrentLiquidity.commentary,
      },
      {
        title: this.getTableRowTooltip(ratioOfSolvency.title),
        twoYearsAgo: {
          items: [ratioOfSolvency.twoYearsAgoText],
          theme: 'default',
        },
        oneYearAgo: {
          items: [ratioOfSolvency.oneYearAgoText],
          theme: 'default',
        },
        currentYear: {
          items: [ratioOfSolvency.currentYearText],
          theme: 'default',
        },
        referenceValue: ratioOfSolvency.referenceValue,
        commentary: ratioOfSolvency.commentary,
      },
      {
        title: this.getTableRowTooltip(degreeOfSolvency.title),
        twoYearsAgo: {
          items: [degreeOfSolvency.twoYearsAgoText],
          theme: this.getTableStringTheme(false, false, degreeOfSolvency.twoYearsAgo > 6),
        },
        oneYearAgo: {
          items: [degreeOfSolvency.oneYearAgoText],
          theme: this.getTableStringTheme(false, false, degreeOfSolvency.oneYearAgo > 6),
        },
        currentYear: {
          items: [degreeOfSolvency.currentYearText],
          theme: this.getTableStringTheme(false, false, degreeOfSolvency.currentYear > 6),
        },
        referenceValue: degreeOfSolvency.referenceValue,
        commentary: degreeOfSolvency.commentary,
      },
      {
        title: this.getTableRowTooltip(ratioOfReceivablesToTotalAssets.title),
        twoYearsAgo: {
          items: [ratioOfReceivablesToTotalAssets.twoYearsAgoText],
          theme: this.getTableStringTheme(
            false,
            false,
            ratioOfReceivablesToTotalAssets.twoYearsAgo > 50,
          ),
        },
        oneYearAgo: {
          items: [ratioOfReceivablesToTotalAssets.oneYearAgoText],
          theme: this.getTableStringTheme(
            false,
            false,
            ratioOfReceivablesToTotalAssets.oneYearAgo > 50,
          ),
        },
        currentYear: {
          items: [ratioOfReceivablesToTotalAssets.currentYearText],
          theme: this.getTableStringTheme(
            false,
            false,
            ratioOfReceivablesToTotalAssets.currentYear > 50,
          ),
        },
        referenceValue: ratioOfReceivablesToTotalAssets.referenceValue,
        commentary: ratioOfReceivablesToTotalAssets.commentary,
      },
    ];
  }

  getStabilityTableData(financeStability: FinancialStability): Array<FinanceAnalysisTableData> {
    const {
      netAssets,
      ratioOfConcentrationOfEquityRatioOfEquityToTotalAmountOfSourcesOfFunds: ratioOfConcentration,
      levelOfFinancialLeverageRatioOfDebtToEquity: levelOfFinancialLeverage,
      ratioOfSecurityOfInterestToPayment,
      ratioOfTotalDebtToProfitFromSales,
    } = financeStability;

    return [
      {
        title: this.getTableRowTooltip(netAssets.title),
        twoYearsAgo: {
          items: [netAssets.twoYearsAgoText],
          theme: this.getTableStringTheme(false, false, netAssets.twoYearsAgo < 0),
        },
        oneYearAgo: {
          items: [netAssets.oneYearAgoText],
          theme: this.getTableStringTheme(false, false, netAssets.oneYearAgo < 0),
        },
        currentYear: {
          items: [netAssets.currentYearText],
          theme: this.getTableStringTheme(false, false, netAssets.currentYear < 0),
        },
        summery: { quicksell: netAssets.commentary },
      },
      {
        title: this.getTableRowTooltip(ratioOfConcentration.title),
        twoYearsAgo: {
          items: [ratioOfConcentration.twoYearsAgoText],
          theme: this.getTableStringTheme(false, false, ratioOfConcentration.twoYearsAgo < 0.3),
        },
        oneYearAgo: {
          items: [ratioOfConcentration.oneYearAgoText],
          theme: this.getTableStringTheme(false, false, ratioOfConcentration.oneYearAgo < 0.3),
        },
        currentYear: {
          items: [ratioOfConcentration.currentYearText],
          theme: this.getTableStringTheme(false, false, ratioOfConcentration.currentYear < 0.3),
        },
        summery: { quicksell: ratioOfConcentration.commentary },
      },
      {
        title: this.getTableRowTooltip(levelOfFinancialLeverage.title),
        twoYearsAgo: {
          items: [levelOfFinancialLeverage.twoYearsAgoText],
          theme: this.getTableStringTheme(false, false, levelOfFinancialLeverage.twoYearsAgo > 3),
        },
        oneYearAgo: {
          items: [levelOfFinancialLeverage.oneYearAgoText],
          theme: this.getTableStringTheme(false, false, levelOfFinancialLeverage.oneYearAgo > 3),
        },
        currentYear: {
          items: [levelOfFinancialLeverage.currentYearText],
          theme: this.getTableStringTheme(false, false, levelOfFinancialLeverage.currentYear > 3),
        },
        summery: { quicksell: levelOfFinancialLeverage.commentary },
      },
      {
        title: this.getTableRowTooltip(ratioOfSecurityOfInterestToPayment.title),
        twoYearsAgo: {
          items: [ratioOfSecurityOfInterestToPayment.twoYearsAgoText],
          theme: this.getTableStringTheme(
            false,
            false,
            ratioOfSecurityOfInterestToPayment.twoYearsAgo < 2.5,
          ),
        },
        oneYearAgo: {
          items: [ratioOfSecurityOfInterestToPayment.oneYearAgoText],
          theme: this.getTableStringTheme(
            false,
            false,
            ratioOfSecurityOfInterestToPayment.oneYearAgo < 2.5,
          ),
        },
        currentYear: {
          items: [ratioOfSecurityOfInterestToPayment.currentYearText],
          theme: this.getTableStringTheme(
            false,
            false,
            ratioOfSecurityOfInterestToPayment.currentYear < 2.5,
          ),
        },
        summery: { quicksell: ratioOfSecurityOfInterestToPayment.commentary },
      },
      {
        title: this.getTableRowTooltip(ratioOfTotalDebtToProfitFromSales.title),
        twoYearsAgo: {
          items: [ratioOfTotalDebtToProfitFromSales.twoYearsAgoText],
          theme: this.getTableStringTheme(
            false,
            false,
            ratioOfTotalDebtToProfitFromSales.twoYearsAgo > 5 ||
              ratioOfTotalDebtToProfitFromSales.twoYearsAgo < 0,
          ),
        },
        oneYearAgo: {
          items: [ratioOfTotalDebtToProfitFromSales.oneYearAgoText],
          theme: this.getTableStringTheme(
            false,
            false,
            ratioOfTotalDebtToProfitFromSales.oneYearAgo > 5 ||
              ratioOfTotalDebtToProfitFromSales.oneYearAgo < 0,
          ),
        },
        currentYear: {
          items: [ratioOfTotalDebtToProfitFromSales.currentYearText],
          theme: this.getTableStringTheme(
            false,
            false,
            ratioOfTotalDebtToProfitFromSales.currentYear > 5 ||
              ratioOfTotalDebtToProfitFromSales.currentYear < 0,
          ),
        },
        summery: { quicksell: ratioOfTotalDebtToProfitFromSales.commentary },
      },
    ];
  }

  getIndicatorOfProfitabilityTableData(
    indicatorsOfProfitability: IndicatorsOfProfitability,
  ): Array<FinanceAnalysisTableData> {
    const {
      profitabilityByProfitFromSales,
      profitabilityByEarningsBeforeTaxInterests: profitabilityByEarnings,
      normOfNetProfit,
      basicEarningPower,
      returnOnAssets,
      returnOnInvestedCapital,
      returnOnEquity,
    } = indicatorsOfProfitability;
    return [
      {
        title: this.getTableRowTooltip(profitabilityByProfitFromSales.title),
        twoYearsAgo: {
          items: [profitabilityByProfitFromSales.twoYearsAgoText],
          theme: this.getTableStringTheme(
            profitabilityByProfitFromSales.twoYearsAgo > 25,
            profitabilityByProfitFromSales.twoYearsAgo < 5 &&
              profitabilityByProfitFromSales.twoYearsAgo >= 0,
            profitabilityByProfitFromSales.twoYearsAgo < 0,
          ),
        },
        oneYearAgo: {
          items: [profitabilityByProfitFromSales.oneYearAgoText],
          theme: this.getTableStringTheme(
            profitabilityByProfitFromSales.oneYearAgo > 25,
            profitabilityByProfitFromSales.oneYearAgo < 5 &&
              profitabilityByProfitFromSales.oneYearAgo >= 0,
            profitabilityByProfitFromSales.oneYearAgo < 0,
          ),
        },
        currentYear: {
          items: [profitabilityByProfitFromSales.currentYearText],
          theme: this.getTableStringTheme(
            profitabilityByProfitFromSales.currentYear > 25,
            profitabilityByProfitFromSales.currentYear < 5 &&
              profitabilityByProfitFromSales.currentYear >= 0,
            profitabilityByProfitFromSales.currentYear < 0,
          ),
        },
        referenceValue: profitabilityByProfitFromSales.referenceValue,
      },
      {
        title: this.getTableRowTooltip(profitabilityByEarnings.title),
        twoYearsAgo: {
          items: [profitabilityByEarnings.twoYearsAgoText],
          theme: this.getTableStringTheme(
            profitabilityByEarnings.twoYearsAgo > 25,
            profitabilityByEarnings.twoYearsAgo < 5 && profitabilityByEarnings.twoYearsAgo >= 0,
            profitabilityByEarnings.twoYearsAgo < 0,
          ),
        },
        oneYearAgo: {
          items: [profitabilityByEarnings.oneYearAgoText],
          theme: this.getTableStringTheme(
            profitabilityByEarnings.oneYearAgo > 25,
            profitabilityByEarnings.oneYearAgo < 5 && profitabilityByEarnings.oneYearAgo >= 0,
            profitabilityByEarnings.oneYearAgo < 0,
          ),
        },
        currentYear: {
          items: [profitabilityByEarnings.currentYearText],
          theme: this.getTableStringTheme(
            profitabilityByEarnings.currentYear > 25,
            profitabilityByEarnings.currentYear < 5 && profitabilityByEarnings.currentYear >= 0,
            profitabilityByEarnings.currentYear < 0,
          ),
        },
        referenceValue: profitabilityByEarnings.referenceValue,
      },
      {
        title: this.getTableRowTooltip(normOfNetProfit.title),
        twoYearsAgo: {
          items: [normOfNetProfit.twoYearsAgoText],
          theme: this.getTableStringTheme(
            normOfNetProfit.twoYearsAgo > 25,
            normOfNetProfit.twoYearsAgo < 5 && normOfNetProfit.twoYearsAgo >= 0,
            normOfNetProfit.twoYearsAgo < 0,
          ),
        },
        oneYearAgo: {
          items: [normOfNetProfit.oneYearAgoText],
          theme: this.getTableStringTheme(
            normOfNetProfit.oneYearAgo > 25,
            normOfNetProfit.oneYearAgo < 5 && normOfNetProfit.oneYearAgo >= 0,
            normOfNetProfit.oneYearAgo < 0,
          ),
        },
        currentYear: {
          items: [normOfNetProfit.currentYearText],
          theme: this.getTableStringTheme(
            normOfNetProfit.currentYear > 25,
            normOfNetProfit.currentYear < 5 && normOfNetProfit.currentYear >= 0,
            normOfNetProfit.currentYear < 0,
          ),
        },
        referenceValue: normOfNetProfit.referenceValue,
      },
      {
        title: this.getTableRowTooltip(basicEarningPower.title),
        twoYearsAgo: {
          items: [basicEarningPower.twoYearsAgoText],
          theme: this.getTableStringTheme(
            false,
            basicEarningPower.twoYearsAgo < 5 && basicEarningPower.twoYearsAgo >= 0,
            basicEarningPower.twoYearsAgo < 0,
          ),
        },
        oneYearAgo: {
          items: [basicEarningPower.oneYearAgoText],
          theme: this.getTableStringTheme(
            false,
            basicEarningPower.oneYearAgo < 5 && basicEarningPower.oneYearAgo >= 0,
            basicEarningPower.oneYearAgo < 0,
          ),
        },
        currentYear: {
          items: [basicEarningPower.currentYearText],
          theme: this.getTableStringTheme(
            false,
            basicEarningPower.currentYear < 5 && basicEarningPower.currentYear >= 0,
            basicEarningPower.currentYear < 0,
          ),
        },
        referenceValue: basicEarningPower.referenceValue,
      },
      {
        title: this.getTableRowTooltip(returnOnAssets.title),
        twoYearsAgo: {
          items: [returnOnAssets.twoYearsAgoText],
          theme: this.getTableStringTheme(
            false,
            returnOnAssets.twoYearsAgo < 5 && returnOnAssets.twoYearsAgo >= 0,
            returnOnAssets.twoYearsAgo < 0,
          ),
        },
        oneYearAgo: {
          items: [returnOnAssets.oneYearAgoText],
          theme: this.getTableStringTheme(
            false,
            returnOnAssets.oneYearAgo < 5 && returnOnAssets.oneYearAgo >= 0,
            returnOnAssets.oneYearAgo < 0,
          ),
        },
        currentYear: {
          items: [returnOnAssets.currentYearText],
          theme: this.getTableStringTheme(
            false,
            returnOnAssets.currentYear < 5 && returnOnAssets.currentYear >= 0,
            returnOnAssets.currentYear < 0,
          ),
        },
        referenceValue: returnOnAssets.referenceValue,
      },
      {
        title: this.getTableRowTooltip(returnOnInvestedCapital.title),
        twoYearsAgo: {
          items: [returnOnInvestedCapital.twoYearsAgoText],
          theme: this.getTableStringTheme(
            false,
            returnOnInvestedCapital.twoYearsAgo < 5 && returnOnInvestedCapital.twoYearsAgo >= 0,
            returnOnInvestedCapital.twoYearsAgo < 0,
          ),
        },
        oneYearAgo: {
          items: [returnOnInvestedCapital.oneYearAgoText],
          theme: this.getTableStringTheme(
            false,
            returnOnInvestedCapital.oneYearAgo < 5 && returnOnInvestedCapital.oneYearAgo >= 0,
            returnOnInvestedCapital.oneYearAgo < 0,
          ),
        },
        currentYear: {
          items: [returnOnInvestedCapital.currentYearText],
          theme: this.getTableStringTheme(
            false,
            returnOnInvestedCapital.currentYear < 5 && returnOnInvestedCapital.currentYear >= 0,
            returnOnInvestedCapital.currentYear < 0,
          ),
        },
        referenceValue: returnOnInvestedCapital.referenceValue,
      },
      {
        title: this.getTableRowTooltip(returnOnEquity.title),
        twoYearsAgo: {
          items: [returnOnEquity.twoYearsAgoText],
          theme: this.getTableStringTheme(
            false,
            returnOnEquity.twoYearsAgo < 5 && returnOnEquity.twoYearsAgo >= 0,
            returnOnEquity.twoYearsAgo < 0,
          ),
        },
        oneYearAgo: {
          items: [returnOnEquity.oneYearAgoText],
          theme: this.getTableStringTheme(
            false,
            returnOnEquity.oneYearAgo < 5 && returnOnEquity.oneYearAgo >= 0,
            returnOnEquity.oneYearAgo < 0,
          ),
        },
        currentYear: {
          items: [returnOnEquity.currentYearText],
          theme: this.getTableStringTheme(
            false,
            returnOnEquity.currentYear < 5 && returnOnEquity.currentYear >= 0,
            returnOnEquity.currentYear < 0,
          ),
        },
        referenceValue: returnOnEquity.referenceValue,
      },
    ];
  }
}
