import { InjectionKey } from 'vue';
import { ValidationIdentifier } from '@/modules/import/types/validationIdentifier';

export interface IIdentifiersMappingService {
  getPersonIdentifiersList(identifiers: string): Array<ValidationIdentifier>;
  getCompanyIdentifiersList(identifiers: string): Array<ValidationIdentifier>;
}

export const IIdentifiersMappingServiceKey: InjectionKey<IIdentifiersMappingService> = Symbol(
  'IIdentifiersMappingService',
);
