import { InjectionKey } from 'vue';
import { CompanyRelationsItem } from '@/modules/dossier/company/relations/types/companyRelations';

export interface ICompanyRelationsService {
  calculateCountRelationsByType(items: Array<CompanyRelationsItem>, type: string): number;
}

export const ICompanyRelationsServiceKey: InjectionKey<ICompanyRelationsService> = Symbol(
  'ICompanyRelationsService',
);
