import { defineStore } from 'pinia';
import { isProxy, reactive, toRaw, unref } from 'vue';
import { NotificationConfig } from '@/modules/notifications/types/notificationConfig';
import { useInjectable } from '@/hooks/useInjectable';
import { ISetupNotificationCleanupServiceKey } from '@/modules/notifications/services/setupNotificationCleanup.interfaces';
import { Notification } from '@/modules/notifications/types/notification';

export const useNotificationStore = defineStore('notificationStore', () => {
  const notifications = reactive<Array<NotificationConfig>>([]);

  function clearNotification(notification: NotificationConfig) {
    const notificationArrayId = notifications.findIndex(n => toRaw(n) === toRaw(notification));

    if (notificationArrayId >= 0) {
      const notification = notifications[notificationArrayId];
      notification.options.onClose?.();

      notifications.splice(notificationArrayId, 1);
    }
  }

  function fireNotification(notification: NotificationConfig): Notification {
    const notificationCleanupService = useInjectable(ISetupNotificationCleanupServiceKey);

    notifications.push(notification);

    notificationCleanupService.setupNotificationCleanup(notification, clearNotification);

    return {
      ...notification,
      close: () => clearNotification(notification),
    };
  }

  return { notifications, fireNotification, clearNotification };
});
