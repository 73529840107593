import { ReportRequisiteCardConfig } from '@/modules/reports/common/types/ReportRequisiteCardConfig';
import { ReportParentType } from '@/modules/reports/common/types/ReportParentType';
import { InjectionKey } from 'vue';

export interface IRequisiteConfigurationService {
  getRequisiteListConfig(
    entityType: ReportParentType,
  ): Array<ReportRequisiteCardConfig> | undefined;

  getRequisiteConfig(
    entityType: ReportParentType,
    requisiteId: number,
  ): ReportRequisiteCardConfig | undefined;
}

export const IRequisiteConfigurationServiceKey: InjectionKey<IRequisiteConfigurationService> =
  Symbol('IRequisiteConfigurationServiceKey');
