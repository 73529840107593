import { injectable } from 'inversify';
import { Trademark } from '@/modules/dossier/company/trademark/types/trademark';
import { ICompanyTrademarkService } from '@/modules/dossier/company/trademark/services/companyTrademarkService.interface';
import { getTime } from 'date-fns';

@injectable()
export default class CompanyTrademarkStatisticsService implements ICompanyTrademarkService {
  calculateCountTotalTrademarks(trademark: Array<Trademark>) {
    return trademark.length;
  }

  calculateCountActualTrademarks(trademark: Array<Trademark>) {
    const currentTime = Date.now();

    return trademark.reduce((acc, { dateEnd }) => {
      if (dateEnd != undefined && currentTime > getTime(new Date(dateEnd))) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }
}
