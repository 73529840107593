import { useInjectable } from '@/hooks/useInjectable';
import { CallbackUtils } from '@/modules/common/utils/callbackUtils';
import { IPluralMessagesServiceKey } from '@/modules/messages/plural/pluralMessagesService.interfaces';
import { useReportsProgressNotificationsStore } from '@/modules/reports/common/stores/reportsProgressNotificationsStore';
import { useReportsGenerationTasksInterruptionConfirmation } from '@/modules/reports/common/composables/useReportsGenerationTasksInterruptionConfirmation/useReportsGenerationTasksInterruptionConfirmation';
import { useTasksListProgressNotification } from '@/modules/tasks/composables/useTasksListProgressNotification/useTasksListProgressNotification';
import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';

export function useReportsProgressNotifications() {
  const pluralMessagesService = useInjectable(IPluralMessagesServiceKey);

  const { fireTasksListProgressNotification } = useTasksListProgressNotification();

  const { showReportsGenerationTasksInterruptionConfirmation } =
    useReportsGenerationTasksInterruptionConfirmation();

  const progressNotificationsStore = useReportsProgressNotificationsStore();
  const { notificationTasks, areNotificationsAllowed } = storeToRefs(progressNotificationsStore);

  const reportsCountLabel = computed(() =>
    pluralMessagesService.getMessage(notificationTasks.value.length, {
      one: x => (x == 1 ? 'отчета' : `${x} отчета`),
      two: x => `${x} отчетов`,
      some: x => `${x} отчетов`,
    }),
  );

  const title = computed(() => `Идет формирование ${reportsCountLabel.value}`);

  const isNotificationNeeded = computed(
    () => notificationTasks.value.length > 0 && areNotificationsAllowed.value,
  );

  watch(isNotificationNeeded, (isNotificationNeeded, _, onCleanup) => {
    if (isNotificationNeeded) {
      const notification = fireTasksListProgressNotification({
        taskIds: notificationTasks,
        title,
        quickSell: 'Пожалуйста, подождите',
        onClick: () => showReportsGenerationTasksInterruptionConfirmation(notificationTasks.value),
        onClose: () => {
          if (areNotificationsAllowed.value) {
            progressNotificationsStore.reset();
          }
        },
      });

      onCleanup(() => notification.close());
    } else {
      onCleanup(CallbackUtils.emptyFunc);
    }
  });
}
