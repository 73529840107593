import {
  UseStatelessUpdateQueueConfig
} from '@/modules/common/composables/useStatelessUpdateQueue/useStatelessUpdateQueue.interfaces';
import { useUpdateQueue } from '@/modules/common/composables/useUpdateQueue/useUpdateQueue';

export function useStatelessUpdateQueue(updateFunc: () => Promise<void>, config: UseStatelessUpdateQueueConfig = {}) {
  const { state } = useUpdateQueue(() => updateFunc(), 0, {
    ...config,
    immediate: true
  });

  function update() {
    state.value++;
  }

  return update;
}
