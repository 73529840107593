import {
  UseScopedStoreConfig
} from '@/modules/common/composables/useScopedStore/useScopedStore.interfaces';
import { tryOnScopeDispose } from '@vueuse/core';
import {
  _ExtractActionsFromSetupStore, _ExtractGettersFromSetupStore,
  _ExtractStateFromSetupStore,
  Store,
  StoreDefinition
} from 'pinia';

type SimpleStoreDefinition<Id extends string, SS> = StoreDefinition<Id, _ExtractStateFromSetupStore<SS>, _ExtractGettersFromSetupStore<SS>, _ExtractActionsFromSetupStore<SS>>
type SimpleStore<Id extends string, SS> = Store<Id, _ExtractStateFromSetupStore<SS>, _ExtractGettersFromSetupStore<SS>, _ExtractActionsFromSetupStore<SS>>

const defaultDisposeDelay = 500;
const wrappersMap = new Map<string, StoreDefinition>();

function buildWrapper<Id extends string, SS>(storeDefinition: SimpleStoreDefinition<Id, SS>, config: UseScopedStoreConfig = {}) {
  const ownerIds = new Set<Symbol>();

  function setupDispose<Id extends string, SS>(
    store: SimpleStore<Id, SS>,
    disposeDelay: number
  ) {
    setTimeout(() => {
      if(ownerIds.size == 0)
        store.$dispose();
    }, disposeDelay);
  }

  const definitionProxy = new Proxy(storeDefinition, {
    apply(target: Function, thisArg, args) {
      const id = Symbol();
      ownerIds.add(id);

      const store = target.apply(thisArg, args) as SimpleStore<Id, SS>;

      tryOnScopeDispose(() => {
        ownerIds.delete(id);

        if(ownerIds.size == 0)
          setupDispose(store, config.disposeDelay ?? defaultDisposeDelay);
      });

      return store;
    }
  });

  return definitionProxy as SimpleStoreDefinition<Id, SS>
}

export function useScopedStore<Id extends string, SS>(storeDefinition: SimpleStoreDefinition<Id, SS>, config: UseScopedStoreConfig = {}) {
  let wrapper = wrappersMap.get(storeDefinition.$id) as SimpleStoreDefinition<Id, SS>;

  if(wrapper == undefined) {
    wrapper = buildWrapper(storeDefinition, config);
    wrappersMap.set(storeDefinition.$id, wrapper as any);
  }

  return wrapper;
}
