import { inject, injectable } from 'inversify';
import { IMassImportCompanyIdentifiersParsingService } from '@/modules/import/modules/massImport/modules/company/services/massImportCompanyIdentifiersParsingService/massImportCompanyIdentifiersParsingService.interfaces';
import { MassImportCompanyIdentifier } from '@/modules/import/modules/massImport/modules/company/types/massImportCompanyIdentifier';
import { MassImportCompanyIdentifierTypes } from '@/modules/import/modules/massImport/modules/company/types/massImportCompanyIdentifierTypes';
import {
  IMassImportIdentifiersParsingService,
  IMassImportIdentifiersParsingServiceKey,
} from '@/modules/import/modules/massImport/services/massImportIdentifiersParsingService/massImportIdentifiersParsingService.interfaces';
import {
  ICompanyInnValidationService,
  ICompanyInnValidationServiceKey,
} from '@/modules/validation/services/companyInnValidationService.interfaces';
import {
  ICompanyOgrnValidationService,
  ICompanyOgrnValidationServiceKey,
} from '@/modules/validation/services/companyOgrnValidationService.interfaces';
import {
  ICompanyOgrnipValidationService,
  ICompanyOgrnipValidationServiceKey,
} from '@/modules/validation/services/companyOgrnipValidationService.interfaces';
import {
  IPersonInnValidationService,
  IPersonInnValidationServiceKey,
} from '@/modules/validation/services/personInnValidationService.interface';

@injectable()
export default class MassImportCompanyIdentifiersParsingService
  implements IMassImportCompanyIdentifiersParsingService
{
  constructor(
    @inject(IMassImportIdentifiersParsingServiceKey as symbol)
    private massImportIdentifiersParsingService: IMassImportIdentifiersParsingService,
    @inject(ICompanyInnValidationServiceKey as symbol)
    private companyInnValidationService: ICompanyInnValidationService,
    @inject(IPersonInnValidationServiceKey as symbol)
    private personInnValidationService: IPersonInnValidationService,
    @inject(ICompanyOgrnValidationServiceKey as symbol)
    private companyOgrnValidationService: ICompanyOgrnValidationService,
    @inject(ICompanyOgrnipValidationServiceKey as symbol)
    private companyOgrnipValidationService: ICompanyOgrnipValidationService,
  ) {}

  parse(identifier: string): MassImportCompanyIdentifier {
    return (
      this.parseCompanyInn(identifier) ??
      this.parseOgrn(identifier) ??
      this.parseIndividualEntrepreneurInn(identifier) ??
      this.parseOgrnip(identifier) ?? {
        type: MassImportCompanyIdentifierTypes.unknown,
        value: identifier,
      }
    );
  }

  private parseCompanyInn(identifier: string): MassImportCompanyIdentifier | undefined {
    return this.massImportIdentifiersParsingService.parse(
      identifier,
      this.companyInnValidationService,
      MassImportCompanyIdentifierTypes.inn,
    );
  }

  private parseIndividualEntrepreneurInn(
    identifier: string,
  ): MassImportCompanyIdentifier | undefined {
    return this.massImportIdentifiersParsingService.parse(
      identifier,
      this.personInnValidationService,
      MassImportCompanyIdentifierTypes.inn,
    );
  }

  private parseOgrn(identifier: string): MassImportCompanyIdentifier | undefined {
    return this.massImportIdentifiersParsingService.parse(
      identifier,
      this.companyOgrnValidationService,
      MassImportCompanyIdentifierTypes.ogrn,
    );
  }

  private parseOgrnip(identifier: string): MassImportCompanyIdentifier | undefined {
    return this.massImportIdentifiersParsingService.parse(
      identifier,
      this.companyOgrnipValidationService,
      MassImportCompanyIdentifierTypes.ogrn,
    );
  }
}
