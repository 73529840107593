import { PersonSocialNetworkAdditionalInfoVKRecordKeys } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKRecordKeys';

export module AdditionalInfoVKMainInfoRecordDictionaryUtils {
  export function getAdditionalInfoVKMainInfoRecordTranslatedKey(
    key: PersonSocialNetworkAdditionalInfoVKRecordKeys,
  ): string | undefined {
    switch (key) {
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.about:
        return 'О себе';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.infoExist:
        return 'infoExist';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.userId:
        return 'user_id';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.firstName:
        return 'Имя';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.lastName:
        return 'Фамилия';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.nickName:
        return 'Ник';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.maidenName:
        return 'Девичья фамилия';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.birthdate:
        return 'Дата рождения';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.gender:
        return 'Пол';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.status:
        return 'Статус';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.country:
        return 'Страна';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.city:
        return 'Город';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.homeCity:
        return 'Родной город';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.languages:
        return 'Языки';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.phone:
        return 'Мобильный телефон';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.site:
        return 'Личный сайт';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.skype:
        return 'Skype';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.maritalStatus:
        return 'Семейное положение';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.partnerUrl:
        return 'partner_url';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.partnerFirstname:
        return 'Имя супруга';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.partnerLastname:
        return 'Фамилия супруга';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.activities:
        return 'Деятельность';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.interests:
        return 'Интересы';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.music:
        return 'Любимая музыка';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.movies:
        return 'Любимые фильмы';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.tv:
        return 'Любимые телешоу';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.books:
        return 'Любимые книги';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.games:
        return 'Любимые игры';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.quotes:
        return 'Любимые цитаты';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.alcohol:
        return 'Отношение к алкоголю';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.smoking:
        return 'Отношение к курению';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.lifeMain:
        return 'Главное в жизни';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.peopleMain:
        return 'Главное в людях';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.political:
        return 'Политические предпочтения';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.religion:
        return 'Мировоззрение';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.inspiredBy:
        return 'Вдохновляют';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.avatars:
        return 'Аватары';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.photos:
        return 'Фотографии размещенные в профиле';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.schools:
        return 'Начальное образование';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.universities:
        return 'Высшее образование';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.career:
        return 'Трудовая деятельность';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.military:
        return 'Военная служба';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.isOnlyIds:
        return 'is_only_ids';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.isRelativesExist:
        return 'is_relatives_exist';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.isFriendsExist:
        return 'is_friends_exist';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.isFollowersExist:
        return 'is_followers_exist';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.isLikersExist:
        return 'is_likers_exist';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.isCommentatorsExist:
        return 'is_commentators_exist';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.isMentionsExist:
        return 'is_mentions_exist';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.isTaggedExist:
        return 'is_tagged_exist';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.isWhoTaggedExist:
        return 'is_who_tagged_exist';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.profileUrl:
        return 'Ссылка на профиль';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.relatives:
        return 'Родственники';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.friendsIds:
        return 'friends_ids';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.followersIds:
        return 'followers_ids';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.likersIds:
        return 'likers_ids';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.commentatorsIds:
        return 'commentators_ids';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.mentionsIds:
        return 'mentions_ids';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.taggedIds:
        return 'tagged_ids';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.whoTaggedIds:
        return 'who_tagged_ids';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.relativesUsers:
        return 'Родственники';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.friends:
        return 'Друзья';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.followers:
        return 'Подписчики';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.likers:
        return 'Лайкеры';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.commentators:
        return 'Комментаторы';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.mentions:
        return 'mentions';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.tagged:
        return 'tagged';
      case PersonSocialNetworkAdditionalInfoVKRecordKeys.whoTagged:
        return 'who_tagged';
      default:
        return undefined;
    }
  }
}
