import { useEvent } from '@/modules/events/composables/useEvent';
import { ApiProcessStatus } from '@/modules/tasks/types/api/apiProcessStatus';

export function useTaskStatusChangedEvent() {
  const { on, trigger } = useEvent<[taskId: string, status: ApiProcessStatus]>('TaskStatusChanged');

  return {
    triggerTaskStatusChangedEvent: trigger,
    onTaskStatusChanged: on,
  };
}
