import { IRequisiteConfigurationService } from '@/modules/reports/common/services/requisiteConfigurationService.interfaces';
import { ReportRequisiteCardConfig } from '@/modules/reports/common/types/ReportRequisiteCardConfig';
import { RequisiteConfiguration } from '@/modules/reports/common/configuration/requisiteConfiguration';
import { ReportParentType } from '@/modules/reports/common/types/ReportParentType';
import { injectable } from 'inversify';

@injectable()
export default class RequisiteConfigurationService implements IRequisiteConfigurationService {
  getRequisiteListConfig(
    entityType: ReportParentType,
  ): Array<ReportRequisiteCardConfig> | undefined {
    const { typesCardPerson, typesCardCompany } = RequisiteConfiguration;

    switch (Number(entityType)) {
      case ReportParentType.company:
        return typesCardCompany;
      case ReportParentType.person:
        return typesCardPerson;
      case ReportParentType.unknown:
      default:
        return undefined;
    }
  }

  getRequisiteConfig(
    entityType: ReportParentType,
    requisiteId: number,
  ): ReportRequisiteCardConfig | undefined {
    const requisiteListConfig = this.getRequisiteListConfig(entityType);

    if (requisiteListConfig == undefined) {
      return undefined;
    }

    return requisiteListConfig.find(config => config.id == requisiteId);
  }
}
