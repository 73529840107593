import { injectable } from 'inversify';
import { NumbersUtils } from '@/utils/numbersUtils';
import { DossierTagData } from '@/modules/common/types/dossierTag';
import { CompanyParticipation } from '@/modules/dossier/company/common/types/companyParticipation/companyParticipation';
import { CompanyParticipationTableData } from '@/modules/dossier/company/participations/types/companyParticipationTableData';
import { ICompanyParticipationMappingService } from '@/modules/dossier/company/participations/services/companyParticipationsMappingService.interface';

@injectable()
export default class companyParticipationMappingService
  implements ICompanyParticipationMappingService
{
  // eslint-disable-next-line class-methods-use-this
  protected getParticipationStatusTagData(status: string): DossierTagData {
    switch (status) {
      case 'Действующее':
        return {
          theme: 'success',
          text: status,
        };
      case 'Ликвидировано':
        return {
          theme: 'danger',
          text: status,
        };
      case 'Ликвидировано по суду':
        return {
          theme: 'danger',
          text: status,
        };
      case 'Ликвидировано по 129-ФЗ':
        return {
          theme: 'danger',
          text: status,
        };
      case 'Прекратило деятельность (реорганизация в форме слияния)':
        return {
          theme: 'warning',
          text: status,
        };
      case 'Прекратило деятельность (реорганизация в форме присоединения)':
        return {
          theme: 'warning',
          text: status,
        };
      case 'Прекратило деятельность (реорганизация в форме преобразования)':
        return {
          theme: 'warning',
          text: status,
        };
      case 'Юридическое лицо находится в процессе реорганизации в форме присоединения к нему других юридических лиц':
        return {
          theme: 'warning',
          text: 'В процессе реорганизации в форме присоединения к нему других юридических лиц',
        };
      case 'Юридическое лицо находится в процессе реорганизации в форме присоединения к другому юридическому лицу':
        return {
          theme: 'warning',
          text: 'В процессе реорганизации в форме присоединения к другому юридическому лицу',
        };
      case 'Прекратило деятельность (реорганизовано в форме слияния)':
        return {
          theme: 'warning',
          text: status,
        };
      case 'Прекратило деятельность (реорганизовано в форме присоединения)':
        return {
          theme: 'warning',
          text: status,
        };
      case 'Прекратило деятельность (реорганизовано в форме преобразования)':
        return {
          theme: 'warning',
          text: status,
        };
      case 'Прекратило деятельность (реорганизовано в форме выделения из другого юридического лица)':
        return {
          theme: 'warning',
          text: status,
        };
      case 'Юридическое лицо признано несостоятельным (банкротом) и в отношении него открыто конкурсное производство':
        return {
          theme: 'danger',
          text: status,
        };
      default:
        return {
          theme: 'common',
          text: 'Неизвестно',
        };
    }
  }

  getCompanyParticipationTableData(participationList: Array<CompanyParticipation>) {
    function getParticipationAuthorizedCapitalSum(authorizedCapitalSum: number | null) {
      if (authorizedCapitalSum != null) {
        return NumbersUtils.separateByThreeDigits(authorizedCapitalSum);
      }
      return '';
    }

    const sortedList = participationList.reduce(
      (acc, curr) => {
        const participation = {
          shortName: curr.shortName,
          inn: curr.inn,
          ogrn: curr.ogrn,
          percent: `${curr.percent ?? ''}`,
          authorizedCapitalSum: getParticipationAuthorizedCapitalSum(curr.authorizedCapitalSum),
          status: this.getParticipationStatusTagData(curr.status),
        };
        acc[participation.status.theme].push(participation);
        return acc;
      },
      { success: [], danger: [], warning: [], common: [] } as Record<
        string,
        Array<CompanyParticipationTableData>
      >,
    );
    const { success, danger, warning, common } = sortedList;
    return [...success, ...danger, ...warning, ...common];
  }
}
