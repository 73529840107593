import { injectable } from 'inversify';
import { ICompanyVehiclesStatisticsService } from '@/modules/dossier/company/vehicles/services/companyVehiclesStatisticsService .interface';
import { CompanyVehiclesFinesItem } from '@/modules/dossier/company/vehicles/types/companyVehiclesFines';

@injectable()
export default class CompanyVehiclesStatisticsService implements ICompanyVehiclesStatisticsService {
  calculateFineCountByType (items: Array<CompanyVehiclesFinesItem>, type: string): number {
    return items.filter(({ status }) => status == type).length;
  }
}
