import { injectable } from 'inversify';
import { IPersonParticipationIpAnalyticService } from '@/modules/dossier/person/participation/services/statistics/personParticipationIpAnalyticService.interface';
import { ApiPersonDetailIndividualEntrepreneursRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailIndividualEntrepreneursRecord';

@injectable()
export default class PersonParticipationIpAnalyticService
  implements IPersonParticipationIpAnalyticService
{
  getActivitiesList(activities: string): Array<string> {
    return activities.split(';').filter((activity) => activity !== '');
  }

  calculateActiveIp(
    legalEntitiesParticipationList: Array<ApiPersonDetailIndividualEntrepreneursRecord>,
  ): number {
    const activeIp = legalEntitiesParticipationList.filter((entity) => {
      if (entity.status == 'Действующее') {
        return entity;
      }
    });
    return activeIp.length;
  }

  calculateInactiveIp(
    legalEntitiesParticipationList: Array<ApiPersonDetailIndividualEntrepreneursRecord>,
  ): number {
    const inactiveIp = legalEntitiesParticipationList.filter((entity) => {
      if (entity.status == 'Недействующее') {
        return entity;
      }
    });
    return inactiveIp.length;
  }
}
