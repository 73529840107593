import {
  IFamilyRelationTypeFormatter
} from '@/modules/dossier/person/common/services/formatters/familyRelationTypeFormatter.interfaces';
import { FamilyRelationTypes } from '@/modules/dossier/person/common/types/api/familyRelationTypes';
import { injectable } from 'inversify';

@injectable()
export default class FamilyRelationTypeFormatter implements IFamilyRelationTypeFormatter {
  format(value: FamilyRelationTypes): string {
    switch (value) {
      case FamilyRelationTypes.Unknown: return 'Другая степень';
      case FamilyRelationTypes.Wife: return 'Жена';
      case FamilyRelationTypes.Husband: return 'Муж';
      case FamilyRelationTypes.Mother: return 'Мать';
      case FamilyRelationTypes.Father: return 'Отец';
      case FamilyRelationTypes.Sister: return 'Сестра';
      case FamilyRelationTypes.Brother: return 'Брат';
      case FamilyRelationTypes.Grandmother: return 'Бабушка';
      case FamilyRelationTypes.Grandfather: return 'Дедушка';
      case FamilyRelationTypes.Son: return 'Сын';
      case FamilyRelationTypes.Daughter: return 'Дочь';
      case FamilyRelationTypes.Grandson: return 'Внук';
      case FamilyRelationTypes.Granddaughter: return 'Внучка';
      case FamilyRelationTypes.Uncle: return 'Дядя';
      case FamilyRelationTypes.Aunt: return 'Тетя';
      case FamilyRelationTypes.Nephew: return 'Племянник';
      case FamilyRelationTypes.Niece: return 'Племянница';
      case FamilyRelationTypes.Stepfather: return 'Отчим';
      case FamilyRelationTypes.Stepdaughter: return 'Падчерица';
      default: return '';
    }
  }

}
