import { InjectionKey } from 'vue';
import { ApiPersonDetailLegalEntitiesParticipationRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailLegalEntitiesParticipationRecord';

export interface IPersonParticipationUIAnalyticService {
  calculateActivitiesAsCompanyHead(
    legalEntitiesParticipationList: Array<ApiPersonDetailLegalEntitiesParticipationRecord>,
  ): number;
  calculateActivitiesAsFounder(
    legalEntitiesParticipationList: Array<ApiPersonDetailLegalEntitiesParticipationRecord>,
  ): number;
}

export const IPersonParticipationUIAnalyticServiceKey: InjectionKey<IPersonParticipationUIAnalyticService> =
  Symbol('IPersonParticipationUIAnalyticServiceKey');
