import {
  IGraphDisplayInfoPlaceholderService
} from '@/modules/graph/table/services/graphDisplayInfoPlaceholderService.interfaces';
import { ApiGraphResultDisplayInfo } from '@/modules/graph/table/types/api/apiGraphResultDisplayInfo';
import { injectable } from 'inversify';

@injectable()
export default class GraphDisplayInfoPlaceholderService implements IGraphDisplayInfoPlaceholderService {
  getDisplayInfo(): ApiGraphResultDisplayInfo {
    return {
      isTooMuchDisplayInformation: false,
      isTooMuchDisplayInformationFromServer: false,
      maximumDisplayRecords: 0,
      totalDisplayRecords: 0,
    }
  }
}
