<template>
  <UISnackBar v-bind="props" @close="$emit('close')">
    <UISnackBarIconContainer
      class="ui-icon-snack-bar"
      :class="uiSnackBarModifierView"
      :icon-name="iconName"
    />
  </UISnackBar>
</template>

<script setup lang="ts">
import UISnackBar from '@/modules/snacks/UISnackBar/UISnackBar.vue';
import UISnackBarIconContainer from '@/modules/snacks/UISnackBar/UISnackBarIconContainer.vue';
import { SnackBarClickHandler } from '@/modules/snacks/UISnackBar/utils/snackBarClickHandler';
import { computed } from 'vue';
import { SnackBarUtils } from '@/modules/snacks/UISnackBar/utils/snackBarUtils';

import { UISnackBarStatus } from '@/modules/snacks/UISnackBar/types/UISnackBarStatus';

interface Props {
  title: string
  quickSell?: string
  status?: UISnackBarStatus
  onClick?: SnackBarClickHandler
}

const props = withDefaults(defineProps<Props>(), {
  status: 'info',
});

const iconName = computed(() => SnackBarUtils.getIconNameByStatus(props.status));
const uiSnackBarModifierView = computed(() => [`ui-icon-snack-bar--${props.status}`]);
</script>

<style lang="scss">
.ui-icon-snack-bar {
  min-width: 48px;
  height: 48px;
  &.ui-icon-snack-bar--info {
    color: var(--color-blue-1);
  }
  &.ui-icon-snack-bar--error {
    color: var(--color-red-1);
  }

  &.ui-icon-snack-bar--warning {
    color: var(--color-orange-3);
  }

  &.ui-icon-snack-bar--success {
    color: var(--color-green-7);
  }
}
</style>
