import { injectable } from 'inversify';
import { ICompanyEnforcementProceedingsStatisticsService } from '@/modules/dossier/company/enforcementProceedings/services/companyEnforcementProceedingsStatisticsService.interface';
import { EnforcementProceedings } from '@/modules/dossier/company/enforcementProceedings/types/enforcementProceedings';

@injectable()
export default class CompanyEnforcementProceedingStatisticsService
  implements ICompanyEnforcementProceedingsStatisticsService
{
  calculateTotalEPAmount(epList: Array<EnforcementProceedings>): number {
    return epList.length;
  }

  calculateActiveEPAmount(epList: Array<EnforcementProceedings>): number {
    return epList.filter(
      (ep) =>
        ep.status == 'Не завершено' ||
        ((ep.status == '' || ep.status == 'Неизвестно') && ep.enforcementProceedingEndDate == null),
    ).length;
  }

  calculateClosedEPAmount(epList: Array<EnforcementProceedings>): number {
    return epList.filter(
      (ep) =>
        ep.status == 'Погашено' ||
        ep.status == 'Завершено' ||
        (ep.status == '' && ep.enforcementProceedingEndDate != null),
    ).length;
  }

  calculateTotalEPSum(epList: Array<EnforcementProceedings>): number {
    return epList.reduce((acc, ep) => (acc += +ep.amount_owed), 0);
  }

  calculateActiveEPSum(epList: Array<EnforcementProceedings>): number {
    return epList.reduce((acc, ep) => {
      if (ep.status != 'Погашено') {
        acc += +ep.amount_owed;
      }
      return acc;
    }, 0);
  }

  calculateClosedEPSum(epList: Array<EnforcementProceedings>): number {
    return epList.reduce((acc, ep) => {
      if (ep.status == 'Погашено') {
        acc += +ep.amount_owed;
      }
      return acc;
    }, 0);
  }
}
