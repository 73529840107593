import { injectable } from 'inversify';
import { IRegulationsInputTypesService } from '@/modules/regulationsCheck/common/services/regulationInputTypesService.interface';
import { RegulationInputTypes } from '@/modules/regulationsCheck/common/types/regulationInputTypes';

@injectable()
export default class RegulationsInputTypesService implements IRegulationsInputTypesService {
  getRegulationInputTypes(status: RegulationInputTypes): string {
    switch (status) {
      case RegulationInputTypes.Standard:
        return 'Стандартная';
      case RegulationInputTypes.Extended:
        return 'Расширенная';
      case RegulationInputTypes.Relations:
        return 'Связи';
      default:
        return '';
    }
  }
}
