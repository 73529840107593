import { InjectionKey } from 'vue';
import { ApiPersonDetailEnforcementProceeding } from '@/modules/dossier/person/common/types/api/apiPersonDetailEnforcementProceeding';

export interface IPersonEnforcementProceedingsStatisticsService {
  calculateActiveEPAmount(epList: Array<ApiPersonDetailEnforcementProceeding>): number;
  calculateActiveEPSum(epList: Array<ApiPersonDetailEnforcementProceeding>): number;
}

export const IPersonEnforcementProceedingsStatisticsServiceKey: InjectionKey<IPersonEnforcementProceedingsStatisticsService> =
  Symbol('IPersonEnforcementProceedingsStatisticsServiceKey');
