import { AxiosResponse } from 'axios';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { ApiUserData } from '@/modules/administration/users/types/apiUserData';
import { InjectionKey } from 'vue';

export interface IRightsService {
  getUsers(): Promise<AxiosResponse<ApiResponse<ApiUserData>>>;
  checkValidToken(): Promise<AxiosResponse<ApiResponse<ApiUserData>>>;
}

export const IRightsServiceKey: InjectionKey<IRightsService> = Symbol('IRightsService');
