import {
  IGridPersonToAnalysisEntityMapper
} from '@/modules/analysis/services/mappers/gridPersonToAnalysisEntityMapper.interfaces';
import { AnalysisEntity } from '@/modules/analysis/types/analysisEntity';
import { AnalysisEntityType } from '@/modules/analysis/types/analysisEntityType';
import {
  IDataGridPersonNameFormatterService, IDataGridPersonNameFormatterServiceKey
} from '@/modules/dataGrid/person/services/formatters/dataGridPersonNameFormatterService/dataGridPersonNameFormatterService.interfaces';
import { ApiGridPersonRecord } from '@/modules/dataGrid/person/types/api/apiGridPersonRecord';
import { inject, injectable } from 'inversify';

@injectable()
export default class GridPersonToAnalysisEntityMapper implements IGridPersonToAnalysisEntityMapper {
  constructor(
    @inject(IDataGridPersonNameFormatterServiceKey as symbol) private nameFormatter: IDataGridPersonNameFormatterService
  ) {
  }

  map(item: ApiGridPersonRecord): AnalysisEntity {
    return {
      id: item.person_original_id,
      type: AnalysisEntityType.person,
      title: this.nameFormatter.format(item)
    }
  }
}
