<template>
  <div class="ui-spinner">
    <img class="ui-spinner__icon" v-svg-inline :src="preloaderIcon" alt="ui-spinner" />
    <span class="ui-spinner__label" v-if="hasTitle">{{ title }}</span>
  </div>
</template>

<script setup lang="ts">
import preloaderIcon from '@/assets/icons/preloader.svg';
import { computed } from 'vue';

interface Props {
  title?: string
}

const props = withDefaults(defineProps<Props>(), { title: '' });

const hasTitle = computed(() => props.title.length > 0);
</script>

<style scoped lang="scss">
.ui-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-2);
}
.ui-spinner__icon {
  animation: 2s linear 0s normal none infinite running ui-rotation;
}
.ui-spinner__label {
  margin-top: 12px;
  font-size: 24px;
  line-height: 32px;
}
</style>
