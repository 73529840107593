import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IDateFormatterServiceKey } from '@/modules/common/services/dateFormatterService/dateFormatterService.interfaces';
import DateFormatterService from '@/modules/common/services/dateFormatterService/dateFormatterService';
import FromNowDateFormatterService
  from '@/modules/common/services/fromNowDateFormatterService/fromNowDateFormatterService';
import {
  IFromNowDateFormatterServiceKey
} from '@/modules/common/services/fromNowDateFormatterService/types/iFromNowDateFormatterService';

export function bindDatesServices(bind: IContainerBinder) {
  bind(IDateFormatterServiceKey).to(DateFormatterService);
  bind(IFromNowDateFormatterServiceKey).to(FromNowDateFormatterService);
}
