import { GroupNameResult } from '@/modules/administration/types/groupNameResult';
import { InjectionKey } from 'vue';
import { RiskDetailRateParameter } from '@/modules/regulationsCheck/common/types/riskDetailRateParameter';
import { DossierRoutingType } from '@/modules/dossier/common/types/DossierRoutingType';

export interface IRegulationsCheckMarkersMappingService {
  getGroupNameAndStatusIcon(
    marker: RiskDetailRateParameter,
    parentType: DossierRoutingType,
  ): GroupNameResult;
}

export const IRegulationsCheckMarkersMappingServiceKey: InjectionKey<IRegulationsCheckMarkersMappingService> =
  Symbol('IRegulationsCheckMarkersMappingServiceKey');
