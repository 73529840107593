import { injectable } from 'inversify';
import { IRegulationsCheckMarkersMappingService } from '@/modules/regulationsCheck/common/services/regulationsCheckMarkersMappingService.interface';
import { RiskDetailRateParameter } from '@/modules/regulationsCheck/common/types/riskDetailRateParameter';
import { GroupNameResult } from '@/modules/administration/types/groupNameResult';
import { StringUtils } from '@/modules/common/utils/stringUtils';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';
import { markerWeightsPersonGroups } from '@/modules/administration/configuration/markerWeightsPersonGroups';
import { DossierRoutingType } from '@/modules/dossier/common/types/DossierRoutingType';
import { markerWeightsCompanyGroups } from '@/modules/administration/configuration/markerWeightsCompanyGroups';

@injectable()
export default class RegulationsCheckMarkersMappingService
  implements IRegulationsCheckMarkersMappingService
{
  getGroupNameAndStatusIcon(
    marker: RiskDetailRateParameter,
    parentType: DossierRoutingType,
  ): GroupNameResult {
    let foundGroup;
    if (parentType == DossierRoutingType.Person) {
      foundGroup = markerWeightsPersonGroups.find((group) => {
        const result = group.list.find((i) => StringUtils.checkEqualStrings(i, marker.name ?? ''));
        return result != undefined;
      });
    } else {
      foundGroup = markerWeightsCompanyGroups.find((group) => {
        const result = group.list.find((i) => StringUtils.checkEqualStrings(i, marker.name ?? ''));
        return result != undefined;
      });
    }

    if (foundGroup != undefined) {
      const { groupName, statusIcon, statusIconColor } = foundGroup;
      return { groupName, statusIcon, statusIconColor };
    }
    return {
      groupName: 'Неизвестно',
      statusIcon: SystemIconsNames.unknown,
      statusIconColor: 'unknown',
    };
  }
}
