import {
  UseDxTagConfig
} from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';
import {
  RegulationCheckStatus
} from '@/modules/regulationsCheck/common/types/regulationCheckStatus';
import { InjectionKey } from 'vue';

export interface IRegulationChecksStatusTagsFactory {
  getTag(status: RegulationCheckStatus): UseDxTagConfig
}

export const IRegulationChecksStatusTagsFactoryKey: InjectionKey<IRegulationChecksStatusTagsFactory> = Symbol('IRegulationChecksStatusTagsFactory');
