import { injectable, inject } from 'inversify';
import { ApiPersonDetailVkRecordStringItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecordStringItem';
import { ApiPersonDetailVkRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecord';
import { PersonSocialNetworkAdditionalInfoVKRecordKeys } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKRecordKeys';
import { PersonSocialNetworkAdditionalInfoDataItem } from '@/modules/dossier/person/socialNetworkAdditionalInfo/common/types/personSocialNetworkAdditionalInfoDataItem';
import {
  IPersonSocialNetworkAdditionalInfoVKGetLastValueService,
  IPersonSocialNetworkAdditionalInfoVKGetLastValueServiceKey,
} from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/parsers/personSocialNetworkAdditionalInfoVKGetLastValueService.interface';
import { IPersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter.interface';
import { AdditionalInfoVKMainInfoRecordDictionaryUtils } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/utils/additionalInfoVKMainInfoRecordDictionaryUtils';

@injectable()
export default class PersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter
  implements IPersonSocialNetworkAdditionalInfoVKMainInfoByKeysFormatter
{
  constructor(
    @inject(IPersonSocialNetworkAdditionalInfoVKGetLastValueServiceKey as symbol)
    private PersonSocialNetworkAdditionalInfoVKGetLastValueService: IPersonSocialNetworkAdditionalInfoVKGetLastValueService,
  ) {}

  getPersonSocialNetworkVKMainInfoFormattedDataByKeys(
    detailVkRecord: ApiPersonDetailVkRecord,
    detailVkRecordKeys: PersonSocialNetworkAdditionalInfoVKRecordKeys[],
  ): Array<PersonSocialNetworkAdditionalInfoDataItem> {
    return detailVkRecordKeys.map((key) => ({
      name: AdditionalInfoVKMainInfoRecordDictionaryUtils.getAdditionalInfoVKMainInfoRecordTranslatedKey(
        key,
      ) as string,
      value:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord[key] as Array<ApiPersonDetailVkRecordStringItem>,
        ),
    }));
  }
}
