import { INegativeInfoTypeFormatter } from '@/modules/dossier/person/common/services/formatters/negativeInfoTypeFormatter.interfaces';
import { NegativeInfoTypes } from '@/modules/dossier/person/common/types/api/negativeInfoType';
import { injectable } from 'inversify';

@injectable()
export default class NegativeInfoTypeFormatter implements INegativeInfoTypeFormatter {
  format(value: NegativeInfoTypes): string {
    switch (value) {
      case NegativeInfoTypes.Unknown:
        return 'Неизвестно';
      case NegativeInfoTypes.WantedPerson:
        return 'В розыске';
      case NegativeInfoTypes.InvalidPassportRus:
        return 'Недействительный паспорт';
      case NegativeInfoTypes.EnforcementProceedingsAvailable:
        return 'Наличие исполнительных производств';
      case NegativeInfoTypes.BadCreditHistory:
        return 'Негативная кредитная история';
      case NegativeInfoTypes.PrejudicedCriminalPenalties:
        return 'Привлекался к уголовной ответственности';
      case NegativeInfoTypes.PrejudicedAdministrativePenalties:
        return 'Привлекался к административной ответственности';
      case NegativeInfoTypes.PsychoNeurologicalOrDrugTreatmentCentres:
        return 'Учет в психоневрологических, наркологических и др. диспансерах';
      case NegativeInfoTypes.RegisterWithOVD:
        return 'Учет в ОВД РФ';
      case NegativeInfoTypes.WeaponLicense:
        return 'Лицензия на оружие';
      case NegativeInfoTypes.Incident:
        return 'Происшествия';
      case NegativeInfoTypes.DocumentExchange:
        return 'Смена документов';
      case NegativeInfoTypes.Gambling:
        return 'Азартные игры';
      case NegativeInfoTypes.Erotic:
        return 'Эротика, секс, порно';
      case NegativeInfoTypes.IllegalSoftware:
        return 'Нелегальное программное обеспечение';
      case NegativeInfoTypes.Malware:
        return 'Вредоносное программное обеспечение';
      case NegativeInfoTypes.Phishing:
        return 'Фишинг';
      case NegativeInfoTypes.Drugs:
        return 'Наркотики';
      case NegativeInfoTypes.Violence:
        return 'Насилие';
      case NegativeInfoTypes.Obscene:
        return 'Нецензура';
      case NegativeInfoTypes.Guns:
        return 'Оружие';
      case NegativeInfoTypes.ForumsChatsDating:
        return 'Форумы, чаты, знакомства';
      case NegativeInfoTypes.Bratva:
        return 'Братва';
      case NegativeInfoTypes.InternetMail:
        return 'Интернет-почта';
      case NegativeInfoTypes.InternetShop:
        return 'Интернет-магазины';
      case NegativeInfoTypes.CasualGames:
        return 'Казуальные игры';
      case NegativeInfoTypes.SocialNetworks:
        return 'Социальные сети';
      case NegativeInfoTypes.RecruitingPortals:
        return 'Рекрутинговые порталы';
      case NegativeInfoTypes.AnonymousProxyServers:
        return 'Анонимные прокси серверы';
      case NegativeInfoTypes.CreditCardPaymentSites:
        return 'Сайты оплаты кредитными картами';
      case NegativeInfoTypes.Thieves:
        return 'Воры';
      case NegativeInfoTypes.Anticollectors:
        return 'Антиколлекторы';
      case NegativeInfoTypes.EarningsOnTheInternet:
        return 'Заработок в интернет';
      case NegativeInfoTypes.SexPornPlus:
        return 'Секс, порно +';
      case NegativeInfoTypes.InvestmentTrading:
        return 'Инвестирование, фондовая биржа, трейдинг';
      case NegativeInfoTypes.CreditsLoans:
        return 'Кредиты, займы, деньги в долг';
      case NegativeInfoTypes.ProtestActivity:
        return 'Протестная активность';
      case NegativeInfoTypes.Suicide:
        return 'Суицид';
      case NegativeInfoTypes.Debt:
        return 'Долги';
      case NegativeInfoTypes.BlackList:
        return 'Чёрный список';
      case NegativeInfoTypes.SpecialAccounting:
        return 'Специальный учёт';
      case NegativeInfoTypes.SpecialPunishment:
        return 'Специальное наказание';
      case NegativeInfoTypes.CriminalLiability:
        return 'Уголовная ответственность';
    }
  }
}
