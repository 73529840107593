import useDeviceStore from '@/stores/device';
import { storeToRefs } from 'pinia';
import { computed, onMounted, shallowRef, watch } from 'vue';

export function useAppPlatformClasses() {
  const deviceStore = useDeviceStore();
  const { isDesktop, isTablet, isMobile } = storeToRefs(deviceStore);

  const targetNode = shallowRef();

  const platformClasses = computed<Record<string, boolean>>(() => ({
    'app-desktop': isDesktop.value,
    'app-tablet': isTablet.value,
    'app-mobile': isMobile.value,
    'app-desktop-or-tablet': isDesktop.value || isTablet.value,
    'app-non-desktop': !isDesktop.value,
    'app-non-tablet': !isTablet.value,
    'app-non-mobile': !isMobile.value,
  }));

  function applyPlatformClasses(targetNode: HTMLElement, classes: Record<string, boolean>) {
    Object.entries(classes).forEach(([appClass, isActive]) => {
      if (isActive) {
        document.body.classList.add(appClass);
      } else {
        document.body.classList.remove(appClass);
      }
    });
  }

  onMounted(() => {
    targetNode.value = document.body;
  });

  watch(
    [targetNode, platformClasses],
    ([targetNode, platformClasses]) => {
      if (targetNode != undefined) {
        applyPlatformClasses(targetNode, platformClasses);
      }
    },
    { immediate: true },
  );
}
