import { InjectionKey } from 'vue';
import { RegulationsChecksHistoryTableRow } from '@/modules/regulationsCheck/common/types/regulationsChecksHistoryTableRow';
import { RegulationsChecksHistoryItem } from '@/modules/regulationsCheck/common/types/regulationsChecksHistoryItem';

export interface IRegulationsChecksHistoryMappingService {
  getRegulationsChecksHistoryTableData(
    regulationsChecks: Array<RegulationsChecksHistoryItem>,
  ): Array<RegulationsChecksHistoryTableRow>;
}

export const IRegulationsChecksHistoryMappingServiceKey: InjectionKey<IRegulationsChecksHistoryMappingService> =
  Symbol('IRegulationsChecksHistoryMappingServiceKey');
