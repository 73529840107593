import { injectable } from 'inversify';
import { ApiPersonDetailVkRecordStringItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecordStringItem';
import { orderBy } from 'lodash';
import { PersonSocialNetworkAdditionalInfoAccordeonSections } from '@/modules/dossier/person/socialNetworkAdditionalInfo/common/types/personSocialNetworkAdditionalInfoAccordeonSections';
import { PersonSocialNetworkAdditionalInfoVKDataSection } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKDataSection';
import { IPersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatter } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKMainInfoByDateFormatter.interface';

@injectable()
export default class PersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatter
  implements IPersonSocialNetworkAdditionalInfoVKMainInfoByDateFormatter
{
  getPersonSocialNetworkVKMainInfoFormattedDataByDate(
    detailVkRecords: ApiPersonDetailVkRecordStringItem[],
    sectionDetailsName: PersonSocialNetworkAdditionalInfoAccordeonSections,
  ): PersonSocialNetworkAdditionalInfoVKDataSection {
    const detailVKSectionValues = detailVkRecords.map((detailVkRecord) => ({
      name: detailVkRecord.date,
      value: detailVkRecord.value,
    }));
    return {
      name: sectionDetailsName,
      dataList: orderBy(detailVKSectionValues, (value) => new Date(value.name), 'desc'),
    };
  }
}
