import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import SearchConceptOptionFormatter
  from '@/modules/searchConcept/common/services/searchConceptOptionFormatter/searchConceptOptionFormatter';
import {
  ISearchConceptOptionFormatterKey
} from '@/modules/searchConcept/common/services/searchConceptOptionFormatter/searchConceptOptionFormatter.interfaces';
import SearchConceptOptionsGroupTypeFormatter
  from '@/modules/searchConcept/common/services/searchConceptOptionsGroupTypeFormatter/searchConceptOptionsGroupTypeFormatter';
import {
  ISearchConceptOptionsGroupTypeFormatterKey
} from '@/modules/searchConcept/common/services/searchConceptOptionsGroupTypeFormatter/searchConceptOptionsGroupTypeFormatter.interfaces';
import SearchConceptOptionsSerializationService
  from '@/modules/searchConcept/common/services/searchConceptOptionsSerializationService/searchConceptOptionsSerializationService';
import {
  ISearchConceptOptionsSerializationServiceKey
} from '@/modules/searchConcept/common/services/searchConceptOptionsSerializationService/searchConceptOptionsSerializationService.interfaces';
import SearchConceptService from '@/modules/searchConcept/common/services/searchConceptService';
import {
  ISearchConceptServiceKey
} from '@/modules/searchConcept/common/services/searchConceptService.interface';
import {
  bindCompanySearchConceptService
} from '@/modules/searchConcept/company/configuration/inversify/container.companySearchConcept';
import {
  bindGeneralSearchConceptServices
} from '@/modules/searchConcept/general/configuration/inversify/container.generalSearchConcept';
import {
  bindPersonSearchConceptService
} from '@/modules/searchConcept/person/configuration/inversify/container.personSearchConcept';

export function bindSearchConceptServices(bind: IContainerBinder) {
  bind(ISearchConceptServiceKey).to(SearchConceptService);
  bind(ISearchConceptOptionFormatterKey).to(SearchConceptOptionFormatter);
  bind(ISearchConceptOptionsGroupTypeFormatterKey).to(SearchConceptOptionsGroupTypeFormatter);
  bind(ISearchConceptOptionsSerializationServiceKey).to(SearchConceptOptionsSerializationService);

  bindGeneralSearchConceptServices(bind);
  bindPersonSearchConceptService(bind);
  bindCompanySearchConceptService(bind);
}
