import { InjectionKey } from 'vue';

export interface IEnvironmentVariables {
  VUE_APP_BASE_URL: string;
  VUE_APP_DADATA_BASE_URL: string;
  VUE_APP_DADATA_TOKEN: string;
  VUE_APP_DADATA_SECRET: string;
  VUE_APP_YANDEX_KEY: string;
  VUE_APP_UI_VERSION: string;
  VUE_APP_DASHBOARD_VERSION?: string;
  VUE_APP_GRAPH_VIEWER_ENDPOINT: string;
  VUE_APP_GRAPH_VIEWER_ENDPOINT_HUB: string;
  VUE_APP_IMPORT_VERSION: string | undefined;
  VUE_APP_REPORTS_VERSION: string | undefined;
  VUE_APP_CI_COMMIT_SHORT_SHA: string | undefined;
  VUE_APP_REPORTS_URL: string;
  VUE_APP_MODE?: string;
  NODE_ENV: string;
  BASE_URL: string;
}

export const IEnvironmentVariablesKey: InjectionKey<IEnvironmentVariables> =
  Symbol('IEnvironmentVariables');
