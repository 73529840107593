import { MaybeRefFields } from '@/modules/common/types/maybeRefFields';
import { useProgressNotification } from '@/modules/notifications/composables/useProgressNotification';
import { UseTasksListProgressNotificationShowConfig } from '@/modules/tasks/composables/useTasksListProgressNotification/useTasksListProgressNotification.interfaces';
import { useTrackedTasks } from '@/modules/tasks/composables/useTrackedTasks/useTrackedTasks';
import { ApiProcessStatus } from '@/modules/tasks/types/api/apiProcessStatus';
import { TrackedTask } from '@/modules/tasks/types/trackedTask';
import { computed, effectScope, unref, watch, watchEffect } from 'vue';

function getTaskProgress(task: TrackedTask) {
  switch (task.status) {
    case ApiProcessStatus.Progress:
      return task.progress;
    case ApiProcessStatus.Complete:
    case ApiProcessStatus.Error:
      return 100;
    default:
      return 0;
  }
}

export function useTasksListProgressNotification() {
  const { fireNotification: fireProgressNotification } = useProgressNotification();

  function fireTasksListProgressNotification(
    config: MaybeRefFields<UseTasksListProgressNotificationShowConfig>,
  ) {
    const { taskIds, title, quickSell, onClick, onClose } = config;

    const scope = effectScope(true);

    const notification = scope.run(() => {
      const tasks = useTrackedTasks(taskIds);

      const progress = computed(() => {
        if (tasks.value.length == 0) {
          return 0;
        }

        const totalProgress = tasks.value.reduce((result, x) => result + getTaskProgress(x), 0);

        return Math.floor(totalProgress / tasks.value.length);
      });

      const notification = fireProgressNotification({
        value: progress,
        title,
        quickSell,
        onClick,

        onClose: () => {
          scope.stop();
          onClose?.();
        },
      });

      watch(progress, progress => {
        if (progress >= 100) {
          notification.close();
        }
      });

      return notification;
    });

    if (notification == undefined) {
      throw new Error('Error during notification construction');
    }

    return notification;
  }

  return {
    fireTasksListProgressNotification,
  };
}
