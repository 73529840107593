import { MaskOptions } from 'maska';

export const carVinMask: MaskOptions = {
  mask: 'Z'.repeat(17),
  tokens: {
    Z: {
      pattern: /[0-9ABCDEFGHJKLMNPRSTUVWXYZ]/,
      transform: (s: string) => s.toUpperCase(),
    },
  },
};

export const carVinMaskPlaceholder = '000xxxxxxxx000xxx';
