import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import {
  useDataTableSettingsChangedEvent
} from '@/modules/profile/composables/events/useDataTableSettingsChangedEvent/useDataTableSettingsChangedEvent';
import {
  IDataTableSettingsService
} from '@/modules/profile/services/dataTableSettingsService.interfaces';
import { ApiDataTableFieldProfile } from '@/modules/profile/types/api/apiDataTableFieldProfile';
import { DataTableConfigProfiles } from '@/modules/profile/types/api/dataTableConfigProfile';
import { AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify';

const { triggerDataTableSettingsChangedEvent } = useDataTableSettingsChangedEvent();

@injectable()
export class DataTableSettingsService implements IDataTableSettingsService {
  constructor(
    @inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance
  ) { }

  getSettings(name: DataTableConfigProfiles): Promise<AxiosResponse<ApiResponse<ApiDataTableFieldProfile[]>>> {
    return this.apiEndpoint.get(`/profile/data-table-settings/${name}`);
  }
  async postSettings(name: DataTableConfigProfiles, data: ApiDataTableFieldProfile[]): Promise<AxiosResponse<ApiResponse<string>>> {
    const response = await this.apiEndpoint.post(`/profile/data-table-settings/${name}`, data);

    triggerDataTableSettingsChangedEvent(name);

    return response;
  }
}
