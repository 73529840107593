import 'reflect-metadata';
import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { bindAnalysisService } from '@/modules/analysis/configuration/inversify/container.analysis';
import { bindDashboardServices } from '@/modules/dashboard/configuration/inversify/container.dashboard';
import { bindElasticService } from '@/modules/elastic/configuration/inversify/container.elastic';
import { bindGraphServices } from '@/modules/graph/common/configuration/inversify/container.graph';
import { bindIndicatorServices } from '@/modules/indicators/configuration/inversify/container.indicators';
import { bindNormalizationServices } from '@/modules/normalization/configuration/inversify/container.normalization';
import { bindOkvedServices } from '@/modules/okved/configuration/inversify/container.okved';
import { bindSearchConceptServices } from '@/modules/searchConcept/common/configuration/inversify/container.searchConcept';
import { bindRouterServices } from '@/modules/router/common/configuration/container.router';
import { bindStatisticsServices } from '@/modules/statistics/configuration/inversify/container.statistics';
import { bindSwaggerServices } from '@/modules/swagger/configuration/inversify/container.swagger';
import { bindDataCollectionServices } from '@/modules/dataCollection/configuration/inversify/configuration.dataCollection';
import { bindTaskServices } from '@/modules/tasks/configuration/inversify/container.tasks';
import { bindValidationService } from '@/modules/validation/configuration/inversify/container.validation';
import { Container } from 'inversify';
import { InjectionKey } from 'vue';
import { bindAuthServices } from '@/modules/auth/common/configuration/inversify/container.auth';
import { bindCommonServices } from '@/modules/common/configuration/inversify/container.common';
import { bindDaDataServices } from '@/modules/dadata/configuration/inversify/container.dadata';
import { bindPersonDossierServices } from '@/modules/dossier/person/common/configuration/inversify/container.personDossier';
import { bindPersonDossierNormalizationServices } from '@/modules/dossier/person/common/configuration/inversify/container.normalization';
import { bindImportServices } from '@/modules/import/configuration/inversify/container.import';
import { bindListsServices } from '@/modules/lists/configuration/inversify/container.lists';
import { bindFilesServices } from '@/modules/files/configuration/inversify/container.files';
import { bindProfileService } from '@/modules/profile/configuration/inversify/container.profile';
import { bindRouteTabsServices } from '@/modules/tabs/routeTabs/configuration/inversify/container.routeTabs';
import { bindTaxesServices } from '@/modules/taxes/configuration/inversify/container.taxes';
import { bindCompanyDossierServices } from '@/modules/dossier/company/common/configuration/inversify/container.companyDossier';
import { bindDxServices } from '@/modules/devExtreme/configuration/inversify/container.dxServices';
import { bindChartsServices } from '@/modules/charts/configuration/inversify/container.charts';
import { bindRiskServices } from '@/modules/risks/configuration/inversify/container.risks';
import { bindNotificationServices } from '@/modules/notifications/configuration/inversify/container.notifications';
import { bindAdministrationServices } from '@/modules/administration/configuration/inversify/container.administration';
import { bindPluralMessagesServices } from '@/modules/messages/plural/configuration/inversify/container.pluralMessages';
import { bindRightsServices } from '@/modules/rights/configuration/inversify/container.right';
import { bindDataGridServices } from '@/modules/dataGrid/configuration/inversify/container.dataGrid';
import { bindDataGridPersonService } from '@/modules/dataGrid/person/configuration/inversify/container.dataGridPerson';
import { bindDataGridCompanyService } from '@/modules/dataGrid/company/configuration/inversify/container.dataGridCompany';
import { bindRequisiteMatchingServices } from '@/modules/requisites/configuration/inversify/container.requisites';
import { bindRegulationChecksServices } from '@/modules/regulationsCheck/common/configuration/inversify/container.regulationCheck';
import { bindReportsServices } from '@/modules/reports/common/configuration/inversify/container.reports';
import { bindProcessingSettingsService } from '@/modules/processingSettings/configuration/inversify/container.import';
import { bindYandexMapsServices } from '@/modules/maps/yandexMaps/configuration/inversify/container.yandexMaps';
import { bindCurrencyServices } from '@/modules/currency/configuration/inversify/container.currency';
import { bindMasksServices } from '@/modules/masks/configuration/inversify/container.masks';

type Binder = (containerBinder: IContainerBinder) => void;

const binders: Array<Binder> = [
  bindCommonServices,
  bindListsServices,
  bindAuthServices,
  bindDaDataServices,
  bindFilesServices,
  bindImportServices,
  bindProfileService,
  bindRiskServices,
  bindRouteTabsServices,
  bindTaxesServices,
  bindPersonDossierServices,
  bindPersonDossierNormalizationServices,
  bindCompanyDossierServices,
  bindAdministrationServices,
  bindDxServices,
  bindChartsServices,
  bindNotificationServices,
  bindDataCollectionServices,
  bindSwaggerServices,
  bindAnalysisService,
  bindPluralMessagesServices,
  bindRegulationChecksServices,
  bindGraphServices,
  bindValidationService,
  bindRightsServices,
  bindDataGridServices,
  bindDataGridPersonService,
  bindDataGridCompanyService,
  bindRequisiteMatchingServices,
  bindNormalizationServices,
  bindReportsServices,
  bindRouterServices,
  bindProcessingSettingsService,
  bindSearchConceptServices,
  bindElasticService,
  bindYandexMapsServices,
  bindTaskServices,
  bindIndicatorServices,
  bindOkvedServices,
  bindCurrencyServices,
  bindStatisticsServices,
  bindDashboardServices,
  bindMasksServices,
];

export function getContainer() {
  const container = new Container();

  function bind<T>(key: InjectionKey<T>) {
    return container.bind<T>(key as symbol);
  }

  for (const binder of binders) binder(bind);

  return container;
}
