import { ApiProcessStatus } from '@/modules/tasks/types/api/apiProcessStatus';
import { ProcessStatus } from '@/modules/tasks/types/api/processStatus';
import { InjectionKey } from 'vue';

export interface ITaskStatusToApiStatusMapperService {
  map(value: ProcessStatus): ApiProcessStatus
  reverseMap(value: ApiProcessStatus): ProcessStatus
}

export const ITaskStatusToApiStatusMapperServiceKey: InjectionKey<ITaskStatusToApiStatusMapperService> = Symbol('ITaskStatusToApiStatusMapperService');
