import {
  DxPdfExportConfiguration
} from '@/modules/devExtreme/export/pdf/configuration/dxPdfExportConfiguration';
import {
  DxPdfExternalFontsConfiguration
} from '@/modules/devExtreme/export/pdf/configuration/dxPdfExternalFontsConfiguration';
import {
  IDxPdfCellFormatter
} from '@/modules/devExtreme/export/pdf/services/dxPdfCellFormatter/dxPdfCellFormatter.interfaces';
import {
  IDxPdfExportService
} from '@/modules/devExtreme/export/pdf/services/dxPdfExportService/dxPdfExportService.interfaces';
import {
  IDxPdfExternalFontsService
} from '@/modules/devExtreme/export/pdf/services/dxPdfExternalFontsService/dxPdfExternalFontsService.interfaces';
import { DxTableExtendedColumn } from '@/modules/devExtreme/types/dxTableExtendedColumn';
import { JsPdfOrientation } from '@/modules/jspdf/types/jsPdfOrientation';
import { exportDataGrid, PdfDataGridCell } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';

function getDxColumn(columns: DxTableExtendedColumn[], gridCell?: PdfDataGridCell): DxTableExtendedColumn | undefined {
  if (gridCell?.column != undefined) {
    const dataField = gridCell.column.dataField;
    return columns.find(x => x.dataField == dataField);
  }
}

export default class DxPdfExportService implements IDxPdfExportService {
  constructor(
    private orientation: JsPdfOrientation,
    private cellFormatters: IDxPdfCellFormatter[],
    private externalFontsService: IDxPdfExternalFontsService
  ) { }

  async exportData(tableInstance: any, columns: DxTableExtendedColumn[], tableExportName: string, selectedRowsOnly: boolean) {
    const cellFormatters = this.cellFormatters;

    const doc = new jsPDF({
      orientation: this.orientation
    });

    for(const font of DxPdfExternalFontsConfiguration.fonts)
      await this.externalFontsService.setupFontAsync(doc, font);

    doc.setFont(DxPdfExportConfiguration.defaultFontName);

    await exportDataGrid({
      component: tableInstance,
      selectedRowsOnly,
      jsPDFDocument: doc,

      customizeCell({ pdfCell, gridCell }) {
        if (pdfCell != undefined && gridCell != undefined) {
          const formatter = cellFormatters.find(x => x.cellType == gridCell.rowType);
          const dxColumn = getDxColumn(columns, gridCell);

          if (formatter != undefined && dxColumn != undefined)
            formatter.format(pdfCell, gridCell, dxColumn);
        }
      }
    });

    doc.autoPrint();

    const blob = doc.output('blob');
    window.open(URL.createObjectURL(blob));
  }
}
