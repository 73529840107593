import { useAsyncUpdates } from '@/hooks/useAsyncUpdates';
import { useInjectable } from '@/hooks/useInjectable';
import { PromiseUtils } from '@/modules/common/utils/promiseUtils';
import {
  useExternalDataParserStartedEvent
} from '@/modules/dataCollection/composables/useExternalDataParserStartedEvent';
import {
  IExternalDataParserServiceKey
} from '@/modules/dataCollection/services/externalDataParserService.interfaces';
import { ParserInputDataType } from '@/modules/dataCollection/types/api/parserInputDataType';
import {
  useCompanyDossierUpdatedEvent
} from '@/modules/dossier/company/common/composables/useCompanyDossierUpdatedEvent';
import {
  usePersonDossierUpdatedEvent
} from '@/modules/dossier/person/common/composables/usePersonDossierUpdatedEvent';
import { defineStore } from 'pinia';
import { shallowRef, watch } from 'vue';

const statusUpdateDelay = 1500;

export const useExternalDataParserStore = defineStore('ExternalDataParser', () => {
  const externalDataParserService = useInjectable(IExternalDataParserServiceKey);

  const { on: onExternalDataParserStarted } = useExternalDataParserStartedEvent();
  const { trigger: triggerPersonDossierUpdatedEvent } = usePersonDossierUpdatedEvent();
  const { trigger: triggerCompanyDossierUpdatedEvent } = useCompanyDossierUpdatedEvent();

  const progress = shallowRef(0);
  const statusUpdateRequest = shallowRef(0);

  const isPending = shallowRef(false);
  const isError = shallowRef(false);
  const isSuccess = shallowRef(false);

  const parserInputDataType = shallowRef<ParserInputDataType>(ParserInputDataType.Unknown);
  const selectedIdListEntity = shallowRef(new Array<string>);

  async function updateStatus() {
    const {
      data: {
        data,
        isError: IsRequestError
      },
    } = await externalDataParserService.getStatus();

    const { isActive } = data;

    if (IsRequestError) {
      progress.value = 0;
      isPending.value = false;
      isError.value = true;
    }

    if (isActive && progress.value <= 75)
      progress.value += 25;

    if (!isActive) {
      progress.value = 100;
      isPending.value = false;
      isSuccess.value = true;
    }
  }

  async function updateStatusAndScheduleNextUpdate() {
    await updateStatus();
    await scheduleStatusUpdate();
  }

  onExternalDataParserStarted((eventParserInputDataType, eventSelectedIdListEntity) => {
    parserInputDataType.value = eventParserInputDataType;
    selectedIdListEntity.value = eventSelectedIdListEntity;

    progress.value = 0;
    isPending.value = true;
    isError.value = false;
    isSuccess.value = false;
  });

  async function scheduleStatusUpdate() {
    await PromiseUtils.delay(statusUpdateDelay);

    if(isPending.value)
      statusUpdateRequest.value += 1;
  }

  function notifyAboutDossierUpdate() {
    const notifier = getDossierUpdatedNotifier();

    for(const id of selectedIdListEntity.value)
      notifier(id);
  }

  function getDossierUpdatedNotifier(): (id: string) => void {
    switch (parserInputDataType.value) {
      case ParserInputDataType.Person: return triggerPersonDossierUpdatedEvent;
      case ParserInputDataType.Company: return triggerCompanyDossierUpdatedEvent;
      default: return () => {};
    }
  }

  watch(isPending, () => scheduleStatusUpdate());

  watch(isSuccess, isSuccess => {
    if(isSuccess) {
      notifyAboutDossierUpdate();
    }
  });

  useAsyncUpdates(statusUpdateRequest, () => updateStatusAndScheduleNextUpdate());

  return {
    progress,
    isPending,
    isError,
    isSuccess
  }
});
