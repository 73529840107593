import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IRightsServiceKey } from '@/modules/rights/services/rightsService.interfaces';
import { RightsService } from '@/modules/rights/services/rightsService';
import { AccessScopeService } from '@/modules/rights/services/AccessScopeService';
import { IAccessScopeServiceKey } from '@/modules/rights/services/AccessScopeService.interfaces';

export function bindRightsServices(bind: IContainerBinder) {
  bind(IRightsServiceKey).to(RightsService);
  bind(IAccessScopeServiceKey).to(AccessScopeService);
}
