import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import {
  IAppModeRouterFactoryKey
} from '@/modules/router/common/factories/appModeRouterFactory.interfaces';
import RouterFactory from '@/modules/router/common/factories/routerFactory';
import { IRouterFactoryKey } from '@/modules/router/common/factories/routerFactory.interfaces';
import GeneralRouterFactory from '@/modules/router/general/factories/generalRouterFactory';
import StandaloneGraphRouterFactory
  from '@/modules/router/standaloneGraph/factories/standaloneGraphRouterFactory';

export function bindRouterServices(bind: IContainerBinder) {
  bind(IRouterFactoryKey).to(RouterFactory);
  bind(IAppModeRouterFactoryKey).to(GeneralRouterFactory);
  bind(IAppModeRouterFactoryKey).to(StandaloneGraphRouterFactory);
}
