import {
  ApiPersonAddEditDataRequest
} from '@/modules/dossier/person/common/types/api/apiPersonAddEditDataRequest';
import { DeleteApiModel } from '@/modules/dossier/person/common/types/api/deleteApiModel';
import { AxiosResponse } from 'axios';
import { InjectionKey } from 'vue';

export interface IPersonDossierCRUDService {
  addEntities(payload: ApiPersonAddEditDataRequest): Promise<AxiosResponse<void>>
  updateEntities(payload: ApiPersonAddEditDataRequest): Promise<AxiosResponse<void>>
  deleteEntities(payload: DeleteApiModel): Promise<AxiosResponse<void>>
}

export const IPersonDossierCRUDServiceKey: InjectionKey<IPersonDossierCRUDService> = Symbol('IDossierCRUDService');
