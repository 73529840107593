import { InjectionKey } from 'vue';
import { Lease } from '../types/lease';

export interface ICompanyLeasingService {
  calculateActiveLeases(leases: Array<Lease>): number;
  calculateArchivedLeases(leases: Array<Lease>): number;
  calculateLesseeSubjects(leases: Array<Lease>): number;
}

export const ICompanyLeasingStatisticsServiceKey: InjectionKey<ICompanyLeasingService> = Symbol(
  'ICompanyLeasingStatisticsService',
);
