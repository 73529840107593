import { injectable } from 'inversify';
import { IMassImportIdentifiersParsingService } from '@/modules/import/modules/massImport/services/massImportIdentifiersParsingService/massImportIdentifiersParsingService.interfaces';
import { MassImportEntityIdentifier } from '@/modules/import/modules/massImport/types/massImportEntityIdentifier';
import { ValidationService } from '@/modules/validation/types/validationService';

@injectable()
export default class MassImportIdentifiersParsingService
  implements IMassImportIdentifiersParsingService
{
  parse<T>(
    value: string,
    validationService: ValidationService,
    type: T,
  ): MassImportEntityIdentifier<T> | undefined {
    if (!validationService.validate(value)) {
      return undefined;
    }

    return {
      type,
      value,
    };
  }
}
