import {
  IDxTableExtendedColumnToDxTableColumnInfoMapperService
} from '@/modules/devExtreme/services/mappers/dxTableExtendedColumnToDxTableColumnInfoMapperService.interfaces';
import { DxTableColumnInfo } from '@/modules/devExtreme/types/dxTableColumnInfo';
import { DxTableExtendedColumn } from '@/modules/devExtreme/types/dxTableExtendedColumn';
import { injectable } from 'inversify';

@injectable()
export default class DxTableExtendedColumnToDxTableColumnInfoMapperService implements IDxTableExtendedColumnToDxTableColumnInfoMapperService {
  map(data: DxTableExtendedColumn): DxTableColumnInfo {
    return {
      dataField: data.dataField,
      visible: data.visible,
      visibleIndex: data.visibleIndex
    };
  }
}
