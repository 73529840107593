import { MutationTree, Module, Store as VuexStore, CommitOptions } from 'vuex';
import type { RootState } from '../../type';
import type { GridPinsState, Mutations } from './type';
import { MutationTypesEnum } from './type';

const moduleState: GridPinsState = {
  companyListsPins: [],
  personListsPins: [],
};

const mutations: MutationTree<GridPinsState> & Mutations = {
  [MutationTypesEnum.SET_COMPANY_LISTS_PINS](state, payload) {
    state.companyListsPins = payload;
  },
  [MutationTypesEnum.SET_PERSON_LISTS_PINS](state, payload) {
    state.personListsPins = payload;
  },
};

export type GridPinsStore<S = GridPinsState> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
};

export const GridPinsModule: Module<GridPinsState, RootState> = {
  state: moduleState,
  mutations,
};
