import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import companyContactsTypeCheckService from '@/modules/dossier/company/contacts/services/companyContactsTypeCheckService';
import { ICompanyContactsTypeCheckServiceKey } from '@/modules/dossier/company/contacts/services/companyContactsTypeCheckService.interface';
import { ICompanyContactsMappingServiceKey } from '@/modules/dossier/company/contacts/services/companyContactsMappingService.interface';
import CompanyContactsMappingService from '@/modules/dossier/company/contacts/services/companyContactsMappingService';
import { ICompanyContactsTableServiceKey } from '@/modules/dossier/company/contacts/services/CompanyContactsTableService.interface';
import CompanyContactsTableService from '@/modules/dossier/company/contacts/services/CompanyContactsTableService';

export function bindCompanyContactsTypeCheckService(bind: IContainerBinder) {
  bind(ICompanyContactsTypeCheckServiceKey).to(companyContactsTypeCheckService);
  bind(ICompanyContactsMappingServiceKey).to(CompanyContactsMappingService);
  bind(ICompanyContactsTableServiceKey).to(CompanyContactsTableService);
}
