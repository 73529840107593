import { MaybeRefFields } from '@/modules/common/types/maybeRefFields';
import { UseStaticNotificationConfig } from '@/modules/notifications/composables/useStaticNotification.interfaces';
import { useNotificationStore } from '@/modules/notifications/stores/notificationStore';
import { Notification } from '@/modules/notifications/types/notification';
import { NotificationDuration } from '@/modules/notifications/types/notificationDuration';
import { NotificationOptions } from '@/modules/notifications/types/notificationOptions';
import UIIconSnackBar from '@/modules/snacks/UISnackBar/UIIconSnackBar.vue';

export function useStaticNotification(options?: NotificationOptions) {
  const notificationStore = useNotificationStore();

  const defaults = {
    duration: NotificationDuration.long,
  };

  const optionsOrDefaults = options != undefined ? { ...defaults, ...options } : defaults;

  function fireNotification(config: MaybeRefFields<UseStaticNotificationConfig>): Notification {
    return notificationStore.fireNotification({
      component: UIIconSnackBar,
      config,
      options: optionsOrDefaults,
    });
  }

  return {
    fireNotification,
  };
}
