import { InjectionKey } from 'vue';
import { RegulationCheckStatus } from '@/modules/regulationsCheck/common/types/regulationCheckStatus';
import { DossierTagData } from '@/modules/common/types/dossierTag';

export interface IRegulationVettingStatusesService {
  getRegulationVettingStatuses(status: RegulationCheckStatus): DossierTagData;
}

export const IRegulationVettingStatusesServiceKey: InjectionKey<IRegulationVettingStatusesService> =
  Symbol('IRegulationVettingStatusesServiceKey');
