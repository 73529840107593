import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { ICompanyParticipationMappingServiceKey } from '@/modules/dossier/company/participations/services/companyParticipationsMappingService.interface';
import CompanyParticipationMappingService from '@/modules/dossier/company/participations/services/companyParticipationsMappingService';
import { ICompanyParticipationsTableServiceKey } from '@/modules/dossier/company/participations/services/companyParticipationsTableService.interface';
import { CompanyParticipationsTableService } from '@/modules/dossier/company/participations/services/companyParticipationsTableService';

export function bindCompanyParticipationServices(bind: IContainerBinder) {
  bind(ICompanyParticipationMappingServiceKey).to(CompanyParticipationMappingService);
  bind(ICompanyParticipationsTableServiceKey).to(CompanyParticipationsTableService);
}
