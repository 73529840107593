<template>
  <div v-if="isActive" class="overlay-root" @click.self="handleRootClick">
      <OverlayLayer
        v-for="(layer, layerIdx) in layers"
        :layer="layer"
        :layer-idx="layerIdx"
        v-show="layer === topLayer"
      />
  </div>
</template>

<script setup lang="ts">
import { useEffects } from '@/modules/common/composables/useEffects';
import OverlayLayer from '@/modules/overlay/components/OverlayLayer/OverlayLayer.vue';
import { useOverlayStore } from '@/modules/overlay/stores/overlayStore';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';

const overlayStore = useOverlayStore();
const { layers } = storeToRefs(overlayStore);

const hasLayers = computed(() => layers.value.length > 0);
const isActive = computed(() => hasLayers.value);

const topLayer = computed(() => _.last(layers.value));
const closeOnBackgroundClick = computed(() => topLayer.value?.closeOnBackgroundClick);

function handleRootClick() {
  if (closeOnBackgroundClick.value)
    topLayer.value?.close();
}

watch(isActive, (isActive, _, cleanup) => {
  if (isActive) {
    const { body, documentElement, scrollingElement } = document;

    const effects = useEffects();

    const scrollPos = scrollingElement?.scrollTop ?? 0;

    documentElement.classList.add('with-overlay');

    effects.register(() => {
      documentElement.classList.remove('with-overlay');
    });

    const savedBodyHeight = body.style.height;
    body.style.height = `${window.innerHeight}px`;

    effects.register(() => {
      body.style.height = savedBodyHeight;
    });

    body.scrollTop = scrollPos;

    effects.register(() => {
      if (scrollingElement != undefined)
        scrollingElement.scrollTop = scrollPos;
    });

    cleanup(() => effects.dispose());

    //кликаем на документе чтобы закрыть все активные выпадающие списки
    //bugfix перекрытия overlay и floating-vue
    document.documentElement.click();
  }
});
</script>

<style lang="scss">
@import '@/styles/_layers.scss';

.with-overlay {
  overflow: hidden;

  body {
    overflow: hidden;
  }
}

.overlay-root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $overlayLayer;
  display: flex;
}
</style>
