import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { ICompanyArbitrationMappingServiceKey } from '@/modules/dossier/company/arbitrations/services/companyArbitrationMappingService.interface';
import { ICompanyArbitrationRisksStatisticPlaintiffServiceKey } from '@/modules/dossier/company/arbitrations/services/companyArbitrationRisksStatisticPlaintiffService.interface';
import { ICompanyArbitrationRisksStatisticDefendantServiceKey } from '@/modules/dossier/company/arbitrations/services/companyArbitrationRisksStatisticDefendantService.interface';
import CompanyArbitrationMappingService from '@/modules/dossier/company/arbitrations/services/companyArbitrationMappingService';
import CompanyArbitrationRisksStatisticPlaintiffService from '@/modules/dossier/company/arbitrations/services/companyArbitrationRisksStatisticPlaintiffService';
import CompanyArbitrationRisksStatisticDefendantService from '@/modules/dossier/company/arbitrations/services/companyArbitrationRisksStatisticDefendantService';

export function bindCompanyArbitrationServices(bind: IContainerBinder) {
  bind(ICompanyArbitrationMappingServiceKey).to(CompanyArbitrationMappingService);
  bind(ICompanyArbitrationRisksStatisticPlaintiffServiceKey).to(
    CompanyArbitrationRisksStatisticPlaintiffService,
  );
  bind(ICompanyArbitrationRisksStatisticDefendantServiceKey).to(
    CompanyArbitrationRisksStatisticDefendantService,
  );
}
