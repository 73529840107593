import { InjectionKey, Ref } from 'vue';
import { ProcessingSettings } from '@/modules/processingSettings/types/processingSettings';

export interface IProcessingSettingsService {
  settings: Ref<ProcessingSettings>;
}

export const IProcessingSettingsServiceKey: InjectionKey<IProcessingSettingsService> = Symbol(
  'IProcessingSettingsService',
);
