<template>
  <div class="overlay-layer" :class="rootModificators">
    <UIComponent :definition="layer.component" @close="layer.close()" />
  </div>
</template>

<script setup lang="ts">
import UIComponent from '@/modules/common/components/UIComponent.vue';
import { OverlayLayerObject } from '@/modules/overlay/types/overlayLayerObject';
import { computed, toRefs } from 'vue';

interface Props {
  layerIdx: number
  layer: OverlayLayerObject

}

const props = defineProps<Props>();
const { layer, layerIdx } = toRefs(props);

const verticalAlignment = computed(() => layer.value.verticalAlignment);
const horizontalAlignment = computed(() => layer.value.horizontalAlignment);

const verticalAlignmentModificators = computed(() => ({
  'overlay-layer--vertical-alignment-top': verticalAlignment.value == 'top',
  'overlay-layer--vertical-alignment-center': verticalAlignment.value == 'center',
  'overlay-layer--vertical-alignment-bottom': verticalAlignment.value == 'bottom',
  'overlay-layer--vertical-alignment-stretch': verticalAlignment.value == 'stretch',
}));

const horizontalAlignmentModificators = computed(() => ({
  'overlay-layer--horizontal-alignment-left': horizontalAlignment.value == 'left',
  'overlay-layer--horizontal-alignment-center': horizontalAlignment.value == 'center',
  'overlay-layer--horizontal-alignment-right': horizontalAlignment.value == 'right',
}));

const rootModificators = computed(() => [
  verticalAlignmentModificators.value,
  horizontalAlignmentModificators.value,
]);
</script>

<style lang="scss">
.overlay-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-black-2);
  display: flex;

  &.overlay-layer--vertical-alignment-top {
    align-items: flex-start;
  }

  &.overlay-layer--vertical-alignment-center {
    align-items: center;
  }

  &.overlay-layer--vertical-alignment-bottom {
    align-items: flex-end;
  }

  &.overlay-layer--vertical-alignment-stretch {
    align-items: stretch;
  }

  &.overlay-layer--horizontal-alignment-left {
    justify-content: flex-start;
  }

  &.overlay-layer--horizontal-alignment-center {
    justify-content: center;
  }

  &.overlay-layer--horizontal-alignment-right {
    justify-content: flex-end;
  }
}
</style>

<style scoped lang="scss">
.overlay-layer {
  z-index: v-bind(layerIdx);
}
</style>
