import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IAdministrationMarkersMappingServiceKey } from '@/modules/administration/services/mappers/administrationMarkersMappingService.interface';
import AdministrationMarkersMappingService from '@/modules/administration/services/mappers/administrationMarkersMappingService';
import MarkerWeightParentTypeFormatter from '@/modules/administration/services/formatters/markerWeightParentTypeFormatter';
import { IMarkerWeightParentTypeFormatterKey } from '@/modules/administration/services/formatters/markerWeightParentTypeFormatter.interfaces';
import IUserRoleFormatterService from '@/modules/administration/users/services/formatters/userRoleFormatterService';
import { IUserRoleFormatterServiceKey } from '@/modules/administration/users/services/formatters/userRoleFormatterService.interfaces';
import IAdministrationUsersDataNormalizationService from '@/modules/administration/users/services/normalization/administrationUsersDataNormalizationService';
import { IAdministrationUsersDataNormalizationServiceKey } from '@/modules/administration/users/services/normalization/administrationUsersDataNormalizationService.interfaces';
import UsersService from '@/modules/administration/users/services/usersService';
import { IUsersServiceKey } from '@/modules/administration/users/services/usersService.interfaces';
import AdministrationCompaniesTypesMappingService from '@/modules/administration/services/mappers/administrationCompaniesTypesMappingService';
import { IAdministrationCompaniesTypesMappingServiceKey } from '@/modules/administration/services/mappers/administrationCompaniesTypesMappingService.interface';

export function bindAdministrationServices(bind: IContainerBinder) {
  bind(IAdministrationMarkersMappingServiceKey).to(AdministrationMarkersMappingService);
  bind(IMarkerWeightParentTypeFormatterKey).to(MarkerWeightParentTypeFormatter);
  bind(IUserRoleFormatterServiceKey).to(IUserRoleFormatterService);
  bind(IAdministrationUsersDataNormalizationServiceKey).to(
    IAdministrationUsersDataNormalizationService,
  );
  bind(IUsersServiceKey).to(UsersService);
  bind(IAdministrationCompaniesTypesMappingServiceKey).to(
    AdministrationCompaniesTypesMappingService,
  );
}
