import { useEvent } from '@/modules/events/composables/useEvent';

export function useReportsGenerationTaskStartedEvent() {
  const { on, trigger } = useEvent<[taskId: string]>('ReportsGenerationTaskStarted');

  return {
    triggerGenerationTaskStartedEvent: trigger,
    onGenerationTaskStarted: on,
  };
}
