import { injectable } from 'inversify';
import { IMarkerWeightParentTypeFormatter } from '@/modules/administration/services/formatters/markerWeightParentTypeFormatter.interfaces';
import { WeightParentType } from '@/modules/administration/types/weightParentType';

@injectable()
export default class MarkerWeightParentTypeFormatter implements IMarkerWeightParentTypeFormatter {
  format(value: WeightParentType): string {
    switch (value) {
      case WeightParentType.Company:
        return 'Компания';
      case WeightParentType.Person:
        return 'Персона';
      case WeightParentType.Unknown:
        return 'Неизвестно';
      default:
        return '';
    }
  }
}
