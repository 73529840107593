import { DateFormatConfiguration } from '@/configuration/dateFormatConfiguration';
import {
  IDateFormatterService, IDateFormatterServiceKey
} from '@/modules/common/services/dateFormatterService/dateFormatterService.interfaces';
import {
  IFromNowDateFormatterService
} from '@/modules/common/services/fromNowDateFormatterService/types/iFromNowDateFormatterService';
import { inject, injectable } from 'inversify';

const msInDay = 1000 * 60 * 60 * 24;

@injectable()
export default class FromNowDateFormatterService implements IFromNowDateFormatterService {
  constructor(
    @inject(IDateFormatterServiceKey as symbol) private dateFormatter: IDateFormatterService
  ) {
  }

  format(date: Date): string | undefined {
    const diff = Date.now() - date.getTime();

    if(diff > 0) {
      if(diff < msInDay)
        return 'Сегодня';

      if(diff < msInDay * 2)
        return 'Вчера';
    }

    return this.dateFormatter.format(date, DateFormatConfiguration.fullDate);
  }

}
