import { InjectionKey } from 'vue';
import { CompanyCertificates } from '@/types/companyData/CompanyCertificates';
import { CertificationsAndDeclarations } from '@/modules/dossier/company/common/types/companyCertificatesAndDeclarations';

export interface ICompanyCertificatesMappingService {
  getCertificatesAnalyticsTableData(
    allRoles: Array<string>,
    certificates: Array<CompanyCertificates>,
  ): Array<CertificationsAndDeclarations>;
}

export const ICompanyCertificatesMappingServiceKey: InjectionKey<ICompanyCertificatesMappingService> =
  Symbol('ICompanyCertificatesMappingServiceKey');
