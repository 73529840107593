import { inject, injectable } from 'inversify';
import { PluralMessagesConfig } from '@/modules/messages/plural/types/pluralMessagesConfig';
import {
  IMessagePointerService,
  IMessagePointerServiceKey,
} from '@/modules/messages/plural/messagePointerService.interfaces';
import { IPluralMessagesService } from '@/modules/messages/plural/pluralMessagesService.interfaces';

@injectable()
export class PluralMessagesServices implements IPluralMessagesService {
  constructor(
    @inject(IMessagePointerServiceKey as symbol)
    private messagePointerServices: IMessagePointerService,
  ) {}

  getMessage(number: number, list: PluralMessagesConfig): string {
    const currentIndex = this.messagePointerServices.getMessagePointer(number);

    const { some, one, two } = list;

    switch (currentIndex) {
      case 1: return two(number);
      case 2: return some(number);
      default: return one(number);
    }
  }
}
