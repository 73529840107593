import {
  RegulationsCheckRootType
} from '@/modules/regulationsCheck/common/types/api/regulationsCheckRootType';
import { InjectionKey } from 'vue';

export interface IRegulationCheckRootTypeFormatterService {
  format(rootType: RegulationsCheckRootType): string
}

export const IRegulationCheckRootTypeFormatterServiceKey: InjectionKey<IRegulationCheckRootTypeFormatterService> = Symbol('IRegulationCheckRootTypeFormatterService');
