import { injectable, inject } from 'inversify';
import { ApiPersonDetailVkRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailVkRecord';
import {
  IPersonSocialNetworkAdditionalInfoVKGetLastValueService,
  IPersonSocialNetworkAdditionalInfoVKGetLastValueServiceKey,
} from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/parsers/personSocialNetworkAdditionalInfoVKGetLastValueService.interface';
import { IFilesService, IFilesServiceKey } from '@/modules/files/services/filesService.interface';
import { IPersonSocialNetworkAdditionalInfoVKCardInfoFormatter } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/services/formatters/personSocialNetworkAdditionalInfoVKCardInfoFormatter.interface';
import { PersonSocialNetworkAdditionalInfoVKCardInfo } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoVK/types/personSocialNetworkAdditionalInfoVKCardInfo';

@injectable()
export default class PersonSocialNetworkAdditionalInfoVKCardInfoFormatter
  implements IPersonSocialNetworkAdditionalInfoVKCardInfoFormatter
{
  constructor(
    @inject(IFilesServiceKey as symbol)
    private FilesService: IFilesService,
    @inject(IPersonSocialNetworkAdditionalInfoVKGetLastValueServiceKey as symbol)
    private PersonSocialNetworkAdditionalInfoVKGetLastValueService: IPersonSocialNetworkAdditionalInfoVKGetLastValueService,
  ) {}

  getAdditionalInfoVKCardInfoFormattedData(
    detailVkRecord: ApiPersonDetailVkRecord,
  ): PersonSocialNetworkAdditionalInfoVKCardInfo {
    const latestAvatar = detailVkRecord.avatars[0]?.original_url || '';
    return {
      avatar: this.FilesService.getDownloadUrl(latestAvatar),
      firstname:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord.firstname,
        ),
      lastname:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord.lastname,
        ),
      profileUrl: detailVkRecord.profile_url,
      birthdate:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord.birthdate,
        ),
      homeCity:
        this.PersonSocialNetworkAdditionalInfoVKGetLastValueService.getPersonSocialNetworkVKMainInfoLatestValue(
          detailVkRecord.home_city,
        ),
    };
  }
}
