import { inject, injectable } from 'inversify';
import { RegulationCheckTarget } from '@/modules/regulationsCheck/common/components/RegulationChecksModal/types/RegulationCheckTarget';
import { ParentDomainType } from '@/modules/dossier/company/relations/types/companyRelations';
import { IPersonGridToRegulationCheckTargetMapper } from '@/modules/dataGrid/person/services/mappers/personGridToRegulationCheckTargetMapper/personGridToRegulationCheckTargetMapper.interfaces';
import {
  IDataGridPersonNameFormatterService,
  IDataGridPersonNameFormatterServiceKey,
} from '@/modules/dataGrid/person/services/formatters/dataGridPersonNameFormatterService/dataGridPersonNameFormatterService.interfaces';
import { ApiGridPersonRecord } from '@/modules/dataGrid/person/types/api/apiGridPersonRecord';

@injectable()
export default class PersonGridToRegulationCheckTargetMapper implements IPersonGridToRegulationCheckTargetMapper {
  constructor(@inject(IDataGridPersonNameFormatterServiceKey as symbol) private personNameFormatter: IDataGridPersonNameFormatterService) {}

  map(item: ApiGridPersonRecord): RegulationCheckTarget {
    return {
      id: item.person_original_id,
      name: this.personNameFormatter.format(item),
      parentType: ParentDomainType.Person,
    };
  }
}
