export enum ProcessTaskStepType {
  Unknown = 0,
  Import = 1,
  Merge = 2,
  DataAggregationParsing = 3,
  EasyReportsGeneration = 4,
  ListCreation = 5,
  PostProcessing = 6,

  PersonsRisksCalculation = 100,
  CompaniesRisksCalculation = 101,

  StatisticsCalculation = 200,

  UlListStatisticsCalculation = 301,
  PersonListStatisticsCalculation = 302
}
