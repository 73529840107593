export module HtmlUtils {
  export function isElementEllipsis(element: HTMLElement) {
    const styles = getComputedStyle(element);
    const elementWidth = parseFloat(styles.width);
    const ctx = document.createElement('canvas').getContext('2d');

    if (ctx == undefined) {
      return false;
    }

    ctx.font = `${styles.fontSize} ${styles.fontFamily}`;
    const text = ctx.measureText(element.innerText);

    return text.width > elementWidth;
  }

  export function cleanHTML(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;

    return div.textContent ?? div.innerText ?? '';
  }

  export function getScrollingContainer(element: HTMLElement | undefined): HTMLElement | undefined {
    while (element != undefined) {
      const style = window.getComputedStyle(element);
      const overflow = style.getPropertyValue('overflow-y');

      if (overflow === 'auto' || overflow === 'scroll') {
        return element;
      }

      element = element.parentElement ?? undefined;
    }

    return element;
  }
}
