import { RawOkvedInfoSection } from '@/modules/okved/types/rawOkvedInfoSection';

export module OkvedConfiguration {
  export const defaultSection: RawOkvedInfoSection = {
    name: 'Наименование раздела не найдено',
    children: [
      {
        code: -1,
        name: 'Наименование класса не найдено',
        imageName: 'default.png'
      }
    ]
  }

  export const sections: RawOkvedInfoSection[] = [
    {
      name: 'Сельское, лесное хозяйство, охота, рыболовство и рыбоводство',
      children: [
        {
          code: 1,
          name: 'Растениеводство и животноводство, охота и предоставление соответствующих услуг в этих областях',
          imageName: '1.png',
        },
        {
          code: 2,
          name: 'Лесоводство и лесозаготовки',
          imageName: '2.png',
        },
        {
          code: 3,
          name: 'Рыболовство и рыбоводство',
          imageName: '3.png',
        },
      ],
    },
    {
      name: 'Добыча полезных ископаемых',
      children: [
        {
          code: 5,
          name: 'Добыча угля',
          imageName: '5-9.png',
        },
        {
          code: 6,
          name: 'Добыча сырой нефти и природного газа',
          imageName: '5-9.png',
        },
        {
          code: 7,
          name: 'Добыча металлических руд',
          imageName: '5-9.png',
        },
        {
          code: 8,
          name: 'Добыча прочих полезных ископаемых',
          imageName: '5-9.png',
        },
        {
          code: 9,
          name: 'Предоставление услуг в области добычи полезных ископаемых',
          imageName: '5-9.png',
        },
      ],
    },
    {
      name: 'Обрабатывающие производства',
      children: [
        {
          code: 10,
          name: 'Производство пищевых продуктов',
          imageName: '10-11.png',
        },
        {
          code: 11,
          name: 'Производство напитков',
          imageName: '10-11.png',
        },
        {
          code: 12,
          name: 'Производство табачных изделий',
          imageName: '12.png',
        },
        {
          code: 13,
          name: 'Производство текстильных изделий',
          imageName: '13-15.png',
        },
        {
          code: 14,
          name: 'Производство одежды',
          imageName: '13-15.png',
        },
        {
          code: 15,
          name: 'Производство кожи и изделий из кожи',
          imageName: '13-15.png',
        },
        {
          code: 16,
          name: 'Обработка древесины и производство изделий из дерева и пробки, кроме мебели, производство изделий из соломки и материалов для плетения',
          imageName: '16.png',
        },
        {
          code: 31,
          name: 'Производство мебели',
          imageName: '28-33.png',
        },
        {
          code: 17,
          name: 'Производство бумаги и бумажных изделий',
          imageName: '17-18.png',
        },
        {
          code: 18,
          name: 'Деятельность полиграфическая и копирование носителей информации',
          imageName: '17-18.png',
        },
        {
          code: 19,
          name: 'Производство кокса и нефтепродуктов',
          imageName: '19.png',
        },
        {
          code: 20,
          name: 'Производство химических веществ и химических продуктов',
          imageName: '20.png',
        },
        {
          code: 21,
          name: 'Производство лекарственных средств и материалов, применяемых в медицинских целях',
          imageName: '21.png',
        },
        {
          code: 22,
          name: 'Производство резиновых и пластмассовых изделий',
          imageName: '22.png',
        },
        {
          code: 23,
          name: 'Производство прочей неметаллической минеральной продукции',
          imageName: '23.png',
        },
        {
          code: 24,
          name: 'Производство металлургическое',
          imageName: '24-25.png',
        },
        {
          code: 25,
          name: 'Производство готовых металлических изделий, кроме машин и оборудования',
          imageName: '24-25.png',
        },
        {
          code: 26,
          name: 'Производство компьютеров, электронных и оптических изделий',
          imageName: '26-27.png',
        },
        {
          code: 27,
          name: 'Производство электрического оборудования',
          imageName: '26-27.png',
        },
        {
          code: 28,
          name: 'Производство машин и оборудования, не включенных в другие группировки',
          imageName: '28-33.png',
        },
        {
          code: 29,
          name: 'Производство автотранспортных средств, прицепов и полуприцепов',
          imageName: '28-33.png',
        },
        {
          code: 30,
          name: 'Производство прочих транспортных средств и оборудования',
          imageName: '28-33.png',
        },
        {
          code: 33,
          name: 'Ремонт и монтаж машин и оборудования',
          imageName: '28-33.png',
        },
        {
          code: 32,
          name: 'Производство прочих готовых изделий',
          imageName: '28-33.png',
        },
      ],
    },
    {
      name: 'Обеспечение электрической энергией, газом и паром; кондиционирование воздуха',
      children: [
        {
          code: 35,
          name: 'Обеспечение электрической энергией, газом и паром; кондиционирование воздуха',
          imageName: '35.png',
        },
      ],
    },
    {
      name: 'Водоснабжение; водоотведение, организация сбора и утилизации отходов, деятельность по ликвидации загрязнений',
      children: [
        {
          code: 36,
          name: 'Забор, очистка и распределение воды',
          imageName: '36-40.png',
        },
        {
          code: 37,
          name: 'Сбор и обработка сточных вод',
          imageName: '36-40.png',
        },
        {
          code: 38,
          name: 'Сбор, обработка и утилизация отходов; обработка вторичного сырья',
          imageName: '36-40.png',
        },
        {
          code: 39,
          name: 'Предоставление услуг в области ликвидации последствий загрязнений и прочих услуг, связанных с удалением отходов',
          imageName: '36-40.png',
        },
      ],
    },
    {
      name: 'Строительство',
      children: [
        {
          code: 41,
          name: 'Строительство зданий',
          imageName: '41-44.png',
        },
        {
          code: 42,
          name: 'Строительство инженерных сооружений',
          imageName: '41-44.png',
        },
        {
          code: 43,
          name: 'Работы строительные специализированные',
          imageName: '41-44.png',
        },
      ],
    },
    {
      name: 'Торговля оптовая и розничная; ремонт автотранспортных средств и мотоциклов',
      children: [
        {
          code: 45,
          name: 'Торговля оптовая и розничная автотранспортными средствами и мотоциклами и их ремонт',
          imageName: '45-48.png',
        },
        {
          code: 46,
          name: 'Торговля оптовая, кроме оптовой торговли автотранспортными средствами и мотоциклами',
          imageName: '45-48.png',
        },
        {
          code: 47,
          name: 'Торговля розничная, кроме торговли автотранспортными средствами и мотоциклами',
          imageName: '45-48.png',
        },
      ],
    },
    {
      name: 'Транспортировка и хранение',
      children: [
        {
          code: 49,
          name: 'Деятельность сухопутного и трубопроводного транспорта',
          imageName: '49-51.png',
        },
        {
          code: 50,
          name: 'Деятельность водного транспорта',
          imageName: '49-51.png',
        },
        {
          code: 51,
          name: 'Деятельность воздушного и космического транспорта',
          imageName: '49-51.png',
        },
        {
          code: 52,
          name: 'Складское хозяйство и вспомогательная транспортная деятельность',
          imageName: '52-53.png',
        },
        {
          code: 53,
          name: 'Деятельность почтовой связи и курьерская деятельность',
          imageName: '52-53.png',
        },
      ],
    },
    {
      name: 'Деятельность гостиниц и предприятий общественного питания',
      children: [
        {
          code: 55,
          name: 'Деятельность по предоставлению мест для временного проживания',
          imageName: '55.png',
        },
        {
          code: 56,
          name: 'Деятельность по предоставлению продуктов питания и напитков',
          imageName: '56.png',
        },
      ],
    },
    {
      name: 'Деятельность в области информации и связи',
      children: [
        {
          code: 58,
          name: 'Деятельность издательская',
          imageName: '58.png',
        },
        {
          code: 59,
          name: 'Производство кинофильмов, видеофильмов и телевизионных программ, издание звукозаписей и нот',
          imageName: '59-61.png',
        },
        {
          code: 60,
          name: 'Производство кинофильмов, видеофильмов и телевизионных программ, издание звукозаписей и нот',
          imageName: '59-61.png',
        },
        {
          code: 61,
          name: 'Производство кинофильмов, видеофильмов и телевизионных программ, издание звукозаписей и нот',
          imageName: '59-61.png',
        },
        {
          code: 62,
          name: 'Разработка компьютерного программного обеспечения, консультационные услуги в данной области и другие сопутствующие услуги',
          imageName: '62-63.png',
        },
        {
          code: 63,
          name: 'Деятельность в области информационных технологий',
          imageName: '62-63.png',
        },
      ],
    },
    {
      name: 'Деятельность финансовая и страховая',
      children: [
        {
          code: 64,
          name: 'Деятельность по предоставлению финансовых услуг, кроме услуг по страхованию и пенсионному обеспечению',
          imageName: '64-67.png',
        },
        {
          code: 65,
          name: 'Страхование, перестрахование, деятельность негосударственных пенсионных фондов, кроме обязательного социального обеспечения',
          imageName: '64-67.png',
        },
        {
          code: 66,
          name: 'Деятельность вспомогательная в сфере финансовых услуг и страхования',
          imageName: '64-67.png',
        },
      ],
    },
    {
      name: 'Деятельность по операциям с недвижимым имуществом',
      children: [
        {
          code: 68,
          name: 'Операции с недвижимым имуществом',
          imageName: '68.png',
        },
      ],
    },
    {
      name: 'Деятельность профессиональная, научная и техническая',
      children: [
        {
          code: 69,
          name: 'Деятельность в области права и бухгалтерского учета',
          imageName: '69-70.png',
        },
        {
          code: 70,
          name: 'Деятельность головных офисов; консультирование по вопросам управления',
          imageName: '69-70.png',
        },
        {
          code: 71,
          name: 'Деятельность в области архитектуры и инженерно-технического проектирования; технических испытаний, исследований и анализа',
          imageName: '71-72.png',
        },
        {
          code: 72,
          name: 'Научные исследования и разработки',
          imageName: '71-72.png',
        },
        {
          code: 73,
          name: 'Деятельность рекламная и исследование конъюнктуры рынка',
          imageName: '73-74.png',
        },
        {
          code: 74,
          name: 'Деятельность профессиональная научная и техническая прочая',
          imageName: '73-74.png',
        },
        {
          code: 75,
          name: 'Деятельность ветеринарная',
          imageName: '75.png',
        },
      ],
    },
    {
      name: 'Деятельность административная и сопутствующие дополнительные услуги',
      children: [
        {
          code: 77,
          name: 'Аренда и лизинг',
          imageName: '77.png',
        },
        {
          code: 78,
          name: 'Деятельность по трудоустройству и подбору персонала',
          imageName: '78.png',
        },
        {
          code: 79,
          name: 'Деятельность туристических агентств и прочих организаций, предоставляющих услуги в сфере туризма',
          imageName: '79.png',
        },
        {
          code: 80,
          name: 'Деятельность по обеспечению безопасности и проведению расследований',
          imageName: '80-82.png',
        },
        {
          code: 81,
          name: 'Деятельность по обслуживанию зданий и территорий',
          imageName: '80-82.png',
        },
        {
          code: 82,
          name: 'Деятельность административно-хозяйственная, вспомогательная деятельность по обеспечению функционирования организации, деятельность по предоставлению прочих вспомогательных услуг для бизнеса',
          imageName: '80-82.png',
        },
      ],
    },
    {
      name: 'Государственное управление и обеспечение военной безопасности; социальное обеспечение',
      children: [
        {
          code: 84,
          name: 'Деятельность органов государственного управления по обеспечению военной безопасности, обязательному социальному обеспечению',
          imageName: '84.png',
        },
      ],
    },
    {
      name: 'Образование',
      children: [
        {
          code: 85,
          name: 'Образование',
          imageName: '85.png',
        },
      ],
    },
    {
      name: 'Деятельность в области здравоохранения и социальных услуг',
      children: [
        {
          code: 86,
          name: 'Деятельность в области здравоохранения',
          imageName: '86-88.png',
        },
        {
          code: 87,
          name: 'Деятельность по уходу с обеспечением проживания',
          imageName: '86-88.png',
        },
        {
          code: 88,
          name: 'Предоставление социальных услуг без обеспечения проживания',
          imageName: '86-88.png',
        },
      ],
    },
    {
      name: 'Деятельность в области культуры, спорта, организации досуга и развлечений',
      children: [
        {
          code: 90,
          name: 'Деятельность творческая, деятельность в области искусства и организации развлечений',
          imageName: '90-93.png',
        },
        {
          code: 91,
          name: 'Деятельность библиотек, архивов, музеев и прочих объектов культуры',
          imageName: '90-93.png',
        },
        {
          code: 92,
          name: 'Деятельность по организации и проведению азартных игр и заключению пари, по организации и проведению лотерей',
          imageName: '90-93.png',
        },
        {
          code: 93,
          name: 'Деятельность в области спорта, отдыха и развлечений',
          imageName: '90-93.png',
        },
      ],
    },
    {
      name: 'Предоставление прочих видов услуг',
      children: [
        {
          code: 94,
          name: 'Деятельность общественных организаций',
          imageName: '94-96.png',
        },
        {
          code: 95,
          name: 'Ремонт компьютеров, предметов личного потребления и хозяйственно-бытового назначения',
          imageName: '94-96.png',
        },
        {
          code: 96,
          name: 'Деятельность по предоставлению прочих персональных услуг',
          imageName: '94-96.png',
        },
      ],
    },
    {
      name: 'Деятельность домашних хозяйств как работодателей; недифференцированная деятельность частных домашних хозяйств по производству товаров',
      children: [
        {
          code: 97,
          name: 'Деятельность домашних хозяйств с наемными работниками',
          imageName: '97-98.png',
        },
        {
          code: 98,
          name: 'Деятельность недифференцированная частных домашних хозяйств по производству товаров и предоставлению услуг для собственного потребления',
          imageName: '97-98.png',
        },
      ],
    },
    {
      name: 'Деятельность экстерриториальных организаций и органов',
      children: [
        {
          code: 99,
          name: 'Деятельность экстерриториальных организаций и органов',
          imageName: '99.png',
        },
      ],
    },
  ];
}
