import { InjectionKey } from 'vue';
import { EnforcementProceedings } from '@/modules/dossier/company/enforcementProceedings/types/enforcementProceedings';

export interface ICompanyEnforcementProceedingsStatisticsService {
  calculateTotalEPAmount(epList: Array<EnforcementProceedings>): number;
  calculateActiveEPAmount(epList: Array<EnforcementProceedings>): number;
  calculateClosedEPAmount(epList: Array<EnforcementProceedings>): number;
  calculateTotalEPSum(epList: Array<EnforcementProceedings>): number;
  calculateActiveEPSum(epList: Array<EnforcementProceedings>): number;
  calculateClosedEPSum(epList: Array<EnforcementProceedings>): number;
}

export const ICompanyEnforcementProceedingsStatisticsServiceKey: InjectionKey<ICompanyEnforcementProceedingsStatisticsService> =
  Symbol('ICompanyEnforcementProceedingsStatisticsServiceKey');
