export enum HousingTypes {
  Unknown = 0,
  LandPlot = 1,
  Building = 10,
  Flat = 20,
  Construction = 30,
  Room = 40,
  ParkingSpace = 50,
  NotFinishedConstruction = 60,
  SingleRealEstateComplex = 70,
  EnterprisePropertyComplex = 80,
  Other = 90,
}
