import { injectable } from 'inversify';
import { IReportsExtendedReportGenerationPayloadMappingService } from '@/modules/reports/common/services/reportsExtendedReportGenerationPayloadMappingService.interfaces';
import { ReportsHistoryListItem } from '@/modules/reports/mobile/history/general/types/ReportHistoryListItem';
import { ExtendedReportGenerationPayload } from '@/modules/reports/common/types/extendedReportGenerationPayload';
import { ReportType } from '@/modules/reports/common/types/ReportType';
import { ReportTypeInput } from '@/modules/reports/common/types/ReportTypeInput';

@injectable()
export default class ReportsExtendedReportGenerationPayloadMappingService
  implements IReportsExtendedReportGenerationPayloadMappingService
{
  mapExtendedReportGenerationPayload(
    reportData: ReportsHistoryListItem,
  ): ExtendedReportGenerationPayload {
    return {
      reportType: ReportType.extended,
      fio: reportData.fio ?? '',
      birthday: reportData.birthday ?? '',
      inn: reportData.inn ?? '',
      phoneNumber: reportData.phoneNumber ?? '',
      carNumber: reportData.carNumber ?? '',
      inputType: reportData.inputType ?? ReportTypeInput.unknown, // TODO нужно автоопределение реквизита после рефакторинга
      isPersonalDataConsentAgreementValidEternally:
        reportData.isPersonalDataConsentAgreementValidEternally ?? false,
    };
  }
}
