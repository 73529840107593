import { injectable } from 'inversify';
import { IManualImportCreateCompanyModelMapper } from '@/modules/import/services/mappers/manualImportCreateCompanyModelMapper/manualImportCreateCompanyModelMapper.interfaces';
import { ManualImportCreateCompanyModel } from '@/modules/import/types/company/manualImportCreateCompanyModel';
import { ApiManualImportCreateCompany } from '@/modules/import/types/api/company/apiManualImportCreateCompany';
import { ApiManualImportCreateCompanyEmail } from '@/modules/import/types/api/company/apiManualImportCreateCompanyEmail';
import { ApiManualImportCreateCompanyPhone } from '@/modules/import/types/api/company/apiManualImportCreateCompanyPhone';
import { ApiManualImportCreateCompanySocial } from '@/modules/import/types/api/company/apiManualImportCreateCompanySocial';
import {
  ImportManualInfoModelsPhone,
  ImportManualInfoModelsSocial,
  ImportManualInfoModelsEmail,
} from '@/modules/import/types/api/importManualInfoModelsTypes';

@injectable()
export default class ManualImportCreateCompanyModelMapper
  implements IManualImportCreateCompanyModelMapper
{
  map(formModel: ManualImportCreateCompanyModel): ApiManualImportCreateCompany {
    return {
      companyName: formModel.name,
      note: formModel.note,
      companyInn: formModel.companyInn,
      companyOgrn: formModel.companyOgrn,
      emails: formModel.emails.map(email => this.mapEmail(email)),
      phones: formModel.phones.map(phone => this.mapPhone(phone)),
      socials: formModel.socials.map(social => this.mapSocial(social)),
      socialFriends: formModel.socials.map(socialFriend => this.mapSocial(socialFriend)),
      autos: formModel.autos,
      listData: formModel.listData,
      settings: formModel.settings,
    };
  }

  private mapEmail(email: ImportManualInfoModelsEmail): ApiManualImportCreateCompanyEmail {
    return {
      value: email.email,
    };
  }

  private mapPhone(phone: ImportManualInfoModelsPhone): ApiManualImportCreateCompanyPhone {
    return {
      type: phone.phoneType.id,
      value: phone.phoneNumber,
    };
  }

  private mapSocial(social: ImportManualInfoModelsSocial): ApiManualImportCreateCompanySocial {
    return {
      id: social.id,
      type: social.type.id,
    };
  }
}
