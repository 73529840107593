import { InjectionKey } from 'vue';
import { InspectionInfoTypes } from '@/types/companyInspectionsInfo/companyInspectionInfoTypes';
import { InspectionsInfo } from '@/types/companyInspectionsInfo/companyInspectionsInfo';
import { InspectionInfoField } from '@/types/companyInspectionsInfo/companyInspectionInfoField';
import { getInspectionsByTypeResult } from '@/modules/dossier/company/common/types/companyInspections/companyInspections';

export interface ICompanyInspectionsMappingService {
  getInspectionsTableRowByTypeAndField(
    target: InspectionInfoTypes | string,
    inspections: Array<InspectionsInfo>,
    field: InspectionInfoField,
  ): getInspectionsByTypeResult;
}

export const ICompanyInspectionsMappingServiceKey: InjectionKey<ICompanyInspectionsMappingService> =
  Symbol('ICompanyInspectionsMappingServiceKey');
