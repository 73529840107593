import { injectable } from 'inversify';
import { IDesktopReportsInputTypeFormatter } from '@/modules/reports/desktop/services/desktopReportsInputTypeFormatter.interfaces';
import { ReportTypeInput } from '@/modules/reports/common/types/ReportTypeInput';

@injectable()
export default class DesktopReportsInputTypeFormatter implements IDesktopReportsInputTypeFormatter {
  format(inputType: ReportTypeInput): string {
    switch (inputType) {
      case ReportTypeInput.fullName:
        return 'ФИО + дата рождения';
      case ReportTypeInput.inn:
        return 'ИНН';
      case ReportTypeInput.phone:
        return 'Телефон';
      case ReportTypeInput.carNumber:
        return 'Транспорт';
      case ReportTypeInput.photo:
        return 'Фото';
      case ReportTypeInput.unknown:
      default:
        return 'Неизвестно';
    }
  }
}
