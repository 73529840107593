import { AccessScopeEnum } from '@/modules/rights/type/AccessScopeEnum';

export enum AccessScopeUser {
  isAuthorized = AccessScopeEnum.isAuthorized,
  hasAccessToHistory = AccessScopeEnum.hasAccessToHistory,
  hasAccessToDataGrid = AccessScopeEnum.hasAccessToDataGrid,
  hasAccessToSearch = AccessScopeEnum.hasAccessToSearch,
  hasAccessToDataAndAnalysis = AccessScopeEnum.hasAccessToDataAndAnalysis,
  hasAccessToDataCollection = AccessScopeEnum.hasAccessToDataCollection,
  hasAccessToUsers = AccessScopeEnum.hasAccessToUsers,
  hasAccessToPerson = AccessScopeEnum.hasAccessToPerson,
  hasAccessToCompany = AccessScopeEnum.hasAccessToCompany,
  hasAccessToImportRootView = AccessScopeEnum.hasAccessToImportRootView,
  hasAccessToCollection = AccessScopeEnum.hasAccessToCollection,
  hasAccessToHistoryExternalData = AccessScopeEnum.hasAccessToHistoryExternalData,
}
