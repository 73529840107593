import {
  DxPdfExportConfiguration
} from '@/modules/devExtreme/export/pdf/configuration/dxPdfExportConfiguration';
import {
  IDxPdfCellFormatter
} from '@/modules/devExtreme/export/pdf/services/dxPdfCellFormatter/dxPdfCellFormatter.interfaces';
import { DxTableExtendedColumn } from '@/modules/devExtreme/types/dxTableExtendedColumn';
import { Cell, PdfDataGridCell } from 'devextreme/pdf_exporter';
import { injectable } from 'inversify';

@injectable()
export default class DxPdfDataCellFormatter implements IDxPdfCellFormatter {
  cellType = 'data'

  format(pdfCell: Cell, gridCell: PdfDataGridCell, dxColumn: DxTableExtendedColumn) {
    pdfCell.font = {
      size: DxPdfExportConfiguration.defaultFontSize
    }

    pdfCell.wordWrapEnabled = true;

    pdfCell.horizontalAlign = DxPdfExportConfiguration.defaultHorizontalAlignment;
    pdfCell.verticalAlign = DxPdfExportConfiguration.defaultVerticalAlignment;

    pdfCell.text = this.getDataCellValue(dxColumn, gridCell);
  }

  private getDataCellValue(dxColumn: DxTableExtendedColumn, gridCell: PdfDataGridCell) {
    if (dxColumn.calculateExportValue != undefined)
      return dxColumn.calculateExportValue(gridCell.value, gridCell.data);

    return gridCell.value;
  }
}
