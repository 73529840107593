import {
  ICompanyListToAnalysisEntityMapper
} from '@/modules/analysis/services/mappers/companyListToAnalysisEntityMapper.interfaces';
import { AnalysisEntity } from '@/modules/analysis/types/analysisEntity';
import { AnalysisEntityType } from '@/modules/analysis/types/analysisEntityType';
import { ApiUlListDataItemCompact } from '@/modules/lists/types/api/ul/apiUlListDataItem';
import { injectable } from 'inversify';

@injectable()
export default class CompanyListToAnalysisEntityMapper implements ICompanyListToAnalysisEntityMapper {
  map(item: ApiUlListDataItemCompact): AnalysisEntity {
    return {
      id: item.id,
      type: AnalysisEntityType.companiesList,
      title: item.displayName
    };
  }
}
