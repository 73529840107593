import { injectable } from 'inversify';
import { IMasksService } from '@/modules/masks/services/masksService/masksService.interfaces';
import { Mask } from '@/modules/masks/types/mask';
import { Mask as Maska } from 'maska';
import { StringUtils } from '@/modules/common/utils/stringUtils';

@injectable()
export default class MasksService implements IMasksService {
  getMaskedValue(value: string | undefined, mask: Mask, defaultValue?: string): string | undefined {
    if (value == undefined) {
      return defaultValue;
    }

    const maskOptions = StringUtils.isString(mask) ? { mask } : mask;
    const maska = new Maska(maskOptions);

    return maska.masked(value);
  }

  getRawValue(value: string | undefined, mask: Mask, defaultValue?: string): string | undefined {
    if (value == undefined) {
      return defaultValue;
    }

    const maskOptions = StringUtils.isString(mask) ? { mask } : mask;
    const maska = new Maska(maskOptions);

    return maska.unmasked(value);
  }

  isCompleted(value: string | undefined, mask: Mask): boolean {
    if (value == undefined) {
      return false;
    }

    const maskOptions = StringUtils.isString(mask) ? { mask } : mask;
    const maska = new Maska(maskOptions);

    return maska.completed(value);
  }
}
