import { injectable } from 'inversify';
import { ContactTypes } from '@/modules/dossier/person/common/types/api/contactTypes';
import { ApiPersonDetailContactsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailContactsRecord';
import { ApiPersonDetailFioPhoneBondVerificationsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailFioPhoneBondVerificationsRecord';
import { ContactsPhonesTableRow } from '@/modules/dossier/person/contacts/types/contactsPhonesTableRow';
import { FioPhoneBondVerificationResultCodeEnum } from '@/modules/dossier/person/contacts/types/fioPhoneBondVerificationResultCodeEnum';
import { IPersonContactsPhonesMappingService } from '@/modules/dossier/person/contacts/services/personContactsPhonesMappingService.interface';

@injectable()
export default class personContactsPhonesMappingService
  implements IPersonContactsPhonesMappingService
{
  // TODO убрать, когда в personDetails.fioPhoneBondVerifications.list будет приходить корректный resultCode (сейчас всегда 0 приходит)
  getFioPhoneBondVerificationResultCode(operationResult?: string): number {
    switch (operationResult) {
      case 'fail':
        return FioPhoneBondVerificationResultCodeEnum.fail;
      case 'success':
        return FioPhoneBondVerificationResultCodeEnum.success;
      default:
        return FioPhoneBondVerificationResultCodeEnum.notFound;
    }
  }

  mapContactsPhonesTable(
    contacts: Array<ApiPersonDetailContactsRecord>,
    fioPhoneBondVerification: Array<ApiPersonDetailFioPhoneBondVerificationsRecord>,
  ): Array<ContactsPhonesTableRow> {
    return contacts
      .filter((contact) => contact.contactType == ContactTypes.Phone)
      .map((contactsPhonesItem): ContactsPhonesTableRow => {
        const fioPhoneBondVerificationResultCode = fioPhoneBondVerification.find(
          (item) => item.checkedPhone == contactsPhonesItem.value?.replace(/[\D]+/g, ''),
        )?.operationResult;

        return {
          id: contactsPhonesItem.id,
          contactType: contactsPhonesItem.contactType,
          contactSubtype: contactsPhonesItem.contactSubtype,
          phoneType: contactsPhonesItem.phoneType,
          source: contactsPhonesItem.source,
          modified: contactsPhonesItem.modified,
          value: contactsPhonesItem.value,
          fioPhoneBondVerificationResultCode: this.getFioPhoneBondVerificationResultCode(
            fioPhoneBondVerificationResultCode,
          ),
          isVerified: contactsPhonesItem.isVerified,
          modifiedStr: contactsPhonesItem.modifiedStr,
          country: contactsPhonesItem.country,
          provider: contactsPhonesItem.provider,
          timeZone: contactsPhonesItem.timeZone,
          phoneVerificationCode: contactsPhonesItem.phoneVerificationCode,
        };
      });
  }
}
