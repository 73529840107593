import { InjectionKey } from 'vue';
import { ReportTypeInput } from '@/modules/reports/common/types/ReportTypeInput';

export interface IReportsWebVersionUrlService {
  getWebVersionUrl(inputType?: ReportTypeInput, reportTaskId?: string | null): string | undefined;
}

export const IReportsWebVersionUrlServiceKey: InjectionKey<IReportsWebVersionUrlService> = Symbol(
  'IReportsWebVersionUrlService',
);
