import { injectable } from 'inversify';
import groupBy from 'lodash/groupBy';
import {
  ICompanyBranchesService,
  MostBranchesInCity,
} from '@/modules/dossier/company/common/services/statistics/companyBranchesService.interface';
import { AddressUtils } from '@/modules/common/utils/addressUtils';
import { TDetailsCompanyBranchesList } from '@/types/companyData/companyData';
import { Dictionary } from '@/types/common';

@injectable()
export default class companyBranchesService implements ICompanyBranchesService {
  calculateBranchesCountByType(branches: Array<TDetailsCompanyBranchesList>, type: string): number {
    return branches.filter(({ branchType }) => branchType == type).length;
  }

  groupBranchesByCity(
    companyBranchData: Array<TDetailsCompanyBranchesList>,
  ): Dictionary<TDetailsCompanyBranchesList[]> {
    return groupBy(companyBranchData, (branch) => AddressUtils.getCityByAddress(branch.address));
  }

  calculateCountCityBranches(
    companyBranchData: Array<TDetailsCompanyBranchesList>,
  ): MostBranchesInCity {
    const groupedBranchesByCity = this.groupBranchesByCity(companyBranchData);

    const mostBranchesInCity = Object.entries(groupedBranchesByCity).reduce(
      (acc, [cityName, branches]) => {
        if (branches.length > acc.count) {
          return { name: cityName, count: branches.length };
        }
        return acc;
      },
      { name: '', count: 0 },
    );
    return mostBranchesInCity;
  }
}
