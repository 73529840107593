import { inject, injectable } from 'inversify';
import { IPersonSnilsValidationService } from '@/modules/validation/services/personSnilsValidationService.interface';
import {
  IMasksService,
  IMasksServiceKey,
} from '@/modules/masks/services/masksService/masksService.interfaces';
import {
  personSnilsLength,
  personSnilsMask,
} from '@/modules/validation/configuration/masks/personSnilsMask';

@injectable()
export default class PersonSnilsValidationService implements IPersonSnilsValidationService {
  constructor(@inject(IMasksServiceKey as symbol) private masksService: IMasksService) {}

  validate(value: string): boolean {
    if (!this.masksService.isCompleted(value, personSnilsMask)) {
      return false;
    }

    return value.length == personSnilsLength;
  }
}
