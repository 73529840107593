import { InjectionKey } from 'vue';
import { ApiPersonDetailOkRecordUserItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordUserItem';
import { ApiPersonDetailOkRecordRelativeItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordRelativeItem';
import { PersonSocialNetworkAdditionalInfoOkRelativesTableRow } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/types/personSocialNetworkAdditionalInfoOkRelativesTableRow';

export interface IPersonSocialNetworkAdditionalInfoOkMappingService {
  mapOkRelativesDataTable(
    okRelativeList: Array<ApiPersonDetailOkRecordRelativeItem | ApiPersonDetailOkRecordUserItem>,
    entity: string,
  ): Array<PersonSocialNetworkAdditionalInfoOkRelativesTableRow>;
}

export const IPersonSocialNetworkAdditionalInfoOkMappingServiceKey: InjectionKey<IPersonSocialNetworkAdditionalInfoOkMappingService> =
  Symbol('IPersonSocialNetworkAdditionalInfoOkMappingServiceKey');
