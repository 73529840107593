import { injectable } from 'inversify';
import { IRegulationsChecksRateNameMapperService } from '@/modules/regulationsCheck/common/services/regulationsChecksRateNameMapperService.interfaces';

@injectable()
export default class RegulationsChecksRateNameMapperService
  implements IRegulationsChecksRateNameMapperService
{
  map(item: string): number | undefined {
    switch (item) {
      case 'InvalidPassport':
        return 1;
      case 'InvalidInn':
        return 2;
      case 'HaveFrom1To4UnfinishedEnfocrementProceedings':
        return 3;
      case 'HaveFrom5To9UnfinishedEnfocrementProceedings':
        return 4;
      case 'HaveGreaterThan10UnfinishedEnfocrementProceedings':
        return 5;
      case 'HadFrom1To4EnfocrementProceedings':
        return 6;
      case 'HadFrom5To9EnfocrementProceedings':
        return 7;
      case 'HadGreaterThan10EnfocrementProceedings':
        return 8;
      case 'HaveUnfinishedEnfocrementProceedingsLessThan2000':
        return 9;
      case 'HaveUnfinishedEnfocrementProceedingsFrom2000To10000':
        return 10;
      case 'HaveUnfinishedEnfocrementProceedingsFrom10000To100000':
        return 11;
      case 'HaveUnfinishedEnfocrementProceedingsGreaterThan100000':
        return 12;
      case 'WantedMvd':
        return 13;
      case 'IsBunkrupt':
        return 14;
      case 'HasANegativeCreditHistory':
        return 16;
      case 'WasProsecuted':
        return 17;
      case 'WasBroughtToAdministrativeResponsibility':
        return 18;
      case 'WasAParticipantInIncidents':
        return 19;
      case 'HasFrom1To10UnpaidTrafficFines':
        return 20;
      case 'HasFrom10UnpaidTrafficFines':
        return 21;
      case 'HadFrom10TrafficFines':
        return 22;
      case 'IsRegistredInNeuropsychiatricNarcologicalAndOverDispansaries':
        return 23;
      case 'IsRegistredInOvdRf':
        return 24;
      case 'HasTheRightToBearArms':
        return 25;
      case 'SubscriberNumberNotVerified':
        return 26;
      case 'IsTheHeadOrFounderOfAnExistingLegalEntity':
        return 27;
      case 'IsTheHeadOrFounderOfAnExistingLegalEntityFrom5To10':
        return 28;
      case 'IsTheHeadOrFounderOfAnExistingLegalEntityFrom10To50':
        return 29;
      case 'IsTheHeadOrFounderOfAnExistingLegalEntityFrom50':
        return 30;
      case 'RegisteredAsAnIndividualEntrepreneur':
        return 31;
      case 'WasRegisteredAsAnIndividualEntrepreneur':
        return 32;
      case 'HadEnfocrementProceedingsLessThan2000':
        return 33;
      case 'HadEnfocrementProceedingsFrom2000To10000':
        return 34;
      case 'HadEnfocrementProceedingsFrom10000To100000':
        return 35;
      case 'HadEnfocrementProceedingsGreaterThan100000':
        return 36;
      case 'HadWeaponsViolations':
        return 37;
      case 'HasOutstandingConviction':
        return 38;
      case 'HadFrom30TrafficFines':
        return 39;
      case 'InvalidSnils':
        return 40;
      case 'PreviouslyWorkedInTheCompany':
        return 109;
      case 'OwnsAStakeInANegativeListedCompany':
        return 110;
      case 'RunsANegativeListedCompany':
        return 111;
      case 'RelativesWorkForTheCompany':
        return 112;
      case 'RelativesWorkedInTheCompany':
        return 113;
      case 'RegisteredAsSelfEmployed':
        return 114;
      case 'AssociatedWithACompetingCompany':
        return 118;
      case 'HasNegativeReviewsFromPastJobs':
        return 119;
      case 'HasDependencies':
        return 120;
      case 'HasMentalDisabilities':
        return 121;
      case 'ChangesJobsOften':
        return 122;
      case 'HasCriminalConnections':
        return 123;
      case 'ShowsHighInternetActivity':
        return 126;
      case 'ActivelyPublishesPersonalInformationOnSocialMedia':
        return 127;
      case 'PostingProhibitedOrInappropriateContentOnSocialNetworks':
        return 128;
      case 'CapableOfEngagingSocialMediaUsersWithPublishedContentAndFormingInformalGroups':
        return 129;
      case 'HasADebtInGisLess500':
        return 130;
      case 'HasADebtInGisFrom500To2000':
        return 131;
      case 'HasADebtInGisFrom2000To10000':
        return 132;
      case 'HasADebtInGisFrom10000To100000':
        return 133;
      case 'HasADebtInGisGreaterThan100000':
        return 134;
      case 'IncludedInTheSanctionsLists':
        return 135;
      case 'ProvidedFalseInformation':
        return 136;
      case 'ProvidedIncompleteInformation':
        return 137;
      case 'ProvidedFakeDocuments':
        return 138;
      case 'HasNoValidWorkPermitsAndWorkPatents':
        return 139;
      case 'DoesNotHaveValidLicensesForEmploymentOutsideTheTerritoryRf':
        return 140;
      case 'HasNoValidInvitationsToEnterTheRf':
        return 141;
      case 'ThereAreGroundsForNotAllowingEntryIntoTheTerritoryOfTheRf':
        return 142;
      case 'HasRestrictionsOnParticipationInLegalEntities':
        return 143;
      case 'IncludedInTheRegisterOfDisqualifiedPersons':
        return 144;
      case 'IsBlacklisted':
        return 146;
      case 'IsOnTheListOfTerroristsAndExtremists':
        return 148;
      case 'HasPledgedMovableProperty':
        return 149;
      case 'WantedByTheFsin':
        return 150;
      case 'WantedByTheFssp':
        return 151;
      case 'LostTheRightToDriveAVehicle':
        return 152;
      case 'MadeUnscheduledReplacementOfDocuments':
        return 153;
      case 'IncludedInTheRegisterOfPersonsDismissedDueToLossOfTrust':
        return 154;
      case 'HasCounterpartyRelation':
        return 155;
      case 'HasSignificantDebtObligations':
        return 157;
      case 'HasNotCorrespondToPosition':
        return 158;
      case 'HasReferenceFromCompanyEmployeeOrHead':
        return 159;
      case 'IsBelongingToInformalGroupsOrSects':
        return 160;
      case 'HasParticipatingInLegalProceedingAsDefendant':
        return 161;
      case 'HasParticipatingInLegalProceedingAsPlaintiff':
        return 162;
      case 'HasFiledLawsuitAgainstEmployer':
        return 163;
      case 'HasFiledLawsuitAgainstCompany':
        return 164;
      case 'IsWasDefendantSuedByEmployer':
        return 165;
      case 'IsWasDefendantSuedByCompany':
        return 166;
      case 'IsVehicleWanted':
        return 167;
      case 'OkbScoringScoreIsLow':
        return 168;
      case 'HasEncumbrances':
        return 169;
      case 'HasADebtInStateFees':
        return 170;
      case 'HasADebtInJudicial':
        return 171;
      case 'HasADebtInServiceBills':
        return 172;
      case 'HasEmploymentInNegativeListedCompanies':
        return 173;
      case 'HasBlacklistRelation':
        return 174;
      case 'HasEmployeesRelation':
        return 175;
      case 'IsCompetitor':
        return 176;
      case 'IsCounterparty':
        return 177;
      case 'IsEmployee':
        return 178;
      case 'IsInUsersBlacklist':
        return 179;
      case 'IsIpdlMpdlRpdl':
        return 180;
      case 'PersonHasNegativeBusinessReputation':
        return 181;
      case 'FicoScoringScoreIsLow':
        return 182;
      case 'V2ScoringScoreIsLow':
        return 183;
      case 'IsActivityTerminated':
        return 1000001;
      case 'HeadOrFounderHasDied':
        return 1000002;
      case 'StatusNeedsAttention':
        return 1000003;
      case 'IsCompanyRegisteredRecently':
        return 1000004;
      case 'LegalAddressChanged1Month':
        return 1000005;
      case 'RegionWasChangedLastYear':
        return 1000006;
      case 'LegalAddressChangedMore2TimesLastYear':
        return 1000007;
      case 'IsMore5CompaniesOnHead':
        return 1000008;
      case 'IsMore3LiquidatedCompaniesOnHead':
        return 1000009;
      case 'IsExceptedFromSmallOrMediumBusinessRegister':
        return 1000010;
      case 'IsMore3LiquidatedCompaniesOnFounder':
        return 1000011;
      case 'HasHeadAndFounderChangedLastYear':
        return 1000012;
      case 'HasHeadChangedMoreTwoTimesLastYear':
        return 1000013;
      case 'SoleFounderAndHeadIsSamePerson':
        return 1000014;
      case 'IsMore1CompaniesWithSameLegalAddress':
        return 1000015;
      case 'IsMore5OperatingCompaniesOnFounder':
        return 1000016;
      case 'IsMassPhone':
        return 1000017;
      case 'HasBankruptedRelatedCompanies':
        return 1000018;
      case 'IsMinimalStatedCapital':
        return 1000019;
      case 'IsDecidedToReduceStatedCapital':
        return 1000020;
      case 'IsIpRegisteredRecently':
        return 1000021;
      case 'IsManagementCompanyInLiquidation':
        return 1000026;
      case 'IsParentCompanyBankruptcyRegistered':
        return 1000027;
      case 'IsMassLegalAddress':
        return 1000101;
      case 'IsUnreliableLegalAddress':
        return 1000102;
      case 'IsUnreliableHeadOrFounderInfo':
        return 1000103;
      case 'IsNoTaxReportMoreThanOneYear':
        return 1000104;
      case 'IsTaxDebtFixed':
        return 1000105;
      case 'IsBankAccountsBlocked':
        return 1000106;
      case 'IsRegisteredByUnscrupulousTenderMembers':
        return 1000107;
      case 'HasFinancialPyramidMark':
        return 1000108;
      case 'HasIllegalCreditorMark':
        return 1000109;
      case 'IsIllegalStocksMarketMember':
        return 1000110;
      case 'HasNegativeListedAffiliateCompany':
        return 1000111;
      case 'HasHighRiskActivityOperations':
        return 1000112;
      case 'IsHeadOrFounderInMassDestructionWeaponsList':
        return 1000113;
      case 'IsInTerroristsAndExtremistsList':
        return 1000114;
      case 'HasViolationsByInspections':
        return 1000115;
      case 'IsFounderNegativeRegistered':
        return 1000118;
      case 'HasIllegalRemunerationAdministrativeLiable':
        return 1000119;
      case 'IsMassHead':
        return 1000120;
      case 'IsMassFounder':
        return 1000121;
      case 'IsFounderInMassHeadList':
        return 1000122;
      case 'IsIpInMassFounder':
        return 1000123;
      case 'IsIpInMassHead':
        return 1000124;
      case 'IsHeadOrFounderHasRestrictionsOnParticipationInLegalEntities':
        return 1000125;
      case 'IsHeadOrFounderInTheRegisterOfPersonsDismissedDueToLossOfTrust':
        return 1000126;
      case 'IsUnreliableAddressInfoEarlier':
        return 1000127;
      case 'IsUnreliableHeadOrFounderInfoEarlier':
        return 1000128;
      case 'IsHeadOrFounderRegisteredAsDisqualifiedPersons':
        return 1000129;
      case 'IsProceedDecreasedByMore50Percents':
        return 1000201;
      case 'IsProceedDecreased3Years':
        return 1000202;
      case 'IsUnprofitableActivity':
        return 1000203;
      case 'IsUnprofitableActivity3Years':
        return 1000204;
      case 'IsNetAssetsDecreased':
        return 1000205;
      case 'IsNetAssetsLessThanStatedCapital':
        return 1000206;
      case 'IsHighCreditLoad':
        return 1000207;
      case 'HasZeroFiguresInFinancialStatements':
        return 1000208;
      case 'HasIdentFiguresInFinancialStatements2Years':
        return 1000209;
      case 'EquityCapitalIsMissing':
        return 1000210;
      case 'HasNoWorkingCapital':
        return 1000211;
      case 'HasNoNonWorkingCapital':
        return 1000212;
      case 'IsFinancialStatementsHasLowRating':
        return 1000214;
      case 'HasBankruptcyIntroductionAct':
        return 1000301;
      case 'HasBankruptcyIntention':
        return 1000302;
      case 'HasCompletedBankruptcyMark':
        return 1000303;
      case 'HasBankruptcyReports':
        return 1000304;
      case 'HasBankruptcyInProgress':
        return 1000305;
      case 'HasHeadOrFounderOrIpBankruptcyMessages':
        return 1000306;
      case 'AmountOfClaimsToCompanyIsSignificant':
        return 1000401;
      case 'AmountOfClaimsFromCompanyIsSignificant':
        return 1000402;
      case 'AmountOfEnforcementProceedingsIsSignificant':
        return 1000403;
      case 'BudgetPaymentsHasSignificantArrears':
        return 1000404;
      case 'HasPendingEnforcementProceedings':
        return 1000406;
      case 'ClaimsToCompanyHasSignificantIncreaseLastYear':
        return 1000407;
      case 'IsOnlyDefendantInArbitrationCourts':
        return 1000408;
      case 'HasUnliquidatedArbitrationLiabilities':
        return 1000409;
      case 'HasEnforcementProceedingsAboutSalary':
        return 1000410;
      case 'HasEnforcementProceedingsAboutArrest':
        return 1000411;
      case 'HasEnforcementProceedingsAboutCreditPayments':
        return 1000412;
      case 'HasEnforcementProceedingsAboutPledgedProperty':
        return 1000413;
      case 'HasEnforcementProceedingsAboutTaxes':
        return 1000414;
      case 'HasEnforcementProceedingsAboutInsurancePremiums':
        return 1000415;
      case 'HasArbitrationCasesAboutBankruptcy':
        return 1000416;
      case 'HasArbitrationCasesAboutCredits':
        return 1000417;
      case 'HasArbitrationCasesAboutTaxes':
        return 1000418;
      case 'HasArbitrationCasesAboutServicesContracts':
        return 1000419;
      case 'HasArbitrationCasesAboutSupplyContracts':
        return 1000420;
      case 'HasReturnedEnforcementProceedings':
        return 1000421;
      case 'IsOnUSASanctions':
        return 1000501;
      case 'IsOnUSASectoralSanctions':
        return 1000502;
      case 'IsOnEUSanctions':
        return 1000503;
      case 'IsOnUKSanctions':
        return 1000504;
      case 'IsOnUASanctions':
        return 1000505;
      case 'IsOnSwitzSanctions':
        return 1000506;
      case 'IsFoundersOnUSASanctions':
        return 1000507;
      case 'IsFoundersOnUSASectoralSanctions':
        return 1000508;
      case 'IsFoundersOnEUSanctions':
        return 1000509;
      case 'IsFoundersOnUKSanctions':
        return 1000510;
      case 'IsFoundersOnUASanctions':
        return 1000511;
      case 'IsFoundersOnSwitzSanctions':
        return 1000512;
      case 'HasNoOpenInfoAboutActivity':
        return 1000602;
      case 'HasNoActivity':
        return 1000603;
      case 'HasSignificantGovernmentContractsInProceed':
        return 1000604;
      case 'HasHighPercentageOfLossesInGovernmentAuctions':
        return 1000605;
      case 'IsNotAdmitToGovernmentAuctions':
        return 1000606;
      case 'HasApplicationToEGRULAboutIPLiquidation':
        return 1000607;
      case 'HasApplicationToEGRULAboutCompanyLiquidation':
        return 1000608;
      case 'HasApplicationToEGRULAboutHeadChange':
        return 1000609;
      case 'HasApplicationToEGRULAboutFounderChange':
        return 1000610;
      case 'HasApplicationToEGRULAboutStatedCapitalChange':
        return 1000611;
      case 'HasApplicationToEGRULInP13014':
        return 1000612;
      case 'HasApplicationToEGRULAboutLegalAddressChange':
        return 1000613;
      case 'IsZeroHeadcount':
        return 1000614;
      case 'HasSignificantDecreaseHeadcountLastYear':
        return 1000615;
      case 'HasNegativeBusinessReputation':
        return 1000616;
      case 'HasNoLicenseForThisTypeOfActivity':
        return 1000617;
      case 'IsCloneCompany':
        return 1000618;
      case 'IsAllTrademarksExpired':
        return 1000619;
      case 'IsAllLicensesExpired':
        return 1000620;
      case 'IsContactPhonesNotAvailable':
        return 1000621;
      case 'HasNoPremises':
        return 1000622;
      case 'HasNoEmail':
        return 1000623;
      case 'HasNoSimilarWorkExperience':
        return 1000624;
      case 'IsSiteContainsFalseInformation':
        return 1000625;
      case 'HasNoHeadcountData':
        return 1000626;
      case 'HasDiscontinuedGovernmentContracts':
        return 1000627;
      case 'HasRiskyJurisdictionRelation':
        return 1000628;
      case 'CompanyHasCriminalRelations':
        return 1000701;
      case 'HeadOrFounderIsIPDL':
        return 1000702;
      case 'HeadOrFounderIsMPDL':
        return 1000703;
      case 'HeadOrFounderIsRPDL':
        return 1000704;
      case 'HeadOrFounderIsInnerCircleOfPDL':
        return 1000705;
      case 'HasRelationsWithCounterparties':
        return 1000706;
      case 'HasRelationsWithCompetitors':
        return 1000707;
      case 'HasRelationsWithEmployees':
        return 1000708;
      case 'HasRelationsWithBlackList':
        return 1000709;
      case 'HasRelationsWithFamilyOfEmployees':
        return 1000710;
      case 'IsVerificationObjectInCompetitors':
        return 1000711;
      case 'IsVerificationObjectInCounterparties':
        return 1000712;
      case 'IsVerificationObjectInEmployees':
        return 1000713;
      case 'IsVerificationObjectInUsersBlackList':
        return 1000714;
      case 'IsTaxBurdenBelowOfIndustryAverage':
        return 1000801;
      case 'IsExpensesExceedIncome':
        return 1000802;
      case 'IsProfitabilityBelowOfIndustryAverage':
        return 1000803;
      case 'IsIncomeNearUSNLimit':
        return 1000804;
      case 'IsAverageSalaryNotExceed15000':
        return 1000805;
      case 'HasEncumbranceInStatedCapital':
        return 1000901;
      case 'HasContractsTerminatedByAgreement':
        return 1001001;
      case 'HasContractsTerminatedByCourt':
        return 1001002;
      case 'HasContractsTerminatedByCounterparty':
        return 1001003;
      case 'IsCompanyWasDefendantFromOurCompany':
        return 1001004;
      case 'IsCompanyWasPlaintiffToOurCompany':
        return 1001005;
      case 'IsReceivableToOurCompany':
        return 1001006;
      case 'HasLossDocumentsFacts':
        return 1001007;
      case 'OffersNonStandardWaysOfPayments':
        return 1001008;
      case 'IsContractSubjectAndTermsViolateLaw':
        return 1001009;
      case 'IsContractHasNoCounterpartyEconomicInterests':
        return 1001010;
      case 'IsHeadOrFounderHaveCountOfCompaniesFrom10To50':
        return 1001022;
      case 'IsHeadOrFounderHaveCountOfCompaniesGreaterThan50':
        return 1001023;
      case 'IsHeadHasMentionedCountOfCompaniesGreaterThan50':
        return 1001024;
      case 'HeadOrFounderIsIp':
        return 1001025;
      case 'IsHeadOrFounderHasHighRiskIndicator':
        return 1001102;
      case 'IsHeadOrFounderHasMediumRiskIndicator':
        return 1001103;
      case 'IsIpHasHighRiskIndicator':
        return 1001104;
      case 'IsIpHasMediumRiskIndicator':
        return 1001105;
      case 'IsNotRequestedInfoAboutHeadFounderOrIp':
        return 1001106;
      case 'HasNoPendingEnforcementProceedings':
        return 1001201;
      case 'HasNoSpecialRegistersRecords':
        return 1001202;
      case 'IsCompanyRegisteredOver3Years':
        return 1001203;
      case 'HasNoBankruptcyInfo':
        return 1001204;
      case 'IsInStrategicCompaniesList':
        return 1001205;
      case 'IsInGoldenShareList':
        return 1001206;
      case 'IsSRONostroyMember':
        return 1001207;
      case 'IsSRONoprizMember':
        return 1001208;
      case 'HasActivity':
        return 1001209;
      case 'AmountOfClaimsIsInsignificant':
        return 1001210;
      case 'AmountOfEnforcementProceedingsIsInsignificant':
        return 1001211;
      case 'HasNoRelationsWithCompetitors':
        return 1001212;
      case 'HasNoRelationsWithCounterparties':
        return 1001213;
      case 'HasNoRelationsWithEmployees':
        return 1001214;
      case 'HasNoRelationsWithBlackLists':
        return 1001215;
      case 'HasGoodRelationsRetrospective':
        return 1001216;
      case 'HasNoNegativeInfoAboutPersons':
        return 1001217;
      case 'HasValidLicenses':
        return 1001218;
      case 'IsStatedCapitalGreaterThan50k':
        return 1001219;
      case 'IsInSmallOrMediumBusinessRegister':
        return 1001220;
      case 'HasNoViolationsByInspections':
        return 1001221;
      case 'IsGoodFinancialState':
        return 1001222;
      case 'HasNoDefendInArbitrationCourts':
        return 1001223;
      case 'HasNoSanctions':
        return 1001224;
      case 'HasGovernmentContractAsContractor':
        return 1001225;
      case 'IsIpRegisteredOver3Years':
        return 1001226;
      case 'IsStatedCapitalGreaterThan100k':
        return 1001227;
      case 'IsStatedCapitalGreaterThan500k':
        return 1001228;
      case 'IsStatedCapitalGreaterThan1M':
        return 1001229;
      case 'IsStatedCapitalGreaterThan10M':
        return 1001230;
      case 'IsStatedCapitalGreaterThan100M':
        return 1001231;
      case 'IsSatisfactorilyFinancialState':
        return 1001232;
      case 'IsProceedGreaterThan1B':
        return 1001233;
      case 'HasFilials':
        return 1001234;
      case 'AmountOfClaimsToIpIsInsignificant':
        return 1001235;
      case 'HasContacts':
        return 1001236;
      case 'HasRegisteredSites':
        return 1001237;
      case 'HasActiveCertificates':
        return 1001238;
      case 'HasApplicationsToRegisterCertificates':
        return 1001239;
      case 'IsIntellectualPropertyOwner':
        return 1001240;
      case 'HasLeasedProperty':
        return 1001241;
      case 'HasFinancialStatements':
        return 1001242;
      case 'HasVehicles':
        return 1001243;
      case 'HasGovernmentContractAsCustomer':
        return 1001244;
      case 'HasCheckedConstituentDocuments':
        return 1001245;
      case 'HasCheckedProcuratories':
        return 1001246;
      case 'HasLicenseForContract':
        return 1001247;
      case 'IsContractHasCounterpartyEconomicInterests':
        return 1001248;
      case 'ContractSubjectAndTermsHasNoViolateLaw':
        return 1001249;
      case 'HasNoRelationsWithFamilyOfEmployees':
        return 1001250;
      default:
        return undefined;
    }
  }
}
