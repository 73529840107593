import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import { IPersonArbitrationMappingServiceKey } from '@/modules/dossier/person/arbitration/services/personArbitrationMappingService.interface';
import PersonArbitrationMappingService from '@/modules/dossier/person/arbitration/services/personArbitrationMappingService';
import { IPersonArbitrationStatisticsServiceKey } from '@/modules/dossier/person/arbitration/services/personArbitrationStatisticsService.interface';
import PersonArbitrationService from '@/modules/dossier/person/arbitration/services/personArbitrationStatisticsService';

export function bindPersonDossierArbitrationServices(bind: IContainerBinder) {
  bind(IPersonArbitrationMappingServiceKey).to(PersonArbitrationMappingService);
  bind(IPersonArbitrationStatisticsServiceKey).to(PersonArbitrationService);
}
