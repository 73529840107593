import { MaskOptions } from 'maska';

export const generalRequisiteMask: MaskOptions = {
  mask: 'E',
  tokens: {
    E: {
      pattern: /[\w\W]/,
      multiple: true,
    },
  },
};
