import { createI18n } from 'vue-i18n';
import enUS from 'vxe-table/lib/locale/lang/en-US';
import ruRU from './ru-RU';

const messages = {
  ru_RU: {
    ...ruRU,
  },
  en_US: {
    ...enUS,
  },
};

const i18n = createI18n({
  locale: 'ru_RU',
  messages,
});

export default i18n;
