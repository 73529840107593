import { IContainerBinder } from '@/configuration/inversify/containerAccessor.interface';
import companyLeasingMappingService from '@/modules/dossier/company/leasing/services/companyLeasingMappingService';
import { ICompanyLeasingMappingServiceKey } from '@/modules/dossier/company/leasing/services/companyLeasingMappingService.interface';
import companyLeasingStatisticsService from '@/modules/dossier/company/leasing/services/companyLeasingStatisticsService';
import { ICompanyLeasingStatisticsServiceKey } from '@/modules/dossier/company/leasing/services/companyLeasingStatisticsService.interface';

export function bindCompanyDossierLeasingServices(bind: IContainerBinder) {
  bind(ICompanyLeasingMappingServiceKey).to(companyLeasingMappingService);
  bind(ICompanyLeasingStatisticsServiceKey).to(companyLeasingStatisticsService);
}
