import { ComponentDefinition } from '@/modules/common/types/componentDefinition';
import { MaybeRefFields } from '@/modules/common/types/maybeRefFields';
import {
  UseUITagIconConfig
} from '@/modules/tags/components/UITagIcon/composables/useUITagIcon/useUITagIcon.interfaces';
import UITagIcon from '@/modules/tags/components/UITagIcon/UITagIcon.vue';

export function useUITagIcon(config: MaybeRefFields<UseUITagIconConfig>): ComponentDefinition {
  return {
    component: UITagIcon,
    config: config
  }
}
