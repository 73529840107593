import { injectable } from 'inversify';
import { IPersonNegativeDebtsStatisticsService } from '@/modules/dossier/person/negativeDebts/services/personNegatveDebtsStaticticsService.interface';
import { ApiPersonDetailNegativeDebtsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailNegativeDebtsRecord';
import { DateUtils } from '@/utils/dateUtils';

@injectable()
export default class PersonNegativeDebtsStatisticsService
  implements IPersonNegativeDebtsStatisticsService
{
  calculateActiveDebtsCount(debts: Array<ApiPersonDetailNegativeDebtsRecord>): number {
    const activeDebts = debts.filter((debt) => {
      if (debt.executiveProcedureDate == undefined) {
        return false;
      }

      return DateUtils.checkActualityToday(debt.executiveProcedureDate);
    });
    return activeDebts.length;
  }

  calculateArchivedDebtsSum(debts: Array<ApiPersonDetailNegativeDebtsRecord>): number {
    const archivedDebts = debts.filter((debt) => {
      if (debt.executiveProcedureDate == undefined) {
        return false;
      }

      return !DateUtils.checkActualityToday(debt.executiveProcedureDate);
    });
    return archivedDebts.reduce((acc, debt) => acc + Number(debt.debt), 0);
  }

  calculateActiveDebtsSum(debts: Array<ApiPersonDetailNegativeDebtsRecord>): number {
    const activeDebts = debts.filter((debt) => {
      if (debt.executiveProcedureDate == undefined) {
        return false;
      }

      return DateUtils.checkActualityToday(debt.executiveProcedureDate);
    });
    return activeDebts.reduce((acc, debt) => acc + Number(debt.debt), 0);
  }
}
