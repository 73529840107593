import {
  IGridCompanyToAnalysisEntityMapper
} from '@/modules/analysis/services/mappers/gridCompanyToAnalysisEntityMapper.interfaces';
import { AnalysisEntity } from '@/modules/analysis/types/analysisEntity';
import { AnalysisEntityType } from '@/modules/analysis/types/analysisEntityType';
import {
  IDataGridCompanyNameFormatterService, IDataGridCompanyNameFormatterServiceKey
} from '@/modules/dataGrid/company/services/formatters/dataGridCompanyNameFormatterService/dataGridCompanyNameFormatterService.interfaces';
import { ApiGridUlRecord } from '@/modules/dataGrid/company/types/api/apiGridUlRecord';
import { inject, injectable } from 'inversify';

@injectable()
export default class GridCompanyToAnalysisEntityMapper implements IGridCompanyToAnalysisEntityMapper {
  constructor(@inject(IDataGridCompanyNameFormatterServiceKey as symbol) private nameFormatter: IDataGridCompanyNameFormatterService) {
  }

  map(item: ApiGridUlRecord): AnalysisEntity {
    return {
      id: item.company_original_id,
      type: AnalysisEntityType.company,
      title: this.nameFormatter.format(item)
    }
  }
}
