import { injectable } from 'inversify';
import { DetailsCompanyLicensesList } from '@/modules/dossier/company/common/types/detailsCompanyLicensesList';
import { ICompanyLicensesService } from '@/modules/dossier/company/common/services/mappings/companyLicensesService.interface';
import { DetailsCompanyLicenseStatuses } from '@/modules/dossier/company/common/types/detailsCompanyLicenseStatuses';
import { UITagTheme } from '@/modules/tags/types/tagTheme';

export function getDetailsCompanyLicensesStatusesString(
  status: DetailsCompanyLicenseStatuses | string,
): string {
  switch (status) {
    case DetailsCompanyLicenseStatuses.Active:
      return 'Действующая';
    case DetailsCompanyLicenseStatuses.Inactive:
      return 'Недействующая';
    case DetailsCompanyLicenseStatuses.Terminated:
      return 'Прекращена';
    case DetailsCompanyLicenseStatuses.DeletedFromSource:
      return 'Недействующая — удалена из открытых данных';
    case DetailsCompanyLicenseStatuses.Expired:
      return 'Истекла';
    case DetailsCompanyLicenseStatuses.Unknown:
    default:
      return 'Неизвестно';
  }
}

export function getStatusDescription(statusDescription?: string | null): string {
  if (statusDescription == '' || statusDescription == null) {
    return getDetailsCompanyLicensesStatusesString(DetailsCompanyLicenseStatuses.Unknown);
  }
  return statusDescription;
}

function getGuaranteesStatusTheme(status?: DetailsCompanyLicenseStatuses | string): UITagTheme {
  switch (status) {
    case 'Действующая':
      return 'success';
    case 'Недействующая':
    case 'Прекращена':
    case 'Недействующая — удалена из открытых данных':
    case 'Истекла':
      return 'danger';
    case 'Неизвестно':
    default:
      return 'common';
  }
}

@injectable()
export default class companyLicensesService implements ICompanyLicensesService {
  getLicensesData(licenses: Array<DetailsCompanyLicensesList>): Array<DetailsCompanyLicensesList> {
    const sortedList = licenses.reduce(
      (acc, curr) => {
        const licensesData = {
          number: curr.number,
          activity: curr.activity,
          places: curr.places,
          services: curr.services,
          source: curr.source,
          issuerName: curr.issuerName,
          startDate: curr.startDate,
          date: curr.date,
          endDate: curr.endDate,
          status: {
            theme: getGuaranteesStatusTheme(curr.statusDescription),
            text: getStatusDescription(curr.statusDescription),
          },
        };
        acc[licensesData.status.theme].push(licensesData);
        return acc;
      },
      {
        success: [],
        danger: [],
        warning: [],
        common: [],
      } as Record<string, Array<DetailsCompanyLicensesList>>,
    );
    const { success, danger, common } = sortedList;
    const dangerSorted = danger.sort((a, b) => a.status.text.localeCompare(b.status.text));
    return [...success, ...dangerSorted, ...common];
  }
}
