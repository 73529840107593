export module DateFormatConfiguration {
  export const fullDate = 'dd.MM.yyyy';
  export const dayAndMonth = 'dd.MM';
  export const year = 'yyyy';
  export const compactDate = 'dd MMMM Y';
  export const monthLong = 'LLLL';
  export const isoDate = 'yyyy-MM-dd';
  export const apiDate = 'dd MMMM yyyy г.';
  export const apiDateTime = 'dd MMMM yyyy г. HH:mm:ss';

  export const dateTime = 'dd.MM.yyyy HH:mm';
  export const fullDateTime = 'dd.MM.yyyy HH:mm:ss';

  export const time = 'HH:mm';

  export const defaultDisplayDateFormat = fullDate;
}
