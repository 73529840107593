import { injectable } from 'inversify';
import { IPersonNegativeOverviewMappingService } from '@/modules/dossier/person/negativeOverview/services/personNegativeOverviewMappingService.interface';
import { PersonNegativeOverviewStatisticsTableRow } from '@/modules/dossier/person/negativeOverview/types/personNegativeOverviewStatisticsTableRow';
import { ApiPersonDetailNegativeInfoRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailNegativeInfoRecord';
import { UseDxTagConfig } from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';

@injectable()
export default class PersonNegativeOverviewMappingService
  implements IPersonNegativeOverviewMappingService
{
  mapNegativeClarificationValue = (value?: boolean): UseDxTagConfig => {
    switch (value) {
      case true:
        return { theme: 'danger', content: 'Да' };
      case false:
        return { theme: 'success', content: 'Нет' };
      default:
        return { theme: 'common', content: 'Неизвестно' };
    }
  };

  mapNegativeOverviewStatisticsTableData(
    negativeMentions: Array<ApiPersonDetailNegativeInfoRecord>,
  ): Array<PersonNegativeOverviewStatisticsTableRow> {
    return negativeMentions.map((mention) => {
      const config = this.mapNegativeClarificationValue(mention.valueBool);
      return {
        type: mention.negativeInfoTypeStr ? mention.negativeInfoTypeStr : 'Неизвестно',
        value: {
          theme: config.theme,
          text: config.content,
        },
        text: mention.originalText ? mention.originalText : 'Неизвестно',
      };
    });
  }
}
