import DefaultAPIInstance from '@/api/client';
import { AxiosResponse } from 'axios';
import { WeightResponse } from '@/api/ratings/types';

const AdminAPI = {
  getWeights(): Promise<AxiosResponse<WeightResponse>> {
    const url = `/ratings/weights`;
    return DefaultAPIInstance.post(url);
  },
  updateWeights(payload: { weight: number; id: string; name: number }[]): Promise<void> {
    const url = `/ratings/weights/update`;
    return DefaultAPIInstance.post(url, payload);
  },
};

export default AdminAPI;
