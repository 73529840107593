import {
  useRegulationCheckCompanyMarkersChangedEvent
} from '@/modules/regulationsCheck/common/composables/events/useRegulationCheckCompanyMarkersChangedEvent/useRegulationCheckCompanyMarkersChangedEvent';
import {
  useRegulationCheckPersonMarkersChangedEvent
} from '@/modules/regulationsCheck/common/composables/events/useRegulationCheckPersonMarkersChangedEvent/useRegulationCheckPersonMarkersChangedEvent';
import { inject, injectable } from 'inversify';
import { ApiEndpointKey } from '@/modules/common/endpoints/apiEndpoint.interfaces';
import { IRegulationsCheckMarkersApiService } from '@/modules/regulationsCheck/common/services/regulationsCheckMarkersApiService.interface';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ApiResponse } from '@/modules/common/types/api/apiResponse';
import { RisksDetail } from '@/modules/regulationsCheck/common/types/risksDetail';
import { RateUpdateParameters } from '@/modules/regulationsCheck/common/types/rateUpdateParameters';

const { triggerRegulationCheckPersonMarkersChangedEvent } = useRegulationCheckPersonMarkersChangedEvent();
const { triggerRegulationCheckCompanyMarkersChangedEvent } = useRegulationCheckCompanyMarkersChangedEvent();


@injectable()
export default class RegulationsCheckMarkersApiService
  implements IRegulationsCheckMarkersApiService
{
  constructor(@inject(ApiEndpointKey as symbol) private apiEndpoint: AxiosInstance) {}

  getPersonMarkersWeight(id: string): Promise<AxiosResponse<ApiResponse<RisksDetail>>> {
    const url = `/risks/person-risks-detail?id=${id}`;
    return this.apiEndpoint.get(url);
  }

  getCompanyMarkersWeight(id: string): Promise<AxiosResponse<ApiResponse<RisksDetail>>> {
    const url = `/risks/ul-risks-detail?id=${id}`;
    return this.apiEndpoint.get(url);
  }

  async updatePersonRating(parameters: RateUpdateParameters): Promise<AxiosResponse<void>> {
    const result = await this.apiEndpoint.post('/person/rate/update', parameters);
    triggerRegulationCheckPersonMarkersChangedEvent(parameters.parentId);
    return result;
  }

  async updateCompanyRating(parameters: RateUpdateParameters): Promise<AxiosResponse<void>> {
    const result = this.apiEndpoint.post('/ul/rate/update', parameters);
    triggerRegulationCheckCompanyMarkersChangedEvent(parameters.parentId);
    return result;
  }
}
