import { InjectionKey } from 'vue';
import { TDetailsCompanyBranchesList } from '@/types/companyData/companyData';
import { Dictionary } from '@/types/common';
import { GetCompanyBranchesStaticsTableData } from '@/modules/dossier/company/common/types/getCompanyBranchesStaticsTableData';

export interface ICompanyBranchesMappingService {
  getCompanyBranchesStaticsTableData(
    groupedBranches: Dictionary<Array<TDetailsCompanyBranchesList>>,
  ): Array<GetCompanyBranchesStaticsTableData>;
}

export const ICompanyBranchesMappingServiceKey: InjectionKey<ICompanyBranchesMappingService> =
  Symbol('ICompanyBranchesMappingServiceInterfaceKey');
