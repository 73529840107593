import { HtmlUtils } from '@/modules/common/utils/htmlUtils';
import {
  IElasticCompanyFieldFormatter, IElasticCompanyFieldFormatterKey
} from '@/modules/elastic/services/elasticCompanyFieldFormatter/elasticCompanyFieldFormatter.interfaces';
import {
  IElasticHighlightParser
} from '@/modules/elastic/services/elasticHighlightParser.interfaces';
import {
  IElasticPersonFieldFormatter, IElasticPersonFieldFormatterKey
} from '@/modules/elastic/services/elasticPersonFieldFormatter/elasticPersonFieldFormatter.interfaces';
import { ApiElasticCompanyField } from '@/modules/elastic/types/api/apiElasticCompanyField';
import { ApiElasticHit } from '@/modules/elastic/types/api/apiElasticHit';
import { ApiElasticPersonField } from '@/modules/elastic/types/api/apiElasticPersonField';
import { ApiElasticSourceType } from '@/modules/elastic/types/api/apiElasticSourceType';
import { ElasticHighlight } from '@/modules/elastic/types/elasticHighlight';
import { inject, injectable } from 'inversify';

const highlightKeyRegex = /(\w+)\.(\w+).values/

@injectable()
export default class ElasticHighlightParser implements IElasticHighlightParser {
  constructor(
    @inject(IElasticPersonFieldFormatterKey as symbol) private personFieldFormatter: IElasticPersonFieldFormatter,
    @inject(IElasticCompanyFieldFormatterKey as symbol) private companyFieldFormatter: IElasticCompanyFieldFormatter
  ) { }
  parse(hit: ApiElasticHit): ElasticHighlight | undefined {
    try {
      if(hit.highlight == undefined)
        return;

      const highlightEntry = Object.entries(hit.highlight)[0];

      if (highlightEntry == undefined)
      return;

      const [highlightKey, highlightValues] = highlightEntry;

      const htmlContent = highlightValues[0];
      const textContent = HtmlUtils.cleanHTML(htmlContent);

      const [source, field] = this.parseHighlightKey(highlightKey);

      const fieldName = source == ApiElasticSourceType.Person ?
        this.personFieldFormatter.format(field as ApiElasticPersonField) :
        this.companyFieldFormatter.format(field as ApiElasticCompanyField);

      return {
        htmlContent,
        textContent,
        source,
        fieldName
      }
    } catch (ex) {
      console.error(ex, hit);
    }
  }

  private parseHighlightKey(highlightKey: string): [source: ApiElasticSourceType, field: string] {
    const match = highlightKeyRegex.exec(highlightKey);

    if (match == undefined)
      throw new Error(`Highlight key parse error: ${highlightKey}`);

    const [, sourceStr, field] = match;
    const source = this.parseSourceStr(sourceStr);

    return [source, field];
  }

  private parseSourceStr(value: string) {
    switch (value.toLowerCase()) {
      case 'personroot': return ApiElasticSourceType.Person;
      case 'companyroot': return ApiElasticSourceType.Company;
      default: return ApiElasticSourceType.Unknown;
    }
  }
}
