import { GuidUtils } from '@/modules/common/utils/guidUtils';
import { IChainsService } from '@/modules/graph/common/services/chainsService.interfaces';
import { GraphCounterPartyChain } from '@/modules/graph/table/types/api/graphCounterPartyChain';
import { injectable } from 'inversify';

@injectable()
export default class ChainsService implements IChainsService {
  getPersonIds(chains: GraphCounterPartyChain[]): string[] {
    const idsSet = new Set<string>();

    for(const chain of chains)
      for(const entity of chain.entities)
        if (entity.personDetailUrl.startsWith('/person/') && !GuidUtils.isEmptyGuid(entity.personId))
          idsSet.add(entity.personId);

    return [...idsSet.values()];
  }

  getCompanyIds(chains: GraphCounterPartyChain[]): string[] {
    const idsSet = new Set<string>();

    for(const chain of chains)
      for(const entity of chain.entities)
        if (!entity.personDetailUrl.startsWith('/person/') && !GuidUtils.isEmptyGuid(entity.personId))
          idsSet.add(entity.personId);

    return [...idsSet.values()];
  }
}
