import { AxiosError, AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import RouteNames from '@/modules/router/common/types/routeNames';
import { Router } from 'vue-router';

export function useAuthInterceptor(router: Router, endpoint: AxiosInstance) {
  async function onRejectedResponse(payload: AxiosError) {
    const { response } = payload;

    if (response?.status === 302) {
      await router.push({ name: RouteNames.AUTH_PAGE });
      Cookies.remove('isAuth');
    }

    return Promise.reject(payload);
  }

  endpoint.interceptors.response.use(
    (r) => r,
    (error) => onRejectedResponse(error),
  );
}
