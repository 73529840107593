import { injectable } from 'inversify';
import { IDxSearchFilterFactory } from '@/modules/devExtreme/services/factories/dxSearchFilterFactory.interface';
import DxRichFilter from '@/modules/devExtreme/filters/models/dxRichFilter';
import { DxCompareOperations } from '@/modules/devExtreme/types/DxCompareOperations';
import { DxTableColumnSearchInfo } from '@/modules/devExtreme/types/dxTableColumnSearchInfo';
import { DxRichFilterItem } from '@/modules/devExtreme/filters/types/DxRichFilterItem';

@injectable()
export default class DxSearchFilterFactory implements IDxSearchFilterFactory {
  buildFilter(
    dataFields: DxTableColumnSearchInfo | Array<DxTableColumnSearchInfo>,
    searchValue: string | Array<string>,
  ): DxRichFilter | undefined {
    if (searchValue.length == 0) {
      return undefined;
    }

    const searchArray = Array.isArray(searchValue) ? searchValue : [searchValue];
    const dataFieldsArray = Array.isArray(dataFields) ? dataFields : [dataFields];

    const items = dataFieldsArray.map(({ dataField, searchCompareType, prepareSearchValue }) =>
      DxRichFilter.create(
        'or',
        searchArray.reduce((filterItems, searchString) => {
          const value =
            prepareSearchValue == undefined ? searchString : prepareSearchValue(searchString);

          if (value != undefined) {
            filterItems.push({
              dataField,
              compare: searchCompareType ?? DxCompareOperations.Contains,
              value,
            });
          }
          return filterItems;
        }, new Array<DxRichFilterItem>()),
      ),
    );

    return DxRichFilter.create('or', items);
  }
}
