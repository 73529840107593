import { injectable } from 'inversify';
import { PersonalSocialNetworkAdditionalInfoOkUserLink } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/types/personalSocialNetworkAdditionalInfoOkUserLink';
import { IPersonSocialNetworkAdditionalInfoOkUserLinkService } from '@/modules/dossier/person/socialNetworkAdditionalInfo/socialNetworkAdditionalInfoOK/services/parsers/personSocialNetworkAdditionalInfoOkUserLinkService.interface';
import { ApiPersonDetailOkRecordUserItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordUserItem';
import { ApiPersonDetailOkRecordRelativeItem } from '@/modules/dossier/person/common/types/api/apiPersonDetailOkRecordRelativeItem';

@injectable()
export default class PersonSocialNetworkAdditionalInfoOkUserLinkService
  implements IPersonSocialNetworkAdditionalInfoOkUserLinkService
{
  getPersonOkUserLink(
    listItemOkRelative: ApiPersonDetailOkRecordRelativeItem | ApiPersonDetailOkRecordUserItem,
  ): PersonalSocialNetworkAdditionalInfoOkUserLink {
    return {
      fullName: `${listItemOkRelative.firstName} ${listItemOkRelative.lastName}`,
      url: listItemOkRelative.profileUrl || '',
    };
  }
}
