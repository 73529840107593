import { IApiSettings, IApiSettingsKey } from '@/modules/common/settings/apiSettings.interfaces';
import { ISwaggerUrlService } from '@/modules/swagger/services/swaggerUrlService.interfaces';
import { inject, injectable } from 'inversify';

@injectable()
export default class SwaggerUrlService implements ISwaggerUrlService {
  constructor(
    @inject(IApiSettingsKey as symbol) private apiSettings: IApiSettings
  ) { }

  getUrl(): string {
    return `${this.apiSettings.rootUrl}/api`;
  }
}
