import { injectable } from 'inversify';
import { IPersonEnforcementProceedingsTagConfigService } from '@/modules/dossier/person/enforcementProceedings/services/personEnforcementProceedingsTagConfigService.interface';
import { UseDxTagConfig } from '@/modules/devExtreme/components/DxTag/composables/useDxTag/useDxTag.interfaces';

@injectable()
export default class PersonEnforcementProceedingTagConfigService
  implements IPersonEnforcementProceedingsTagConfigService
{
  determineEPTagConfig(status: string): UseDxTagConfig {
    if (status == null || status == '') {
      return { content: 'Неизвестно', theme: 'common' };
    }
    if (status.toLowerCase() == 'погашено') {
      return { content: status, theme: 'success' };
    }
    return { content: status, theme: 'danger' };
  }
}
