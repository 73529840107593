import { injectable } from 'inversify';
import { ApiPersonDetailPassportsRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailPassportsRecord';
import { ApiPersonDetailPassportComplexChecks } from '@/modules/dossier/person/common/types/api/apiPersonDetailPassportComplexChecks';
import { ApiPersonDetailPassportComplexCheckDataRecord } from '@/modules/dossier/person/common/types/api/apiPersonDetailPassportComplexCheckDataRecord';
import { IPersonPassportComplexCheckingParserService } from '@/modules/dossier/person/passports/services/parsers/personPassportComplexCheckingParserService.interface';

@injectable()
export default class PersonPassportComplexCheckingParserService
  implements IPersonPassportComplexCheckingParserService
{
  parse(
    complexChecksList: ApiPersonDetailPassportComplexChecks,
    passportItem: ApiPersonDetailPassportsRecord,
  ): ApiPersonDetailPassportComplexCheckDataRecord | undefined {
    if (complexChecksList.list.length !== 0) {
      return complexChecksList.list.find(
        (item: { checkedPassportNumber: string }) =>
          item.checkedPassportNumber ==
          `${passportItem.passportSeries}${passportItem.passportNumber}`,
      );
    }
  }
}
