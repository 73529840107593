import { injectable } from 'inversify';
import { RequisiteType } from '../types/requisiteType';
import { IRequisiteTypeFormatterService } from './requisiteTypeFormatterService.interfaces';

@injectable()
export class RequisiteTypeFormatterService implements IRequisiteTypeFormatterService {
  getRequisiteType(set: RequisiteType): string {
    switch (set) {
      case RequisiteType.carNumber:
        return 'Госномер автомобиля';
      case RequisiteType.fullName:
        return 'ФИО + дата';
      case RequisiteType.innFl:
        return 'ИНН ФЛ';
      case RequisiteType.innUl:
        return 'ИНН ЮЛ';
      case RequisiteType.innIp:
        return 'ИНН ИП';
      case RequisiteType.phone:
      default:
        return 'Абоненский номер';
    }
  }
}
