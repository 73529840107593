import { InjectionKey } from 'vue';
import { Lease } from '../types/lease';
import { LeasingContractsTableRow } from '../types/leasingContractsTableRow';
import { LeasingSubjectsTableRow } from '../types/leasingSubjectsTableRow';
import { LessorsTableRow } from '../types/lessorsTableRow';

export interface ICompanyLeasingMappingService {
  mapLeasingSubjectsTable(leases: Array<Lease>): Array<LeasingSubjectsTableRow>;
  mapLessorsTable(leases: Array<Lease>): Array<LessorsTableRow>;
  mapLeasingContractsTable(leases: Array<Lease>): Array<LeasingContractsTableRow>;
}

export const ICompanyLeasingMappingServiceKey: InjectionKey<ICompanyLeasingMappingService> = Symbol(
  'ICompanyLeasingMappingServiceKey',
);
