import { ReportRequisiteCardConfig } from '@/modules/reports/common/types/ReportRequisiteCardConfig';
import { SystemIconsNames } from '@/types/systemIcons/systemIconsNames';

export module RequisiteConfiguration {
  export const typesCardPerson: Array<ReportRequisiteCardConfig> = [
    {
      icon: SystemIconsNames.reportsRequisitesFullName,
      id: 1,
      text: 'ФИО + Дата рождения',
    },
    {
      icon: SystemIconsNames.reportsRequisitesPhone,
      id: 2,
      text: 'Абонентский номер',
    },
    {
      icon: SystemIconsNames.reportsRequisitesCar,
      id: 3,
      text: 'Госномер автомобиля',
    },
    { icon: SystemIconsNames.reportsRequisitesInn, id: 4, text: 'ИНН ФЛ' },
    {
      icon: SystemIconsNames.reportsRequisitesPhoto,
      id: 7,
      text: 'Поиск соц. сетей по фотографии',
    },
  ];

  export const typesCardPersonMobile: Array<ReportRequisiteCardConfig> = [
    {
      icon: SystemIconsNames.reportsRequisitesFullName,
      id: 1,
      text: 'ФИО + дата рожд.',
    },
    {
      icon: SystemIconsNames.reportsRequisitesPhone,
      id: 2,
      text: 'Абонент. номер',
    },
    {
      icon: SystemIconsNames.reportsRequisitesCar,
      id: 3,
      text: 'Госномер автомобиля',
    },
    { icon: SystemIconsNames.reportsRequisitesInn, id: 4, text: 'ИНН ФЛ' },
  ];

  export const typesCardCompany: Array<ReportRequisiteCardConfig> = [
    { icon: SystemIconsNames.reportsRequisitesInn, id: 5, text: 'ИНН ЮЛ' },
    { icon: SystemIconsNames.reportsRequisitesInn, id: 6, text: 'ИНН ИП' },
  ];

  export const typesCardFuturePerson: Array<ReportRequisiteCardConfig> = [
    {
      icon: SystemIconsNames.reportsRequisitesPassportDisabled,
      id: 0,
      text: 'Паспорт',
    },
    { icon: SystemIconsNames.reportsRequisitesInnDisabled, id: 0, text: 'СНИЛС' },
    {
      icon: SystemIconsNames.reportsRequisitesAddressDisabled,
      id: 0,
      text: 'Адрес',
    },
    {
      icon: SystemIconsNames.reportsRequisitesMessengerDisabled,
      id: 0,
      text: 'Мессенджер',
    },
    {
      icon: SystemIconsNames.reportsRequisitesSocialDisabled,
      id: 0,
      text: 'Социальные сети',
    },
    {
      icon: SystemIconsNames.reportsRequisitesMessengerDisabled,
      id: 0,
      text: 'Почта',
    },
  ];

  export const typesCardFutureCompany: Array<ReportRequisiteCardConfig> = [
    {
      icon: SystemIconsNames.reportsRequisitesInnDisabled,
      id: 0,
      text: '№ арбитражного дела',
    },
    {
      icon: SystemIconsNames.reportsRequisitesInnDisabled,
      id: 0,
      text: '№ исполнительного производства',
    },
    {
      icon: SystemIconsNames.reportsRequisitesInnDisabled,
      id: 0,
      text: '№ извещения о закупке',
    },
    {
      icon: SystemIconsNames.reportsRequisitesInnDisabled,
      id: 0,
      text: '№ госконтракта',
    },
    {
      icon: SystemIconsNames.reportsRequisitesInnDisabled,
      id: 0,
      text: 'ОГРН/ОГРНИП',
    },
  ];
}
