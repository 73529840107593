import { useInjectable } from '@/hooks/useInjectable';
import { injectable } from 'inversify';
import { IApiSettingsKey } from '@/modules/common/settings/apiSettings.interfaces';
import { IFilesService } from '@/modules/files/services/filesService.interface';
import { FinancialStatementsFileTypes } from '@/modules/files/types/FinancialStatementsFileTypes';

@injectable()
export default class FilesService implements IFilesService {
  private apiSettings = useInjectable(IApiSettingsKey);

  getImportPersonSchemeUrl() {
    return `${this.apiSettings.staticFilesUrl}persons/manual-insert/templates/ManualInsertPersons.xlsx`;
  }

  getImportCompanySchemeUrl() {
    return `${this.apiSettings.staticFilesUrl}persons/manual-insert/templates/ManualInsertCompanies.xlsx`;
  }

  getDownloadUrl(relativeUrl?: string): string {
    if (relativeUrl == undefined) {
      return this.apiSettings.rootUrl;
    }

    return `${this.apiSettings.rootUrl}${relativeUrl}`;
  }

  getDownloadEGRN(companyId: string): string {
    return `${this.apiSettings.v1ApiUrl}proxies/damia/doc/vyp?companyId=${companyId}`;
  }

  // Бухгалтерская отчетность
  getDownloadFinancialStatementsUrl(
    companyId: string,
    fileType: FinancialStatementsFileTypes,
    year: number,
  ): string {
    return `${this.apiSettings.v1ApiUrl}proxies/damia/doc/bo?companyId=${companyId}&xls=${fileType}&year=${year}`;
  }

  // eslint-disable-next-line class-methods-use-this
  downloadFile(response: string | Uint8Array, filename: string, type = 'application/json') {
    const blob = new Blob([response], { type });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  }

  // eslint-disable-next-line class-methods-use-this
  convertBase64ToUnit8Array(base64: string) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < binaryString.length; i += 1) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }
}
